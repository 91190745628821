import React, { useState, useEffect, useRef } from "react";
import { useParams } from 'react-router-dom';
import { Box, Grid, TextField, Divider, InputAdornment, IconButton, FormControl, OutlinedInput, InputLabel, FormHelperText,DialogContent,
    useMediaQuery, Dialog, DialogTitle,DialogActions, List,ListItem,Paper,ListItemText,Button } from '@mui/material';
import { Typography, Card, CardContent,  CardOverflow } from '@mui/joy';
import Freez from '../../Components/Loader/Loader';
import Backdrop from '@mui/material/Backdrop';
// functional imports
import AuthUser from '../../Components/Auth/AuthUser';
import { Formik, Form } from 'formik';
import swal from "sweetalert";
import * as Yup from 'yup';
import { useTheme } from '@mui/material/styles';
import SecureWebsite from "../../Components/Enrollment/SecureWebsite";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Chat from "../CreditSnapshot/Chat";
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { FCMServerKey, FCMSendUrl } from "../../firbase/firstore";
import config from "../../AppConfig";
import { ReactComponent as ChatIcon } from "../../assets/images/Chatbutton.svg";
import { ReactComponent as EmailIcon } from "../../assets/images/Emailbutton.svg";
import { ReactComponent as PlayIcon } from "../../assets/images/Playbutton.svg";
let chatSessionId = null;

const NewEnrollment1 = ({ onValueChange, props, customColorArray, textColor,aid, pid ,adid,btnTextColor, chatEnable, emailUsEnable,companyId, step1Link}) => {
    const { http } = AuthUser();
    const { sid, tid } = useParams();
    const [errorfromcd, seterrorfromcd] = useState();
    const [loginLink, setloginLink] = useState();
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const [checkUsaTldStatus, setCheckUsaTldStatus] = useState(false);
    const [emailButton, setEmailButton] = useState(false);
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [isMinimized, setIsMinimized] = useState(true); 
    const [assistYouFlag, setAssistYouFlag] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [emailChatopen, setEmailChatopen] = useState(false);
    const[messages, setMessages] = useState([]);
    const [openIframe, setOpenIframe] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleMaxWidthChange = (event) => {
        setMaxWidth(
            // @ts-expect-error autofill of arbitrary value is not handled.
            event.target.value,
        );
    };

    const handleFullWidthChange = (event) => {
        setFullWidth(event.target.checked);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .matches(
                /^(?=[-0-9A-Za-z.#'\s]*$)/,
                'Please enter a valid First Name'
            )
            .required('First Name is required'),
        middleName :Yup.string()
            .matches(
                /^(?=[-0-9A-Za-z.#'\s]*$)/,
                'Please enter a valid Middle Name'
            ),
        lastName: Yup.string()
            .matches(
                /^(?=[-0-9A-Za-z.#'\s]*$)/,
                'Please enter a valid Last Name'
            )
            .required('Last Name is required'),
        email: Yup.string()
            .email('Invalid email')
            // .matches(/^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24})$/i, "Invalid email format.")
            .matches(/^[A-Z0-9][A-Z0-9._-]*@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid email format.")
            .matches(/^[A-Z0-9._-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid email format.")
            .notOneOf(['%', '+', '='], 'Invalid email format.')
            .required('Email is required'),
        password: Yup.string()
            .min(8, 'Password must be at least 8 characters')
            .required('Password is required')
            .matches(/^[^\+=]*$/, "The password contains invalid characters"),

    });

     //MainEnroll function starts
    const MainErollStep1 = (values) => {
        setButtonDisabled(true);
        handleFreezStart();
        http.post("/registerEnrollment", {
            step: 1,
            firstName: values.firstName,
            middlename: values.middleName,
            lastName: values.lastName,
            email: values.email,
            password: values.password,
            source: "default",
            aid: aid,
            pid: pid,
            adid: adid,
        })
            .then((res) => {
                handleFreezStop();
                if (res.data.success === true) {
                    localStorage.setItem('customerToken', res.data.data.customerToken);
                    localStorage.setItem('trackingToken', res.data.data.trackingToken);
                    localStorage.setItem('Main_name', values.firstName+' '+values.lastName);
                    localStorage.setItem('Main_firstName', values.firstName);
                    localStorage.setItem('Main_lastName', values.lastName);
                    localStorage.setItem('Main_middleName', values.middleName);
                    localStorage.setItem('Main_email', values.email);
                    localStorage.setItem('Main_password', values.password);
                    localStorage.setItem('aid', aid);
                    localStorage.setItem('pid', pid);
                    localStorage.setItem('adid', adid);
                    if (res.data.data.nextstep == 3) {
                        localStorage.setItem('idQuestions', JSON.stringify(res.data.data.idQuestions));
                    }
                    onValueChange(res.data.data.nextstep)
                    setButtonDisabled(false);

                } else {
                    const errorObj = res.data.data.errorByCD;
                    if (errorObj[0].message) {
                        seterrorfromcd(errorObj[0].message)
                    } else {
                        seterrorfromcd(res.data.data.errorByCD)
                    }
                    if (res.data.data.loginUrl) {
                        setloginLink(res.data.data.loginUrl);
                    }
                    onValueChange(props);
                    setButtonDisabled(false);

                }
            })
            .catch((error) => {
                handleFreezStop();
                setButtonDisabled(false);
                swal({
                    title: "Failed",
                    text: error.message,
                    icon: "error",
                    button: "Ok",
                })

            });
    }
    //MainEnroll function ends
    //validation schema ends
    const handleCallback = (data) => {
        seterrorfromcd(data);
    }
    const handleFreezCallback = (data) => {
        setFreez(data);
    }
    const checkUsaTLD = (email) => {
        if (email.includes('@')) {
            setEmailButton(true);
            http.post("/checkUsaTLD", {
                email: email,
            })
            .then((res) => {
                if (res.data.success === true) {
                    setCheckUsaTldStatus(true);
                } else {
                    setCheckUsaTldStatus(false);
                }
            })
            setEmailButton(false);
        }
    }

    const closeEmailChatPopup = () => {
        setEmailChatopen(false);
    }

    const openChatPopup = (e) => {
        setIsChatOpen(true);
        var sessionMessagesObj;
        if(localStorage.getItem("sessionMessages") != undefined) {
            sessionMessagesObj = localStorage.getItem("sessionMessages");
        }
        e.preventDefault();
        const element = document.getElementById("chatbotDiv");
        element.style.display = "block";
        console.log("isMinimized:"+isMinimized);
        setIsMinimized(!isMinimized);
        
        chatSessionId = localStorage.getItem('chatSessionId');
        if(chatSessionId != null){
            setAssistYouFlag(true);
        }else{
            if(!assistYouFlag){
                setAssistYouFlag(true);
            }
        }
    }

    const sendEmailChatSchema = Yup.object().shape({
            subject: Yup.string()
                .min(2, 'Too Short!')
                .required('Please enter subject'),
            description : Yup.string()
                .min(2, 'Too Short!')
                .required('Please enter Description'),
            email: Yup.string()
                .email('Please enter a valid email address') 
                .required('Please enter your email address'),
    });

    const openEmailChatPopup = () => {
        setIsMinimized(true);
        setEmailChatopen(true);
        // setIsMinimized(!isMinimized);
    }

    const changeOpenChatPopupValue = (value) => {
        setIsMinimized(value);
    }
    const handleIFrame = () => {
        setOpenIframe(true);
    }

    const handleCloseIFrame = () => {
        setOpenIframe(false);
    }
    return (
        <>
        <Box>
            <Formik
                initialValues={{ firstName: '', lastName: '', email: '', password: '' }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    // Handle form submission logic here
                    if(emailButton === true || checkUsaTldStatus === true) {
                        return false;
                    }
                    setSubmitting(false);
                    MainErollStep1(values);
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <Form>
                        <Grid container item lg={12} md={12} sm={12} xs={12} >
                            <FormControl variant="outlined" fullWidth
                                margin="dense" size="small">
                                <InputLabel sx={{color:customColorArray.primaryColor}}>First Name*</InputLabel>
                                <OutlinedInput
                                    id="firstName"
                                    name="firstName"
                                    label="First Name*"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    autoComplete="firstName"
                                    margin="dense"
                                    className='cobrandTextField'
                                    error={touched.firstName && !!errors.firstName}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                            >
                                                {touched.firstName && !errors.firstName ? (
                                                    <CheckIcon style={{ color: 'green' }} />
                                                ) : (
                                                    touched.firstName && errors.firstName ? (
                                                        <CloseIcon style={{ color: 'red' }} />
                                                    ) : null
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    value={values.firstName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    sx={{
                                        "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${customColorArray?.primaryColor}` }, // Styles the fieldset
                                        "&:hover .MuiOutlinedInput-notchedOutline": { // Changed selector to include hover state
                                            borderColor: `${customColorArray?.primaryColor}`,
                                        }
                                    }}
                                />
                                <FormHelperText style={{ color: 'red',backgroundColor: '#ffffffad',paddingLeft:'3px' }}>
                                    {touched.firstName && errors.firstName}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center" }}>
                            <FormControl variant="outlined" fullWidth
                                margin="dense" size="small">
                                <InputLabel sx={{color:customColorArray.primaryColor}}>Middle Name</InputLabel>
                                <OutlinedInput
                                    id="middleName"
                                    name="middleName"
                                    label="Middle Name"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    autoComplete="middleName"
                                    margin="dense"
                                    className='cobrandTextField'
                                    error={touched.middleName && !!errors.middleName}
                                    value={values.middleName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    sx={{
                                        "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${customColorArray?.primaryColor}` }, // Styles the fieldset
                                        "&:hover .MuiOutlinedInput-notchedOutline": { // Changed selector to include hover state
                                            borderColor: `${customColorArray?.primaryColor}`,
                                        }
                                    }}
                                />
                                <FormHelperText style={{ color: 'red',backgroundColor: '#ffffffad',paddingLeft:'3px' }}>
                                    {touched.middleName && errors.middleName}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center" }}>
                            <FormControl variant="outlined" fullWidth
                                margin="dense" size="small">
                                <InputLabel sx={{color:customColorArray.primaryColor}}>Last Name*</InputLabel>
                                <OutlinedInput
                                    id="lastName"
                                    name="lastName"
                                    label="Last Name*"
                                    autoComplete="lastName"
                                    className='cobrandTextField'
                                    error={touched.lastName && !!errors.lastName}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                            >
                                                {touched.lastName && !errors.lastName ? (
                                                    <CheckIcon style={{ color: 'green' }} />
                                                ) : (
                                                    touched.lastName && errors.lastName ? (
                                                        <CloseIcon style={{ color: 'red' }} />
                                                    ) : null
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    value={values.lastName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    sx={{
                                        "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${customColorArray?.primaryColor}` }, // Styles the fieldset
                                        "&:hover .MuiOutlinedInput-notchedOutline": { // Changed selector to include hover state
                                            borderColor: `${customColorArray?.primaryColor}`,
                                        }
                                    }}
                                />
                                <FormHelperText style={{ color: 'red',backgroundColor: '#ffffffad',paddingLeft:'3px' }}>
                                    {touched.lastName && errors.lastName}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center" }}>
                            <FormControl variant="outlined" fullWidth
                                margin="dense" size="small">
                                <InputLabel sx={{color:customColorArray.primaryColor}}>Email*</InputLabel>
                                <OutlinedInput
                                    id="email"
                                    name="email"
                                    autoComplete="email"
                                    label="Email*"
                                    className='cobrandTextField'
                                    error={touched.email && !!errors.email}
                                    // value={values.email}
                                    defaultValue={values.email}
                                    // onChange={handleChange}
                                    onChange={(e)=>{
                                        checkUsaTLD(e.target.value);   
                                        handleChange('email');
                                        setFieldValue('email', e.target.value, true)
                                    }}
                                    onBlur={handleBlur}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                            >
                                                {(touched.email && !errors.email) &&  checkUsaTldStatus == false ? (
                                                    <CheckIcon style={{ color: 'green' }} />
                                                ) : (
                                                    (touched.email && errors.email) || checkUsaTldStatus == true ? (
                                                        <CloseIcon style={{ color: 'red' }} />
                                                    ) : null
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    sx={{
                                        "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${customColorArray?.primaryColor}` }, // Styles the fieldset
                                        "&:hover .MuiOutlinedInput-notchedOutline": { // Changed selector to include hover state
                                            borderColor: `${customColorArray?.primaryColor}`,
                                        }
                                    }}
                                />
                                <FormHelperText style={{ color: 'red' ,backgroundColor: '#ffffffad',paddingLeft:'3px'}}>
                                    {touched.email && errors.email ?errors.email : checkUsaTldStatus ? "Invalid email format." : '' }
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center" }}>
                            <FormControl variant="outlined" size="small" fullWidth margin="dense">
                                <InputLabel htmlFor="outlined-adornment-password" sx={{color:customColorArray.primaryColor}}>Password*</InputLabel>
                                <OutlinedInput
                                    id="password"
                                    name="password"
                                    label="Password*"
                                    autoComplete="password"
                                    error={touched.password && !!errors.password}
                                    defaultValue={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                            >
                                                {touched.password && !errors.password ? (
                                                    <CheckIcon style={{ color: 'green' }} />
                                                ) : (
                                                    touched.password && errors.password ? (
                                                        <CloseIcon style={{ color: 'red' }} />
                                                    ) : null
                                                )}
                                            </IconButton>
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    className='cobrandTextField'
                                    sx={{
                                        "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${customColorArray?.primaryColor}` }, // Styles the fieldset
                                        "&:hover .MuiOutlinedInput-notchedOutline": { // Changed selector to include hover state
                                            borderColor: `${customColorArray?.primaryColor}`,
                                        }
                                    }}
                                />
                                <FormHelperText style={{ color: 'red' ,backgroundColor: '#ffffffad',paddingLeft:'3px'}}>
                                    {touched.password && errors.password}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                {errorfromcd ? <Typography sx={{ fontWeight: "bold", color: "red",backgroundColor: '#ffffffad',paddingLeft:'3px' }}>
                                    Error :- {errorfromcd === "An account already exists with the email you entered. Please login to view your credit report and scores." ?
                                        <>
                                            An account already exists with the email you entered. Please <a href={loginLink} target="_blank">login</a> to view your credit report and scores.
                                        </>
                                        : errorfromcd}
                                </Typography>
                                    : ""}
                            </Grid> 
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} sx={{ justifyContent: "right", display: "flex" }}>
                            <Button type="submit" disabled={isButtonDisabled} sx={{
                                minWidth: 150, backgroundColor: `${customColorArray?.buttonColor}`, color: `${customColorArray?.buttonTextColor}`, borderRadius: "0%", '&:hover': {
                                    backgroundColor: `${customColorArray?.buttonColor}`
                                }
                            }}>NEXT STEP</Button>
                        </Grid>
                        <SecureWebsite textColor={textColor} hyperlinkColor={customColorArray.hyperlinkColor}/>
                    </Form>
                )}
            </Formik>
        </Box>
        <Box>
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{display:"flex",justifyContent:"flex-end",mt:isMobile?4:0}}>
            {
                chatEnable  ?
                    <Grid item lg={4} md={4} sm={12} xs={12} mt={1} sx={{ display:"flex",justifyContent:"flex-end",marginTop:isMobile?2:0 }}>
                    <Button varient="outlined"
                        onClick={openChatPopup}
                        // disabled={isChatOpen}
                    startIcon={<ChatIcon style={{ width: 30, height: 30, fill: `${customColorArray?.buttonTextColor}` }} />}
                    // sx={{
                    //     textTransform: "none !important",
                    //     border:"1px solid #2E67BC",
                    //     minWidth:'80%' ,
                    //     maxWidth:'100%',
                    //     color:"white",
                    //     alignItems:"center",
                    //     fontWeight:"bold"// Prevents uppercase transformation
                    // }}
                    sx={{
                        textTransform: "none !important",minWidth:'80%',maxWidth:'100%', backgroundColor: `${customColorArray?.buttonColor}`, color: `${customColorArray?.buttonTextColor}`, borderRadius: "10px", '&:hover': {
                            backgroundColor: `${customColorArray?.buttonColor}`
                        }
                    }}
                    >Chat With Us Now</Button>
                    </Grid>
                : ''
            }
            {
                emailUsEnable ?
                    <Grid item lg={4} md={4} sm={12} xs={12}  mt={1} sx={{ display:"flex",justifyContent:"flex-end",marginTop:isMobile?2:0 }}>
                        <Button  
                        startIcon={<EmailIcon style={{ width: 30, height: 30, fill: `${customColorArray?.buttonTextColor}` }} />}
                        onClick={openEmailChatPopup}
                        // sx={{
                        // textTransform: "none",
                        // border:"1px solid #2E67BC",
                        // minWidth:'80%' ,
                        // maxWidth:'100%',
                        // color:"white",
                        // alignItems:"center",
                        // fontWeight:"bold" // Prevents uppercase transformation
                        // }}
                        sx={{
                            textTransform: "none !important",minWidth:'80%',maxWidth:'100%', backgroundColor: `${customColorArray?.buttonColor}`, color: `${customColorArray?.buttonTextColor}`, borderRadius: "10px", '&:hover': {
                                backgroundColor: `${customColorArray?.buttonColor}`
                            }
                        }}
                        >Email Us Now</Button>
                    </Grid>
                : ''}

                {step1Link ?( <> 
                    <Grid item lg={4} md={4} sm={12} xs={12} mt={1} sx={{ display:"flex",justifyContent:"center",marginTop:isMobile?2:0 }}>
                        <Button onClick={handleIFrame}
                        startIcon={<PlayIcon style={{ width: 30, height: 30, fill: `${customColorArray?.buttonTextColor}` }} />}
                        // sx={{
                        //     textTransform: "none",
                        //     border:"1px solid #2E67BC", 
                        //     minWidth:'80%' ,
                        //     maxWidth:'100%',
                        //     alignItems:"center",
                        //     color:"white",
                        //     fontWeight:"bold"
                        // }}

                        sx={{
                            textTransform: "none !important",minWidth:'80%',maxWidth:'100%', backgroundColor: `${customColorArray?.buttonColor}`, color:`${customColorArray?.buttonTextColor}` , borderRadius: "10px", '&:hover': {
                                backgroundColor: `${customColorArray?.buttonColor}`
                            }
                        }}
                        
                        >Learn More About Us
                        </Button>
                    </Grid>
                </>): ""} 
            </Grid>
        </Box>
        
            <Chat mEmail={null} firstName={null} isMinimized={isMinimized} changeOpenChatPopupValue={changeOpenChatPopupValue} openEmailChatPopup={openEmailChatPopup} companyId={companyId} source="cobrand"/>
          
            {/* Chat functionality started */}
            {isChatOpen && (
                    <Box
                        sx={{
                            position: "fixed",
                            bottom: "2%",
                            right: "2%",
                            cursor: "pointer",
                        }}
                        onClick={openChatPopup}
                        >
                        <img
                            src={require("../../assets/images/chatforbutton.png")}
                            alt="Chat Icon"
                            style={{
                            width: "70px",
                            height: "70px",
                            borderRadius: "50%",
                            boxShadow:
                                "0px 4px 8px rgba(0, 0, 0, 0.2), 0px 8px 16px rgba(0, 0, 0, 0.19)",
                            }}
                        />
                        </Box>
                        )}
             {/* Chat functionality ended here */}

             <Formik
            initialValues={{
            subject: '',
            description : '',
            email : ''
            }}
            validationSchema={sendEmailChatSchema}
            onSubmit={async (values, { resetForm }) => {
            handleFreezStart();
            http.post("/sendChatEmail", {
                email : values.email,
                subject : values.subject,
                description : values.description,
                companyId : companyId,
                source : "cobrand"
            })
            .then((res) => {
                if (res.data.success === true) {
                swal({
                    title: "Success",
                    text: res.data.message,
                    icon: "success",
                    button: "Ok",
                });
                handleFreezStop();
                }else{
                // alert("something went wrong");
                handleFreezStop();
                }
            })
            closeEmailChatPopup();
            resetForm();
            }}
        >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <Dialog
                open={emailChatopen}
                onClose={(_, reason) => {
                if (reason !== "backdropClick") {
                    // setDeleteOpen(false)
                }
                }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {/* Delete Pid */}
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{justifyContent:"space-between"}}>
                <Grid item lg={10} md={10} sm={10} xs={10}>
                    <Typography level="h5" sx={{textAlign:"center"}}>Send Us The Email</Typography>
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={2} sx={{textAlign:"right"}}>
                    <CloseIcon  onClick={closeEmailChatPopup}/>
                </Grid>
                </Grid>
            </DialogTitle>
            <DialogActions >
            <Grid container item lg={12} md={12} sm={12} xs={12} >
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                        id="outlined-start-adornment"
                        type="text"
                        label="From Email Address"
                        fullWidth
                        variant="outlined"
                        size="small"
                        margin="dense"
                        autoComplete="email"
                        value={values.email}
                        onChange={handleChange('email')}
                        onBlur={handleBlur('email')} 
                        InputLabelProps={{
                            shrink: Boolean(values.email), 
                        }}
                        />
                        {touched.email && errors.email ? <div className="error">{errors.email}</div> : null}
                    </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                    id="outlined-start-adornment"
                    type="text"
                    label="Subject"
                    fullWidth
                    variant="outlined"
                    size="small"
                    margin='dense'
                    autoComplete='subject'
                    value={values.subject}
                    onChange={handleChange('subject')}
                    onBlur={handleBlur('subject')}
                    InputLabelProps={{
                        shrink: Boolean(values.subject)
                    }}
                    /> 
                    {touched.subject && errors.subject ? <div className='error'>{errors.subject}</div> : null}
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <TextareaAutosize
                        placeholder="Message"
                        type="text"
                        size="small"
                        fullWidth
                        name='description'
                        autoComplete='description'
                        id='description'
                        onChange={handleChange('description')}
                        onBlur={handleBlur('description')}
                        value={values.description}
                        maxRows={7}
                        minRows={4}
                        
                        style={{
                        width: '100%', // Ensures full width
                        // padding: '8px', // Optional for better spacing
                        boxSizing: 'border-box', // Ensures padding doesn't affect width
                    }}
                    />
                    {touched.description && errors.description ? <div className='error'>{errors.description}</div> : null}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} mt={2} sx={{ justifyContent: "center", display: "flex"}}>
                <Button sx={{
                                minWidth: 150, backgroundColor: `${customColorArray?.primaryColor}`, color: btnTextColor, '&:hover': {
                                    backgroundColor: `${customColorArray?.primaryColor}`
                                }
                            }}
                    onClick={handleSubmit}
                    >
                    Send
                </Button>
                </Grid>
            </Grid>
            </DialogActions>
            </Dialog>
        )}
        </Formik>

    {/* learn more about us popup start here */}
    {/* Iframe Dialog Start */}
            <Dialog open={openIframe}
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleCloseIFrame();
                    }
                }}
                fullWidth={fullWidth}
                maxWidth="md"
                >
                <DialogTitle sx={{ bgcolor: "#F6FAFF" }}>
                    <Grid container item xs sx={{ justifyContent: "space-between" }}>
                        <Grid item xs>
                            <Typography level="h4" color="primary">Learn More About Us</Typography>
                        </Grid>
                        <Grid item xs sx={{ textAlign: "right" }}>
                            <IconButton
                                aria-label="close"
                                onClick={handleCloseIFrame}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} sx={{ justifyContent : "center" }}>
                        <iframe
                            width="560"
                            height="315"
                            src={step1Link}
                            title="Video Player"
                            // frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </Grid>
                </DialogContent>
                {/* </DialogActions> */}
            </Dialog>
            {/* Iframe Dialog End */}
    {/* learn more about us popup end here */}
            {/* loader code start */}
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}
        </>
    )
}
export default NewEnrollment1;