import React, { useMemo, useState,useEffect } from 'react';
import MFSNImage from '../../assets/images/MFSN_with_Zapier_2.png';
import ZapierLogin from '../../assets/images/Zapier-Login.png';
import CreateZAP from '../../assets/images/Create-ZAP.png';
import SelectApp from '../../assets/images/Select-App.png';
import Authentication from '../../assets/images/Authentication.png';
import TestAuthetication from '../../assets/images/Test-Authetication.png';
import ZapierAction from '../../assets/images/Zapier-Action.png';
import ZapierAction1 from '../../assets/images/Zapier-Action1.png';
import ZapierConfiguration from '../../assets/images/Zapier-Configuration.png';
import ZapierPublish from '../../assets/images/Zapier-Publish.png';
function ApiZapier(props) {

    
    const apiData = [
        {
            "id": "1",
            "title": "Fetch Active Member List",
            "description": "This action is used to fetch data of members who have completed the enrollment process and have an ACTIVE account status",
            "response": {
                "success": true,
                "data": [
                    {
                        "aid": "363128HD",
                        "name": "Test-LNCAA, test",
                        "email": "user1@gmail.com",
                        "date_range_filter": "2024-11-21 05:10:16",
                        "account_status": "ACTIVE",
                        "member_id": 6353297,
                        "street_address": null,
                        "city": "Bothell",
                        "state": "WA",
                        "zip": 98011,
                        "plan": "Premium",
                        "publisher_id": 17,
                        "phone_number": "",
                        "signup_reports": {
                            "email": "user1@gmail.com",
                            "password": "VGVzdGluZ0AxMjM0"
                        },
                        "software_enroll_client_for_member_list": null,
                        "free_member": null
                    },
                    {
                        "aid": "363128E1",
                        "name": "Test-LNCAA, test",
                        "email": "user2@gmail.com",
                        "date_range_filter": "2024-11-21 01:42:42",
                        "account_status": "ACTIVE",
                        "member_id": 6353229,
                        "street_address": null,
                        "city": "Bothell",
                        "state": "WA",
                        "zip": 98011,
                        "plan": "Premium",
                        "publisher_id": 17,
                        "phone_number": "",
                        "signup_reports": {
                            "email": "user2@gmail.com",
                            "password": null
                        },
                        "software_enroll_client_for_member_list": null,
                        "free_member": null
                    }
                ]
            }
        },
        {
            "id": "2",
            "title": "Fetch Paused Member List",
           "description": "This action is used to fetch data of members who have completed the enrollment process and have a CLOSED or SUSPENDED account status",
            "response": {
                    "success": true,
                    "data":[ 
                        {
                            "aid": "363128FT",
                            "name": "Test-KNCAA, Donald",
                            "email": "user1@gmail.com",
                            "date_range_filter": "2024-07-29 03:47:06",
                            "account_status": "CLOSED",
                            "member_id": 6098509,
                            "street_address": null,
                            "city": "Bothell",
                            "state": "WA",
                            "zip": 98011,
                            "plan": "Sponsored",
                            "publisher_id": 81586,
                            "phone_number": "5555551222",
                            "signup_reports": {
                                "email": "user1@gmail.com",
                                "password": "VGVzdGluZ0AxMjM="
                            },
                            "software_enroll_client_for_member_list": null,
                            "free_member": null
                        },
                        {
                            "aid": "363128FT",
                            "name": "Test-JYEWR, testb2s",
                            "email": "user2@gmail.com",
                            "date_range_filter": "2024-05-29 06:44:38",
                            "account_status": "CLOSED",
                            "member_id": 6051285,
                            "street_address": null,
                            "city": "Schenectady",
                            "state": "NY",
                            "zip": 12345,
                            "plan": "Sponsored",
                            "publisher_id": 81586,
                            "phone_number": "",
                            "signup_reports": {
                                "email": "user2@gmail.com",
                                "password": "VGVzdGluZ0AxMjM0"
                            },
                            "software_enroll_client_for_member_list": null,
                            "free_member": null
                        }
                    ]
                }
        },
        {
            "id": "3",
            "title": "Fetch Enrolled Snapshot Leads",
            "description": "This action is used to fetch data of members who have completed the Company's CreditSnapshot registration process through their Credit Snapshot link to view their respective Credit Scores",
            "response": {
                "success": true,
                "data": {
                    "enrolledMembers": [
                        {
                            "aid": "363128HD",
                            "first_name": "Donald",
                            "last_name": "Grupp",
                            "email": "user1@gmail.com",
                            "mobile": "8917667882",
                            "ssn": "666296598",
                            "dob": "1972-07-02",
                            "street_address": "6939 W GEORGE ST",
                            "city": "Bothell",
                            "state": "WA",
                            "zip": "98011",
                            "signup_reports": null
                        },
                        {
                            "aid": "363128HD",
                            "first_name": "s",
                            "last_name": "s",
                            "email": "user2@gmail.com",
                            "mobile": "8917667882",
                            "ssn": "666296598",
                            "dob": "1972-07-02",
                            "street_address": "test5",
                            "city": "bbsr",
                            "state": "AK",
                            "zip": "98011",
                            "signup_reports": null
                        }
                    ]
                }
            }
        },
        {
            "id": "4",
            "title": "Fetch Upgraded Snapshot Leads",
            "description": "This action is used to fetch data of members who have completed the Company's CreditSnapshot registration process through their Credit Snapshot link to view their respective Credit Scores and also those have upgraded to 3 bureau reports",
            "response": {
                "success": true,
                "data": {
                    "upgradedMembers": [
                        {
                            "aid": "363128E1",
                            "first_name": "Test",
                            "last_name": "Test-ZQWJE",
                            "email": "user6@gmail.com",
                            "mobile": "8275187271",
                            "ssn": "666296598",
                            "dob": "1985-06-19",
                            "street_address": "123 main street",
                            "city": "westlake village",
                            "state": "CA",
                            "zip": "91361",
                            "signup_reports": {
                                "first_name": "Eve",
                                "last_name": "Cornelius",
                                "street_address": "31735 bainbrook ct",
                                "zip": "91361",
                                "city": "westlake village",
                                "state": "CA",
                                "email": "user6@gmail.com",
                                "phone_number": "(818) 854-4635",
                                "dob": "07/31/1999",
                                "date_time": "2019-06-17 21:10:38",
                                "completed": "True"
                            }
                        },
                        {
                            "aid": "363128HD",
                            "first_name": "Donald",
                            "last_name": "Grupp",
                            "email": "user7@gmail.com",
                            "mobile": "7204984121",
                            "ssn": "666296598",
                            "dob": "1972-07-02",
                            "street_address": "6939 W GEORGE ST",
                            "city": "BOTHELL",
                            "state": "WA",
                            "zip": "98011",
                            "signup_reports": {
                                "first_name": "Donald",
                                "last_name": "Test-KNCAA",
                                "street_address": "6939 W GEORGE ST",
                                "zip": "98011",
                                "city": "BOTHELL",
                                "state": "WA",
                                "email": "user7@gmail.com",
                                "phone_number": "7204984121",
                                "dob": "07/02/1972",
                                "date_time": null,
                                "completed": "True"
                            }
                        }
                    ]
                }
            }
        },
        {
            "id": "5",
            "title": "Fetch Referred Affiliate List",
            "description": "This action is used to fetch those Referred Affiliates List who have completed the Affiliate Registration process through their respective Affiliate's Referral link",
            "response": {
                "success": true,
                "data": {
                    "id": 12654,
                    "event_by": 10093,
                    "company_name": "MicrosoftAds",
                    "company_website": null,
                    "title": null,
                    "business_started_year": null,
                    "marketing_type": "DisputeFox",
                    "company_logo_url": "1730958800.png",
                    "referral_code": "0UuRtDDC",
                    "referred_by": "0UuRtDDC",
                    "referral_pandadoc_id": "",
                    "referral_enrolled_date": "2023-03-17",
                    "referral_completion_date": "2023-03-17",
                    "referral_pandadoc_expired": null,
                    "resend_pandadoc_id": null,
                    "resend_date": null,
                    "resend_pandadoc_expired": null,
                    "report_delivery": null,
                    "referral_flag": 1,
                    "referral_terms_and_conditions": 1,
                    "terms_and_conditions_accepted_date": "2024-03-08",
                    "snapshot_flag": 1,
                    "company_status": 1,
                    "epic_pro_status": 1,
                    "is_disabled": 0,
                    "notification_flag": 1,
                    "created_at": "2022-05-10T05:53:08.000000Z",
                    "updated_at": "2024-09-09T13:12:03.000000Z",
                    "deleted_at": null,
                    "updated_at_old": "2024-04-04 01:57:35",
                    "executive": "",
                    "company_master_id": 12654,
                    "first_name": "Dev",
                    "last_name": "Test",
                    "email": "microsoftads@gmail.com",
                    "communication_email": "microsoftads@gmail.com",
                    "phone_no": "8885482008",
                    "address": null,
                    "city": null,
                    "state_code": null,
                    "zip_code": null,
                    "agreement_completion_date": null,
                    "pandadoc_id": "",
                    "reference_affiliate_status_master_id": 0,
                    "email_sent": null,
                    "email_sent_time": null,
                    "email_verified": null,
                    "approved_by": null,
                    "approved_date": "2022-05-09 18:41:56",
                    "completed_by": null,
                    "completed_reason": null,
                    "completed_date": null,
                    "pandadoc_version": null,
                    "next_followup": null,
                    "is_active": 1,
                    "disabled_by": null,
                    "disabled_reason": null,
                    "restored_by": null,
                    "is_delete": 0,
                    "is_deferred": 0,
                    "deferred_by": null,
                    "deferred_reason": null,
                    "epic_challenge_status": 0,
                    "deferred_till": null,
                    "active_lead_flag": 0,
                    "assigned_to": null,
                    "first_signer_date": null,
                    "second_signer_date": null,
                    "bounce_email_flag": 1,
                    "is_test": null,
                    "oldupdated_at": "2023-09-06 23:00:34",
                    "link": "referenceAffiliateRequest/MFV1UnREREM=",
                    "refferedCompany": [
                        {
                            "first_name": "Test",
                            "last_name": "Test",
                            "email": "googleads@gmail.com",
                            "phone_no": "8885482008",
                            "company_name": "GoogleAds",
                            "company_website": null,
                            "title": null,
                            "business_started_year": null,
                            "referral_enrolled_date": "2023-03-17",
                            "referralCount": 0,
                            "activeEnrolledCount": 0
                        }
                    ]
                }
            }
        },
        {
            "id": "6",
            "title": "Fetch Suspended Members",
            "description": "This action is used to fetch data of members who have completed the enrollment process and have a SUSPENDED account status due to non-payment of their dues by the due date",
            "response": {
                "success": true,
                "data": [
                    {
                        "member_id": 2307225,
                        "aid": "363128E1",
                        "publisher_id": 20048,
                        "account_status": "SUSPENDED",
                        "name": "Bryant, Cassundra",
                        "email": "cassundra26@yahoo.com",
                        "street_address": null,
                        "city": "HOUSTON",
                        "state": "TX",
                        "zip": 77045,
                        "plan": "Premium",
                        "phone_number": "832483",
                        "agentName": "N/A",
                        "updated_at": "2023-07-31T18:33:31.000000Z",
                        "current_time": "5:02 AM",
                        "current_hours": "05"
                    }
                ]
            }
        },
        {
            "id": "7",
            "title": "Fetch Pending Closed Members",
            // "description": "This API is used get the pending closed members data",
           "description": "This action is used to fetch data of members who have completed the enrollment process but have not fully closed their accounts (i.e., their accounts are yet to be permanently closed).",
            "response": {
                "success": true,
                "data": {
                    "MemberData": [
                        {
                            "PID": "00017",
                            "AID": "seo",
                            "plan": "Premium",
                            "name": "test test",
                            "phone_no": "888888888",
                            "email": "user10@gmail.com",
                            "subscription_date": "2024-05-24 09:55:46",
                            "current_time": "6:06 AM",
                            "current_hours": "06",
                            "user": null
                        }
                    ]
                }
            }
        }
    ];

    const apiData1 = [
      {
          "id": "1",
          "title": "Login",
          "description": "Go to Zapier Login :   https://zapier.com/app/login",
          "img":ZapierLogin,
      },
      {
          "id": "2",
          "title": "Create a ZAP",
         "description": " In your Zapier account, hover over the left sidebar menu and click Create a Zap.",
          "img":CreateZAP,
      },
      {
          "id": "3",
          "title": "Select MyFreeScoreNow",
          "description": "When you open the Zap editor, you'll be prompted to pick an app as your trigger. In that Select MyFreeScoreNow  App",
          "img":SelectApp,
      },
      {
          "id": "4",
          "title": "Authentication",
          "description": "Next, you'll be asked to choose a trigger event. This is the event that happens in your trigger app that signals Zapier to start your Zap.Click on New Authentication.And in Account SignIn with MFSN login",
          "img":Authentication,
      },
      {
          "id": "5",
          "title": "Test Authentication",
          "description": "Once you've set up your trigger, you need to test it to ensure it works. When you click Test trigger, Zapier will look for data that already exists in your trigger app account and pull that information into the Zap editor",
          "img":TestAuthetication,
      },
      {
          "id": "6",
          "title": "Setup Action",
          "description": "Just like you did with your trigger, select your action app—this time by clicking the dropdown menu and searching for your app. Once you've selected your app, choose your action event—which is what you want your Zap to do—in the second dropdown menu.",
          "img":ZapierAction,
      },
      {
          "id": "7",
          "title": "Create another Action",
          "description": "Select the Action App you want to perform. For example. If you want to pass data on mail than select App as Google. And login with your gmail account.",
          "img":ZapierAction1,
      },
      {
        "id": "8",
        "title": "Configuration",
        "description": "Add configuration  Step and click on Continue.And than click on Test Step and check if records are pass in Sheet",
        "img":ZapierConfiguration,
      },
      {
        "id": "9",
        "title": "Publish ZAP",
        "description": "Then publish the Zap",
        "img":ZapierPublish,
      }
  ];

  const [selectedCategory, setSelectedCategory] = useState("MFSN"); // Tracks the active category
  const [selectedApi, setSelectedApi] = useState(apiData1[0] || null);
  
  const handleItemClick = (item, category) => {
    // Set the selected category when a sub-item is clicked directly
    if (selectedCategory !== category) {
      setSelectedCategory(category);
    }
    setSelectedApi(item); // Update the selected API based on the clicked item
  };
  
  return (
    <div style={{ fontFamily: "Arial, sans-serif", marginTop: "20px" }}>
      <div style={{ textAlign: "center" }}>
        <img src={MFSNImage} alt="MFSN with Zapier" />
      </div>
  
      <div style={{ display: "flex", marginTop: "20px" }}>
        {/* Sidebar */}
        <div
          style={{
            width: "25%",
            borderRight: "1px solid #ddd",
            padding: "20px",
            background: "#F6FAFF",
          }}
        >
          {/* First Heading with Subheadings */}
          <div>
            <h3
              style={{
                cursor: "pointer",
                color: selectedCategory === "MFSN" ? "#007bff" : "#000",
              }}
              onClick={() => {
                setSelectedApi(apiData1[0]); // Show the first API item in details
                setSelectedCategory("MFSN");
              }}
            >
              Zapier Integration with MFSN
            </h3>
            <ul style={{ listStyle: "none", padding: 0 }}>
              {apiData1.map((item) => (
                <li key={item.id} style={{ marginBottom: "10px" }}>
                  <button
                    style={{
                      background: "none",
                      border: "none",
                      padding: "10px 0",
                      color:
                        selectedCategory === "MFSN" && selectedApi?.id === item.id
                          ? "#007bff"
                          : "#000",
                      cursor: "pointer",
                      fontWeight:
                        selectedCategory === "MFSN" &&
                        selectedApi?.id === item.id
                          ? "bold"
                          : "normal",
                      textAlign: "left",
                      width: "100%",
                    }}
                    onClick={() => handleItemClick(item, "MFSN")}
                  >
                    {item.title}
                  </button>
                  {item.subApis && (
                    <ul style={{ paddingLeft: "20px", marginTop: "5px" }}>
                      {item.subApis.map((subItem) => (
                        <li key={subItem.id}>
                          <button
                            style={{
                              background: "none",
                              border: "none",
                              padding: "5px 0",
                              color:
                                selectedCategory === "MFSN" &&
                                selectedApi?.id === subItem.id
                                  ? "#007bff"
                                  : "#000",
                              cursor: "pointer",
                              textAlign: "left",
                              fontSize: "14px",
                            }}
                            onClick={() => handleItemClick(subItem, "MFSN")}
                          >
                            {subItem.title}
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
  
          {/* Second Heading with Subheadings */}
          <div>
            <h3
              style={{
                cursor: "pointer",
                color: selectedCategory === "Zapier" ? "#007bff" : "#000",
              }}
              onClick={() => {
                setSelectedApi(apiData[0]); // Show the first API item in details
                setSelectedCategory("Zapier");
              }}
            >
              Zapier Integrated API
            </h3>
            <ul style={{ listStyle: "none", padding: 0 }}>
              {apiData.map((item) => (
                <li key={item.id} style={{ marginBottom: "10px" }}>
                  <button
                    style={{
                      background: "none",
                      border: "none",
                      padding: "10px 0",
                      color:
                        selectedCategory === "Zapier" &&
                        selectedApi?.id === item.id
                          ? "#007bff"
                          : "#000",
                      cursor: "pointer",
                      fontWeight:
                        selectedCategory === "Zapier" &&
                        selectedApi?.id === item.id
                          ? "bold"
                          : "normal",
                      textAlign: "left",
                      width: "100%",
                    }}
                    onClick={() => handleItemClick(item, "Zapier")}
                  >
                    {item.title}
                  </button>
                  {item.subApis && (
                    <ul style={{ paddingLeft: "20px", marginTop: "5px" }}>
                      {item.subApis.map((subItem) => (
                        <li key={subItem.id}>
                          <button
                            style={{
                              background: "none",
                              border: "none",
                              padding: "5px 0",
                              color:
                                selectedCategory === "Zapier" &&
                                selectedApi?.id === subItem.id
                                  ? "#007bff"
                                  : "#000",
                              cursor: "pointer",
                              textAlign: "left",
                              fontSize: "14px",
                            }}
                            onClick={() => handleItemClick(subItem, "Zapier")}
                          >
                            {subItem.title}
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
  
        {/* API Details */}
        <div
          style={{
            width: "75%",
            padding: "20px",
            background: "#ffffff",
            borderRadius: "8px",
            marginLeft: "20px",
          }}
        >
          {selectedApi ? (
            <>
              <h2>{selectedApi.title}</h2>
              <p>{selectedApi.description}</p>
              {selectedApi.img && <img src={selectedApi.img} alt={selectedApi.title} style={{ width: "100%",  objectFit: "cover" }} />}
              {selectedApi.queryParams && selectedApi.queryParams.length > 0 && (
                <>
                  <h4>Query Parameters</h4>
                  <ul>
                    {selectedApi.queryParams.map((param) => (
                      <li key={param.name}>
                        <code>{param.name}</code>{" "}
                        <span
                          style={{
                            color: param.required ? "red" : "green",
                          }}
                        >
                          {param.required ? "required" : "optional"}
                        </span>{" "}
                        — {param.description}
                      </li>
                    ))}
                  </ul>
                </>
              )}
  
              {selectedApi.headers && selectedApi.headers.length > 0 && (
                <>
                  <h4>Headers</h4>
                  <ul>
                    {selectedApi.headers.map((header) => (
                      <li key={header.name}>
                        <code>{header.name}</code>{" "}
                        <span
                          style={{
                            color: header.required ? "red" : "green",
                          }}
                        >
                          {header.required ? "required" : "optional"}
                        </span>{" "}
                        — {header.description}
                      </li>
                    ))}
                  </ul>
                </>
              )}
  
              {selectedApi.response && (
                <>
                  <h4>Response</h4>
                  <pre
                    style={{
                      background: "#f8f9fa",
                      padding: "10px",
                      border: "1px solid #ddd",
                      borderRadius: "4px",
                      maxHeight: "300px",
                      overflow: "auto",
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                    }}
                  >
                    {JSON.stringify(selectedApi.response, null, 2)}
                  </pre>
                </>
              )}
            </>
          ) : (
            <p>Please select an API to view its details.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default ApiZapier;