import React, { useState, useEffect, useRef } from "react";
import {Grid, IconButton, Dialog, DialogTitle, DialogContent} from "@mui/material";
import Typography from '@mui/joy/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { fetchData } from '../../redux/apiSlice';
import Moment from 'moment';
import StarIcon from '@mui/icons-material/StarRate';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Reviews = ({filter,handleFreezStart,handleFreezStop }) => {
    const dispatch = useDispatch();
    const [reviewsList, setReviwsList] = useState([]);
    const [totalReviwsList, setTotalReviwsList] = useState([]);
    const [openSlider, setOpenSlider] = React.useState(false);
    const[index,setIndex]= useState(0);
    const [reviewFlag, setReviewFlag] = useState(0);
    const sliderRef = useRef(null);

    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "80px",
        slidesToShow: 1,
        speed: 500,
        lazyLoad: 'ondemand',
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: "0px",
                }
            }
        ]
    };


    useEffect(() => {
            fetchReviewsList();
    }, []);

    const goToFirstSlide=()=>{
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(0);
            setIndex(0);
            }
    }

    const fetchReviewsList = async () => {
        if(handleFreezStart != null) {
            handleFreezStart();
        }
        const apiUrl = `/reviews-data/1/10/${filter}`;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            setReviwsList(res.data.reviews.reviews);
            setTotalReviwsList(res.data.reviews.reviews);
            if(filter != 'all' && handleFreezStop != null) {
                handleFreezStop();
            }
            const url = `/reviews-data/1/${res.data.reviews.total_results}/${filter}`;
            const resp = await dispatch(fetchData(url)).unwrap();
            if (resp.status == "success") {
                setTotalReviwsList(resp.data.reviews.reviews);
                setReviewFlag(1)
            } else {
                setReviewFlag(1)
            }
        }

    };

    const handleClickOpen1 = (index) => {
        setOpenSlider(true);
        setIndex(index)
    };

    const handleClickClose = () => {
        setOpenSlider(false);
    };

    return (
            <>
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <div className="marquee-container">
                        <marquee behavior="scroll" direction="left" scrollamount="4" loop="infinite">
                            {reviewsList.map((item, index) => (
                                <div onClick={()=>handleClickOpen1(index)} className='card' key={index}>
                                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                                        <Grid item lg={3} md={3} sm={3} xs={3}>
                                            <div className="profile">
                                            <Typography level="h4" color='primary'>{(item.displayname).charAt(0)}</Typography></div>
                                        </Grid>
                                        <Grid item lg={9} md={9} sm={9} xs={9}>
                                            <div className='wrapper-profil-info'>
                                            <Typography level="h6">{item.displayname}</Typography>
                                            <Typography level="body1">{Moment(item.date).format('MM-DD-YYYY')}</Typography>
                                            </div>
                                            {[...new Array(item.rating)].map(_ => <StarIcon sx={{ color: "#76C044" }} />)}
                                            <Typography level="body1" className="text-start" sx={{textWrap:'auto'}}>{(item.review && item.review.trim() !== '') ? (item.review.slice(0, 55) + "...") : (item.settings && item.settings.placeholder_text && (item.settings.placeholder_text).slice(0, 25) + "..." )}</Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                            ))}
                        </marquee>
                    </div>
                </Grid>
                <Dialog
                    onClose={(_, reason) => {
                        if (reason !== "backdropClick") {
                            handleClickClose();
                        }
                    }}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    fullWidth
                    maxWidth={'md'}
                    open={openSlider}
                    className='slider-popup'
                >
                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                        {/* Modal title */}
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClickClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent>
                        <div className="slider-container agentSlider">
                            <button onClick={goToFirstSlide} className='back-button'>Go To First Review</button>
                            <Slider ref={sliderRef} {...settings} initialSlide={index}>
                                {totalReviwsList.map((item, index) => (
                                    <div key={index} className="slider-card">
                                        <div className='card-slider-inner-block'>
                                            <div style={{ display: 'flex' }}>
                                                <div className="profile">
                                                    <Typography level="h4" color='primary'>{(item.displayname).charAt(0)}</Typography>
                                                </div>
                                                <div className='profile-info'>
                                                    <Typography level="h6">{item.displayname}</Typography>
                                                    {[...new Array(item.rating)].map(_ => <StarIcon sx={{ color: "#76C044" }} />)}
                                                    <Typography level="body1">{Moment(item.date).format('MM-DD-YYYY')}</Typography>
                                                </div>
                                            </div>
                                            <div className='reviews-block'>
                                                <Typography level="body1" className="text-start">{item.review ? item.review : item.settings.placeholder_text}</Typography>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </DialogContent>
                </Dialog>
            </>
    )

}

export default Reviews;