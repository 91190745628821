import React ,{useEffect, useState}from 'react';
import { Grid, TextField,Button ,Stack,Card,Typography,RadioGroup,useMediaQuery,FormControlLabel,Tooltip,IconButton,InputAdornment,OutlinedInput,FormControl,InputLabel,Radio} from '@mui/material';
import SecureWebsite from "../../../Components/Enrollment/SecureWebsite";
import { ReactComponent as ChatIcon } from "../../../assets/images/Chatbutton.svg";
import { ReactComponent as EmailIcon } from "../../../assets/images/Emailbutton.svg";
import { ReactComponent as PlayIcon } from "../../../assets/images/Playbutton.svg";
const Step1 = ({btnTextColor,primaryColor,popupFlage,textColorProp,hyperlinkColor,onValueChange,chatEnabled,emailEnabled,step1Option,setStep1Option,step1Link,setStep1Link,getVideoOptions,buttonColor,buttonTextColor }) => {
        const [uploadedFile, setUploadedFile] = useState(null); // State for the uploaded file
        // const [step1Link, setStep1Link] = useState("");
        const [radioSelect, setRadioSelect] = useState(step1Option || ""); // Ensure default value

        useEffect(() => {
            // if (step1Option) { // Ensuring it's not empty or undefined
                setRadioSelect(step1Option);
            // }
        }, [step1Option]);

        const isMobile = useMediaQuery('(max-width:600px)');
        // const [isChatEnable, setIsChatEnable] = useState(chatEnabled);
        // const [isEmailEnable, setIsEmailEnable] = useState(emailEnabled);
        // console.log("chatEnabled",chatEnabled);
        // console.log("emailEnabled",emailEnabled);
        const handleStep1RadioChange = (event) => {
            setUploadedFile(null);
            setStep1Option( event.target.value);
        };

        const handleFileUpload = (event) => {
            const file = event.target.files[0]; // Get the uploaded file
            const maxSize = 10 * 1024 * 1024;
            if (file) {
                // Check if file size exceeds 10MB
                if (file.size > maxSize) {
                  alert("Video size should not exceed 10MB"); // Display error message
                  event.target.value = ""; // Clear the file input
                  return;
                }
            
                // File size is valid
                setUploadedFile(file); // Set the uploaded file in state
                setStep1Link(file); // Pass the file to step1Link
                onValueChange(file); // Trigger additional callback if needed
              } else {
                console.error("Invalid file type. Please upload a video file.");
              }
        };
        // useEffect(()=>{
        //     setIsChatEnable(chatEnable);
        // }, [chatEnable]);
    
        // useEffect(()=>{
        //     setIsEmailEnable(emailEnable);
        // }, [emailEnable]);
    return (
        <>
        <div style={{ pointerEvents: 'none' }}>
            <Grid item lg={12} md={12} sm={12} xs={12} mt={2} >
                <TextField size='small' label="First Name*" margin='dense' fullWidth 
                    sx={{
                        label: { color: `${primaryColor}` },
                        "& .MuiInputLabel-root":{color: `${primaryColor}`},
                        "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${primaryColor}`,backgroundColor:'white' }, // Styles the fieldset
                    }}
                 />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} mt={2} >
                <TextField size='small' label="Middle Name" margin='dense' fullWidth 
                    sx={{
                        label: { color: `${primaryColor}` },
                        "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${primaryColor}`,backgroundColor:'white' }, // Styles the fieldset
                    }}
                 />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} mt={2} >
                <TextField size='small' label="Last Name*" margin='dense' fullWidth 
                    sx={{label: { color: `${primaryColor}` },
                        "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${primaryColor}`,backgroundColor:'white' }, // Styles the fieldset
                    }}
                 />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                <TextField size='small' label="Email*" margin='dense' fullWidth 
                    sx={{label: { color: `${primaryColor}` },
                        "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${primaryColor}`,backgroundColor:'white' }, // Styles the fieldset
                      
                    }}
                />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                <TextField size='small' label="Password*" margin='dense' fullWidth 
                    sx={{
                        label: { color: `${primaryColor}` },
                        "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${primaryColor}`,backgroundColor:'white' }, // Styles the fieldset
                    }}
                />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} mt={2} sx={{ justifyContent: "right", display: "flex" }}>
                <Button variant='contained' size="lg" sx={{ minWidth: "60%", backgroundColor: `${buttonColor}`, color: `${buttonTextColor}`,
                        '&:hover': {
                            backgroundColor: `${buttonColor}`
                        } 
                    }} className='popup-next-btn'>NEXT STEP</Button>
            </Grid>
            
            <SecureWebsite textColor={textColorProp} hyperlinkColor={hyperlinkColor} />
            </div>

            {!popupFlage && (
            <div>
                <Card variant="outlined" sx={{border:"1px solid black",padding:"2%"}}>
                        <Grid  item lg={12} md={12} sm={12} xs={12}>
                            <Grid container item lg={12} md={12} xs={12} sm={12} sx={{justifyContent:"flex-end"}}>
                                        {step1Option === 'uploadVideo'?
                                    <Typography sx={{color:"red",fontSize:"12px"}}>(Note: Video Size Should Not Exceed 10Mb)</Typography>
                                    :""} 
                            </Grid>
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={radioSelect}
                                    onChange={(event) => handleStep1RadioChange(event)}
                                >
                                    <Stack direction="row" >
                                    <FormControlLabel value="youTube" control={<Radio />} label={<img
                                        src={require('../../../assets/images/youtubeSnap.png')} // Replace with male icon URL
                                        alt="Youtube"

                                        style={{ marginRight: 8,width:"35px" }}
                                        />} />
                                    <FormControlLabel value="vimeo" control={<Radio />} label={<img
                                        src={require('../../../assets/images/vimeoLogo.png')} // Replace with male icon URL
                                        alt="Male"
                                        style={{ marginRight: 8 ,width:"55px"}}
                                        />} />
                                    <FormControlLabel value="uploadVideo" control={<Radio />} label="Upload Video" />
                                    </Stack>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid  item lg={12} md={12} sm={12} xs={12}>
                            {step1Option === 'youTube' && (
                                <>
                                <TextField
                                    label="YoutTube Video Url"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    id="step1Link"
                                    name="step1Link"
                                    value={getVideoOptions(step1Link) == 'youTube' ? step1Link :'' }
                                    onChange={(e) => {setStep1Link(e.target.value); onValueChange(e.target.value);}}
                                    // onBlur={handleChange}
                                />
                                {/* {touched.step1Link && errors.step1Link ? <div className='error'>{errors.step1Link}</div> : null} */}
                                </>
                             )} 
                            {step1Option === 'vimeo' && (
                                <>
                                <TextField
                                    label="Vimeo Video URL"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    id="step1Link"
                                    name="step1Link"
                                    value={getVideoOptions(step1Link) == 'vimeo' ? step1Link :''}
                                    onChange={(e) => {setStep1Link(e.target.value); onValueChange(e.target.value);}}
                                    // onBlur={handleChange}
                                />
                                {/* {touched.step1Link && errors.step1Link ? <div className='error'>{errors.step1Link}</div> : null} */}
                                </>
                             )} 

                            {step1Option === 'uploadVideo' && (
                                <>
                                <FormControl variant="outlined" fullWidth margin="dense" size="small">
                                <InputLabel htmlFor="uploadedLogoStep1">Upload Your Video</InputLabel>
                                <OutlinedInput
                                    id="uploadedLogo"
                                    type="text" // Show the file name instead of the file picker
                                    value={typeof step1Link === "string" && step1Link.includes('mp4')
                                        ? step1Link.split('/').pop().split('_').slice(1).join('_')
                                        : uploadedFile?.name || ""} // Show the file name in the input field
                                    // value={getVideoOptions(step1Link) == 'vimeo' ? step1Link :''}
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <Tooltip
                                        title={"Upload Video"}
                                        >
                                        <IconButton
                                            edge="end"
                                            onClick={() => document.getElementById("hiddenFileInputStep1").click()}
                                        >
                                            <img
                                            src={require("../../../assets/images/uploadFile.png")}
                                            style={{ height: "25px" }}
                                            alt="Upload"
                                            />
                                        </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                    }
                                    label="Upload Your Video"
                                />
                                <input
                                    id="hiddenFileInputStep1"
                                    type="file"
                                    name="uploaded"
                                    style={{ display: "none" }} // Hidden input
                                    accept="video/*"
                                    onChange={handleFileUpload}
                                    // onChange={(event) => {
                                    // const file = event.target.files[0];
                                    // if (file) {
                                    //     setFieldValue("step1Link", file); 
                                    // }
                                    // }}
                                />

                                </FormControl>
                                {/* {touched.step1Link && errors.step1Link ? (
                                <div className="error">{errors.step1Link}</div>
                                ) : null} */}
                                </>
                            )} 
                        </Grid>
                </Card>
            </div>
             )
            }
           

            {popupFlage == true ?
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ display:"flex",justifyContent: isMobile?"center":"end",}}>
                              <Grid container item lg={12} md={12} sm={12} xs={12} sx={{mt:isMobile?4:0}}>
                              {chatEnabled == true ? (
                                <>
                                      <Grid item lg={4} md={4} sm={12} xs={12} mt={1} sx={{ display:"flex",justifyContent:"center",marginTop:isMobile?2:0 }}>
                                        
                                      <Button varient="outlined"
                                        // onClick={openChatPopup}
                                        startIcon={<ChatIcon style={{ width: 30, height: 30, fill: `${buttonTextColor}` }} />}
                                    //   sx={{
                                    //     textTransform: "none",
                                    //     border:"1px solid #2E67BC",
                                    //     minWidth:'80%' ,
                                    //     maxWidth:'100%',
                                    //     color:"black",
                                    //     alignItems:"center",
                                    //     fontWeight:"bold"// Prevents uppercase transformation
                                    //   }}
                                    sx={{
                                        textTransform: "none !important",minWidth:'80%',maxWidth:'100%', backgroundColor: `${buttonColor}`, color: `${buttonTextColor}`, borderRadius: "10px", '&:hover': {
                                            backgroundColor: `${buttonColor}`
                                        }
                                    }}
                                      >Chat With Us Now</Button>
                                    
                                    </Grid>
                                   </> ) :''  } 
                               {emailEnabled == true ? (
                                <>
                                <Grid item lg={4} md={4} sm={12} xs={12}  mt={1} sx={{ display:"flex",justifyContent:"center",marginTop:isMobile?2:0 }}>
                                        <Button  
                                        startIcon={<EmailIcon style={{ width: 30, height: 30, fill: `${buttonTextColor}` }} />}
                                        // onClick={openEmailChatPopup}
                                        // sx={{
                                        //   textTransform: "none",
                                        //   border:"1px solid #2E67BC",
                                        //   minWidth:'80%' ,
                                        //   maxWidth:'100%',
                                        //   color:"black",
                                        //   alignItems:"center",
                                        //   fontWeight:"bold" // Prevents uppercase transformation
                                        // }}
                                        sx={{
                                            textTransform: "none !important",minWidth:'80%',maxWidth:'100%', backgroundColor: `${buttonColor}`, color: `${buttonTextColor}`, borderRadius: "10px", '&:hover': {
                                                backgroundColor: `${buttonColor}`
                                            }
                                        }}
                                        >Email Us Now</Button>
                                  </Grid>
                                  </>) :''  } 
                                  {step1Link ?( <>
                                    <Grid item lg={4} md={4} sm={12} xs={12} mt={1} sx={{ display:"flex",justifyContent:"center",marginTop:isMobile?2:0 }}>
                                      <Button
                                        startIcon={<PlayIcon style={{ width: 30, height: 30, fill: `${buttonTextColor}` }} />}
                                        // sx={{
                                        //   textTransform: "none",
                                        //   border:"1px solid #2E67BC", 
                                        //   minWidth:'80%' ,
                                        //   maxWidth:'100%',
                                        //   alignItems:"center",
                                        //   color:"black",
                                        //   fontWeight:"bold"
                                        // }}
                                        sx={{
                                            textTransform: "none !important",minWidth:'80%',maxWidth:'100%', backgroundColor: `${buttonColor}`, color: `${buttonTextColor}`, borderRadius: "10px", '&:hover': {
                                                backgroundColor: `${buttonColor}`
                                            }
                                        }}
                                        >Learn More About Us
                                      </Button>
                                  </Grid>
                                  </>): ""}
                                  
                             
                            </Grid>
                               
                    </Grid>
             : ""
             }
        </>
    );
}
export default Step1;