
import React, { useMemo, useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Typography, Table, Option, } from '@mui/joy';
import DefaultLayout from '../../../Components/DefaultLayout';
import { Grid, InputLabel, MenuItem, Button, FormControl, Select, Dialog, DialogTitle, DialogContent, DialogActions, Box, Tab, DialogContentText, Alert } from '@mui/material';
import { TabContext, TabPanel, TabList } from '@mui/lab';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import AuthUser from "../../../Components/Auth/AuthUser";
import Loader from '../../../Components/Loader/Loader';
import AffiliateCommissionSummary from '../../../Components/CommissionSummary/AffiliateCommissionSummary';
import AddMissingMember from '../../../Components/ManageMembers/AddMissingMember';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import { async } from 'q';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Moment from 'moment';
import moment from 'moment';

function CommissionSummary(props) {
    const dispatch = useDispatch();
    const { http,user } = AuthUser();
    const theme = useTheme();
    const [data, setData] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [commissionSummarylist, setCommissionSummarylist] = useState([]);
    const [pendingCommissions, setPendingCommissions] = useState([]);
    const [companyId, setCompanyId] = useState();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [disable, setDisable] = useState(false);
    const [approvedFlag, setApprovedFlag] = useState(false);
    const [acceptFlag, setAcceptFlag] = useState(false);
    const [lowBalanceFlag, setLowBalanceFlag] = useState(false);
    const [selectedRanges, setSelectedRanges] = useState([]);
    const [openCalender, setOpencalender] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [paymentMadeEndedDate, setPaymentMadeEndedDate] = useState('');
    const [balance, setBalance] = useState('');
    const [fastStartBonusDetails, setFastStartBonusDetails] = useState();
    const [holidayDates, setHolidayDates] = React.useState([]);

    //Fetch reports
    const fetchReport = async() => {
        setErrorMessage("")
        setIsLoading(true)
        const apiUrl = '/showMissingMember';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            setData(res);
            setIsLoading(false)
        }
    }

    const handleClsoeCalender=()=>{
        setOpencalender(false);
    }

    const onCalendar = (acceptedDate,verifiedStartedDate,verifiedMiniEndedDate,verifiedEndedDate,paymentMadeStartedDate,paymentMadeEndedMiniEndedDate,paymentMadeEndedDate,holidays) =>{
        setPaymentMadeEndedDate(paymentMadeEndedDate);
        setHolidayDates(holidays);
        setSelectedRanges([
            { start: acceptedDate, end: acceptedDate, color: 'highlight0' },
            { start: verifiedStartedDate, end: verifiedMiniEndedDate, color: 'highlight2' },
            { start: paymentMadeStartedDate, end: paymentMadeEndedMiniEndedDate, color: 'highlight1' },
            { start: verifiedEndedDate , end: verifiedEndedDate, color: 'darkOrange' },
            { start: paymentMadeEndedDate, end: paymentMadeEndedDate, color: 'darkGreen' },
        ])
        setOpencalender(true);
    }

    const tileClassName = ({ date }) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');

        if (holidayDates.includes(formattedDate)) {
            return 'holidayColor';  // Use a specific color for holidays
        }
    
       for (const range of selectedRanges) {
         const { start, end, color } = range;
         if (color != 'highlight0' ) {
           // Check if the date is a weekend (Saturday or Sunday)
           const isWeekend = moment(formattedDate).isoWeekday() === 6 || moment(formattedDate).isoWeekday() === 7;
         
           if (moment(formattedDate).isBetween(start, end, null, '[]') && !isWeekend) {
             return color;
           }
         }
          else if (moment(formattedDate).isBetween(start, end, null, '[]')) {
           return color;
         }
       }
    
        return null;
    };

    const setErrorMsg = (msg) => {
        setErrorMessage(msg);
    }

    const setSuccessMsg = (msg) => {
        setSuccessMessage(msg);
    }

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    //Submit Request Add Missing Member Form


    const handleClickOpen = () => {
        setOpen(true);
    };


    const handleClose = () => {
        setOpen(false);
    };

    const handleClose1 = () => {
        setOpen1(false);
    };
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const fetchSummaryList = async() => {
        const apiUrl = '/affiliate/commission-summary-report';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            setCommissionSummarylist(res.data.reportData);
            setPendingCommissions(res.data.pendingCommissions);
            setCompanyId(res.data.companyId);
            setApprovedFlag(res.data.approvedFlag);
            setLowBalanceFlag(res.data.lowBalanceFlag);
            setAcceptFlag(res.data.acceptFlag);
            setIsLoading(false);
            setBalance(res.data.balance);
            setFastStartBonusDetails(res.data.fastStartBonusDetails);
        }
    }

    useEffect(() => {
        fetchSummaryList();
    }, []);

    function ContentLayout() {
        return (
            <>
                {isLoading ? <Loader /> : <></>}
                <Box>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mb={1} mt={1.5} sx={{ justifyContent: "space-between" }}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Typography level="h4" color="primary">
                                Commission Summary Report
                            </Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ textAlign: "right"}}>
                            <Button  variant="contained" color="warning" onClick={handleClickOpen}>
                                Request/Add missing member
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mb={3}>
                        <Typography level="h6">
                            Congratulations! You have earned commissions as below
                        </Typography>
                    </Grid>
                    {
                        balance > 0 ?
                        <Grid container item lg={12} md={12} sm={12} xs={12} mb={3}>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <Typography level="h6">
                                    Your Balance : {balance ? '$'+ parseFloat(balance).toFixed(2) : '$0.00'}
                                </Typography>
                            </Grid>
                            {
                                fastStartBonusDetails ?
                                <>
                                    <Grid item lg={8} md={8} sm={12} xs={12}>
                                        <Typography level="h6">
                                            Fast Start Bonus : ${fastStartBonusDetails.bonus} (In {fastStartBonusDetails.month + " " + fastStartBonusDetails.year})
                                        </Typography>
                                    </Grid>
                                </> : ''}
                        </Grid>
                        : ''
                    }
                   
                    {pendingCommissions ?
                        <>
                            {pendingCommissions > 0 ?
                                <>
                                    <Grid container item xs={12} mb={1}>
                                        <Typography level="h6">
                                            Please confirm Bank details to get paid.&nbsp;&nbsp;
                                            <Button
                                                color="info"
                                                variant="contained"
                                                size="small"
                                                onClick={() => navigate('/affiliate-update-bank-details/' + companyId)}
                                            >
                                                Click Here
                                            </Button>
                                        </Typography>
                                    </Grid>
                                </> : ''}
                        </> : ''}
                    {/* <Grid container item xs={12} mt={2} mb={1}>
                        <Typography level="h4" color="primary">
                            Commission Report
                        </Typography>
                    </Grid> */}
                    
                    <AffiliateCommissionSummary commissionSummarylist={commissionSummarylist} acceptFlag={acceptFlag} approvedFlag={approvedFlag} lowBalanceFlag={lowBalanceFlag} onCalendar={onCalendar}/>
                    
                    <Dialog
                        open={open}
                        onClose={(_, reason) => {
                            if (reason !== "backdropClick") {
                                handleClose();
                            }
                        }}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        fullWidth
                        minWidth="md"
                    >
                        <DialogTitle>
                            <Typography level="h3" sx={{ mb: 0.5 }}>Member Details</Typography>
                        </DialogTitle>
                        <DialogContent>
                            {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
                            {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
                            <AddMissingMember onError={setErrorMsg} onSuccess={setSuccessMsg} ModalClose={handleClose} />
                        </DialogContent>
                    </Dialog>

                    {/* -----------Calender dialog -------------*/}
                    <Dialog
                        open={openCalender}
                        onClose={(_, reason) => {
                        if (reason !== "backdropClick") {
                            handleClsoeCalender();
                        }
                        }}
                        fullWidth={fullWidth}
                        maxWidth={'sm'}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                    
                        <DialogContent>
                            <Grid container item lg={12} md={12} xs={12} sm={12} >
                                <Grid item lg={6} md={6} xs={6} sm={6}>
                                    <Calendar
                                        calendarType='gregory'
                                        className='popup-calender'
                                        tileClassName={tileClassName}
                                        next2Label={null}
                                        prev2Label={null}
                                        value={selectedRanges[0] ? selectedRanges[0].start : ''}
                                    />            
                                </Grid>
                                <Grid item lg={6} md={6} xs={6} sm={6}>
                                    <Grid item lg={12} md={12} xs={12} sm={12}>
                                        <DialogContentText sx={{alignSelf:'center',display: "flex", justifyContent: "center"}}>
                                            <Box>
                                                <Typography level="h4" sx={{ fontWeight: "bold"}} pb={2}>Expect to be paid by <br/>{Moment(paymentMadeEndedDate).format('MMMM DD, YYYY')}</Typography> 
                                                <Typography level="h4" sx={{ fontWeight: "bold"}} pb={2}>Legends </Typography> 
                                                <Box sx={{display:'flex',justifyContent: 'start',alignItems:'center'}}><div className='circleColor highlight0'></div><Typography pl={2}>Commission Accepted</Typography></Box>
                                               {
                                                selectedRanges[1] ? selectedRanges[1].start != "" ?
                                                    <Box sx={{display:'flex',justifyContent: 'start',alignItems:'center'}} pt={1}><div className='circleColor darkOrange'></div><Typography pl={2}>Bank Details Verified</Typography></Box> 
                                                    : '' : ''
                                               }
                                               
                                                <Box sx={{display:'flex',justifyContent: 'start',alignItems:'center'}} pt={1}><div className='circleColor darkGreen'></div><Typography pl={2} >Expected Payment Date </Typography></Box>
                                                <Box sx={{display:'flex',justifyContent: 'start',alignItems:'center'}} pt={1}><div className='circleColor holidayColor'></div><Typography pl={2} >Holiday </Typography></Box>
                                            </Box>
                                        </DialogContentText>
                                    </Grid>
                                    <Grid item lg={12} md={12} xs={12} sm={12}  sx={{position: 'absolute',bottom: '25px',right: '20px'}}>
                                        <DialogContentText >
                                            <Button variant="contained"  color="success" onClick={handleClsoeCalender} sx={{height: 'fit-content',marginLeft:'9px'}}>Ok</Button>
                                        </DialogContentText>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>   
                </Box>
            </>
        );
    }

    return (
        <div>
            <DefaultLayout content={<ContentLayout />} />
        </div>
    );
}

export default CommissionSummary;