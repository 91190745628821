import React, { useState } from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import { Grid, Stack, useMediaQuery, Box, Divider } from '@mui/material';
import { Typography, Card, List, ListItem, ListItemDecorator, ListItemContent, Button } from '@mui/joy';
import AuthUser from '../../../Components/Auth/AuthUser';
import swal from "sweetalert";
import Freez from '../../../Components/Loader/Loader'
import Backdrop from '@mui/material/Backdrop';
import { useDispatch } from 'react-redux';
import { postData } from '../../../redux/apiSlice';
import { useNavigate, Link, useParams } from 'react-router-dom';
import LoginHeader from '../../../Components/LoginHeader';
import RegisterFooter from '../../Auth/RegisterFooter';
import { styled } from '@mui/material/styles';
const CustomTypography = styled(Typography)({
  fontFamily: 'Rubik',
});
function CreditSnap(props) {
  //Neccessary variables declearation start
  const dispatch = useDispatch();
  //loader function and variable start
  const [freez, setFreez] = React.useState(false);
  const [bounce, setBounce] = useState(props.bounceFlag);
  const isMobile = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();

  const handleFreezStop = () => {
    setFreez(false);
  };
  const handleFreezStart = () => {
    setFreez(true);
  };
  //loader function and variable end
  const inlineFonts = { fontFamily: "'Rubik' !important"};


  const handleRedirect = () => {
    navigate("/login");
  };
  return (
    
    <div >
      <LoginHeader />
      <Card  variant='plain' className="creditsnapMarketing" sx={{ backgroundColor: "#2E67BC", borderRadius: "0px !important" }}>
        <Grid container item lg={12} md={12} sm={12} xs={12} py={2} px={2}>
          <Card variant='outlined' sx={{ marginTop: "10px",width:"100%",textAlign:"center" }} >
            <Grid container item lg={12} md={12} sm={12} xs={12}>
              <Grid item lg={5} md={5} sm={12} xs={12} sx={{display:'flex', flexDirection:"column"}}>
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{flexGrow:1}}>
                  <CustomTypography level="h4" sx={{ color: "#2A9005" }}><b>LEADS! $$$&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LEADS! $$$&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LEADS! $$$</b></CustomTypography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{flexGrow:1}}>
                  <CustomTypography level="h2" sx={{ color: "#2E67BC", letterSpacing:'10px' }}><b>GROW YOUR BUSINESS</b></CustomTypography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} >
                  <CustomTypography className="creditTypo">WITH</CustomTypography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <img src={require("../../../assets/images/credit_snapshot_logo.png")} style={{ width: "75%",height:"auto" }} />
                </Grid>
              </Grid>
              <Grid item lg={7} md={7} sm={12} xs={12}>
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt:4}}>
                  <CustomTypography className="creditTypo">A Brand New Lead Generation Tool That Is Available <span className="creditTypo"><b>ONLY TO OUR AFFILIATES!</b></span></CustomTypography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt:2}}>
                  <CustomTypography className="creditTypo">Convert More Visitors From Your Advertising Referrals Or Social Media.</CustomTypography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt:2}}>
                  <CustomTypography className="creditTypo">Make Your Website Interactive With This</CustomTypography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt:2}}>
                  <CustomTypography className="creditTypo"><b>INCREDIBLE FREE OFFER!</b></CustomTypography>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>       
        <Grid container item lg={12} md={12} sm={12} xs={12}>
          <Grid item lg={5} md={5} sm={12} xs={12}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <CustomTypography sx={{ color: "#FFFFFF",fontWeight:700,fontSize:"22px",textAlign:"center" }}>Credit Snapshot Usage And Your Branded Website Is</CustomTypography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <CustomTypography sx={{ color: "#F1FF4D",fontWeight:800,fontSize:"30px",textAlign:"center"  }}><b>FREE! FREE! FREE*</b></CustomTypography>
            </Grid>
          </Grid>
          <Grid item lg={1} md={1} sm={12} xs={12} sx={{maxWidth:"1% !important"}}>
            <Divider orientation="vertical" style={{ height: '100%', width: '1px', backgroundColor: "white" }} />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <CustomTypography sx={{ color: "#FFFFFF",display: "flex"  }}><img src={require('../../../assets/images/yellowTick.png')} alt="Yellow Tick" 
                    style={{ marginRight: "8px", width: "20px", height: "20px" }} />
                Credit Snapshot Consumers Are Qualified Leads With Genuine Intent, Known For High Conversion Rates. Acquiring These Leads Through Advertising Would Cost At Least $10-$15 Each!</CustomTypography>
            </Grid>
          </Grid>
        </Grid>
      </Card>

      <Box sx={{ padding: "0 2%" }}>
        <Grid container item lg={12} md={12} sm={12} xs={12}>
          <Grid item lg={5} md={5} sm={12} xs={12} sx={{display:'flex',flexGrow:1}}>
            <Grid container item lg={12} md={12} sm={12} xs={12} py={2} px={2}>
              <Card variant="outlined" sx={{border:"1px solid #2E67BC",width:"100%"}}>
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                  <CustomTypography level="h4" >What's The Offer</CustomTypography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                  <List
                    aria-labelledby="ellipsis-list-demo"
                    size="sm"
                  >
                    <ListItem>
                      <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 0.5 }}>
                        <img src={require("../../../assets/images/greenTick.png")}  alt="Green Tick" style={{ width: "20px", height: "20px" }} />
                      </ListItemDecorator>
                      <ListItemContent ><CustomTypography sx={{  display: "inline-flex", alignItems: "center"}}>FREE &nbsp;<img src={require("../../../assets/images/equifax.png")} style={{width:"100px",height:"auto"}}/> &nbsp; Credit Score</CustomTypography>
                      </ListItemContent>
                    </ListItem>
                    <ListItem>
                      <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 0.5 }}>
                        <img src={require("../../../assets/images/greenTick.png")}  alt="Green Tick1" style={{  width: "20px", height: "20px" }} />
                      </ListItemDecorator>
                      <ListItemContent ><CustomTypography>FREE Credit Report Summary Of Negative Items</CustomTypography></ListItemContent>
                    </ListItem>
                    <ListItem>
                    <ListItemContent ><img src={require("../../../assets/images/Scorepagenew.png")} style={{width:"100%"}} /></ListItemContent>
                    </ListItem>
                    <ListItem>
                      <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 0.5 }}>
                          <img src={require("../../../assets/images/greenTick.png")}  alt="Green Tick1" style={{  width: "20px", height: "20px" }} />
                        </ListItemDecorator>
                        <ListItemContent ><CustomTypography>FREE Debt Utilization Calculation</CustomTypography></ListItemContent>
                    </ListItem>
                    <ListItem>
                      <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 0.5 }}>
                          <img src={require("../../../assets/images/greenTick.png")}  alt="Green Tick1" style={{  width: "20px", height: "20px" }} />
                        </ListItemDecorator>
                        <ListItemContent ><CustomTypography>Pages Can Be Translated With One Click Into 50+ Other Languages!</CustomTypography></ListItemContent>
                    </ListItem>
                  </List>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <Grid item lg={7} md={7} sm={12} xs={12} sx={{display:'flex',flexGrow:1}}>
            <Grid container item lg={12} md={12} sm={12} xs={12} py={2} px={2}>
              <Card variant="outlined" sx={{border:"1px solid #2E67BC",width:"100%"}} >
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{backgroundColor:"#F3F9FF",}}>
                  <CustomTypography level="h4" >What's New</CustomTypography>
                  <List aria-labelledby="ellipsis-list-demo" size="sm">
                    <ListItem style={{ display: 'flex', alignItems: 'center' }}>
                      <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 1 }}>
                        <img
                          src={require("../../../assets/images/greenTick.png")}
                          alt="Dash Icon" style={{  width: "20px", height: "20px" }}
                        // Adjust size if needed
                        />
                      </ListItemDecorator>
                      <ListItemContent>
                        <CustomTypography >
                          <Stack direction="row" alignItems="center" spacing={1} sx={{ display: isMobile ? "flow" : "flex" }}>
                            <img
                              src={require("../../../assets/images/Chats.png")}
                              style={{ width: '27px' }}
                              alt="Chats Icon"
                            />
                            <img
                              src={require("../../../assets/images/Emailing.png")}
                              style={{ width: '27px' }}
                              alt="Emailing Icon"
                            />&nbsp;Chat and Email Features-Interact Directly With Leads
                          </Stack>
                        </CustomTypography>
                      </ListItemContent>
                    </ListItem>
                    <ListItem style={{ display: 'flex', alignItems: 'center' }}>
                      <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 1 }}>
                        <img
                          src={require("../../../assets/images/greenTick.png")}
                          style={{  width: "20px", height: "20px" }}
                          alt="Dash Icon"
                        />
                      </ListItemDecorator>
                      <ListItemContent>
                        <CustomTypography>
                          <Stack direction="row" alignItems="center" spacing={1} sx={{ display: isMobile ? "flow" : "flex" }}>
                            <img
                              src={require("../../../assets/images/playing.png")}
                              style={{ width: '27px' }}
                              alt="Play Icon"
                            />&nbsp;Upload Your Company Videos -Build Trust And Start Building Up To The Close
                          </Stack>
                        </CustomTypography>
                      </ListItemContent>
                    </ListItem>

                    <ListItem style={{ display: 'flex', alignItems: 'center' }}>
                      <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 1 }}>
                        <img
                          src={require("../../../assets/images/greenTick.png")}
                          style={{  width: "20px", height: "20px" }}
                          alt="Dash Icon"
                        />
                      </ListItemDecorator>
                      <ListItemContent>
                        <CustomTypography>
                          <Stack direction="row" alignItems="center" spacing={1} sx={{ display: isMobile ? "flow" : "flex" }}>
                            <img
                              src={require("../../../assets/images/LinkIcon.png")}
                              style={{ width: '27px' }}
                              alt="Link Icon"
                            />&nbsp;Your CRM Link For Direct Onboarding Of The Lead To Your Own System
                          </Stack>
                        </CustomTypography>
                      </ListItemContent>
                    </ListItem>

                    <ListItem style={{ display: 'flex', alignItems: 'center' }}>
                      <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 1 }}>
                        <img
                          src={require("../../../assets/images/greenTick.png")}
                          style={{  width: "20px", height: "20px" }}
                          alt="Dash Icon"
                        />
                      </ListItemDecorator>
                      <ListItemContent>
                        <CustomTypography>
                          <Stack direction="row" alignItems="center" spacing={1} sx={{ display: isMobile ? "flow" : "flex" }}>
                            <img
                              src={require("../../../assets/images/Automationicon.png")}
                              style={{ width: '27px' }}
                              alt="Automation Icon"
                            />&nbsp;Zapier Integration
                          </Stack>
                        </CustomTypography>
                      </ListItemContent>
                    </ListItem>
                  </List>
                </Grid>
                <Grid item container lg={12} md={12} sm={12} xs={12} sx={{display:'flex', flexDirection:'row'}}>
                  <Grid item sx={{flexGrow:1,}}>
                    <Card variant="outlined" py={2} px={2} sx={{width:"auto",minHeight: "180px", maxHeight: "200px" }}>
                      <CustomTypography level="h6" color="primary" mb={2}>Consumer Experience</CustomTypography>
                      <iframe
                        width="100%"
                        height="150px"
                        src="https://www.youtube.com/embed/5SiyOJLt1y0?si=1fQs16b1uKqrHynp"
                        title="Video Player"
                        // frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </Card>
                  </Grid>
                  <Grid item sx={{flexGrow:1,}}>
                    <Card variant="outlined" py={2} px={2} sx={{width:"auto",minHeight: "180px", maxHeight: "200px" }}>
                      <CustomTypography level="h6" color="primary" mb={2}>How To Set Up Credit Snapshot</CustomTypography>
                        <iframe
                          width="100%"
                          height="150px"
                          src="https://www.youtube.com/embed/QOyOfg9D288?si=-N4y8jsigCi91MFc"
                          title="Video Player"
                          // frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                    </Card>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item lg={12} md={12} sm={12} xs={12}>
          <Grid item lg={5} md={5} sm={12} xs={12} sx={{display:'flex',flexGrow:1}}>
            <Grid container item lg={12} md={12} sm={12} xs={12} py={2} px={2}>
              <Card variant="outlined" sx={{border:"1px solid #2E67BC"}} >
                <CustomTypography level="h4">What do I get?</CustomTypography>
                <List
                  aria-labelledby="ellipsis-list-demo"
                  size="sm"
                >
                  <ListItem>
                    <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 1 }}>
                      <img src={require("../../../assets/images/greenTick.png")}  alt="Dash Icon" style={{  width: "20px", height: "20px" }} />
                    </ListItemDecorator>
                    <ListItemContent sx={{ mr: 0.5 }}><CustomTypography>More Leads With Verified Emails And Phone Numbers</CustomTypography>
                    </ListItemContent>
                  </ListItem>
                  <ListItem>
                    <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 1 }}>
                      <img src={require("../../../assets/images/greenTick.png")} alt="Dash Icon" style={{  width: "20px", height: "20px" }} />
                    </ListItemDecorator>
                    <ListItemContent sx={{ mr: 0.5 }}><CustomTypography>Free End To End Customer Experience Branded With Your Logo And Phone Number  </CustomTypography>
                    </ListItemContent>
                  </ListItem>
                  <ListItem>
                    <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 1 }}>
                      <img src={require("../../../assets/images/greenTick.png")}  alt="Dash Icon" style={{  width: "20px", height: "20px" }}/>
                    </ListItemDecorator>
                    <ListItemContent sx={{ mr: 0.5 }}><CustomTypography>Credit Snapshot Accounts Automatically Expire, Allowing Consumers To Upgrade Via Your Affiliate Link Or Directly From The Results Page—Earning You Commission! </CustomTypography>
                    </ListItemContent>
                  </ListItem>
                  <ListItem>
                    <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 1 }}>
                      <img src={require("../../../assets/images/greenTick.png")} alt="Dash Icon" style={{  width: "20px", height: "20px" }} />
                    </ListItemDecorator>
                    <ListItemContent sx={{ mr: 0.5 }}><CustomTypography>Credit Snapshot accounts automatically expire. Consumers can upgrade to your affiliate link through you OR
                      directly from the Credit Snapshot results page - so you’ll be eligible to earn commission!
                    </CustomTypography>
                    </ListItemContent>
                  </ListItem>
                </List>
              </Card>
            </Grid>
          </Grid>
          <Grid item lg={7} md={7} sm={12} xs={12} sx={{display:'flex',flexGrow:1}}>
            <Grid container item lg={12} md={12} sm={12} xs={12} py={2} px={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}  sx={{display:'flex',flexGrow:1}}>
                <Card variant="outlined" sx={{border:"1px solid #2E67BC"}}>
                  <CustomTypography level="h4">How do I use it?</CustomTypography>
                  <List
                    aria-labelledby="ellipsis-list-demo"
                    size="sm">
                    <ListItem>
                      <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 1 }}>
                        <img src={require("../../../assets/images/greenTick.png")} alt="Dash Icon" style={{  width: "20px", height: "20px" }}/>
                      </ListItemDecorator>
                      <ListItemContent sx={{ mr: 0.5 }}><CustomTypography>Register For Credit Snapshot Right From This Page</CustomTypography>
                      </ListItemContent>
                    </ListItem>
                    <ListItem>
                      <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 1 }}>
                        <img src={require("../../../assets/images/greenTick.png")} alt="Dash Icon" style={{  width: "20px", height: "20px" }}/>
                      </ListItemDecorator>
                      <ListItemContent sx={{ mr: 0.5 }}><CustomTypography>Once Approved, Use The Unique Link We Provide To Post The Offer On Social Media, In Email Campaigns, Or As A Customizable Website Pop-Up.</CustomTypography>
                      </ListItemContent>
                    </ListItem>
                    <ListItem>
                      <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 1 }}>
                        <img src={require("../../../assets/images/greenTick.png")} alt="Dash Icon" style={{  width: "20px", height: "20px" }}/>
                      </ListItemDecorator>
                      <ListItemContent sx={{ mr: 0.5 }}><CustomTypography>Set Up Is Only 4 Easy Steps And Takes Just A Few Minutes!</CustomTypography>
                      </ListItemContent>
                    </ListItem>
                    <ListItem>
                      <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 1 }}>
                        <img src={require("../../../assets/images/greenTick.png")} alt="Dash Icon" style={{  width: "20px", height: "20px" }}/>
                      </ListItemDecorator>
                      <ListItemContent sx={{ mr: 0.5 }}><CustomTypography>See And Manage All Your Leads In The Affiliate Portal</CustomTypography>
                      </ListItemContent>
                    </ListItem>
                  </List>
                </Card>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} sx={{display:'flex',flexGrow:1}} >
                <Grid container item lg={12} md={12} sm={12} xs={12} py={2} px={2} >
                  <Card variant="plain" sx={{boxShadow:'none'}}>
                    <CustomTypography level="h4" color="primary" mb={2} sx={{textAlign:'center'}}>Register Now With Credit Snapshot</CustomTypography>
                    <Button onClick={handleRedirect} sx={{
                      bgcolor: "#054da7",
                      color: "white",
                      borderRadius: "10px",
                      fontSize: "19px",
                      fontWeight: "bold",
                      fontFamily: 'Rubik',
                      width: '100%',
                      // minWidth: 150,
                      // maxWidth: 200,
                      // animation: "blink 1s infinite",
                      // "@keyframes blink": {
                      //   "0%": { bgcolor: "black", color: "white" },
                      //   "50%": { bgcolor: "#FF9300", color: "white" },
                      //   "100%": { bgcolor: "black", color: "white" },
                      // },
                      // "&:hover": {
                      //   bgcolor: "#FF9300",
                      //   color: "white"
                      // },

                    }}>REGISTER NOW</Button>
                    <CustomTypography sx={{ mt: 1, mb: 1, textAlign:'center',color:'#2A9005'}}><b>For any query Call or text us  @1-888-548-2008?</b></CustomTypography>
                    <CustomTypography sx={{ mt: 1, mb: 1, color: "grey" }}><b><i>*Terms And Conditions Apply. We Reserve The Right To Make Changes At Any Time.</i></b>   </CustomTypography>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>        
      </Box>
      <RegisterFooter />
    </div>
  );


}


export default CreditSnap;