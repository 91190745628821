import React, { useState,useEffect} from "react";
import AuthUser from '../Auth/AuthUser';
import { useMediaQuery,Grid, Select, FormControl, MenuItem, Input,InputLabel,FormLabel,RadioGroup, Radio,TextField, InputAdornment,Tooltip,ClickAwayListener,  OutlinedInput, Backdrop, Checkbox,FormControlLabel, Stack} from '@mui/material';
import { Typography, Card, CardContent, CardOverflow, Button} from '@mui/joy';
import IconButton from '@material-ui/core/IconButton';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import Chip from '@material-ui/core/Chip';
import * as Yup from 'yup';
import { Formik, FieldArray } from 'formik';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import SnapshotOperationalHours from '../../Components/SnapshotOperationalHours';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import swal from "sweetalert";
import FormHelperText from '@material-ui/core/FormHelperText';
import InputMask from 'react-input-mask';
import {  useDispatch } from 'react-redux';
import { postData,postFormData } from '../../redux/apiSlice';
import Freez from '../../Components/Loader/Loader';
import { styled } from '@mui/material/styles';
import SnapshotOperationalHours from "./SnapshotOperationalHours";
import iIcon from '../../assets/images/i_icon.png'; 
import InfoIcon from '@mui/icons-material/Info';
import Visibility from '@mui/icons-material/Visibility';

let blankValueIndexes1 = [];
let blankValueIndexes2 = [];
let operationalhoursvalues = [];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 350,
        },
    },
};

const CustomInputAdornment = styled(InputAdornment)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    '& .MuiIconButton-root': {
        padding: '3px', // Apply reduced padding to all IconButtons
    },
}));

const CompanyDetails = ({ receivedphone,defaultPhoneNumber,receivedemails,defaultEmail,receivedpid,receivedaid,business,creditinfo,pids,aidlist1,firstAid,receivedshift,fetchCreditInfo,defaultOutOfOfficeMsg }) => {
    // const [receivedaid, setReceivedAid] = useState([]);
    // const [firstAid, setFirstAid] = useState([]);
    const { http, user } = AuthUser();
    const isMobile = useMediaQuery('(max-width:600px)');
    
    // const [aidlist1, setAidList1] = useState([]);
    const dispatch = useDispatch();
    const [freez, setFreez] = React.useState(false);
    // const [receivedshift, setReceivedShift] = useState([{ label: "Monday-Friday(Weekdays)", timings: [{ start: "09:00", end: "18:00" }] },
    // { label: "Saturday-Sunday(Weekends)", timings: [{ start: "09:00", end: "18:00" }] },
    // ]);
    // tooltip popup start here
        const [openTooltip, setOpenTooltip] = useState(false);
        const [signupLinkLabel, setSignupLinkLabel] = useState(creditinfo && creditinfo.data && creditinfo.data.crs_signup_enabled === 1 ? "Add Your Customer Signup Link*" : "Add Your Customer Signup Link");
        const [outOfOfficeLabel, setOutOfOfficeLabel] = useState(creditinfo && creditinfo.data && creditinfo.data.enable_chat_support === 1 ? "Out Of Office Message For Chat Support*" : "Out Of Office Message For Chat Support");
        
       
        
        const handleTooltipOpen = () => {
            setOpenTooltip(true);
        };

        const handleTooltipClose = () => {
            setOpenTooltip(false);
        };
        
     // tooltip popup end here
    const CustomSelect = styled(Select)(({ theme }) => ({
        '& .MuiInput-underline:before': {
          borderBottom: 'none',
        },
        '& .MuiInput-underline:after': {
          borderBottom: 'none',
        },
    }));
    
    const [step1Option, setStep1Option] = useState(() => {
        if (creditinfo && creditinfo.data && creditinfo.data.step_1_link) {
            if (creditinfo.data.step_1_link.includes('youtube.com') || creditinfo.data.step_1_link.includes('youtube.com/embed')) {
                return 'youTube';  // or any value representing YouTube
            } else if (creditinfo.data.step_1_link.includes('vimeo.com')) {
                return 'vimeo';  // or any value representing Vimeo
            } else if (creditinfo.data.step_1_link.includes('mp4')){
                return 'uploadVideo';  // default value if neither
            }
        }
        return '';  // fallback in case creditinfo is not available
    });
    const [step2Option, setStep2Option] = useState(() => {
        if (creditinfo && creditinfo.data && creditinfo.data.step_2_link) {
            if (creditinfo.data.step_2_link.includes('youtube.com') || creditinfo.data.step_2_link.includes('youtube.com/embed')) {
                return 'youTube';  // or any value representing YouTube
            } else if (creditinfo.data.step_2_link.includes('vimeo.com')) {
                return 'vimeo';  // or any value representing Vimeo
            } else if (creditinfo.data.step_2_link.includes('mp4')){
                return 'uploadVideo';  // default value if neither
            }
        }
        return '';  // fallback in case creditinfo is not available
    });
    const [step3Option, setStep3Option] = useState(() => {
        if (creditinfo && creditinfo.data && creditinfo.data.summary_page_link) {
            if (creditinfo.data.summary_page_link.includes('youtube.com') || creditinfo.data.summary_page_link.includes('youtube.com/embed')) {
                return 'youTube';  // or any value representing YouTube
            } else if (creditinfo.data.summary_page_link.includes('vimeo.com')) {
                return 'vimeo';  // or any value representing Vimeo
            } else if (creditinfo.data.summary_page_link.includes('mp4')){
                return 'uploadVideo';  // default value if neither
            }
        }
        return '';  // fallback in case creditinfo is not available
    });
    const [values, setValues] = useState({
    step1Link: '',
    step2Link: '',
    step3Link: ''
    });

    const handleStep1RadioChange = (event, setFieldValue,values) => {
        const selectedOption = event.target.value;
        setStep1Option(selectedOption);

        // Clear the `step1Link` value when switching options
        if (selectedOption !== 'uploadVideo') {
            if (values.step1Link instanceof File) {
                setFieldValue('step1Link', '');  // Clear the input field if it's not a string
            }
            else{
                setFieldValue('step1Link', creditinfo ? creditinfo.data ? creditinfo.data.step_1_link ? creditinfo.data.step_1_link : "" : "": "");
            }  
        }
    };

    const handleStep2RadioChange = (event, setFieldValue,values) => {
        const selectedOption1 = event.target.value;
        setStep2Option(event.target.value);
        if (selectedOption1 !== 'uploadVideo') {
            if (values.step2Link instanceof File) {
            setFieldValue('step2Link', '');  // Clear the input field if switching away from "Upload Video"
            }else{
                setFieldValue('step2Link', creditinfo ? creditinfo.data ? creditinfo.data.step_2_link ? creditinfo.data.step_2_link : "" : "": "");
            }  
        }
    };

    const handleStep3RadioChange = (event, setFieldValue,values) => {
        const selectedOption2 = event.target.value;
        setStep3Option(event.target.value);
        if (selectedOption2 !== 'uploadVideo') {
            if (values.summaryLink instanceof File) {
                setFieldValue('summaryLink', '');  // Clear the input field if switching away from "Upload Video"
            }else{
                setFieldValue('summaryLink', creditinfo ? creditinfo.data ? creditinfo.data.summary_page_link ? creditinfo.data.summary_page_link : "" : "": "");
            }  
        }
    };


    const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
        '&.Mui-focused': {
          color: theme.palette.text.primary,
        },
    }));

    function formatPhoneNumber(phoneNumber) {
        const cleaned = ('' + phoneNumber).replace(/\D/g, ''); // Remove non-digits
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }

        return phoneNumber; // Return original input if it doesn't match the expected format
    }

    const phoneRegExp = /^\(\d{3}\) \d{3}-\d{4}$/;

    const validateVideoUrl = (url) => {
        // Regex for video file extensions
        const videoFileRegex = /\.(mp4|webm|ogg|avi|mov|mkv)$/i;
      
        // Regex for video platforms like YouTube and Vimeo
        const videoPlatformRegex = /(youtube\.com|youtu\.be|vimeo\.com)/i;
      
        // Validate URL as video link
        if (videoFileRegex.test(url) || videoPlatformRegex.test(url)) {
          return true;
        }
        return false;
      };

    const validationSchema = Yup.object().shape({
        phoneNumbers: Yup.array().of(
            Yup.object().shape({
                number: Yup.string()
                    .matches(phoneRegExp, 'Phone number must match the pattern (123) 456-7890')
                    // .min(14, 'Must be 10 digits...')
                    // .max(14, 'Must be 10 digits.')
                    .required('Phone number is required'),
            })
        ),
        PID1: Yup.string()
            .required('Please select PID.'),
        AID: Yup.string()

            .required('Please select AID.'),
        timezone: Yup.string()
        .required('Please select Timezone.'),
        // BusinessName: Yup.string()
        //     .min(2, 'Too Short!')
        //     .max(50, 'Too Long!')
        //     .required('Business name is required.'),
        creditSnapshotLink: Yup.string().when("signupEnabled", {
            is: (signupEnabled) => signupEnabled == true,
            then: (schema) => schema
                .required('Credit Repair Signup Link is Required')
                .url('Please enter a valid URL.'),
        }),
        outOfOfficeMsg: Yup.string().when("signupEnabled", {
            is: (signupEnabled) => signupEnabled == true,
            then: (schema) => schema
                .required('Out of office chat message is Required')
                .min(2, 'Too Short!'),
        }),
        // email: Yup.string()
        //     .email('Invalid Email Format.')
        //     .matches(/^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24})$/i, "Invalid email format.")
        //     .required('Please enter your Email Address.'),
        emails: Yup.array().of(
            Yup.object().shape({
                email: Yup.string()
                    .email('Invalid Email Format.')
                    .matches(/^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24})$/i, "Invalid email format.")
                    .required('Please enter your Email Address.'),
            })
        ),

        step1Link: Yup.mixed()
            .test(
            "is-valid-step1Link",
            "Invalid input. Please provide a valid URL or an MP4 file.", // Default error message
            function (value) {
                if (!value) return true;
                // YouTube URL validation
                const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/(watch\?v=[\w-]+|embed\/[\w-]+|shorts\/[\w-]+)|youtu\.be\/[\w-]+)/;
                // Vimeo URL validation
                const vimeoRegex = /^(https?:\/\/)?(www\.)?(vimeo\.com\/(video\/)?\d+|player\.vimeo\.com\/video\/\d+)/;
                if (typeof value === "string") {
                    if (step1Option === 'youTube') {
                        // Validate YouTube URL using the regex
                        if (youtubeRegex.test(value)) {
                            return true; // Valid YouTube URL
                        } else {
                            return this.createError({
                                path: this.path,
                                message: "Step 1 Link must be a valid YouTube URL.",
                            });
                        }
                    }
                    
                    if (step1Option === 'vimeo') {
                        // Validate Vimeo URL using the regex
                        if (vimeoRegex.test(value)) {
                            return true; // Valid Vimeo URL
                        } else {
                            return this.createError({
                                path: this.path,
                                message: "Step 1 Link must be a valid Vimeo URL.",
                            });
                        }
                    }
                }
                // Check if value is a File object and validate file type and size
                if (value instanceof File) {
                    if (value.type !== "video/mp4") {
                        return this.createError({
                            path: this.path,
                            message: "Step 1 File must be an MP4 video file.",
                        });
                    }
                    const maxSizeInBytes = 10 * 1024 * 1024; // 10 MB
                    if (value.size > maxSizeInBytes) {
                        return this.createError({
                            path: this.path,
                            message: "Step 1 File size must not exceed 10MB.",
                        });
                    }
                    return true; // Valid MP4 file within size limit
                }
                return true;
            }
        ),
        step2Link: Yup.mixed()
        .test(
            "is-valid-step2Link",
            "Invalid input. Please provide a valid URL or an MP4 file.", // Default error message
            function (value) {
                // Allow empty values (optional field)
                if (!value) return true;

                // YouTube URL validation
                const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/(watch\?v=[\w-]+|embed\/[\w-]+|shorts\/[\w-]+)|youtu\.be\/[\w-]+)/;
                // Vimeo URL validation
                const vimeoRegex = /^(https?:\/\/)?(www\.)?(vimeo\.com\/(video\/)?\d+|player\.vimeo\.com\/video\/\d+)/;

                if (typeof value === "string") {
                    if (step2Option === 'youTube') {
                        // Validate YouTube URL using the regex
                        if (youtubeRegex.test(value)) {
                            return true; // Valid YouTube URL
                        } else {
                            return this.createError({
                                path: this.path,
                                message: "Step 2 Link must be a valid YouTube URL.",
                            });
                        }
                    }
                    
                    if (step2Option === 'vimeo') {
                        // Validate Vimeo URL using the regex
                        if (vimeoRegex.test(value)) {
                            return true; // Valid Vimeo URL
                        } else {
                            return this.createError({
                                path: this.path,
                                message: "Step 2 Link must be a valid Vimeo URL.",
                            });
                        }
                    }
                }
                
                // Check if value is a File object and validate file type and size
                if (value instanceof File) {
                    if (value.type !== "video/mp4") {
                        return this.createError({
                            path: this.path,
                            message: "Step 2 File must be an MP4 video file.",
                        });
                    }
                    const maxSizeInBytes = 10 * 1024 * 1024; // 10 MB
                    if (value.size > maxSizeInBytes) {
                        return this.createError({
                            path: this.path,
                            message: "Step 2 File size must not exceed 10MB.",
                        });
                    }
                    return true; // Valid MP4 file within size limit
                }
                return true;
            }
        ),
        summaryLink: Yup.mixed()
        .test(
            "is-valid-summaryLink",
            "Invalid input. Please provide a valid URL or an MP4 file.", // Default error message
            function (value) {
                // Allow empty values (optional field)
                if (!value) return true;

                // YouTube URL validation
                const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/(watch\?v=[\w-]+|embed\/[\w-]+|shorts\/[\w-]+)|youtu\.be\/[\w-]+)/;
                // Vimeo URL validation
                const vimeoRegex = /^(https?:\/\/)?(www\.)?(vimeo\.com\/(video\/)?\d+|player\.vimeo\.com\/video\/\d+)/;

                if (typeof value === "string") {
                    if (step3Option === 'youTube') {
                        // Validate YouTube URL using the regex
                        if (youtubeRegex.test(value)) {
                            return true; // Valid YouTube URL
                        } else {
                            return this.createError({
                                path: this.path,
                                message: "Credit Report Summary Link must be a valid YouTube URL.",
                            });
                        }
                    }
                    
                    if (step3Option === 'vimeo') {
                        // Validate Vimeo URL using the regex
                        if (vimeoRegex.test(value)) {
                            return true; // Valid Vimeo URL
                        } else {
                            return this.createError({
                                path: this.path,
                                message: "Credit Report Summary Link must be a valid Vimeo URL.",
                            });
                        }
                    }
                }

                // Check if value is a File object and validate file type and size
                if (value instanceof File) {
                    if (value.type !== "video/mp4") {
                        return this.createError({
                            path: this.path,
                            message: "Credit Report Summary File must be an MP4 video file.",
                        });
                    }
                    const maxSizeInBytes = 10 * 1024 * 1024; // 10 MB
                    if (value.size > maxSizeInBytes) {
                        return this.createError({
                            path: this.path,
                            message: "Credit Report Summary File size must not exceed 10MB.",
                        });
                    }
                    return true; // Valid MP4 file within size limit
                }
                return true;
            }
        ),
    });

    const handleChildValue = (value1) => {
        blankValueIndexes1 = []
        blankValueIndexes2 = []
        operationalhoursvalues = value1
        if(value1.length == 2) {
            value1[0]["timings"].forEach((value, index) => {
                if (value.start === "" || value.start === null || value.start === undefined) {
                    blankValueIndexes1.push(index);
                }
                if (value.end === "" || value.end === null || value.end === undefined) {
                    blankValueIndexes1.push(index);
                }
            });
            value1[1]["timings"].forEach((value, index) => {
                if (value.start === "" || value.start === null || value.start === undefined) {
                    blankValueIndexes2.push(index);
                }
                if (value.end === "" || value.end === null || value.end === undefined) {
                    blankValueIndexes2.push(index);
                }
            });
        }else{
            value1[0]["timings"].forEach((value, index) => {
                if (value.start === "" || value.start === null || value.start === undefined) {
                    blankValueIndexes1.push(index);
                }
                if (value.end === "" || value.end === null || value.end === undefined) {
                    blankValueIndexes1.push(index);
                }
            });
        }
        
    };


    const CreateSnapInfo = async (values) => {
        if (blankValueIndexes1.length > 0) {
            swal({
                title: "Alert",
                text: "Some fields are missing in weekdays (Monday - Friday) shift either delete the shift or add its valus!",
                icon: "error",
                button: "Ok",
            })
        } else if (blankValueIndexes2.length > 0) {
            swal({
                title: "Alert",
                text: "Some fields are missing in weekends (Saturday - Sunday) shift either delete the shift or add its valus!",
                icon: "error",
                button: "Ok",
            })
        } else {
            console.log(values.phoneNumbers)
            const numArray = [];
            let errorFlag = 0;
            const numbers = values.phoneNumbers;
            if (Object.values(numbers).length > 0) {
                numbers.map((row, index) => {
                    if (numArray.includes(row.number.trim())) {
                        alert(row.number.trim() + ' is duplicate, Please check.')
                        errorFlag = 1;
                        return false;
                    } else {
                        numArray.push(row.number.trim());
                    }
                })
            }

            const emailArray = [];
            let errorEmailFlag = 0;
            const emails = values.emails;
            if (Object.values(emails).length > 0) {
                emails.map((row, index) => {
                    if (emailArray.includes(row.email.trim())) {
                        alert(row.email.trim() + ' is duplicate, Please check.')
                        errorEmailFlag = 1;
                        return false;
                    } else {
                        emailArray.push(row.email.trim());
                    }
                })
            }

            if (errorFlag === 1) {
                return false;
            } else if (errorEmailFlag === 1) {
                return false;
            }else {
                setFreez(true);
                operationalhoursvalues = operationalhoursvalues.length == 0 ? receivedshift : operationalhoursvalues;
                const formData = new FormData();
                // Append other fields
                formData.append('phone', JSON.stringify(values.phoneNumbers));
                formData.append('email', JSON.stringify(values.emails));
                formData.append('pid', values.PID1);
                formData.append('aidid', values.AID);
                formData.append('operationalhours', JSON.stringify(operationalhoursvalues));
                formData.append('creditSnapshotLink', values.creditSnapshotLink);
                formData.append('outOfOfficeMsg', values.outOfOfficeMsg);
                formData.append('signupEnabled', values.signupEnabled);
                formData.append('chatEnabled', values.chatEnabled);
                formData.append('emailEnabled', values.emailEnabled);
                formData.append('timezone', values.timezone);

                // Append video file if present
                if (values.step1Link instanceof File) {
                    formData.append('step1Link', values.step1Link); // Append the file
                } else if (values.step1Link) {
                    formData.append('step1Link', values.step1Link); // Append URL if it's a YouTube or Vimeo link
                }
                if (values.step2Link instanceof File) {
                    formData.append('step2Link', values.step2Link); // Append the file
                } else if (values.step2Link) {
                    formData.append('step2Link', values.step2Link); // Append URL if it's a YouTube or Vimeo link
                }
                if (values.summaryLink instanceof File) {
                    formData.append('summaryLink', values.summaryLink); // Append the file
                } else if (values.summaryLink) {
                    formData.append('summaryLink', values.summaryLink); // Append URL if it's a YouTube or Vimeo link
                }

                const apiUrl = '/updateSnapshotDetails';
               
                const res = await dispatch(postFormData({ apiUrl, formData })).unwrap();
                if (res) {
                    if (res.success === true) {
                        fetchCreditInfo();
                        swal({
                            title: "Success",
                            text: res.message,
                            icon: "success",
                            button: "Ok",
                        })
                    } else {
                        swal({
                            title: "Alert",
                            text: "Something went wrong please try again",
                            icon: "error",
                            button: "Ok",
                        })
                    }
                }
                setFreez(false);
            }
        }
    }

    return(
        <>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                >
                    <Freez />
                </Backdrop>
            </div>
            <Card>
                {/* <CardOverflow
                    sx={{
                        bgcolor: '#F6FAFF',
                        borderColor: '#F6FAFF',
                        padding: '1%',
                        mb: 0.5
                    }}
                >
                    <Typography level="h5" >Enter Company Details & operational Hours</Typography>
                </CardOverflow> */}
                {/* formik start */}
                <Formik
                    initialValues={{
                        phoneNumbers: receivedphone ? receivedphone : [{ number: formatPhoneNumber(defaultPhoneNumber), notifications: true, chatoption: true }],
                        PID1: receivedpid ? receivedpid : "",
                        // BusinessName: business,
                        AID: receivedaid ? receivedaid : firstAid,
                        creditSnapshotLink: creditinfo ? creditinfo.data ? creditinfo.data.credit_snapshot_link ? creditinfo.data.credit_snapshot_link : "" : "" : "",
                        outOfOfficeMsg: creditinfo ? creditinfo.data ? creditinfo.data.out_of_office_msg ? creditinfo.data.out_of_office_msg : defaultOutOfOfficeMsg : defaultOutOfOfficeMsg: defaultOutOfOfficeMsg,
                        step1Link: creditinfo ? creditinfo.data ? creditinfo.data.step_1_link ? creditinfo.data.step_1_link : "" : "": "",
                        step2Link: creditinfo ? creditinfo.data ? creditinfo.data.step_2_link ? creditinfo.data.step_2_link : "" : "": "",
                        summaryLink: creditinfo ? creditinfo.data ? creditinfo.data.summary_page_link ? creditinfo.data.summary_page_link : "" : "": "",
                        emails: receivedemails ? receivedemails : [{email: defaultEmail, notifications : true, chatoption: true}],
                        signupEnabled: creditinfo ? creditinfo.data ? creditinfo.data.crs_signup_enabled === 1 ? true : false : false: true,
                        chatEnabled: creditinfo ? creditinfo.data ? creditinfo.data.enable_chat_support === 1 ? true : false : false: true,
                        emailEnabled: creditinfo ? creditinfo.data ? creditinfo.data.enable_email_support === 1 ? true : false : false: true,
                        timezone: creditinfo ? creditinfo.data ? creditinfo.data.timezone? creditinfo.data.timezone:"":"":"",
                    }}

                    validationSchema={validationSchema}
                    onSubmit={(values, errors) => {
                        CreateSnapInfo(values);
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, setFieldTouched, handleSubmit, setFieldValue, setFieldError }) => (
                        <div>
                            <CardContent>
                                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={4} >
                                    <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                                    <Typography level="body1" sx={{ fontWeight: "bold"}}>Contact Information To Receive Notification & Chat Link</Typography>
                                        <FieldArray
                                            name="phoneNumbers"
                                            render={({ push, remove }) => (
                                                <div>
                                                    {values.phoneNumbers.map((phoneNumber, index) => (
                                                        <Grid item lg={12} md={12} sm={12} xs={12} key={index}>
                                                            <FormControl fullWidth margin="dense" size="small" >
                                                                {/* <InputLabel htmlFor="outlined-adornment-password">Phone Number</InputLabel> */}
                                                                <InputMask
                                                                    mask="(999) 999-9999"
                                                                    maskChar={null}
                                                                    disabled={user.roles_id > 3 ? true : false}

                                                                    // label={`Phone Number`}
                                                                    // name={`phoneNumbers[${index}].number`}
                                                                    value={formatPhoneNumber(phoneNumber.number)}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}

                                                                    error={
                                                                        Boolean(phoneNumber.number) &&
                                                                        Boolean(errors.phoneNumbers) &&
                                                                        Boolean(errors.phoneNumbers[index]) &&
                                                                        Boolean(errors.phoneNumbers[index].number)
                                                                    }
                                                                    helperText={
                                                                        errors.phoneNumbers &&
                                                                        errors.phoneNumbers[index] &&
                                                                        errors.phoneNumbers[index].number
                                                                    }

                                                                >
                                                                    {() => <TextField
                                                                        size="small"
                                                                        margin="dense"
                                                                        disabled={user.roles_id > 3 ? true : false}
                                                                        label={`Mobille Phone Number*`}
                                                                        name={`phoneNumbers[${index}].number`}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <CustomInputAdornment position="end" >
                                                                                    {phoneNumber.notifications ? (
                                                                                        <IconButton
                                                                                            color="primary"
                                                                                            onClick={() =>
                                                                                                setFieldValue(
                                                                                                    `phoneNumbers[${index}].notifications`,
                                                                                                    index === 0 ? true : !values.phoneNumbers[index].notifications
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <Tooltip title="Text Notification Enabled"><NotificationsIcon fontSize="small" style={{ color: "#76C044" }} /></Tooltip>
                                                                                        </IconButton>
                                                                                    ) : (
                                                                                        <IconButton
                                                                                            color="secondary"
                                                                                            onClick={() =>
                                                                                                setFieldValue(
                                                                                                    `phoneNumbers[${index}].notifications`,
                                                                                                    !values.phoneNumbers[index].notifications
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <Tooltip title="Text Notification Disabled"><NotificationsOffIcon fontSize="small" style={{ color: "red" }} /></Tooltip>
                                                                                        </IconButton>
                                                                                    )}

                                                                                {phoneNumber.chatoption ? (
                                                                                    <IconButton
                                                                                        color="primary"
                                                                                        onClick={() =>
                                                                                            setFieldValue(
                                                                                                `phoneNumbers[${index}].chatoption`,
                                                                                                index === 0 ? true : !values.phoneNumbers[index].chatoption
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                    <Tooltip title="Chat Notification Enabled"><SpeakerNotesIcon fontSize="small" style={{ color: "#76C044" }} /></Tooltip>
                                                                                        </IconButton>
                                                                                    ) : (
                                                                                        <IconButton
                                                                                            color="secondary"
                                                                                            onClick={() =>
                                                                                                setFieldValue(
                                                                                                    `phoneNumbers[${index}].chatoption`,
                                                                                                    !values.phoneNumbers[index].chatoption
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <Tooltip title="Chat Notification Disabled"><SpeakerNotesOffIcon fontSize="small" style={{ color: "red" }} /></Tooltip>
                                                                                        </IconButton>
                                                                                    )}
                                                                                    {index > 0 && (
                                                                                        <IconButton
                                                                                            color="secondary"
                                                                                            onClick={() => remove(index)}
                                                                                        >
                                                                                            {user.roles_id <= 3 ?
                                                                                                <DeleteOutline
                                                                                                    fontSize="small"

                                                                                                    style={{ color: "grey" }}
                                                                                                />
                                                                                                : null}
                                                                                        </IconButton>
                                                                                    )}
                                                                                    {user.roles_id <= 3 && values.phoneNumbers.length < 5?
                                                                                        <IconButton
                                                                                            color="primary"
                                                                                            onClick={() => {
                                                                                                push({ number: '', notifications: false, chatoption: false})
                                                                                            }}
                                                                                        >
                                                                                            <AddCircleOutlineIcon
                                                                                                fontSize="small"
                                                                                            />
                                                                                        </IconButton>
                                                                                        : null}
                                                                                </CustomInputAdornment>
                                                                            )
                                                                        }}

                                                                    />}
                                                                </InputMask>
                
                                                                {errors.phoneNumbers &&
                                                                    errors.phoneNumbers[index] &&
                                                                    errors.phoneNumbers[index].number && (
                                                                        <FormHelperText error>
                                                                            {errors.phoneNumbers[index].number}
                                                                        </FormHelperText>
                                                                    )}
                                                            </FormControl>
                                                        </Grid>

                                                    ))}
                                                </div>
                                            )}
                                        />
                                         <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                           {/* <TextField
                                                label="Email Address"
                                                variant="outlined"
                                                id="email"
                                                name="email"
                                                size="small"
                                                fullWidth
                                                margin="dense"
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleChange}
                                            />
                                            {touched.email && errors.email ? <div className='error'>{errors.email}</div> : null} */}
                                        </Grid>

                                        <FieldArray
                                            name="emails"
                                            render={({ push, remove }) => (
                                                <div>
                                                    {values.emails.map((email, index) => (
                                                        <Grid item lg={12} md={12} sm={12} xs={12} key={index}>
                                                            <FormControl fullWidth margin="dense" size="small" >
                                                                <TextField
                                                                    size="small"
                                                                    margin="dense"
                                                                    disabled={user.roles_id > 3 ? true : false}
                                                                    value={(email.email)}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        Boolean(email.email) &&
                                                                        Boolean(errors.emails) &&
                                                                        Boolean(errors.emails[index]) &&
                                                                        Boolean(errors.emails[index].email)
                                                                    }
                                                                    label={`Email*`}
                                                                    name={`emails[${index}].email`}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <CustomInputAdornment position="end">
                                                                                {email.notifications ? (
                                                                                    <IconButton
                                                                                        color="primary"
                                                                                        onClick={() =>
                                                                                            setFieldValue(
                                                                                                `emails[${index}].notifications`,
                                                                                                index === 0 ? true : !values.emails[index].notifications
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <Tooltip title="Email Notification Enabled"><NotificationsIcon fontSize="small" style={{ color: "#76C044" }} /></Tooltip>
                                                                                    </IconButton>
                                                                                ) : (
                                                                                    <IconButton
                                                                                        color="secondary"
                                                                                        onClick={() =>
                                                                                            setFieldValue(
                                                                                                `emails[${index}].notifications`,
                                                                                                !values.emails[index].notifications
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <Tooltip title="Email Notification Disabled"><NotificationsOffIcon fontSize="small" style={{ color: "red" }} /></Tooltip>
                                                                                    </IconButton>
                                                                                )}
                                                                                {email.chatoption ? (
                                                                                    <IconButton
                                                                                        color="primary"
                                                                                        onClick={() =>
                                                                                            setFieldValue(
                                                                                                `emails[${index}].chatoption`,
                                                                                                index === 0 ? true : !values.emails[index].chatoption
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                    <Tooltip title="Chat Notification Enabled"><SpeakerNotesIcon fontSize="small" style={{ color: "#76C044" }} /></Tooltip>
                                                                                        </IconButton>
                                                                                    ) : (
                                                                                        <IconButton
                                                                                            color="secondary"
                                                                                            onClick={() =>
                                                                                                setFieldValue(
                                                                                                    `emails[${index}].chatoption`,
                                                                                                    !values.emails[index].chatoption
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <Tooltip title="Chat Notification Disabled"><SpeakerNotesOffIcon fontSize="small" style={{ color: "red" }} /></Tooltip>
                                                                                        </IconButton>
                                                                                    )}
                                                                                {index > 0 && (
                                                                                    <IconButton
                                                                                        color="secondary"
                                                                                        onClick={() => remove(index)}
                                                                                    >
                                                                                        {user.roles_id <= 3 ?
                                                                                            <DeleteOutline
                                                                                                fontSize="small"

                                                                                                style={{ color: "grey" }}
                                                                                            />
                                                                                            : null}
                                                                                    </IconButton>
                                                                                )}
                                                                                {user.roles_id <= 3  && values.emails.length < 5?
                                                                                    <IconButton
                                                                                        color="primary"
                                                                                        onClick={() => {
                                                                                            push({ email: '', notifications: false, chatoption: false })
                                                                                        }}
                                                                                    >
                                                                                        <AddCircleOutlineIcon
                                                                                            fontSize="small"
                                                                                        />
                                                                                    </IconButton>
                                                                                    : null}
                                                                            </CustomInputAdornment>
                                                                        )
                                                                    }}

                                                                />
                                                                {errors.emails &&
                                                                    errors.emails[index] &&
                                                                    errors.emails[index].email && (
                                                                        <FormHelperText error>
                                                                            {errors.emails[index].email}
                                                                        </FormHelperText>
                                                                    )}
                                                            </FormControl>
                                                        </Grid>

                                                    ))}
                                                </div>
                                            )}
                                        />
                                        <Grid container item lg={12} md={12} sm={12} xs={12} >
                                            <Typography level="body1" sx={{ fontWeight: "bold"}}>Upgrade Configuration</Typography>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                            <FormControl size="small" fullWidth margin="dense" error={touched.PID1 && Boolean(errors.PID1)}>
                                                <CustomInputLabel id="demo-simple-select-label">Select Plan To Upgrade From Free To Paid Membership*</CustomInputLabel>
                                                <CustomSelect
                                                    labelId="demo-simple-select-label"
                                                    id="PID1"
                                                    name="PID1"
                                                    value={values.PID1}
                                                    label="Select Plan To Upgrade From Free To Paid Membership"
                                                    onChange={handleChange}
                                                    onBlur={handleChange}
                                                    style={{ width: '100%' }}
                                                    className="textWrap"
                                                    MenuProps={MenuProps}
                                                >
                                                    {pids.map((pid) => (
                                                        <MenuItem key={pid} value={pid}>
                                                            <Chip label={pid} style={{ margin: 2 }} size="small" />
                                                        </MenuItem>
                                                    ))}
                                                </CustomSelect>
                                                {touched.PID1 && errors.PID1 ? <div className='error'>{errors.PID1}</div> : null}
                                            </FormControl>
                                        </Grid>
                                        {Array.isArray(aidlist1) && aidlist1.length > 1 ? (
                                        <Grid item lg={12} md={12} sm={12} xs={12} mt={2} >
                                            <FormControl fullWidth size='small'  error={touched.AID && Boolean(errors.AID)}>
                                                <CustomInputLabel id="demo-multiple-name-label">AID List*</CustomInputLabel>
                                                <CustomSelect
                                                    labelId="demo-multiple-name-label"
                                                    name='AID'
                                                    id='AID'
                                                    input={<OutlinedInput label="AID List" />}
                                                    onChange={handleChange}
                                                    onBlur={handleChange}
                                                    style={{ width: '100%' }}
                                                    MenuProps={MenuProps}
                                                    value={values.AID}
                                                >

                                                    {aidlist1.map((aid) => (
                                                        <MenuItem key={aid.id} value={aid.id}>
                                                            <Chip label={aid.aid} style={{ margin: 2 }} size="small" />
                                                        </MenuItem>
                                                    ))}
                                                   
                                                </CustomSelect>
                                                {touched.AID && errors.AID ? <div className='error'>{errors.AID}</div> : null}
                                            </FormControl>
                                        </Grid>
                                       ): (
                                        ""
                                        )}
                                        {/* <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                            <TextField
                                                label="Enter Your Business Name"
                                                variant="outlined"
                                                id="BusinessName"
                                                name="BusinessName"
                                                size="small"
                                                fullWidth
                                                margin="dense"
                                                disabled
                                                value={values.BusinessName}
                                                onChange={handleChange}
                                                onBlur={handleChange}
                                            />
                                            {touched.BusinessName && errors.BusinessName ? <div className='error'>{errors.BusinessName}</div> : null}
                                        </Grid> */}
                                        
                                    </Grid>
                                    <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{textAlign : 'center',display:"block"}}>
                                            <Typography level="body1" sx={{ fontWeight: "bold", textAlign : 'center'}}>Days & Hours Of Operation </Typography>
                                        </Grid>
                                        <Grid item md={6} container spacing={1} mt={2} sx={{ alignSelf: "flex-start" }}>
                                            <Grid container item md={12}>
                                                <FormControl fullWidth size='small' margin='dense'>
                                                <InputLabel id="demo-multiple-name-label">Timezone*</InputLabel>
                                                <Select
                                                    name="timezone"
                                                    labelId="demo-simple-select-label"
                                                    id="timezone"
                                                    value={values.timezone||''}
                                                    label="Timezone"
                                                    fullWidth
                                                    size='small'
                                                    margin='dense'
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    MenuProps={MenuProps}
                                                >
                                                    <MenuItem value="PST">PST - Pacific Standard Time</MenuItem>
                                                    {/* <MenuItem value="CT">CT - Central Time</MenuItem> */}
                                                    <MenuItem value="EST">EST - Eastern Standard Time</MenuItem>
                                                    <MenuItem value="Asia/Kolkata">IST - Indian Standard Time</MenuItem>
                                                    <MenuItem value="EDT">EDT - Eastern Daylight Time</MenuItem>
                                                    <MenuItem value="UTC">UTC - Coordinated Universal Time</MenuItem>
                                                    <MenuItem value="PDT">PDT - Pacific Daylight Time</MenuItem>
                                                    <MenuItem value="WET">WET - Western European Time</MenuItem>
                                                    <MenuItem value="CDT">CDT - Central Daylight Time</MenuItem>
                                                    <MenuItem value="Australia/Perth">WST - Western Standard Time</MenuItem>
                                                    <MenuItem value="CST">CST - Central Standard Time</MenuItem>
                                                    <MenuItem value="MST">MST - Mountain Standard Time</MenuItem>
                                                    <MenuItem value="HST">HST - Hawaii Standard Time</MenuItem>
                                                </Select>
                                                {touched.timezone && errors.timezone ? <div className='error'>{errors.timezone}</div> : null}
                                                </FormControl>
                                            </Grid>
                                        </Grid>     
                                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                                            <SnapshotOperationalHours id="OperationalHours1" name="OperationalHours1" onValueChange={handleChildValue} props={receivedshift} />
                                        </Grid>
                                        {/* {
                                             values.signupEnabled ?
                                             <>
                                                <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                                    <TextField
                                                        label="Out Of Office Chat Message:"
                                                        variant="outlined"
                                                        id="outOfOfficeMsg"
                                                        name="outOfOfficeMsg"
                                                        size="small"
                                                        fullWidth
                                                        margin="dense"
                                                        value={values.outOfOfficeMsg}
                                                        onChange={handleChange}
                                                        onBlur={handleChange}
                                                    />
                                                {touched.outOfOfficeMsg && errors.outOfOfficeMsg ? <div className='error'>{errors.outOfOfficeMsg}</div> : null}
                                                </Grid>   
                                             </>
                                             :''
                                        } */}
                                                      
                                    </Grid>
                                    
                                       
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                        <Typography level="body1" sx={{ fontWeight: "bold"}}>Add Your Company Videos For Each Enrollment Step (Optional)</Typography>
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
                                        <Grid item lg={4} md={4} sm={12} xs={12} sx={{ display:"flex",flexGrow:1}}>
                                            <Card variant="outlined" sx={{border:"1px solid black",display:"flex",flexGrow:1}}>
                                                <Grid  item lg={12} md={12} sm={12} xs={12}>
                                                    <Grid container item lg={12} md={12} xs={12} sm={12} sx={{display:"flex",justifyContent:"space-between"}}>
                                                        <Grid item lg={3} md={3} xs={3} sm={3}>
                                                            <b>Step 1</b>
                                                        </Grid>
                                                        <Grid item  lg={9} md={9} xs={9} sm={9} sx={{textAlign:"right"}}>
                                                             {step1Option === 'uploadVideo'?
                                                            <Typography sx={{color:"red",fontSize:"12px"}}>(Note: Video Size Should Not Exceed 10Mb)</Typography>
                                                            :""}
                                                        </Grid>
                                                    </Grid>
                                                <FormControl>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        value={step1Option}
                                                        onChange={(event) => handleStep1RadioChange(event, setFieldValue,values)}
                                                    >
                                                        <Stack direction="row" >
                                                        <FormControlLabel value="youTube" control={<Radio />} label={<img
                                                            src={require('../../assets/images/youtubeSnap.png')} // Replace with male icon URL
                                                            alt="Youtube"

                                                            style={{ marginRight: 8,width:"35px" }}
                                                            />} />
                                                        <FormControlLabel value="vimeo" control={<Radio />} label={<img
                                                            src={require('../../assets/images/vimeoLogo.png')} // Replace with male icon URL
                                                            alt="Male"
                                                            style={{ marginRight: 8 ,width:"55px"}}
                                                            />} />
                                                        <FormControlLabel value="uploadVideo" control={<Radio />} label="Upload Video" />
                                                        </Stack>
                                                    </RadioGroup>
                                                </FormControl>
                                                </Grid>
                                                <Grid  item lg={12} md={12} sm={12} xs={12}>
                                                    {step1Option === 'youTube' && (
                                                        <>
                                                        <TextField
                                                            label="YoutTube Video Url"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="dense"
                                                            size="small"
                                                            id="step1Link"
                                                            name="step1Link"
                                                            value={step1Option === 'youTube' ? values.step1Link ? values.step1Link.includes('youtube.com') || values.step1Link.includes('youtube.com/embed')|| values.step1Link.includes('youtu.be')?values.step1Link : '' : '':''}
                                                            onChange={handleChange}
                                                            onBlur={handleChange}
                                                        />
                                                        {touched.step1Link && errors.step1Link ? <div className='error'>{errors.step1Link}</div> : null}
                                                        </>
                                                    )}
                                                    {step1Option === 'vimeo' && (
                                                        <>
                                                        <TextField
                                                            label="Vimeo Video URL"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="dense"
                                                            size="small"
                                                            id="step1Link"
                                                            name="step1Link"
                                                            value={step1Option === 'vimeo' ? values.step1Link ? values.step1Link.includes('vimeo') ? values.step1Link : '': '':'' }
                                                            onChange={handleChange}
                                                            onBlur={handleChange}
                                                        />
                                                        {touched.step1Link && errors.step1Link ? <div className='error'>{errors.step1Link}</div> : null}
                                                        </>
                                                    )}
                                                    {/* {step1Option === 'uploadVideo' && (
                                                        <>
                                                        <TextField
                                                            id="uploadedLogo"
                                                            // label="Logo Name Text"
                                                            variant="outlined"
                                                            margin="dense"
                                                            size="small"
                                                            type="file" 
                                                            name="uploaded"
                                                            fullWidth 
                                                            helperText="Video Size Should Not Exceed 10Mb"
                                                            // ref={fileInputRef}
                                                            // onDrop={(e) => {
                                                            //     e.preventDefault();
                                                            // }}
                                                            // onChange={handleUploadFile}
                                                            // onClick={handleCancelFile}
                                                            onChange={(event) => {
                                                                const file = event.target.files[0];
                                                                if (file) {
                                                                    setFieldValue('step1Link', file);
                                                                }
                                                            }}
                                                            onBlur={handleBlur}
                                                            inputProps={{ accept: 'video/*' }}//only accept png,jpeg,jpg formats
                                                            InputProps={{
                                                                endAdornment:values.step1Link && typeof values.step1Link === 'string' && values.step1Link.includes('mp4') &&  (
                                                                    <InputAdornment position="end">
                                                                        <Tooltip title={values.step1Link.split('/').pop().split('_').slice(1).join('_')} >
                                                                            <IconButton aria-label="show file information">
                                                                                <InfoIcon color="primary"/>
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                       
                                                    {touched.step1Link && errors.step1Link ? <div className='error'>{errors.step1Link}</div> : null}
                                                   
                                                    </>
                                                    )} */}
                                                    {step1Option === 'uploadVideo' && (
                                                        <>
                                                        <FormControl variant="outlined" fullWidth margin="dense" size="small">
                                                        <InputLabel htmlFor="uploadedLogoStep1">Upload Your Video</InputLabel>
                                                        <OutlinedInput
                                                            id="uploadedLogo"
                                                            type="text" // Show the file name instead of the file picker
                                                            value={typeof values.step1Link === "string" && values.step1Link.includes('mp4')
                                                                ? values.step1Link.split('/').pop().split('_').slice(1).join('_')
                                                                : values.step1Link?.name || ""} // Show the file name in the input field
                                                            onBlur={handleBlur}
                                                            endAdornment={
                                                            <InputAdornment position="end">
                                                                <Tooltip
                                                                title={"Upload Video"}
                                                                >
                                                                <IconButton
                                                                    edge="end"
                                                                    onClick={() => document.getElementById("hiddenFileInputStep1").click()}
                                                                >
                                                                    <img
                                                                    src={require("../../assets/images/uploadFile.png")}
                                                                    style={{ height: "25px" }}
                                                                    alt="Upload"
                                                                    />
                                                                </IconButton>
                                                                </Tooltip>
                                                            </InputAdornment>
                                                            }
                                                            label="Upload Your Video"
                                                        />
                                                        <input
                                                            id="hiddenFileInputStep1"
                                                            type="file"
                                                            name="uploaded"
                                                            style={{ display: "none" }} // Hidden input
                                                            accept="video/*"
                                                            onChange={(event) => {
                                                            const file = event.target.files[0];
                                                            if (file) {
                                                                setFieldValue("step1Link", file); // Set the file value
                                                            }
                                                            }}
                                                        />
                                                        </FormControl>
                                                        {touched.step1Link && errors.step1Link ? (
                                                        <div className="error">{errors.step1Link}</div>
                                                        ) : null}
                                                        </>
                                                    )}
                                                   </Grid>
                                        </Card>
                                                
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={12} xs={12} sx={{ display:"flex",flexGrow:1}}>
                                        <Card variant="outlined" sx={{border:"1px solid black", display:"flex",flexGrow:1}}>
                                                <Grid  item lg={12} md={12} sm={12} xs={12}>
                                                <Grid container item lg={12} md={12} xs={12} sm={12} sx={{display:"flex",justifyContent:"space-between"}}>
                                                        <Grid item lg={3} md={3} xs={3} sm={3}>
                                                            <b>Step 2</b>
                                                        </Grid>
                                                        <Grid item  lg={9} md={9} xs={9} sm={9} sx={{textAlign:"right"}}>
                                                            {step2Option === 'uploadVideo' ?
                                                            <Typography sx={{color:"red",fontSize:"12px"}}>(Note: Video Size Should Not Exceed 10Mb)</Typography>
                                                            :""}
                                                        </Grid>
                                                    </Grid>
                                                <FormControl>
                                                    {/* <FormLabel id="demo-row-radio-buttons-group-label"><b>Step 2</b></FormLabel> */}
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        value={step2Option}
                                                        onChange={(event) => handleStep2RadioChange(event, setFieldValue,values)}
                                                    >
                                                        <Stack direction="row" >
                                                        <FormControlLabel value="youTube" control={<Radio />} label={<img
                                                            src={require('../../assets/images/youtubeSnap.png')} // Replace with male icon URL
                                                            alt="Youtube"

                                                            style={{ marginRight: 8,width:"35px" }}
                                                            />} />
                                                        <FormControlLabel value="vimeo" control={<Radio />} label={<img
                                                            src={require('../../assets/images/vimeoLogo.png')} // Replace with male icon URL
                                                            alt="Male"
                                                            style={{ marginRight: 8 ,width:"55px"}}
                                                            />} />
                                                        <FormControlLabel value="uploadVideo" control={<Radio />} label="Upload Video" />
                                                        </Stack>
                                                    </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                <Grid  item lg={12} md={12} sm={12} xs={12}>
                                                    {step2Option === 'youTube' && (
                                                        <>
                                                        <TextField
                                                            label="YoutTube Video Url"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="dense"
                                                            size="small"
                                                            id="step2Link"
                                                            name="step2Link"
                                                            value={step2Option === 'youTube' ? values.step2Link ? values.step2Link.includes('youtube.com') || values.step2Link.includes('youtube.com/embed')|| values.step2Link.includes('youtu.be')?values.step2Link : '' : '':''}
                                                            onChange={handleChange}
                                                            onBlur={handleChange}
                                                        />
                                                        {touched.step2Link && errors.step2Link ? <div className='error'>{errors.step2Link}</div> : null}
                                                        </>
                                                    )}
                                                        {step2Option === 'vimeo' && (
                                                            <>
                                                        <TextField
                                                            label="Vimeo Video URL"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="dense"
                                                            size="small"
                                                            id="step2Link"
                                                            name="step2Link"
                                                            value={step2Option === 'vimeo' ? values.step2Link ? values.step2Link.includes('vimeo') ? values.step2Link : '': '':'' }
                                                            onChange={handleChange}
                                                            onBlur={handleChange}
                                                        />
                                                        {touched.step2Link && errors.step2Link ? <div className='error'>{errors.step2Link}</div> : null}
                                                        </>
                                                    )}
                                                        {/* {step2Option === 'uploadVideo' && (
                                                            <>
                                                        <TextField
                                                            id="uploadedLogo"
                                                            // label="Logo Name Text"
                                                            variant="outlined"
                                                            margin="dense"
                                                            size="small"
                                                            type="file" 
                                                            name="uploaded"
                                                            fullWidth 
                                                            helperText="Video Size Should Not Exceed 10Mb"
                                                            // ref={fileInputRef}
                                                            // onDrop={(e) => {
                                                            //     e.preventDefault();
                                                            // }}
                                                            // onChange={handleUploadFile}
                                                            // onClick={handleCancelFile}
                                                            onChange={(event) => {
                                                                const file = event.target.files[0];
                                                                if (file) {
                                                                    setFieldValue('step2Link', file);
                                                                } 
                                                            }}
                                                            onBlur={handleBlur}
                                                            inputProps={{ accept: 'video/*' }}  //only accept png,jpeg,jpg formats
                                                            InputProps={{
                                                                endAdornment:values.step2Link && typeof values.step2Link === 'string' && values.step2Link.includes('mp4') &&  (
                                                                    <InputAdornment position="end">
                                                                        <Tooltip title={values.step2Link.split('/').pop().split('_').slice(1).join('_')}>
                                                                            <IconButton aria-label="show file information">
                                                                                <InfoIcon color="primary" />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        /> 
                                                        {touched.step2Link && errors.step2Link ? <div className='error'>{errors.step2Link}</div> : null}
                                                        </>
                                                    )} */}

                                                    {step2Option === 'uploadVideo' && (
                                                        <>
                                                        <FormControl variant="outlined" fullWidth margin="dense" size="small">
                                                        <InputLabel htmlFor="uploadedLogoStep2">Upload Your Video</InputLabel>
                                                        <OutlinedInput
                                                            id="uploadedLogo"
                                                            name="uploaded"
                                                            type="text" // Show the file name instead of the file picker
                                                            value={typeof values.step2Link === "string"  && values.step2Link.includes('mp4')
                                                                ? values.step2Link.split('/').pop().split('_').slice(1).join('_')
                                                                : values.step2Link?.name || ""} // Show the file name in the input field
                                                            onBlur={handleBlur}
                                                            endAdornment={
                                                            <InputAdornment position="end">
                                                                <Tooltip
                                                                title={"Upload Video"}
                                                                >
                                                                <IconButton
                                                                    edge="end"
                                                                    onClick={() => document.getElementById("hiddenFileInputStep2").click()}
                                                                >
                                                                    <img
                                                                    src={require("../../assets/images/uploadFile.png")}
                                                                    style={{ height: "25px" }}
                                                                    alt="Upload"
                                                                    />
                                                                </IconButton>
                                                                </Tooltip>
                                                            </InputAdornment>
                                                            }
                                                            label="Upload Your Video"
                                                        />
                                                        <input
                                                            id="hiddenFileInputStep2"
                                                            type="file"
                                                            name="uploaded"
                                                            style={{ display: "none" }} // Hidden input
                                                            accept="video/*"
                                                            onChange={(event) => {
                                                            const file = event.target.files[0];
                                                            if (file) {
                                                                setFieldValue('step2Link', file); // Set the file value
                                                            }
                                                            }}
                                                        />
                                                        </FormControl>
                                                        {touched.step2Link && errors.step2Link ? (
                                                        <div className="error">{errors.step2Link}</div>
                                                        ) : null}
                                                        </>
                                                    )}  


                                                    </Grid>
                                            </Card>
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={12} xs={12} sx={{ display:"flex",flexGrow:1}} >
                                        <Card variant="outlined" sx={{border:"1px solid black", display:"flex",flexGrow:1}}>
                                                <Grid  item lg={12} md={12} sm={12} xs={12}>
                                                <Grid container item lg={12} md={12} xs={12} sm={12} sx={{display:"flex",justifyContent:"space-between"}}>
                                                        <Grid item lg={6} md={6} xs={7} sm={7}>
                                                            <b>Credit Report Summary Page</b>
                                                        </Grid>
                                                        <Grid item  lg={6} md={6} xs={5} sm={5} sx={{textAlign:"right"}}>
                                                            {step3Option === 'uploadVideo'?
                                                            <Typography sx={{color:"red",fontSize:"12px"}}>(Note: Video Size Should Not Exceed 10Mb)</Typography>
                                                            :""}
                                                        </Grid>
                                                    </Grid>
                                                <FormControl>
                                                    {/* <FormLabel id="demo-row-radio-buttons-group-label"><b>Credit Report Summary Page</b></FormLabel> */}
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        value={step3Option}
                                                        onChange={(event) => handleStep3RadioChange(event, setFieldValue,values)}
                                                    >
                                                        <Stack direction="row">
                                                        <FormControlLabel value="youTube" control={<Radio />} label={<img
                                                            src={require('../../assets/images/youtubeSnap.png')} // Replace with male icon URL
                                                            alt="Youtube"

                                                            style={{ marginRight: 8,width:"35px" }}
                                                            />} />
                                                        <FormControlLabel value="vimeo" control={<Radio />} label={<img
                                                            src={require('../../assets/images/vimeoLogo.png')} // Replace with male icon URL
                                                            alt="Male"
                                                            style={{ marginRight: 8 ,width:"55px"}}
                                                            />} />
                                                        <FormControlLabel value="uploadVideo" control={<Radio />} label="Upload Video" />
                                                        </Stack>
                                                    </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                <Grid  item lg={12} md={12} sm={12} xs={12}>
                                                    {step3Option === 'youTube' && (
                                                        <>
                                                        <TextField
                                                            label="YoutTube Video Url"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="dense"
                                                            size="small"
                                                            id="summaryLink"
                                                            name="summaryLink"
                                                            value={step3Option === 'youTube' ? values.summaryLink ? values.summaryLink.includes('youtube.com') || values.summaryLink.includes('youtube.com/embed')|| values.summaryLink.includes('youtu.be')?values.summaryLink : '' : '':''}
                                                            onChange={handleChange}
                                                            onBlur={handleChange}
                                                        />
                                                        {touched.summaryLink && errors.summaryLink ? <div className='error'>{errors.summaryLink}</div> : null}
                                                        </>
                                                    )}
                                                    {step3Option === 'vimeo' && (
                                                        <>
                                                        <TextField
                                                            label="Vimeo Video URL"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="dense"
                                                            size="small"
                                                            id="summaryLink"
                                                            name="summaryLink"
                                                            value={step3Option === 'vimeo' ? values.summaryLink ? values.summaryLink.includes('vimeo') ? values.summaryLink : '': '':'' }
                                                            onChange={handleChange}
                                                            onBlur={handleChange}
                                                        />
                                                        {touched.summaryLink && errors.summaryLink ? <div className='error'>{errors.summaryLink}</div> : null}
                                                        </>
                                                    )}
                                                    {/* {step3Option === 'uploadVideo' && (
                                                        <>
                                                        <TextField
                                                            id="uploadedLogo"
                                                            // label="Logo Name Text"
                                                            variant="outlined"
                                                            margin="dense"
                                                            size="small"
                                                            type="file" 
                                                            name="uploaded"
                                                            fullWidth 
                                                            helperText="Video Size Should Not Exceed 10Mb"
                                                            // ref={fileInputRef}
                                                            // onDrop={(e) => {
                                                            //     e.preventDefault();
                                                            // }}
                                                            // onChange={handleUploadFile}
                                                            // onClick={handleCancelFile}
                                                            
                                                            onChange={(event) => {
                                                                const file = event.target.files[0];
                                                                if (file ) {
                                                                    setFieldValue('summaryLink', file);
                                                                } 
                                                            }}
                                                            onBlur={handleBlur}
                                                            inputProps={{ accept: 'video/*' }}  //only accept png,jpeg,jpg formats
                                                            InputProps={{
                                                                endAdornment:values.summaryLink && typeof values.summaryLink === 'string' && values.summaryLink.includes('mp4') &&  (
                                                                    <InputAdornment position="end">
                                                                        <Tooltip title={values.summaryLink.split('/').pop().split('_').slice(1).join('_')}>
                                                                            <IconButton aria-label="show file information">
                                                                                <InfoIcon color="primary" />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        /> 
                                                        {touched.summaryLink && errors.summaryLink ? <div className='error'>{errors.summaryLink}</div> : null}
                                                        </>
                                                    )} */}


                                                    {step3Option === 'uploadVideo' && (
                                                        <>
                                                        <FormControl variant="outlined" fullWidth margin="dense" size="small">
                                                        <InputLabel htmlFor="uploadedLogoStep3">Upload Your Video</InputLabel>
                                                        <OutlinedInput
                                                            id="uploadedLogo"
                                                            name="uploaded"
                                                            type="text" // Show the file name instead of the file picker
                                                            value={typeof values.summaryLink === "string" && values.summaryLink.includes('mp4')
                                                                ? values.summaryLink.split('/').pop().split('_').slice(1).join('_')
                                                                : values.summaryLink?.name || ""} // Show the file name in the input field
                                                            onBlur={handleBlur}
                                                            endAdornment={
                                                            <InputAdornment position="end">
                                                                <Tooltip
                                                                title={"Upload Video"}
                                                                >
                                                                <IconButton
                                                                    edge="end"
                                                                    onClick={() => document.getElementById("hiddenFileInput3").click()}
                                                                >
                                                                    <img
                                                                    src={require("../../assets/images/uploadFile.png")}
                                                                    style={{ height: "25px" }}
                                                                    alt="Upload"
                                                                    />
                                                                </IconButton>
                                                                </Tooltip>
                                                            </InputAdornment>
                                                            }
                                                            label="Upload Your Video"
                                                        />
                                                        <input
                                                            id="hiddenFileInput3"
                                                            type="file"
                                                            name="uploaded"
                                                            style={{ display: "none" }} // Hidden input
                                                            accept="video/*"
                                                            onChange={(event) => {
                                                                const file = event.target.files[0];
                                                                if (file ) {
                                                                    setFieldValue('summaryLink', file);
                                                                } 
                                                            }}
                                                        />
                                                        </FormControl>
                                                        {touched.summaryLink && errors.summaryLink ? (
                                                        <div className="error">{errors.summaryLink}</div>
                                                        ) : null}
                                                        </>
                                                    )} 


                                                    </Grid>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={1} >
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <FormControlLabel control={<Checkbox checked={values.signupEnabled} />} 
                                                label={
                                                    <span style={{ fontWeight: "bold" }}>
                                                       Add Your Auto Customer Signup Link For Your CRM
                                                    </span>
                                                }
                                                onChange={(e) => {   
                                                    // showOrHideChatInputs(!values.signupEnabled)
                                                    setFieldValue('signupEnabled',!values.signupEnabled,true)
                                                    setSignupLinkLabel("Customer Signup Link*");
                                                }}
                                            />
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
                                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                                    <FormControlLabel control={<Checkbox checked={values.emailEnabled} />} 
                                                        label={
                                                            <span style={{ fontWeight: "bold" }}>Enable Email Support</span>
                                                        } onChange={(e) => {   
                                                            // showOrHideChatInputs(!values.signupEnabled)
                                                            setFieldValue('emailEnabled',!values.emailEnabled,true)
                                                        }}/>
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                                    <FormControlLabel control={<Checkbox checked={values.chatEnabled} />} 
                                                    label={
                                                        <span style={{ fontWeight: "bold" }}>Enable Chat Support</span>
                                                    } onChange={(e) => {   
                                                        // showOrHideChatInputs(!values.signupEnabled)
                                                        setFieldValue('chatEnabled',!values.chatEnabled,true)
                                                        setOutOfOfficeLabel("Out Of Office Message For Chat Support*");
                                                    }}/>
                                               
                                                </Grid>
                                            </Grid>
                                            </Grid>    
                                        </Grid>
                                            <>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
                                            {
                                            values.signupEnabled ?
                                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                                   
                                                    <TextField
                                                        label={signupLinkLabel}
                                                        variant="outlined"
                                                        id="creditSnapshotLink"
                                                        name="creditSnapshotLink"
                                                        size="small"
                                                        fullWidth
                                                        margin="dense"
                                                        value={values.creditSnapshotLink}
                                                        onChange={handleChange}
                                                        onBlur={handleChange}
                                                        InputProps={{
                                                            endAdornment: (
                                                            <InputAdornment position="end">
                                                               <ClickAwayListener onClickAway={handleTooltipClose}>
                                                                    <div>
                                                                        <Tooltip
                                                                        PopperProps={{
                                                                            disablePortal: true, // Ensures the tooltip stays within bounds
                                                                        }}
                                                                        onClose={handleTooltipClose}
                                                                        open={openTooltip}
                                                                        disableFocusListener
                                                                        disableHoverListener
                                                                        disableTouchListener
                                                                        title={
                                                                            <div >
                                                                             <p sx={{fontSize:"100px" }}>
                                                                                Enter The Link Below <br />
                                                                                Where Your Clients Can <br />
                                                                                Automatically Signup <br />
                                                                                For Your Services.
                                                                            </p>
                                                                            </div>
                                                                        }
                                                                        placement="top-start"
                                                                        interactive
                                                                        >
                                                                        <IconButton
                                                                            aria-label="toggle tooltip"
                                                                            edge="end"
                                                                            onClick={handleTooltipOpen}
                                                                        >
                                                                            <img src={iIcon} alt="info" />
                                                                        </IconButton>
                                                                        </Tooltip>
                                                                    </div>
                                                                </ClickAwayListener>
                                                            </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                    {touched.creditSnapshotLink && errors.creditSnapshotLink ? <div className='error'>{errors.creditSnapshotLink}</div> : null}
                                                    
                                                </Grid>
                                                :<Grid item lg={6} md={6} sm={6} xs={6}>
                                                </Grid>}
                                                {values.chatEnabled ?
                                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                                   
                                                        <TextField
                                                            label={outOfOfficeLabel}
                                                            variant="outlined"
                                                            id="outOfOfficeMsg"
                                                            name="outOfOfficeMsg"
                                                            size="small"
                                                            fullWidth
                                                            margin="dense"
                                                            value={values.outOfOfficeMsg}
                                                            onChange={handleChange}
                                                            onBlur={handleChange}
                                                        />
                                                    {touched.outOfOfficeMsg && errors.outOfOfficeMsg ? <div className='error'>{errors.outOfOfficeMsg}</div> : null}
                                                   
                                                </Grid>
                                                :""}
                                            </Grid>
                                            
                                            </>
                                           
                                       
                                       
                                        
                                    {user.roles_id <= 3 ?
                                    <>
                                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "center" }} mt={2}>
                                            <Button sx={{ minWidth:350,maxWidth:300 }} className="CustomButton" onClick={handleSubmit}>
                                            SAVE INFORMATION
                                            </Button>
                                        </Grid>
                                         <Grid item lg={12} md={12} sm={12} xs={12} mt={2} sx={{display: "flex",justifyContent:"center", alignItems: "center",}}>
                                         <p style={{fontSize:"16px",textAlign:"center",display: "flex",alignItems: "center", margin: 0,}}><span><i><img src={require('../../assets/images/snapVector.png')} alt="Snap Vector" style={{width:"25px",verticalAlign: "middle",}}/> Ensure All Required Fields Are Completed And Remember To Save.</i> </span></p>
                                         </Grid>
                                         </>
                                        : null}
                            </CardContent>
                        </div>
                    )}
                </Formik>
                {/* formik end */}
            </Card>
        </>
    );
}

export default CompanyDetails;