import React, { useState } from "react";
import { DialogContent, Grid, Select, FormControl, MenuItem, InputLabel, Box, TextField, Dialog, InputAdornment, DialogActions, OutlinedInput, Backdrop, DialogTitle, Tab } from '@mui/material';
import { Typography, Card, CardContent, CardOverflow, Button, List, ListItem, ListItemDecorator, ListItemContent } from '@mui/joy';
import AuthUser from '../Auth/AuthUser';

const SnapshotCodeToSetPopup = ({firstlogoUrl,receivedpid,snapshotStr}) => {
  const [snippetCopy, setSnippetCopy] = useState('Copy');
  const { user } = AuthUser();

    return(
        <>
          {firstlogoUrl && receivedpid ?
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={2}>
                {/* <Grid item xl={6} lg={6} md={12} sm={12} xs={12}> */}
                    <Card className='cardstyle' >
                        <CardOverflow
                            sx={{
                                bgcolor: '#F6FAFF',
                                borderColor: '#F6FAFF',
                                padding: '1%',
                                mb: 0.5,
                            }}
                        >
                            <Typography level="h5" sx={{textAlign : 'center'}}>Your snippet code to add pop-up to your site</Typography>
                        </CardOverflow>
                        <CardContent >
                            <p className="rectangle1" style={{wordBreak: "break-all"}}>
                               
                                {snapshotStr}
                            </p>
                            {user.roles_id >= 3 && user.roles_id <= 5 ?
                                <Grid item  sx={{display:"flex",justifyContent:"center"}}>
                                 <Button color="success" sx={{ borderRadius: "0% !important", backgroundColor: "#76C044", minWidth: 150, }} 
                                 onClick={() => {
                                     navigator.clipboard.writeText(snapshotStr)
                                     setSnippetCopy(snapshotStr)
                                     setTimeout(() => {
                                         setSnippetCopy('Copy');
                                     }, 3000);
                                 }} >{snippetCopy == snapshotStr ? "Copied!" : 'Copy'}
                                 </Button>
                                </Grid>
                                : null}
                        </CardContent>
                    </Card>
                {/* </Grid> */}
            </Grid>
            : null}
        </>
    );
}

export default SnapshotCodeToSetPopup;