import React, { useMemo, useState, useEffect } from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import MaterialReactTable from 'material-react-table';
import AuthUser from '../../../Components/Auth/AuthUser';
import { format, subDays } from 'date-fns';
import {Box, Button, Dialog, DialogContent, DialogTitle,Grid, FormControl, Select, MenuItem, InputLabel, OutlinedInput,Tooltip,DialogActions
} from '@mui/material';
import { Typography } from '@mui/joy';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import ConatctModal from '../../../Components/ContactLog/ContactLog';
import { makeStyles } from '@material-ui/core/styles';
import { DateRangePicker,createStaticRanges } from 'react-date-range';
import Loader from '../../../Components/Loader/Loader';
import Moment from 'moment';
import { useTheme } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader'
import config from '../../../AppConfig';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import { useNavigate } from 'react-router-dom';
import formatPhoneNumber from '../../../Components/Formats/PhoneNumberValidation';
import { calendarFilter,useDateSelectionRange } from '../../../Components/CommonFormula';
import swal from "sweetalert";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

let whoC = "Member";
let CallTypeDataListLET = [];
//css for contact type modal start
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));


//css for contact type modal end
function ContactLog() {
  const dispatch = useDispatch();
  const { user,http } = AuthUser();
  const [adminList, setAdminList] = React.useState([]);
  const [filterCompany, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [adminid, setAdminid] = useState();
  const [assignedTo, setAssignedTo] = useState('all');
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const q = search == '' ? 'null' : search;
  const [open, setOpen] = React.useState(false);

  const theme = useTheme();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const [rowSelection, setRowSelection] = useState({});
  const { selectionRange, setSelectionRange, startDate, endDate,clearFilter } = useDateSelectionRange();
  const [openConfrimationBox, setOpenConfrimationBox] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const navigate = useNavigate();
  //loader function and variable start

  const [freez, setFreez] = React.useState(false);
  const handleFreezStop = () => {
    setFreez(false);
  };
  const handleFreezStart = () => {
    setFreez(true);
  };

  //loader function and variable end

  const sideBar = calendarFilter();
  const staticRanges = [
      // ...defaultStaticRanges,
      ...createStaticRanges(sideBar)
  ];

  const fetchAdminList = async () => {
    handleFreezStart();
    const apiUrl = '/fetchOnlyAdmins';
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if (res) {
      handleFreezStop();
      setAdminList(res);
    }
  };

  const fetchUserList = async page => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id != 1 && loginUser.roles_id != 2 && loginUser.roles_id != 6 && loginUser.roles_id != 13)
     {
      navigate('/login');
      return false;
    }
    setErrorMessage("")
    setIsLoading(true)

    const apiUrl = '/fetchMemberDisposition';
    const args = {
      assignto: assignedTo,
      fromDate: startDate,
      toDate: endDate
    }
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if (res) {
      setData(res)
      setIsLoading(false)
    } else {
      setErrorMessage(" went wrong.")
      setIsLoading(false)
    }
  }
  useEffect(() => {
    if (user.roles_id < 3 || user.roles_id == 6|| user.roles_id==13) {
      fetchUserList();
    } else {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    fetchAdminList();

  }, []);

  
  const handleSelectChange = (event) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    const selectedValue = event.target.value; // Get the selected value from event
    setAssignedTo(selectedValue); // Set the selected value to state  
  }
  

  //contact type modal code start
  const classes = useStyles();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose1 = () => {
    setOpen(false);
   
  };
  const deleteContactLog=async ()=>{
    setOpenConfrimationBox(false)
    setIsLoading(true)
    const apiUrl = '/deleteContactLog';
    const args = {
      contactLogId : deleteId,
    };
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if (res) {
      fetchUserList()
      setIsLoading(false)
      if (res.success === true) {
          swal({
              title: "Success",
              text: 'Contact log has been deleted successfully',
              icon: "success",
              button: "Ok",
          })
      } else {
          setIsLoading(false)
          alert("something went wrong !");
      }
    }
  }
  //contact type modal code end
  const UserListColumns = useMemo(

    () => [
      {
        id: 'employee', //id used to define `group` column
       
        columns: [
          {
            accessorFn: (row) => `${(row.user) ? (row.user.name) ? row.user.name.trim() : 'N/A' : 'N/A'}`,
            id: 'Agent', //id is still required when using accessorFn instead of accessorKey
            header: 'Agent',
            size: 150,

          },
          {
            accessorFn: (row) => `${row.member_id ? row.member_id : 'N/A'}`, //accessorFn used to join multiple data into a single cell
            id: 'Member Id', //id is still required when using accessorFn instead of accessorKey
            header: 'Member Id',
            size: 150,
          },
          {
            accessorFn: (row) => `${row.email ? row.email.trim() : 'N/A'}`, //accessorFn used to join multiple data into a single cell
            id: 'Email Address', //id is still required when using accessorFn instead of accessorKey
            header: 'Email Address',
            size: 150,
          },
          {
            // accessorFn: (row) => `${(row.account_status) ? (row.account_status) ? row.account_status:'N/A' : 'N/A'}`,
            accessorFn: (row) => `${row.customer_details ? row.customer_details.account_status.trim() : ''}`, //accessorFn used to join multiple data into a single cell
            id: 'Member_Status', //id is still required when using accessorFn instead of accessorKey
            header: 'Member Status',
            size: 150,
            Cell: ({ renderedCellValue, row }) => (row.original.customer_details ? row.original.customer_details.account_status.trim() : 'N/A'),
          },
          {
            accessorFn: (row) => `${row.who_contacted ? row.who_contacted.trim() : ''}`, //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
            header: 'Type',
            id: 'Type',
            size: 150,
            Cell: ({ renderedCellValue, row }) => (row.original.who_contacted ? row.original.who_contacted.trim() : 'N/A'),
          },

          {
            accessorFn: (row) => `${(row.aid_master) ? (row.aid_master.aid) ? row.aid_master.aid.trim() : '' : ''}`,
            id: 'AID',
            header: 'AID',
            size: 150,
            Cell: ({ renderedCellValue, row }) => ((row.original.aid_master) ? (row.original.aid_master.aid) ? row.original.aid_master.aid.trim() : 'N/A' : 'N/A'),
          },
          {
            accessorFn: (row) => `${row.mobile_no ? formatPhoneNumber(row.mobile_no.trim()) : 'N/A'}`, //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
            id: 'Contact_No',
            header: 'Contact No',
            size: 150,
          },
          {
            accessorFn: (row) => `${row.updated_at ? row.updated_at : ''}`,
            id: 'Date',
            header: 'Date',
            size: 150,
            Cell: ({ renderedCellValue, row }) => (
              <div>
                {row.original.updated_at ? Moment(row.original.updated_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') : 'N/A'}
              </div>
            ),
          },
          {
            accessorFn: (row) => `${row.source ? row.source.trim() : ''}`, //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
            id: 'Source',
            header: 'Source',
            size: 150,
          },
          {
            accessorFn: (row) => `${row.call_source ? row.call_source.trim() : ''}`, //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
            id: 'Contact Type',
            header: 'Contact Type',
            size: 150,
            Cell: ({ renderedCellValue, row }) => (
              <div>
                {row.original.call_source ? row.original.call_source.trim() : 'N/A'}
              </div>
            ),
          },
          {
            accessorFn: (row) => `${(row.call_type) ? (row.call_type.call_type) ? row.call_type.call_type.trim() : '' : ''}`,
            id: 'Contact Reason',
            header: 'Contact Reason',
            size: 150,
            Cell: ({ renderedCellValue, row }) => (
              <div>
                {(row.original.call_type) ? (row.original.call_type.call_type) ? row.original.call_type.call_type.trim() : 'N/A' : 'N/A'}
              </div>
            ),
          },
          {
            accessorFn: (row) => `${(row.call_type_disposition) ? (row.call_type_disposition.disposition) ? row.call_type_disposition.disposition.trim() : '' : ''}`,
            id: 'Disposition',
            header: 'Disposition',
            size: 150,
            Cell: ({ renderedCellValue, row }) => (
              <div>
                {(row.original.call_type_disposition) ? (row.original.call_type_disposition.disposition) ? row.original.call_type_disposition.disposition.trim() : 'N/A' : 'N/A'}
              </div>
            ),
          },
          {
            accessorFn: (row) => `${row.call_type_reason && row.call_type_reason !== null
              ? row.call_type_reason.reason.trim() !== ''
                ? row.call_type_reason.reason.trim()
                : ''
              : ''}`,
            id: 'Reasons',
            header: 'Reasons',
            size: 150,
            Cell: ({ renderedCellValue, row }) => (
              <div>
                {row.original.call_type_reason && row.original.call_type_reason !== null
                  ? row.original.call_type_reason.reason.trim() !== ''
                    ? row.original.call_type_reason.reason.trim()
                    : 'N/A'
                  : 'N/A'}
              </div>
            ),
          },
          {
            accessorFn: (row) => `${row.what_was_offred ? row.what_was_offred.trim() : ''}`, //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
            id: 'What_Was_Offered',
            header: 'What Was Offered?',
            size: 150,
            Cell: ({ renderedCellValue, row }) => (
              <div>
                {row.original.what_was_offred ? row.original.what_was_offred.trim() : 'N/A'}
              </div>
            ),
          },
          {
            accessorFn: (row) => `${row.walkthrough_offred ? row.walkthrough_offred.trim() : ''}`, //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
            id: 'Was_Walk_Through_offered',
            header: 'Was Walk Through offered?',
            size: 150,
            Cell: ({ renderedCellValue, row }) => (
              <div>
                {row.original.walkthrough_offred ? row.original.walkthrough_offred.trim() : 'N/A'}
              </div>
            ),
          },
          {
            accessorFn: (row) => `${row.walkthrough_conducted ? row.walkthrough_conducted.trim() : ''}`, //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
            id: 'Was_Walk_Through_conducted',
            header: 'Was Walk Through conducted?',
            size: 150,
            Cell: ({ renderedCellValue, row }) => (
              <div>
                {row.original.walkthrough_conducted ? row.original.walkthrough_conducted.trim() : 'N/A'}
              </div>
            ),
          },
          {
            accessorFn: (row) => `${row.notes ? row.notes.trim() : ''}`,//accessorKey used to define `data` column. `id` gets set to accessorKey automatically
            id: 'Notes',
            header: 'Notes',
            size: 100,
            Cell: ({ renderedCellValue, row }) => (
              <div className='datatable'>
                {row.original.notes ? row.original.notes.trim() : 'N/A'}
              </div>
            ),
          },
        ],
      },
      ...(user.roles_id==13 ? [{
        accessorFn: (row) => (
              <Tooltip title="Delete" arrow>
                  <img src={require('../../../assets/icons/deleteIcon2.png')}  onClick={()=>{setDeleteId(row.id);setOpenConfrimationBox(true)}}/>
          </Tooltip>
        ),
        id: 'Action',
        header: 'Action',
        size: 20,
    }] : []),
    ],
    [],
  );

  const tablePreferencesArray = {
    Notes: false,
    Was_Walk_Through_conducted: false,
    Was_Walk_Through_offered: false,
    What_Was_Offered: false,
    Reasons: false,
    Member_Status: false
  }


  useEffect(() => {
  }, [rowSelection]);
  ////////////////////////
  useEffect(() => {
    if (Object.values(columnVisibility).length > 0) {
      setColumnVisibility(columnVisibility);
    }
  }, [rowSelection]);

  useEffect(() => {
    tablePreferences();
  }, [rowSelection]);
  ////////////////////////

  useEffect(() => {
    saveTablePreferences();
  }, [columnVisibility])

  const saveTablePreferences = async () => {
    if (Object.values(columnVisibility).length > 0) {
      const apiUrl = '/saveTablePreferences';
      const args = {
        columnArray: columnVisibility,
        userId: user.id,
        tableId: 'contactLog'
      }
      const res = await dispatch(postData({ apiUrl, args })).unwrap();
    }
  }

  const tablePreferences = async () => {
    const apiUrl = "/fetchTablePreferences/contactLog";
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if (res) {
      if (Object.values(res).length == 0) {
        setColumnVisibility(tablePreferencesArray);
      } else {
        setColumnVisibility((JSON.parse(res.preferences)));
        console.log(columnVisibility);
      }
    }
  }
  // CSV start here
  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    fileName: `Customer Contact Disposition Logs`, // Add a dynamic file name using the current date
    headers: ['Agent', 'Member Id','email', 'Member Status', 'Type', 'AID', 'Contact No', 'Date', 'source','Contact Type', 'Contact Reasons', 'Desposition', 'Reasons', 'What Was Offered?', 'Was Walk Through offered?', 'Was Walk Through conducted?', 'Notes'],
  };

  const csvExporter = new ExportToCsv(csvOptions);
  const handleExportData = (filterCompany) => {
    const exportedData = filterCompany.map((row) => {
      return {
        agent: (row.original.user) ? (row.original.user.name) ? row.original.user.name : 'N/A' : 'N/A',
        member_id: row.original.member_id ? row.original.member_id : 'N/A',
        email: row.original.email ? row.original.email.trim() : 'N/A',
        member_status: row.original.customer_details ? row.original.customer_details.account_status : 'N/A',
        type: row.original.who_contacted ? row.original.who_contacted : 'N/A',
        aid: (row.original.aid_master) ? (row.original.aid_master.aid) ? row.original.aid_master.aid : 'N/A' : 'N/A',
        contact_no: row.original.mobile_no ? formatPhoneNumber(row.original.mobile_no) : 'N/A',
        date: row.original.created_at ? Moment(row.original.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') : 'N/A',
        source:row.original.source ? row.original.source.trim() : 'N/A',
        contact_type: row.original.call_source ? row.original.call_source : 'N/A',
        contact_reason: (row.original.call_type) ? (row.original.call_type.call_type) ? row.original.call_type.call_type : 'N/A' : 'N/A',
        disposition: (row.original.call_type_disposition) ? (row.original.call_type_disposition.disposition) ? row.original.call_type_disposition.disposition : 'N/A' : 'N/A',
        reason: (row.original.call_type_reason) ? (row.original.call_type_reason.reason) ? row.original.call_type_reason.reason : 'N/A' : 'N/A',
        what_was_offred: row.original.what_was_offred ? row.original.what_was_offred : 'N/A',
        what_walk_through_offered: row.original.walkthrough_offred ? row.original.walkthrough_offred : 'N/A',
        was_walk_through_conducted: row.original.walkthrough_conducted ? row.original.walkthrough_conducted : 'N/A',
        note: row.original.notes ? row.original.notes.trim() : 'N/A',
      };
    });
  // Convert data to CSV format
  const csvContent = convertToCSV(exportedData);

  // Create a Blob object from the CSV data
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

  // Create a download link element
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = csvOptions.fileName + '.csv';

  // Append the link to the document body
  document.body.appendChild(link);

  // Programmatically click the link to trigger the file download
  link.click();

  // Clean up the temporary URL and remove the link from the document
  URL.revokeObjectURL(link.href);
  link.remove();
};

const convertToCSV = (data) => {
  const headers = csvOptions.headers.join(',') + '\n';

  const rows = data.map((row) => {
    return Object.values(row).map((value) => {
      // Handle any necessary formatting or escaping of values
      // For simplicity, we assume all values are already properly formatted

      // Replace newline characters with spaces
      if (typeof value === 'string') {
        value = value.replace(/\n/g, ' '); // Replace newline characters with spaces
      }

      // Wrap the value with double quotes if it contains a comma or quote
      if (typeof value === 'string' && (value.includes(',') || value.includes('"'))) {
        return `"${value.replace(/"/g, '""')}"`; // Escape double quotes by doubling them
      }

      return value;
    }).join(',');
  }).join('\n');

  return headers + rows;
};
  // CSV end here

  const ContentLayout=

   
      <>
        {isLoading ? <><Loader /></> : ''}
        <Grid container item lg={12} md={12} sm={12} xs={12} mb={2}>
          <Grid item xs>
            <Typography level="h4" color="primary">Customer Contact Disposition Logs</Typography>
          </Grid>
          
        </Grid>
        <Grid container item lg={12} md={12} sm={12} xs={12} mb={2} spacing={2}>
          <Grid item xs>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-multiple-name-label">Assigned to</InputLabel>
              <Select
                id="assignedto"
                name="assignedto"
                input={<OutlinedInput label="Assigned To" />}
                value={assignedTo}
                onChange={handleSelectChange}
                MenuProps={MenuProps}
                size='small'
                sx={{ minWidth: 300 }}
              >
                <MenuItem
                  value='all'
                >
                  All
                </MenuItem>
                {adminList.map((array,index) => (
                  <MenuItem
                  key={index}
                    value={array.id}
                  >
                    {array.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs>
            <FormControl fullWidth size='small'>
              <InputLabel id="demo-multiple-name-label">Select Date Range</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value="date"
                input={<OutlinedInput label="Select Date Range" />}
              >
                <MenuItem value="date" >{startDate ? <>{startDate} To {endDate}</> : ''}</MenuItem>
                <DateRangePicker
                  className='custom-date-picker'
                  onChange={item => setSelectionRange([item.selection])}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={selectionRange}
                  direction="horizontal"
                  maxDate={new Date()}
                  staticRanges={staticRanges}
                />
                 
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs >
            <Button color="primary" onClick={fetchUserList} variant="contained" sx={{ minWidth: 150 }}>
              Submit
            </Button>
          </Grid>

          
        </Grid>
        <Box mt={2}>
          <MaterialReactTable
            columns={UserListColumns}
            data={filterCompany}
            filterFns={{
              customSearchFilterFn: (row, id, filterValue) =>
              row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
            }} 
            globalFilterFn="customSearchFilterFn" //exact serach filter functionality
            enableColumnFilterModes
            onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
            state={{ columnVisibility, rowSelection }} //pass our managed row selection state to the table to use
            initialState={{ showColumnFilters: false, density: 'compact' }}
            enableColumnFilters={config.DatatableColumnFilter}
            onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
            positionToolbarAlertBanner="bottom"
            enableDensityToggle={false}
            muiTableHeadCellProps={{
              //simple styling with the `sx` prop, works just like a style prop in this example
              sx: {
                backgroundColor: "#F6FAFF",
              },
            }}
            renderTopToolbarCustomActions={({ table }) => (
              <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}>
                <Button
                  color="primary"
                  disabled={table.getPrePaginationRowModel().rows.length === 0}
								//export all rows, including from the next page, (still respects filtering and sorting)
                  onClick={() =>
                    handleExportData(table.getPrePaginationRowModel().rows)
                  }
                  //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                  startIcon={<FileDownloadIcon />}
                  variant="contained"
                >
                  CSV
                </Button>
                {user.roles_id >=3? null :
                  <Button variant="contained" color="success" sx={{ minWidth: 200 }} onClick={handleOpen}>Add new</Button>
                }
              </Box>
            )}
          />
        </Box>
        {/* contact type modal start */}

        <Dialog
          open={open}
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              handleClose();
            }
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>
            <Typography level="h3" sx={{ mb: 0.5 }}>Select Data</Typography>
          </DialogTitle>
          <DialogContent>
            <ConatctModal ModalClose={handleClose1} fetchUserListData={fetchUserList}  />
          </DialogContent>
        </Dialog>
        {/* contact type modal end */}
        <Dialog
                                fullWidth
                                maxWidth={'sm'}
                                open={openConfrimationBox}
                                // onClose={handleClose}
                                onClose={(_, reason) => {
                                    if (reason !== "backdropClick") {
                                        setOpenConfrimationBox(false);
                                    }
                                }}
                            >
                 
                                <DialogContent>
                                    <Box mt={1} sx={{textAlign:'center'}}>
                                        <Typography level='h4'>Are you sure you want to delete this contact log?</Typography>
                                    </Box>
                                </DialogContent>
                                <DialogActions sx={{ margin: 'auto' }} >
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" onClick={()=>{deleteContactLog()}} color="success" sx={{ minWidth: 250 }} >
                                            Delete
                                        </Button>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" onClick={() => setOpenConfrimationBox(false)} color="primary" sx={{ minWidth: 250 }}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                </DialogActions>
                    </Dialog>
      </>

    
  
  return (
    <div>
      <DefaultLayout content={ContentLayout} />
      {/* loader code start */}
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={freez}
          onClick={handleFreezStop}
        >
          <Freez />
        </Backdrop>
      </div>
      {/* loader code end */}
    </div>
  );
}


export default ContactLog;