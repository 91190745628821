
import React, { useState, useEffect } from "react";
import Loader from '../../../Components/Loader/Loader';
import swal from "sweetalert";
import AuthUser from '../../../Components/Auth/AuthUser';
import { Grid, Typography,Box } from "@mui/material";
import { Card, CardContent } from '@mui/joy';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate } from "react-router-dom";

function View3BReport() {
  const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [email, setEmail] = useState();
    const [error, setError]= useState('');
    const { http } = AuthUser();
    useEffect(() => {
      const pathname = window.location.pathname;
      const parts = pathname.split('/');
      const email = atob(parts[2]);
      const password = atob(parts[3]);
      fetchReport(email,password)
    }, []);
  
    const fetchReport = async (email, password) => {
        setIsLoading(true)
        const args = {
          username: email,
          password: password,
          is_system: true
        };
      
        try {
          const res = await http.post('/auth/3B/report.html', args);
          
          if (res.status === 200) {
            if (res.data.success === true) {
                setData(res.data.data.html);
              setIsLoading(false)
            } else {
                setIsLoading(false)
                setError(res.data.error)
            }
          } else {
            setIsLoading(false)
            setError('Something went wrong')
            
          }
        } catch (error) {
            setIsLoading(false)
          if (error.response) {
            if(error.response.data.message=="Unauthenticated."){
              navigate('/login');
            }else{
              setError(error.response.data.error || 'Something went wrong')
            }
          } else {
            setError('Something went wrong')
          }
        }
      };
    return (
        <>
         {isLoading ? <><Loader /></> :   
         data.length>0?   
         <div dangerouslySetInnerHTML={{ __html: data }}></div>
         :
        <Box sx={{height:'100vh',display:'flex'}}>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{display:'flex',justifyContent:'center',margin:'auto'}}>
            <Card variant="outlined" size="lg" sx={{textAlign:'center'}}>
           <CardContent>
          <Grid>
          <ErrorOutlineIcon color="error"  sx={{fontSize:'6vw'}}/>
                <Typography variant="h2" sx={{color:'orange'}}>
                    Error
                </Typography>
                <Typography variant="h4">{error}</Typography>
          </Grid>
           
           </CardContent> </Card>
            </Grid>
        </Box>
           }
       
             
        </>
    );
}

export default View3BReport;