import React,{useMemo,useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {Box,Grid,InputLabel,Select,TableBody ,TableCell ,Dialog,DialogActions ,DialogTitle,TextField,DialogContent,DialogContentText,FormControl,
  TableContainer,TableHead,TableRow,Paper,Button,MenuItem,FormHelperText,Alert  } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import Moment from 'moment';
import Loader from '../Loader/Loader';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Formik } from 'formik';
import * as Yup from 'yup';
import swal from "sweetalert";
import AuthUser from '../Auth/AuthUser';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../redux/apiSlice';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    //   width: 250,
    },
  },
};


function ManageMembersList({ModalClose, onError, onSuccess}) {
    const dispatch = useDispatch();
    const {http, user} = AuthUser();
    const [AidList, SetAidList] = useState([]);
    const [disable,setDisable]= useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    let RequestFormInitialValues = {
        id:'',
        aid: '',
        email:'',
      };

    const fetchAidList = async(id) => {
       
        const apiUrl = '/fetchCompanyAidList/' + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res) {
            RequestFormInitialValues.aid = res.aidList ? res.aidList[0] : ''
            SetAidList(res.aidList);
        }
      }
      useEffect(() => {
       
          fetchAidList(user.company_master_id);
    
    
      }, [])

    const submitRequest = async(values, { resetForm }) =>{
        onError("");
        setIsLoading(true)
        setDisable(true);
        RequestFormInitialValues.firstName = values.firstName;
        RequestFormInitialValues.lastName = values.lastName;
        RequestFormInitialValues.email = values.email;
        RequestFormInitialValues.ssn = values.ssn;
        RequestFormInitialValues.aid = values.aid;
            
            const apiUrl = '/createMissingMember';
            const args = {
                aid:values.aid,
                ssn:values.ssn,
                fname:values.firstName,
                lname:values.lastName,
                email:values.email,
                membernotexit:0,
            }
            const res = await dispatch(postData({apiUrl,args})).unwrap();
            if(res) {
                if(res.success === false) {
                    onError(res.message);
                    setIsLoading(false)
                    setTimeout(function () {
                        onError('');
                        setDisable(false)
                    }, 10000);
                    setDisable(false);
                    RequestFormInitialValues.firstName = '';
                    RequestFormInitialValues.lastName = '';
                    RequestFormInitialValues.email = '';
                    RequestFormInitialValues.ssn = '';
                    RequestFormInitialValues.aid = '';
                }else{
                    if (res.message == 'Aid_null') {
                        swal({
                            title: "Info",
                            text: "This member has been now associated with the given AID. All set! Thank you.",
                            icon: "info",
                            button: "Ok",
                        })
                    }
                    else if (res.message == 'Exists') {
                        swal({
                            title: "Info",
                            text: "This member is already associated with the affiliate account. Nothing to be done. Thank you.",
                            icon: "info",
                            button: "Ok",
                        })
                    }
                    else if (res.message == 'Not_Exists') {
                        swal({
                            title: "Info",
                            text: "This member is already associated with another Affiliate. Investigate and take action accordingly. Thank you.",
                            icon: "info",
                            button: "Ok",
                        })
                    }
                    else if (res.message == 'registrants') {
                        swal({
                            title: "Info",
                            text: "The member has not yet completed the enrollment process. Look for any possible duplicate registrations for this member using the last 4 digits of SSN, the first name and last name on support link. If needed, make changes SupportLink to ensure that there is a single valid entry for the registrant. Then ask the member/affiliate to get the registration completed. Thank you.",
                            icon: "info",
                            button: "Ok",
                        })
                    }
                    else if (res.message == 'fail') {
                        swal({
                            title: "Info",
                            text: "A Member with the given details does not exist! Search using the First Name, Last Name and SSN on SupportLink and look for any misspelt email address. Take a corrective action and then rerun this tool. Thank you.",
                            icon: "info",
                            button: "Ok",
                        })
                    }
                    onSuccess(res.message);
                    setIsLoading(false)
                    setTimeout(function () {
                        onSuccess('');
                        setDisable(false)
                      }, 10000);
                    setDisable(false);
                    ModalClose();
                    RequestFormInitialValues.aid = '';
                    RequestFormInitialValues.firstName = '';
                    RequestFormInitialValues.lastName = '';
                    RequestFormInitialValues.email = '';
                    RequestFormInitialValues.ssn = '';
                }
            }
           
    } 

    const RequestFormSchema = Yup.object().shape({
        aid:Yup.string().required('Please enter aid'),
     
        email:Yup.string().email('Invalid email format.')
                .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid email format.")
                .required('Please enter your email address.'),
       
    })


        return (
            <>
              
              {/* new activity popup start */}
                <Formik
                        initialValues={RequestFormInitialValues}
                        validationSchema={RequestFormSchema}
                        onSubmit={submitRequest}
                        >{({ values, errors, touched, handleChange, handleBlur, handleSubmit,setFieldValue }) => (
                        <>
                            <Grid container item xs={12} spacing={2} mt={1}>
                                <Grid item xs>
                                    {user.roles_id === 3 || user.roles_id === 4?
                                        <>
                                            <FormControl fullWidth size="small">
                                                <InputLabel id="demo-simple-select-label">Select AID</InputLabel>
                                                    <Select
                                                    labelId="demo-simple-select-label"
                                                    id="aid"
                                                    name="aid"
                                                    value={values.aid}
                                                    label="Select AID"
                                                    MenuProps={MenuProps}
                                                    onChange={handleChange}
                                                    helperText={touched.aid && errors.aid ? errors.aid : null}
                                                    error={touched.aid && errors.aid ? errors.aid : null}
                                                    >
                                                         {AidList.map((row,idx)=>(
                                                            <MenuItem value={row}>{row}</MenuItem>
                                                        ))}
                                                        
                                                    </Select>
                                            </FormControl>
                                        </>
                                        :<>
                                            
                                            <TextField 
                                            fullWidth 
                                            size="small" 
                                            id="aid" 
                                            label="AID" 
                                            variant="outlined"
                                            name='aid'
                                            value={values.aid}
                                            onChange={handleChange}
                                            helperText={touched.aid && errors.aid ? errors.aid : null}
                                            error={touched.aid && errors.aid ? errors.aid : null}
                                            />
                                        </>
                                    }
                                </Grid>
                                <Grid item xs>
                                    <TextField 
                                    fullWidth 
                                    size="small" 
                                    id="outlined-basic" 
                                    label="First Name" 
                                    variant="outlined"
                                    name='firstName'
                                    value={values.firstName}
                                    onChange={handleChange}
                                    
                                    />
                                </Grid>
                                <Grid item xs>
                                    <TextField 
                                    fullWidth 
                                    size="small" 
                                    id="outlined-basic" 
                                    label="Last Name" 
                                    variant="outlined" 
                                    name='lastName'
                                    value={values.lastName}
                                    onChange={handleChange}
                                    
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={2} mt={1}>
                                <Grid item xs>
                                    <TextField 
                                    fullWidth 
                                    size="small" 
                                    id="outlined-basic" 
                                    label="Email Address*" 
                                    variant="outlined"
                                    name='email'
                                    value={values.email}
                                    onChange={handleChange}
                                    helperText={touched.email && errors.email ? errors.email : null}
                                    error={touched.email && errors.email ? errors.email : null}
                                    />  
                                </Grid>
                                <Grid item xs>
                                    <TextField 
                                    fullWidth 
                                    size="small" 
                                    id="outlined-basic" 
                                    label="Last 4-digit SSN"
                                    name='ssn'
                                    value={values.ssn}
                                    onChange={handleChange}
                                    helperText={touched.ssn && errors.ssn ? errors.ssn : null}
                                    error={touched.ssn && errors.ssn ? errors.ssn : null} 
                                    variant="outlined" />
                                </Grid>
                            </Grid>
                            <DialogActions>
                                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{textAlign:"center",width:"100%"}} spacing={3} mt={2}>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" sx={{minWidth:250}} onClick={()=>{ModalClose()}}>
                                            CLOSE
                                        </Button>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" sx={{minWidth:250}} onClick={ disable?"":handleSubmit} color="success" type="submit">
                                            SAVE
                                        </Button>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </>
                        )}
                </Formik>            
               {/* new activity popup end */}
              
            </>
        );
    }
    
    
    export default ManageMembersList;