import React,{useState,useEffect} from 'react';
import { useNavigate} from 'react-router-dom';
import {
    Box,
    Alert,
    Grid,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
   ListItemIcon,Checkbox,ListItemText
} from '@mui/material';
import { Typography } from '@mui/joy';
import { DateRangePicker,createStaticRanges  } from 'react-date-range';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { fetchData,postData } from '../../redux/apiSlice';
import {  format } from 'date-fns';
import swal from "sweetalert";
import AuthUser from "../Auth/AuthUser";
import { useStyles } from "../SuspendedMembers/utils";
import { calendarFilter,useDateSelectionRange,getInitialSelectionRange  } from '../CommonFormula';
import useMediaQuery from '@mui/material/useMediaQuery';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const tablePreferencesArray = { 
	leadType: false,
	softwareUsed: false,
	aid: false,
	docId: false,
	leadGeneratedDate: false,
	followup: false
}


const CommonFliter = React.memo((props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { user } = AuthUser();
    const [assignedOpen, setAssignedOpen] = useState(false);
    const [statusMessage, setStatusMesage] = useState(props.statusMessage?props.statusMessage:'');
	const [errorMessage,setErrorMessage] = useState('');
    const [dateRangeType, setDateRangeType] = useState('all');
    const [assignedTo, setAssignedTo] = useState('all');
    const [adminList, setAdminList] = React.useState([]);
    const [assignedToAction, setAssignedToAction] = useState('Action');
    const [isLoading, setIsLoading] = useState(false);
    const [statusValue, setStatusValue] = useState(0);
    const [showEnrollments, setShowEnrollments] = useState('all');
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [selected, setSelected] = useState([]);
    const [selectedID, setSelectedID] = useState([]);
    const [showMenuItem, setShowMenuItem] = useState(true);
    const [submitEnable, setSubmitEnable] = useState(false);
    const [completedBy, setCompletedBy] = useState('all');
    const { selectionRange, setSelectionRange, startDate, endDate,clearFilter } = useDateSelectionRange(getInitialSelectionRange('true'));
    const isMobile = useMediaQuery('(max-width:600px)');

   const isAllSelected =
    adminList.length > 0 && selected.length === adminList.length;

    const handleChange2 = async (event, obj) => {

        const {
        target: { value }
        } = event;

        const extractedArray = adminList.map(arr => arr.id);
        setSelectedID(value);

        if (value[value.length - 1] === "all" || value[0] === "all") {
        setSelected(selected.length === adminList.length ? [] : extractedArray);

        return;
        }
        setSelected(value);

    };

    const handleClickOpen = () => {
      setOpen(true);
    };
    const assignedToStaffSchema = Yup.object().shape({
        staffId: Yup.array()
            .required('Please select User to Assign.'),
    });

    const rowsSeleted = Object.keys(props.rowSelection);

    const handleClickOpenAssigned = () => {
        setAssignedOpen(true);
    };
  
    const handleCloseAssigned = () => {
        setAssignedOpen(false);
        setAssignedToAction('Action');
    };

    //assign to functionality
    const handleActionChange = async (event) => {
        if(rowsSeleted.length > 0){
            setAssignedToAction(event.target.value);
            if(event.target.value === "assignToStaff"){
                handleClickOpenAssigned();
            }else if(event.target.value === "assignToNone"){
                setIsLoading(true);

                const apiUrl = '/leadsUnassign';
                const args = {
                    leadsArray: rowsSeleted,
                }
                const res = await dispatch(postData({apiUrl,args})).unwrap();
                if(res){
                    if (res.success === true){
                        swal({
                            title: "Success",
                            text: res.message,
                            icon: "success",
                            button: "Ok",
                          })
                        fetchReferredList();
                        setSelectedID([]);
                        setSelected([]);
                        props.changeRowSelection();
                    }else{
                        swal({
                            title: "Failed",
                            text: res.message,
                            icon: "error",
                            button: "Ok",
                        })
                        if(res.message == "This action is unauthorized.") {
                            navigate('/login');
                        }
                    }
                    setAssignedToAction('Action');

                    
                }
                setIsLoading(false);   
            }
        }else{
            alert('Please select at least one lead');
        }
    }

    const sideBar = calendarFilter();
    const staticRanges = [
        // ...defaultStaticRanges,
        ...createStaticRanges(sideBar)
    ];

    useEffect(() => {
        if(user.roles_id < 3 || user.roles_id == 6 ||user.roles_id==13) {
            fetchReferredList();
        }else{
        navigate('/login');
        }
    },[startDate,endDate,dateRangeType,assignedTo,props.refresh,completedBy]);

    useEffect(() => {
        fetchAdminList();
        tablePreferences();
    },[]);

    const saveTablePreferences = async() => {
        if(Object.values(props.columnArray).length > 0){
            const apiUrl = '/saveTablePreferences';
            const args = {
                columnArray: props.columnArray,
                userId: props.userId,
                tableId: props.leadType
            }
		    const res = await dispatch(postData({apiUrl,args})).unwrap();
        } 
    }

    useEffect(()=>{  
        saveTablePreferences();
    },[props.columnArray])

    //Clear date ranges
    

    //Fetch Leads
    const fetchReferredList = async (ref) => {
        props.startLoading();
        const apiUrl = '/fetchLeads/' + props.leadType + '/list?dateRangeType='+dateRangeType+'&assignedTo='+assignedTo+'&startDate='+startDate+'&endDate='+endDate+ ((props.leadType == 'active-leads') ? '&statusId='+ statusValue : '') + ((props.leadType == 'completed-leads' && dateRangeType === "walkthroughCompleted") ? '&completedBy='+ completedBy : '');
		const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res){
            props.onDataChange(res);
        }
    };
    //fetch all admins for assignment
	const fetchAdminList = async () => {
        const apiUrl = '/fetchOnlyAdmins';
		const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res){
            setAdminList(Object.values(res));
        }
	};
	const handleClose = () => {
        setOpen(false);
      };

	const changeEnrollmentFilterCount = (count) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
		if(loginUser.roles_id < 3 || loginUser.roles_id == 6) {
            setShowEnrollments(count);
            props.onChangeEnrollmentFilter(count);
        }else{
            swal({
                title: "Failed",
                text: "This action is unauthorized.",
                icon: "error",
                button: "Ok",
            })
            navigate('/login');
        }
        
    };

	const changeStatusFilterValue = (status) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id < 3 || loginUser.roles_id == 6) {
            setStatusValue(status);
            props.onChangeStatusFilter(status);
        }else{
            swal({
                title: "Failed",
                text: "This action is unauthorized.",
                icon: "error",
                button: "Ok",
            })
            navigate('/login');
        }
        
    };
    //fetch table preferences from database
    const tablePreferences = async () => { 
        const apiUrl = '/fetchTablePreferences/'+props.leadType;
		const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res){
            if(Object.values(res).length == 0 ){
                props.onTablePreferences(tablePreferencesArray);
            }else{
                props.onTablePreferences(JSON.parse(res.preferences));
            }
        }
    }

    const changeDataFilter = async (value) => { 
        setDateRangeType(value);
        if(value == "walkthroughCompleted"){
           setAssignedTo("all");
        }
    }

    const updateEnrollment = async () => { 
        props.startLoading();
        setIsLoading(true);
        const apiUrl = '/execute-command';
        const args = {}
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        if(res){
            if (res.success === true){
                swal({
                    title: "Success",
                    text: "Enrollment counts updated",
                    icon: "success",
                    button: "Ok",
                })
            }else{
                swal({
                    title: "Failed",
                    text: res.message,
                    icon: "error",
                    button: "Ok",
                })
                if(res.message == "This action is unauthorized.") {
                    navigate('/login');
                    return false;
                }
            }
            
        }else{
            swal({
                title: "Alert",
                text: "Something went wrong please try again",
                icon: "error",
                button: "Ok",
            })
        }
        setIsLoading(false); 
        fetchReferredList();
    }

    const dateRangeArray = [
        {
            name: 'All',
            value: 'all',
        },
        {
            name: 'Last Updated',
            value: 'lastUpdated',
        },
        {
            name: 'Lead Generated Date',
            value: 'leadGeneratedDate',
        },
        {
            name: 'Next Followup',
            value: 'nextFollowupDate',
        },
        ...(props.leadType === 'completed-leads' ? [
            {
                name: 'Walkthrough Completed',
                value: 'walkthroughCompleted',
            },
            {
                name: "Affiliate Doesn't need Walkthrough",
                value: "affiliateDoesWalkthrough",
            },
            {
                name: 'Received Links But No Walkthrough',
                value: 'receivedLinksButNoWalkthrough',
            },
            {
                name: "Automated Via Enrollment/CS/RP/CB",
                value: "automatedViaEnrollment",
            },
        ] : [])
    ];

    return (
        <div>
            {/* {isLoading?<Loader/>:<></>}      */}
            <Box pb={1} sx={{ width: '100%' }}>
            	{statusMessage?<Alert severity="success">{statusMessage}</Alert>:''}
                {errorMessage?<Alert severity="error">{errorMessage}</Alert>:''}
            </Box>
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3}>
                <Grid item lg={3} md={3} sm={6} xs={6}>
                    <FormControl fullWidth size='small'>
                        <InputLabel id="demo-multiple-name-label">Select Date Range</InputLabel>

                <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    value="date"
                    input={<OutlinedInput label="Select Date Range" />}
                >
                    <MenuItem value="date" >{startDate ? <>{startDate} To {endDate}</> : 'Select Date Range'}</MenuItem>
                   
                    <DateRangePicker
                    className='custom-date-picker'
                    onChange={item => setSelectionRange([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={selectionRange}
                    direction="horizontal"
                    maxDate={new Date()}
                    staticRanges={staticRanges}
                    />
                </Select>
                    <Button onClick={clearFilter} >Clear Dates</Button>
                    </FormControl>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={6}>
                    <FormControl fullWidth size='small'>
                        <InputLabel id="demo-multiple-name-label">Data Filter</InputLabel>
                        <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        value={dateRangeType}
                        onChange={(e)=> {changeDataFilter(e.target.value)}}
                        input={<OutlinedInput label="Data Filter" />}
                        MenuProps={MenuProps}
                        >
                        {dateRangeArray.map((name) => (
                            <MenuItem
                            key={name.name}
                            value={name.value}
                            >
                            {name.name}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                </Grid> 
                { user.roles_id == 13 ? null :
                <Grid item lg={3} md={3} sm={6} xs={6}>
                    {
                        props.leadType == 'completed-leads' && dateRangeType == "walkthroughCompleted" ?
                            <FormControl fullWidth size='small'>
                                <InputLabel id="demo-multiple-name-label">Completed By</InputLabel>
                                <Select
                                id="completedBy"   
                                name="completedBy"     
                                input={<OutlinedInput label="Completed By" />}
                                value={completedBy}   
                                onChange={(e)=>{setCompletedBy(e.target.value)}}
                                MenuProps={MenuProps}
                                >
                                    <MenuItem
                                    value='all'
                                    >
                                    All
                                    </MenuItem>
                                        {adminList.map((array) => (
                                    <MenuItem
                                    key={array.name}
                                    value={array.id}
                                    >
                                    {array.name}
                                    </MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                        :
                        <FormControl fullWidth size='small'>
                        <InputLabel id="demo-multiple-name-label">Assigned to</InputLabel>
                        <Select
                        id="demo-multiple-name"   
                        name="assignedto"     
                        input={<OutlinedInput label="Assigned To" />}
                        value={assignedTo}   
                        onChange={(e)=>{setAssignedTo(e.target.value)}}
                        MenuProps={MenuProps}
                        >
                            <MenuItem
                            value='all'
                            >
                            All
                            </MenuItem>
                                {adminList.map((array) => (
                            <MenuItem
                            key={array.name}
                            value={array.id}
                            >
                            {array.name}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    }
                   
                </Grid>  }
                {user.roles_id == 6 || user.roles_id == 13 ? null :    
                    <Grid item lg={3} md={3} sm={6} xs={6}>
                        <FormControl fullWidth size='small'>
                            <InputLabel id="demo-multiple-name-label">Action</InputLabel>
                            <Select
                            id="assignedToAction"
                            value={assignedToAction}
                            onChange={handleActionChange}
                            input={<OutlinedInput label="Action" />}
                            MenuProps={MenuProps}
                            >    
                                <MenuItem key="Action" value="Action" disabled={true}>
                                Action
                                </MenuItem>                    
                                <MenuItem key="assignToStaff" value="assignToStaff">
                                Assign to Staff
                                </MenuItem>
                                <MenuItem key="assignToNone" value="assignToNone">
                                Assign to None
                                </MenuItem>                        
                            </Select>
                        </FormControl>
                    </Grid>
                }
            </Grid>
            {
                props.leadType == 'active-leads' && user.roles_id != 13
                ?
                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3} sx={{mt:0.5}}>
                    <Grid item lg={3} md={3} sm={6} xs={6} >
                        <FormControl fullWidth size='small'>
                            <InputLabel id="demo-multiple-name-label">Status</InputLabel>
                            <Select
                                id="demo-multiple-name"
                                value={statusValue}
                                onChange={(e)=>{changeStatusFilterValue(e.target.value)}}
                                input={<OutlinedInput label="Status" />}
                            >
                                <MenuItem  value="0" >All</MenuItem>
                                <MenuItem  value="6" >Email Sent</MenuItem>
                                <MenuItem  value="7" >Email Not Sent</MenuItem>
                                <MenuItem  value="2" >Agreement Generated</MenuItem>
                                <MenuItem  value="3" >Agreement Signed</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                :
                null
            }
            {
                (props.leadType == 'approved-leads' || props.leadType == 'completed-leads') && user.roles_id != 13
                ?
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{mt:0.5}} spacing={3}>
                    <Grid item lg={3} md={3} sm={6} xs={6}>
                        <FormControl fullWidth size='small'>
                            <InputLabel id="demo-multiple-name-label">Show Enrollments</InputLabel>
                            <Select
                                id="demo-multiple-name"                                
                                value={showEnrollments}
                                onChange={(e)=>{changeEnrollmentFilterCount(e.target.value)}}
                                input={<OutlinedInput label="Show Enrollments" />}
                            >
                                <MenuItem  value="all" >All</MenuItem>
                                <MenuItem  value="0" >0</MenuItem>
                                <MenuItem  value="5" >1 to 5</MenuItem>   
                                <MenuItem  value="6" >6 to 20</MenuItem>
                                <MenuItem  value="20" >Above 20</MenuItem>                         
                            </Select>
                        </FormControl>
                    </Grid>
                    {user.roles_id == 6 || user.roles_id == 13 ? null :
                        <Grid item lg={3} md={3} sm={6} xs={6}>
                            <Button variant="contained" color="success" sx={{minWidth:isMobile ? 150 :250}} onClick={updateEnrollment}>Update Enrollments</Button>
                        </Grid>
                    }
                </Grid>
                :
                null
            }

            <Formik
                initialValues={{
                    staffId: selected,
                }}
                validationSchema={assignedToStaffSchema}
                onSubmit={ async (values, error)=>{
                    setSubmitEnable(true);
                    setIsLoading(true);
                    const apiUrl = '/leadsAssignToUser';
                    const args = {
                        staffId: selectedID,
                        leadsArray: rowsSeleted,
                    }
                    const res = await dispatch(postData({apiUrl,args})).unwrap();
                    if(res){
                        if(res.success === true) {
                            handleCloseAssigned();
                            setIsLoading(false);
                            if(res.manageLeadMessage == 1){
                                swal({
                                  title: "Success",
                                  text: res.message,
                                  icon: "success",
                                  button: "Ok",
                                })
                              }else{
                                swal({
                                  title: "Error",
                                  text: res.message,
                                  icon: "error",
                                  button: "Ok",
                                })
                              }
                             
                            fetchReferredList();
                            setSelectedID([]);
                            setSelected([]);
                            props.changeRowSelection();
                            setSubmitEnable(false);
                        }else{
                            swal({
                                title: "Failed",
                                text: res.message,
                                icon: "error",
                                button: "Ok",
                            })
                            if(res.message == "This action is unauthorized.") {
                                navigate('/login');
                            }
                        }
                    }
                    
                }}
            >
                {({errors,touched,handleSubmit}) =>(
                    <Dialog
                        open={assignedOpen}
                        onClose={(_, reason) => {
                            if (reason !== "backdropClick") {
                                handleCloseAssigned();
                            }
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle textAlign={'center'} id="responsive-dialog-title" p={5} sx={{backgroundColor:"#F6FAFF"}}>
                            <Typography level="h4" color='primary'>Assign Selected Leads To Staff</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container item lg={12} md={12} sm={12} xs={12} pt={2}>
                                <FormControl className={classes.formControl} fullWidth size="small">
                                    <InputLabel id="mutiple-select-label">Action</InputLabel>
                                    <Select
                                        labelId="mutiple-select-label"
                                        multiple
                                        id="staffId"
                                        name="staffId"
                                        input={<OutlinedInput label="Action" />}
                                        value={selected}
                                        onChange={handleChange2}
                                        renderValue={(select) =>
                                        select
                                            .map((id) => {
                                            const selectedOption = adminList.find((option) => option.id === id);
                                            return selectedOption ? selectedOption.name : '';
                                            })
                                            .join(", ")
                                        }
                                        MenuProps={MenuProps}
                                    >
                                        <MenuItem
                                        value="all"
                                        classes={{
                                            root: isAllSelected ? classes.selectedAll : ""
                                        }}
                                        >
                                        <ListItemIcon>
                                            <Checkbox
                                            classes={{ indeterminate: classes.indeterminateColor }}
                                            checked={isAllSelected}
                                            indeterminate={
                                                selected.length > 0 && selected.length < adminList.length
                                            }
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            classes={{ primary: classes.selectAllText }}
                                            primary="Select All"
                                        />
                                        </MenuItem>

                                            {adminList.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                <ListItemIcon>
                                                <Checkbox checked={selected.indexOf(option.id) > -1} />
                                                </ListItemIcon>
                                                <ListItemText primary={option.name} />
                                            </MenuItem>
                                            ))}
                                        </Select>
                                        {touched.staffId && errors.staffId ? <div className='error'>{errors.staffId}</div> : null}
                                </FormControl>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{textAlign:"center",width:"100%"}}>
                                <Grid item lg={6} md={6} sm={6} xs={6} px={2}>
                                    <Button  autoFocus variant="contained" sx={{minWidth:250}}  onClick={()=>{handleCloseAssigned()}}>
                                        CLOSE
                                    </Button>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6} px={2}>
                                    <Button variant="contained" sx={{minWidth:250}} color="success" type="submit" disabled={submitEnable} onClick={handleSubmit}>
                                        SAVE
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Dialog>
                )}
            </Formik>
        </div>
    );
})

export default CommonFliter;