import React, { useEffect } from 'react';
import { Backdrop } from '@mui/material';
import AuthUser from '../Components/Auth/AuthUser';
import Freez from '../Components/Loader/Loader';
import { useParams } from "react-router-dom";
import config from '../AppConfig';


function ShellAccount() {
    const { http } = AuthUser();
    const [freez, setFreez] = React.useState(true);
    const { email,fname,lname } = useParams();

    useEffect(()=>{
        http.post("/registerEnrollment", {
            step: 1,
            firstName: fname,
            middlename: "",
            lastName: lname,
            email: email,
            password: "Credit@2025",
            source: "default",
            aid: "BeLimitlessEnterpriseLLC",
            pid: "12778",
        }).then((res) => {
            console.log("res ===", res.data);
            if (res.data.success === true) {
                localStorage.setItem('customerToken', res.data.data.customerToken);
                localStorage.setItem('trackingToken', res.data.data.trackingToken);
                localStorage.setItem('Main_name', fname+' '+lname);
                localStorage.setItem('Main_firstName', fname);
                localStorage.setItem('Main_lastName', lname);
                localStorage.setItem('Main_middleName', "");
                localStorage.setItem('Main_email', email);
                localStorage.setItem('Step', res.data.data.nextstep);
                localStorage.setItem('Main_password', "Credit@2025");
                localStorage.setItem('aid', "BeLimitlessEnterpriseLLC");
                localStorage.setItem('pid', "12778");
    
                if (res.data.data.nextstep == 3) {
                    localStorage.setItem('idQuestions', JSON.stringify(res.data.data.idQuestions));
                }
                window.location.href = config.BASE_URL+ "enroll?pid=12778&aid=BeLimitlessEnterpriseLLC&sid=&tid=&adid=&from=crsleads";
    
            } else {
                const errorObj = res.data.data.errorByCD;
                window.location.href = config.BASE_URL+ "enroll?pid=12778&aid=BeLimitlessEnterpriseLLC&sid=&tid=&adid=";
            }
        })
        .catch((error) => {
            window.location.href = config.BASE_URL+ "enroll?pid=12778&aid=BeLimitlessEnterpriseLLC&sid=&tid=&adid=";
        });

    },[])



    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={freez}
            >
                <Freez />
            </Backdrop>
        </div>
    );
}

export default ShellAccount;