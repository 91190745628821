import React, { useState, useEffect } from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import {
    InputLabel, MenuItem, FormControl, Select, Box, Grid, TextField, Button, Alert,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/joy';
import AuthUser from '../../../Components/Auth/AuthUser';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Loader from '../../../Components/Loader/Loader';
import ManageMembersList from '../../../Components/ManageMembers/ManageMembersList';
import swal from "sweetalert";
import {useNavigate} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchData,postData } from '../../../redux/apiSlice';

const RequestFormInitialValues = {
    firstName: '',
    lastName: '',
    email: '',
    ssn: '',
    aid: ''
};

function ManagerMembers(props) {
    const dispatch = useDispatch();
    const [reportOption, setReportoption] = useState(false);
    const { http, user } = AuthUser();
    const [data, setData] = useState([]);
    const [disable, setDisable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:600px)');

    // RequestFormInitialValues.aid = !user.aid_master[0] ? RequestFormInitialValues.aid:user.aid_master[0].aid;
    //Fetch reports
    const fetchReport =async () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id >= 3 ) {
            navigate('/login');
            return false;
        }
        setErrorMessage("")
        setIsLoading(true)

        const apiUrl = '/showMissingMember'; // Replace with your API endpoint
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            setData(res.data);
            setIsLoading(false)
        }else
        {
            setErrorMessage("Something went wrong.")
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if(user.roles_id < 3) {
            fetchReport();
        }else{
            navigate('/login');
        }
    }, []);

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id >= 3 ) {
            navigate('/login');
            return false;
        }
        setOpen(true);

    };

    const handleClose = () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id >= 3 ) {
            navigate('/login');
            return false;
        }
        setOpen(false);
    };
    const handleData = (data) => {
        console.log(data);
    }


    //Start Request Add Missing Member
    //Schema for formik form
    const RequestFormSchema = Yup.object().shape({
        aid: Yup.string().required('Please enter aid'),
        // firstName:Yup.string().required('Please enter first name'),
        // lastName: Yup.string().required('Please enter last name'),
        email: Yup.string().email('Invalid email format.')
            .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid email format.")
            .required('Please enter your email address.'),
        // ssn:Yup.string().min(4, 'Please enter last 4 digit of SSN')
        //         .max(4, 'Please enter last 4 digit of SSN')
        //         .required('Please enter last 4 digit of SSN')
    })



    //Submit Request Add Missing Member Form
    const submitRequest =async (values, { resetForm }) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id >= 3 ) {
            navigate('/login');
            return false;
        }
        setErrorMessage("")
        setIsLoading(true)
        setDisable(true);
        RequestFormInitialValues.firstName = values.firstName;
        RequestFormInitialValues.lastName = values.lastName;
        RequestFormInitialValues.email = values.email;
        RequestFormInitialValues.ssn = values.ssn;
        RequestFormInitialValues.aid = values.aid;

        const apiUrl = '/createMissingMember'; // Replace with your API endpoint
        const args = {
            aid: values.aid,
            ssn: values.ssn,
            fname: values.firstName,
            lname: values.lastName,
            email: values.email,
            membernotexit: 0,
        };
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if(res) 
        {
            if (res.success === false) {
                setErrorMessage(res.message)
                setIsLoading(false)
                setTimeout(function () {
                    setErrorMessage('')
                    setDisable(false)
                    handleClose();
                }, 4000);
                setDisable(false);
                RequestFormInitialValues.firstName = '';
                RequestFormInitialValues.lastName = '';
                RequestFormInitialValues.email = '';
                RequestFormInitialValues.ssn = '';
                RequestFormInitialValues.aid = '';
            } else {
                if (res.message == 'Aid_null') {
                    swal({
                        title: "Info",
                        text: "This member has been now associated with the given AID. All set! Thank you.",
                        icon: "info",
                        button: "Ok",
                    })
                }
                else if (res.message == 'Exists') {
                    swal({
                        title: "Info",
                        text: "This member is already associated with the affiliate account. Nothing to be done. Thank you.",
                        icon: "info",
                        button: "Ok",
                    })
                }
                else if (res.message == 'Not_Exists') {
                    swal({
                        title: "Info",
                        text: "This member is already associated with another Affiliate. Investigate and take action accordingly. Thank you.",
                        icon: "info",
                        button: "Ok",
                    })
                }
                else if (res.message == 'registrants') {
                    swal({
                        title: "Info",
                        text: "The member has not yet completed the enrollment process. Look for any possible duplicate registrations for this member using the last 4 digits of SSN, the first name and last name on support link. If needed, make changes SupportLink to ensure that there is a single valid entry for the registrant. Then ask the member/affiliate to get the registration completed. Thank you.",
                        icon: "info",
                        button: "Ok",
                    })
                }
                else if (res.message == 'fail') {
                    swal({
                        title: "Info",
                        text: "A Member with the given details does not exist! Search using the First Name, Last Name and SSN on SupportLink and look for any misspelt email address. Take a corrective action and then rerun this tool. Thank you.",
                        icon: "info",
                        button: "Ok",
                    })
                }
                setSuccessMessage(res.message)
                setIsLoading(false)
                setTimeout(function () {
                    setSuccessMessage('')
                    setDisable(false)
                }, 3000);
                setDisable(false);
                handleClose();
                fetchReport();
                RequestFormInitialValues.aid = '';
                RequestFormInitialValues.firstName = '';
                RequestFormInitialValues.lastName = '';
                RequestFormInitialValues.email = '';
                RequestFormInitialValues.ssn = '';

                // this.refs.fieldorg.value="";
                // this.refs.fieldNum.value="";
            }

        }else {
            setErrorMessage("Something went wrong.")
            setIsLoading(false)
            setTimeout(function () {
                setErrorMessage('')
                setDisable(false)
                handleClose();
            }, 4000);
            setDisable(false);
        }

    }
    function ContentLayout() {
        return (
            <>
                <Box>
                    {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
                    {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: 'space-between' }} mb={2}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Typography level="h4" color="primary">Manage Members</Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ textAlign: "right" }}>
                            <Button variant="contained" color="warning" onClick={handleClickOpen}>
                                Request/Add missing member
                            </Button>
                        </Grid>
                    </Grid>
                    {/* <CustomerDetails/> */}
                    {isLoading ? <><Loader /></> : ''}
                    <ManageMembersList data={data} onChangeData={handleData} />
                    {/* add missing member report popup */}
                    <Dialog
                        fullScreen={fullScreen}
                        open={open}
                        onClose={(_, reason) => {
                            if (reason !== "backdropClick") {
                                handleClose();
                            }
                        }}
                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle id="responsive-dialog-title" sx={{ backgroundColor: "#F6FAFF" }}>
                            Member Details
                        </DialogTitle>
                        <Formik
                            initialValues={RequestFormInitialValues}
                            validationSchema={RequestFormSchema}
                            onSubmit={submitRequest}
                        >{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (<>
                            <DialogContent>
                                <DialogContentText>
                                    {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
                                    {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
                                    <Grid container item xs={12} spacing={2} mt={1}>
                                        <Grid item xs>
                                            {user.roles_id === 3 ?
                                                <>
                                                    <FormControl fullWidth size="small">
                                                        <InputLabel id="demo-simple-select-label">Select AID</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="aid"
                                                            name="aid"
                                                            value={values.aid}
                                                            label="Report Options"
                                                            onChange={handleChange}
                                                            helperText={touched.aid && errors.aid ? errors.aid : null}
                                                            error={touched.aid && errors.aid ? errors.aid : null}
                                                        >
                                                            {user.aid_master.map((row, idx) => (
                                                                <MenuItem value={row.aid}>{row.aid}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </>
                                                : <>
                                                    {/* <TextField fullWidth size="small" 
                                                    id="aid" 
                                                    label="AID :" 
                                                    name='aid'
                                                    value={values.aid}
                                                    onChange={handleChange}
                                                    helperText={touched.aid && errors.aid ? errors.aid : null}
                                                    error={touched.aid && errors.aid ? errors.aid : null}
                                                    // variant="outlined" /> */}
                                                    <TextField
                                                        fullWidth
                                                        size="small"
                                                        id="aid"
                                                        label="AID"
                                                        variant="outlined"
                                                        name='aid'
                                                        value={values.aid}
                                                        onChange={handleChange}
                                                        helperText={touched.aid && errors.aid ? errors.aid : null}
                                                        error={touched.aid && errors.aid ? errors.aid : null}
                                                    />
                                                </>
                                            }
                                        </Grid>
                                        <Grid item xs>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                id="outlined-basic"
                                                label="First Name"
                                                variant="outlined"
                                                name='firstName'
                                                value={values.firstName}
                                                onChange={handleChange}
                                            // helperText={touched.firstName && errors.firstName ? errors.firstName : null}
                                            // error={touched.firstName && errors.firstName ? errors.firstName : null}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                id="outlined-basic"
                                                label="Last Name"
                                                variant="outlined"
                                                name='lastName'
                                                value={values.lastName}
                                                onChange={handleChange}
                                            // helperText={touched.lastName && errors.lastName ? errors.lastName : null}
                                            // error={touched.lastName && errors.lastName ? errors.lastName : null}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} spacing={2} mt={1}>
                                        <Grid item xs>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                id="outlined-basic"
                                                label="Email Address*"
                                                variant="outlined"
                                                name='email'
                                                value={values.email}
                                                onChange={handleChange}
                                                helperText={touched.email && errors.email ? errors.email : null}
                                                error={touched.email && errors.email ? errors.email : null}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                id="outlined-basic"
                                                label="Last 4-digit SSN"
                                                name='ssn'
                                                value={values.ssn}
                                                onChange={handleChange}
                                                // helperText={touched.ssn && errors.ssn ? errors.ssn : null}
                                                // error={touched.ssn && errors.ssn ? errors.ssn : null} 
                                                variant="outlined" />
                                        </Grid>
                                    </Grid>
                                </DialogContentText>
                                <DialogActions sx={{marginTop:isMobile?"3%":"2%"}}>
                                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }} spacing={3}>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" sx={{ minWidth: 250 }} onClick={handleClose}>
                                            CLOSE
                                        </Button>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" sx={{ minWidth: 250 }} onClick={disable ? "" : handleSubmit} color="success" type="submit">
                                            SAVE
                                        </Button>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                            </DialogContent>
                            

                        </>)}</Formik>
                    </Dialog>
                </Box>
            </>
        );
    }
    return (
        <div>
            <DefaultLayout content={<ContentLayout />} />
        </div>
    );
}


export default ManagerMembers;