import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { Typography, Box, Grid, TextField, Button,IconButton } from "@mui/material";
import Moment from 'moment';
import MaterialReactTable from 'material-react-table';
import { postData } from '../../../../redux/apiSlice';
import swal from "sweetalert";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CloseIcon from "@mui/icons-material/Close";
const AIDsTab = ({fetchCompanyDetails,aids,user,checkUserRole,company_id,handleFreezStart,handleFreezStop}) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const handleOpenAid = () => {
        var check = checkUserRole();
        if(check === true) {
            setOpen(true);
        }
    };

    const AidSchema = Yup.object().shape({
        AID: Yup.string()
            .min(2, 'Too Short!')
            .max(60, 'Length Of AID can be maximum 60 Chars/digits!')
            .matches(/^[^,\s]*$/, 'No commas or spaces allowed')
            .matches(/^[a-zA-Z0-9]*$/, 'Only alphanumeric characters allowed')
            .required('Please enter AID.'),
    });

    const aidColumns = [
        { accessorKey: "aid", header: "AID", size: 100 },
        {
            accessorKey: "created_by",
            header: "Created By",
            size: 200,
            accessorFn: (row) => row.user ? row.user.name.toLowerCase() : "system generated", // ✅ Used for sorting
            sortingFn: (rowA, rowB) => {
                const nameA = rowA.original.user ? rowA.original.user.name.toLowerCase() : "system generated";
                const nameB = rowB.original.user ? rowB.original.user.name.toLowerCase() : "system generated";
                return nameA.localeCompare(nameB); // ✅ Alphabetical sorting
            },
            Cell: ({ row }) => (
                    row.original.user 
                    ? `${row.original.user.name} ${
                        row.original.user.roles_id === 1 ? "(Super Admin)"
                        : row.original.user.roles_id === 2 ? "(Admin)"
                        : row.original.user.roles_id === 3 ? "(Affiliate)"
                        : "(No Role)"  // <-- Added default case
                    }`
                    : "System Generated"
            ),
        },
        {
            accessorKey: "created_at",
            header: "Created At",
            size: 150,
            Cell: ({ row }) => (Moment(row.original.created_at).tz("America/Los_Angeles").format("MM-DD-YYYY")),
        },
        {
            accessorKey: "aid_status",
            header: "Status",
            size: 120,
            Cell: ({ row }) => (row.original.aid_status === 1 ? "Active" : "Disabled"),
        },
    ];
    
    return(
        <>
            <Box sx={{ width: "100%" }}>
                <MaterialReactTable
                    columns={aidColumns}
                    data={aids}
                    enablePagination
                    enableColumnFilters
                    enableSorting
                    enableGlobalFilter
                    enableRowSelection={false}
                    muiTablePaperProps={{
                        sx: { backgroundColor: "white" },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            backgroundColor: "#2A68B3",
                            color: "white",
                            fontWeight: "bold",
                        },
                    }}
                    muiTableBodyRowProps={({ row }) => ({
                        sx: { backgroundColor: row.index % 2 === 0 ? "white" : "#EBF4FF" },
                    })}
                    renderTopToolbarCustomActions={({ table }) => (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between', // Keeps the layout consistent
                                gap: '1rem',
                                p: '0.5rem',
                                flexWrap: 'wrap',
                                width: '100%',
                            }}
                        >
                            {user.roles_id < 3 && (
                                <Button
                                    variant="contained"
                                    className="create-button"
                                    onClick={handleOpenAid}
                                >
                                    Create AID
                                </Button>
                            )}
                        </Box>
                    )}
                />
            </Box>
            {/* Aid Modal Open */}
            <Formik
                initialValues={{
                    AID: "",
                }}
                validationSchema={AidSchema}
                onSubmit={async (values, { resetForm }) => {
                    handleFreezStart();
                    const apiUrl = '/addCompanyAID';
                    const args = {
                        company_master_id: company_id,
                        aid: values.AID
                    }
                    const res = await dispatch(postData({ apiUrl, args })).unwrap();
                    let shouldClosePopup = false;
                    if (res) {
                        if (res.success === true) {
                            handleFreezStop();
                            swal({
                                title: "Success",
                                text: res.message,
                                icon: "success",
                                button: "Ok",
                                showBackdrop: true, // Show backdrop
                                closeOnClickOutside: false, // Disable closing on click outside
                            });
                            handleClose();
                            fetchCompanyDetails();
                            resetForm();
                            shouldClosePopup = true;
                        } else {
                            handleFreezStop();
                            handleClose();
                            resetForm();
                            swal({
                                title: "Failed",
                                text: "AID Already Exists.",
                                icon: "error",
                                button: "Ok",
                                showBackdrop: true,
                                closeOnClickOutside: false,
                            });
                        }
                    }
                    document.querySelector(".swal-modal").addEventListener("click", (event) => {
                        event.stopPropagation();
                    });

                    // Listen for clicks outside the popup and close it if needed
                    document.body.addEventListener("click", () => {
                        if (shouldClosePopup) {
                            swal.close();
                        }
                    });
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                    <>
                        <Dialog
                            open={open}
                            onClose={(event, reason) => {
                                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                                    handleClose();
                                }
                            }}
                            fullWidth={fullWidth}
                            maxWidth={maxWidth}
                            PaperProps={{
                                sx: {
                                    borderRadius: "12px",
                                    padding: "20px"
                                }
                            }}
                        >
                            <DialogTitle className="dialog-title" >
                                <Typography variant="h6" fontWeight="bold"> Create New AID </Typography>
                                <IconButton onClick={(e)=>{handleClose();}}>
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <TextField
                                        label="New AID"
                                        type="text"
                                        size="small"
                                        fullWidth
                                        margin='dense'
                                        value={values.AID}
                                        onChange={(e) => {
                                            handleChange(e);
                                        }}
                                        // onChange={(e) => { handlechange1(e.target.value) }}
                                        name='AID'
                                        id='AID'
                                        className="blue-border"
                                        helperText={touched.AID && errors.AID ? errors.AID : null}
                                        error={touched.AID && errors.AID ? errors.AID : null}
                                    />
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }} spacing={2} mt={1}>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" 
                                            onClick={(e)=>{
                                                handleClose();
                                            }}
                                            className="cancel-button">
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" onClick={handleSubmit} className="save-button">
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Dialog>
                    </>
                )}
            </Formik>
        </>
       
    );
}

export default AIDsTab;