import React, { useState, useRef } from "react";
import {Grid, Typography } from '@mui/material';


function ContentElement({ title, blockId, blockName, value, colorType, handleValue, customColorArray,openColorPicker, toggleColorPicker  }) {
    const [color, setColor] = useState(value);
    const [showColorPicker, setShowColorPicker] = useState(false);
    const colorInputRef = useRef(null);

    const handleImageClick = () => {
        if (colorInputRef.current) {
            colorInputRef.current.click();
        }
    };
    
    const onColorChange = (event) => {
        setColor(event.target.value);
        const block = document.getElementById(blockId)
        if (colorType == "text") {
            block.style.color = event.target.value?.hex || event.target.value;
        } else if (colorType == "icon" || colorType == "bodyText") {
            const blockClass = document.getElementsByClassName(blockId);
            for (var i = 0, len = blockClass.length; i < len; i++) {
                if (colorType == "bodyText") {
                    blockClass[i].style.color = event.target.value?.hex || event.target.value;
                } else {
                    blockClass[i].style["color"] = event.target.value?.hex || event.target.value;
                }
            }
        }
        else {
            block.style.background = event.target.value?.hex || event.target.value;
        }
        customColorArray[blockName] = event.target.value?.hex || event.target.value;

        handleValue(customColorArray);
    };
    const handleClose = () => {
        setShowColorPicker(!showColorPicker);
    };
    return (
        <>
            <div
                style={{
                    margin: "0.10rem 0",
                    height: "70px",
                    fontWeight: "500",
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: "#1C1F28" 
                }}
            >
                <Grid container item xs>
                    <Grid item xs mt={2}>
                        <Typography sx={{ fontWeight: "bold" }}>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item xs sx={{display:'flex',justifyContent:'center'}}>
                    <input
                    type="color"
                    id={blockId}
                    name={blockName}
                    value={color}
                    onChange={onColorChange}
                    style={{ height: "3vw", width: "3vw",borderRadius: "10%" }}
                    className="color-picker-size"
                    ref={colorInputRef}
                />
                <img style={{marginLeft : '5%', width: '8%', height: '50%', marginTop: '3%'}} src={require("../../../../src/assets/images/color-pencil.png")} onClick={handleImageClick}/>
                       
                    </Grid>
                </Grid>
            </div>
        </>

    );
}

export default ContentElement;
