import React, { useEffect, useState } from 'react';
import { Grid, Paper } from '@mui/material';
import AboutUs from '../FooterPages/AboutUs';
import ContactUs from '../FooterPages/ContactUs';
import TermsAndConditions from '../FooterPages/TermsAndConditions';
import PrivacyPolicy from '../FooterPages/PrivacyPolicy';
import CancelPolicy from '../FooterPages/CancelPolicy';
import RefundPolicy from '../FooterPages/RefundPolicy';
import Faq from '../FooterPages/Faq';
import { useNavigate,Link } from "react-router-dom";
import { Typography, Card, CardContent } from '@mui/joy';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import BackIcon from '@mui/icons-material/HighlightOff';
import config from '../../AppConfig';

function EnrollFooter(props) {
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const toggleDrawer1 = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpen1(open);
  };
  const toggleDrawer2 = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen2(open);
  };
  const toggleDrawer3 = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen3(open);
  };
  const toggleDrawer4 = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen4(open);
  };
  const toggleDrawer5 = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen5(open);
  };
  const toggleDrawer6 = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen6(open);
  };
  const toggleDrawer7 = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen7(open);
  };

  return (
    <>
      <Grid container item lg={12} md={12} sm={12} xs={12} sx={{position:"relative",width:"100%", marginBottom: '0'}}>
        <Paper sx={{
          border: 'none',
          bottom: 0,
          width:"100%",
           background: 'linear-gradient(to right, #F0FFEB, #e0fce7)'
          }} component="footer" square variant="outlined">
          <Card id="primaryColor" sx={{ backgroundColor:"#F0FFEB",zIndex:'0'}}>
            {/* <CardContent> */}
              <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                <Typography level="body1" fontSize="md">Powered By</Typography>
              </Grid>
              <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                <img  alt="#" src={require("../../assets/images/myfreescorenowLogo.png")} style={{width:"170px"}}/>
              </Grid>
              <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                  <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mb={1}>
                    <a onClick={toggleDrawer1(true)} style={{ cursor: "pointer", color: "#2C1A95", textDecoration: "none", fontSize: "1rem",fontWeight:700  }} className="hover-underline">About Us</a>&nbsp;&nbsp;<span style={{color:"#2C1A95"}} >|</span>&nbsp;&nbsp;
                    <SwipeableDrawer
                      anchor={'bottom'}
                      open={open1}
                      onClose={toggleDrawer1(false)}
                      onOpen={toggleDrawer1(true)}>
                      <AboutUs closeVal={toggleDrawer1(false)} />
                    </SwipeableDrawer>
                    {/* <a onClick={toggleDrawer2(true)} style={{ cursor: "pointer", color: "#2C1A95", textDecoration: "none", fontSize: "1rem",fontWeight:700  }} className="hover-underline">Contact</a>&nbsp;&nbsp;<span style={{color:"#2C1A95"}}>|</span>&nbsp;&nbsp;
                    <SwipeableDrawer
                      anchor={'bottom'}
                      open={open2}
                      onClose={toggleDrawer2(false)}
                      onOpen={toggleDrawer2(true)}
                    >
                      <ContactUs closeVal={toggleDrawer2(false)} />
                    </SwipeableDrawer> */}
                      {/* <a style={{ cursor: "pointer", color: "#2C1A95", textDecoration: "none", fontSize: "1rem" ,fontWeight:700}} onClick={() => {
                      localStorage.setItem('origin', "mfsnhomepage");
                      
                      window.location.href = config.BASE_URL + 'register';  
                    }} className="hover-underline">
                      Become An Affiliate
                    </a>&nbsp;&nbsp;<span style={{color:"#2C1A95"}}>|</span>&nbsp;&nbsp; */}
                    <a onClick={toggleDrawer3(true)} style={{ cursor: "pointer", color: "#2C1A95", textDecoration: "none", fontSize: "1rem",fontWeight:700  }} className="hover-underline">Terms & Conditions</a>&nbsp;&nbsp;<span style={{color:"#2C1A95"}}>|</span>&nbsp;&nbsp;
                    <SwipeableDrawer
                      anchor={'bottom'}
                      open={open3}
                      onClose={toggleDrawer3(false)}
                      onOpen={toggleDrawer3(true)}
                    >
                      <TermsAndConditions closeVal={toggleDrawer3(false)} />
                    </SwipeableDrawer>
                    <a onClick={toggleDrawer4(true)} style={{ cursor: "pointer", color: "#2C1A95", textDecoration: "none", fontSize: "1rem",fontWeight:700  }} className="hover-underline">Privacy Policy</a>&nbsp;&nbsp;
                    <SwipeableDrawer
                      anchor={'bottom'}
                      open={open4}
                      onClose={toggleDrawer4(false)}
                      onOpen={toggleDrawer4(true)}
                    >
                      <PrivacyPolicy closeVal={toggleDrawer4(false)} />
                    </SwipeableDrawer>
                  </Grid>
                  <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                    <Typography level="body1" fontSize="md" sx={{ textAlign: "center" }}>Copyright © {currentYear} www.MyFreeScoreNow.com. All rights reserved. MyFreeScoreNow.com is operated by MyFreeScoreNow Inc.</Typography>
                  </Grid>
                  
              </Grid>
            {/* </CardContent> */}
          </Card>
        </Paper>
      </Grid>
    </>
  );
}
export default EnrollFooter;
