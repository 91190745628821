
import React,{useMemo,useState,useEffect} from 'react';
import { useTheme } from '@mui/material/styles';
import {Box,Tooltip,Table ,TableBody ,TableCell,TableContainer,TableHead,TableRow,Paper,Button } from '@mui/material';
import MaterialReactTable from 'material-react-table';
//Date Picker Imports
import {  useNavigate } from 'react-router-dom';
import AuthUser from "../Auth/AuthUser";
//Icons Imports
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import config from '../../AppConfig';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../redux/apiSlice';
import { async } from 'q';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import InfoIcon from '@mui/icons-material/Info';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Moment from 'moment';
import moment from 'moment';
import {currencyFormat} from '../../Components/CommonFormula';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }
  
  const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  ];
function CommissionSummary(props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const { http, user } = AuthUser();
  const [data, setData] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [search, setSearch] = useState("");
  const [perPage, setPerPage] = useState(100000);
  const [commissionSummarylist, setCommissionSummarylist] = useState(props.commissionSummarylist);
  const q = search == '' ? 'null' : search;
  const navigate = useNavigate();
  const [columnVisibility, setColumnVisibility] = React.useState({});
 


const tablePreferencesArray = { 

  }


  const viewDetails = (month,year) => {
    
    navigate('/affiliate/commission-details', { state: { month:month, year:year} });
  };

  const onCalendar = (acceptedDate,verifiedStartedDate,verifiedMiniEndedDate,verifiedEndedDate,paymentMadeStartedDate,paymentMadeEndedMiniEndedDate,paymentMadeEndedDate,holidays) => {
    props.onCalendar(acceptedDate,verifiedStartedDate,verifiedMiniEndedDate,verifiedEndedDate,paymentMadeStartedDate,paymentMadeEndedMiniEndedDate,paymentMadeEndedDate,holidays);
  }


  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  //save preference function start here
  useEffect(() => {
  }, [rowSelection]);
   ////////////////////////
   useEffect(() => {
    if(Object.values(columnVisibility).length>0){
      setColumnVisibility(columnVisibility);
    }
    //do something when the row selection changes...
    // console.info({ rowSelection });
  }, [rowSelection]);

  useEffect(() => {
    tablePreferences();
  }, [rowSelection]);

  useEffect(()=>{  
    saveTablePreferences();
},[columnVisibility])
  ////////////////////////

  const tablePreferences = async() => {   
    const apiUrl = '/fetchTablePreferences/affiliatecommisionsummary' ;
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if(res) {
          if (Object.values(res).length == 0) {
            setColumnVisibility(tablePreferencesArray);
          } else {
            setColumnVisibility((JSON.parse(res.preferences)));
          }
    }
  }

  const saveTablePreferences = async() => {
    if (Object.values(columnVisibility).length > 0) {
      const apiUrl = '/saveTablePreferences';
      const args = {
          columnArray: columnVisibility,
          userId: user.id,
          tableId: 'affiliatecommisionsummary'
      }
      const res = await dispatch(postData({apiUrl,args})).unwrap();
    } 
  }
  
  //save preference function end here
      const CommisionListColumns = useMemo(
        () => [
              {
                accessorFn: (row) => `${row.month?row.month:''}`, //accessorFn used to join multiple data into a single cell
                id: 'month', //id is still required when using accessorFn instead of accessorKey
                header: 'Month',
                size: 200,
                enableClickToCopy: false,
                Cell: ({ renderedCellValue, row }) => (row.original.month? row.original.month: 'N/A'),
              },
              {
                accessorFn: (row) => `${row.year?row.year:''}`, //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                id: 'year', //id is still required when using accessorFn instead of accessorKey
                header: 'Year',
                size: 100,
                enableClickToCopy: false,
                Cell: ({ renderedCellValue, row }) => (row.original.year? row.original.year: 'N/A'),
              },
              {
                accessorFn: (row) => `${row.totalCommission?row.totalCommission:''}`, //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                id: 'commission', //id is still required when using accessorFn instead of accessorKey
                header: 'Commission ($)',
                size: 100,
                enableClickToCopy: false,
                sortingFn: (rowA, rowB) => {
                  const sortA = rowA.original.totalCommission;
                  const sortB = rowB.original.totalCommission;
                  return sortA-sortB;
                },
                Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.totalCommission))),
              },
              {
                accessorFn: (row) => `${row.referralCommission?row.referralCommission:''}`, //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                id: 'ReferralCommission', //id is still required when using accessorFn instead of accessorKey
                header: 'Referral Commission ($)',
                size: 100,
                enableClickToCopy: false,
                sortingFn: (rowA, rowB) => {
                  const sortA = rowA.original.referralCommission;
                  const sortB = rowB.original.referralCommission;
                  return sortA-sortB;
                },
                Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.referralCommission))),
              },
              {
                accessorFn: (row) => `${row.bonus?row.bonus:''}`, //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                id: 'bonus', //id is still required when using accessorFn instead of accessorKey
                header: 'One Time Referral Bonus ($)',
                size: 100,
                enableClickToCopy: false,
                sortingFn: (rowA, rowB) => {
                  const sortA = rowA.original.bonus;
                  const sortB = rowB.original.bonus;
                  return sortA-sortB;
                },
                Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.bonus))),
              },
              {
                accessorFn: (row) => `${row.targetBonus?row.targetBonus:''}`, //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                id: 'EnrollmentBonus', //id is still required when using accessorFn instead of accessorKey
                header: 'Target Incentive ($)',
                size: 100,
                enableClickToCopy: false,
                sortingFn: (rowA, rowB) => {
                  const sortA = rowA.original.targetBonus;
                  const sortB = rowB.original.targetBonus;
                  return sortA-sortB;
                },
                Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.targetBonus))),
              },
              {
                accessorFn: (row) => `${row.finalTotalCommission?row.finalTotalCommission:''}`, //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                id: 'totalCommission', //id is still required when using accessorFn instead of accessorKey
                header: 'Total Commission ($)',
                size: 100,
                enableClickToCopy: false,
                sortingFn: (rowA, rowB) => {
                  const sortA = rowA.original.finalTotalCommission;
                  const sortB = rowB.original.finalTotalCommission;
                  return sortA-sortB;
                },
                Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.finalTotalCommission))),
              },
              {
                accessorFn: (row) => `${row.paymentAmount?row.paymentAmount:''}`, //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                id: 'paymentAmount', //id is still required when using accessorFn instead of accessorKey
                header: 'Payment Amount ($)',
                size: 100,
                enableClickToCopy: false,
                sortingFn: (rowA, rowB) => {
                  const sortA = rowA.original.paymentAmount;
                  const sortB = rowB.original.paymentAmount;
                  return sortA-sortB;
                },
                Cell:({renderedCellValue,row}) =>(row.original.paymentAmount == 0 ? 'N/A' : (currencyFormat(parseFloat(row.original.paymentAmount)) + (row.original.paymentAmount > row.original.finalTotalCommission ? '*' : ''))),
              },
              {
                accessorFn: (row) => `${row.paymentDate?row.paymentDate:''}`, 
                id: 'PaymentDate', 
                header: 'Payment Date',
                size: 100,
                enableClickToCopy: false,
                sortingFn: (rowA, rowB) => {
                  const sortOrder = {
                      "On Hold": 1,
                      "Not Accepted": 2,
                      "Commission Accepted": 3
                  };
          
                  const valueA = rowA.original.paymentDate;
                  const valueB = rowB.original.paymentDate;
          
                  // Determine if the value is a date
                  const isDateA = moment(valueA, 'YYYY-MM-DD', true).isValid();
                  const isDateB = moment(valueB, 'YYYY-MM-DD', true).isValid();
          
                  // Convert values to sortable format
                  const sortA = sortOrder[valueA] !== undefined ? sortOrder[valueA] : isDateA ? new Date(valueA).getTime() : 0;
                  const sortB = sortOrder[valueB] !== undefined ? sortOrder[valueB] : isDateB ? new Date(valueB).getTime() : 0;
          
                  return sortA - sortB;
              },
                Cell: ({ renderedCellValue, row }) => (
                  moment(row.original.paymentDate, 'YYYY-MM-DD', true).isValid() ? moment(row.original.paymentDate).format('MM-DD-YYYY') 
                   : 
                (
                  row.original.paymentDate == "On Hold" ? 
                  
                  <>
                  On Hold
                  <Tooltip title="Approved balance should be a minimum of $100 to be paid"   arrow> 
                    <InfoIcon color="black" sx={{ marginLeft:'8px',verticalAlign: 'bottom'}} />
                  </Tooltip>
                  </>
                : 
                (
                  row.original.paymentDate == "Not Accepted" ? 
                  <>
                    Not Accepted
                  </>
                  :
                  (
                    Object.values(row.original.paymentDate).length > 1 ? 
                  <>
                    Commission Accepted
                    <CalendarMonthIcon color="black" sx={{ marginLeft:'8px',verticalAlign: 'bottom',cursor:'pointer'}} onClick={()=> onCalendar(`${row.original.paymentDate.acceptedDate}`,`${row.original.paymentDate.verifiedStartedDate}`,`${row.original.paymentDate.verifiedMiniEndedDate}`,`${row.original.paymentDate.verifiedEndedDate}`,`${row.original.paymentDate.paymentMadeStartedDate}`,`${row.original.paymentDate.paymentMadeEndedMiniEndedDate}`,`${row.original.paymentDate.paymentMadeEndedDate}`,`${row.original.paymentDate.holidays}`)}/>
                  </>
                  : ''
                  )
                )
                )
                ),
              },
              {
                accessorFn: (data) => `${data}`,
                id: 'action',
                header: 'Action',
                size: 100,
                enableClickToCopy: false,
                enableSorting: false,
                Cell: ({ row }) => (
                  
                    <>        
                      <Tooltip title="Details" arrow>
                        <Button className='btn btn-sm btn-info text-white'  onClick={()=> viewDetails( `${row.original.month}`,row.original.year)} >
                          <img src={require('../../assets/images/details.png')} />
                      </Button>
                      </Tooltip>
                    </> 
                  
                ),
               
              },
        ]
      );
  //csv option start here
  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    fileName: 'Commission Summary Report',
    headers: ['Month','Year', 'Commission ($)','Referral Commission ($)', 'One Time Referral Bonus ($)','Target Incentive ($)','Total Commission ($)','Payment Amount ($)','Payment Date',],
  };

  const csvExporter = new ExportToCsv(csvOptions);
  const handleExportData = (commissionSummarylist) => {
    const exportedData = commissionSummarylist.map((row) => {
      return {
        month: `${row.original.month? row.original.month: 'N/A'}`,
        year: `${row.original.year? row.original.year: 'N/A'}`,
        totalCommission: `${row.original.totalCommission ? row.original.totalCommission : '0'}`,
        referralCommission: `${row.original.referralCommission ? row.original.referralCommission : '0'}`,
        bonus:`${row.original.bonus ? row.original.bonus : '0'}`,
        targetBonus:`${row.original.targetBonus ? row.original.targetBonus : '0'}`,
        finalTotalCommission:`${row.original.finalTotalCommission ? row.original.finalTotalCommission : '0'}`,
        paymentAmount: (row.original.paymentAmount == 0 ? 'N/A' : (currencyFormat(parseFloat(row.original.paymentAmount)) + (row.original.paymentAmount > row.original.finalTotalCommission ? '*' : ''))),
        paymentDate:`${moment(row.original.paymentDate, 'YYYY-MM-DD', true).isValid() ? row.original.paymentDate : row.original.paymentDate == "Not Accepted" ? "Not Accepted" :'Commission Accepted'}`,
      };
    });

    const csvContent = convertToCSV(exportedData);
    // Create a Blob object from the CSV data
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

    // Create a download link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = csvOptions.fileName + '.csv';

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the file download
    link.click();

    // Clean up the temporary URL and remove the link from the document
    URL.revokeObjectURL(link.href);
    link.remove();
  };

  const convertToCSV = (data) => {
    const headers = csvOptions.headers.join(',') + '\n';

    const rows = data.map((row) => {
      return Object.values(row).map((value) => {
        // Handle any necessary formatting or escaping of values
        // For simplicity, we assume all values are already properly formatted
    
        // Wrap the value with double quotes if it contains a comma
        if (typeof value === 'string' && value.includes(',')) {
        return `"${value}"`;
        }
    
        return value;
      }).join(',');
      }).join('\n');

    return headers + rows;
  };
  //csv end here
   
        return (
            <>

            <MaterialReactTable
                columns={CommisionListColumns}
                data={commissionSummarylist}
                enableColumnFilterModes
                filterFns={{
                  customSearchFilterFn: (row, id, filterValue) =>
                  row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
                 }} 
                globalFilterFn="customSearchFilterFn" //exact serach filter functionality
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{ columnVisibility, rowSelection }} 
                onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
                enableRowSelection
                enableColumnFilters={config.DatatableColumnFilter}
                initialState={{ showColumnFilters: false, density: 'compact' }}
                positionToolbarAlertBanner="bottom"
                enableDensityToggle={false}
                muiTableHeadCellProps={{
                    //simple styling with the `sx` prop, works just like a style prop in this example
                    sx: {
                      backgroundColor:"#F6FAFF",
                    },
                  }} 
                renderDetailPanel={({ row }) => (
                    <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                    }}
                    >
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 500 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>

                                <TableCell align="center" sx={{fontWeight:"bold"}}>Aid</TableCell>
                                <TableCell align="center" sx={{fontWeight:"bold"}}>Commission</TableCell>
                                </TableRow>
                            </TableHead>
                              <TableBody>
                                {row.original.summaryData.map((aidArr) => (
                                  <TableRow
                                    key={aidArr.aid}
                                  >
                                    <TableCell align="center" sx={{ width: '50%' }}>
                                      {aidArr.aid}
                                    </TableCell>
                                    <TableCell align="center" sx={{ width: '50%' }} >
                                      {currencyFormat(parseFloat(aidArr.aidCommission))}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                )}
                //top csv option
                renderTopToolbarCustomActions={({ table }) => (
                  <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                  >
                     <Button
                color="primary"
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                //export all rows, including from the next page, (still respects filtering and sorting)
                  onClick={() =>
                    handleExportData(table.getPrePaginationRowModel().rows)
                  }
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                // onClick={handleExportData}
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                CSV
              </Button>
                  </Box>
                )
              } 
                />
            </>
            );
        
    }
    
    
    export default CommissionSummary;