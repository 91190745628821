
import React,{useState,useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import { Grid,Tooltip, Stack,Table,TableRow, TableCell} from '@mui/material';
import { Typography, Card, CardOverflow, CardContent,Button } from '@mui/joy';
import DefaultLayout from '../../../Components/DefaultLayout';
import AuthUser from '../../../Components/Auth/AuthUser';
import Loader from '../../../Components/Loader/Loader';
import BackIcon from '@mui/icons-material/HighlightOff';
import config from '../../../AppConfig';
import { useDispatch } from 'react-redux';
import { fetchData } from '../../../redux/apiSlice';
import swal from "sweetalert";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

let logoURL = require("../../../assets/images/uploadLogo.png");


function CobrandDetails(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = AuthUser();
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [cobrandObj, setCobrandObj] = useState([]);
    const [updatedAttributes, setUpdatedAttributes] = useState([]);

    // API Call starts here
    const getCobrandData = async () => {
        const apiUrl = '/cobrandRequest/show/' + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            if (res.success === true) {
                setCobrandObj(res.data.cobrandObj);
                if (res.data.updatedAttributes) {
                    setUpdatedAttributes(res.data.updatedAttributes);
                }
                if (res.data.cobrandObj) {
                    if (res.data.cobrandObj.company_logo) {
                        logoURL = config.BACKEND_URL + "images/companyLogo/" + res.data.cobrandObj.company_logo;
                    }
                }
                setIsLoading(false);
            } else {
                swal({
                    title: "Failed",
                    text: res.message,
                    icon: "error",
                    button: "Ok",
                })
                window.location.href = config.BASE_URL + 'login';
                return false;
            }

        }
    }
    // API Call ends here

    useEffect(() => {
        getCobrandData();
    }, [])

    function ContentLayout() {
        return (
            <>
                {isLoading ? <Loader /> : <></>}
                <Grid container item lg={12} md={12} sm={12} xs={12} pb={2} direction="row" justifyContent={'space-between'}>
                    <Grid item >
                        <Grid item lg={12} md={12} sm={12} xs={12} >
                            <Stack direction={"row"}  >
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Card variant='outlined' size='md' sx={{ height: 150, width: 300 }} color={updatedAttributes.includes('company_logo') ? "warning" : ''} >
                                        <CardContent sx={{ textAlign: 'center', justifyContent: 'center' }}>
                                            <img className="dashlogo" style={{ height: '90px' }} src={logoURL} />
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12} pl={2} justifyContent={'space-between'}>
                                    <Grid item>
                                        <Typography color="success">Status:
                                            {cobrandObj ? cobrandObj.is_active == 1 ? 'Enabled' + (cobrandObj.status == "Updated" || cobrandObj.status == "PlanUpdated" ? " (Updated)" : '') : cobrandObj.status : 'Requested'}
                                        </Typography>
                                    </Grid>
                                    {user.roles_id != 5
                                        ?
                                        <Grid item >
                                            {cobrandObj.status == "Disabled" ? '' :
                                                <Button color="success" sx={{ width: 200 }}
                                                    onClick={() => { navigate("/cobrand-request/" + id + "?updateFlag=1") }}
                                                    disabled={cobrandObj ? cobrandObj.status == "Requested" || cobrandObj.status == "Updated" || cobrandObj.status == "Verified" || cobrandObj.status == "PlanUpdated" || cobrandObj.status == "Disabled" ? true : false : false}
                                                >{cobrandObj ? cobrandObj.status == "Requested" || cobrandObj.status == "Updated" || cobrandObj.status == "Verified" || cobrandObj.status == "PlanUpdated" || cobrandObj.status == "Disabled" ? cobrandObj.status == "Disabled" ? "Update" : "Under Verification" : "Update" : "Update"}</Button>
                                            }
                                        </Grid>
                                        : null}
                                </Grid>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid item >
                        <Tooltip title="Close" >
                            <BackIcon fontSize="large" sx={{ cursor: 'pointer' }} onClick={() => navigate(-1)} />
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid container item xs={12}>
                <Card variant='outlined' sx={{width:'100%'}}>
                    <CardOverflow
                        sx={{
                            bgcolor:'#F6FAFF',
                            borderColor:'#F6FAFF',
                            padding: '1%',                            
                        }}
                    >
                        <Typography level="h4" color="primary">Details</Typography>
                    </CardOverflow>
                    <CardContent>
                        <Table>
                            {
                                cobrandObj.selected_cobrand_plan
                                ?          
                                Object.values(cobrandObj.selected_cobrand_plan).length > 0
                                ?                      
                                <TableRow>
                                    <TableCell style={{ width: '40%' }}><Typography>Current Co-Brand Plans :</Typography></TableCell>
                                    <TableCell style={{ width: '60%',fontWeight:'500' }} className="cobrandText">                                    
                                    {
                                        (cobrandObj.selected_cobrand_plan.map((row,idx)=>{           
                                            return(
                                                <Typography>{idx+1}. PID : {row.pid}, Plan : {row.base_plan.plan_name?row.base_plan.plan_name:'N/A'}</Typography>
                                            )                                       
                                        }))      
                                    } 
                                    </TableCell>
                                </TableRow>
                                :null
                                :null
                            }
                            {
                                cobrandObj.requested_cobrand_plan
                                ?
                                <TableRow>
                                    <TableCell style={{ width: '40%' }}><Typography>Requested Co-Brand Plan :</Typography></TableCell>
                                    <TableCell style={{ width: '60%',fontWeight:'500' }} className="cobrandText">                                    
                                        <Typography color={'warning'}>{cobrandObj.requested_cobrand_plan.base_plan.plan_name ? cobrandObj.requested_cobrand_plan.base_plan.plan_name : 'N/A'} -(Clone of PID {cobrandObj.requested_cobrand_plan.base_plan.base_pid ? cobrandObj.requested_cobrand_plan.base_plan.base_pid : 'N/A'})</Typography>                                            
                                    </TableCell>
                                </TableRow>
                                :
                                null
                            }
                            <TableRow>
                                <TableCell><Typography>Contact Name :</Typography></TableCell>
                                <TableCell className="cobrandText" sx={{fontWeight:'500'}}>
                                    <Typography color={updatedAttributes.includes('name') ? "warning" : ''}>{cobrandObj.name}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><Typography>Company Name :</Typography></TableCell>
                                <TableCell className="cobrandText" sx={{fontWeight:'500'}}>
                                    <Typography color={updatedAttributes.includes('company_name') ? "warning" : ''}>{cobrandObj.company_name?cobrandObj.company_name:'N/A'}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><Typography>Email address 1 :</Typography></TableCell>
                                <TableCell className="cobrandText" sx={{fontWeight:'500'}}>
                                    <Typography color={updatedAttributes.includes('email') ? "warning" : ''}>{cobrandObj.email?cobrandObj.email:'N/A'}
                                    { cobrandObj.email ? 
                                    
                                        cobrandObj.bounce_email ?
                                                <Tooltip title="Emails are not getting delivered to this address. Please update the email" arrow>
                                                <ErrorOutlineIcon color={'error'} />
                                                </Tooltip>  : "" : "" }
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><Typography>Email address 2 :</Typography></TableCell>
                                <TableCell className="cobrandText" sx={{fontWeight:'500'}}>
                                    <Typography color={updatedAttributes.includes('email2') ? "warning" : ''}>{cobrandObj.email2?cobrandObj.email2:'N/A'}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><Typography>Contact No. :</Typography></TableCell>
                                <TableCell className="cobrandText" sx={{fontWeight:'500'}}>
                                    <Typography color={updatedAttributes.includes('phone') ? "warning" : ''}>{cobrandObj.phone?cobrandObj.phone:'N/A'}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><Typography>Operational Hours :</Typography></TableCell>
                                <TableCell className="cobrandText" sx={{fontWeight:'500'}}>
                                    <Typography color={updatedAttributes.includes('operational_hrs') ? "warning" : ''}>{cobrandObj.operational_hrs?cobrandObj.operational_hrs:'N/A'}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><Typography>Co-branding Subtitle :</Typography></TableCell>
                                <TableCell className="cobrandText" sx={{fontWeight:'500'}}>
                                    <Typography color={updatedAttributes.includes('subtitle') ? "warning" : ''}>{cobrandObj.subtitle?cobrandObj.subtitle:'N/A'}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><Typography>Company Website(url) :</Typography></TableCell>
                                <TableCell className="cobrandText" sx={{fontWeight:'500'}}>
                                    <Typography color={updatedAttributes.includes('website_url') ? "warning" : ''}>{cobrandObj.website_url?cobrandObj.website_url:'N/A'}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><Typography>Special Messaging Paragraph 1 :</Typography></TableCell>
                                <TableCell className="cobrandText" sx={{fontWeight:'500'}} color={'warning'}>
                                    <Typography color={updatedAttributes.includes('special_message_1') ? "warning" : ''}>{cobrandObj.special_message_1?cobrandObj.special_message_1:'N/A'}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{borderBottom:'0px'}}><Typography>Special Messaging Paragraph 2 :</Typography></TableCell>
                                <TableCell className="cobrandText" sx={{fontWeight:'500',borderBottom:'0px'}}>
                                    <Typography color={updatedAttributes.includes('special_message_2') ? "warning" : ''}>{cobrandObj.special_message_2?cobrandObj.special_message_2:'N/A'}</Typography>
                                </TableCell>
                            </TableRow>
                        </Table>
                    </CardContent>
                </Card>
                
           </Grid>
        </>
        );
    }

    return (
        <div>
            <DefaultLayout content={<ContentLayout />} />
        </div>
    );
}

export default CobrandDetails;