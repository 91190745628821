import React, { useState, useEffect } from "react";
import { Box, Grid , Modal, TextField, MenuItem ,Dialog, DialogActions,DialogTitle, InputLabel, DialogContent, OutlinedInput, FormHelperText} from '@mui/material';
import { Typography, Card, CardContent, Button, Select, FormControl } from '@mui/joy';
import AuthUser from "../../Components/Auth/AuthUser";
import moment from 'moment';
import Freez from '../../Components/Loader/Loader'
import Backdrop from '@mui/material/Backdrop';
import { Formik, Field, Form } from "formik";
import * as Yup from 'yup';
import { fetchData, postData  } from '../../redux/apiSlice';
import { useSelector, useDispatch } from 'react-redux';
import QuestionAnswerDialog from "./QuestionAnswerDialog";

function NewEnrollment5(props) {
    const dispatch = useDispatch();
    const { http } = AuthUser();
    const currentDate = moment().format("MM/DD/YYYY");
    const [futureDate, setFutureDate] = useState(moment().add(7, 'days').format("MM/DD/YYYY"));


    //loader function and variable start
    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    //loader function and variable end


    //chagers and subscription values functions and variable starts
    const [offerdetails, setOfferDetails] = useState();

const pid = props.pid?props.pid:localStorage.getItem('pid');
const [acceptCheck,setAcceptCheck] = useState(true);
const [checkDisable,setCheckDisable] = useState(false);
const [credentialsStatus,setCredentialsStatus] =  useState(false);
const linkedAccFlag = localStorage.getItem('linkedAccFlag');

const [dialogOpen, setDialogOpen] = useState(false);
const handleDialogOpen = () => setDialogOpen(true);
const handleDialogClose = () => setDialogOpen(false);
const [snapshotLink, setSnapshotLink] = useState(localStorage.getItem("creditSnapshotLink"));

let actionUrl = "https://member.myfreescorenow.com/external-login";
let hiddenInputs = null;

if (snapshotLink) {
    try {
        const url = new URL(snapshotLink);
        actionUrl = url.origin + url.pathname;

        const params = new URLSearchParams(url.search);
        hiddenInputs = Array.from(params.entries()).map(([key, value]) => (
            <input type="hidden" key={key} name={key} value={value} />
        ));
    } catch (error) {
        console.error("Invalid URL provided:", error);
    }
}

const handleApiResponse = (data) => {
    console.log("API Response:", data);
    console.log("API Response Received:", data);

    // Find the form element
    const form = document.getElementById("enrollCompletionForm");

    // Populate hidden input fields with API response
    if (form) {
        if(!snapshotLink){
            form.elements["j_username"].value = localStorage.getItem('Main_email') || "";
            form.elements["j_password"].value = localStorage.getItem('tempPassword') || "";
        }
      form.submit();
    }
};

    const changeAcceptValue = () => {
        var acceptFlag = acceptCheck;
        acceptFlag = !acceptFlag;
        setAcceptCheck(!acceptCheck);
        if (acceptFlag == true) {
            const element = document.getElementById("continueDiv");
            element.style.display = "none";

            const element2 = document.getElementById("continueDetailsDiv");
            element2.style.display = "none";

        const element3 = document.getElementById("acceptBtnDiv");
        element3.style.display = "block";
    }else{
        const element = document.getElementById("acceptBtnDiv");
        element.style.display = "none";

        const element2 = document.getElementById("continueDiv");
        element2.style.display = "block";

        const element3 = document.getElementById("continueDetailsDiv");
        element3.style.display = "block";
    }
}

const getOfferDetails =()=>{
  
    handleFreezStart();
    http.post("/getofferdetailsofpid", {
        pid: pid,
    })
    .then((res) => {
        handleFreezStop();
        if (res.data.success === true) {     
            setOfferDetails(res.data.data[0])
            setFutureDate(moment().add(res.data.data[0].days, 'days').format("MM/DD/YYYY"));
        } 
    })
}

const checkEnrollData =()=>{
    handleFreezStart();
    var password = btoa(localStorage.getItem('Main_password'))
    http.post("/checkEnrollData", {
        customerToken: localStorage.getItem('customerToken'),
        password: password,
        linkedAccFlag: linkedAccFlag
    })
    .then((res) => {
        handleFreezStop();
            const element = document.getElementById("acceptBtnDiv");
            element.style.display = "none";

            const element2 = document.getElementById("continueDiv");
            element2.style.display = "block";

            const element3 = document.getElementById("continueDetailsDiv");
            element3.style.display = "block";
        })
    }
    
    useEffect(() => {
        getOfferDetails();
        var status = false;
        if (localStorage.getItem("credentialsStatus") === null || localStorage.getItem("credentialsStatus") == 0) {
            setCredentialsStatus(false);
        } else {
            setCredentialsStatus(localStorage.getItem('credentialsStatus'));
            status = localStorage.getItem('credentialsStatus');
        }
        if (status == true) {
            const element = document.getElementById("continueDiv");
            element.style.display = "none";

            const element2 = document.getElementById("continueDetailsDiv");
            element2.style.display = "none";

            const element3 = document.getElementById("acceptBtnDiv");
            element3.style.display = "block";
        } else {

            const element2 = document.getElementById("continueDiv");
            element2.style.display = "block";

            const element3 = document.getElementById("continueDetailsDiv");
            element3.style.display = "block";

            const element = document.getElementById("acceptBtnDiv");
            element.style.display = "none";
        }
    }, []);

    //chagers and subscription values functions and variable ends
    return (
            <>
                <Box p={4} className="enrollment-p-0">
                <form 
                    method={snapshotLink ? "get" : "post"} 
                    action={actionUrl} 
                    id="enrollCompletionForm"
                >
                    <Card variant="outlined">
                        {snapshotLink ? hiddenInputs : (
                            <>
                                <input type="hidden" name="loginType" value="CUSTOMER" />
                                <input type="hidden" name="j_username" value={localStorage.getItem('Main_email') || ''} />
                                <input type="hidden" name="j_password" value={localStorage.getItem('tempPassword') || ''} />
                            </>
                        )}
                        <CardContent>
                            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3}>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                                        <Typography level="h4"><b>Thank You! Your Order is Complete.</b></Typography>
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                                        <Typography level="h6"><b>Your Account:{props.internFlag === true ? 'John Smith' : `${localStorage.getItem('Main_firstName')} ${localStorage.getItem('Main_lastName')}`} <br />User Name: {props.internFlag === true ? 'john.smith@test.com' : localStorage.getItem('Main_email')}<br /></b></Typography>
                                    </Grid>

                                    {credentialsStatus ?
                                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} >
                                            <Typography level="h6">
                                                <input type="checkbox" checked={acceptCheck} disabled={checkDisable}
                                                    onChange={e => {
                                                        changeAcceptValue();
                                                    }}
                                                    style={{
                                                        appearance: !checkDisable ? 'none' : 'checkbox', // Hide the default appearance
                                                        width: '12px',
                                                        height: '12px',
                                                        border: '1px solid #3470B1', // Border color for unchecked state
                                                        borderRadius: '2px',
                                                        backgroundColor: acceptCheck && !checkDisable ? '#3470B1' : 'white', // Background color when checked
                                                        backgroundImage: acceptCheck && !checkDisable ? 'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'%23fff\'><path d=\'M13.6 1.6L5 10.2 2.4 7.6 1 9l4 4 9-9-1.4-1.4z\'/></svg>")' : 'none', // Checkmark background image when checked
                                                        color: 'white',
                                                        marginRight: '5px', // Adjust spacing
                                                        cursor: 'pointer', // Show pointer cursor on hover
                                                    }} />
                                                <span id="acceptDiv" style={{ fontWeight: "700" }}> By clicking the “Accept” button below, I hereby authorize MyFreeScoreNow to submit the login credentials (user ID: email address and password) to {localStorage.getItem('cobrandCompanyName')}. I understand and agree that the information I'm authorizing to submit to {localStorage.getItem('cobrandCompanyName')} is to enable them to provide me a personalized consultation.</span></Typography>
                                        </Grid>
                                        : ''}


                                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="continueDetailsDiv">
                                        <Typography level="h6" ><b>Click the “Continue” button below to see your score and report.</b></Typography>
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id='acceptBtnDiv'>
                                    <Button type="button" onClick={checkEnrollData} sx={{
                                            minWidth: 150, backgroundColor: "green", color: "white", borderRadius: "0%", '&:hover': {
                                                backgroundColor: 'green'
                                            }
                                        }}>Accept</Button>
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id='continueDiv'>
                                    <Button onClick={handleDialogOpen}  color="warning"  sx={{
                                            minWidth: 150, backgroundColor:'#ED7E2E', color: "white", borderRadius: "0%"
                                        }}>CONTINUE</Button>
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={1} id='OrderdetailsDiv'>
                                        <Typography level="h6"><b>Order Details :</b>

                                            <br />
                                            {

                                                offerdetails ? offerdetails.is_sponsored ?
                                                    <Typography level="h6" id="offerDetails" style={{ fontWeight: "700" }}>{offerdetails.description}</Typography>
                                                    :
                                                    offerdetails.days === 0 ?
                                                        pid === 11233 ?
                                                            <Typography level="h6" id="offerDetails" style={{ fontWeight: "700" }}>Get all 3 Reports and 3 Scores now!  A new 3-Bureau report included each month. $1.00 Upfront Activation Fee AND {offerdetails.price} Monthly MyFreeScoreNow Membership automatically charged at time of order. Payments non-refundable.
                                                             {/* Call 888-548-2008 to cancel to avoid future billings. */}
                                                             </Typography>
                                                            :
                                                            <Typography level="h6" id="offerDetails" style={{ fontWeight: "700" }}>Get all 3 Reports and 3 Scores now!  A new 3-Bureau report included each month. {offerdetails.price} Monthly MyFreeScoreNow Membership automatically charged at time of order. Payments non-refundable. 
                                                            {/* Call 888-548-2008 to cancel to avoid future billings. */}
                                                            </Typography>
                                                        :
                                                        offerdetails.hasFee === 'None' ?
                                                            <Typography level="h6" id="offerDetails" style={{ fontWeight: "700" }}>Get all 3 Reports and 3 Scores now! {offerdetails.days}-Day Free Trial of credit monitoring. {offerdetails.price} Monthly MyFreeScoreNow Membership automatically charged after the {offerdetails.days}-Day Trial Period. Payments non-refundable. 
                                                            {/* Call 1-888-548-2008 to cancel to avoid future billings. */}
                                                            </Typography>
                                                            :
                                                            <Typography level="h6" id="offerDetails" style={{ fontWeight: "700" }}>Get all 3 Reports and 3 Scores now! New 3-Bureau Report included monthly $1.00 Upfront Activation Fee. Monthly MyFreeScoreNow Membership of {offerdetails.price} automatically charged after {offerdetails.days}-Day Trial. Payments non-refundable. 
                                                            {/* Call 888-548-2008 to cancel to avoid future billings. */}
                                                            </Typography>
                                                    : ''}
                                        </Typography>
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                                        <Typography level="h6" id="MembershipDiv" style={{ fontWeight: "700" }}>Membership Fees will appear as this on your bank debit or credit card statement as:</Typography>
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                                        <Typography level="h6" id="webDiv" style={{ fontWeight: "700" }}>MYFREESCORENOW.COM</Typography>
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                                        <Typography level="h6" id="moNoDiv" style={{ fontWeight: "700" }}>(800) 218-8306 CA</Typography>
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Typography level="h6" id="SubscriptionDiv" style={{ fontWeight: "700" }}>Subscription Start Date :	</Typography>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Typography level="h6" id="dateDiv" style={{ fontWeight: "700" }}>{currentDate}</Typography>
                                        </Grid>
                                    </Grid>
                                    {
                                        offerdetails ? offerdetails.is_sponsored ? null :
                                            <>
                                                <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                                        <Typography level="h6" id="trialDiv" sx={{ color: "#2A68B3", fontWeight: "700" }}>Trial Period :</Typography>
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                                        <Typography level="h6" id="daysDiv" sx={{ color: "#2A68B3", fontWeight: "700" }}>{offerdetails ? offerdetails.days : ""} Days</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                                        <Typography level="h6" id="futureDateDiv" sx={{ fontWeight: "700" }}>Monthly fee starting on {futureDate}</Typography>
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                                        <Typography level="h6" id="priceDiv" sx={{ color: "#ED7E2E", fontWeight: "700" }}>{offerdetails ? offerdetails.price : ""}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </>
                                            : ''}
                                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                                        <Typography level="h6" id="questionDiv" sx={{ fontWeight: "700" }}>For questions or to cancel,<br />just call 1-888 548-2008</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <img src={require("../../assets/images/enrollmentimg.png")} alt="#" style={{ width: "100%" }} />
                                    <Typography level="h4" sx={{ fontWeight: "700", textAlign: 'center', marginBottom: '27px', color: '#3470B1' }} >Also available on</Typography>
                                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', textAlign: 'center' }}>
                                        <div>
                                            <img src={require("../../assets/images/applestorelogo.png")} alt="#" style={{ width: "50%", float: 'right' }} />
                                        </div>
                                        <div>
                                            <img src={require("../../assets/images/playstorelogo.png")} alt="#" style={{ width: "50%", float: 'left' }} />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </form>
            </Box>
            {/* loader code start */}
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                    onClick={handleFreezStop}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}
            <QuestionAnswerDialog open={dialogOpen} onClose={() => setDialogOpen(false)} onSubmitSuccess={handleApiResponse} handleFreez={handleFreezStart}
      />
        </>
    )
}
export default NewEnrollment5;