import React, { useState, useEffect} from 'react'
import { Box,Grid, TextField,Button,Card,Divider,useMediaQuery, Container,IconButton,OutlinedInput, FormHelperText,FormControl,InputLabel,InputAdornment, } from '@mui/material';
import { Typography } from 'material-ui';
import { useDispatch,useSelector } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import swal from "sweetalert";
import { Formik } from 'formik';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

let RequestFormInitialValues = {
  firstname: '',
  lastname: '',
  companyName: '',
  phone: '',
  password : '',
  email : '',
  companyId : ''
};

function M2galaRegister(props) {
    const isMobile = useMediaQuery('(max-width:600px)');
    const dispatch = useDispatch();
    const [registerEmail, setRegisterEmail] = useState('');
    const [emailError, setEmailError] = useState("");
    const [showForm,setShowForm] = useState(false);
    const [btn,setBtn] = useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

    const accountSchema = Yup.object() .shape({
        firstname: Yup.string()
                        .min(2, 'Too Short!')
                        .max(50, 'Too Long!')
                        .required('Please enter your full Name.'),
            
        lastname: Yup.string()
                    .min(2, 'Too Short!')
                    .max(50, 'Too Long!')
                    .required('Please enter your last Name.'),
        email: Yup.string()
                // .email('Invalid email format.')
                // .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid email format.")
                .matches(/^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24})$/i, "Invalid email format.")
                .required('Please enter your email address.'),
        phone: Yup.string()
                .test(
                    'is-10-digits',
                    'Must be 10 digits.',
                    (value) => value && value.replace(/\D/g, '').length === 10 // Ensure only numeric digits are counted
                )
                .required('Please enter your mobile number.'),
        companyName :   Yup.string()      
                    .required('Please enter your company name.'),
        password: Yup.string()
            .min(6, 'Password must be at least 6 characters')
            .required('Please enter password.'),

       
    });

    const validateEmail = (email) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    
            if (!email) {
                return "Please enter your email address.";
            } 
            if (!emailRegex.test(email)) {
                return "Invalid email format."; 
            }
            return "";
    };


    const validateRegisterEmail = async() => {
    // var myEmail = event.target.value;
    const errorMessage = validateEmail(registerEmail);
    setEmailError(errorMessage);
    
    
    if (errorMessage) {
        return;
    }

    const apiUrl = '/validateAffiliateEmail'; // Replace with your API endpoint
    const args = {
        email : registerEmail,
    };
    try{
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if(res){
    //   handleFreezStop();
        if(res.success === true) {
        
        // swal({
        //     title: "Success",
        //     text: res.message,
        //     icon: "success",
        //     button: "Ok",
        // })
        RequestFormInitialValues.firstname = res.user.first_name;
        RequestFormInitialValues.lastname = res.user.last_name;
        RequestFormInitialValues.email = res.user.email;
        RequestFormInitialValues.phone = res.user.phone_no;
        RequestFormInitialValues.companyName = res.user.company ? res.user.company.company_name : '';
        RequestFormInitialValues.companyId = res.user.company_master_id;
        console.log("RequestFormInitialValues:"+JSON.stringify(RequestFormInitialValues));
        setShowForm(true);
        setDisableBtn(true);
        }else{
            swal({
                title: "Failed",
                text: res.message,
                icon: "error",
                button: "Ok",
            })
        }
    }
    }catch (error) {
        console.error("Error validating email:", error);
    }
    
    };

    const CreateUser = async(values) => {
        // var myEmail = event.target.value;
        setBtn(true);
        const apiUrl = '/CreateGalaUser'; // Replace with your API endpoint
        const args = {
            email : values.email,
            password : values.password,
            firstname : values.firstname,
            lastname : values.lastname,
            phone : values.phone, 
            companyName : values.companyName, 
            companyId : values.companyId,
        };
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if(res){
        //   handleFreezStop();
            if(res.success === true) {
            // setTimeout(function () {
                swal({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    button: "Ok",
                })
            // }, 5000);
            // setBtn(false);
            setTimeout(() => {
                window.location.href = "https://www.m2gala.com/login";
            }, 2000); 
            
             // }
            }else{
                setBtn(false);
                setDisableBtn(false);
                setShowForm(false);
                let errorMessages = "";
                if (typeof res.message === "string") {
                    // Case 1: Message is a plain string
                    errorMessages = res.message;
                } else if (typeof res.message === "object") {
                    // Case 2: Message is an object (with arrays)
                    errorMessages = Object.values(res.message).flat().join("\n");
                }
                swal({
                    title: "Failed",
                    text: errorMessages,
                    icon: "error",
                    button: "Ok",
                });
            }
        }
        };
    
    return (
        <>
            <Container maxWidth="md">
                <Grid Container item lg={12} md={12} sm={12} xs={12} sx={{display:"flex",justifyContent:"space-between",alignItems:'flex-end',marginTop:isMobile?"4%":"1%"}}>
                    <Grid item  >
                            <img src={require('../../../assets/images/m2_gala.png')} style={{width: isMobile ? "26vw" : "9vw",height:"auto"}}/>
                    </Grid>
                    <Grid item  >
                            <img src={require('../../../assets/images/epr_circle.png')} style={{width: isMobile ? "25vw" : "8.5vw",height:"auto"}}/>
                    </Grid>
                    <Grid item sx={{textAlign:"right", marginTop:"4%"}}>
                        <Typography >Partnered with</Typography>                    
                        <img src={require('../../../assets/images/mainLogo.jpg')} style={{width: isMobile ? "40vw":"11vw"}} />
                    </Grid> 
                </Grid>                
            </Container>
            <Container maxWidth="sm">
                <Card sx={{boxShadow:"none",marginTop:isMobile?"4%":"2%"}}>
                    <Grid item Container lg={12} md={12} sm={12} xs={12} sx={{display:"flex",justifyContent:"center",paddingBottom:"10px",color:"green"}}>
                        <Typography style={{fontSize:"24px",color:"green"}}><b>Register For Your FREE M2Gala Account</b></Typography>
                    </Grid>
                    <Grid item Container lg={12} md={12} sm={12} xs={12} sx={{display:"flex",justifyContent:"center"}}>
                        <Typography><b>Please Enter Your MyFreeScoreNow Affiliate User Id (Email Address)</b></Typography>
                    </Grid>
                    <Grid item Container lg={12} md={12} sm={12} xs={12}  sx={{display:"flex",justifyContent:"center"}} >
                    <TextField id="registerEmail"
                        size="small" 
                        label="Please Enter Your MyFreeScoreNow User Id (Email Address)" 
                        variant="outlined" 
                        fullWidth 
                        margin='dense'
                        name="registerEmail"
                        value={registerEmail}
                        onChange={(event) => setRegisterEmail(event.target.value)}
                        error={Boolean(emailError)} 
                            helperText={emailError}
                    />
                    </Grid>
                    <Grid Container item sx={{textAlign: isMobile ? "center": "right"}} mt={1} lg={12} md={12} sm={12} xs={12}>
                        <Button variant="contained" color='warning' sx={{minWidth:213,backgroundColor:"#FF690B",color:"white",fontWeight:"bold", borderRadius:"7px"}} onClick={validateRegisterEmail} disabled={disableBtn}>Submit</Button>
                    </Grid>
                
                    {
                        showForm ?
                        <>
                        <Formik
                                    initialValues={RequestFormInitialValues}
                                    validationSchema={accountSchema}
                                    onSubmit={async(values, errors) => {
                                        CreateUser(values);
                                    }}
                                    >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                <>
                        <Grid Container item mt={1} lg={12} md={12} sm={12} xs={12}>
                            <Divider sx={{height:"0.5px",backgroundColor:"#757575"}} />
                        </Grid>
                        <Grid item Container lg={12} md={12} sm={12} xs={12}  mt={1}  sx={{display:"flex",justifyContent:"center"}}>
                            <TextField id="firstname" 
                            size="small" 
                            label="First Name" 
                            variant="outlined" 
                            fullWidth 
                            name="firstname"
                            onChange={handleChange('firstname')}
                            onBlur={handleBlur('firstname')}
                            value={values.firstname} 
                            margin='dense'
                            helperText={touched.firstname && errors.firstname ? errors.firstname : null}
                            error={touched.firstname && errors.firstname ? errors.firstname : null}
                            />
                        </Grid>
                        <Grid item Container lg={12} md={12} sm={12} xs={12}  mt={1}  sx={{display:"flex",justifyContent:"center"}}>
                            <TextField id="lastname" 
                            size="small" 
                            label="Last Name" 
                            variant="outlined" 
                            fullWidth 
                            name="lastname"
                            onChange={handleChange('lastname')}
                            onBlur={handleBlur('lastname')}
                            value={values.lastname}  
                            margin='dense'
                            helperText={touched.lastname && errors.lastname ? errors.lastname : null}
                            error={touched.lastname && errors.lastname ? errors.lastname : null}
                            />
                        </Grid>
                        <Grid item Container lg={12} md={12} sm={12} xs={12}  mt={1}  sx={{display:"flex",justifyContent:"center"}}>
                             <InputMask 
                             id="phone" 
                             mask='(999) 999-9999'
                             maskChar={null}
                             size="small" 
                             type="text"
                             label="Phone No" 
                             variant="outlined" 
                             fullWidth 
                             name="phone"
                             autoComplete="phone"
                            //  onChange={handleChange('phone')}
                            onChange={(e) => {
                                const numericValue = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                                setFieldValue('phone', numericValue); // Set only numbers to Formik
                            }}
                             onBlur={handleBlur('phone')}
                             value={values.phone}  
                             margin='dense'
                             helperText={touched.phone && errors.phone ? errors.phone : null}
                             error={touched.phone && errors.phone ? errors.phone : null}>
                                {(inputProps) =>
                                    <TextField
                                        label="Phone Number" variant="outlined"
                                        {...inputProps}
                                    />
                                }
                             </InputMask>
                            {/* <TextField 
                            id="phone" 
                            size="small" 
                            label="Phone" 
                            variant="outlined" 
                            fullWidth 
                            name="phone"
                            onChange={handleChange('phone')}
                            onBlur={handleBlur('phone')}
                            value={values.phone}  
                            margin='dense'
                            helperText={touched.phone && errors.phone ? errors.phone : null}
                            error={touched.phone && errors.phone ? errors.phone : null}
                            /> */}
                        </Grid>
                        <Grid item Container lg={12} md={12} sm={12} xs={12} mt={1}  sx={{display:"flex",justifyContent:"center"}}>
                            <TextField 
                            id="outlined-basic" 
                            size="small" 
                            label="Company Name" 
                            variant="outlined" 
                            fullWidth 
                            name="companyName"
                            value={values.companyName}  
                            onChange={handleChange('companyName')}
                            onBlur={handleBlur('companyName')}
                            margin='dense'
                            helperText={touched.companyName && errors.companyName ? errors.companyName : null}
                            error={touched.companyName && errors.companyName ? errors.companyName : null}
                            />
                        </Grid>
                        <Grid item Container lg={12} md={12} sm={12} xs={12}  mt={1}  sx={{display:"flex",justifyContent:"center"}}>
                            <TextField id="outlined-basic" 
                            size="small" 
                            label="Email" 
                            variant="outlined" 
                            fullWidth 
                            name="email"
                            value={values.email}  
                            onChange={handleChange('email')}
                            onBlur={handleBlur('email')}
                            margin='dense'
                            helperText={touched.email && errors.email ? errors.email : null}
                            error={touched.email && errors.email ? errors.email : null}
                            />
                        </Grid>
                        <Grid item Container lg={12} md={12} sm={12} xs={12}  mt={1} sx={{display:"flex",justifyContent:"center"}}>
                            <FormControl variant="outlined" fullWidth margin="dense" size="small">
                                <InputLabel>Password</InputLabel>
                                <OutlinedInput
                                    size="small" 
                                    label="Password" 
                                    variant="outlined" 
                                    fullWidth 
                                    name="password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={values.password}  
                                    onChange={handleChange('password')}
                                    onBlur={handleBlur('password')}
                                    helperText={touched.password && errors.password ? errors.password : null}
                                    error={touched.password && errors.password ? errors.password : null}
                                    margin='dense'
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? (
                                                <VisibilityOffIcon />
                                                ) : (
                                                <VisibilityIcon />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                        }
                                    />
                                <FormHelperText style={{ color: 'red' }}>
                                    {touched.password && errors.password ? errors.password : null}
                                </FormHelperText>
                            </FormControl>
                            {/* <TextField id="outlined-basic" 
                                size="small" label="Password" 
                                variant="outlined" 
                                fullWidth 
                                name="password"
                                type="password"
                                value={values.password}  
                                onChange={handleChange('password')}
                                onBlur={handleBlur('password')}
                                margin='dense'
                                helperText={touched.password && errors.password ? errors.password : null}
                                error={touched.password && errors.password ? errors.password : null}
                            /> */}
                        </Grid>
                        <Grid item Container lg={12} md={12} sm={12} xs={12}  mt={1}  sx={{textAlign: isMobile ? "center":"right"}}>
                            <Button variant="contained" color='warning' onClick={handleSubmit} disabled={btn}  sx={{minWidth:213,backgroundColor:"#FF690B",color:"white",fontWeight:"bold", borderRadius:"7px"}}>Submit</Button>
                        </Grid>
                        </>
                    )}
                    </Formik>
                        </>
                        : ''
                    }
                </Card> 
                <Grid sx={{textAlign:'center',marginTop:'2%',marginBottom:'2%',}}>
                    <iframe width={isMobile ? "" : "560"} height={isMobile ? "" : "315"} src="https://www.youtube.com/embed/ay7Wrpulsig?si=3HQbw2eAwVue-FdP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </Grid>
            </Container>
        </>
    )
    }

export default M2galaRegister;