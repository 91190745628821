import React,{useEffect} from "react";
import { Typography, Box, Grid, Button,Switch,Stack } from "@mui/material";
import Moment from 'moment';
import formatPhoneNumber from '../../../../Components/Formats/PhoneNumberValidation';
import config from '../../../../AppConfig';

const W9DetailsTab = ({fetchCompanyDetails,company_details,tax_details,user,checkUserRole,handleFreezStart,handleFreezStop}) => {
    const viewTaxForm = () => {
        window.location.href= config.BASE_URL + 'W9Form';
    }
    return(
        <>
            <Box className="common-container">
                {/* Row 1 */}
                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                    <Grid item xs={3}>
                        <Typography variant="body2" >Company Name :</Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{tax_details.company_name ? tax_details.company_name : "N/A"}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" >Name :</Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{tax_details.first_name && tax_details.last_name ? `${tax_details.first_name} ${tax_details.last_name}`: "N/A"}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" >Phone Number :</Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{formatPhoneNumber(tax_details.phone_number  ? tax_details.phone_number : 'N/A')}</Typography>
                        
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" >Mobile Number :</Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{formatPhoneNumber(tax_details.mobile_number ? tax_details.mobile_number : 'N/A')}</Typography>
                    </Grid>
                </Grid>
                {/* Row 2 */}
                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                    <Grid item xs={3}>
                        <Typography variant="body2" >Email :</Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{tax_details.email ? tax_details.email : "N/A"}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" >Street Address :</Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{tax_details.street_address ? tax_details.street_address : "N/A"}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" >City :</Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{tax_details.city ? tax_details.city : "N/A"}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2">State : </Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{tax_details.state_code ? tax_details.state_code  : "N/A"}</Typography>
                    </Grid>
                </Grid>
                {/* Row 3 */}
                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                    <Grid item xs={3}>
                        <Typography variant="body2">Zip : </Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{tax_details.zip ? tax_details.zip : "N/A"}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" >Country :</Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{tax_details.country ? tax_details.country : "N/A" }</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2">Fax : </Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{tax_details.fax ? tax_details.fax : "N/A" }</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" >Tax ID :</Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{tax_details.tax_id ? tax_details.tax_id : "N/A" }</Typography>
                    </Grid>
                </Grid>
                {/* Row 4 */}
                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                    <Grid item xs={3}>
                        <Typography variant="body2">Form Submitted : </Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{tax_details.submitted_flag == 1 ? "Yes" : "No"}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" >Form Submitted Date :</Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{tax_details.submitted_date ? Moment(tax_details.submitted_date).format('MM-DD-YYYY') : "N/A"}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2">Verify Status : </Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{tax_details.verification_flag == 1 ? "Yes" : "No" }</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" >Verification Date :</Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{tax_details.verification_date ? Moment(tax_details.verification_date).format('MM-DD-YYYY') : "N/A"}</Typography>
                    </Grid>
                </Grid>
                {/* Row 5 */}
                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                    
                    <Grid item xs={3} sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <Stack direction="row" alignItems="center" spacing={1} >
                            <Typography variant="body2">EIN</Typography>
                            <Switch checked={tax_details.proof_type === "ssn"} size="small" sx={{ transform: "scale(0.8)" }}/>
                            <Typography variant="body2">SSN</Typography>
                        </Stack>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{tax_details.tax_id ? tax_details.tax_id : "N/A" }</Typography>
                    </Grid>
                </Grid>
                {
                user.roles_id == 3 ?
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button variant="contained" className="create-button" onClick={viewTaxForm}>
                            Update
                        </Button>
                    </Box>
                :''}
            </Box>
        </>
       
    );
}

export default W9DetailsTab;