import { ExportToCsv } from 'export-to-csv';

import Moment from 'moment-timezone';
import formatPhoneNumber from '../Formats/PhoneNumberValidation';
const exportToCSV = (rows, type) => {
    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        fileName: `${type} Leads`,
        headers: getHeaders(type),
    };

    const csvExporter = new ExportToCsv(csvOptions);
    const exportedData = rows.map((row) => {
        const commonData = {
            name: `${row.original.first_name} ${row.original.last_name}` || "N/A",
            email_id: formatEmail(row),
            phone: row.original.phone_no ? formatPhoneNumber(row.original.phone_no) : "N/A",
            address: row.original.address || "N/A",
            city: row.original.city || "N/A",
            state_code: row.original.state_code || "N/A",
            zip_code: row.original.zip_code || "N/A",
            doc_id: row.original.pandadoc_id || "N/A",
            aid: row.original.company?.aid_master?.aid || "N/A",
            status: row.original.reference_affiliate_status_master_id === 1
                        ? (row.original.email_sent === 0 ? 'Email Not Sent' : 'Email Sent')
                        : (row.original.status?.status_name || 'N/A'),
            last_updated: row.original.updated_at ? Moment(row.original.updated_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') : "N/A",
            lead_generated_date: row.original.created_at ? Moment(row.original.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') : "N/A",
            company_name: row.original.company?.company_name || "N/A",
            followup: row.original.last_activity_logs?.type || "N/A",
            lead_type: row.original.company?.referred_by_company?.company_name || row.original.company?.referred_by || "N/A",
            software_used: row.original.company?.marketing_type || "N/A",
            comment: row.original.last_activity_logs?.notes?.replace(/[\r\n]+/g, '') || "N/A",
            assigned_to: row.original.lead_assigned_user?.name || "N/A",
        };

        switch (type) {
            case 'Hot':
                return {
                    ...commonData,
                   next_followup: `${row.original.last_activity_logs && row.original.last_activity_logs.add_next_followup !== "0000-00-00 00:00:00" && row.original.last_activity_logs.add_next_followup !== null ? Moment(row.original.last_activity_logs.add_next_followup).format('MM-DD-YYYY H:mm') : "N/A"}`,
                };
            case 'Active':
                return {
                    ...commonData,
                    next_followup: `${row.original.last_activity_logs && row.original.last_activity_logs.add_next_followup !== "0000-00-00 00:00:00" && row.original.last_activity_logs.add_next_followup !== null ? Moment(row.original.last_activity_logs.add_next_followup).format('MM-DD-YYYY H:mm') : "N/A"}`,
                };
            case 'Approved':
                return {
                    ...commonData,
                    communication_email: row.original.communication_email || "N/A",
                    last_60_days_enrollment: row.original.member_count?.total || 0,
                    approvedBy: row.original.lead_approved_user?.name || "N/A",
                    next_followup: `${row.original.last_activity_logs && row.original.last_activity_logs.add_next_followup !== "0000-00-00 00:00:00" && row.original.last_activity_logs.add_next_followup !== null ? Moment(row.original.last_activity_logs.add_next_followup).format('MM-DD-YYYY H:mm') : "N/A"}`,
                };
            case 'Completed':
                return {
                    ...commonData,
                    communication_email: row.original.communication_email || "N/A",
                    last_60_days_enrollment: row.original.member_count?.total || 0,
                    approvedBy: row.original.lead_approved_user?.name || "N/A",
                    next_followup: `${row.original.last_activity_logs && row.original.last_activity_logs.add_next_followup !== "0000-00-00 00:00:00" && row.original.last_activity_logs.add_next_followup !== null ? Moment(row.original.last_activity_logs.add_next_followup).format('MM-DD-YYYY H:mm') : "N/A"}`,
                    completedBy: row.original.lead_completed_user?.name || "N/A",
                    creditSnapshotStatus: row.original.company?.snapshot_flag === 1 ? "Enabled" : "Pending",
                    creditSnapshotBy: row.original.company?.snapshot_flag === 1 ? (row.original.credit_snapshot_by?.event_by_user?.name || "N/A") : "N/A",
                    referralStatus: row.original.company?.referral_flag === 1 && row.original.company?.referral_terms_and_conditions === 1 ? "Enabled" : "Pending",
                    referralProgramBy: row.original.company?.referral_flag === 1 ? row.original.referral_snapshot_by?.event_by_user?.name || "N/A" : "N/A",
                    cobrandingStatus: row.original.company?.cobranding_flag === 1 ? "Enabled" : "Pending",
                    cobrandingVerifiedBy: row.original.company?.cobranding_flag === 1 ? row.original.cobranding_snapshot_by?.event_by_user?.name || "N/A" : "N/A",
                };
            case 'Deferred':
                return {
                    ...commonData,
                    deferred_till: `${row.original.deferred_till ? Moment(row.original.deferred_till).format('MM-DD-YYYY H:mm') : 'N/A'}`,
                    
                };
            case 'Disabled':
                return {
                    ...commonData,
                    next_followup: `${row.original.last_activity_logs && row.original.last_activity_logs.add_next_followup !== "0000-00-00 00:00:00" && row.original.last_activity_logs.add_next_followup !== null ? Moment(row.original.last_activity_logs.add_next_followup).format('MM-DD-YYYY H:mm') : "N/A"}`,
                };
            default:
                return commonData;
        }
    });

    const csvContent = convertToCSV(exportedData, csvOptions);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = csvOptions.fileName + '.csv';
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(link.href);
    link.remove();
};
const convertToCSV = (data, csvOptions) => {
    const headers = csvOptions.headers.join(',') + '\n';

    const rows = data.map((row) => {
        return Object.values(row).map((value) => {
            // Wrap the value with double quotes if it contains a comma
            if (typeof value === 'string' && value.includes(',')) {
                return `"${value}"`;
            }
            return value;
        }).join(',');
    }).join('\n');

    return headers + rows;
};
// Function to return headers dynamically
const getHeaders = (type) => {
    const commonHeaders = ['Name', 'Email Address', 'Phone No.', 'Street Address', 'City', 'State', 'Zip', 'Agreement ID', 'AID', 'status', 'Last Updated', 'Lead Generated Date','Company Name', 'Followup', 'Lead Type', 'Software Used', 'Comment', 'Assigned To'];
    
    switch (type) {
        case 'Hot':
            return [...commonHeaders, 'Next Followup'];
        case 'Active':
            return [...commonHeaders, 'Next Followup'];
        case 'Approved':
            return [...commonHeaders, 'Communication Email Address', 'Enrollments Last 60 Days','Approved By','Next Followup'];
        case 'Completed':
            return [...commonHeaders, 'Communication Email Address', 'Enrollment last 60 days', 'Approved By','Next Followup','Completed By','credit Snapshot Status','credit Snapshot By','Referral Program Status','Referral Program By','Cobranding Status','Cobranding Verified By'];
        case 'Deferred':
            return [...commonHeaders, 'Deferred Till'];
        case 'Disabled':
            return [...commonHeaders, 'Next Followup'];
        default:
            return commonHeaders;
    }
};
const formatEmail = (row) => {
    let emailId = row.original.email || "N/A";
    if (row.original.reference_affiliate_status_master_id === 1 && (row.original.email_verified === 0 || row.original.email_verified === null)) {
        emailId += ' (Email Not Verified)';
    }
    return emailId;
};
// Function to call export
const handleExportLeads = (rows, type) => exportToCSV(rows, type);

export { handleExportLeads };