import React, { useState, useEffect,useRef  } from "react";
import {
  Modal,
  List, Paper, Box,
  ListItem,
  ListItemText, Grid, Button, Tooltip, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Stack, Table, TableBody, TableCell, TableRow, TextField, CardContent
} from '@mui/material';
import { Typography, Sheet, Card, CardOverflow } from '@mui/joy';
import AuthUser from "../../../Components/Auth/AuthUser";
import { useNavigate, useParams } from 'react-router-dom';
import swal from "sweetalert";
import config from '../../../AppConfig';
import { useDispatch } from "react-redux";
import { fetchData, } from "../../../redux/apiSlice";
import formatPhoneNumber from '../../../Components/Formats/PhoneNumberValidation';
import LogoutIcon from '@mui/icons-material/Logout';
import Freez from '../../../Components/Loader/Loader'
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import GaugeComponent from 'react-gauge-component';
import { useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from "@mui/material/styles";
import Chat from "../../../Components/CreditSnapshot/Chat";
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { Formik } from 'formik';
import * as Yup from 'yup';
// import { display } from "html2canvas/dist/types/css/property-descriptors/display";
import Moment from 'moment';
import EnrollFooter from '../../Auth/EnrollFooter';
import axios from "axios";

let chatSessionId = null;

const AnimatedButton = styled(Button)(({ theme }) => ({
  display: "contents",
  alignItems: "right",
  justifyContent: "right",
  overflow: "hidden",
  position: "relative",
  width: "48px",
  padding: theme.spacing(1),
  textTransform: "none", // Prevents uppercase transformation
  transition: theme.transitions.create("width", {
    duration: theme.transitions.duration.short,
  }),
  "&:hover": {
    variant:"contained",
    width: "150px", // Adjust this width for wrapping effect
  },
  "& .icon": {
    
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.short,
    }),
    marginRight: theme.spacing(1),
  },
  "& .text": {
    opacity: 0,
    whiteSpace: "nowrap",
    transform: "scaleX(0)", // Initially hidden
    transformOrigin: "left",
    transition: theme.transitions.create(["opacity", "transform"], {
      duration: theme.transitions.duration.short,
    }),
  },
  "&:hover .text": {
    opacity: 1,
    transform: "scaleX(1)", // Fully visible on hover
  },
}));
let newDateStr = '';
function SnapshotScore(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { http } = AuthUser();
  const { userId } = useParams();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [freez, setFreez] = React.useState(false);
  const [creditScore, setCreditScore] = useState(0);
  const [logourl, setlogourl] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [open, setOpen] = React.useState(false);
  const [BlackboxObj, setBlackboxObj] = useState(null);
  const [startJourneyOpen, setStartJourneyOpen] = useState(false);
  const [msgChatopen, setMsgChatopen] = useState(false);
  const [memberMsg, setMemberMsg] = useState("");
  const [assistYouFlag, setAssistYouFlag] = useState(false);
  // const crsSignupEnabled = useSelector((state) => state.auth.crsSignupEnabled == 1 ? true : false);
  const [crsSignupEnabled, setCrsSignupEnabled] = useState(false)
  const [emailChatopen, setEmailChatopen] = useState(false);
  const [companyId, setCompanyId] = useState();
  const CRSForm = "CRSForm";
  const BASE_URL = config.BASE_URL;
  const chatbotRef = useRef(null);
  const [creditSnapshotObj, setCreditSnapshotObj] = useState([]);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const currentYear = new Date().getFullYear();

  const[messages, setMessages] = useState([]);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(true); // Minimize state
  const [score, setScore] = useState('');
  const [Fname, setFname] = useState('');
  const [memberEmail, setMemberEmail] = useState('');

  //added gauge 
  const toggleDrawer1 = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpen1(open);
  };
  const toggleDrawer2 = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen2(open);
  };
  const toggleDrawer3 = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen3(open);
  };
  const toggleDrawer4 = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen4(open);
  };
  const toggleDrawer5 = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen5(open);
  };
  const toggleDrawer6 = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen6(open);
  };
  const toggleDrawer7 = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen7(open);
  };

  const getColorForPointer = (score) => {
    if (score >= 300 && score < 599) {
      return '#df240c'; // Red for Poor
    } else if (score >= 600 && score < 660) {
      return '#fe992b'; // Orange for Fair
    } else if (score >= 661 && score < 715) {
      return '#eff307'; // Yellow for Good
    } else if (score >= 716 && score < 747) {
      return '#c9ff38'; // Green for Very Good
    } else if (score >= 748 && score <= 850) {
      return '#01c201'; // Dark Green for Excellent
    } else {
      return '#000000'; // Default to black if score is invalid
    }
  };
  const getCreditScoreLabel = (score) => {
    if (score >= 300 && score < 599) {
      return 'Poor';
    } else if (score >= 600 && score < 660) {
      return 'Fair';
    } else if (score >= 661 && score < 715) {
      return 'Good';
    } else if (score >= 716 && score < 747) {
      return 'Very Good';
    } else if (score >= 748 && score <= 850) {
      return 'Excellent';
    } else {
      return 'Invalid Score';
    }
  };
  const formatValueWithLabel = (value) => {
    return `${value}\n${getCreditScoreLabel(value)}`;
  };
  //added gauge
  // var BlackboxObj;
  const handleFreezStop = () => {
    setFreez(false);
  };
  const handleFreezStart = () => {
    setFreez(true);
  };
  
  function formatDate(dateStr) {
    const date = new Date(dateStr);
    const month = date.toLocaleString('default', { month: 'long' });
    const day = date.getDate();
    const suffix = getNumberSuffix(day);
    return `${month} ${day}${suffix} ${date.getFullYear()}`;
  }

  function getNumberSuffix(day) {
    if (day >= 11 && day <= 13) {
      return 'th';
    }
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }

  const formattedDate = (score ? Moment(score.reportDate).format('MM/DD/YYYY') : '');
  const Upgradeto3B = () => {
    setStartJourneyOpen(false);
    handleFreezStart();
    var BlackboxObj1 = null;
    if (window.IGLOO && typeof window.IGLOO.getBlackbox === 'function') {
        BlackboxObj1 = window.IGLOO.getBlackbox();
        setBlackboxObj(BlackboxObj1);
        console.log('Blackbox generated:', BlackboxObj1);
    } else {
        console.error('IGLOO or getBlackbox function is not available.');
    }

    localStorage.setItem('aid', score ? score.aid : 'seo');
    localStorage.setItem('pid', score ? score.pid : '00017');
    localStorage.setItem('Main_password', score ? score.userObj ? atob(score.userObj.password) : '' :'');
    http.post("/registerEnrollment", {
      ugradeToCD: true,
      userId: userId,
      blackboxCode: BlackboxObj1 ? BlackboxObj1.blackbox : "",
      step: 1
    })
      .then((res) => {
        handleFreezStop();
        if (res.data.success === true) {
          localStorage.setItem("trackingToken", res.data.data.trackingToken);
          localStorage.setItem('customerToken', res.data.data.customerToken);
          localStorage.setItem('Step', res.data.data.nextstep);
          localStorage.setItem('idQuestions', JSON.stringify(res.data.data.idQuestions));
          localStorage.setItem("UpgradeTo3B", true);
          localStorage.setItem('Main_firstName', score ? score.userObj.first_name : '');
          localStorage.setItem('Main_lastName', score ? score.userObj.last_name : '');
          localStorage.setItem('Main_email', score ? score.email : '');
          window.location.href = config.BASE_URL + `enroll?pid=` + localStorage.getItem('pid') + `&aid=` + localStorage.getItem('aid') + `&sid=&tid=&adid=&from=crsleads`;
        } else {
          handleFreezStop();
          const errorObj = res.data.data.errorByCD;
          swal({
            title: "Failed",
            text: errorObj[0] && errorObj[0].message ? errorObj[0].message : res.data.data.errorByCD,
            icon: "error",
            button: "Ok",
          })
        }
      })
  }

  const sendMessage = (message) => {
    chatbotRef.current.handleNewUserMessage(message);
  };

  const startCreditRepairJourney = () => {
    setStartJourneyOpen(true);
  }

  const openChatPopup = (e) => {
    console.log("on open");
    setIsChatOpen(true);
    var sessionMessagesObj;
    if(localStorage.getItem("sessionMessages") != undefined) {
      sessionMessagesObj = localStorage.getItem("sessionMessages");
    }
    e.preventDefault();
    const element = document.getElementById("chatbotDiv");
    element.style.display = "block";
    setIsMinimized(!isMinimized);
    
    setStartJourneyOpen(false);
    chatSessionId = localStorage.getItem('chatSessionId');
    if(chatSessionId != null){
      const sessionMessage1 = localStorage.getItem("sessionMessages");
      if (sessionMessage1) {
        const parsedMessages = JSON.parse(sessionMessage1);
         setMessages(parsedMessages);
      }
     
      setAssistYouFlag(true);
    }else{
      if(sessionMessagesObj > 1) {
          setMessages(sessionMessagesObj);
      }
      if(!assistYouFlag){
        setAssistYouFlag(true);
      }
    }
    
  }

  const changeOpenChatPopupValue = (value) => {
    setIsMinimized(value);
  }

  const openEmailChatPopup = () => {
    setStartJourneyOpen(false);
    setEmailChatopen(true);
    setIsMinimized(true);
  }

  const closeStartJourneyOpen = () => {
    setStartJourneyOpen(false);
  }

  const closeEmailChatPopup = () => {
    setStartJourneyOpen(false);
    setEmailChatopen(false);
  }

  const getScoreData = async () => {
    handleFreezStart();
    const apiUrl = '/creditSnapshotInternalLogin/' + userId;
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if (res) {
      if (res.success === true) {
        setScore(res.data);
        setMemberEmail(res.data.userObj.email);
        setFname(res.data.userObj.first_name);
        setlogourl(res.data ? config.BACKEND_URL + "images/companyLogo/" + res.data.affCobrandingLogo : '')
        setCompanyName(res.data ? res.data.companyName : '')
        setCreditScore(res.data.creditScore)
        localStorage.setItem("creditSnapshotLink", res.data.creditSnapshotLink);        

        var email1 = localStorage.getItem("chatbot_email");
        console.log("email1:" +email1);
        if(email1 != undefined && (email1 != res.data.userObj.email)) {
            localStorage.removeItem("chatbot_email");
            localStorage.removeItem("sessionMessages");
            localStorage.removeItem("chatSessionId");
        }

        handleFreezStop();
        setTimeout(function () {
          handleFreezStop();
        }, 8000);
      } else {
        navigate(-1);
      }
    }
  }

  const getAffiliateCSSInfo = async () => {
    const apiUrl = '/getCSSInfo/' + userId;
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if (res) {
      if (res.success === true) {
        setCreditSnapshotObj(res.data);
        setCrsSignupEnabled(res.data.crs_signup_enabled);
        setCompanyId(res.data.company_master_id);
      } else {
        navigate(-1);
      }
    }
  }
  
  useEffect(() => {
    getScoreData();
    getAffiliateCSSInfo();
    if (score) {
      const dateStr = score ? score.reportDate : '';
      const date = new Date(dateStr);
      date.setDate(date.getDate() + score.offerDetails[0].days);
      newDateStr = date.toISOString().slice(0, 10);
    }
  }, [])

 
  //dialog box start here
  const handleClickOpen = () => {
    setOpen(true);
  };
  
  const handleClickLearnMoreAboutUs = () => {
    if(creditSnapshotObj.summary_page_link) {
      const dynamicLink = creditSnapshotObj.summary_page_link;
      window.open(dynamicLink, "_blank"); //
    }
  };
  const handleLogout = () => {


    // Get the base URL
    const baseUrl = window.location.origin;

    // Append "/score" to the base URL
    const updatedUrl = config.BASE_URL + `snapshotlogin`;

    // Navigate to the updated URL
    window.location.href = updatedUrl;

  }
  const handleClose = () => {
    setOpen(false);
  };

  const sendEmailChatSchema = Yup.object().shape({
    subject: Yup.string()
      .min(2, 'Too Short!')
      .required('Please enter subject'),
    description : Yup.string()
      .min(2, 'Too Short!')
      .required('Please enter Description'),
  });


  //dialog box end here

 
  return (
    <>
      <Grid container item lg={12} md={12} xs={12} sm={12} sx={{display:"flex",justifyContent:"end"}} p={2}>
          <Grid item lg={6} md={6} xs={6} sm={6}>
              {/* <Button variant="contained">Back</Button> */}
          </Grid>
          <Grid item lg={6} md={6} xs={6} sm={6} sx={{display:"flex",justifyContent:"end"}}>
              <AnimatedButton
                variant="outlined"
                color="primary"
                onClick={handleLogout}
                sx={{
                  minWidth: 50, // Initial width for just the icon
                  maxWidth: 150, // Full width when the text is visible
                  height: 40, // Fixed height for consistent alignment
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "8px",
                  textTransform: "none",
                  overflow: "hidden", // Ensures only visible content is displayed
                  transition: "all 0.3s", // Smooth transition for width
                  whiteSpace: "nowrap", // Prevents text wrapping
                  "&:hover": {
                    backgroundColor: "blue", // Solid blue background on hover
                    color: "white", // Optional: Change text/icon color for better contrast
                    minWidth: 150, // Expand width to show text
                  },
                  "& svg": {
                    transition: "transform 0.3s",
                  },
                  "& span": {
                    opacity: 0,
                    transition: "opacity 0.3s",
                    display: "inline-block",
                    fontSize:"17px"  
                  },
                  "&:hover span": {
                    opacity: 1, // Show text on hover
                  },
                  "&:hover svg": {
                    transform: "translateX(-10px)",
                    position: "relative",  // Shift icon slightly left to align with text
                  },
                }}
              >
                <span>Log Out </span>&nbsp;
                <LogoutIcon sx={{position: "fixed"}}
                />
              </AnimatedButton>
          </Grid>
      </Grid>
      <Grid container item lg={12} md={12} sm={12} xs={12}>
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ p: isMobile ? 0 : 2,flexGrow:1 }}>
          <Card sx={{
            boxShadow:
              "0 0px 6px 0 rgba(0, 0, 0, 0.2), 0 0px 10px 0 rgba(0, 0, 0, 0.19)",
              minHeight:isMobile ? "100%" : 400,
              maxHeight:isMobile ? "100%" : 450
          }}>
            {/* <CardContent> */}
              <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                <img alt="#" src={require("../../../assets/images/equifax.png")} style={{ marginLeft: "auto", marginRight: "auto", height: "22px" }} />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                <Typography level="h3" sx={{fontWeight:600,textAlign:"center"}}>Your Credit Report Summary</Typography>
              </Grid>
              <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
                <Grid item lg={5} md={5} sm={12} xs={12}>
                  <Card sx={{ border: "1px solid #2E67BC", borderRadius: "20px" }}>
                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                      <Card sx={{ borderRadius: "10px", width: "100%",padding:"3%" ,borderBottom:"1px solid #2E67BC"}}>
                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "space-between", }}>
                          <Grid item lg={6} md={6} sm={6} xs={6}>Collections</Grid>
                          <Grid item lg={6} md={6} sm={6} xs={6} sx={{ textAlign: "right" }}><b>{score ? score.collections : ''}</b></Grid>
                        </Grid>
                      </Card>
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                      <Card sx={{ borderRadius: "10px", width: "100%",padding:"3%",borderBottom:"1px solid #2E67BC" }}>
                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                          <Grid item lg={6} md={6} sm={6} xs={6}>Public Record</Grid>
                          <Grid item lg={6} md={6} sm={6} xs={6} sx={{ textAlign: "right" }}><b>{score ? score.publicRecord : ''}</b></Grid>
                        </Grid>
                      </Card>
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                      <Card sx={{ borderRadius: "10px", width: "100%",padding:"3%",borderBottom:"1px solid #2E67BC" }}>
                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                          <Grid item lg={6} md={6} sm={6} xs={6}>Charge offs</Grid>
                          <Grid item lg={6} md={6} sm={6} xs={6} sx={{ textAlign: "right" }}><b>{score ? score.chargeOffs : ''}</b></Grid>
                        </Grid>
                      </Card>
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                      <Card sx={{ borderRadius: "10px", width: "100%",padding:"3%",borderBottom:"1px solid #2E67BC" }}>
                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                          <Grid item lg={6} md={6} sm={6} xs={6}>Credit available</Grid>
                          <Grid item lg={6} md={6} sm={6} xs={6} sx={{ textAlign: "right" }}><b>{score ? score.creditAvailable : 0}%</b></Grid>
                        </Grid>
                      </Card>
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                      <Card sx={{ borderRadius: "10px", width: "100%",padding:"3%",borderBottom:"1px solid #2E67BC" }}>
                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                          <Grid item lg={6} md={6} sm={6} xs={6}>Inquiries</Grid>
                          <Grid item lg={6} md={6} sm={6} xs={6} sx={{ textAlign: "right" }}><b>{score ? score.inquiries : ''}</b></Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  </Card>
                  <Grid container item lg={12} md={12} sm={12} xs={12} p={1}>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <Typography sx={{ fontSize: "20px"}}><b>Total Negative Items</b></Typography>
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={2}>
                      <Typography sx={{ fontSize: "20px", color: "red", textAlign: "center" }}><b>{score ? score.totalNeagtiveItems : ''}</b></Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={7} md={7} sm={12} xs={12}>
                  <Card sx={{ border: "1px solid #2E67BC", borderRadius: "20px" }}>
                    {/* <CardContent> */}
                      <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                        <GaugeComponent
                          value={creditScore}
                          type="radial"
                          minValue={300}
                          maxValue={850}
                          labels={{
                            valueLabel: {
                              fontSize: 80,
                              formatTextValue: (value) => `${value} :  ${getCreditScoreLabel(value)}`,
                              style: {
                                fill: "#000000",
                                // backgroundColor: getColorForPointer(creditScore),
                                fontSize: "50px",
                                textShadow: "0px 0px",
                                fontWeight: "bold"
                              }
                            },
                            tickLabels: {
                              type: "outer",
                              ticks: [
                                { value: 300 },
                                { value: 362 },
                                { value: 424 },
                                { value: 484 },
                                { value: 548 },
                                { value: 610 },
                                { value: 672 },
                                { value: 734 },
                                { value: 796 },
                                { value: 850 }
                              ],

                            }
                          }}
                          arc={{
                            colorArray: ["#df240c", "#df240c", "#df240c", "#df240c", "#df240c", "#fe992b", "#eff307", "#c9ff38", "#01c201"],
                            subArcs: [
                              { limit: 300, color: '#df240c', showTick: true },
                              { limit: 362, color: '#df240c', showTick: true },
                              { limit: 424, color: '#df240c', showTick: true },
                              { limit: 484, color: '#df240c', showTick: true },
                              { limit: 548, color: '#df240c', showTick: true },
                              { limit: 610, color: '#fe992b', showTick: true },
                              { limit: 672, color: '#eff307', showTick: true },
                              { limit: 734, color: '#c9ff38', showTick: true },
                              { limit: 796, color: '#01c201', showTick: true },

                            ],
                            padding: 0,
                            cornerRadius: 1,
                            width: 0.3,
                            valueConfig: {
                              formatTextValue: (value) => `${value} :  ${getCreditScoreLabel(value)}`,
                              style: {
                                fill: getColorForPointer(creditScore),
                                fontSize: "50px",
                                textShadow: "0px 0px",
                                fontWeight: "bold"
                              } // Corrected formatTextValue usage
                            }
                          }}
                          pointer={{
                            elastic: true,
                            animationDelay: 0,

                          }}

                        />
                      </Grid>
                      <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={1}>
                        <Grid>
                          <Typography level="body2"><b>Vantage Score 3.0</b>&nbsp;
                            <Tooltip title="Vantage 3.0 uses the same 300-850 range as base FICO.">
                              <span><img alt="#" src={require("../../../assets/images/info-icon.png")} /></span>
                            </Tooltip>
                          </Typography>
                        </Grid>
                        &nbsp;&nbsp;
                        <Grid>
                          <Typography level="body1">
                            As of {formattedDate}
                          </Typography>
                        </Grid>
                      </Grid>
                    {/* </CardContent> */}
                  </Card>
                </Grid>
              </Grid>
            {/* </CardContent> */}
          </Card>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ p: isMobile ? 0 : 2 ,display:"flex",flexGrow:1}}>
          <Card sx={{
            boxShadow:
              "0 0px 6px 0 rgba(0, 0, 0, 0.2), 0 0px 10px 0 rgba(0, 0, 0, 0.19)",
              minHeight:isMobile ? "100%":400,
              maxHeight:isMobile ? "100%":450
          }}>
            {/* <CardContent> */}
              <Grid container item lg={12} md={12} sm={12} xs={12} mb={1}>
                  <img alt="#" src={logourl} style={{ display: "block", marginLeft: "auto", marginRight: "auto", }} />
              </Grid>
              {
                crsSignupEnabled ?
                <>
                  <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                        <img src={require("../../../assets/images/creditScore.png")} style={{ width: isMobile?"100vw":"23vw",height:"auto" }} />
                      </Grid>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} mt={6} >
                      <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                        <Typography level="h4" sx={{color:"#435AC2",textAlign:"center",fontWeight:450}}><b>NEED HELP IMPROVING YOUR CREDIT?</b></Typography>
                      </Grid>
                      <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                        <Typography level="body2" className="fontUs" mt={1} sx={{textAlign:"center"}}><b>YOU CAN IMPROVE YOUR SCORES WE CAN HELP!</b></Typography>
                      </Grid>
                      <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={3}>
                        <Button variant="contained" color="success" sx={{minWidth:320, maxWidth:350, textAlign:"center",backgroundColor:"#3FAC19",fontSize:"18px",
                        fontWeight:"bold",
                          animation: "blink 1.5s infinite",
                          "@keyframes blink": {
                            "0%": { backgroundColor: "#3FAC19" }, 
                            "50%": { backgroundColor: "#55cc28" }, 
                            "100%": { backgroundColor: "#3FAC19" },
                          },
                        }}   
                          onClick={() =>{ startCreditRepairJourney() }}
                            >START NOW
                       </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  
                </>
                :
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                      <img src={require("../../../assets/images/creditScore.png")} style={{ width: "20vw",height:"auto" }} />
                    </Grid>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} >
                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                      <Typography level="h4" sx={{color:"#435AC2",textAlign:"center",fontWeight:300}}><b>NEED HELP IMPROVING YOUR CREDIT?</b></Typography>
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                      <Typography level="body2" className="fontUs" mt={1} sx={{textAlign:"center"}}><b>YOU CAN IMPROVE YOUR SCORES, WE CAN HELP!</b></Typography>
                    </Grid>
                  </Grid>
                </Grid>
              }
                
            {/* </CardContent> */}
          </Card>
        </Grid>
      </Grid>
       
        
        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ display:"flex",justifyContent: isMobile?"center":"end",}}>
              <Grid item lg={6} md={6} sm={12} xs={12}> </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Grid container item lg={12} md={12} sm={12} xs={12} sx={{mt:isMobile?4:0}}>
                    {
                       creditSnapshotObj && creditSnapshotObj.enable_chat_support ? (
                          <Grid item lg={4} md={4} sm={12} xs={12} mt={1} sx={{ display:"flex",justifyContent:"center",marginTop:isMobile?2:0 }}>
                            
                          <Button varient="outlined"
                            onClick={openChatPopup}
                            // disabled={isChatOpen}
                          startIcon={<img src={require("../../../assets/images/chatforbutton.png")} alt="icon" style={{ width: 30, height: 30 }} />}
                          sx={{
                            textTransform: "none",
                            border:"1px solid #2E67BC",
                            minWidth:'80%' ,
                            maxWidth:'100%',
                            color:"black",
                            alignItems:"center",
                            fontWeight:"bold"// Prevents uppercase transformation
                          }}>Chat With Us Now</Button>
                        
                        </Grid>
                       ) : ''
                    }
                    { creditSnapshotObj && creditSnapshotObj.enable_email_support ? 
                    <Grid item lg={4} md={4} sm={12} xs={12}  mt={1} sx={{ display:"flex",justifyContent:"center",marginTop:isMobile?2:0 }}>
                            <Button  startIcon={<img src={require("../../../assets/images/Emailforbutton.png")} alt="icon" style={{ width: 30, height: 30 }} />}
                            onClick={openEmailChatPopup}
                            sx={{
                              textTransform: "none",
                              border:"1px solid #2E67BC",
                              minWidth:'80%' ,
                              maxWidth:'100%',
                              color:"black",
                              alignItems:"center",
                              fontWeight:"bold" // Prevents uppercase transformation
                            }}>Email Us Now</Button>
                      </Grid>
                      : ''}
                      {
                        creditSnapshotObj && creditSnapshotObj.summary_page_link ? 
                        <Grid item lg={4} md={4} sm={12} xs={12} mt={1} sx={{ display:"flex",justifyContent:"center",marginTop:isMobile?2:0 }}>
                          <Button
                           onClick={handleClickLearnMoreAboutUs}
                            startIcon={<img src={require("../../../assets/images/playforbutton.png")} alt="icon" style={{ width: 30, height: 30 }} />}
                            sx={{
                              textTransform: "none",
                              border:"1px solid #2E67BC", // Prevents uppercase transformation
                              minWidth:'80%' ,
                              maxWidth:'100%',
                              alignItems:"center",
                              color:"black",
                              fontWeight:"bold"
                            }}>Learn More About Us
                          </Button>
                      </Grid>
                      : ''
                      }
                      
                  </Grid>
                </Grid>
                   
        </Grid>
       
      {
        crsSignupEnabled ?
        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ marginBottom : '6%' }} mt={1} >
        </Grid>
        :
        <>
          <Card sx={{ backgroundColor: "#435AC2", borderRadius: 0, marginTop: 1 }}>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
              <Grid item lg={5} md={5} sm={12} xs={12} sx={{textAlign:"center"}}>
                {/* <Typography level="h2" sx={{ color: "black" }} className="textCenter">Explore More Insights!<br /><span style={{fontWeight:700, color:"primary"}}>Credit Scores Can Vary Significantly </span><br /><span style={{fontWeight:700}}>Between Bureaus</span></Typography> */}
                <Typography level="h2" sx={{ color: "#FFFFFF" }} className="textCenter">
                  Explore More Insights!
                </Typography>
                <Typography  level="h5" sx={{ marginTop:'2%', color :'#9DFF00'}} className="textCenter">
                  <span style={{ fontWeight: 700 }}>
                    Credit Scores Can Vary Significantly
                  </span>
                  <br />
                  <span style={{ fontWeight: 700 }}>
                    Between Bureaus
                  </span>
                </Typography>
              </Grid>
              <Grid item lg={7} md={7} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                <Card sx={{ borderRadius: 0 }} >
                  <Grid container item lg={12} md={12} xs={12} sm={12}>
                      <Grid item lg={8} md={8} sm={12} xs={12}>
                          <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                          <Typography level="h5" sx={{ color: "#292E40", textAlign: "center" }}>Get Access to your </Typography>
                          </Grid>
                          <Grid>
                          <Typography level="h5" sx={{ color: "#292E40", textAlign: "center" }}>
                              Three Bureau Reports and Scores
                          </Typography>
                          </Grid>
                          <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                          <img alt="#" src={require('../../../assets/images/bureau-logos.png')} className="bureau-logos" />
                          </Grid>
                      </Grid>
                      <Grid item lg={4} md={4} sm={12} xs={12}>
                      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center" }}  className="marginScoress" mt={3}>
                              {score.upgradeTo3bFlag == 0 ?
                              <Button variant="contained"
                              size="large"
                              // color="warning"
                              sx={{ backgroundColor: "#2E67BC", borderColor: "#FF9400", minWidth: 150, minHeight: 40,fontSize:"20px",fontWeight:"bold",
                              boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}
                              onClick={() => { Upgradeto3B() }}
                              >
                              Take me to my score
                              </Button>
                              :
                              <>
                                  <form method="POST" action={config.API_URL+'/api/member/view3B'} target="_blank">
                                      <input type="hidden" name="username" value={(score?btoa(score.userObj.email):'')} />
                                      <input type="hidden" name="password" value={score?score.userObj?score.userObj.password:'':''} />  
                                      <Button variant="contained"
                                        size="large"
                                        color="warning"
                                        sx={{ backgroundColor: "primary", borderColor: "#FF9400", minWidth: 150, minHeight: 40 ,fontSize:"20px",fontWeight:"bold",
                                        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}
                                        // href={config.API_URL + '/member/view3B/' + btoa(score ? score.userObj.email : '') + '/' + (score ? score.userObj ? score.userObj.password : '' : '')}
                                        // target="_blank"
                                        type="submit"
                                        >
                                        Take me to my <br/> scores now
                                        </Button>
                                      {/* <Tooltip title="View 3B" arrow><button type='submit' style={{border:"none",background:"none",borderRadius:"0%",padding:'0px'}}><img src={require('../../assets/icons/view3B.png')} /></button></Tooltip> */}
                                  </form>
                              </>
                              
                          }
              
                          </Grid>
                      </Grid>
                  </Grid>
                  
                </Card>
              </Grid>
              
              
            </Grid>
          </Card>
          <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center", marginBottom : '5%' }} mt={2} >
              <Typography level="h5" sx={{ fontWeight: "bold" }} className="textCenter">With Enrollment in 7/24 Credit Monitoring</Typography>
          </Grid>
        </>
      }
        <Box>
            <Chat mEmail={memberEmail} firstName={Fname} isMinimized={isMinimized} changeOpenChatPopupValue={changeOpenChatPopupValue} openEmailChatPopup={openEmailChatPopup} companyId={companyId} source="snapshot"/>
            <EnrollFooter />
        </Box> 
      
      {isChatOpen && (
      <Box
          sx={{
            position: "fixed",
            bottom: "2%",
            right: "2%",
            cursor: "pointer",
          }}
          onClick={() => changeOpenChatPopupValue(!isMinimized)}
        >
          <img
            src={require("../../../assets/images/chatforbutton.png")}
            alt="Chat Icon"
            style={{
              width: "70px",
              height: "70px",
              borderRadius: "50%",
              boxShadow:
                "0px 4px 8px rgba(0, 0, 0, 0.2), 0px 8px 16px rgba(0, 0, 0, 0.19)",
            }}
          />
        </Box>
        )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      >
        <div>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={freez}
          >
            <Freez />
          </Backdrop>
        </div>
        <Grid>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={3}>
              <Typography level="h2"><span style={{ color: "red" }}><i>WAIT!</i></span> READY TO SEE IT ALL? </Typography>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={3}>
              <Typography level="h5" sx={{ color: "#76C044" }}>You've taken the first step to staying on top of your credit - DON'T STOP HERE!</Typography>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={3}>
              <Typography level="h4" sx={{ color: "#2D6DB4", fontWeight: 900 }}>SCORES CAN VERY BY BUREAU. CHECK YOUR SCORES FROM <u>ALL 3 BUREAUS NOW!</u></Typography>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container item mt={3} spacing={3}>
            <Grid item xs sx={{ textAlign: "center" }}>
              {score.upgradeTo3bFlag == 0 ?
                <Button variant="contained"
                  size="large"
                  color="warning"
                  sx={{ backgroundColor: "#FF9400", borderColor: "#FF9400", minWidth: 300, minHeight: 40 }}
                  onClick={() => { Upgradeto3B() }}
                >
                  Take me to my scores now
                </Button>
                :
                <>
                  <form method="POST" action={config.API_URL + '/api/member/view3B'} target="_blank">
                    <input type="hidden" name="username" value={btoa(score ? score.userObj.email : '')} />
                    <input type="hidden" name="password" value={score ? score.userObj ? score.userObj.password : '' : ''} />
                    <Button variant="contained"
                      size="large"
                      color="warning"
                      sx={{ backgroundColor: "#FF9400", borderColor: "#FF9400", minWidth: 300, minHeight: 40 }}
                      // href={config.API_URL + '/member/view3B/' + btoa(score ? score.userObj.email : '') + '/' + (score ? score.userObj ? score.userObj.password : '' : '')}
                      // target="_blank"
                      type="submit"
                    >
                      Take me to my scores now
                    </Button>
                    {/* <Tooltip title="View 3B" arrow><button type='submit' style={{border:"none",background:"none",borderRadius:"0%",padding:'0px'}}><img src={require('../../assets/icons/view3B.png')} /></button></Tooltip> */}
                  </form>
                </>

              }
            </Grid>
            <Grid item xs sx={{ textAlign: "center" }}>
              <Button onClick={handleLogout} variant="contained" size="large" color="success" sx={{ minWidth: 250, minHeight: 40, backgroundColor: "#76C044", borderColor: "#76C044" }}>
                Logout
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      {/* Start Credit Repair Journey */}
      <Dialog
        open={msgChatopen}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            // setDeleteOpen(false)
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {/* Delete Pid */}
          <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
            <Grid item lg={10} md={10} sm={10} xs={10}>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={2} sx={{ textAlign: "right" }}>
              <CloseIcon onClick={closeStartJourneyOpen} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
        </DialogContent>
        <DialogActions >
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              label="member Msg"
              type="text"
              size="small"
              fullWidth
              name='memberMsg'
              autoComplete='memberMsg'
              id='memberMsg'
              onChange={(e) => { setMemberMsg(e.target.value); }}
              value={memberMsg}
            />
          </Grid>
          <Grid item>
            <Button variant="contained" sx={{ minWidth: 200 }} color="success"
              onclick={sendMessage}
            >
              Send1
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>

      <Dialog
        open={startJourneyOpen}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            // setDeleteOpen(false)
          }
        }}
         maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {/* Delete Pid */}
          <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
            <Grid item lg={10} md={10} sm={10} xs={10}>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={2} sx={{ textAlign: "right" }}>
              <CloseIcon onClick={closeStartJourneyOpen} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container item lg={12} md={12} sm={12} xs={12} className="centering"><img alt="#" src={require("../../../assets/images/bureau-logos.png")}/> </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
              <Typography sx={{ fontWeight: '600', color: '#000000',textAlign:"center",fontSize:"30px" }}>Great, We Need Some Information To Get Started, But first, Let's Get Your 3B Report.</Typography>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions >
          <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center" }}>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center" }}>
              <Button variant="contained"
                size="large"
                // color="warning"
                sx={{
                  backgroundColor: "#2E67BC", borderColor: "#FF9400", minWidth: 150, minHeight: 40, fontSize: "20px", fontWeight: "bold",
                  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                }}
                onClick={() => { Upgradeto3B() }}
              >
                GET ALL 3 REPORTS AND SCORES
              </Button>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} mt={2} sx={{ textAlign: "center" }}>
              <a target="_blank" href={creditSnapshotObj && creditSnapshotObj.credit_snapshot_link ? creditSnapshotObj.credit_snapshot_link : "#"}> I’ll Do That Later, Continue To Auto Signup Now</a>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display:"flex", justifyContent:"center",alignItems: "center",marginTop:"2%" }} >
              <Stack direction="row">
                {
                    creditSnapshotObj && creditSnapshotObj.enable_chat_support ?
                    <>
                     <Card varient="outlined" className="centering" sx={{boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",flex: "1", minWidth:150, maxWidth: "300px",}}>
                      <Grid container item lg={12} md={12} sm={12} xs={12} className="centering">
                        <a href="#" onClick={openChatPopup}><img alt="#" src={require("../../../assets/images/Chat-bot-Icon.png")} style={{ marginLeft: "auto", marginRight: "auto", height: "auto" ,width:"56%"}} /></a>
                      </Grid>
                    
                      <Grid container item lg={12} md={12} sm={12} xs={12} className="centering">
                        <Typography level="h5" sx={{textAlign:"center"}}>Chat With Us</Typography>
                      </Grid>
                    </Card>
                    </> : ''
                  }
               
                &nbsp; &nbsp;
                <Card varient="outlined" className="centering" sx={{boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",flex: "1",minWidth:150,maxWidth: "300px",}}>
                <Grid container item lg={12} md={12} sm={12} xs={12} className="centering">
                  <a href="#" onClick={openEmailChatPopup}><img alt="#" src={require("../../../assets/images/email-multiple-outline.png")} style={{ marginLeft: "auto", marginRight: "auto", height: "auto",width:"100%" }} /></a>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} className="centering">
                  <Typography level="h5" sx={{textAlign:"center"}}>Email Us</Typography>
                </Grid>
                </Card>
                </Stack>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>


      {/* Email Chat DialogBox */}
      <Formik
        initialValues={{
          subject: '',
          description : ''
        }}
        validationSchema={sendEmailChatSchema}
        onSubmit={async (values, { resetForm }) => {
          handleFreezStart();
          http.post("/sendChatEmail", {
            // userId : score.userObj.user_id,
            email : score.userObj.email,
            subject : values.subject,
            description : values.description,
            companyId : companyId,
            source : "snapshot"
          })
          .then((res) => {
            if (res.data.success === true) {
              swal({
                title: "Success",
                text: res.data.message,
                icon: "success",
                button: "Ok",
              });
              handleFreezStop();
            }else{
              // alert("something went wrong");
              handleFreezStop();
            }
          })
          closeEmailChatPopup();
          resetForm();
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
        <Dialog
            open={emailChatopen}
            onClose={(_, reason) => {
            if (reason !== "backdropClick") {
                // setDeleteOpen(false)
            }
            }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
          <DialogTitle id="alert-dialog-title">
            {/* Delete Pid */}
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{justifyContent:"space-between"}}>
              <Grid item lg={10} md={10} sm={10} xs={10}>
                  <Typography level="h5" sx={{textAlign:"center"}}>Send Us The Email</Typography>
              </Grid>
              <Grid item lg={2} md={2} sm={2} xs={2} sx={{textAlign:"right"}}>
                <CloseIcon  onClick={closeEmailChatPopup}/>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogActions >
          <Grid container item lg={12} md={12} sm={12} xs={12} >
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="outlined-start-adornment"
                  type="text"
                  label="Subject"
                  fullWidth
                  variant="outlined"
                  size="small"
                  margin='dense'
                  autoComplete='subject'
                  value={values.subject}
                  onChange={handleChange('subject')}
                  onBlur={handleBlur('subject')}
                  InputLabelProps={{
                    shrink: Boolean(values.subject)
                  }}
                /> 
                {touched.subject && errors.subject ? <div className='error'>{errors.subject}</div> : null}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                <TextareaAutosize
                    placeholder="Message"
                    type="text"
                    size="small"
                    fullWidth
                    name='description'
                    autoComplete='description'
                    id='description'
                    onChange={handleChange('description')}
                    onBlur={handleBlur('description')}
                    value={values.description}
                    maxRows={7}
                    minRows={4}
                   
                    style={{
                      width: '100%', // Ensures full width
                      // padding: '8px', // Optional for better spacing
                      boxSizing: 'border-box', // Ensures padding doesn't affect width
                  }}
                />
                 {touched.description && errors.description ? <div className='error'>{errors.description}</div> : null}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} mt={2} sx={{ justifyContent: "center", display: "flex"}}>
              <Button variant="contained" sx={{ minWidth: 200 }} color="success" 
                onClick={handleSubmit}
                >
                  Send
              </Button>
            </Grid>
          </Grid>
          </DialogActions>
        </Dialog>
      )}
      </Formik>
      {/* loader code start */}
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={freez}
          onClick={handleFreezStop}
        >
          <Freez />
        </Backdrop>
      </div>
      {/* loader code end */}
    </>
  )
}
export default SnapshotScore;