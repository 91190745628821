import React from 'react';
import { Grid, IconButton } from '@mui/material';
import { Card, CardContent, CardOverflow, Button, List, ListItem, ListItemDecorator, ListItemContent, } from '@mui/joy';
import config from '../../AppConfig';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CloseIcon from '@mui/icons-material/Close';

function PopupPreview({ customColorArray, creditinfo, firstlogoUrl, handleClose, previewPopup, link }) {

    const redirect = () => {
        window.open(link, '_blank');
    }
    

    return (
        <div>
            <Card sx={{ p:0, marginLeft: "auto", marginRight: "auto", display: "block", border : "1px solid grey"}}>
                <CardOverflow
                    sx={{
                        bgcolor: `${customColorArray.popupHeaderBackground}`,
                        borderColor: `${customColorArray.popupHeaderBackground}`,
                        p: 3,
                        margin: 0 
                    }}>
                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center", textAlign: 'center' }}>                            
                            <p style={{ margin:0, color: `${customColorArray.popupHeaderText}`, fontSize: "1.2vw",fontWeight:'bold'}} className='texting'>
                                You Can Improve Your Credit Score
                            <br />
                            {
                                creditinfo
                                    ?
                                    firstlogoUrl
                                    ?
                                    <img className="dashlogo" style={{padding:'1%',height:'70px'}} src={config.BACKEND_URL + "images/companyLogo/" + firstlogoUrl} />
                                    :
                                    <img className="dashlogo" style={{padding:'1%',height:'70px'}} src={require("../../assets/images/uploadLogo.png")} />
                                :
                                <img className="dashlogo" style={{padding:'1%',height:'70px'}} src={require("../../assets/images/uploadLogo.png")} />
                            }   
                            <br />
                            <span style={{ fontWeight: "bold",fontSize: "1.2vw", }} className='texting'>Check Your Credit For Free</span>
                            </p>   
                            {
                                previewPopup 
                                ?
                                    <IconButton
                                        aria-label="close"                             
                                        onClick={handleClose}
                                        style={{position: 'absolute',top: 0,right: 0}}
                                    >
                                        <CloseIcon />
                                    </IconButton>   
                                :
                                null
                            }                             
                        </Grid>                                                                   
                    </Grid>
                </CardOverflow>
                <CardContent sx={{ p: 2, borderRadius: '0px 0px 10px 10px', background:`${customColorArray.popupBodyBackground}` }}>
                    <List
                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                        aria-label="contacts">
                        <ListItem disablePadding>
                            <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 0.5 }}>
                                <TaskAltIcon sx={{color: `${customColorArray.popupIconColor}` }} />                                                    
                            </ListItemDecorator>
                            <ListItemContent sx={{ mr: 0.5,textAlign:"left", color: `${customColorArray.popupBodyText}` }}  >Get Your Free Credit Score
                            </ListItemContent>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 0.5 }}>
                                <TaskAltIcon sx={{color: `${customColorArray.popupIconColor}` }} />  
                            </ListItemDecorator>
                            <ListItemContent sx={{ mr: 0.5,textAlign:"left", color: `${customColorArray.popupBodyText}` }}  >Get Your Free Credit Report Summary
                            </ListItemContent>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 0.5 }}>
                                <TaskAltIcon sx={{color: `${customColorArray.popupIconColor}` }} />  
                            </ListItemDecorator>
                            <ListItemContent sx={{ mr: 0.5,textAlign:"left", color: `${customColorArray.popupBodyText}` }} >Get A Credit Consultation
                            </ListItemContent>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 0.5 }}>
                                <TaskAltIcon sx={{color: `${customColorArray.popupIconColor}` }} />  
                            </ListItemDecorator>
                            <ListItemContent sx={{ mr: 0.5,textAlign:"left", color: `${customColorArray.popupBodyText}` }} >No Credit Card Required
                            </ListItemContent>
                        </ListItem>
                    </List>
                    <Grid container item xs mt={1} sx={{ display: "flex", justifyContent: "center" }}>
                        <Button 
                        sx={{ 
                            backgroundColor: `${customColorArray.popupButtonBackground}`, 
                            borderRadius: "3px", 
                            color: `${customColorArray.popupButtonText}`,
                            minWidth: 200 ,
                            '&:hover': {
                                backgroundColor: `${customColorArray.popupButtonBackground}`
                            }
                        }}
                        onClick={previewPopup? redirect : null}
                        >GET STARTED</Button>
                    </Grid>
                </CardContent>
            </Card>            
        </div>
    );
}

export default PopupPreview;