import React, { useState, useEffect,useMemo,useRef } from 'react';
import LoginHeader from '../Components/LoginHeader';
import RegisterFooter from './Auth/RegisterFooter';
import { Box, Divider, Grid, TextField,Button,List, Paper,ListItem,ListItemText,Dialog,DialogTitle,DialogContent,DialogActions} from '@mui/material';
import { Typography, Card, CardContent, CardOverflow } from '@mui/joy';
import config from '../AppConfig';
import '../Css/Custom.css';
import moment, { isMoment } from 'moment';
import AuthUser from "../Components/Auth/AuthUser";
import Moment from 'moment';
import MaterialReactTable from 'material-react-table';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../Components/Loader/Loader';
import { useParams } from 'react-router-dom';
import axios from "axios";
import { Formik } from 'formik';
import * as Yup from 'yup';
import swal from "sweetalert";
import useMediaQuery from '@mui/material/useMediaQuery';
import SendIcon from "@mui/icons-material/Send"; 
import LogoutIcon from '@mui/icons-material/Logout';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import Tooltip from '@mui/material/Tooltip';

const AffChatSession= ({ props }) => {
    const today = new Date();
    const { sessionId, type, encodedEmail} = useParams();
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    const formattedDate = today.toLocaleDateString('en-US', options);
    const [rowSelection, setRowSelection] = useState({});
    const [columnVisibility, setColumnVisibility] = useState({});
    const [affEmail, setAffEmail] = useState("");
     const isMobile = useMediaQuery('(max-width:600px)');
    // const [companyId, setCompanyId] = useState("");
    const[data, setData] = useState([]);
    const[messages, setMessages] = useState([]);
    const [freez, setFreez] = React.useState(true);
    const [affName, setAffName] = useState('');
    const { http } = AuthUser();
    const chatbotRef = useRef(null);
    const [initialMsg, setInitialMsg] = useState([]);
    const [isSessionCleared, setIsSessionCleared] = useState(false);
    const [open, setOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [chatOptionEnable, setChatOptionEnable] = useState(false);
  const [isSetMsg, setIsSetMsg] = useState(false);
  const [memberName, setMemberName] = useState(null);
  const [flag, setFlag] = useState(true);
  const isReasonEmpty = reason.trim() === '';
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const messagesEndRef = useRef(null);

  const [input, setInput] = useState("");
  const [isMinimized, setIsMinimized] = useState(false); // Minimize state


  const handleSendMessage = async() => {
    if (input.trim()) {
      setIsSetMsg(true);
      setMessages((prevMessages) => [...prevMessages,  { text: input, type: 1 }]);
      const response2 = await axios.post( 
        config.API_BASE_URL + "/saveMemberMessage", { message: input, session_id: sessionId, email : affEmail,isAffiliate : '1' });
    }
    setInput("");
    setIsSetMsg(false);
  };
  
  const handleClearSession = async(expired=0) => {
    handleClose();
    const finalReason = reason !== null && reason !== '' ? reason : "Clear Session due to inactivity"; 
    const response2 = await axios.post(
      `${config.API_BASE_URL}/clearSession/${sessionId}`,{expired:expired, reason:finalReason}
    );
    if (response2.data.success == true) {
      if(isSessionCleared)
      {
        setIsSessionCleared(false);
      }else {
        setIsSessionCleared(true);
      }
    }
  };

 
    useEffect(() => {
      //  fetchSuspendedMembers();
       setAffEmail(atob(encodedEmail));
      // setCompanyId(companyMasterId);
      //  setFreez(false);
       if (chatbotRef.current) {
        chatbotRef.current.toggleChatbot();
      }
    }, [sessionId]);


  useEffect(() => {
	  const fetchNewMessages = async () => {
		try {
      if(chatOptionEnable) {
        const response = await http.get(`/fetchNewMessages/${sessionId}/affiliate/null`);
        if (response.data.success) {
            const newMessages = response.data.data.map(item => ({
                text: item.message,
                type: item.is_affiliate,
            }));
  
            if (newMessages.length > 0) {
              localStorage.setItem('lastMessageTime', Date.now());
              setMessages((prevMessages) => [...prevMessages, ...newMessages]);
            }
            if (response.data.sessionObj && response.data.sessionObj.is_expired == 1)
            {
                setIsSessionCleared(true);
            }else if(response.data.sessionObj && response.data.sessionObj.is_expired == 0){
                setIsSessionCleared(false);
            }
        }
      }
		 
		} catch (error) {
		  console.error("Error fetching new messages:", error);
		}
	  };
  
	  const interval = setInterval(() => {
		fetchNewMessages();
	  }, 10000);
  
    
    const inactivityCheck = setInterval(() => {
      const lastMessageTime = localStorage.getItem("lastMessageTime");
      if (lastMessageTime) {
        const timeElapsed = Date.now() - lastMessageTime;
        if (timeElapsed > 5 * 60 * 1000) { // 5 minutes in milliseconds
          localStorage.removeItem("lastMessageTime");
          handleClearSession(1);
        }
      }
    }, 10000);

	  return () => clearInterval(interval); clearInterval(inactivityCheck); // Cleanup interval on component unmount
	}, [chatOptionEnable]);

const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

   const updateChatSession = async() => {
         try {
          await axios.post(
            config.API_BASE_URL + "/update-affiliate-chatSession",
            { email : atob(encodedEmail), sessionId: sessionId }
          );
        } catch (error) {
          console.error("Error updating chat session:", error);
        }
    };

    const fetchMessages = async() => {
      http.get('/fetchMessages/'+ sessionId).then((res) => {
        if (res.data.success === true) {
          const messagesValue = res.data.messages.map(item => ({
            text: item.message,
            type: item.is_affiliate,
          }));
          setInitialMsg(messagesValue);
          setMessages(messagesValue);
          setAffName(res.data.session.affiliate_name);
          setMemberName(res.data.session.member_name);
         
        }
      }) 
    }
   

    useEffect(() => {
      http.post('/checkAffiliateChatSession', {
        email: atob(encodedEmail),
        sessionId: sessionId,
        type: type
      }).then((res) => {
        if (res.data.success === true) {
          if(res.data.data.affiliate_name != null) {                   // already chat initiated
            // if(res.data.data.affiliate_email == atob(encodedEmail)) {   // already chat initiated & with same affiliate
              setChatOptionEnable(true);
              fetchMessages();
              setFreez(false);
            // }
          }else{
            setChatOptionEnable(true);
            updateChatSession();
            fetchMessages();
            setFreez(false);
          }
        }else{                       
          swal({
            title: "Failed",
            text: res.data.message,
            icon: "error",
            button: "Ok",
          })                         //
          setChatOptionEnable(false);
          setFreez(false);
          setFlag(false)
        }
      })
      // setFreez(false);
    }, [sessionId]);


    const validationSchema = Yup.object().shape({
      name: Yup.string()
          .required('Please Enter Name.'),
    });

    const submitUserName = async (values) => {
      setFreez(true);
      const response = await axios.post( 
        config.API_BASE_URL + "/chatbot/add-affiliate-name", { name: values.name, sessionId: sessionId, email: atob(encodedEmail), type : type});
      if(response) {
        if(response.data && response.data.success == true) {
          updateChatSession();
          setAffName(values.name);
          setChatOptionEnable(true);
        }
      }

      http.get('/fetchMessages/'+ sessionId).then((res) => {
				if (res.data.success === true) {
					// const messages = res.data.data.map((item) => item.message);
          const messagesValue = res.data.messages.map(item => ({
            text: item.message,
            type: item.is_affiliate,
          }));
					setInitialMsg(messagesValue);
          setMessages(messagesValue);
          localStorage.setItem("member_chat_name", res.data.session.member_name);
          setMemberName(res.data.session.member_name);
				}
			})  
      setFreez(false);
    }

  const ContentLayout =
        <>
            <LoginHeader />
            <div>
              <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={freez}
              >
                <Freez />
              </Backdrop>
            </div>
            {/* <Grid container item xs={12} mt={3}>
                <Typography level="h3" sx={{display:"block",marginLeft:"auto", marginRight:"auto"}}><b>You are chatting with {memberName}</b></Typography>
            </Grid> */}
            {
                ((affName === '' || affName == null) && flag == true) ?
                <>
                  <Grid container item xs={12} mt={3} sx={{ textAlign:"center"}}>
                    <Card sx={{minWidth:600,maxWidth:650,display:"block",marginLeft:"auto",marginRight:"auto",}}>
                  <CardOverflow
                  sx={{
                    bgcolor: '#F6FAFF',
                    borderColor: '#F6FAFF',
                    padding: '1%',
                }}><Typography level="h6" sx={{textAlign:"center"}}>Enter Details To Start Chat Session</Typography></CardOverflow>
                
                    <Formik
                        initialValues={{
                            name: ''
                          
                        }}
    
                        validationSchema={validationSchema}
                        onSubmit={(values, errors) => {
                            submitUserName(values);
                        }}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, setFieldTouched, handleSubmit, setFieldValue, setFieldError }) => (      
                  <CardContent>
                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                      <TextField
                        label="Name*"
                        type="text"
                        size="small"
                        fullWidth
                        margin="dense"
                        name="name"
                        id="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        />
                        {touched.name && errors.name ? <div className='error'>{errors.name}</div> : null}
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} sx={{justifyContent:"center",display:"flex"}}>
                      <Button variant="contained" color="primary" sx={{ minWidth:  200, maxWidth: "100%" }} onClick={handleSubmit}>SUBMIT</Button>
                    </Grid>
                  </CardContent>
                    )}
                  </Formik>
                    </Card>
                  </Grid>
                </> 
            : 
            <>
            <Grid container item xs={12}>
            </Grid>
            {
               chatOptionEnable && affName != ''?
                <>
                  <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      sx={{ position:isMobile?"relative": "fixed", width: "100%", zIndex: 1000, padding: "1%" }}
                      >
                      <Grid item xs={12} sm={8} md={6} lg={4}>
                        <Card sx={{
                            boxShadow:
                              "0 0px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 12px 0 rgba(0, 0, 0, 0.19)",
                            borderRadius: "0px"
                          }}>
                            <CardOverflow sx={{ backgroundColor: "#435AC2", borderRadius: "0px", padding: "3%", cursor: isMinimized ? "pointer" : "default",  }}
                            onClick={() => {
                              if (isMinimized) {
                                setIsMinimized(false); // Expand the chatbot when clicking on CardOverflow
                              }
                            }}>
                            <Grid 
                            container 
                            item 
                            lg={12} 
                            md={12} 
                            sm={12} 
                            xs={12} 
                            sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}
                          >
                            <Grid 
                              item 
                              sx={{ display: "flex", alignItems: "center", justifyContent: "center", mr: 1 }}
                            >
                              <img 
                                src={require("../assets/images/chatforbutton.png")} 
                                alt="Chat Icon" 
                                style={{ width: "35px", height: "35px" }} 
                              />
                            </Grid>
                            <Grid item>
                              <Typography 
                                level="h1" 
                                sx={{ color: "white !important", fontSize: "xl", mb: 0.5 }}
                              >
                                <b>You are chatting with {memberName ? memberName : 'User'}</b>
                              </Typography>
                            </Grid>
                          </Grid>



                          </CardOverflow>
                          {!isMinimized && (
                            <CardContent>
                              {/* Message Area */}
                              <Box
                                sx={{
                                  maxHeight: 300,
                                  minHeight: 300,
                                  overflowY: "auto",
                                  p: 1,
                                  // border: "1px solid #ddd",
                                  borderRadius: 2,
                                  mt: 2,
                                  backgroundColor: "#f9f9f9" 
                                }}
                              >
                                <List>
                                  {messages.map((message, index) => (
                                    <ListItem
                                      key={index}
                                      sx={{
                                        justifyContent:
                                          message.type == 1 ? "flex-end" : "flex-start",
                                      }}
                                    >
                                      <Paper
                                        sx={{
                                          p: 1.5,
                                          backgroundColor:
                                            message.type == 1 ? "#1976d2" : "#eee",
                                          color: message.type == 1 ? "#fff" : "#000",
                                          borderRadius: 2,
                                        }}
                                      >
                                        <ListItemText primary={message.text} />
                                      </Paper>
                                    </ListItem>
                                  ))}

                                  {isSessionCleared && (
                                    <ListItem sx={{ justifyContent: "center" }}>
                                      <Paper
                                        sx={{
                                          p: 1,
                                          backgroundColor: "#e0f7fa",
                                          color: "#00796b",
                                          borderRadius: 2,
                                        }}
                                      >
                                        <ListItemText
                                          primary={`The chat session has been ended`}
                                          primaryTypographyProps={{
                                            align: "center",
                                            fontSize: "0.9rem",
                                            fontWeight: "bold",
                                          }}
                                        />
                                      </Paper>
                                    </ListItem>
                                  )}
                                  <div ref={messagesEndRef}></div>
                                </List>
                                </Box>

                              {/* Input Area */}
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  mt: 2,
                                }}
                              >
                                <TextField
                                  fullWidth
                                  placeholder="Type your message..."
                                  value={input}
                                  onChange={(e) => setInput(e.target.value)}
                                  onKeyPress={(e) => {
                                    if (e.key === "Enter" && input.trim()) handleSendMessage();
                                  }}
                                  sx={{ mr: 1 }}
                                  disabled={isSessionCleared}
                                />
                                <Tooltip title="Send Message" arrow>
                                  <IconButton
                                    sx={{ border: "1px solid #3779eb", borderRadius: "6px", backgroundColor: isSessionCleared || !input.trim() ? "#ccc !important" : "#435AC2 !important", color: "white", padding: "2.3%", mr: 1 }}
                                    onClick={handleSendMessage}
                                    disabled={isSessionCleared || !input.trim() || isSetMsg} 
                                  >
                                    <SendIcon sx={{ fontSize: "1.7rem" }} />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Clear Session" arrow>
                                  <IconButton
                                    sx={{
                                      border: "1px solid #ff1414",
                                      borderRadius: "6px",
                                      backgroundColor: isSessionCleared
                                        ? "#ccc !important"
                                        : "#e20a0a !important",
                                      color: "white",
                                      padding: "2.3%",
                                    }}
                                    onClick={handleOpen}
                                    disabled={isSessionCleared}
                                  >
                                    <LogoutIcon sx={{ fontSize: "1.7rem" }} />
                                  </IconButton>
                                </Tooltip>
                                {/* Dialog for Reason */}
                                <Dialog open={open} onClose={handleClose}>
                                  <DialogTitle>Clear Session</DialogTitle>
                                  <DialogContent>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                                    <TextField
                                      autoFocus
                                      margin="dense"
                                      fullWidth
                                      label="Reason"
                                      variant="outlined"
                                      value={reason}
                                      onChange={(e) => setReason(e.target.value)}
                                      multiline
                                      rows={3}
                                      placeholder="Enter the reason for clearing the session"
                                      error={isReasonEmpty} // Highlight the field if empty
                                      helperText={isReasonEmpty ? "Reason cannot be empty" : ""}
                                    />
                                    </Grid>
                                  </DialogContent>
                                  <DialogActions>
                                    <Button onClick={handleClose} color="secondary">
                                      Cancel
                                    </Button>
                                    <Button
                                      onClick={() => handleClearSession(1)}
                                      color="primary"
                                      variant="contained"
                                    >
                                      Confirm
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              </Box>
                            </CardContent>
                          )}
                        </Card>
                      </Grid>
                  </Grid>
                </>
               : '' 
            }
           
            </>
              }
           
            
            <Box sx={{position:isMobile?"relative":"fixed",left: 0,bottom: 0,width: "100%"}}>
            <RegisterFooter />
            </Box>
            
        </>
    // );

    return(
      <>
          {ContentLayout}
          <div>
           
        </div>
      </>
    )
}

export default AffChatSession;
