import React, { useState, useEffect, useRef } from "react";
import { Box, Select, FormControl, MenuItem, Grid, TextField, InputLabel, Divider, OutlinedInput, FormHelperText, InputAdornment,DialogContent,
     IconButton, useMediaQuery, Dialog, DialogTitle,DialogActions, List,ListItem,Paper,ListItemText, Button} from '@mui/material';
import { Typography, Card, CardContent } from '@mui/joy';
import Freez from '../../Components/Loader/Loader'
import Backdrop from '@mui/material/Backdrop';
//UI imports ends
//functional imports starts
import { Formik, Form } from 'formik';
import AuthUser from "../../Components/Auth/AuthUser";
import swal from "sweetalert";
import * as Yup from 'yup';
import InputMask from 'react-input-mask';
import OfferDetails from "../../Components/Enrollment/OfferDetails";
import SecureWebsite from "../../Components/Enrollment/SecureWebsite";
import { parse, isBefore, subYears } from 'date-fns';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../redux/apiSlice';
import { FCMServerKey, FCMSendUrl,getOAuthToken } from "../../firbase/firstore";
import config from "../../AppConfig";
import TextareaAutosize from '@mui/base/TextareaAutosize';
import Chat from "../CreditSnapshot/Chat";
import { ReactComponent as ChatIcon } from "../../assets/images/Chatbutton.svg";
import { ReactComponent as EmailIcon } from "../../assets/images/Emailbutton.svg";
import { ReactComponent as PlayIcon } from "../../assets/images/Playbutton.svg";
let chatSessionId = null;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
    });
    return formattedDate;
};



//functional imports ends
function NewEnrollment2({ onValueChange, props, customColorArray,textColor,btnTextColor, chatEnable, emailUsEnable,companyId, step2Link}) {
    const dispatch = useDispatch();
    const { http } = AuthUser();
    const [states, setStates] = useState([]);
    const inputRef1 = useRef(null);
    const [errorfromcd, seterrorfromcd] = useState();
    const [fullssn, setFullSsn] = useState(false);
    const [ssnlabel, setssnlabel] = useState("Last Four Digits of SSN*");
    const aid = localStorage.getItem('aid');
    const pid = localStorage.getItem('pid');
    const [ssnFlag, setSsnFlag] = useState(false);
    const [ssnError, setSsnError] = useState(false);
    const [isDateValid, setDateValid] = useState(true);
    const [isValidFormat, setIsValidFormat] = useState(true);
    const [lessthanErrorMsg, setLessthanErrorMsg] = useState("");
    const [dateFormatErrorMsg, setDateFormatErrorMsg] = useState("");
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [BlackboxObj, setBlackboxObj] = useState([]);
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [isMinimized, setIsMinimized] = useState(true); 
    const [assistYouFlag, setAssistYouFlag] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [emailChatopen, setEmailChatopen] = useState(false);
    const [chatEmail, setChatEmail] = useState(localStorage.getItem('chatbot_email') != undefined ?  localStorage.getItem('chatbot_email') : localStorage.getItem('Main_email'));
    const [openIframe, setOpenIframe] = useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    
    const sendEmailChatSchema = Yup.object().shape({
            subject: Yup.string()
              .min(2, 'Too Short!')
              .required('Please enter subject'),
            description : Yup.string()
              .min(2, 'Too Short!')
              .required('Please enter Description'),
    });
    // var BlackboxObj;
    // document.addEventListener('DOMContentLoaded', function () {
    //     BlackboxObj = window.IGLOO ? window.IGLOO.getBlackbox() : '';
    // });
    // let BlackboxObj = window.IGLOO ? window.IGLOO.getBlackbox():'';
    //loader function and variable start
    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };

    const openChatPopup = (e) => {
        setIsChatOpen(true);
        var sessionMessagesObj;
        if(localStorage.getItem("sessionMessages") != undefined) {
            sessionMessagesObj = localStorage.getItem("sessionMessages");
        }
        e.preventDefault();
        const element = document.getElementById("chatbotDiv");
        element.style.display = "block";
        console.log("isMinimized:"+isMinimized);
        setIsMinimized(!isMinimized);
        
        chatSessionId = localStorage.getItem('chatSessionId');
        if(chatSessionId != null){
            
            setAssistYouFlag(true);
        }else{
            if(!assistYouFlag){
                setAssistYouFlag(true);
            }
        }
    }
    //loader function and variable end

    const openEmailChatPopup = () => {
        setIsMinimized(true);
        setEmailChatopen(true);
    }

    const changeOpenChatPopupValue = (value) => {
        setIsMinimized(value);
    }

    const closeEmailChatPopup = () => {
        setEmailChatopen(false);
    }
    const handleIFrameStep2 = () => {
        setOpenIframe(true);
    }

    const handleCloseIFrameStep2 = () => {
        setOpenIframe(false);
    }
    //enrollment step 2 function start
    const MainErollStep2 = (values) => {
        if (values.DateofBirth1 == "" || values.DateofBirth1 == undefined || values.DateofBirth1 == null) {
            setButtonDisabled(false);
            swal({
                title: "Alert",
                text: "Date of Birth is required",
                icon: "error",
                button: "Ok",
            }).then((ok) => {
                if (ok) {
                    inputRef1.current?.focus();
                }
            });
            return null;

        }
        var submitFlag = false;
        if (ssnlabel == "Full SSN*" && (!(values.SocialSecurityNumber.match(/^\d{9}$/)))) {
            setButtonDisabled(false);
            setSsnError("Full 9 digit of SSN reqired");
            setSsnFlag(true);
            submitFlag = true;
        } else if (ssnlabel == "Last Four Digits of SSN*" && (!(values.SocialSecurityNumber.match(/^\d{4}$/)))) {
            setButtonDisabled(false);
            setSsnError("Only last 4 digit of SSN reqired");
            setSsnFlag(true);
            submitFlag = true;
        } else {
            setSsnFlag(false);
        }

        if (submitFlag == true) {
            return false;
        }

        if (isDateValid == false || isValidFormat == false) {
            return false;
        }
        handleFreezStart();
        setButtonDisabled(true);
        var mobile = values.MobileNumber.replace(/[^0-9]/g, '');
        var BlackboxObj1 = null;
        if (window.IGLOO && typeof window.IGLOO.getBlackbox === 'function') {
            BlackboxObj1 = window.IGLOO.getBlackbox();
            setBlackboxObj(BlackboxObj1);
            console.log('Blackbox generated:', BlackboxObj1);
        } else {
            console.error('IGLOO or getBlackbox function is not available.');
        }

        http.post("/registerEnrollment", {
            streetAddress: values.StreetAddress,
            city: values.City,
            state: values.State,
            phoneNumber: mobile,
            dob: formatDate(values.DateofBirth1),
            ssn: values.SocialSecurityNumber,
            zip: values.ZipCode,
            firstName: localStorage.getItem('Main_firstName'),
            middlename: localStorage.getItem('Main_middleName'),
            lastName: localStorage.getItem('Main_lastName'),
            email: localStorage.getItem('Main_email'),
            password: localStorage.getItem('Main_password'),
            source: "default",
            pid: pid,
            aid: aid,
            trackingToken: localStorage.getItem('trackingToken'),
            customerToken: localStorage.getItem('customerToken'),
            fullSSN: fullssn,
            blackboxCode: BlackboxObj1 ? BlackboxObj1.blackbox : "",
            step: 2,
        })
            .then(async (res) => {
                handleFreezStop();
                if(res.data.data.failedAttempt){
                    const memberData = res.data.data.memberData;
                    const fcmTokens = res.data.data.fcmTokens;
                    /** Send desktop notification */                        
                    var key = FCMServerKey;
                    var OAuthToken =await getOAuthToken();
                    var dataPayload = {
                        title: "Abandoned Registrant Process",
                        body: `Abandoned At: ID Verification \nAID: ${localStorage.getItem("aid")}\nName: ${memberData.first_name || "N/A"} ${memberData.last_name || "N/A"}\nEmail: ${memberData.email || "N/A"}\nPhone Number: ${memberData.phone_number || "N/A"}`,
                        image: "firebase-logo.png",
                        click_action: `${config.BASE_URL}AbandonedMembers`, // Handle redirection in service worker
                    };
                    fcmTokens.forEach((token) => {
                        fetch(FCMSendUrl, {
                            method: "POST",
                            headers: {
                                Authorization: "Bearer " + OAuthToken,
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                message: {
                                    token: token, // Send to one token per request
                                    data: dataPayload,
                                },
                            }),
                        })
                            .then((response) => response.json())
                            .then((data) => console.log("FCM Response:", data))
                            .catch((error) => console.error("FCM Error:", error));
                    });
                }
                if (res.data.success === true) {
                    localStorage.setItem('idQuestions', JSON.stringify(res.data.data.idQuestions));
                    onValueChange(res.data.data.nextstep);
                } else {
                    seterrorfromcd();
                    console.log(res.data.data.errorByCD[0].message)
                    if(res.data.data.errorByCD[0].message == "422"){
                        swal({
                            title: "Oops!",
                            text: "We're sorry, a small hiccup has occurred. Don't worry! You're just a few steps away from getting your credit report.\n\nAn agent will call you shortly to assist you. Please standby. They'll be calling you from 888-548-2008 or you may receive a sms text or email.\n\nBe sure to pick up the phone when we call.\n\nWe appreciate your patience and understanding.",
                            icon: "error",
                            button: "Ok",
                        });

                    }else{
                        seterrorfromcd(res.data.data.errorByCD[0].message);
                        if (res.data.data.fullSSNRequired === true) {
                            setButtonDisabled(false);
                            setFullSsn(true);
                            setssnlabel("Full SSN*");
                        } else {
                            setButtonDisabled(false);
                            setFullSsn(false);
                            setssnlabel("Last Four Digits of SSN*");
                        }
                        let firstStep = props;
                        res.data.data.errorByCD.forEach(function (errorByCD) {
                            if (errorByCD.code === "EXCEEDED_MAX_TRIES") {
                                firstStep = 1;
                                swal({
                                    title: "Failed",
                                    text: errorByCD.message,
                                    icon: "error",
                                    button: "Ok",
                                });
                            }
                        });
                        onValueChange(firstStep ? firstStep : props);
                    }
                    
                }
            })
            .catch((error) => {
                handleFreezStop();
                setButtonDisabled(false);
                swal({
                    title: "Failed",
                    text: error.message,
                    icon: "error",
                    button: "Ok",
                })
            });
    }
    //enrollment step 2 function end
    //validation schema starts
    const validationSchema = Yup.object().shape({
        StreetAddress: Yup.string()
            .matches(
                /^(?=[-0-9A-Za-z.#'"/\s]*$)/,
                'Please enter a valid Street Address'
            )
            .min(1, 'Too Short!')
            .required('Street address is required'),
        City: Yup.string()
            .required('City is required'),
        State: Yup.string()
            .required('Please select state'),
        ZipCode: Yup.string()
            .min(5, 'ZipCode must be of 5 digits!')
            .max(5, 'ZipCode must be of maximum 5 digits!')
            .required('Zipcode is required'),
        MobileNumber: Yup.string()
            .min(14, 'Mobile number must be of 10 digits!')
            .max(14, 'Mobile number must be of maximum 10 digits!')
            .required('Mobile number is required'),

        SocialSecurityNumber: Yup.string()
            .max(9, 'SSN must be of maximum 9 digits!')
            .matches(
                /^(?=[0-9\-\. ]*$)/,
                'only digits are allowed'
            )
            .required('Social Security Number is required'),

        DateofBirth1: Yup.string()
            .required('Please enter Date of Birth'),
    });
    //validation schema ends
    //useeffect to fetch state array starts
    const fetchState = () => {
        handleFreezStart();
        http.get("/fetchStates").then((res) => {
            handleFreezStop();
            setStates(res.data.data);
        });
    }
    useEffect(() => {
        fetchState();
        // if (document.readyState !== 'loading') {
        //     if (window.IGLOO && typeof window.IGLOO.getBlackbox === 'function') {
        //         var BlackboxObj1 = window.IGLOO.getBlackbox();
        //         setBlackboxObj(BlackboxObj1);
        //     }
        // } else {
        //     document.addEventListener('DOMContentLoaded', function () {
        //         if (window.IGLOO && typeof window.IGLOO.getBlackbox === 'function') {
        //             var BlackboxObj1 = window.IGLOO.getBlackbox();
        //             setBlackboxObj(BlackboxObj1);
        //         }
        //     });
        // }
    }, [])
    //useeffect to fetch state array end
    const beforeMaskedValueChange = (newState, oldState, userInput) => {
        var { value } = newState;
        var selection = newState.selection;
        // keep minus if entered by user
        if (value === '(') {
            value = value.slice(0, -1);
        }


        return {
            value,
            selection
        };
    }
    const handleCallback = (data) => {
        seterrorfromcd(data);
    }
    const handleFreezCallback = (data) => {
        setFreez(data);
    }

    const handleDateChange = (event) => {
        const { value } = event.target;
        //check date format
        const regexPattern = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
        const checkformat = regexPattern.test(value);
        if (checkformat) {

            const [month, day, year] = value.split('/');
            const parsedDate = new Date(`${year}-${month}-${day}`);

            // Check if the parsed date is valid
            const isValidDate = !isNaN(parsedDate) && parsedDate.toISOString().slice(0, 10) === `${year}-${month}-${day}`;
            setIsValidFormat(isValidDate);
            if (!isValidDate) {
                setDateFormatErrorMsg("The birth date must be formatted as MM/DD/YYYY");
                return;
            }

        } else {
            setIsValidFormat(false);
            setDateFormatErrorMsg("The birth date must be formatted as MM/DD/YYYY");
            return;
        }
        //check less than 18 years old
        const inputDateParsed = parse(value, 'MM/dd/yyyy', new Date());
        const eighteenYearsAgo = subYears(new Date(), 18);
        const isValid = isBefore(inputDateParsed, eighteenYearsAgo);
        const currentDate = new Date();
        setDateValid(isValid);
        if (currentDate <= inputDateParsed) {
            setLessthanErrorMsg("Invalid date of birth");
        }
        else if (!isValid) {
            setLessthanErrorMsg("You must be at least 18 to be a member");
        } else {
            setLessthanErrorMsg("");
        }
    };
    //fetch city and state from zipcode
    const fetchLocationInfo = async (zipcode, setFieldValue) => {
        const apiUrl = "/zipcode/" + zipcode;
        setFieldValue('City', '');
        setFieldValue('State', '');
        if (zipcode.length == 5) {
            setFieldValue('City', '...');
            setFieldValue('State', '...');
            handleFreezStart();
            const res = await dispatch(fetchData(apiUrl)).unwrap();
            if (res.success) {
                setFieldValue('City', res.data.places[0]['place name']);
                setFieldValue('State', res.data.places[0]['state abbreviation']);
                handleFreezStop();
            }
            else {
                setFieldValue('City', '');
                setFieldValue('State', '');
                handleFreezStop();
            }
        }
    };
    return (
        <>
            <Formik
                initialValues={{
                    StreetAddress: '',
                    StreetAddress2: '',
                    City: '',
                    State: '',
                    ZipCode: '',
                    MobileNumber: '',
                    DateofBirth: '',
                    DateofBirth1: '',
                    SocialSecurityNumber: '',
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    // Handle form submission logic here
                    setSubmitting(false);
                    MainErollStep2(values)
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <Form>

                        <Grid container item lg={12} md={12} sm={12} xs={12} >
                            <FormControl variant="outlined" fullWidth
                                margin="dense" size="small">
                                <InputLabel sx={{color:customColorArray.primaryColor}}>Street Address*</InputLabel>
                                <OutlinedInput
                                    id="StreetAddress"
                                    name="StreetAddress"
                                    label="Street Address*"
                                    error={touched.StreetAddress && !!errors.StreetAddress}
                                    value={values.StreetAddress}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className='cobrandTextField'
                                    sx={{
                                        "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${customColorArray.primaryColor}` }, // Styles the fieldset
                                        "&:hover .MuiOutlinedInput-notchedOutline": { // Changed selector to include hover state
                                            borderColor: `${customColorArray?.primaryColor}`,
                                        }
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                            >
                                                {touched.StreetAddress && !errors.StreetAddress ? (
                                                    <CheckIcon style={{ color: 'green' }} />
                                                ) : (
                                                    touched.StreetAddress && errors.StreetAddress ? (
                                                        <CloseIcon style={{ color: 'red' }} />
                                                    ) : null
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }

                                />
                                <FormHelperText style={{ color: '#d32f2f',backgroundColor: '#ffffffad',paddingLeft:'3px'}}>
                                    {touched.StreetAddress && errors.StreetAddress}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} >
                            <InputMask
                                mask='99999'
                                maskChar={null}
                                id="ZipCode"
                                name="ZipCode"
                                label="Zip Code*"
                                variant="outlined"
                                autoComplete="ZipCode"
                                size="small"
                                fullWidth
                                margin="dense"
                                sx={{
                                    label: { color: `${customColorArray?.primaryColor} !important` },
                                    "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${customColorArray?.primaryColor}`, background: 'white', zIndex: '-1' }, // Styles the fieldset
                                    " & .MuiFormHelperText-root": {
                                        backgroundColor: '#ffffffad',paddingLeft:'3px'
                                        }, "&:hover .MuiOutlinedInput-notchedOutline": { // Changed selector to include hover state
                                            borderColor: `${customColorArray?.primaryColor} !important`,
                                        }
                                }}
                                error={touched.ZipCode && !!errors.ZipCode}
                                value={values.ZipCode}
                                onChange={(e) => {
                                    handleChange(e);
                                    fetchLocationInfo(e.target.value, setFieldValue);
                                }}
                                onBlur={handleBlur}
                            >
                                {(inputProps) =>
                                    <TextField
                                        label="Zip Code*" variant="outlined"
                                        {...inputProps}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton edge="end">
                                                        {touched.ZipCode && !errors.ZipCode ? (
                                                            <CheckIcon style={{ color: 'green' }} />
                                                        ) : (
                                                            touched.ZipCode && errors.ZipCode ? (
                                                                <CloseIcon style={{ color: 'red' }} />
                                                            ) : null
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                }
                            </InputMask>
                            <FormHelperText style={{color: "#d32f2f",backgroundColor: '#ffffffad',paddingLeft:'3px',marginLeft:'14px'}}>
                                                {touched.ZipCode && errors.ZipCode}
                                            </FormHelperText>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>

                            <FormControl variant="outlined" fullWidth
                                margin="dense" size="small">
                                <InputLabel sx={{color:customColorArray.primaryColor}}>City*</InputLabel>
                                <OutlinedInput
                                    id="City"
                                    name="City"
                                    label="City*"
                                    autoComplete="City"
                                    className='cobrandTextField'
                                    sx={{
                                        "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${customColorArray?.primaryColor}` }, // Styles the fieldset
                                        "&:hover .MuiOutlinedInput-notchedOutline": { // Changed selector to include hover state
                                            borderColor: `${customColorArray?.primaryColor}`,
                                        }
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                            >
                                                {touched.City && !errors.City ? (
                                                    <CheckIcon style={{ color: 'green' }} />
                                                ) : (
                                                    touched.City && errors.City ? (
                                                        <CloseIcon style={{ color: 'red' }} />
                                                    ) : null
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    error={touched.City && !!errors.City}
                                    value={values.City}
                                    onChange={(e) => {
                                        // allow only alphanumeric characters
                                        const inputValue = e.target.value;
                                        const alphanumericRegex = /^[a-zA-Z0-9\s]*$/;
                                        if (alphanumericRegex.test(inputValue)) {
                                            handleChange(e);
                                        }
                                    }}
                                    onBlur={handleBlur}
                                />
                                <FormHelperText style={{ color: '#d32f2f',backgroundColor: '#ffffffad',paddingLeft:'3px',marginLeft:'14px' }}>
                                    {touched.City && errors.City}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <FormControl fullWidth size='small' margin="dense"
                                error={touched.State && errors.State ? errors.State : null}>
                                <InputLabel id="demo-multiple-name-label" sx={{ color: `${customColorArray?.primaryColor} !important`}}>State*</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="demo-simple-select-label"
                                    name="State"
                                    id="State"
                                    sx={{
                                        "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${customColorArray.primaryColor}` }, // Styles the fieldset
                                        "&:hover .MuiOutlinedInput-notchedOutline": { // Changed selector to include hover state
                                            borderColor: `${customColorArray?.primaryColor}`,
                                        }
                                    }}
                                    className='cobrandTextField'
                                    error={touched.State && !!errors.State}
                                    value={values.State}
                                    autoComplete="State"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    input={<OutlinedInput label="State*" />}
                                    MenuProps={MenuProps}

                                >
                                    {states.map((state, index) => {
                                        return (
                                            <MenuItem value={state.state_code} key={index}>{state.state_name}</MenuItem>
                                        )
                                    })}
                                </Select>
                                {touched.State && errors.State ? <FormHelperText style={{backgroundColor: '#ffffffad',paddingLeft:'3px'}}>Please select State.</FormHelperText> : null}

                            </FormControl>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            {/* main mobile number start here */}
                            <InputMask
                                beforeMaskedValueChange={beforeMaskedValueChange}
                                mask='(999) 999-9999'
                                maskChar={null}
                                name='MobileNumber'
                                fullWidth
                                margin="dense"
                                size="small"
                                id='MobileNumber'
                                autoComplete="MobileNumber"
                                error={touched.MobileNumber && !!errors.MobileNumber}
                                value={values.MobileNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                sx={{
                                    label: { color: `${customColorArray?.primaryColor} !important` },
                                    "& .MuiOutlinedInput-notchedOutline": { background: 'white', zIndex: '-1', borderWidth: '2px', borderStyle: 'solid', borderColor: `${customColorArray.primaryColor}` }, // Styles the fieldset
                                    " & .MuiFormHelperText-root": {
                                        backgroundColor: '#ffffffad',paddingLeft:'3px'
                                        },
                                        "&:hover .MuiOutlinedInput-notchedOutline": { // Changed selector to include hover state
                                            borderColor: `${customColorArray?.primaryColor}`,
                                        }
                                }}
                            >
                                {(inputProps) =>
                                    <TextField
                                        label="Phone Number*" variant="outlined"
                                        {...inputProps}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton edge="end">
                                                        {touched.MobileNumber && !errors.MobileNumber ? (
                                                            <CheckIcon style={{ color: 'green' }} />
                                                        ) : (
                                                            touched.MobileNumber && errors.MobileNumber ? (
                                                                <CloseIcon style={{ color: 'red' }} />
                                                            ) : null
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                }
                            </InputMask>
                            <FormHelperText style={{color: "#d32f2f",backgroundColor: '#ffffffad',paddingLeft:'3px',marginLeft:'14px'}}>
                                                {touched.MobileNumber && errors.MobileNumber}
                                            </FormHelperText>
                          
                            {/* main mobile number end here */}

                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <InputMask
                                mask={ssnlabel == "Full SSN*" ? '999999999' : '9999'}
                                maskChar={null}
                                id="SocialSecurityNumber"
                                name="SocialSecurityNumber"
                                fullWidth
                                margin="dense"
                                size="small"
                                autoComplete="SocialSecurityNumber"
                                value={values.SocialSecurityNumber}
                                error={touched.SocialSecurityNumber && !!errors.SocialSecurityNumber}
                                onChange={(e) => {
                                    handleChange(e);
                                    if (ssnlabel === "Full SSN*" && (!(e.target.value.match(/^\d{9}$/)))) {
                                        setSsnError("Full 9 digits of SSN required");
                                        setSsnFlag(true);
                                    } else if (ssnlabel == "Last Four Digits of SSN*" && (!(e.target.value.match(/^\d{4}$/)))) {
                                        setSsnError("Only last 4 digits of SSN required");
                                        setSsnFlag(true);
                                    } else {
                                        setSsnFlag(false);
                                    }
                                }}
                                onBlur={(e) => {
                                    handleBlur(e);
                                    if (ssnlabel === "Full SSN*" && (!(e.target.value.match(/^\d{9}$/)))) {
                                        setSsnError("Full 9 digits of SSN required");
                                        setSsnFlag(true);
                                    } else if (ssnlabel == "Last Four Digits of SSN*" && (!(e.target.value.match(/^\d{4}$/)))) {
                                        setSsnError("Only last 4 digits of SSN required");
                                        setSsnFlag(true);
                                    } else {
                                        setSsnFlag(false);
                                    }
                                }}
                                sx={{
                                    label: { color: `${customColorArray?.primaryColor} !important` },
                                    "& .MuiOutlinedInput-notchedOutline": { background: 'white', zIndex: '-1', borderWidth: '2px', borderStyle: 'solid', borderColor: `${customColorArray.primaryColor}` }, // Styles the fieldset
                                    " & .MuiFormHelperText-root": {
                                        backgroundColor: '#ffffffad',paddingLeft:'3px'
                                        } ,"&:hover .MuiOutlinedInput-notchedOutline": { // Changed selector to include hover state
                                            borderColor: `${customColorArray.primaryColor} !important`,
                                        }
                                }}
                            >
                                {(inputProps) =>
                                    <TextField
                                        label={ssnlabel} variant="outlined"
                                        {...inputProps}
                                        InputProps={{
                                            endAdornment: (
                                                values.SocialSecurityNumber ?
                                                    <InputAdornment position="end">
                                                        <IconButton edge="end">
                                                            {!ssnFlag ? (
                                                                <CheckIcon style={{ color: 'green' }} />
                                                            ) : (
                                                                ssnFlag ? (
                                                                    <CloseIcon style={{ color: 'red' }} />
                                                                ) : null
                                                            )}

                                                        </IconButton>
                                                    </InputAdornment> : null
                                            ),
                                        }}
                                    />
                                }
                            </InputMask>
                            <FormHelperText style={{color: "#d32f2f",backgroundColor: '#ffffffad',paddingLeft:'3px',marginLeft:'14px'}}>
                                                {touched.SocialSecurityNumber && errors.SocialSecurityNumber}
                                            </FormHelperText>
                          
                        </Grid>
                        {ssnFlag ? <div className='error' style={{backgroundColor: '#ffffffad',paddingLeft:'3px'}}>{ssnError}</div> : null}
                        <Grid container item lg={12} md={12} sm={12} xs={12} >

                            <FormControl fullWidth size='small'>
                                <InputMask
                                    name="DateofBirth1"
                                    id='DateofBirth1'
                                    value={values.DateofBirth1}
                                    error={touched.DateofBirth1 && !!errors.DateofBirth1}
                                    onChange={(e) => {
                                        handleDateChange(e);
                                        handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    mask='99/99/9999'
                                    maskChar={null}
                                    label="Date of Birth*"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    margin="dense"
                                    autoComplete="DateofBirth1"
                                    placeholder="MM/DD/YYYY"
                                    sx={{
                                        label: { color: `${customColorArray?.primaryColor} !important` },
                                        "& .MuiOutlinedInput-notchedOutline": { background: 'white', zIndex: '-1', borderWidth: '2px', borderStyle: 'solid', borderColor: `${customColorArray.primaryColor}` }, // Styles the fieldset
                                        " & .MuiFormHelperText-root": {
                                            backgroundColor: '#ffffffad',paddingLeft:'3px'
                                            }, "&:hover .MuiOutlinedInput-notchedOutline": { // Changed selector to include hover state
                                                borderColor: `${customColorArray?.primaryColor}`,
                                            }
                                    }}
                                >
                                    {(inputProps) =>
                                        <TextField
                                            label="Date of Birth*" variant="outlined"
                                            {...inputProps}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton edge="end">
                                                            {touched.DateofBirth1 && !errors.DateofBirth1 && isValidFormat && isDateValid ? (
                                                                <CheckIcon style={{ color: 'green' }} />
                                                            ) : (
                                                                (touched.DateofBirth1 && errors.DateofBirth1 || !isValidFormat || !isDateValid) ? (
                                                                    <CloseIcon style={{ color: 'red' }} />
                                                                ) : null
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    }
                                </InputMask>
                                <FormHelperText style={{color: "#d32f2f",backgroundColor: '#ffffffad',paddingLeft:'3px'}}>
                                                {touched.DateofBirth1 && errors.DateofBirth1}
                                            </FormHelperText>
                                
                            </FormControl>
                        </Grid>
                        <FormHelperText sx={{ color: "#d32f2f",backgroundColor: '#ffffffad',paddingLeft:'3px' }}>{!isValidFormat ? dateFormatErrorMsg : !isDateValid ? lessthanErrorMsg : ''}</FormHelperText>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            {errorfromcd ? <Typography sx={{ fontWeight: "bold", color: "red" ,backgroundColor: '#ffffffad',paddingLeft:'3px'}}>
                                Error :- {errorfromcd}
                            </Typography>
                                : ""}
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12} mt={2} sx={{ justifyContent: "right", display: "flex" }}>
                            <Button type="submit" sx={{
                                minWidth: 150, backgroundColor: `${customColorArray.buttonColor}`, color: `${customColorArray?.buttonTextColor}`, borderRadius: "0%", '&:hover': {
                                    backgroundColor: `${customColorArray.buttonColor}`
                                }
                            }} disabled={isButtonDisabled} >NEXT STEP</Button>
                        </Grid>
                        <SecureWebsite textColor={textColor} hyperlinkColor={customColorArray.hyperlinkColor}/>

                    </Form>
                )}
            </Formik>

            <Box>
                  <Grid container item lg={12} md={12} sm={12} xs={12} sx={{display:"flex",justifyContent:"flex-end",mt:isMobile?4:0}}>
                    {
                        chatEnable   ?
                            <Grid item lg={4} md={4} sm={12} xs={12} mt={1} sx={{ display:"flex",justifyContent:"flex-end",marginTop:isMobile?2:0 }}>
                            <Button varient="outlined"
                                onClick={openChatPopup}
                                // disabled={isChatOpen}
                            startIcon={<ChatIcon style={{ width: 30, height: 30, fill: `${customColorArray?.buttonTextColor}` }} />}
                            // sx={{
                            //     textTransform: "none !important",
                            //     border:"1px solid #2E67BC",
                            //     minWidth:'80%' ,
                            //     maxWidth:'100%',
                            //     color:"white",
                            //     alignItems:"center",
                            //     fontWeight:"bold"// Prevents uppercase transformation
                            // }}
                            sx={{
                                textTransform: "none !important",minWidth:'80%',maxWidth:'100%', backgroundColor: `${customColorArray?.buttonColor}`, color: `${customColorArray?.buttonTextColor}`, borderRadius: "10px", '&:hover': {
                                    backgroundColor: `${customColorArray?.buttonColor}`
                                }
                            }}
                            >Chat With Us Now</Button>
                            </Grid>
                        : ''
                    }
                    {
                        emailUsEnable ?
                            <Grid item lg={4} md={4} sm={12} xs={12}  mt={1} sx={{ display:"flex",justifyContent:"flex-end",marginTop:isMobile?2:0 }}>
                                <Button  
                                startIcon={<EmailIcon style={{ width: 30, height: 30, fill: `${customColorArray?.buttonTextColor}` }} />}
                                onClick={openEmailChatPopup}
                                // sx={{
                                // textTransform: "none",
                                // border:"1px solid #2E67BC",
                                // minWidth:'80%' ,
                                // maxWidth:'100%',
                                // color:"white",
                                // alignItems:"center",
                                // fontWeight:"bold" // Prevents uppercase transformation
                                // }}
                                sx={{
                                    textTransform: "none !important",minWidth:'80%',maxWidth:'100%', backgroundColor: `${customColorArray?.buttonColor}`, color: `${customColorArray?.buttonTextColor}`, borderRadius: "10px", '&:hover': {
                                        backgroundColor: `${customColorArray?.buttonColor}`
                                    }
                                }}
                                >Email Us Now</Button>
                            </Grid>
                    : ''}

                                {step2Link ?( <> 
                                    <Grid item lg={4} md={4} sm={12} xs={12} mt={1} sx={{ display:"flex",justifyContent:"center",marginTop:isMobile?2:0 }}>
                                      <Button onClick={handleIFrameStep2}
                                        startIcon={<PlayIcon style={{ width: 30, height: 30, fill: `${customColorArray?.buttonTextColor}` }} />}
                                        // sx={{
                                        //   textTransform: "none",
                                        //   border:"1px solid #2E67BC", 
                                        //   minWidth:'80%' ,
                                        //   maxWidth:'100%',
                                        //   alignItems:"center",
                                        //   color:"white",
                                        //   fontWeight:"bold"
                                        // }}

                                        sx={{
                                            textTransform: "none !important",minWidth:'80%',maxWidth:'100%', backgroundColor: `${customColorArray?.buttonColor}`, color: `${customColorArray?.buttonTextColor}`, borderRadius: "10px", '&:hover': {
                                                backgroundColor: `${customColorArray?.buttonColor}`
                                            }
                                        }}
                                        >Learn More About Us
                                      </Button>
                                  </Grid>
                                </>): ""}
                            </Grid>
            </Box>

            <Chat mEmail={chatEmail} firstName={localStorage.getItem('Main_firstName')} isMinimized={isMinimized} changeOpenChatPopupValue={changeOpenChatPopupValue} openEmailChatPopup={openEmailChatPopup} companyId={companyId} source="cobrand"/>

            {/* Chat functionality started */}
            {isChatOpen && (
                    <Box
                        sx={{
                            position: "fixed",
                            bottom: "2%",
                            right: "2%",
                            cursor: "pointer",
                        }}
                        onClick={openChatPopup}
                        >
                        <img
                            src={require("../../../src/assets/images/chatforbutton.png")}
                            alt="Chat Icon"
                            style={{
                            width: "70px",
                            height: "70px",
                            borderRadius: "50%",
                            boxShadow:
                                "0px 4px 8px rgba(0, 0, 0, 0.2), 0px 8px 16px rgba(0, 0, 0, 0.19)",
                            }}
                        />
                        </Box>
                        )}
             {/* Chat functionality ended here */}

             <Formik
            initialValues={{
            subject: '',
            description : '',
            email : ''
            }}
            validationSchema={sendEmailChatSchema}
            onSubmit={async (values, { resetForm }) => {
            handleFreezStart();
            http.post("/sendChatEmail", {
                email : chatEmail,
                subject : values.subject,
                description : values.description,
                companyId : companyId,
                 source : "cobrand"
            })
            .then((res) => {
                if (res.data.success === true) {
                swal({
                    title: "Success",
                    text: res.data.message,
                    icon: "success",
                    button: "Ok",
                });
                handleFreezStop();
                }else{
                // alert("something went wrong");
                handleFreezStop();
                }
            })
            closeEmailChatPopup();
            resetForm();
            }}
        >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <Dialog
                open={emailChatopen}
                onClose={(_, reason) => {
                if (reason !== "backdropClick") {
                    // setDeleteOpen(false)
                }
                }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {/* Delete Pid */}
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{justifyContent:"space-between"}}>
                <Grid item lg={10} md={10} sm={10} xs={10}>
                    <Typography level="h5" sx={{textAlign:"center"}}>Send Us The Email</Typography>
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={2} sx={{textAlign:"right"}}>
                    <CloseIcon  onClick={closeEmailChatPopup}/>
                </Grid>
                </Grid>
            </DialogTitle>
            <DialogActions >
            <Grid container item lg={12} md={12} sm={12} xs={12} >
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                    id="outlined-start-adornment"
                    type="text"
                    label="Subject"
                    fullWidth
                    variant="outlined"
                    size="small"
                    margin='dense'
                    autoComplete='subject'
                    value={values.subject}
                    onChange={handleChange('subject')}
                    onBlur={handleBlur('subject')}
                    InputLabelProps={{
                        shrink: Boolean(values.subject)
                    }}
                    /> 
                    {touched.subject && errors.subject ? <div className='error'>{errors.subject}</div> : null}
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <TextareaAutosize
                        placeholder="Message"
                        type="text"
                        size="small"
                        fullWidth
                        name='description'
                        autoComplete='description'
                        id='description'
                        onChange={handleChange('description')}
                        onBlur={handleBlur('description')}
                        value={values.description}
                        maxRows={7}
                        minRows={4}
                        
                        style={{
                        width: '100%', // Ensures full width
                        // padding: '8px', // Optional for better spacing
                        boxSizing: 'border-box', // Ensures padding doesn't affect width
                    }}
                    />
                    {touched.description && errors.description ? <div className='error'>{errors.description}</div> : null}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} mt={2} sx={{ justifyContent: "center", display: "flex"}}>
                <Button sx={{
                                minWidth: 150, backgroundColor: `${customColorArray?.primaryColor}`, color: btnTextColor, '&:hover': {
                                    backgroundColor: `${customColorArray?.primaryColor}`
                                }
                            }}
                    onClick={handleSubmit}
                    >
                    Send
                </Button>
                </Grid>
            </Grid>
            </DialogActions>
            </Dialog>
        )}
        </Formik>


 {/* Iframe Dialog Start */}
 <Dialog open={openIframe}
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleCloseIFrameStep2();
                    }
                }}
                fullWidth={fullWidth}
                maxWidth="md"
                >
                <DialogTitle sx={{ bgcolor: "#F6FAFF" }}>
                    <Grid container item xs sx={{ justifyContent: "space-between" }}>
                        <Grid item xs>
                            <Typography level="h4" color="primary">Learn More About Us</Typography>
                        </Grid>
                        <Grid item xs sx={{ textAlign: "right" }}>
                            <IconButton
                                aria-label="close"
                                onClick={handleCloseIFrameStep2}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} sx={{ justifyContent : "center" }}>
                        <iframe
                            width="560"
                            height="315"
                            src={step2Link}
                            title="Video Player"
                            // frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </Grid>
                </DialogContent>
                {/* </DialogActions> */}
            </Dialog>
            {/* Iframe Dialog End */}


            {/* loader code start */}
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}
        </>
    )
}
export default NewEnrollment2;