import React, { useRef, useState, useEffect } from 'react';
import { Button,InputAdornment,Tooltip,IconButton,OutlinedInput,CardMedia,Card,DialogContentText, Dialog, DialogActions, DialogContent, DialogTitle, RadioGroup, Grid, FormControlLabel, Radio, TextField, FormHelperText, FormControl } from '@mui/material';
import { Typography } from '@mui/joy';
import config from '../../AppConfig';
import swal from "sweetalert";
import AuthUser from '../Auth/AuthUser';
import axios from 'axios';
import InputMask from 'react-input-mask';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../Loader/Loader';
axios.defaults.withCredentials = true;

function LogoEdit({id,setOpen,logoFlagEdit,updateFlag,logoName,logoEditURL,source,handleEditLogoURLFlag,fetchCreditInfo,logo,type,text}) {
	const { httpFormData } = AuthUser();
	const [freez, setFreez] = React.useState(false);
	const [fileName, setFileName] = useState("");
	const [radioValue, setRadioValue] = useState(
		 type === "usepersonalised" ? "defaultLogo" : 
		type === "uploadyourlogo" ? "customLogo" : "defaultLogo"
	  );
	const [imageUrl, setImageUrl] = useState(config.BASE_URL+'genericlogo.png');
	const [imageFile, setImageFile] = useState(null);
	const [logoFlag, setLogoFlag] = useState(logo ? true : false);
	const [stampText, setStampText] = useState(text ? text : '');
	const [stampTextError, setStampTextError] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isChange, setIsChange] = useState(false);
	const [companyLogo, setCompanyLogo] = useState('');
	const handleUpload = (image) => {
		setCompanyLogo(image); // Make sure `image` is a valid URL or Blob URL
	  };
	const fileInputRef = React.useRef(null);
	const handleFreezStop = () => {
		setFreez(false);
	  };
	  const handleFreezStart = () => {
		setFreez(true);
	  };

	const handleClose = () => {
		setOpen(false);
	};
	useEffect(() => {
		if(radioValue == "defaultLogo"){
			const genericLogoGrid = document.getElementById("genericLogoGrid");
            genericLogoGrid.style.display= "block";
			const customLogoGrid = document.getElementById("customLogoGrid");
            customLogoGrid.style.display= "none";
		}else{
			const genericLogoGrid = document.getElementById("genericLogoGrid");
            genericLogoGrid.style.display= "none";
			const customLogoGrid = document.getElementById("customLogoGrid");
            customLogoGrid.style.display= "block";
		}
	}, []); 

	const handleRadioChange = (event) => {
		setRadioValue(event.target.value);
		if(event.target.value == "defaultLogo"){
			handleSaveImage();
			if (text?.trim()) {
				setIsChange(text !== stampText);
			} else {
				setIsChange(!!stampText?.trim());
			}
			if(type == "uploadyourlogo"){
				setImageUrl(config.BASE_URL+'genericlogo.png');
				setLogoFlag(false);
			}
			// setImageUrl(logo ? logo : config.BASE_URL+'genericlogo.png');
			const genericLogoGrid = document.getElementById("genericLogoGrid");
            genericLogoGrid.style.display= "block";
			const customLogoGrid = document.getElementById("customLogoGrid");
            customLogoGrid.style.display= "none";
		}else{
			if (fileName && imageFile) {
				handleUploadFile({ target: { files: [imageFile] } });
			} else{
				if(type != "uploadyourlogo"){
					setImageUrl(config.BASE_URL+'uploadLogo.png');
					setLogoFlag(false);
				}else{
					setImageUrl(logo ? logo : config.BASE_URL + 'uploadLogo.png');
					setLogoFlag(logo ? true: false);
				}
			}
			const genericLogoGrid = document.getElementById("genericLogoGrid");
            genericLogoGrid.style.display= "none";
			const customLogoGrid = document.getElementById("customLogoGrid");
            customLogoGrid.style.display= "block";
			setIsChange(!!fileName);
		}
	}
	const handleStampChange = async(event) => {
		setLogoFlag(false);
		setImageUrl(config.BASE_URL+'genericlogo.png');
		setIsSubmitting(true);
		setStampText(event.target.value);
		if (text && text != "") {
			if (text == event.target.value) {
				setIsChange(false);
			} else {
				setIsChange(true);
			}
		}else {
			setIsChange(true);
		}
		
		if(event.target.value === ''){
			setStampTextError('Please add Logo text.');
			setIsChange(false);
		}else{
			if((event.target.value).length > 32){
				setStampTextError('Text should be in max 32 characters.');
				setIsChange(false);
			}else{
				setStampTextError('');
			}
		}		
	};

	const handleSaveImage = async () => {
		if(stampText === ''){
			setImageUrl(config.BASE_URL+'genericlogo.png');
		}else{
			var canvas = document.createElement('canvas');
			var context = canvas.getContext('2d');
			var img = new Image();	
			
			// clear the canvas
			context.clearRect(0, 0, canvas.width, canvas.height);
            
	        var regex = /\s*(?:(\S{16})|([\s\S]{1,16})(?!\S))/g;
            var cname2= stampText.replace(regex, function($0,$1,$2) { return $1 ? $1 + "\n" : $2 + "\n"; } );

			var line=cname2.split("\n");
            var linelength = line.length;

			img.crossOrigin = 'anonymous';
			img.src = config.BASE_URL+'genericlogo.png';
			img.onload = async() => {
				canvas.width = "300";
				canvas.height = "90";
				
				context.drawImage(img, 0, 0);
				context.font = '22px Arial';
				context.fillStyle = 'black';

				var count = 100 / linelength;				
				for(var i=0; i < linelength-1; i++){
					context.fillText(line[i], img.width - 200, (count + (count*i)));
				}
				// context.fillText(stampText, img.width - 200, img.height - 50);
				setImageUrl(canvas.toDataURL('image/png'));
				setLogoFlag(false);	
				// Save stampedImageUrl to database or use it as the source of an <img> tag
			};
			// img.src = imageUrl;	
		}
	}

	useEffect(()=>{			
		handleSaveImage();			
	},[stampText])

	const handleUploadFile = (event) => {
		setIsSubmitting(true);
		const file = event.target.files[0];
    
		if (!file) {
			return; // If no file is selected, just return.
		}
		// added file type validation start here
		const validFileTypes = ['image/jpeg', 'image/png', 'image/gif'];
		if (!validFileTypes.includes(file.type)) {
			alert('Only JPEG, PNG, or GIF image formats are allowed.');
			fileInputRef.current.value = null;
			document.getElementById("uploadedLogo").value = '';
			setImageUrl(config.BASE_URL + 'uploadLogo.png');
			return;
		}
		// added file type validation end here
		
		let textValueError = document.getElementById("uploadLogoError");
		textValueError.style.display= "none";
		const img = new Image();
		img.src = URL.createObjectURL(file)
		img.onload = () => {			
			if (img.width != 300 || img.height != 90) {
				alert('Image must be 300px in width and 90px in height.');
				event.target.value = null;
				fileInputRef.current.value = null;
				document.getElementById("uploadedLogo").value = '';
				setImageUrl(config.BASE_URL+'uploadLogo.png');
				setImageFile(null);
				setFileName('');
				setIsChange(false);
			}else{
				setFileName(file.name);
				setImageFile(file);
				setImageUrl(img.src);
				setLogoFlag(false);
				setIsChange(true);
			}
		};
	};

	const handleCancelFile = () => {
		fileInputRef.current.value = null;
		document.getElementById("uploadedLogo").value = '';
		setImageUrl(config.BASE_URL+'uploadLogo.png');
	}

	const UploadLogo = () => {
		handleFreezStart();
		const formData = new FormData(); // Create a FormData object
		if(radioValue == 'defaultLogo'){
			let textValue = document.getElementById("stampText");
			if(textValue.value === ''){
				handleFreezStop();
				setStampTextError('Please add Logo text.');
				return false;
			}else{
				if(textValue.length > 32){
					handleFreezStop();
					setStampTextError('Text should be in max 32 characters.');
					return false;
				}else{
					setStampTextError('');
				}
			}
			console.log("imageUrl",imageUrl);
			formData.append('uploadedLogo', imageUrl);
			formData.append('defaultLogoText', stampText);
		}else {
			let fileInput = document.getElementById("hiddenFileInput");
			let textValueError = document.getElementById("uploadLogoError");
			if (!fileInput.files.length || !imageFile) {
				handleFreezStop();
				textValueError.style.display = "block"; // Show error message
				return false;
			} 
			textValueError.style.display = "none"; // Hide error message
			formData.append('uploadedLogo', imageFile);
		}
		formData.append('aidMasterId', id);
		formData.append('logoType', radioValue);
		setIsSubmitting(true);
		if(source == "company"){
			httpFormData.post('/uploadCompanyLogo',formData).then((res)=>{
				if(res.data.success === true){
					handleFreezStop();
					handleClose();				
					logoFlagEdit(1);
					logoEditURL(res.data.filename);
					if(fetchCreditInfo != null) {
						fetchCreditInfo();
					}
					swal({
						title: "Success",
						text: res.data.message,
						icon: "success",
						Button: "Ok",
					}).then(() => {
						window.location.reload(); // Reloads the page after success
					}); 
				}else{
					handleFreezStop();
					logoFlagEdit(0);
					setIsSubmitting(false);
					swal({
						title: "Alert",
						text: "Something went wrong please try again",
						icon: "error",
						button: "Ok",
					})
				}            
			}).catch(error => {
				handleFreezStop();
				logoFlagEdit(0);
				setIsSubmitting(false);
				swal({
					title: "Alert",
					text: "Network Error",
					icon: "error",
					button: "Ok",
				})
			})
		}
		else if(source == "register"){
			handleFreezStop();
			formData.append('imageUrl', imageUrl);
			logoEditURL(formData)
			handleEditLogoURLFlag(1)
			handleClose();
		}
		else{
			if(updateFlag == 1){
				formData.append('updateFlag', updateFlag);
			}
			if(source == "requestCobrand"){
				formData.append('updateFlag', 1);
			}
			httpFormData.post('/uploadLogo',formData).then((res)=>{
				if(res.data.success === true){
					handleFreezStop();
					handleClose();				
					logoFlagEdit(1);
					logoEditURL(imageUrl,radioValue,stampText);
					logoName(res.data.fileName);
					if(fetchCreditInfo != null) {
						fetchCreditInfo();
					}
					swal({
						title: "Success",
						text: res.data.message,
						icon: "success",
						Button: "Ok",
					});
				}else{
					handleFreezStop();
					logoFlagEdit(0);
					setIsSubmitting(false);
					swal({
						title: "Alert",
						text: "Something went wrong please try again",
						icon: "error",
						button: "Ok",
					})
				}            
			}).catch(error => {
				handleFreezStop();
				logoFlagEdit(0);
				setIsSubmitting(false);
				swal({
					title: "Alert",
					text: "Network Error",
					icon: "error",
					button: "Ok",
				})
			})
		}
	}
	
  return (
    <>      
		<DialogTitle textAlign={'center'} id="responsive-dialog-title" component="h1" color="primary.main" sx={{ backgroundColor: "#F6FAFF" }}>
			Upload Logo
		</DialogTitle>
		<DialogContent mt={2}>
			<Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center",alignContent:'center' }}>
				<Grid>
					{logoFlag ? (
						<CardMedia
							component="img"
							style={{ width: '100%' }}
							image={logo} 
							alt="Uploaded Logo"
						/>
					) : (
						<img src={imageUrl} alt="Default Logo" />
					)}
				</Grid>
			</Grid>
			<RadioGroup
				aria-labelledby="demo-radio-buttons-group-label"
				value={radioValue}
				name="radio-buttons-group"
				onChange={handleRadioChange}
			>
				<Grid container item lg={12} md={12} sm={12} xs={12}>				
					<FormControlLabel value="defaultLogo" control={<Radio />} label="Use Generic Logo" />				
				</Grid>
				<Grid container item lg={12} md={12} sm={12} xs={12}>
					<Typography>(you may change later)</Typography>
				</Grid>
				<Grid container item lg={12} md={12} sm={12} xs={12}>
					<Typography>Logo Text (Max 32 characters)</Typography>
				</Grid>
				<Grid container item lg={12} md={12} sm={12} xs={12} mt={1} id="genericLogoGrid" sx={{display:'block'}}>
					<FormControl fullWidth >
						<TextField
							id="stampText"
							label="Logo Name Text"
							variant="outlined"
							margin="dense"
							size="small"
							fullWidth 
							value={stampText}
							onChange={handleStampChange}
							inputProps={{ maxLength: 32 }}
						/>
						{
							stampTextError ?
							<FormHelperText id="stampTextError" sx={{color:'red'}}>{stampTextError}</FormHelperText>
							:null
						}
					</FormControl>
				</Grid>
				<Grid container item lg={12} md={12} sm={12} xs={12}>
					<FormControlLabel value="customLogo" control={<Radio />} label="Upload Your Own Logo" />
				</Grid>
				<Grid container item lg={12} md={12} sm={12} xs={12}>
					<Typography>(size 300px X 90px, file size 50kb)</Typography>
				</Grid>
				<Grid container item lg={12} md={12} sm={12} xs={12} mt={1} id="customLogoGrid" sx={{display:'none'}}>
					<OutlinedInput
						id="uploadedLogo"
						variant="outlined"
						margin="dense"
						size="small"
						type="text"
						name="uploadedLogo"
						fullWidth 
						ref={fileInputRef}
						value={fileName || (type === "uploadyourlogo" ? (logo ? logo.split('/').pop() : "") : "")}
						onDrop={(e) => {
							e.preventDefault();
						}}
						onChange={handleUploadFile}
						inputProps={{ accept: 'image/png, image/jpeg, image/jpg' }}  
						endAdornment={
							<InputAdornment position="end">
								<Tooltip
								title={"Upload Logo"}
								>
								<IconButton
									edge="end"
									onClick={() => document.getElementById("hiddenFileInput").click()}
								>
									<img
									src={require("../../assets/images/uploadFile.png")}
									style={{ height: "25px" }}
									alt="Upload"
									/>
								</IconButton>
								</Tooltip>
							</InputAdornment>
							}
					/>
					<input
							id="hiddenFileInput"
							type="file"
							name="uploadedLogo"
							style={{ display: "none" }} 
							accept="image/png, image/jpeg, image/jpg"
							onChange={handleUploadFile}
						/>
					<FormHelperText id="uploadLogoError" sx={{display:'none',color:'red'}}>Please choose logo image</FormHelperText>
				</Grid>
				<Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
					<Typography sx={{color:"#2A9006",textAlign:"center"}} variant="body1"><i><b>“Need help with adding logo?  Call us @1-888-548-2008”</b></i></Typography>
				</Grid>
			</RadioGroup>
		</DialogContent>
		<DialogActions sx={{ display: "flex", justifyContent: "center" }}>
			<Button sx={{ minWidth: 250 }} variant="contained" color="primary" onClick={handleClose}>
			CLOSE
			</Button>
			<Button variant="contained" sx={{ minWidth: 250 }} color="warning" disabled={!isChange} onClick={UploadLogo}>
			SAVE
			</Button>
		</DialogActions>

		{/* loader code start */}
		<div>
			<Backdrop
			sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
			open={freez}
			onClick={handleFreezStop}
			>
			<Freez />
			</Backdrop>
      </div>
      {/* loader code end */}
    </>
  );
}


export default LogoEdit;