import React, { useState, useEffect } from "react";
import { DialogContent, Grid, Select, FormControl, MenuItem, InputLabel, Box, TextField, Dialog, InputAdornment, DialogActions, OutlinedInput, Backdrop, DialogTitle, Tab,Tooltip } from '@mui/material';
import { Typography, Card, CardContent, CardOverflow, Button, List, ListItem, ListItemDecorator, ListItemContent } from '@mui/joy';
import AuthUser from '../Auth/AuthUser';
import config from '../../AppConfig';
import PopupPreview from "../../Components/CreditSnapshot/PopupPreview";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ContentElement from "../../../src/views/Web/CreditSnapshot/ContentElement";
import Freez from '../../Components/Loader/Loader';
import swal from "sweetalert";
import {  useDispatch } from 'react-redux';
import { fetchData, postData } from '../../redux/apiSlice';
import ShareIcons from './MediaShareIcons';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const SnapshotPopupSetUp = ({ firstlogoUrl,receivedpid,creditinfo,companyId,link,fetchCreditInfo,customColorArray,snapshotStr}) => {
  const { http, user } = AuthUser();
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [openColorPicker, setOpenColorPicker] = useState(null);
  const [freez, setFreez] = React.useState(false);
  const dispatch = useDispatch();
 const [snippetCopy, setSnippetCopy] = useState('Copy');
 const [uploadLogo, setUploadLogo] = React.useState(false);
 const [copySuccess, setCopySuccess] = useState('');
  const handleClickOpen2 = () => {
      setOpen2(true);
  };

  const handleClose1 = () => {
      handleClickOpen1();
      setOpen1(false);
      setOpenColorPicker(null)
  };

  const handleClickOpen1 = () => {
    if (creditinfo.data) {
        const attr = JSON.parse(creditinfo.data.snapshot_popup_attribute_json);
        customColorArray = {
            popupHeaderBackground: attr ? attr.popupHeaderBackground : '#00BBD3',
            popupHeaderText: attr ? attr.popupHeaderText : '#000000',
            popupBodyBackground: attr ? attr.popupBodyBackground : '#ffffff',
            popupBodyText: attr ? attr.popupBodyText : '#000000',
            popupButtonBackground: attr ? attr.popupButtonBackground : '#FF9400',
            popupButtonText: attr ? attr.popupButtonText : '#FFFFFF',
            popupIconColor: attr ? attr.popupIconColor : '#ff9400'
        }
        setOpen1(true);
    }
  };

  const handleValue = (colorArray) => {
    customColorArray = colorArray;
  }

  const toggleColorPicker = (blockName) => {
    setOpenColorPicker((prevBlockName) => (prevBlockName === blockName ? null : blockName));
  };

  const  handleResetColors=()=>{
    setOpen1(false);
    setOpenColorPicker(null)
    setTimeout(() => {
        handleClickOpen1();
        setOpen1(true);
    }, 600);

  }

  const handleFreezStop = () => {
      setFreez(false);
  };
  const handleFreezStart = () => {
      setFreez(true);
  };

  const savePopupAttribute = async () => {
    setOpenColorPicker(null)
    setOpen1(false);
    handleFreezStart();
    const apiUrl = "/saveCustomPopupAttr";
    const args = {
        attrColorArray: customColorArray,
        companyId: companyId
    }
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if (res) {
        handleFreezStop();
        if (res.success) {
            swal({
                title: "Success",
                text: res.message,
                icon: "success",
                button: "Ok",
            }).then((ok) => {
                fetchCreditInfo();
            });
        } else {
            swal({
                title: "Alert",
                text: res.message,
                icon: "error",
                button: "Ok",
            })
        }
    }
  }

  const handlePopupPreview = (data) => {
    handleClickOpen2();
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

    return(
        <>
        <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                // onClick={handleFreezStop}
                >
                    <Freez />
                </Backdrop>
            </div>
        {firstlogoUrl && receivedpid ?
        <>
        <Grid  container item lg={12} md={12} sm={12} xs={12}>
            <Typography level="h5" mt={1}>Promote Your Credit Snapshot Link On Social Media</Typography>
        </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Card sx={{width:"100%", boxShadow:"none",border:"1px solid #2E67BC"}}>
                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                        {link ? <ShareIcons url={link} /> : ""}
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={1} p={1}>
                            {firstlogoUrl && receivedpid ?
                               <Card sx={{width:"100%" ,boxShadow:"none",border:"1px solid #2E67BC"}}>
                                    <Grid  container item lg={12} md={12} sm={12} xs={12} spacing={2} p={0}>
                                        <Grid item lg={9} md={9} sm={9} xs={9} className="link-word-wrap">
                                            {link}
                                        </Grid>
                                        <Grid item lg={3} md={3} sm={3} xs={3} sx={{ textAlign: "right" }} pr={2}  >
                                             <Tooltip title="Copy">
                                                <ContentCopyIcon  
                                                onClick={() => {
                                                    navigator.clipboard.writeText(link)
                                                    setCopySuccess(link)
                                                    setTimeout(() => {
                                                        setCopySuccess('');
                                                    }, 3000);
                                                }}
                                                disabled={copySuccess === link} sx={{cursor:"pointer"}}/>
                                            </Tooltip>
                                            {copySuccess == link ? "Copied!" : ''}      

                                        </Grid>
                                    </Grid>
                                </Card>
                            : null}
                    </Grid>
                </Card>
                <Grid item lg={12} md={12} sm={12} xs={12} mt={1}><ul><li>Copy your credit snapshot link to post on social media or share with your client.</li></ul></Grid> 
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={2}>
              <Card sx={{width:"100%",boxShadow:"none"}}>
                <Typography level="h5" sx={{fontWeight : 'bold'}}>Customize And Set Up Your Pop-up For Your Website</Typography>
                <CardContent>
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between", marginTop: '2%' }}>
                    <Grid item lg={6} md={6} sm={6} xs={6} sx={{ textAlign: "right" }}>
                        {/* <IconButton
                            aria-label="close"
                            onClick={handleClose1}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton> */}
                    </Grid>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3} >
                    <Grid item lg={5} md={5} sm={5} xs={5} >
                        <Card sx={{ padding: 0 }} variant="outlined" >
                            <CardOverflow
                                sx={{
                                    bgcolor: `${customColorArray.popupHeaderBackground}`,
                                    borderColor: `${customColorArray.popupHeaderBackground}`,
                                    p: 3,
                                    margin: 0
                                }}
                                id="popupHeaderBackground"
                            >
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center", textAlign: 'center' }} >
                                        <p style={{ margin: 0, color: `${customColorArray.popupHeaderText}`, fontSize: "1.5em",fontWeight:'bold' }} id="popupHeaderText">
                                            You Can Improve Your Credit Score
                                            <br />
                                            {
                                                creditinfo
                                                    ?
                                                    firstlogoUrl
                                                        ?
                                                        <img alt="#" className="dashlogo" height="65" style={{ padding: '1%' }} src={config.BACKEND_URL + "images/companyLogo/" + firstlogoUrl} />
                                                        :
                                                        <img alt="#" className="dashlogo" height="65" style={{ padding: '1%' }} src={require("../../assets/images/uploadLogo.png")} />
                                                    :
                                                    <img alt="#" className="dashlogo" height="65" style={{ padding: '1%' }} src={require("../../assets/images/uploadLogo.png")} />
                                            }
                                            <br />
                                            <span style={{ fontWeight: "bold", fontSize: "1em", }}>Check Your Credit For Free</span>
                                        </p>
                                    </Grid>
                                </Grid>
                            </CardOverflow>
                            <CardContent sx={{ p: 4, borderRadius: '0px 0px 10px 10px', background: `${customColorArray.popupBodyBackground}` }} id="popupBodyBackground">
                                <List
                                    sx={{ width: '100%', bgcolor: 'background.paper' }}
                                    aria-label="contacts"
                                >
                                    <ListItem disablePadding>
                                        <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 0.5 }}>
                                            <TaskAltIcon className="popupIconColor" sx={{ color: `${customColorArray.popupIconColor}` }} />
                                        </ListItemDecorator>
                                        <ListItemContent sx={{ mr: 0.5, }}><Typography level="h6" >
                                            <span className="popupBodyText" style={{ color: `${customColorArray.popupBodyText}` }}>Get Your Free Credit Score</span>
                                        </Typography>
                                        </ListItemContent>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 0.5 }}>
                                            <TaskAltIcon className="popupIconColor" sx={{ color: `${customColorArray.popupIconColor}` }} />
                                        </ListItemDecorator>
                                        <ListItemContent sx={{ mr: 0.5, }}><Typography level="h6" >
                                            <span className="popupBodyText" style={{ color: `${customColorArray.popupBodyText}` }}>Get Your Free Credit Report Summary</span>
                                        </Typography>
                                        </ListItemContent>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 0.5 }}>
                                            <TaskAltIcon className="popupIconColor" sx={{ color: `${customColorArray.popupIconColor}` }} />
                                        </ListItemDecorator>
                                        <ListItemContent sx={{ mr: 0.5, }}><Typography level="h6" >
                                            <span className="popupBodyText" style={{ color: `${customColorArray.popupBodyText}` }}>Get A Credit Consultation</span>
                                        </Typography>
                                        </ListItemContent>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 0.5 }}>
                                            <TaskAltIcon className="popupIconColor" sx={{ color: `${customColorArray.popupIconColor}` }} />
                                        </ListItemDecorator>
                                        <ListItemContent sx={{ mr: 0.5, }}><Typography level="h6" >
                                            <span className="popupBodyText" style={{ color: `${customColorArray.popupBodyText}` }}>No Credit Card Required</span>
                                        </Typography>
                                        </ListItemContent>
                                    </ListItem>
                                </List>

                                <Grid container item xs mt={2} sx={{ display: "flex", justifyContent: "center" }}>
                                    <Button color="warning" id="popupButton" 
                                        sx={{
                                            backgroundColor: `${customColorArray.popupButtonBackground}`,
                                            borderRadius: "3px",
                                            color: `${customColorArray.popupButtonText}`,
                                            minWidth: 200,
                                            '&:hover': {
                                                backgroundColor: `${customColorArray.popupButtonBackground}`
                                            }
                                        }}
                                        size="lg">GET STARTED</Button>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item lg={7} md={7} sm={7} xs={7} >
                        <Typography level="h6" sx={{fontWeight : 'bold'}}>Click Below To Change The Colors</Typography>
                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex", flexDirection: "column", alignItems: "flex-center",marginLeft:"3%",marginRight:"3%" }}>
                            <ContentElement title="Header Background" blockId="popupHeaderBackground" blockName="popupHeaderBackground" colorType="background"
                                value={customColorArray.popupHeaderBackground}
                                customColorArray={customColorArray}
                                handleValue={handleValue}
                                openColorPicker={openColorPicker}
                                toggleColorPicker={toggleColorPicker}
                            />
                            <ContentElement title="Header Text" blockId="popupHeaderText" blockName="popupHeaderText" colorType="text"
                                value={customColorArray.popupHeaderText}
                                customColorArray={customColorArray}
                                handleValue={handleValue}
                                openColorPicker={openColorPicker}
                                toggleColorPicker={toggleColorPicker} />
                            <ContentElement title="Body Background" blockId="popupBodyBackground" blockName="popupBodyBackground" colorType="background"
                                value={customColorArray.popupBodyBackground}
                                customColorArray={customColorArray}
                                handleValue={handleValue} 
                                openColorPicker={openColorPicker}
                                toggleColorPicker={toggleColorPicker} />
                            <ContentElement title="Body Text" blockId="popupBodyText" blockName="popupBodyText" colorType="bodyText"
                                value={customColorArray.popupBodyText}
                                customColorArray={customColorArray}
                                handleValue={handleValue}
                                openColorPicker={openColorPicker}
                                toggleColorPicker={toggleColorPicker}/>
                            <ContentElement title="Button Background" blockId="popupButton" blockName="popupButtonBackground" colorType="background"
                                value={customColorArray.popupButtonBackground}
                                customColorArray={customColorArray}
                                handleValue={handleValue} 
                                openColorPicker={openColorPicker}
                                toggleColorPicker={toggleColorPicker}/>
                            <ContentElement title="Button Text" blockId="popupButton" blockName="popupButtonText" colorType="text"
                                value={customColorArray.popupButtonText}
                                customColorArray={customColorArray}
                                handleValue={handleValue}  
                                openColorPicker={openColorPicker}
                                toggleColorPicker={toggleColorPicker}/>
                            <ContentElement title="Highlight Color" blockId="popupIconColor" blockName="popupIconColor" colorType="icon"
                                value={customColorArray.popupIconColor}
                                customColorArray={customColorArray}
                                handleValue={handleValue}
                                openColorPicker={openColorPicker}
                                toggleColorPicker={toggleColorPicker}/>
                                 <Grid item lg={12} md={12} sm={12} xs={12} sx={{display:"flex",justifyContent:'center', marginTop : '3%'}}>
                                    <Grid item lg={6} md={6} sm={6} xs={6} >
                                        <Button color="primary" variant="outlined" className="CustomButton" sx={{  minWidth: '310px', color: "blue" }}
                                                onClick={handleClickOpen2}
                                            >
                                                SEE PREVIEW
                                            </Button>
                                         </Grid>
                                         <Grid item lg={6} md={6} sm={6} xs={6} >
                                         <Button color="primary" 
                                          className="CustomButton"
                                            sx={{  minWidth: '310px',  color: "white" }}
                                            onClick={savePopupAttribute}
                                        >SAVE</Button>
                                        </Grid>
                                </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            
                {/* <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                        <Button color="primary" sx={{ borderRadius: "0% !important", minWidth: 250, backgroundColor: "#2A68B3", color: "white", 
                      }}
                            onClick={handleResetColors} 
                        >Reset</Button>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6} sx={{ textAlign: "right" }}>
                        <Button color="success"
                            sx={{ borderRadius: "0% !important", minWidth: 250, backgroundColor: "#76C044", color: "white" }}
                            onClick={savePopupAttribute}
                        >Save</Button>
                    </Grid>
                </Grid> */}
            
                  {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{marginTop:"2%"}}>
                    
                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: 'center', justifyContent: 'center' }}>
                            <Grid item onClick={handleClickOpen2}>
                                <PopupPreview customColorArray={customColorArray} creditinfo={creditinfo} firstlogoUrl={firstlogoUrl} previewPopup={false} />
                            </Grid>
                        </Grid>
                      
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} spacing={2}>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                {user.roles_id <= 3 ?
                                    <Button sx={{
                                        minWidth: 200, bgcolor: '#2A68B3',
                                        borderColor: '#2A68B3', borderRadius: "2px"
                                    }}
                                        onClick={handleClickOpen1}
                                    >
                                        Customize Pop-up
                                    </Button>
                                    : null}
                            </Grid>
                            <Grid item xs sx={{ textAlign: "right" }}>

                                <Button color="success" sx={{ borderRadius: "0% !important", backgroundColor: "#76C044", minWidth: 200, }}
                                    onClick={handleClickOpen2}
                                >
                                    Show Preview
                                </Button>

                            </Grid>
                        </Grid>
                   
                  </Grid> */}
                </CardContent>
              </Card>
            </Grid>
            </>
            : <>  <Typography level="h5"><b>Complete "Setup Credit Snapshot" Step First</b></Typography></>}
        <>
          {firstlogoUrl && receivedpid ?
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={2}>
                {/* <Grid item xl={6} lg={6} md={12} sm={12} xs={12}> */}
                    <Card className='cardstyle' sx={{width:"100%"}} >
                        
                            <Typography level="h5">Your snippet code to add pop-up to your website</Typography>
                      
                        <CardContent>
                            <p style={{wordBreak: "break-all"}}>
                               
                                {snapshotStr}
                            </p>
                            {user.roles_id >= 3 && user.roles_id <= 5 ?
                                <Grid item>
                                 <Button sx={{ minWidth: 150 }}
                                  className="CustomButton" 
                                 onClick={() => {
                                     navigator.clipboard.writeText(snapshotStr)
                                     setSnippetCopy(snapshotStr)
                                     setTimeout(() => {
                                         setSnippetCopy('Copy');
                                     }, 3000);
                                 }} >{snippetCopy == snapshotStr ? "COPIED!" : 'COPY'}
                                 </Button>
                                </Grid>
                                : null}
                        </CardContent>
                    </Card>
                {/* </Grid> */}
            </Grid>
            : null}
        </>
        <Dialog
            fullWidth={fullWidth}
            maxWidth={'md'}
            open={open1}
            onClose={handleClose1}
        >
            <DialogTitle>
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                        <Typography level="h4">Customize And Set Up Your Pop-up For Your Website</Typography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6} sx={{ textAlign: "right" }}>
                        {/* <IconButton
                            aria-label="close"
                            onClick={handleClose1}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton> */}
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3} >
                    <Grid item lg={7} md={7} sm={7} xs={7} >
                        <Card sx={{ padding: 0 }} variant="outlined" >
                            <CardOverflow
                                sx={{
                                    bgcolor: `${customColorArray.popupHeaderBackground}`,
                                    borderColor: `${customColorArray.popupHeaderBackground}`,
                                    p: 3,
                                    margin: 0
                                }}
                                id="popupHeaderBackground"
                            >
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center", textAlign: 'center' }} >
                                        <p style={{ margin: 0, color: `${customColorArray.popupHeaderText}`, fontSize: "1.5em",fontWeight:'bold' }} id="popupHeaderText">
                                            You Can Improve Your Credit Score
                                            <br />
                                            {
                                                creditinfo
                                                    ?
                                                    firstlogoUrl
                                                        ?
                                                        <img alt="#" className="dashlogo" height="65" style={{ padding: '1%' }} src={config.BACKEND_URL + "images/companyLogo/" + firstlogoUrl} />
                                                        :
                                                        <img alt="#" className="dashlogo" height="65" style={{ padding: '1%' }} src={require("../../assets/images/uploadLogo.png")} />
                                                    :
                                                    <img alt="#" className="dashlogo" height="65" style={{ padding: '1%' }} src={require("../../assets/images/uploadLogo.png")} />
                                            }
                                            <br />
                                            <span style={{ fontWeight: "bold", fontSize: "1em", }}>Check Your Credit For Free</span>
                                        </p>
                                    </Grid>
                                </Grid>
                            </CardOverflow>
                            <CardContent sx={{ p: 4, borderRadius: '0px 0px 10px 10px', background: `${customColorArray.popupBodyBackground}` }} id="popupBodyBackground">
                                <List
                                    sx={{ width: '100%', bgcolor: 'background.paper' }}
                                    aria-label="contacts"
                                >
                                    <ListItem disablePadding>
                                        <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 0.5 }}>
                                            <TaskAltIcon className="popupIconColor" sx={{ color: `${customColorArray.popupIconColor}` }} />
                                        </ListItemDecorator>
                                        <ListItemContent sx={{ mr: 0.5, }}><Typography level="h6" >
                                            <span className="popupBodyText" style={{ color: `${customColorArray.popupBodyText}` }}>Get Your Free Credit Score</span>
                                        </Typography>
                                        </ListItemContent>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 0.5 }}>
                                            <TaskAltIcon className="popupIconColor" sx={{ color: `${customColorArray.popupIconColor}` }} />
                                        </ListItemDecorator>
                                        <ListItemContent sx={{ mr: 0.5, }}><Typography level="h6" >
                                            <span className="popupBodyText" style={{ color: `${customColorArray.popupBodyText}` }}>Get Your Free Credit Report Summary</span>
                                        </Typography>
                                        </ListItemContent>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 0.5 }}>
                                            <TaskAltIcon className="popupIconColor" sx={{ color: `${customColorArray.popupIconColor}` }} />
                                        </ListItemDecorator>
                                        <ListItemContent sx={{ mr: 0.5, }}><Typography level="h6" >
                                            <span className="popupBodyText" style={{ color: `${customColorArray.popupBodyText}` }}>Get A Credit Consultation</span>
                                        </Typography>
                                        </ListItemContent>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 0.5 }}>
                                            <TaskAltIcon className="popupIconColor" sx={{ color: `${customColorArray.popupIconColor}` }} />
                                        </ListItemDecorator>
                                        <ListItemContent sx={{ mr: 0.5, }}><Typography level="h6" >
                                            <span className="popupBodyText" style={{ color: `${customColorArray.popupBodyText}` }}>No Credit Card Required</span>
                                        </Typography>
                                        </ListItemContent>
                                    </ListItem>
                                </List>

                                <Grid container item xs mt={2} sx={{ display: "flex", justifyContent: "center" }}>
                                    <Button color="warning" id="popupButton"  onClick={handleClickOpen2}
                                        sx={{
                                            backgroundColor: `${customColorArray.popupButtonBackground}`,
                                            borderRadius: "3px",
                                            color: `${customColorArray.popupButtonText}`,
                                            minWidth: 200,
                                            '&:hover': {
                                                backgroundColor: `${customColorArray.popupButtonBackground}`
                                            }
                                        }}
                                        size="lg">GET STARTED</Button>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item lg={5} md={5} sm={5} xs={5}>
                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex", flexDirection: "column", alignItems: "flex-center" }}>
                            <ContentElement title="Header Background" blockId="popupHeaderBackground" blockName="popupHeaderBackground" colorType="background"
                                value={customColorArray.popupHeaderBackground}
                                customColorArray={customColorArray}
                                handleValue={handleValue}
                                openColorPicker={openColorPicker}
                                toggleColorPicker={toggleColorPicker}
                            />
                            <ContentElement title="Header Text" blockId="popupHeaderText" blockName="popupHeaderText" colorType="text"
                                value={customColorArray.popupHeaderText}
                                customColorArray={customColorArray}
                                handleValue={handleValue}
                                openColorPicker={openColorPicker}
                                toggleColorPicker={toggleColorPicker} />
                            <ContentElement title="Body Background" blockId="popupBodyBackground" blockName="popupBodyBackground" colorType="background"
                                value={customColorArray.popupBodyBackground}
                                customColorArray={customColorArray}
                                handleValue={handleValue} 
                                openColorPicker={openColorPicker}
                                toggleColorPicker={toggleColorPicker} />
                            <ContentElement title="Body Text" blockId="popupBodyText" blockName="popupBodyText" colorType="bodyText"
                                value={customColorArray.popupBodyText}
                                customColorArray={customColorArray}
                                handleValue={handleValue}
                                openColorPicker={openColorPicker}
                                toggleColorPicker={toggleColorPicker}/>
                            <ContentElement title="Button Background" blockId="popupButton" blockName="popupButtonBackground" colorType="background"
                                value={customColorArray.popupButtonBackground}
                                customColorArray={customColorArray}
                                handleValue={handleValue} 
                                openColorPicker={openColorPicker}
                                toggleColorPicker={toggleColorPicker}/>
                            <ContentElement title="Button Text" blockId="popupButton" blockName="popupButtonText" colorType="text"
                                value={customColorArray.popupButtonText}
                                customColorArray={customColorArray}
                                handleValue={handleValue}  
                                openColorPicker={openColorPicker}
                                toggleColorPicker={toggleColorPicker}/>
                            <ContentElement title="Highlight Color" blockId="popupIconColor" blockName="popupIconColor" colorType="icon"
                                value={customColorArray.popupIconColor}
                                customColorArray={customColorArray}
                                handleValue={handleValue}
                                openColorPicker={openColorPicker}
                                toggleColorPicker={toggleColorPicker}/>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                        {/* <Button color="primary" sx={{ borderRadius: "0% !important", minWidth: 250, backgroundColor: "#2A68B3", color: "white", 
                      }}
                            onClick={handleResetColors} 
                        >Reset</Button> */}
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6} sx={{ textAlign: "right" }}>
                        <Button color="success"
                            sx={{ borderRadius: "0% !important", minWidth: 250, backgroundColor: "#76C044", color: "white" }}
                            onClick={savePopupAttribute}
                        >Save</Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>

          {/* show preview dialog start here */}
          <Dialog
              fullWidth={fullWidth}
              open={open2}
              onClose={(_, reason) => {
                if (reason !== "backdropClick") {
                  handleClose2();
                }
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <PopupPreview customColorArray={customColorArray} creditinfo={creditinfo} firstlogoUrl={firstlogoUrl} handleClose={handleClose2}
                handleOpen={handlePopupPreview}
                previewPopup={true} link={link} 
            />
            
          </Dialog>
        </>

        
        
    );
}

export default SnapshotPopupSetUp;