import React, { useMemo, useState, useEffect } from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import AuthUser from '../../../Components/Auth/AuthUser';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader'
//MRT Imports
import MaterialReactTable from 'material-react-table';
import Moment from 'moment';
import swal from "sweetalert";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import { Typography,Box, Grid, Button, Tooltip,DialogContent, DialogActions, DialogContentText, Dialog, DialogTitle,Stack,Switch } from '@mui/material';
import { useTheme,styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/CheckBox';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import EnrollIcon from '@mui/icons-material/NotStartedOutlined';
import ReSendIcon from '@mui/icons-material/SendToMobileOutlined';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined';
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import HistoryIcon from "@mui/icons-material/History";
import IconButton from '@mui/material/IconButton';
import UpdateBankForm from "../CommonComponent/BankDetail/UpdateBankForm";
import UserForm from "../CommonComponent/User/UserForm";
import CloseIcon from "@mui/icons-material/Close";
import formatPhoneNumber from '../../../Components/Formats/PhoneNumberValidation';

let htmlData ='';
const AdminCompanyDetail = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const navigate = useNavigate();
    const { user } = AuthUser();
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [medWidth, setMedWidth] = React.useState('md');
    const [filterCompany, setFilterCompany] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [type, setType] = useState("active");
    const [mode, setMode] = useState("create");
    const [openUser, setOpenUser] = useState(false);
    const [userData, setUserData] = useState(null);
    const [open, setOpen] = useState(false); 
    const [bank_details, setBankDetails] = useState([]);
    const [updatedAttributes, setUpdatedAttributes] = useState([]);
    const [disableOperation, setDisableOperation] = useState("");
    const [operation, setOperation] = useState("Update");
    const [btntext, setBtntext] = useState("Update");
    const [bankName, setBankName] = useState("");
    const [currentDate, setCurrentDate] = useState("");
    const [routingNumber, setRoutingNumber] = useState("");
    const [flag, setFlag] = useState(true);
    const [routingNumberError, setRoutingNumberError] = useState(true);
    const [routingNumberPreviousValue, setRoutingNumberPreviousValue] = useState(false);
    const [viewBankHistory, setViewBankHistory] = useState([]);
    const [filterhistory, setFilterHistory] = useState([]);
    const [companyId, setCompanyId] = useState("");
    const [openCompanyDisable, setOpenCompanyDisable] = React.useState(false);
    const [checkUsaTldStatus, setCheckUsaTldStatus] = useState(false);
    const [userTypeList, setUserTypeList] = useState([]);
    const [aidNames, setAidNames] = useState([]);
    const [openbank, setOpenbank] = useState(false);
    const [companyName, setCompanyName] = useState("");
    
    const handlebankOpen = (id,companyname) => {
        setCompanyName(companyname);
        setCompanyId(id);
        fetchCompanyBankDetails(id);
        handleFreezStart();
        setTimeout(() => {
            handleFreezStop();
            setOpenbank(true);
        }, 3000); 
    }
    const handlebankClose = () =>{
        setOpenbank(false);
    } 
    const loginUser = JSON.parse(localStorage.getItem('user'));
    const handleCloseOfDisableCompany = () => {
        setOpenCompanyDisable(false);
    };
    const handleOpenUpdate = (id) => {
        fetchCompanyBankDetails(id);
        handleFreezStart();
        setTimeout(() => {
            handleFreezStop();
            setOpen(true);
        }, 4000); 
    };
    const handleClose = () => {
        handleFreezStart();
        setBankName(""); 
        setTimeout(() => {
            handleFreezStop();
            setOpen(false);
        }, 3000);
        
    }
    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    

    useEffect(() => {
        if(user.roles_id < 3) {
            const myDate = new Date();
            let cDate = Moment(myDate).tz("America/Los_Angeles").format("MM-DD-YY");
            setCurrentDate(cDate); 
            fetchCompanyList(type);
            fetchUserTypes();
        }else{
          navigate('/login');
        }
    }, [])
    
    const checkUsaTLD =async (email) => {
        if (email.includes('@')) {
            try {
            const apiUrl = '/checkUsaTLD';
            const args = {
                email: email,
            }
            const res = await dispatch(postData({apiUrl,args})).unwrap();
            if (res && res.data && typeof res.data.success === 'boolean') {
                setCheckUsaTldStatus(res.data.success);
            } else {
                // Handle unexpected response structure
                console.warn("Unexpected response structure:", res);
                setCheckUsaTldStatus(false);
            }
        } catch (error) {
                // Handle errors from the API call
                console.error("Error checking USA TLD:", error);
                setCheckUsaTldStatus(false);
            }
        }
    }
    const fetchCompanyList = async (companyType) => {
        handleFreezStart(); 
        setIsLoading(true); 
        try {
            const apiUrl = `/fetchCompanyList/${companyType}`;
            const res = await dispatch(fetchData(apiUrl)).unwrap();
            if (res) {
                setFilterCompany(res);
                setIsLoading(false); 
                handleFreezStop();
            }else{
                setIsLoading(false); 
                handleFreezStop();
            }
        } catch (error) {
            console.error("Error fetching company list:", error);
            setIsLoading(false); 
            handleFreezStop();
        } 
    };

    const fetchCompanyBankDetails = async (id) => {
        handleFreezStart();
        const apiUrl = '/fetch-company-bank-details/' + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            if (res.companyBankDetails && res.companyBankDetails.company_master_id && res.companyBankDetails.company_master_id != null) {
                setBankDetails(res.companyBankDetails);
                setUpdatedAttributes(res.companyBankDetails.updated_attributes);
                    htmlData = `
                    <p>Dear ${res.companyBankDetails.company_name},</p>
                    <p>The bank details you provided could not be verified with your bank. Please login to your affiliate account and update your account information in bank details.</p>
                    <p>If you have any questions or if there is anything we can do to help you, please feel free to contact us:</p>
                    <p>info@myfreescorenow.com</p>
                    <p>888-548-2008</p>
                    <p>Thank You!</p>`;
                    setBtntext(res.companyBankDetails.report_issue == 1 ? (user.roles_id < 3 ? "Update And Resolve" : "Update") : "Update");
                    setOperation("Update");
                    if (res.companyBankDetails.routing_number) {
                        let routingNumberOriginal = res.companyBankDetails.routing_number;
                        let routingNumberUpdated = res.companyBankDetails.routing_number_updated;
                        let bankNameOriginal = res.companyBankDetails.bank_name;
                        const apiUrl = `/bankname/${routingNumberOriginal}`;
                        if (routingNumberOriginal.length === 9) {
                            try {
                                const res = await dispatch(fetchData(apiUrl)).unwrap();
                                if (res.success) {
                                    setBankName(res.data);
                                    setRoutingNumberError(true);
                                    if(routingNumberUpdated == 1) {
                                        setRoutingNumberError(true);
                                        setRoutingNumber(routingNumberOriginal);
                                        setBankName(bankNameOriginal);
                                        setRoutingNumberPreviousValue(true);
                                    }
                                } else {
                                    setBankName(bankNameOriginal);
                                    if (routingNumberUpdated == 1) {
                                        setRoutingNumberError(true);
                                        setRoutingNumber(routingNumberOriginal);
                                        setRoutingNumberPreviousValue(true);
                                    } else {
                                        setRoutingNumberError(false);
                                    }
                                }
                            } catch (error) {
                                console.error("Error fetching bank details:", error);
                            }
                        }
                    }
                setFilterHistory(Object.values(res.updatedAllAttributes));
                handleFreezStop();
            } else {
                handleFreezStop();
            }
        }
    };

    const checkAdmin = () => {
        var check = 1;
        if (loginUser.roles_id != 1 && loginUser.roles_id != 2 && loginUser.roles_id != 3 && loginUser.roles_id != 9) {
            check = 0;
        } 
        if(check == 0) {
            navigate('/login');
            return false;
        }
        return true;
    }

    const handleSubmit = async (values) => {
        var check = checkAdmin();
        if(check === true) {
            const loginUser = JSON.parse(localStorage.getItem('user'));
            if(loginUser.roles_id <= 3 ) {
                if(loginUser.roles_id == 3 && loginUser.company_master_id != values.companyID) {
                    swal({
                        title: "Failed",
                        text: "This action is unauthorized.",
                        icon: "error",
                        button: "Ok",
                    })
                    navigate('/login');
                    return false;
                }
            }
            if(checkUsaTldStatus == true) {
                setFlag(false);
            }else if ((values.UserType == 3 || values.UserType == 4 || values.UserType == 5)) {
                if(values.UserType != 7){
                    swal({
                        title: "Failed",
                        text: "Plese Select Company",
                        icon: "error",
                        button: "Ok",
                    })
                    setFlag(false);
                }
            }else if ((values.UserType == 3 || values.UserType == 4 || values.UserType == 5) && ( values.Aidarray.length == 0)) {
                    swal({
                        title: "Failed",
                        text: "Plese Select AID",
                        icon: "error",
                        button: "Ok",
                    })
                    setFlag(false);
            } else{
                setFlag(true);
            }
            if (flag) {
                handleFreezStart();
                const apiUrl = '/createUser';
                const args = {
                    first_name: values.firstName,
                    last_name: values.lastName,
                    role_id: values.userType,
                    email: values.email,
                    phone_no: formatPhoneNumber(values.phone),
                    password: values.password,
                    aids: values.userAidList,
                    defaultTimezone: values.timezone,
                    company_id: values.companyID,
                    softwaretype: values.softwaretype,
                }
                const res = await dispatch(postData({apiUrl,args})).unwrap();
                if(res)
                {
                    if (res.success === true) {
                        swal({
                            title: "Success",
                            text: res.message,
                            icon: "success",
                            button: "Ok",
                        });
                        fetchCompanyList(type);
                        handleCloseUser();
                        handleFreezStop();
                    } else {
                        swal({
                            title: "Failed",
                            text: "Email Already Exist",
                            icon: "error",
                            button: "Ok",
                        })
                        handleCloseUser();
                        handleFreezStop(); 
                    }
                }else
                {
                    swal({
                        title: "Failed",
                        text: "Something went Wrong",
                        icon: "error",
                        button: "Ok",
                    })
                    handleCloseUser();
                    handleFreezStop();
                }
            }
        }
        
    };

    const handleSwitchChange = (event) => {
        const newType = event.target.checked ? "active" : "all";
        setType(newType);
        fetchCompanyList(newType); 
    };

    const handleDisable = (id, status) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id  > 2) {
          navigate('/login');
          return false;
        }
        if (status == "disabled") {
            setDisableOperation("disabled");
        } else {
            setDisableOperation("active");
        }
        setCompanyId(id);
        setOpenCompanyDisable(true);
    };

    const enableDisableCompany = async () => {
        setOpenCompanyDisable(false);
        const apiUrl = '/change-company-disable-status';
        const args = {
          id: companyId,
          requestType: disableOperation,
        }
    
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if (res) {
          if (res.success === true) {
            swal({
              title: "Success",
              text: res.message,
              icon: "success",
              button: "Ok",
            })
          } else {
            swal({
              title: "Failed",
              text: res.message,
              icon: "error",
              button: "Ok",
            })
          }
        }
        fetchCompanyList(type);
    }

    const checkbankdetailsfollowup = (compnyId) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
            if(loginUser.roles_id  > 2) {
                navigate('/login');
                return false;
            }
        navigate('/checkbankdetailsfollowup/' + compnyId,{ state: { flag: true } }) ;
    }

    const fetchUserTypes = async() => {
        handleFreezStart();
        const apiUrl = '/fetchUserTypes';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res) {
            const filteredUsers = res.filter(user => [3, 4, 5, 11].includes(user.id));
            setUserTypeList(filteredUsers);
            handleFreezStop();
        }
    }

    const handleOpenCreate = (id,companyName,aid) => {
        setAidNames([aid]);
        setMode("create");
        setUserData({
            companyId: id,
            companyName: companyName,
            userType: "",
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            userAidList: [],
            timezone: "",
            password: "",
        });
        handleFreezStart();
        setTimeout(() => {
            handleFreezStop();
            setOpenUser(true);
        }, 3000); 
        
    };
    const handleCloseUser = () => setOpenUser(false);

    const columns = [
        {
            accessorKey: "company_master_id",
            header: "Company ID",
            size: 150,
            enableSorting: true, // ✅ Enable sorting explicitly
        },
        { accessorKey: "company_name", header: "Company Name", size: 150 ,
            Cell: ({ row }) => (
                <Box display="flex" alignItems="center">
                    <Typography variant="body2" >
                        {row.original.company_name}
                    </Typography>
                </Box>
            ),
        },
        {
            accessorFn: (row) => `${row.account_active_flag} ${row.account_flag} ${row.ach_info_added_to_bank}`,
            id: 'bankDetails',
            header: 'Bank Details',
            size: 150,
            Cell: ({ row }) => (
                <Box display="flex" flexDirection="column" gap={1}>
                    {/* Submitted Status */}
                    <Box display="flex" alignItems="center" justifyContent="space-between" >
                        <Typography variant="body2">Submitted</Typography>
                        <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
                            {row.original.bankDetailsFlag === "green" ? (
                                <Tooltip title="Bank Details Confirmed" arrow>
                                    <CheckIcon color="success" />
                                </Tooltip>
                            ) : row.original.bankDetailsFlag === "gray" ? (
                                <Tooltip title="Bank Details Not Submitted" arrow>
                                    <CheckIcon color="disabled" />
                                </Tooltip>
                            ) : row.original.bankDetailsFlag === "red" ? (
                                <Tooltip title="Incorrect Bank Details" arrow>
                                    <CheckIcon htmlColor="red" />
                                </Tooltip>
                            ) : row.original.bankDetailsFlag === "blue" ? (
                                <Tooltip title={`Bank Details Updated on ${Moment(row.original.updated_at).format('MM-DD-YYYY')}`} arrow>
                                    <CheckIcon htmlColor="gold" />
                                </Tooltip>
                            ) : (
                                // Default else case: "Bank Details Not Submitted"
                                <Tooltip title="Bank Details Not Submitted." arrow>
                                    <CheckIcon color="disabled" />
                                </Tooltip>
                            )}
                        </Box>
                    </Box>
                    {/* ACH Added Status */}
                    <Box display="flex" alignItems="center" justifyContent="space-between" >
                        <Typography variant="body2">ACH Added</Typography>
                        <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
                            {row.original.achDetailsFlag === "green" && (
                                <Tooltip title="ACH Updated" arrow>
                                    <CheckIcon color="success" />
                                </Tooltip>
                            )}
                            {row.original.achDetailsFlag === "grey" && (
                                <Tooltip title="ACH Not Added" arrow>
                                    <CheckIcon color="disabled" />
                                </Tooltip>
                            )}
                        </Box>
                    </Box>
                    {/* Actions */}
                    <Box display="flex" alignItems="center" justifyContent="space-between" >
                        <Typography variant="body2">Action</Typography>
                        <Box display="flex" alignItems="center" gap={1}>
                            {row.original.account_flag === "Added" &&
                            row.original.account_active_flag === "true" &&
                            row.original.ach_info_added_to_bank === "true" ? (
                                <Tooltip title="View" onClick={() => {  handlebankOpen(row.original.company_master_id,row.original.company_name); }} arrow>
                                    <VisibilityIcon />
                                </Tooltip>
                            ) : (
                                <>
                                    <Tooltip title="Edit" arrow>
                                        <EditIcon color="primary" onClick={() => {
                                            handleOpenUpdate(row.original.company_master_id); 
                                        }}/>
                                    </Tooltip>
                                    <Tooltip title="View" onClick={() => {  handlebankOpen(row.original.company_master_id,row.original.company_name); }} arrow>
                                        <VisibilityIcon color="primary" />
                                    </Tooltip>
                                </>
                            )}
                        </Box>
                    </Box>
                </Box>
            ),
        },
        {
            accessorFn: (row) => `${row.referral_flag} ${row.snapshot_flag} ${row.is_disabled}`,
            id: 'enableFeature',
            header: 'Enable Feature',
            size: 150,
            Cell: ({ row }) => (
                <Box display="flex" flexDirection="column" gap={1}>
                    {/* Referral Program */}
                    <Box display="flex" justifyContent="space-between" >
                        <Typography variant="body2">Referral</Typography>
                        <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
                            {row.original.referral_flag == 1 && row.original.referral_terms_and_conditions == 1 ? (
                                <Tooltip title="Terms and Conditions Accepted" arrow>
                                    <CheckIcon color="success" />
                                </Tooltip>
                            ) : row.original.referral_flag == 1 ? (
                                <Tooltip title="Not Yet Accepted" arrow>
                                    <CheckIcon color="disabled" />
                                </Tooltip>
                            ) : row.original.referral_flag == 0 && row.original.referral_enrolled_date == "null" ? (
                                <Tooltip title="Enroll Referral" arrow>
                                    <EnrollIcon color="primary" />
                                </Tooltip>
                            ) : (
                                <Tooltip title="Resend Referral Invitation" arrow>
                                    <ReSendIcon htmlColor="orange" />
                                </Tooltip>
                            )}
                        </Box>
                    </Box>
                    {/* CreditSnapShot */}
                    <Box display="flex" justifyContent="space-between" >
                        <Typography variant="body2">CreditSnapShot</Typography>
                        <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
                            {row.original.snapshot_status == "2" ? (
                                <Tooltip title="Terms and Conditions Accepted" arrow>
                                    <CheckIcon color="success" />
                                </Tooltip>
                            ) : (
                                <Tooltip title="Not Yet Accepted" arrow>
                                    <CheckIcon color="disabled" />
                                </Tooltip>
                            )}
                        </Box>
                    </Box>
                    
                </Box>
            ),
        },
        {
            accessorFn: (row) => `${row.PID != null ?  row.PID : "N/A"}`, 
            id: 'PID', 
            header: 'PID',
            size: 150,
            Cell: ({ row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem', 
                        whiteSpace: 'nowrap', 
                        overflow: 'hidden',
                        textOverflow: 'ellipsis', 
                    }}
                >{row.original.PID != null ? row.original.PID : "N/A"}
                </Box>
            ),
        },
        {
            accessorFn: (row) => `${row.aid != null ? row.aid : "N/A"}`,
            id: 'aid', 
            header: ' Aid List',
            size: 150, 
            Cell: ({ row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem',
                        whiteSpace: 'nowrap', 
                        overflow: 'hidden',
                        textOverflow: 'ellipsis', 
                    }}
                >
                    {row.original.aid?row.original.aid:"N/A" }
                </Box>
            ),
        },
        {
            accessorKey: "action",
            header: "Action",
            size: 150,
            enableSorting: false,
            enableColumnFilter: false,
            Cell: ({ row }) => (
                <Box display="flex" flexDirection="column" >
                    <Box display="flex" alignItems="center" gap={3}>
                        <Tooltip title="Create User">
                            <IconButton 
                                onClick={() => handleOpenCreate(row.original.company_master_id, row.original.company_name, row.original.aid)} 
                                disabled={row.original.is_disabled == 1}
                            >
                                <PersonAddIcon color="success" />
                                <Typography variant="body2" ml={1}>Create User</Typography>
                            </IconButton>
                        </Tooltip>
                        {row.original.is_disabled ? (
                            <Tooltip title="Activate Company" arrow>
                                <IconButton onClick={() => handleDisable(row.original.company_master_id, 'active')}>
                                    <PersonAddAltIcon color="success" />
                                    <Typography variant="body2" ml={1}>Activate</Typography>
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Deactivate Company" arrow>
                                <IconButton onClick={() => handleDisable(row.original.company_master_id, 'disabled')}>
                                    <PersonRemoveOutlinedIcon color="success" />
                                    <Typography variant="body2" ml={1}>Deactivate</Typography>
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                    
                    {/* Activate/Deactivate & See Activity in One Line */}
                    <Box display="flex" alignItems="center" gap={3}>
                        <Tooltip title="See Details">
                            <IconButton 
                                onClick={() => navigate(`/companydetail/${row.original.company_master_id}`, { state: { flag: true } })} 
                                disabled={row.original.is_disabled == 1}
                            >
                                <VisibilityIcon color="primary" />
                                <Typography variant="body2" ml={1}>See Details</Typography>
                            </IconButton>
                        </Tooltip>
                        {row.original.bankDetailsFlag === "red" && (
                            <Tooltip title="See Activity">
                                <IconButton 
                                    onClick={() => checkbankdetailsfollowup(row.original.company_master_id)} 
                                    disabled={row.original.is_disabled == 1}
                                >
                                    <HistoryIcon color="action" />
                                    <Typography variant="body2" ml={1}>See Activity</Typography>
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                </Box>
            ),
        },
          
    ];

    const ContentLayout =
    <>
        <Box sx={{ padding: 3 }}>
            <Typography variant="h5" fontWeight="bold">
                Company Details
            </Typography>
            <Grid item mt={2}>
                <Stack direction="row" alignItems="center">
                    <Typography variant="h6"  gutterBottom>
                        Show All Companies
                    </Typography>
                    <Switch
                        checked={type === "active"}
                        onChange={handleSwitchChange}
                        inputProps={{ 'aria-label': 'Switch API Access' }}
                    />
                    <Typography variant="h6"  gutterBottom>
                        Show Active Companies
                    </Typography>
                </Stack>
            </Grid>

            <Box sx={{ width: "100%" }}>
                <MaterialReactTable
                    columns={columns}
                    data={filterCompany}
                    enablePagination
                    enableColumnFilters
                    enableSorting
                    enableGlobalFilter
                    enableRowSelection={false}
                    initialState={{ showColumnFilters: false, density: 'compact', columnVisibility: { company_master_id: false },
                        sorting: [
                        {
                            id: 'company_master_id',
                            desc: true
                        },
                        ] 
                    }}
                    state={{ isLoading:isLoading }}
                    muiTablePaperProps={{
                        sx: { backgroundColor: "white" },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            backgroundColor: "#2A68B3",
                            color: "white",
                            fontWeight: "bold",
                        },
                    }}
                    muiTableBodyCellProps={({ row, column }) => {
                        const columnIndex = column.id;
                        const isDisabled = row && row.original.is_disabled == 1;
                        return {
                            sx: {
                                border: "1px solid #ccc",
                                padding: "8px",
                                opacity: columnIndex !== "action" && isDisabled ? 0.6 : 1,
                                pointerEvents: columnIndex !== "action" && isDisabled ? "none" : "auto",
                            },
                        };
                    }}
                    muiTableBodyRowProps={({ row }) => ({
                        sx: { backgroundColor: row.index % 2 === 0 ? "white" : "#EBF4FF" },
                    })}
                    renderTopToolbarCustomActions={({ table }) => (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between', // Keeps the layout consistent
                                gap: '1rem',
                                p: '0.5rem',
                                flexWrap: 'wrap',
                                width: '100%',
                            }}
                        >
                            {/* <Button
                                sx={{ bgColor: "#2A68B3" }}
                                disabled={table.getPrePaginationRowModel().rows.length === 0}
                                onClick={() =>
                                handleExportRows(table.getPrePaginationRowModel().rows)
                                }
                                startIcon={<FileDownloadIcon />}
                                variant="contained"
                                className='export'
                            >
                                CSV
                            </Button> */}
                        </Box>
                    )
                    }
                    renderDetailPanel={({ row }) =>
                    (
                        <Box
                            sx={{
                                p: 1,
                            }}
                        >
                            <Grid container spacing={1} sx={{ marginBottom: 3 }}>
                                <Grid item xs={3}>
                                    <Typography variant="body2" >Company Id :</Typography>
                                    <Typography variant="subtitle1" fontWeight="bold" className="common-value">{row.original.company_master_id}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="body2" >Business Start Year :</Typography>
                                    <Typography variant="subtitle1" fontWeight="bold" className="common-value">{row.original.business_started_year ? row.original.business_started_year : "Not Available"}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="body2" >Company Website :</Typography>
                                    <Typography variant="subtitle1" fontWeight="bold" className="common-value">{row.original.company_website ? row.original.company_website : "Not Available"}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="body2" >Communication Email :</Typography>
                                    <Typography variant="subtitle1" fontWeight="bold" className="common-value">{row.original.communication_email}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="body2" >Approval Date :</Typography>
                                    <Typography variant="subtitle1" fontWeight="bold" className="common-value">{row.original.approved_date? Moment(row.original.approved_date).format('MM-DD-YYYY') : 'N/A'}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="body2" >Referral Code :</Typography>
                                    <Typography variant="subtitle1" fontWeight="bold" className="common-value">{row.original.referral_code}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="body2" >Referred By :</Typography>
                                    <Typography variant="subtitle1" fontWeight="bold" className="common-value">{row.original.referred_by?row.original.referred_by:"N/A" }</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="body2" >Agreement Id :</Typography>
                                    <Typography variant="subtitle1" fontWeight="bold" className="common-value">{row.original.pandadoc_id ? row.original.pandadoc_id : 'N/A'}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                />
            </Box>
            
            {/* Update Bank Form Popup */}
            <UpdateBankForm open1={open} handleClose={handleClose} bankDetails={bank_details} updatedAttributes={updatedAttributes} filterhistory={filterhistory} fetchCompanyBankDetails={fetchCompanyBankDetails} btntext={btntext} htmlData={htmlData} currentDate={currentDate} companyName={bank_details.company_name} operation={operation} bankName={bankName} routingNumberErr={routingNumberError} routingNumberOriginal={routingNumber} routingNumberPreviousValue={routingNumberPreviousValue} handleFreezStart={handleFreezStart} handleFreezStop={handleFreezStop} fetchCompanyDetails={() => fetchCompanyList(type)}/>
            
            {/* View Bank Form */}
            <Dialog
                open={openbank}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                        handlebankClose();
                    }
                }}
                fullWidth={fullWidth}
                maxWidth={medWidth}
                PaperProps={{
                    sx: {
                        borderRadius: "12px",
                        padding: "2px"
                    }
                }}
            >
                <DialogTitle className="dialog-title" >
                    <Typography variant="h6" fontWeight="bold">{companyName} 's Bank Details</Typography>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <IconButton onClick={handlebankClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>

                <DialogContent>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                        <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                            <Grid item xs={3}>
                                <Typography variant="body2" >Bank Name :</Typography>
                                <Typography variant="subtitle1" fontWeight="bold" className="common-value">{bank_details.bank_name ? bank_details.bank_name : "N/A"}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" >Account Number :</Typography>
                                <Typography variant="subtitle1" fontWeight="bold" className="common-value">{bank_details.account_number ? bank_details.account_number : "N/A"}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" >Name on Account :</Typography>
                                <Typography variant="subtitle1" fontWeight="bold" className="common-value">{bank_details.name_on_account ? bank_details.name_on_account.replace(':', ' ') : "N/A"}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2">Bank Routing Number :</Typography>
                                <Typography variant="subtitle1" fontWeight="bold" className="common-value">{bank_details.routing_number ? bank_details.routing_number : "N/A"}</Typography>
                            </Grid>
                        </Grid>
                        {/* Row 2 */}
                        <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                            <Grid item xs={3}>
                                <Typography variant="body2" >ACH Added :</Typography>
                                <Typography variant="subtitle1" fontWeight="bold" className="common-value">{bank_details.ach_info_added_to_bank ? bank_details.ach_info_added_to_bank == 1 ? "Added" : "Not Added" : "N/A"}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" >ACH Added On :</Typography>
                                <Typography variant="subtitle1" fontWeight="bold" className="common-value">{bank_details.last_ach_updated ? Moment(bank_details.last_ach_updated).format('MM-DD-YYYY') : "N/A"}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" >ACH Updated On :</Typography>
                                <Typography variant="subtitle1" fontWeight="bold" className="common-value">{bank_details.last_ach_updated ? Moment(bank_details.last_ach_updated).format('MM-DD-YYYY') : "N/A"}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2">Email Address to Notify : </Typography>
                                <Typography variant="subtitle1" fontWeight="bold" className="common-value">{bank_details.email_to_notify ? bank_details.email_to_notify : "N/A"}</Typography>
                            </Grid>
                        </Grid>
                        {/* Row 3 */}
                        <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                            <Grid item xs={3}>
                                <Typography variant="body2">Type Of Account : </Typography>
                                <Typography variant="subtitle1" fontWeight="bold" className="common-value">{bank_details.account_type ? bank_details.account_type : "N/A"}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" >Is Account :</Typography>
                                <Typography variant="subtitle1" fontWeight="bold" className="common-value">{bank_details.account_category ? bank_details.account_category : "N/A"}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
             
            {/* Activate or Deactivate Company */}
            <Dialog
                open={openCompanyDisable}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                        handleCloseOfDisableCompany();
                    }
                }}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                PaperProps={{
                    sx: {
                        borderRadius: "12px",
                        padding: "2px"
                    }
                }}
            >
                <DialogTitle className="dialog-title" >
                    <Typography variant="h6" fontWeight="bold">{disableOperation == "disabled" ? "Deactivate" : "Activate"} Company</Typography>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <IconButton onClick={handleCloseOfDisableCompany}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                        <>
                            Are you sure you want to {disableOperation == "disabled" ? "deactivate" : "activate"} this company?
                        </>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }} spacing={2} mt={1}>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Button variant="contained" onClick={handleCloseOfDisableCompany} className="cancel-button">
                                No
                            </Button>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Button variant="contained" onClick={enableDisableCompany}  className="save-button">
                                Yes
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            {/* Create User */}
            <UserForm
                open={openUser}
                handleClose={handleCloseUser}
                handleSubmit={handleSubmit}
                userData={userData}
                setUserData={setUserData}
                userTypeList={userTypeList}
                names={aidNames}
                mode={mode}
                checkUsaTLD={checkUsaTLD}
            />

        </Box>
    </>
   
    return (
        <div>
            <DefaultLayout content={ContentLayout} />
            {/* loader code start */}
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 10 }}
                    open={freez}
                // onClick={handleFreezStop}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}
        </div>
    );
  
}

export default AdminCompanyDetail; 