
import React, { useMemo, useState, useEffect } from 'react';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import DefaultLayout from '../../../Components/DefaultLayout';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {
  Box, Grid, Button, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Dialog, DialogActions, DialogContent, TextField, Stack, TableContainer, Paper,
  TableHead, TableRow, TableCell, TableBody, FormControlLabel, FormLabel, Checkbox, FormGroup, FormHelperText, Tooltip
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Typography, Card, CardContent, CardOverflow, Table } from '@mui/joy';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import AuthUser from "../../../Components/Auth/AuthUser";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import IconButton from '@mui/joy/IconButton';
import MaterialReactTable from 'material-react-table';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import dayjs from 'dayjs';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import Loader from '../../../Components/Loader/Loader';
import Moment from 'moment';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import CheckIcon from '@mui/icons-material/CheckBoxOutlined';
import config from '../../../AppConfig';
import swal from "sweetalert";
import { useSelector, useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {currencyFormat} from '../../../Components/CommonFormula';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


let RequestFormInitialValues = {
  paymentDetail: '',
  totalCapturePayment: 0,
  additionalPayment: 0,
  paymentDate: dayjs(),
};

let editPaymentValues = {
  id: '',
  amount: '',
  paymentDetails: '',
  paymentDate: '',
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

const captureSchema = Yup.object().shape({
  // totalCapturePayment: Yup.number()
  // .moreThan(0, 'additional commision should not be less than zero'),

  // paymentDetail: Yup.string()
  //   .required('Please enter payment details'),

  paymentDate: Yup.string()
    .required('Please enter Commission'),

  additionalPayment: Yup.number()
  .test('is-decimal', 'Must have up to 2 decimal places', value => {
    if (value === undefined || value === null) return true;
      return /^-?\d+(\.\d{1,2})?$/.test(value.toString());
  }),
    // .moreThan(-1, 'additional commision should not be less than zero'),

})

const editPaymentSchema = Yup.object().shape({
  paymentDetails: Yup.string()
    .required('Please enter payment details'),

  paymentDate: Yup.string()
    .required('Please enter payment date'),
})

function getStyles(name, personName, theme) {

}

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
];

// let totalCapturePayment = 0;

// let captureMonthYear = [];


function PaymentDetails(props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const { state } = useLocation();
  const { id } = useParams();
  const { http, user } = AuthUser();
  const [isLoading, setIsLoading] = useState(true);
  const [totalApprovedCommission, setTotalApprovedCommission] = useState();
  const [totalPaid, setTotalPaid] = useState();
  const [balance, setBalance] = useState();
  const [achAddedToBank, setAchAddedToBank] = useState();
  const [bankDetails, setBankDetails] = useState();
  const [data, setData] = useState();
  const [open, setOpen] = React.useState(false);
  const [commissionSummaryData, setCommissionSummaryData] = useState([]);
  const [companyName, setCompanyName] = useState();
  const [payments, setPayments] = useState([]);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [captureEnable, setCaptureEnable] = React.useState(true);
  const [pendingCommissionData, setPendingCommissions] = useState([]);
  const [balanceMatch, setBalanceMatch] = useState();
  const [currentBalanceMatch, setCurrentBalanceMatch] = useState();
  const [pendings, setPendings] = useState([]);
  const [paymentDetail, setPaymentDetails] = useState();
  const [companyId, setCompanyId] = useState();
  const [currentDate, setCurrentDate] = useState("");
  const isMobile = useMediaQuery('(max-width:600px)');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get('page');
  const pageSize = queryParams.get('pageSize');
  const [paymentNote, setPaymentNote] = useState('');
  const [isSlectAll, setIsSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [totalCapturePayment, setPayment] = useState(0);
  const [finalTotalCapturePayment, setFinalTotalCapturePayment] = useState(0);
  const [captureMonthYear, setCaptureMonthYear] = useState([]);
  const [editPaymentOpen, setEditPaymentOpen] = useState(false);
  const [editPaymentEnable,setEditPaymentEnable] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const navigate = useNavigate();
  const [openLog, setOpenLog] = useState(false);
  const [statusValue, setStatusValue] = useState();
  const [editHistory, setEditHistory] = useState([]);
  const [deleteHistory, setDeleteHistory] = useState([]);
  const [logPopupMaxWidth, setLogPopupMaxWidth] = React.useState('m');
  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value,
    );

    setLogPopupMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value,
    );
  };


  const fetchPaymentViewDetails = async page => {
    const apiUrl = '/payment-details';
    const args = {
      companyId: id,
    }
    const res = await dispatch(postData({apiUrl,args})).unwrap();
    if(res) {
      if (res.success === true) {
        setTotalApprovedCommission(res.totalApprovedCommission);
        setTotalPaid(res.totalPaid);
        setBalance(res.balance);
        setCommissionSummaryData(res.commissionSummaryData);
        setPayments(res.payments ? res.payments : '');
        setCompanyName(res.companyName);
        setBankDetails(res.statusArray.bankStatus);
        setAchAddedToBank(res.statusArray.achAddedToBank);
        setPendings(res.pendingCommissionData);
        setCompanyId(res.companyId);
        var sum = 0;
        res.pendingCommissionData.forEach(subData => sum += parseFloat(subData.monthlyCommission ? subData.monthlyCommission : 0));
        var matchValue = ((parseFloat(res.balance) - parseFloat(sum)).toFixed(2));
        setCurrentBalanceMatch(matchValue);
        setIsLoading(false);
        if (parseFloat(res.balance) > 0) {
          setCaptureEnable(false);

        } else {
          setCaptureEnable(true);
        }

      } else {
        setIsLoading(false);
      }
    }
  }

  
  useEffect(() => {
    if(user.roles_id < 3) {
      fetchPaymentViewDetails();
    }else{
      navigate('/login');
    }
  }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };


  const handleClickOpen = () => {
    
    // totalCapturePayment = 0;
    // paymentNote = '';
    // captureMonthYear = [];
    setPaymentNote('');
    setCompanyId(id);
    setPayment(0);
    setCaptureMonthYear([]);
    setBalance(balance);
    setSelectedItems([]);
    setIsSelectAll(false);
    setPendingCommissions(pendings);
    setBalanceMatch(currentBalanceMatch);
    setFinalTotalCapturePayment(currentBalanceMatch);
    RequestFormInitialValues.additionalPayment = currentBalanceMatch;
    
    const newDate = new Date();
    newDate.setDate(newDate.getDate());
    let todaysDate = Moment(newDate).tz('America/Los_Angeles').format('MM-DD-YY'); 
    setCurrentDate(todaysDate);
    setOpen(true);
  };

  const handleClose = (resetForm) => {
    resetForm();
    setOpen(false);
  };

  const handleClickOpen1 = (id,payDate,paymentDetails,amount) => {
    editPaymentValues.paymentId = id;
    editPaymentValues.paymentDate = payDate;
    editPaymentValues.paymentDetails = paymentDetails;
    editPaymentValues.amount = parseFloat(amount).toFixed(2);
    setEditPaymentOpen(true);
  };

  const handleCloseEditPayment = (resetForm) => {
    resetForm();
    setEditPaymentOpen(false);
  };

  const handleDeletePayment = async (id) => {
		const loginUser = JSON.parse(localStorage.getItem('user'));
		if (loginUser.roles_id >= 3) {
			window.location.href = config.BASE_URL + 'login';
			return false;
		}
		setDeleteId(id)
		setDeleteOpen(true);
	}

  const closeDeletePayment = () => {
		const loginUser = JSON.parse(localStorage.getItem('user'));
		if (loginUser.roles_id >= 3) {
			navigate('/login');
			return false;
		}
		setDeleteOpen(false)
	}

  
  const changeCapturePayment = (month, year, monthlyCommission, event) => {
    var captured = 0;
    var note = paymentNote;
    let updatedSelectedItems = [...selectedItems];
    if(event.target.checked == true) {
      captured = parseFloat(totalCapturePayment) +  parseFloat(monthlyCommission);
      setPayment(parseFloat(captured).toFixed(2));
      setFinalTotalCapturePayment((parseFloat(captured) + parseFloat(balanceMatch)).toFixed(2));
      setCaptureMonthYear(prevArray => [...prevArray, [month,year]]);
      if(paymentNote == '') {
        setPaymentNote( month + ' ' + year);
      }else{
        setPaymentNote( paymentNote + "," + month + ' ' + year);
      }
      updatedSelectedItems.push({ month, year });
    }else{
      var index;
      captured = parseFloat(totalCapturePayment) -  parseFloat(monthlyCommission);
      for(var k = 0; k < captureMonthYear.length; k++) {
        if(captureMonthYear[k][0] == month && captureMonthYear[k][1] == year){
            index = k;
            break;
        }
      }
      setPayment(parseFloat(captured).toFixed(2));
      setFinalTotalCapturePayment((parseFloat(captured) + parseFloat(balanceMatch)).toFixed(2));
      setCaptureMonthYear((monthAndYear) => monthAndYear.filter((_, i) => i !== index));

      let substringToRemove = month + ' ' + year;
      note = note.replace(substringToRemove, '');
      if (note.charAt(note.length - 1) === ',') {
        note = note.slice(0, -1);
        setPaymentNote(note);
      }else{
        setPaymentNote(note);
      }
      updatedSelectedItems = updatedSelectedItems.filter(item => !(item.month === month && item.year === year));
    }
    setSelectedItems(updatedSelectedItems);
    setIsSelectAll(updatedSelectedItems.length === pendingCommissionData.length);
  };
  
  const selectAllFun = (event) => {
    const isChecked = event.target.checked;
    setIsSelectAll(isChecked);
    if(event.target.checked == true) {
      const commissionSum = pendingCommissionData.reduce((total, commission) => {
        return total + commission.monthlyCommission;
      }, 0);
      var note = '';
      note = pendingCommissionData.reduce((paymentNote, commission) => {
        if(paymentNote == '')
          return commission.month + ' ' + commission.year;
        else
          return paymentNote + "," + commission.month + ' ' + commission.year;
      }, 0);
      setPayment(parseFloat(commissionSum).toFixed(2));
      setFinalTotalCapturePayment((parseFloat(commissionSum) + parseFloat(balanceMatch)).toFixed(2));
      setPaymentNote(note);
      const monthYearArray = pendingCommissionData.map(commission => {
       return [commission.month,commission.year]
      });
      setCaptureMonthYear(monthYearArray);
      
    }else{
      setPayment(0);
      setFinalTotalCapturePayment(balanceMatch);
      setPaymentNote('');
      setCaptureMonthYear([]);
    }
    const selected = isChecked ? pendingCommissionData.map(item => ({ month: item.month, year: item.year })) : [];
    setSelectedItems(selected);
    // document.getElementById("inputCapturePayment").value = parseFloat(totalCapturePayment).toFixed(2);
    document.getElementById("paymentDetail").value = paymentNote;
   
  };

  const capturePayment = async(values,resetForm) => {
    if (totalCapturePayment == 0) {
      alert("Please select atleast one month and year");
      return false;
    }
    // if (values.paymentDetail == '') {
    //   alert("Please add payment details");
    //   return false;
    // }
    setCaptureEnable(true);
    setIsLoading(true);
    const date = new Date(values.paymentDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    var mydate = year+"/"+month+"/"+day;
    const apiUrl = '/capture-payment';
    const args = {
      companyId: companyId,
      monthsYears: captureMonthYear,
      paymentDate: mydate,
      totalCapturePayment:totalCapturePayment,
      paymentDetails: paymentNote,
      additionalPayment: values.additionalPayment == "" ? 0 : values.additionalPayment
    }
    const res = await dispatch(postData({apiUrl,args})).unwrap();
    if(res) {
      if (res.success === true) {
        swal({
          title: "Success",
          text: res.message,
          icon: "success",
          button: "Ok",
      })
      resetForm();
      setOpen(false);
      setIsLoading(false);
      fetchPaymentViewDetails();

      // RequestFormInitialValues.paymentDetail = '';
      // RequestFormInitialValues.totalCapturePayment = 0;
      // RequestFormInitialValues.additionalPayment = 0;
      // RequestFormInitialValues.paymentDate = dayjs();

      totalCapturePayment = 0;
      captureMonthYear = [];
     
      } else {
        resetForm();
        setOpen(false);
        swal({
					title: "Failed",
					text: res.message,
					icon: "error",
					button: "Ok",
				})
        if(res.message == "This action is unauthorized.") {
          navigate('/login');
        }else{
          fetchPaymentViewDetails();
          setIsLoading(false);
        }
       
      }
    }
 
    setCaptureEnable(false);
  };

  const editPayment = async(values,resetForm) => {
   
    setEditPaymentEnable(true);
    setIsLoading(true);
    const date = new Date(values.paymentDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    var mydate = year+"/"+month+"/"+day;
    const apiUrl = '/edit-payment';
    const args = {
      paymentId: values.paymentId,
      paymentDate: mydate,
      paymentDetails: values.paymentDetails,
    }
    const res = await dispatch(postData({apiUrl,args})).unwrap();
    if(res) {
      if (res.success === true) {
        swal({
          title: "Success",
          text: res.message,
          icon: "success",
          button: "Ok",
        })
        resetForm();
        setEditPaymentOpen(false);
        setIsLoading(false);
        fetchPaymentViewDetails();
     
      } else {
        resetForm();
        setEditPaymentOpen(false);
        swal({
					title: "Failed",
					text: res.message,
					icon: "error",
					button: "Ok",
				})
        if(res.message == "This action is unauthorized.") {
          navigate('/login');
        }else{
          fetchPaymentViewDetails();
          setIsLoading(false);
        }
      }
    }
    setEditPaymentEnable(false);
  };

  const deletePayment = async() => {
    closeDeletePayment();
    setIsLoading(true);
    const apiUrl = '/delete-payment';
    const args = {
      paymentId: deleteId,
    }
    const res = await dispatch(postData({apiUrl,args})).unwrap();
    if(res) {
      if (res.success === true) {
        swal({
          title: "Success",
          text: res.message,
          icon: "success",
          button: "Ok",
        })
        setIsLoading(false);
        fetchPaymentViewDetails();
     
      } else {
        swal({
					title: "Failed",
					text: res.message,
					icon: "error",
					button: "Ok",
				})
        if(res.message == "This action is unauthorized.") {
          navigate('/login');
        }else{
          fetchPaymentViewDetails();
          setIsLoading(false);
        }
      }
    }
    setDeleteId('');
  };

  const viewDetails = (status,updateLogs,deleteLog) => {
    setStatusValue(status);
    if(status == "Updated") {
      setEditHistory(updateLogs);
    }else{
      setDeleteHistory(deleteLog);
    }
    setOpenLog(true);
  };

  const handleCloseLog = () => {
    setEditHistory([]);
    setOpenLog(false);
  };

  const changePaymentDetails = (e) => {
      setPaymentNote(e.target.value);
  }

  const editLog = useMemo(
    () => [
        {
            accessorFn: (row) => `${Moment(row.created_at).format('MM-DD-YYYY H:mm')}`, //accessorFn used to join multiple data into a single cell
            id: 'updated_at', //id is still required when using accessorFn instead of accessorKey
            header: 'Updated At',
            numeric: true,
            size: 150,
            Cell: ({ renderedCellValue, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <span>{row.original.created_at ? Moment(row.original.created_at).format('MM-DD-YYYY H:mm') : ''}</span>
                </Box>
            ),
        },
        {
            accessorFn: (row) => `${row.edited_by.name}`, //accessorFn used to join multiple data into a single cell
            id: 'updated_by', //id is still required when using accessorFn instead of accessorKey
            header: 'Updated By',
            numeric: true,
            size: 150,
            Cell: ({ renderedCellValue, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <span>{row.original.edited_by ? row.original.edited_by.name : ''}</span>
                </Box>
            ),
        },
        {
          accessorFn: (row) => `${row.payment_details_old}`, 
            id: 'old_payment_details', //id is still required when using accessorFn instead of accessorKey
            header: 'Old Payment Details',
            size: 150,
            Cell: ({ renderedCellValue, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <span>{row.original.payment_details_old}</span>
                </Box>
            ),
        },
        {
          accessorFn: (row) => `${row.payment_details_new}`, 
          id: 'new_payment_details', //id is still required when using accessorFn instead of accessorKey
          header: 'New Payment Details',
          size: 150,
          Cell: ({ renderedCellValue, row }) => (
              <Box
                  sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '1rem',
                  }}
              >
                  <span>{row.original.payment_details_new}</span>
              </Box>
          ),
      },
      {
        accessorFn: (row) => `${Moment(row.payment_date_new).format('MM-DD-YYYY')}`, 
        id: 'new_payment_date', //id is still required when using accessorFn instead of accessorKey
        header: 'New Payment Date',
        size: 150,
        Cell: ({ renderedCellValue, row }) => (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                }}
            >
                <span>{Moment(row.original.payment_date_new).format('MM-DD-YYYY')}</span>
            </Box>
        ),
    },
    {
      accessorFn: (row) => `${row.payment_date_old}`,
      id: 'old_payment_date', //id is still required when using accessorFn instead of accessorKey
      header: 'Old Payment Date',
      size: 150,
      Cell: ({ renderedCellValue, row }) => (
          <Box
              sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
              }}
          >
              <span>{Moment(row.original.payment_date_old).format('MM-DD-YYYY')}</span>
          </Box>
      ),
  },  
       



    ],
    [],
);

  const ChangeFinalTotalPayment = (value) => {
    var amount = value ? parseFloat(value).toFixed(2) : 0;
    setBalanceMatch(amount);
    setFinalTotalCapturePayment((parseFloat(totalCapturePayment) + parseFloat(amount)).toFixed(2));
  } 

  const columns = useMemo(
    //column definitions...
    () => [
      {
        accessorFn: (data) => `${data.month}`,
        id: 'month',
        header: 'Month',
        size: 50,

      },
      {
        accessorFn: (data) => `${data.year}`,  //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: true,
        id: 'year',
        header: 'Year',
        size: 100,
      },
      {
        accessorFn: (data) => parseFloat(`${data.totalMonthlyCommission}`).toFixed(2),
        filterVariant: 'range',
        id: 'monthly_commission',
        header: 'Commission ($)',
        size: 200,
        Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.totalMonthlyCommission))),
        //custom conditional format and styling
      },
      {
        accessorFn: (data) => parseFloat(`${data.referralCommission}`).toFixed(2),
        filterVariant: 'range',
        id: 'referralCommission',
        header: 'Referral Commission ($)',
        size: 200,
        Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.referralCommission))),
      },
      {
        accessorFn: (data) => parseFloat(`${data.bonus}`).toFixed(2),
        filterVariant: 'range',
        id: 'bonus',
        header: 'One Time Referral Bonus ($)',
        size: 200,
        Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.bonus))),
      },
      {
        accessorFn: (data) => parseFloat(`${data.targetBonus}`).toFixed(2),
        filterVariant: 'range',
        id: 'targetBonus',
        header: 'Target Incentive ($)',
        size: 200,
        Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.targetBonus))),
      },
      {
        accessorFn: (data) =>  `${data.fastStartBonus ? (parseFloat(data.fastStartBonus)).toFixed(2) : ""}`,
        filterVariant: 'range',
        numeric: true,
        id:'fast_start_bonus',
        header: 'Fast Start Bonus ($)',
        size: 100,
        Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.fastStartBonus))),
      },
      {
        accessorFn: (data) => parseFloat(`${data.totalCommission}`).toFixed(2),
        filterVariant: 'range',
        id: 'totalCommission',
        header: 'Total Commission ($)',
        size: 200,
        Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.totalCommission))),
      },
      {
        accessorFn: (data) => `${data.approvedDate}`,
        filterVariant: 'range',
        id: 'approved_date',
        header: 'Approved Date',
        size: 100,
        Cell:({renderedCellValue,row}) => (
          <div >
          {Moment(row.original.approvedDate).format('MM-DD-YYYY H:mm')}
          </div>          
          ),
        //custom conditional format and styling
      },
    ]
  )

  const paymentDetailsColumns = useMemo(
    //column definitions...
    () => [
      {
        accessorFn: (data) => `${data.paymentDate}`,
        id: 'payment_date',
        header: 'Payment Date',
        size: 50,
        Cell:({renderedCellValue,row}) => (
          <div >
          {Moment(row.original.paymentDate).format('MM-DD-YYYY')}
          </div>          
          ),
      },
      {
        accessorFn: (data) => `${data.capturedDate}`,
        id: 'captured_date',
        header: 'Captured Date',
        size: 50,
        Cell:({renderedCellValue,row}) => (
          <div >
          {Moment(row.original.capturedDate).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm')}
          </div>          
          ),
      },
      {
        accessorFn: (data) => `${parseFloat(data.totalAmount).toFixed(2)}`,  //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: true,
        id: 'amount',
        header: 'Payment Amount	($)',
        size: 100,
        Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.totalAmount))),
      },
      {
        accessorFn: (data) => `${data.capturedBy}`,
        filterVariant: 'range',
        id: 'captured_by',
        header: 'Captured By',
        size: 100,
        //custom conditional format and styling
      },
      {
        accessorFn: (data) => `${data.status}`,
        filterVariant: 'range',
        id: 'status',
        header: 'Status',
        size: 100,
        Cell: ({ row }) => (
          <Box>
            {
              row.original.status != '' ?
              <>
              {row.original.status}
              <Tooltip title="Details" arrow>
                <Button className='btn btn-sm btn-info text-white'  
                  onClick={()=> viewDetails(`${row.original.status}`, row.original.updateLogs,row.original.deleteLog)} 
                >
                  <img src={require('../../../assets/images/details.png')} />
                </Button>
              </Tooltip>
              </>
              : ''
            }
          </Box>
        ),
      },
      {
        accessorFn: (data) => `${data.id}`,
        filterVariant: 'range',
        id: 'id',
        header: 'Action',
        size: 100,
        Cell: ({ row }) => (
          <Box>
            {
              row.original.status != "Deleted" ?
              <>
              <Tooltip title="Edit" arrow>
                <EditIcon color={'primary'}
                  onClick={() => { handleClickOpen1(`${row.original.id}`, `${row.original.paymentDate}`, `${row.original.paymentDetails}`,`${row.original.totalAmount}`) }}
                /> 
              </Tooltip>
              <Tooltip title="Delete" arrow>
                <DeleteIcon color={'error'}
                  onClick={() => { handleDeletePayment(`${row.original.id}`) }}
              /></Tooltip>
              </>
              :''
            }
           
          </Box>
        ),
      },
    ]
  )
 
  //Approved Commission csv option start here
  const approvedcommissionCombinedData = [...commissionSummaryData];
    const paymentCombinedData = [...paymentDetailsColumns];
    // ... (Approved Commission CSV options start)
    const approvedcommissionCsvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      fileName: 'Approved Commission Details', // Change the file name
      headers: ['Month', 'Year', 
      'Commission ($)', 
      'Referral Commission ($)',
      'One Time Referral Bonus ($)', 
      'Target Incentive ($)', 
      'Total Commission ($)','Approved Date']
  };
  // ... (Approved Commission CSV options end)
  // ... (Payment DEtails CSV options start)
  const paymentdetailsCsvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    fileName: 'Payment Details', // Change the file name
    headers: ['Payment Date',
    'Captured Date',
    'Payment Amount	($)',
    'Captured By',],
};
 // ... (Payment DEtails CSV options end)
 // Define the convertToCSV function start
 const convertToCSV = (data, headers) => {
  const csvContent = headers.join(',') + '\n' +
      data.map((row) => (
          Object.values(row).map((value) => (
              typeof value === 'string' && value.includes(',') ? `"${value}"` : value
          )).join(',')
      )).join('\n');

  return csvContent;
};

// Define the convertToCSV function end

// Define export functions for  Approved Commission  start
const handleExportApprovedCommission = (approvedcommissionCombinedData) => {
  const loginUser = JSON.parse(localStorage.getItem('user'));
  if (loginUser.roles_id >= 3) {
      navigate('/login');
      return false;
  }
  // const exportedData = approvedcommissionCombinedData.map((row) => ({
    
  //   month: `${row.original.month}` || 'N/A',
  //   year: `${row.original.year}` || 'N/A',
  //   totalMonthlyCommission: parseFloat(`${row.original.totalMonthlyCommission}`).toFixed(2),
  //   referralCommission:  parseFloat(`${row.original.referralCommission}`).toFixed(2),
  //   bonus: parseFloat(`${row.original.bonus}`).toFixed(2),
  //   totalCommission: parseFloat(`${row.original.totalCommission}`).toFixed(2),
  //   approvedDate: Moment(row.original.approvedDate).format('MM-DD-YYYY H:mm')
  // }));
  const exportedData = [];
  approvedcommissionCombinedData.forEach((row) => {
    const mainTableRow = {
      month: `${row.original.month}` || 'N/A',
      year: `${row.original.year}` || 'N/A',
      totalMonthlyCommission: parseFloat(`${row.original.totalMonthlyCommission}`).toFixed(2),
      referralCommission:  parseFloat(`${row.original.referralCommission}`).toFixed(2),
      bonus: parseFloat(`${row.original.bonus}`).toFixed(2),
      targetBonus: parseFloat(`${row.original.targetBonus}`).toFixed(2),
      totalCommission: parseFloat(`${row.original.totalCommission}`).toFixed(2),
      approvedDate: Moment(row.original.approvedDate).format('MM-DD-YYYY H:mm')
    }
    exportedData.push(mainTableRow);
    // Add subheaders
    const subheader = {
      'AID': 'AID', // Subheader 1
      'Total Monthly Commission ($)': 'Total Monthly Commission ($)', // Subheader 2
     
    };
    exportedData.push(subheader); 
    if (row.original.aidDetails) {
      row.original.aidDetails.forEach((aidDetail) => {
        const subRow = {
          aid: aidDetail.aid || 'N/A',
          monthlyCommission: parseFloat(aidDetail.monthlyCommission).toFixed(2),
        };
        exportedData.push(subRow);
      });
    } else {
      // If no subrows available, add an empty row for subrows
      exportedData.push({});
    }
  });
 
  // Concatenate subheader rows to the main exportedData array
  const allRows = [...exportedData,];

  // Generate CSV content
  const csvContent = convertToCSV(allRows, approvedcommissionCsvOptions.headers);
  // Create a Blob object from the CSV data
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

  // Create a download link element
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = approvedcommissionCsvOptions.fileName + '.csv';

  // Append the link to the document body
  document.body.appendChild(link);

  // Programmatically click the link to trigger the file download
  link.click();

  // Clean up the temporary URL and remove the link from the document
  URL.revokeObjectURL(link.href);
  link.remove();
};
// Define export functions for  approved commission end

 // Define export functions for  payment details start
 const handleExportPaymentDetails = (paymentCombinedData) => {
  const loginUser = JSON.parse(localStorage.getItem('user'));
  if (loginUser.roles_id >= 3) {
      navigate('/login');
      return false;
  }
  // const exportedData = paymentCombinedData.map((row) => ({
  //     paymentdate:  Moment(row.original.paymentDate).format('MM-DD-YYYY H:mm')||"N/A",
  //     captured_date: Moment(row.original.capturedDate).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm')||"N/A",
  //     payment_amount:`${parseFloat(row.original.totalAmount).toFixed(2)}`||"N/A",
  //     captured_by:`${row.original.capturedBy}` ||"N/A"
      
  // }));
  const exportedData = [];
  paymentCombinedData.forEach((row) => {
    const mainTableRow = {
      paymentdate:  Moment(row.original.paymentDate).format('MM-DD-YYYY H:mm')||"N/A",
      captured_date: Moment(row.original.capturedDate).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm')||"N/A",
      payment_amount:`${parseFloat(row.original.totalAmount).toFixed(2)}`||"N/A",
      captured_by:`${row.original.capturedBy}` ||"N/A"
      
    }
    exportedData.push(mainTableRow);
    // Add subheaders
    if (row.original.paymentArr && Array.isArray(row.original.paymentArr)) {
      row.original.paymentArr.forEach((paymentArr) => {
        const subheader = {
          'Approved Month': 'Approved Month',
          'Approved Year': 'Approved Year',
          'Payment Amount': 'Payment Amount',
        };
        exportedData.push(subheader);

        const subRow = {
          'Approved Month': paymentArr.month,
          'Approved Year': paymentArr.year,
          'Payment Amount': parseFloat(paymentArr.totalCommission).toFixed(2),
        };
        exportedData.push(subRow);

        const aidSubheader = {
          'AID': 'AID',
          'Payment Amount': 'Payment Amount',
        };
        exportedData.push(aidSubheader);

        if (paymentArr.aidArr && Array.isArray(paymentArr.aidArr)) {
          paymentArr.aidArr.forEach((aidArr) => {
            const aidSubRow = {
              'AID': aidArr.aid,
              'Payment Amount': parseFloat(aidArr.paymentAmount).toFixed(2),
            };
            exportedData.push(aidSubRow);
          });
        } else {
          // If no aidArr available, add an empty row for aidArr
          exportedData.push({});
        }
      });
    } else {
      // If no paymentArr available, add an empty row for paymentArr
      exportedData.push({});
    }
  });
  // Concatenate subheader rows to the main exportedData array
  const allRows = [...exportedData];

  // Generate CSV content
  const csvContent = convertToCSV(allRows, paymentdetailsCsvOptions.headers);

  // Create a Blob object from the CSV data
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

  // Create a download link element
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = paymentdetailsCsvOptions.fileName + '.csv';

  // Append the link to the document body
  document.body.appendChild(link);

  // Programmatically click the link to trigger the file download
  link.click();

  // Clean up the temporary URL and remove the link from the document
  URL.revokeObjectURL(link.href);
  link.remove();
};

// Define export functions for  payment details end

  //csv end here

  const ContentLayout=
      <>
        {isLoading ? <Loader /> : <></>}
        <Box>
          <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
            <Typography level="h3" color="primary">Payment Details ( Company name : {companyName ? companyName : ''} )</Typography>
            <CancelOutlinedIcon onClick={() => navigate(`/commission-payments?page=${page}` + `&pageSize=${pageSize}`)} />
          </Grid>
          <Grid container item lg={12} md={12} sm={12} xs={12} mt={3}>
            <Grid item lg={2} md={2} sm={12} xs={12}>
              <Button variant="contained" color="warning" sx={{ minWidth: 150 }} disabled={captureEnable} onClick={handleClickOpen}>Capture Payment </Button>
            </Grid>
          </Grid>

          <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
            <Table>
              <tr>
                <td className="borderStyle" rowSpan={2}><b>Summary</b></td>
                <td className="borderStyle">Total Approved</td>
                <td className="borderStyle">Total Paid</td>
                <td className="borderStyle">Balance</td>
                <td className="borderStyle">ACH Info Added to Bank:</td>
                <td className="borderStyle">Banking Details</td>
              </tr>
              <tr>
                <td className="borderStyle"><b>$ {parseFloat(totalApprovedCommission).toFixed(2)}</b></td>
                <td className="borderStyle"><b>$ {parseFloat(totalPaid).toFixed(2)}</b></td>
                <td className="borderStyle"><b>$ {parseFloat(balance).toFixed(2)}</b></td>
                <td className="borderStyle"><b>{achAddedToBank}</b></td>
                <td className="borderStyle"><b>{bankDetails}</b></td>
              </tr>
            </Table>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>

            <Typography level="h3" mb={2} align="center">
              Approved Commission Details
            </Typography >
            <MaterialReactTable
              columns={columns}
              data={commissionSummaryData ? commissionSummaryData : []}
              filterFns={{
                customSearchFilterFn: (row, id, filterValue) =>
                row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
            }}   
            globalFilterFn="customSearchFilterFn" //exact serach filter functionality
              enableColumnFilterModes
              enableGrouping={false}
              enablePinning={false}
              initialState={{ showColumnFilters: false, density: 'compact' }}
              enableColumnFilters={config.DatatableColumnFilter}
              positionToolbarAlertBanner="bottom"
              enableDensityToggle={false}
              muiTableHeadCellProps={{
                //simple styling with the `sx` prop, works just like a style prop in this example
                sx: {
                  backgroundColor: "#F6FAFF",
                },
              }}
              renderDetailPanel={({ row }) => (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                  }}
                >
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 500 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }}>AID</TableCell>
                          <TableCell align="center" sx={{ fontWeight: "bold" }}>Total Monthly Commission ($)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {row.original.aidDetails.map((aidDetail) => (
                          <TableRow
                            key={aidDetail.aid}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              {aidDetail.aid}
                            </TableCell>
                            <TableCell align="left">{currencyFormat(parseFloat(aidDetail.monthlyCommission))}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
              top csv option
              renderTopToolbarCustomActions={({ table }) => (
                <Box
                  sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                  <Button
              color="primary"
              disabled={table.getPrePaginationRowModel().rows.length === 0}
              //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() =>
                  handleExportApprovedCommission(table.getPrePaginationRowModel().rows)
                }
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              // onClick={handleExportData}
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              CSV
            </Button>
                </Box>
              )
              }
            />


          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography level="h3" align="center" mb={2} mt={2}>
              Payment Details
            </Typography >
            <MaterialReactTable
              columns={paymentDetailsColumns}
              data={payments ? payments : []}
              filterFns={{
                customSearchFilterFn: (row, id, filterValue) =>
                row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
            }}   
            globalFilterFn="customSearchFilterFn" //exact serach filter functionality
              enableColumnFilterModes
              enableColumnFilters={config.DatatableColumnFilter}
              enableGrouping={false}
              enablePinning={false}
              initialState={{ showColumnFilters: false, density: 'compact' }}
              positionToolbarAlertBanner="bottom"
              enableDensityToggle={false}
              muiTableBodyCellProps={({ row, column }) => {
                const columnIndex = column.id;
                return {
                  sx: {
                    opacity:  (columnIndex != "status") && row && row.original.status == "Deleted"  ? 0.6  : 1,
                    pointerEvents:  (columnIndex != "status") && row && row.original.status == "Deleted"  ? 'none' : 'auto',
                  },
                };
              }}
  
              muiExpandButtonProps={({ row }) => ({                
                                        sx: {                 
                                          opacity: 1,                 
                                          pointerEvents: 'auto',                
                                        },               
                                      })}
              // muiTableHeadCellProps={{
              //   //simple styling with the `sx` prop, works just like a style prop in this example
              //   sx: {
              //     backgroundColor: "#F6FAFF",
              //   },
              // }}
              renderDetailPanel={({ row }) => (
                <Box
                  sx={{
                    justifyContent: 'space-around',
                    alignItems: 'center',
                  }}
                >
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 500 }} aria-label="simple table">
                      <TableRow>
                        <TableCell align="center" sx={{ fontWeight: "bold" }}>Payment Amount ($)</TableCell>
                        <TableCell align="center" sx={{ fontWeight: "bold" }}>Additional Amount ($)</TableCell>
                        <TableCell align="center" sx={{ fontWeight: "bold" }}>Total Amount ($)</TableCell>
                        <TableCell align="center" sx={{ fontWeight: "bold" }}> Payment Details</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center"> {currencyFormat(parseFloat(row.original.paymentAmount))}</TableCell>
                        <TableCell align="center"> {currencyFormat(parseFloat(row.original.additionalAmount))}</TableCell>
                        <TableCell align="center"> {currencyFormat(parseFloat(row.original.totalAmount))}</TableCell>
                        <TableCell align="center"> {row.original.paymentDetails}</TableCell>
                      </TableRow>
                    </Table>
                  </TableContainer>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 500 }} aria-label="simple table">
                      {/* <TableRow>
                        <TableCell align="center" sx={{ fontWeight: "bold" }}>Approved Month</TableCell>
                        <TableCell align="center" sx={{ fontWeight: "bold" }}>Approved Year</TableCell>
                        <TableCell align="center" sx={{ fontWeight: "bold" }}>Payment Amount</TableCell>
                      </TableRow> */}
                      {
                        row.original.paymentArr ?
                        row.original.paymentArr.map((paymentArr) => (
                          <>
                          <TableRow>
                          <TableCell align="center" sx={{ fontWeight: "bold" }}>Approved Month</TableCell>
                          <TableCell align="center" sx={{ fontWeight: "bold" }}>Approved Year</TableCell>
                          <TableCell align="center" sx={{ fontWeight: "bold" }}>Payment Amount</TableCell>
                          </TableRow>
                          <TableRow
                            key={paymentArr.month}
                          >
                            <TableCell align="center" sx={{ width: '50%' }}>
                              {paymentArr.month}
                            </TableCell>
                            <TableCell align="center" sx={{ width: '50%' }}>
                              {paymentArr.year}
                            </TableCell>
                            <TableCell align="center" sx={{ width: '50%' }}>
                              {currencyFormat(parseFloat(paymentArr.totalCommission))}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="center" sx={{ fontWeight: "bold", width: '50%' }}>AID</TableCell>
                            <TableCell align="center" sx={{ fontWeight: "bold", width: '50%' }} colSpan={2}>Payment Amount</TableCell>
                          </TableRow>
                          {paymentArr.aidArr.map((aidArr) => (
                            <TableRow
                              key={aidArr.aid}
                            >
                              <TableCell align="center" sx={{ width: '50%' }}>
                                {aidArr.aid}
                              </TableCell>
                              <TableCell align="center" sx={{ width: '50%' }} colSpan={2}>
                                {currencyFormat(parseFloat(aidArr.paymentAmount))}
                              </TableCell>
                            </TableRow>
                          ))}
                          </>
                        ))
                         : ''
                      }
                    </Table>
                  </TableContainer>
                </Box>
              )}
              top csv option
              renderTopToolbarCustomActions={({ table }) => (
                <Box
                  sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                  <Button
                    color="primary"
                    disabled={table.getPrePaginationRowModel().rows.length === 0}
                    //export all rows, including from the next page, (still respects filtering and sorting)
                      onClick={() =>
                        handleExportPaymentDetails(table.getPrePaginationRowModel().rows)
                      }
                    startIcon={<FileDownloadIcon />}
                    variant="contained"
                  >
              CSV
            </Button>
                </Box>
              )
              }
            />


          </Grid>

          <Formik

            initialValues={RequestFormInitialValues}
            validationSchema={captureSchema}
            onSubmit={(values, { resetForm }) => {
              capturePayment(values,resetForm);
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, setFieldTouched, handleSubmit, setFieldValue, setFieldError,resetForm }) => (
              <>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={fullWidth}
                  maxWidth={maxWidth}
                  style={{height : isMobile? "100%" : "100vh" }}
                >
                  <DialogContent>

                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                      <Typography level="h3">
                        Capture Payment
                      </Typography >
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <span sx={{fontsize : "12px"}}>(Balance : ${balance ? parseFloat(balance).toFixed(2) : ''})</span>
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                      <TextField margin="dense" size="small" id="outlined-basic" label="Payment Amount ($): *"
                        variant="outlined" fullWidth
                        type="number"
                        value={totalCapturePayment}
                        onChange={handleChange('totalCapturePayment')}
                        readOnly={true}
                        onBlur={handleBlur('totalCapturePayment')} />
                      {/* value={balance}/> */}
                    </Grid>
                    {/* {touched.totalCapturePayment && errors.totalCapturePayment ? <div className='error'>{errors.totalCapturePayment}</div> : null} */}
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                      <TextField margin="dense" size="small" id="additionalPayment" name='additionalPayment' label="Additional Payment ($): *"
                        type="number"
                        variant="outlined" fullWidth
                        value={values.additionalPayment}
                        // onChange={handleChange('additi.additionalPaymentonalPayment')}
                        onChange={(e) => {
                          handleChange(e);
                          ChangeFinalTotalPayment(e.target.value);
                        }}
                        onBlur={handleBlur('additionalPayment')
                      }
                        placeholder="0" />
                    </Grid>
                    {touched.additionalPayment && errors.additionalPayment ? <div className='error'>{errors.additionalPayment}</div> : null}
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                      <TextField margin="dense" size="small" id="outlined-basic" label="Total Payment Amount ($): *"
                        variant="outlined" fullWidth
                        type="number"
                        value={finalTotalCapturePayment}
                        onChange={handleChange('finalTotalCapturePayment')}
                        readOnly={true}
                        onBlur={handleBlur('finalTotalCapturePayment')} />
                    </Grid>
                   
                    <Grid item lg={12} md={12} sm={12} xs={12} mt={2} sx={{minHeight:200,maxHeight:200,overflowX : 'auto'}}>
                                        <FormControl>
                                            <FormLabel id="demo-row-radio-buttons-group-label">Select Month & Year:</FormLabel> 
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox checked={isSlectAll}/>} label="Select All"
                                                    onChange={(event) => selectAllFun(event)}
                                                />
                                                {pendingCommissionData.map((pending) => (
                                                  <FormControlLabel control={<Checkbox checked={selectedItems.some(item => item.month === pending.month && item.year === pending.year)}/>} label={pending.month + " " + pending.year} 
                                                    onChange={(event) => changeCapturePayment(pending.month, pending.year, pending.monthlyCommission, event)}
                                                  />
                                                ))}
                                            </FormGroup>
                                        </FormControl> 
                      </Grid>

                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                      <FormControl fullWidth size="small">
                        <TextareaAutosize
                          margin="dense"
                          aria-label="minimum height"
                          minRows={3}
                          // value={values.paymentDetail || ''}
                          value={paymentNote}
                          // onChange={handleChange('paymentDetail')}
                          onChange={changePaymentDetails}
                          onBlur={handleBlur('paymentDetail')}
                          placeholder="Payment Details"
                          style={{ width: "100%" }}
                        />
                      </FormControl>
                      {touched.paymentDetail && errors.paymentDetail ? <div className='error'>{errors.paymentDetail}</div> : null}
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DemoContainer components={['DatePicker']} margin="dense" size="small" fullWidth>
                          <DatePicker label="Payment Date:*" name="paymentDate" id="paymentDate" defaultValue={dayjs(currentDate)}
                            onChange={(value) => { setFieldValue("paymentDate", value, true) }} />
                        </DemoContainer>
                      </LocalizationProvider>
                      <FormHelperText>{touched.paymentDate && errors.paymentDate ? errors.paymentDate : ''}</FormHelperText>
                    </Grid>
                  </DialogContent>
                  <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                    <Button variant="contained" color="primary" 
                      onClick={(e)=>{
                        handleClose(resetForm);
                      }}
                     sx={{ minWidth: 200 }}>CLOSE</Button>
                    <Button disabled={captureEnable} variant="contained" color="success" onClick={handleSubmit} sx={{ minWidth: 200 }}>Capture Payment</Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </Formik>

          <Formik

            initialValues={editPaymentValues}
            validationSchema={editPaymentSchema}
            onSubmit={(values, { resetForm }) => {
              editPayment(values,resetForm);
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, setFieldTouched, handleSubmit, setFieldValue, setFieldError,resetForm }) => (
              <>
                <Dialog
                  open={editPaymentOpen}
                  onClose={handleCloseEditPayment}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={fullWidth}
                  maxWidth={maxWidth}
                  style={{height:'100vh'}}
                >
                  <DialogContent>
                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                      <Typography level="h3">
                      Edit Payment
                      </Typography >
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                      <input id="paymentId" name='paymentId' type="hidden" value={editPaymentValues.id}/>
                      <TextField  margin="dense" size="small" id="outlined-basic" label="Total Payment Amount ($): *"
                        variant="outlined" fullWidth
                        type="number"
                        value={values.amount}
                        readOnly={true}
                        disabled
                        />
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                      <FormControl fullWidth size="small">
                        <TextareaAutosize
                          margin="dense"
                          aria-label="minimum height"
                          minRows={3}
                          value={values.paymentDetails}
                          onChange={handleChange('paymentDetails')}
                          onBlur={handleBlur('paymentDetails')}
                          placeholder="Payment Details"
                          style={{ width: "100%" }}
                        />
                      </FormControl>
                      <FormHelperText>{touched.paymentDetails && errors.paymentDetails ? <div className='error'>{errors.paymentDetails}</div> : null}</FormHelperText>
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DemoContainer components={['DatePicker']} margin="dense" size="small" fullWidth>
                          <DatePicker label="Payment Date:*" name="paymentDate" id="paymentDate" defaultValue={dayjs(values.paymentDate)}
                            onChange={(value) => { setFieldValue("paymentDate", value, true) }} />
                        </DemoContainer>
                      </LocalizationProvider>
                     
                    </Grid>
                     <FormHelperText>{touched.paymentDate && errors.paymentDate ? <div className='error'>{errors.paymentDate} </div> : null}</FormHelperText>
                  </DialogContent>
                  <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                    <Button disabled={editPaymentEnable} variant="contained" color="success" onClick={handleSubmit} sx={{ minWidth: 200 }}>Update</Button>
                    <Button disabled={editPaymentEnable} variant="contained" color="primary" onClick={(e)=>{handleCloseEditPayment(resetForm);}} sx={{ minWidth: 200 }}>CLOSE</Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </Formik>

          <Dialog
            open={deleteOpen}
            onClose={(_, reason) => {
              if (reason !== "backdropClick") {
                setDeleteOpen(false)
              }
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
			    >
            <DialogTitle id="alert-dialog-title">Delete Payment</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this Payment?
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button autoFocus variant="contained" sx={{ minWidth: 100 }} onClick={closeDeletePayment} >
                Cancel
              </Button>
              <Button autoFocus style={{ backgroundColor: 'red', color: 'white', minWidth: 100 }} onClick={deletePayment}>
                Delete
              </Button>
            </DialogActions>
			    </Dialog>

          {/* Edit/Delete History */}
          <>
            <Dialog
              open={openLog}
              onClose={handleCloseLog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={fullWidth}
              maxWidth={statusValue == "Updated" ? logPopupMaxWidth : maxWidth}
            >
              <DialogTitle id="responsive-dialog-title">
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{justifyContent:"space-between"}}>
                  <Grid item lg={10} md={10} sm={10} xs={10}>
                    <Typography level="h4" color="primary" align="center">
                      Payment Log
                    </Typography>
                  </Grid>
                  <Grid item lg={2} md={2} sm={2} xs={2} sx={{textAlign:"right"}}>
                    <CloseIcon onClick={handleCloseLog} />
                  </Grid>
                </Grid>
              </DialogTitle>
              <DialogContent>
                {
                  statusValue == "Updated" ?
                      <MaterialReactTable
                          columns={editLog}
                          data={editHistory}
                          enableColumnFilterModes
                          positionToolbarAlertBanner="bottom"
                          enableColumnOrdering={false}
                          enableGrouping={false}
                          enablePinning={false}
                          initialState={{ showColumnFilters: false,density: 'compact' }}
                          enableDensityToggle={false}
                          enableFullScreenToggle={ false}
                          enableHiding={false} 
                          enableColumnFilters={false}
                          enableSorting={true}
                          filterFns={{
                            customSearchFilterFn: (row, id, filterValue) =>
                            row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
                          }}                                   
                          globalFilterFn="customSearchFilterFn"

                      />
                      :
                  <Grid container item lg={12} md={12} sm={12} xs={12} sx={{justifyContent:"space-between"}}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography level="h6">
                        Deleted By : {deleteHistory ? deleteHistory.edited_by ? deleteHistory.edited_by.name : '' : ''}
                      </Typography >
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h6">
                        Deleted At : {deleteHistory ? deleteHistory.created_at ? Moment(deleteHistory.created_at).format('MM-DD-YYYY H:mm') : '': ''}
                      </Typography >
                    </Grid>
                  </Grid>
                }
              </DialogContent>
            </Dialog>
          </> 
        </Box>
      </>
 

  return (
    <div>
      <DefaultLayout content={ContentLayout } />
    </div>
  );
}

export default PaymentDetails;