
import React, { useMemo, useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Typography,Option, } from '@mui/joy';
import DefaultLayout from '../../../Components/DefaultLayout';
import { Grid,Box,Button,Tooltip} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import AuthUser from "../../../Components/Auth/AuthUser";
import Loader from '../../../Components/Loader/Loader';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import Moment from 'moment';
import moment from 'moment';
import MaterialReactTable from 'material-react-table';
import config from '../../../AppConfig';
import {currencyFormat} from '../../../Components/CommonFormula';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

function SoftwareCommissions(props) {
    const dispatch = useDispatch();
    const { http,user } = AuthUser();
    const theme = useTheme();
    const [data, setData] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [commissionSummarylist, setCommissionSummarylist] = useState([]);
    const [pendingCommissions, setPendingCommissions] = useState([]);
    const [companyId, setCompanyId] = useState();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [disable, setDisable] = useState(false);
    const [approvedFlag, setApprovedFlag] = useState(false);
    const [acceptFlag, setAcceptFlag] = useState(false);
    const [lowBalanceFlag, setLowBalanceFlag] = useState(false);
    const [selectedRanges, setSelectedRanges] = useState([]);
    const [openCalender, setOpencalender] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [paymentMadeEndedDate, setPaymentMadeEndedDate] = useState('');
    const [rowSelection, setRowSelection] = useState({});
    const [columnVisibility, setColumnVisibility] = React.useState({});

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    //Submit Request Add Missing Member Form


    const fetchSummaryList = async page => {
        const apiUrl = '/software-commission-summary-report';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            setCommissionSummarylist(res.data);
            setIsLoading(false);
           
        }
    }

    const viewDetails = (softwareId,month,year) => {
      navigate('/software-commission-details', { state: { softwareId: softwareId, month:month, year:year} });
    };

    useEffect(() => {
        fetchSummaryList();
    }, []);

    const CommisionListColumns = useMemo(
        () => [
              {
                accessorFn: (row) => `${row.month?row.month:''}`, //accessorFn used to join multiple data into a single cell
                id: 'month', //id is still required when using accessorFn instead of accessorKey
                header: 'Month',
                size: 200,
                enableClickToCopy: false,
                Cell: ({ renderedCellValue, row }) => (row.original.month? row.original.month: 'N/A'),
              },
              {
                accessorFn: (row) => `${row.year?row.year:''}`, //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                id: 'year', //id is still required when using accessorFn instead of accessorKey
                header: 'Year',
                size: 100,
                enableClickToCopy: false,
                Cell: ({ renderedCellValue, row }) => (row.original.year? row.original.year: 'N/A'),
              },
              {
                accessorFn: (row) => `${row.referral_commission?row.referral_commission:''}`, //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                id: 'ReferralCommission', //id is still required when using accessorFn instead of accessorKey
                header: 'Referral Commission ($)',
                size: 100,
                enableClickToCopy: false,
                sortingFn: (rowA, rowB) => {
                  const sortA = rowA.original.referral_commission;
                  const sortB = rowB.original.referral_commission;
                  return sortA-sortB;
                },
                Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.referral_commission))),
              },
              {
                accessorFn: (row) => `${row.bonus?row.bonus:''}`, //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                id: 'bonus', //id is still required when using accessorFn instead of accessorKey
                header: 'One Time Referral Bonus ($)',
                size: 100,
                enableClickToCopy: false,
                sortingFn: (rowA, rowB) => {
                  const sortA = rowA.original.bonus;
                  const sortB = rowB.original.bonus;
                  return sortA-sortB;
                },
                Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.bonus))),
              },
              {
                accessorFn: (row) => `${row.total_commission?row.total_commission:''}`, //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                id: 'totalCommission', //id is still required when using accessorFn instead of accessorKey
                header: 'Total Commission ($)',
                size: 100,
                enableClickToCopy: false,
                sortingFn: (rowA, rowB) => {
                  const sortA = rowA.original.total_commission;
                  const sortB = rowB.original.total_commission;
                  return sortA-sortB;
                },
                Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.total_commission))),
              },
              {
                accessorFn: (row) => `${row.paymentDate?row.payment_date:''}`, 
                id: 'PaymentDate', 
                header: 'Payment Date',
                size: 100,
                enableClickToCopy: false,
                sortingFn: (rowA, rowB) => {
                  const sortA = rowA.original.payment_date ? rowA.original.payment_date : '';
                  const sortB = rowB.original.payment_date ? rowB.original.payment_date : '';
                  return sortA-sortB;
                },
                Cell: ({ renderedCellValue, row }) => (
                  moment(row.original.payment_date, 'YYYY-MM-DD', true).isValid() ? moment(row.original.payment_date).format('MM-DD-YYYY') 
                   : ''
               
                )
              },
              {
                id: 'action',
                header: 'Action',
                Cell: ({ row }) => (
                  <Box>
                    <>   
                      <Tooltip title="Details" arrow><Button sx={{marginBottom:"15px"}} className='btn btn-sm btn-info text-white' onClick={()=> viewDetails(`${row.original.software_id}`,`${row.original.month}`,`${row.original.year}`)} ><img src={require('../../../assets/images/details.png')} /></Button></Tooltip>
                    </> 
                  </Box>
                ),
               
              },
             
        ]
    );

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        fileName: 'Commission Summary Report',
        headers: ['Month','Year', 'Referral Commission ($)', 'One Time Referral Bonus ($)','Total Commission ($)','Payment Date'],
      };

    const convertToCSV = (data) => {
        const headers = csvOptions.headers.join(',') + '\n';
    
        const rows = data.map((row) => {
          return Object.values(row).map((value) => {
            // Handle any necessary formatting or escaping of values
            // For simplicity, we assume all values are already properly formatted
        
            // Wrap the value with double quotes if it contains a comma
            if (typeof value === 'string' && value.includes(',')) {
            return `"${value}"`;
            }
        
            return value;
          }).join(',');
          }).join('\n');
    
        return headers + rows;
      };

    const handleExportData = (commissionSummarylist) => {
        const exportedData = commissionSummarylist.map((row) => {
          return {
            month: `${row.original.month? row.original.month: 'N/A'}`,
            year: `${row.original.year? row.original.year: 'N/A'}`,
            referralCommission: `${row.original.referral_commission ? row.original.referral_commission : '0'}`,
            bonus:`${row.original.bonus ? row.original.bonus : '0'}`,
            finalTotalCommission:`${row.original.total_commission ? row.original.total_commission : '0'}`,
            paymentDate:`${row.original.payment_date?row.original.payment_date:'N/A'}`,
          };
        });
    
        const csvContent = convertToCSV(exportedData);
        // Create a Blob object from the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    
        // Create a download link element
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = csvOptions.fileName + '.csv';
    
        // Append the link to the document body
        document.body.appendChild(link);
    
        // Programmatically click the link to trigger the file download
        link.click();
    
        // Clean up the temporary URL and remove the link from the document
        URL.revokeObjectURL(link.href);
        link.remove();
      };

    function ContentLayout() {
        return (
            <>
                {isLoading ? <Loader /> : <></>}
                <Box>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mb={1} mt={1.5} sx={{ justifyContent: "space-between" }}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Typography level="h4" color="primary">
                                Commission Summary Report
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mb={3}>
                        <Typography level="h6">
                            Congratulations! You have earned commissions as below
                        </Typography>
                    </Grid>
                    
                    <MaterialReactTable
                        columns={CommisionListColumns}
                        data={commissionSummarylist}
                        enableColumnFilterModes
                        filterFns={{
                        customSearchFilterFn: (row, id, filterValue) =>
                        row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
                        }} 
                        globalFilterFn="customSearchFilterFn" //exact serach filter functionality
                        onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                        state={{ columnVisibility, rowSelection }} 
                        onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
                        enableRowSelection
                        enableColumnFilters={config.DatatableColumnFilter}
                        initialState={{ showColumnFilters: false, density: 'compact' }}
                        positionToolbarAlertBanner="bottom"
                        enableDensityToggle={false}
                        muiTableHeadCellProps={{
                            //simple styling with the `sx` prop, works just like a style prop in this example
                            sx: {
                            backgroundColor:"#F6FAFF",
                            },
                        }} 
                        renderTopToolbarCustomActions={({ table }) => (
                        <Box
                            sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                        >
                        <Button
                            color="primary"
                            disabled={table.getPrePaginationRowModel().rows.length === 0}
                            //export all rows, including from the next page, (still respects filtering and sorting)
                            onClick={() =>
                                handleExportData(table.getPrePaginationRowModel().rows)
                            }
                            startIcon={<FileDownloadIcon />}
                            variant="contained"
                        >
                            CSV
                        </Button>
                        </Box>
                        )
                        } 
                    />
                

                     
                </Box>
            </>
        );
    }

    return (
        <div>
            <DefaultLayout content={<ContentLayout />} />
        </div>
    );
}

export default SoftwareCommissions;