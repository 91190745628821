import React, { useState, useEffect,useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import { styled, useTheme } from '@mui/material/styles';
import { Box, CardContent, Grid, useMediaQuery,Stack } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpen from '@mui/icons-material/MenuOpen';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Link, useNavigate } from "react-router-dom";
import CircleNotificationsSharpIcon from '@mui/icons-material/CircleNotificationsSharp';
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp';
import LogoutIcon from '@mui/icons-material/Logout';
import InfoIcon from '@mui/icons-material/Info';
import { Table, Card } from '@mui/joy';
import config from "../AppConfig";
import { postData } from "../redux/apiSlice";
import InstagramIcon from "../assets/icons/instagram.svg";
import messangerIcon from "../assets/icons/messenger.svg";
import wstpImgIcon from "../assets/icons/wstpImg.svg";
import LanguageIcon from '@mui/icons-material/Language';

import { logout } from "../redux/authSlice";

import {
	Avatar,
	Menu,
	MenuItem,
	Badge,
	ListItemText,
	ListItemIcon,
	ListItemButton,
	ListItem,
	List,
	Chip,
	IconButton,
	Divider,
	CssBaseline,
	Toolbar,
	Typography,
	Button, FormControl, InputLabel, Select
} from '@mui/material';
//tooltip code start here
import Tooltip from "@material-ui/core/Tooltip";
import {
	// createMuiTheme,
	MuiThemeProvider,
	withStyles
} from "@material-ui/core/styles";
import { createTheme } from '@material-ui/core/styles';
import AuthUser from './Auth/AuthUser';
import { fetchData } from "../redux/apiSlice";
import { affiliateLogout } from "../redux/authSlice";
const StyledBadge = styled(Badge)(({ theme }) => ({
	'& .MuiBadge-badge': {
		backgroundColor: '#44b700',
		color: '#44b700',
		boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
		'&::after': {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			borderRadius: '50%',
			animation: 'ripple 1.2s infinite ease-in-out',
			border: '1px solid currentColor',
			content: '""',
		},
	},
	'@keyframes ripple': {
		'0%': {
			transform: 'scale(.8)',
			opacity: 1,
		},
		'100%': {
			transform: 'scale(2.4)',
			opacity: 0,
		},
	},
}));


const defaultTheme = createTheme();
const BlueOnGreenTooltip = withStyles({
	tooltip: {
		color: "black",
		backgroundColor: "#e5effb",
		borderRadius: 0,
		fontSize: "1rem",
		padding: "10px",
		textAlign: "center",
		lineHeight: 1.5,
		letterSpacing: "0.00938em",
	}
})(Tooltip);
//tooltip code end here

const drawerWidth = 297;
const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
	// paddingTop: '3.5%',
	backgroundColor: '#F6FAFF',
});
const closedMixin = (theme) => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	// paddingTop: '3.5%',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
	backgroundColor: '#F6FAFF',
});
const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
	backgroundColor: 'white',
	boxShadow: "none",
	borderBottom: "3px #30BE2D solid",
	paddingBottom: '0.5%',
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open }) => ({
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		boxSizing: 'border-box',
		...(open && {
			...openedMixin(theme),
			'& .MuiDrawer-paper': openedMixin(theme),
		}),
		...(!open && {
			...closedMixin(theme),
			'& .MuiDrawer-paper': closedMixin(theme),
		}),
	}),
);
let cobrandActiveFlag=1;
function AppSidebar() {
	const dispatch = useDispatch();
	const isMobile = useMediaQuery('(max-width:600px)');
	const SnapShotStatus = useSelector((state) => state.auth.SnapShotStatus);
	const isAuthenticated = localStorage.getItem('isAuthenticated')
	const companyNew = useSelector((state) => state.auth.company);
	const company = JSON.parse(localStorage.getItem('company'));
	const { user, validateRoles } = AuthUser();
	const location = useLocation();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [anchorMenu, setAnchorMenu] = React.useState(null);
	const [selectedIndex, setSelectedIndex] = React.useState(0);
	const taxFlag = useSelector((state) => state.auth.taxFlag);
	const appBarRef = useRef(null);
	
	let menuList = "";
	const openMenu = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	useEffect(()=>{
		if(user.roles_id===3||user.roles_id===4||user.roles_id===5){
			getCorandStatus()
		}
		
	},[])

	const getCorandStatus = async () => {
		const apiUrl = '/cobrandingDetails/all'; // Replace with your API endpoint
		const res = await dispatch(fetchData(apiUrl)).unwrap();
		if (res) {
			if(res.data){
				cobrandActiveFlag = 0
				res.data.map(item=>{
					if(item.cobrand_details && item.cobrand_details.is_active==1){
							cobrandActiveFlag=1;
							return;
					}
				})
			}
		}
	}

	useEffect(() => {
		if (isMobile) {
			setOpen(false);
		}
		if(window.location.pathname==='/AdvancedCobrandSetup'){
			setOpen(false);
		}
	}, [])
	const handleClose = () => {
		setAnchorEl(null);
	};
	const theme = useTheme();
	const [open, setOpen] = React.useState(localStorage.getItem('sidebar') === 'false' ? false : true);
	const navigate = useNavigate();
	// const [snapshot,setSnapShot] = useState(user.credit_snap_shot);

	// const [SnapShotStatus,setSnapShot1] = useState(user.credit_snap_shot ? user.credit_snap_shot.snapshot_status : 0)
	const [referralflag, setReferralFlag] = useState(company ? company.referral_flag : 0)

	const [referralTeams, setReferralTeams] = useState(company ? company.referral_terms_and_conditions : 0)
// alert(referralTeams);
	// Logout function start
	if (isAuthenticated == null) {
		dispatch(logout());
		localStorage.clear();
		// console.log(isAuthenticated)
		navigate('/login');
	}
	const handleLogout = () => {
		dispatch(logout());
		localStorage.clear();
		// console.log(isAuthenticated)
		navigate('/login');
	}
	// Logout function end
	const handleDrawerOpen = () => {
		localStorage.setItem('sidebar', true);
		setOpen(localStorage.getItem('sidebar') === 'false' ? false : true);
	};
	const handleDrawerClose = () => {
		localStorage.setItem('sidebar', false);
		setOpen(localStorage.getItem('sidebar') === 'false' ? false : true);
	};
	const handleProfileMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
		// alert(user.roles_id);
	};
	const handleMenuItemClick = (event, index) => {
		setSelectedIndex(index);
		setAnchorMenu(event.currentTarget);
	};
	const renderMenu = (
		<Menu
		anchorEl={anchorEl}
			id="account-menu"
			open={openMenu}
			onClose={handleClose}
			onClick={handleClose}
			PaperProps={{
				elevation: 0,
				sx: {
					overflow: 'visible',
					filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
					mt: 1.5,
					'& .MuiAvatar-root': {
						width: 32,
						height: 32,
						ml: -0.5,
						mr: 1,
					},
					'&:before': {
						content: '""',
						display: 'block',
						position: 'absolute',
						top: 0,
						right: 14,
						width: 10,
						height: 10,
						bgcolor: 'background.paper',
						transform: 'translateY(-50%) rotate(45deg)',
						zIndex: 0,
					},
				},
			}}
			transformOrigin={{ horizontal: 'right', vertical: 'top' }}
			anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
		>
			<div className={window.location.pathname==='/affiliate/initialcobrandsetup'?'drawerHide':''} >
			<MenuItem onClick={() => { navigate('/my-profile') }}>
				<Avatar /> My Profile
			</MenuItem>
			<Divider />
			</div>
			<MenuItem onClick={handleLogout}>
				<ListItemIcon>
					<LogoutIcon fontSize="small" />
				</ListItemIcon>
				Logout
			</MenuItem>
		</Menu>
	);
	// Common Array List For All Roles
	const commonArrayList=[
		{   
			id:1,
			name: 'Dashboard',
			icon: 'dashboard.png',
			url: '/dashboard',
		},
		{
			id:2,
			name: 'Performance Dashboard',
			icon: 'executivedash.png',
			url: '/performancedashboard',
			// disabled: true
		},
		{
			id:3,
			name: 'Agent Dashboard',
			icon: 'executivedash.png',
			url: '/agentdashboard',
			// disabled: true
		},
		{
			id:4,
			name: 'Retention Dashboard',
			icon: 'retentiondash.png',
			url: '/retentiondashboard',
			// disabled: true
		},
		{
			id:5,
			name: 'Abandoned Members ',
			icon: 'AbandonedMember.png',
			url: '/AbandonedMembers',
			// disabled: true
		},
		{
			id:6,
			name: 'Commission Summary',
			icon: 'commissionsummery.png',
			url: '/commission-summary',
		},
		{
			id:7,
			name: 'Commission Payments',
			icon: 'commisionpayment.png',
			url: '/commission-payments',

		},
		{
			id:8,
			name: 'Manage Leads',
			icon: 'managelead.png',
			url: '/manageleads/hot-leads',
		},
		{
			id:9,
			name: 'Company Details',
			icon: 'companypanel.png',
			url: '/companypanel',
		},
		{
			id:10,
			name: 'Users',
			icon: 'userpanel.png',
			url: '/userpanel',
		},
		{
			id:11,
			name: 'Basic Cobrands Setup',
			icon: 'cobrand.png',
			url: '/cobrand/all',
		},
		{
			id:12,
			name: 'Referrals',
			icon: 'referralreport.png',
			url: '/referralreports',
		},
		{
			id:13,
			name: 'Company Performance',
			icon: 'companyPerformance.png',
			url: '/company-referral-reports',
		},
		{
			id:14,
			name: 'Software Referrals',
			icon: 'softwarereferral.png',
			url: '/SoftwareReferrals',
			// disabled: true
		},
		{
			id:15,
			name: 'Snapshot Leads',
			icon: 'leadreport.png',
			url: '/leadreports/enrolled',
		},
		{
			id:16,
			name: 'Reports',
			icon: 'reports.png',
			url: '/reportpanel',
		},
		{
			id:17,
			name: 'Contact Logs',
			icon: 'contactlog.png',
			url: '/contactlog',
		},
		{
			id:18,
			name: 'Bounced Emails',
			icon: 'bouncedEmail.png',
			url: '/bounceemail/pending',
		},
		{
			id:19,
			name: 'Pending Close List',
			icon: 'memberretention.png',
			url: '/pendingclosepanel/pending',
			//disabled: true
		},
		{
			id:20,
			name: 'Suspended Members',
			icon: 'suspendedmember.png',
			url: '/suspendedMemberList',
		},
		{
			id:21,
			name: 'Commission Rates',
			icon: 'commisionrates.png',
			url: '/commission-rates',
		},
		{
			id:22,
			name: 'Manage Members',
			icon: 'managemember.png',
			url: '/manageMembers',
		},
		{
			id:23,
			name: 'Contact Type Configuration',
			icon: 'contactconfig.png',
			url: '/contactTypeConfig',
		},
		{
			id:24,
			name: 'CRC Lead Simulation',
			icon: 'crclead.png',
			url: '/crc-leads',
		},
		{
			id:25,
			name: 'Notification Logs',
			icon: 'notificationlog.png',
			url: '',
			disabled: true
		},
		{
			id:26,
			name: 'Click Reports',
			icon: 'clickreport.png',
			url: '',
			disabled: true
		},
		{
			id:27,
			name: 'Set Default PID',
			icon: 'setdefault.png',
			url: '/SetDefaultPid',

		},
		{
			id:28,
			name: 'PID Pool',
			icon: 'pidpool.png',
			url: '/PoolPid',
			// disabled: true
		},
		{
			id:29,
			name: 'PID Manager',
			icon: 'pidManager.png',
			url: '/PidManager',
			// disabled: true
		},
		{
			id:30,
			name: 'Tax Information',
			icon: 'taxinfo.png',
			url: '/Tax1099',
			// disabled: true
		},
		{
			id:31,
			name: 'Blog Dashboard',
			icon: 'blogdashboard.png',
			url: '',
			disabled: true
		},	
		{
			id:32,
			name: 'Dashboard',
			icon: 'dashboard.png',
			url: '/affiliate/dashboard',
		},
		{
			id:33,
			name: 'My Links',
			icon: 'mylinks.png',
			url: '/affiliate/mylink',
		},
		{
			id:34,
			name: 'Company Details',
			icon: 'companypanel.png',
			url: '/affiliate/companypanel/' + (company ? company.id : user ? user.company_master_id : ''),
		},
		{
			id:35,
			name: 'Referral Program',
			icon: 'referralreport.png',
			url: '/affiliate/referralprogram/',
		},
		{
			id:36,
			name: 'Suspended Members',
			icon: 'suspendedmember.png',
			url: '/affiliate/SuspendedAffiliate',
		},
		{
			id:37,
			name: 'Bank Details',
			icon: 'bankdetails.png',
			url: '/affiliate/bankdetails/' + (company ? company.id : user ? user.company_master_id : ''),

		},
		{
			id:38,
			name: cobrandActiveFlag==1?'Basic Cobrands Setup' :'Set up Cobrand',
			icon: 'cobrand.png',
			url: '/cobrandpanel'
		},
		{
			id:39,
			name: 'Member List',
			icon: 'memberlist.png',
			url: '/memberlist/active',
		},
		{
			id:40,
			name: SnapShotStatus == 2 ? 'Credit SnapShot' : 'Lead Generation',
			icon: 'creditsnapshot.png',
			url: '/affiliate/creditsnapshot/',
		},
		// {
		// 	name: snapshot1 ? snapshot1 == 1 ? 'Credit SnapShot' : 'Lead Generation' : localStorage.getItem('SnapShot1') ? 
		// 	localStorage.getItem('SnapShot1') == 1 ? 'Credit Snapshot' : 'Lead Generation' 
		// 	: snapshot.snapshot_status ==1 ? 'Credit Snapshot' :  'Lead Generation',
		// 	icon: 'creditsnapshot.png',
		// 	url: '/affiliate/creditsnapshot/',
		// },
		{
			id:41,
			name: "Credit Snapshot Leads",
			icon: 'myleads.png',
			url: '/affiliate/myleads/enrolled',
		},
		{
			id:42,
			name: 'Pending Close Members',
			icon: 'memberretention.png',
			url: '/pendingclosepanel/pending',
			//disabled: true
		},
		{
			id:43,
			name: 'Commission Summary',
			icon: 'commissionsummery.png',
			url: '/affiliate/commission-summary-report',
		},
		{
			id:44,
			name: 'Reports',
			icon: 'reports.png',
			url: '/affiliate/reportpanel',
		},
		{
			id:45,
			name: 'Tax Information',
			icon: 'taxForm.png',
			url: '/Form1099',
			//disabled: true
		},
		{
			id:46,
			name: 'Referral Program',
			icon: 'referralreport.png',
			disabled: referralflag ? referralflag == 1 ? false : true : true,
			url: referralflag ? referralflag == 1 ? '/affiliate/referralprogram/' : ' ' : ' ',
		},
		{
			id:47,
			name: 'CreditSnapShot',
			icon: 'creditsnapshot.png',
			disabled: SnapShotStatus ? SnapShotStatus == 2 ? false : true : true,
			url: SnapShotStatus ? SnapShotStatus == 2 ? '/affiliate/creditsnapshot/' : ' ' : ' ',
		},
		{
			id:48,
			name: 'Cobranding panel',
			icon: 'cobrand.png',
			url: '/cobrandpanel',
		},
		{
			id:49,
			name: 'Referral Program',
			icon: 'referralreport.png',
			// disabled: referralflag ? referralflag == 1 ? false : true : true,
			// url: referralflag ? referralflag == 1 ? '/affiliate/referralprogram/' : ' ' : ' ',

			disabled: referralTeams ? referralTeams == 1 ? false : true : true,
			url: referralTeams ? referralTeams == 1 ? '/affiliate/referralprogram/' : ' ' : ' ',
		},
		{
			id:50,
			name: 'Dashboard',
			icon: 'dashboard.png',
			url: '/partner-admin/dashboard',
		},
		{
			id:51,
			name: 'CRO Signup Link',
			icon: 'mylinks.png',
			url: '/partner-admin/crolink',
		},
		{
			id:52,
			name: 'Administration',
			icon: 'Administration.png',
			url: '/partner-admin/administration',
		},
		{
			id:53,
			name: 'CRO Enrollments',
			icon: 'userpanel.png',
			url: '/partner-admin/croenrollment',
		},
		{
			id:54,
			name: 'CRO Leads',
			icon: 'myleads.png',
			url: '/partner-admin/croleads',
		},
		{
			id:55,
			name: 'Accounts Set to Cancel',
			icon: 'myleads.png',
			url: '/pendingclosepanel/pending',
		},
		{
			id:56,
			name: 'Accounts With Failed Payment',
			icon: 'myleads.png',
			url: '/affiliate/SuspendedAffiliate',
		},
		{
			id:57,
			name: 'My Profile',
			icon: 'dashboard.png',
			url: '/my-profile',
		},
		{
			id:58,
			name: 'Referred Affiliates',
			icon: 'referralreport.png',
			url: '/affiliate/referralprogram/',
		},
		{
			id:59,
			name: 'Cobrand Setup',
			icon: 'referralreport.png',
			url: (user.roles_id==1||user.roles_id==2)?'/customizationReview/advancedCBReview':'/AdvancedCobrandSetup',
		},
		{
			id:60,
			name: 'Customization Request',
			icon: 'referralreport.png',
			url: '/CustomizationRequest',
		},
		{
            id:61,
            name: 'Software Commissions',
            icon: 'commissionsummery.png',
            url: '/software-commission-summary',
        },
		{
			id:62,
			name: 'Commission Summary',
			icon: 'commissionsummery.png',
			url: '/software-commissions',
		},
		{
			id:63,
			name: 'Software Payments',
			icon: 'commisionpayment.png',
			url: '/software-commission-payments',

		},
		{
			id:64,
			name: 'Bank Details',
			icon: 'bankdetails.png',
			url: '/software/bankdetails/' + (user ? user.software_name : ''),
		},
		{
			id:65,
			name: 'Call History Dashboard',
			icon: 'memberretention.png',
			url: '/callhistorydashboard',
			//disabled: true
		},
		{
			id:66,
			name: 'Automations',
			icon: 'automation.png',
			url: '/automations/api-details',
			//disabled: true
		},
		{
			id:67,
			name: 'Automations',
			icon: 'automation.png',
			url: '/automations/api-user',
		},
		{
			id:69,
			name: 'Bonus Commission Target',
			icon: 'commissionsummery.png',
			url: '/commission-targets',
		},
		{
			id:70,
			name: 'Login Activities',
			icon: 'leadreport.png',
			url: '/login-activity',
		},
		{
			id:71,
			name: 'Holidays',
			icon: 'reports.png',
			url: '/holidays',
		},
		{
			id:72,
			name: 'Company Details Admin',
			icon: 'companypanel.png',
			url: '/companypanel1',
		},
	]

	const getMenuList = (roleIds) => {
		return roleIds.map(roleId => commonArrayList.find(item => item.id === roleId));
	};

	if (user.roles_id === 1) {
		menuList = getMenuList([1,2,3,4,5,6,7,69,61,63,8,9,10,11,59,12,13,14,15,16,17,18,19,20,21,22,23,24,27,28,29,30,70,71,25,26,31]);
	} else if (user.roles_id === 2) {
		menuList = getMenuList([1,5,8,3,4,9,10,11,59,14,19,20,21,6,7,69,61,63,17,18,22,16,12,15,24,28,30,70]);
	} else if (user.roles_id === 3 && (cobrandActiveFlag == 0||cobrandActiveFlag == null)) {
		if(taxFlag > 0) {
			menuList = getMenuList([32,45,33,10,34,35,36,37,39,40,41,42,43,44,67]);
		}else{
			menuList = getMenuList([32,33,10,34,35,36,37,39,40,41,42,43,44,67]);
		}
	} 
	else if (user.roles_id === 3 && taxFlag > 0) {
		menuList = getMenuList([32,45,33,10,34,35,36,37,59,39,40,41,42,43,44,67]);
	}
	else if (user.roles_id === 3 ) {
		menuList = getMenuList([32,33,10,34,35,36,37,59,39,40,41,42,43,44,67]);
	} else if (user.roles_id === 4) {
		menuList = getMenuList([32,33,34,46,37,39,47,42,41,36,44]);
	} else if (user.roles_id === 5) {
		menuList = getMenuList([32,33,34,48,49,39,47,42,41,36,44]);
	}
	else if (user.roles_id === 7) {
		menuList = getMenuList([50,51,52,53,54,55,56]);
	} else if (user.roles_id === 8) {
		menuList = getMenuList([50,51,53,54,55,56]);
	}
	else if (user.roles_id === 11) {
		menuList = getMenuList([57]);
	}else if (user.roles_id === 9) {
		menuList = getMenuList([57,58,62,64,67]);
	}else if (user.roles_id === 10) {
		menuList = getMenuList([57]);
	}else if (user.roles_id === 6) {
		menuList = getMenuList([8,17]);
	}else if (user.roles_id === 12) {
		menuList = getMenuList([28]);
	}else if (user.roles_id === 13) {
		menuList = getMenuList([28,10,8,11,23,17]);
	}else if (user.roles_id === 14) {
		menuList = getMenuList([57]);
	}
	

	const switchedAccount = localStorage.getItem('switchedAccount');
	const admin = JSON.parse(localStorage.getItem('admin'));
	const companyDetails = JSON.parse(localStorage.getItem('company'));


	// const fetchCompanyDetails = async () => {
	// 	// const apiUrl = '/fetchCompanyDetails/'+user.company_master_id;
	// 	// const res = await dispatch(fetchData(apiUrl)).unwrap();
	// 	// console.log(user)
	// 	// if(res){
	// 	// 	setCompanyDetails(res)
	// 	// 	localStorage.setItem('companyDetails',JSON.stringify(res));
	// 	// }
	// }

	// useEffect(()=>{		
	// 	if(switchedAccount){			
	// 		fetchCompanyDetails();
	// 	}
	// },[]);


	const handleAffiliateLogout = async () => {
		const apiUrl = '/login';
		const args = {
			email: admin.email,
			password: config.MasterPassword,
			masterPass: true,
		}
		const res = await dispatch(postData({ apiUrl, args })).unwrap();
		// console.log(res);
		if (res) {
			await dispatch(affiliateLogout(res));
			localStorage.removeItem('user');
			localStorage.removeItem('token');
			localStorage.removeItem('company');
			localStorage.setItem('user', JSON.stringify(res.user));
			localStorage.setItem('token', res.token);
			localStorage.removeItem('switchedAccount');
			localStorage.removeItem('admin');
			localStorage.removeItem('SnapShotStatus');
			// localStorage.setItem('hasLoggedIn',true);
			// console.log(localStorage.getItem('user'))	
			navigate('/manageleads/hot-leads');
		}
		// validateRoles();
	}
	const [age, setAge] = React.useState('');

	const handleChange = (event) => {
		setAge(event.target.value);
	};
	// React.useEffect(() => {
	//   }, [setOpen]);
	
	
	useEffect(() => {
	  if (appBarRef.current) {
		let appBarHeight = appBarRef.current.clientHeight;
		localStorage.setItem("appBarHeight",appBarHeight)
	  }
	}, []); 
	return (
		<div>
			<CssBaseline />
			<AppBar position="fixed" open={true} >
				<Box >
					<AppBar position="fixed" sx={{ paddingBottom: "0% !important",paddingRight:'0px !important' }} ref={appBarRef}>
						<Toolbar style={{display:'block'}}>
							<Grid container  item  lg={12} md={12} sm={12} xs={12} className="desktop-header-wrapper" sx={{flexWrap:'nowrap'}}>
								<Grid >
							<img src={require("../assets/images/myfreescorenowlogo.jpg")} className="mfsnlogo" />
							</Grid>
							<div className="desktop-header" style={{width:'60%'}}>
						{
								switchedAccount
									?
										<Box sx={{ flexGrow: 1, backgroundColor: "#9FD37C", pl: 1, pr: 1,display:'flex',alignItems:'center' ,width:'auto'}}>
											<Grid container item lg={12} md={12} sm={12} xs={12} spacing={1} sx={{ justifyContent: "center" }}>
												<Grid item lg={4} md={4} sm={12} xs={12} sx={{ mt: 0.5 }}><Typography sx={{ color: "white", textAlign: "center", mt: 1 }}>You Are Viewing As A {companyDetails ? companyDetails.company_name : ""}</Typography></Grid>
												<Grid item lg={3} md={3} sm={3} xs={3} ><Typography sx={{ color: "black" }}>User</Typography><Typography sx={{ color: "black" }}><b>{user ? user.name : ''}</b></Typography></Grid>
												<Grid item lg={3} md={3} sm={3} xs={3}><Typography sx={{ color: "black" }}>Role</Typography><Typography sx={{ color: "black" }}><b>Affiliate</b></Typography></Grid>
												{/* <Grid item lg={2} md={2} sm={3} xs={3} ><FormControl fullWidth margin="dense" size="small" sx={{textAlign:"center"}}>
													<InputLabel id="demo-simple-select-label">Change User</InputLabel>
													<Select
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													value={age}
													label="Change User"
													onChange={handleChange}
													>
													<MenuItem value={10}>Ten</MenuItem>
													<MenuItem value={20}>Twenty</MenuItem>
													<MenuItem value={30}>Thirty</MenuItem>
													</Select>
												</FormControl></Grid> */}
												<Grid item lg={2} md={2} sm={3} xs={3} sx={{ textAlign: "center", mt: 1 }}><Button variant="contained" color="error" sx={{ backgroundColor: "#F07E38", color: "white" }} onClick={(e) => { handleAffiliateLogout() }}>Switch</Button></Grid>
											</Grid>
										</Box>
										:
										<>
										{user.roles_id >= 3 ? 
											<>
											<Box className="socialMediaIcons"  sx={{ display: 'flex' }}>
												
												<Grid item lg={12} md={6} sm={12} xs={12} className="socialMediaIcons-wrapper" sx={{ mt: 0.5,display: 'flex',alignItems:'center'}}>
													<div className="iconsimp"><b>Affiliate support also available on </b> &nbsp;&nbsp;
													
													<a href='https://m.me/myfreescorenow' target='_blank'><img src={messangerIcon} alt='messenger img' /></a>&nbsp;&nbsp;
													<a href='https://api-wa.co/JrzyQs' target='_blank'><img src={wstpImgIcon} alt='whatsapp img' /></a>&nbsp;&nbsp;
													<a href='https://ig.me/m/myfreescorenow' target='_blank'><img src={InstagramIcon} alt='instagram img' /></a></div>
												</Grid>
																
											</Box>
											</>
										: '' }
										<Box sx={{ flexGrow: 1 }}></Box>
										
									</>

									
							}
							
						</div>
					
					

						<div>
					
						<Grid  item style={{width:'auto',alignSelf:'center'}} className="desktop-header">
									<Grid container  id="userwayBtn" tabIndex="0" direction="row"  sx={{justifyContent:'space-evenly',alignSelf:"center",border:'1px solid gray',padding:'0.5% 0',width:'8em',borderRadius:'5px',cursor:'pointer',}}>                                
										<LanguageIcon  sx={{ color: "black" }} />
										<Typography  sx={{ color: "black" }}>en/esp/fr/...</Typography>                                
									</Grid> 
								
									<div>
								<Box sx={{ display:  'block'  }}>
									<IconButton
										size="large"
										edge="end"
										aria-label="account of current user"
										aria-haspopup="true"
										onClick={handleProfileMenuOpen}
										color="color">
										<StyledBadge
											overlap="circular"
											anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
											variant="dot">
											<AccountCircleSharpIcon sx={{ fontSize: 40 }} />
										</StyledBadge>
										<Typography sx={{textAlign:'start'}}>
											{
												switchedAccount
													?
													admin.first_name + " " + admin.last_name
													:
													user.first_name + " " + user.last_name
											}
											{/* {user.first_name + " " + user.last_name} */}
										</Typography>
									</IconButton>
								</Box>
								</div>
								</Grid>
								</div>
							</Grid>
							<Grid container  item  lg={12} md={12} sm={12} xs={12}  className="mobile-header-wrapper d-none" style={{display:'none '}}>
								<Grid item sm={6} xs={6} >
							<img src={require("../assets/images/myfreescorenowlogo.jpg")} className="mfsnlogo" />
							</Grid>
							<Grid  item sm={6} xs={6} style={{textAlign:'-webkit-right'}} className="mobile-header d-none">
									<Grid container  id="userwayBtn" tabIndex="0" direction="row"  sx={{justifyContent:'space-evenly',alignSelf:"center",border:'1px solid gray',padding:'0.5% 0',width:'8em',borderRadius:'5px',cursor:'pointer',}}>                                
										<LanguageIcon  sx={{ color: "black" }} />
										<Typography  sx={{ color: "black" }}>en/esp/fr/...</Typography>                                
									</Grid> 
								
									<div>
								<Box sx={{ display: { md: 'flex' } }}>
									<IconButton
										size="large"
										edge="end"
										aria-label="account of current user"
										aria-haspopup="true"
										onClick={handleProfileMenuOpen}
										color="color">
										<StyledBadge
											overlap="circular"
											anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
											variant="dot">
											<AccountCircleSharpIcon sx={{ fontSize: 40 }} />
										</StyledBadge>
										<Typography>
											{
												switchedAccount
													?
													admin.first_name + " " + admin.last_name
													:
													user.first_name + " " + user.last_name
											}
											{/* {user.first_name + " " + user.last_name} */}
										</Typography>
									</IconButton>
								</Box>
								</div>
								</Grid>

							</Grid>


							<Grid  className="mobile-header" >
								
							<div >
													{
															switchedAccount
																?
																	<Box sx={{ backgroundColor: "#9FD37C", pl: 1, pr: 1,marginTop:'3%' }}>
																		<Grid container item lg={12} md={12} sm={12} xs={12} spacing={1} sx={{ justifyContent: "center" }}>
																			<Grid item lg={4} md={4} sm={12} xs={12} sx={{ mt: 0.5 }}><Typography sx={{ color: "white", textAlign: "center", mt: 1 }}>You Are Viewing As A {companyDetails ? companyDetails.company_name : ""}</Typography></Grid>
																			<Grid item lg={3} md={3} sm={3} xs={3} ><Typography sx={{ color: "black" }}>User</Typography><Typography sx={{ color: "black" }}><b>{user ? user.name : ''}</b></Typography></Grid>
																			<Grid item lg={3} md={3} sm={3} xs={3}><Typography sx={{ color: "black" }}>Role</Typography><Typography sx={{ color: "black" }}><b>Affiliate</b></Typography></Grid>
																			{/* <Grid item lg={2} md={2} sm={3} xs={3} ><FormControl fullWidth margin="dense" size="small" sx={{textAlign:"center"}}>
																				<InputLabel id="demo-simple-select-label">Change User</InputLabel>
																				<Select
																				labelId="demo-simple-select-label"
																				id="demo-simple-select"
																				value={age}
																				label="Change User"
																				onChange={handleChange}
																				>
																				<MenuItem value={10}>Ten</MenuItem>
																				<MenuItem value={20}>Twenty</MenuItem>
																				<MenuItem value={30}>Thirty</MenuItem>
																				</Select>
																			</FormControl></Grid> */}
																			<Grid item lg={2} md={2} sm={3} xs={3} sx={{ textAlign: "center", mt: 1 }}><Button variant="contained" color="error" sx={{ backgroundColor: "#F07E38", color: "white" }} onClick={(e) => { handleAffiliateLogout() }}>Switch</Button></Grid>
																		</Grid>
																	</Box>
																	:
																	<>
																	{user.roles_id >= 3 ? 
																		<>
																		<Box className="socialMediaIcons" sx={{ display: 'flex',justifyContent:'center' }}>
																			
																			<Grid item lg={12} md={6} sm={12} xs={12} sx={{ mt: 0.5 }}><div className="iconsimp"><b>Affiliate support also available on </b>&nbsp;
																				
																				<div style={{display:'flex'}}>
																				<a href='https://m.me/myfreescorenow' target='_blank'><img src={messangerIcon} alt='messenger img' /></a>&nbsp;
																				<a href='https://api-wa.co/JrzyQs' target='_blank'><img src={wstpImgIcon} alt='whatsapp img' /></a>&nbsp;
																				<a href='https://ig.me/m/myfreescorenow' target='_blank'><img src={InstagramIcon} alt='instagram img' /></a>
																					</div></div>
																			</Grid>
																							
																		</Box>
																		</>
																	: '' }
																	<Box sx={{ flexGrow: 1 }}></Box>
																	
																</>

																
														}
														
													</div>
													
							</Grid>
						</Toolbar>
					</AppBar>
					{/* {renderMobileMenu} */}
					{renderMenu}
					{/* <AppBar sx={{ background: 'green' }}>
					<Toolbar>
						<Box >
							<Typography>
								You are viewing as a Company Name 

							</Typography>
						</Box>
					</Toolbar>
				</AppBar> */}
				</Box>
			</AppBar>
			<Box className={window.location.pathname==='/affiliate/initialcobrandsetup'?'drawerHide':''} 
			sx={{marginTop:`${localStorage.getItem("appBarHeight")} px`}}>
				<Drawer variant="permanent" open={open} >
					<List className="openmenu" sx={{marginTop:`${localStorage.getItem("appBarHeight")}px !important`}}>
						<ListItem >
							<IconButton
								color="inherit"
								aria-label="open drawer"
								onClick={handleDrawerOpen}
								// edge="start"
								edge="end"
								sx={{
									marginRight: 5,
									...(open && { display: 'none' }),
									justifyContent: open ? 'initial' : 'center',
								}}

							>
								<MenuIcon />
							</IconButton>
							<IconButton
								color="inherit"
								aria-label="close drawer"
								onClick={handleDrawerClose}
								// edge="start"
								edge="end"
								sx={{
									marginRight: 5,
									...(!open && { display: 'none' }),
									justifyContent: open ? 'initial' : 'center',
								}}
							>
								{theme.direction === 'rtl' ? <ChevronRightIcon /> : <MenuOpen />}
							</IconButton>
						</ListItem>
						{
							menuList.map((menu, index) => (
								<MuiThemeProvider theme={defaultTheme} key={index}>
									<BlueOnGreenTooltip title={user.roles_id <= 3
										?
										menu.name
										:
										(menu.name == "Referral Program" && referralflag == 0) || (menu.name == "Credit Snapshot" && SnapShotStatus == 0) ? "This panel is disabled" : menu.name
									} placement="right" arrow>
										<ListItem
											selected={location.pathname == menu.url}
											key={menu.name}
											disablePadding
											sx={{ display: 'block', color: "black" }}
											component={Link}
											to={menu.url}
											anchorel={anchorMenu}
											onClick={menu.disabled ? '' : handleMenuItemClick}
											disabled={menu.disabled}
										>
											<ListItemButton
												sx={{
													minHeight: 48,
													justifyContent: open ? 'initial' : 'center',
													px: 2.5,
												}}
											>
												<ListItemIcon
													sx={{
														minWidth: 0,
														mr: open ? 3 : 'auto',
														justifyContent: 'center',
													}}
												>
													<img src={require('../assets/icons/Sidebar/' + menu.icon)} width={30} />
												</ListItemIcon>
												<ListItemText primary={menu.name} sx={{ opacity: open ? 1 : 0 }} />
												{menu.name == 'Lead Generation' ? <Chip label="Enable" color="primary" size="small" /> :  (menu.name == "Tax Information" && user.roles_id === 3 &&  taxFlag == 2 ? <Chip label="Required"  color="error" size="small" sx={{fontSize:'12px'}}/> : "")}
											</ListItemButton>
										</ListItem>
									</BlueOnGreenTooltip>
								</MuiThemeProvider>
							))
						}
					</List>
				</Drawer>
			</Box>
		</div >
	);
}
export default AppSidebar;