import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { DialogContent, Grid, Select, FormControl, MenuItem, InputLabel, Box, TextField, Dialog, InputAdornment, DialogActions, OutlinedInput, DialogTitle } from '@mui/material';
import { Typography, Card, CardContent, CardOverflow, Button, List, ListItem, ListItemDecorator, ListItemContent } from '@mui/joy';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../redux/apiSlice';
import config from '../../AppConfig';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import Freez from '../../Components/Loader/Loader';
import Backdrop from '@mui/material/Backdrop';

function ShowReferralLink(props) {
    const [open1, setOpen1] = React.useState(false);
    const [freez, setFreez] = React.useState(false);
    const [referalinfo, setReferalInfo] = useState([]);
    const dispatch = useDispatch();
    const [fullWidth, setFullWidth] = React.useState(true);
    const [url, setUrl] = useState();
    const [copySuccess, setCopySuccess] = useState('');
    const { id } = useParams();
    useEffect(() => {
        GetReferalinfo();
    }, []);
    
    const GetReferalinfo = async () => {
        handleFreezStart();
        const apiUrl = '/fetchReferralInfo/' + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
          if (res.success == true) {
            handleFreezStop();
            setReferalInfo(res.data);
            setUrl(config.BASE_URL + res.data.link);
            console.log("url:"+res.data.link);
          } else {
            handleFreezStop();
          }
          setOpen1(true);
        }
    }

    const handleFreezStop = () => {
        setFreez(false);
    };

    const handleFreezStart = () => {
    setFreez(true);
    };

      
    return (
        <div>
            <div>
            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={freez}
            // onClick={handleFreezStop}
            >
            <Freez />
            </Backdrop>
        </div>
        <Dialog
            fullWidth={fullWidth}
            maxWidth={'md'}
            open={open1}
            // onClose={handleClose1}
        >
            <DialogTitle>
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
                        <Typography level="h4">Affiliate Referral Link</Typography>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3} >
                    {/* <Grid item lg={7} md={7} sm={7} xs={7} > */}
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center", textAlign: 'center' }} >
                                <p style={{ margin: 0,fontWeight:'bold' }} >
                                {referalinfo.link ? config.BASE_URL + referalinfo.link + '=': "Getting Link"} &nbsp;&nbsp;&nbsp;
                                    <ContentCopyOutlinedIcon text={config.BASE_URL + referalinfo.link + '='} sx={{ fontSize: "15px", color: "blue" }}
                                        onClick={() => {
                                            navigator.clipboard.writeText(url ? config.BASE_URL + referalinfo.link + '=' : "Getting Link")
                                            setCopySuccess(url ?config.BASE_URL + referalinfo.link + '=' : "Getting Link")
                                            setTimeout(() => {
                                            setCopySuccess('');
                                            }, 5000);
                                        }} />{copySuccess ? "Copied!" : ''}
                                </p>
                            </Grid>
                        </Grid>
                    {/* </Grid> */}
                </Grid>
            </DialogContent>
        </Dialog>

        </div>
    );
}

export default ShowReferralLink;