import React, { useEffect, useState, useRef, } from 'react';
import { Grid, Step, Stepper, StepLabel, DialogContent, Divider,useMediaQuery, DialogContentText, Button, TextField, FormGroup, FormControlLabel, Checkbox, FormControl,IconButton,InputLabel,OutlinedInput,InputAdornment, FormLabel, Tooltip, RadioGroup, Radio, Table, FormHelperText, Box, Paper,Stack,Typography,Card } from '@mui/material';
import SecureWebsite from "../../../Components/Enrollment/SecureWebsite";
import { ReactComponent as ChatIcon } from "../../../assets/images/Chatbutton.svg";
import { ReactComponent as EmailIcon } from "../../../assets/images/Emailbutton.svg";
import { ReactComponent as PlayIcon } from "../../../assets/images/Playbutton.svg";
const Step4 = ({ btnTextColor,primaryColor, textColorProp, popupFlage,hyperlinkColor,onValueChange3 ,chatEnabled,emailEnabled,step3Option, setStep3Option, step3Link, setStep3Link,getVideoOptions,buttonColor,buttonTextColor}) => {
    const [textColor, setTextColor] = useState()
    const [radioSelect, setRadioSelect] = useState(step3Option || ""); // Ensure default value
    useEffect(() => {
        // if (step3Option) { // Ensuring it's not empty or undefined
            setRadioSelect(step3Option);
        // }
    }, [step3Option]);
    useEffect(() => {
        setTextColor(textColorProp)
    }, [textColorProp])

const isMobile = useMediaQuery('(max-width:600px)');
        // const [step3Option, setStep3Option] = useState("");
        const [uploadedFile, setUploadedFile] = useState(null); // State for the uploaded file
        // const [step3Link, setStep3Link] = useState(step_3_Link);
        const handleStep1RadioChange = (event) => {
            setUploadedFile(null);
            setStep3Option( event.target.value);
        };

        const handleFileUpload3 = (event) => {
            const file = event.target.files[0]; // Get the uploaded file
            const maxSize = 10 * 1024 * 1024;
            if (file) {
                if (file.size > maxSize) {
                    alert("Video size should not exceed 10MB"); // Display error message
                    event.target.value = ""; // Clear the file input
                    return;
                  }
                setUploadedFile(file);
                setStep3Link(file);
                onValueChange3(file);
            }
        };
    return (
        <>
            <div style={{ pointerEvents: 'none' }}>
                <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <TextField size='small' label="Card Number" margin='dense' fullWidth sx={{
                       label: { color: `${primaryColor}` },
                        "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${primaryColor}`, backgroundColor: 'white' }, // Styles the fieldset
                    }} />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <TextField size='small' label="CVV" margin='dense' fullWidth sx={{
                       label: { color: `${primaryColor}` },
                        "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${primaryColor}`, backgroundColor: 'white' }, // Styles the fieldset
                    }} />
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                        <FormControl margin="dense" variant="outlined" fullWidth >
                            <TextField
                                label="Expiration Month"
                                sx={{
                                   label: { color: `${primaryColor}` },
                                    "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${primaryColor}`, backgroundColor: 'white' }, // Styles the fieldset
                                }}
                            >
                            </TextField>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                        <FormControl margin="dense" variant="outlined" fullWidth >
                            <TextField
                                label="Expiration Year"
                                sx={{
                                    label: { color: `${primaryColor}` },
                                    "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${primaryColor}`, backgroundColor: 'white' }, // Styles the fieldset
                                }}
                            >
                            </TextField>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <div className="form-group">
                        <div className=" pull-right input-group checkbox">
                            <label htmlFor="isConfirmedTerms" style={{ color: `${textColor}` }}>
                                <input type="checkbox"
                                    style={{
                                        appearance: 'none', // Hide the default appearance
                                        width: '12px',
                                        height: '12px',
                                        border: `1px solid ${primaryColor}`, // Border color for unchecked state
                                        borderRadius: '2px',
                                        backgroundColor: `${primaryColor}`, // Background color when checked
                                        backgroundImage: 'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'%23fff\'><path d=\'M13.6 1.6L5 10.2 2.4 7.6 1 9l4 4 9-9-1.4-1.4z\'/></svg>")', // Checkmark background image when checked
                                        color: 'white',
                                        marginRight: '5px', // Adjust spacing
                                        cursor: 'pointer', // Show pointer cursor on hover
                                    }}
                                />
                                &nbsp;My billing address is same as current address
                            </label>
                        </div>
                    </div>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <div className="form-group">
                        <div className=" pull-right input-group checkbox">
                            <label htmlFor="isConfirmedTerms" style={{ color: `${textColor}` }}>
                                <input type="checkbox"
                                    style={{
                                        appearance: 'none', // Hide the default appearance
                                        width: '12px',
                                        height: '12px',
                                        border: `1px solid ${primaryColor}`, // Border color for unchecked state
                                        borderRadius: '2px',
                                        color: 'white',
                                        marginRight: '5px', // Adjust spacing
                                        cursor: 'pointer', // Show pointer cursor on hover
                                        textWrap:"wrap"
                                    }}
                                />
                                &nbsp;By checking this box: I acknowledge that I have read, understand, agree to and accept the &nbsp;
                                <a style={{ whiteSpace: 'nowrap', cursor: "pointer" ,color:`${hyperlinkColor}`,textWrap:"wrap"}} href="#">
                                    Offer Details </a>&nbsp;,&nbsp;
                                    <a style={{ whiteSpace: 'nowrap', cursor: "pointer" ,color:`${hyperlinkColor}`,textWrap:"wrap"}} href="#">
                                    Terms and Conditions </a>&nbsp;,&nbsp;
                                <a href="#" style={{ cursor: "pointer",color:`${hyperlinkColor}`,textWrap:"wrap" }}>
                                    Cancellation Policy
                                </a>, and&nbsp;
                                <a href="#" style={{ cursor: "pointer",color:`${hyperlinkColor}` }}>
                                    Privacy Policy
                                </a> .
                            </label>
                        </div>
                    </div>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} mt={2} sx={{ justifyContent: "right", display: "flex" }}>
               <Button variant='contained' size="large" sx={{
                        minWidth: "60%", backgroundColor: `${buttonColor}`, color: `${buttonTextColor}`,
                        '&:hover': {
                            backgroundColor: `${buttonColor}`
                        }
                    }} className='popup-next-btn'>
                        Submit My Order<br />Take Me to My Scores
                    </Button>
                </Grid>
                <SecureWebsite textColor={textColorProp} hyperlinkColor={hyperlinkColor}/> 
            </div>
            {!popupFlage && (
            <div>
            
            <Card variant="outlined" sx={{border:"1px solid black",padding:"2%"}}>
                    <Grid  item lg={12} md={12} sm={12} xs={12}>
                        <Grid container item lg={12} md={12} xs={12} sm={12} sx={{justifyContent:"flex-end"}}>
                                    {step3Option === 'uploadVideo'?
                                <Typography sx={{color:"red",fontSize:"12px"}}>(Note: Video Size Should Not Exceed 10Mb)</Typography>
                                :""} 
                        </Grid>
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={radioSelect}
                                onChange={(event) => handleStep1RadioChange(event)}
                            >
                                <Stack direction="row" >
                                <FormControlLabel value="youTube" control={<Radio />} label={<img
                                    src={require('../../../assets/images/youtubeSnap.png')} // Replace with male icon URL
                                    alt="Youtube"

                                    style={{ marginRight: 8,width:"35px" }}
                                    />} />
                                <FormControlLabel value="vimeo" control={<Radio />} label={<img
                                    src={require('../../../assets/images/vimeoLogo.png')} // Replace with male icon URL
                                    alt="Male"
                                    style={{ marginRight: 8 ,width:"55px"}}
                                    />} />
                                <FormControlLabel value="uploadVideo" control={<Radio />} label="Upload Video" />
                                </Stack>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid  item lg={12} md={12} sm={12} xs={12}>
                        {step3Option === 'youTube' && (
                            <>
                            <TextField
                                label="YoutTube Video Url"
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                size="small"
                                id="step3Link"
                                name="step3Link"
                                value={getVideoOptions(step3Link) == 'youTube' ? step3Link :''}
                                onChange={(e) => {setStep3Link(e.target.value); onValueChange3(e.target.value);}}
                                
                            />
                            </>
                         )} 
                        {step3Option === 'vimeo' && (
                            <>
                            <TextField
                                label="Vimeo Video URL"
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                size="small"
                                id="step3Link"
                                name="step3Link"
                                value={getVideoOptions(step3Link) == 'vimeo' ? step3Link :''}
                                onChange={(e) => {setStep3Link(e.target.value); onValueChange3(e.target.value);}}
                            />
                            </>
                         )} 
                        
                        {step3Option === 'uploadVideo' && (
                            <>
                            <FormControl variant="outlined" fullWidth margin="dense" size="small">
                            <InputLabel htmlFor="uploadedLogoStep1">Upload Your Video</InputLabel>
                            <OutlinedInput
                                id="uploadedLogo"
                                type="text" // Show the file name instead of the file picker
                                value={typeof step3Link === "string" && step3Link.includes('mp4')
                                    ? step3Link.split('/').pop().split('_').slice(1).join('_')
                                    : uploadedFile?.name || ""} // Show the file name in the input field
                                // value={uploadedFile?.name || step3Link}
                                endAdornment={
                                <InputAdornment position="end">
                                    <Tooltip
                                    title={"Upload Video"}
                                    >
                                    <IconButton
                                        edge="end"
                                        onClick={() => document.getElementById("hiddenFileInputStep1").click()}
                                    >
                                        <img
                                        src={require("../../../assets/images/uploadFile.png")}
                                        style={{ height: "25px" }}
                                        alt="Upload"
                                        />
                                    </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                                }
                                label="Upload Your Video"
                            />
                            <input
                                id="hiddenFileInputStep1"
                                type="file"
                                name="uploaded"
                                style={{ display: "none" }} // Hidden input
                                accept="video/*"
                                onChange={handleFileUpload3}
                                // onChange={(event) => {
                                // const file = event.target.files[0];
                                // if (file) {
                                //     setFieldValue("step3Link", file); 
                                // }
                                // }}
                            />
                             
                            </FormControl>
                           
                            </>
                        )} 
                    </Grid>
            </Card>  
        
        </div>     
 )
}

            {popupFlage == true ?
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ display:"flex",justifyContent: isMobile?"center":"end",}}>
                                          <Grid container item lg={12} md={12} sm={12} xs={12} sx={{mt:isMobile?4:0}}>
                                          {chatEnabled == true ? (
                                            <>
                                                  <Grid item lg={4} md={4} sm={12} xs={12} mt={1} sx={{ display:"flex",justifyContent:"center",marginTop:isMobile?2:0 }}>
                                                    
                                                  <Button varient="outlined"
                                                    // onClick={openChatPopup}
                                                  startIcon={<ChatIcon style={{ width: 30, height: 30, fill: `${buttonTextColor}` }} />}
                                                //   sx={{
                                                //     textTransform: "none",
                                                //     border:"1px solid #2E67BC",
                                                //     minWidth:'80%' ,
                                                //     maxWidth:'100%',
                                                //     color:"black",
                                                //     alignItems:"center",
                                                //     fontWeight:"bold"// Prevents uppercase transformation
                                                //   }}
                                                sx={{
                                                    textTransform: "none !important",minWidth:'80%',maxWidth:'100%', backgroundColor: `${buttonColor}`, color: `${buttonTextColor}`, borderRadius: "10px", '&:hover': {
                                                        backgroundColor: `${buttonColor}`
                                                    }
                                                }}
                                                  >Chat With Us Now</Button>
                                                
                                                </Grid>
                                               </> ) :''  } 
                                           {emailEnabled == true ? (
                                            <>
                                            <Grid item lg={4} md={4} sm={12} xs={12}  mt={1} sx={{ display:"flex",justifyContent:"center",marginTop:isMobile?2:0 }}>
                                                    <Button  
                                                    startIcon={<EmailIcon style={{ width: 30, height: 30, fill: `${buttonTextColor}` }} />}
                                                    // onClick={openEmailChatPopup}
                                                    // sx={{
                                                    //   textTransform: "none",
                                                    //   border:"1px solid #2E67BC",
                                                    //   minWidth:'80%' ,
                                                    //   maxWidth:'100%',
                                                    //   color:"black",
                                                    //   alignItems:"center",
                                                    //   fontWeight:"bold" // Prevents uppercase transformation
                                                    // }}
                                                    sx={{
                                                        textTransform: "none !important",minWidth:'80%',maxWidth:'100%', backgroundColor: `${buttonColor}`, color: `${buttonTextColor}`, borderRadius: "10px", '&:hover': {
                                                            backgroundColor: `${buttonColor}`
                                                        }
                                                    }}
                                                    >Email Us Now</Button>
                                              </Grid>
                                              </>) :''  } 
                                              {step3Link ?( <>
                                                <Grid item lg={4} md={4} sm={12} xs={12} mt={1} sx={{ display:"flex",justifyContent:"center",marginTop:isMobile?2:0 }}>
                                                  <Button
                                                    startIcon={<PlayIcon style={{ width: 30, height: 30, fill: `${buttonTextColor}` }} />}
                                                    // sx={{
                                                    //   textTransform: "none",
                                                    //   border:"1px solid #2E67BC", 
                                                    //   minWidth:'80%' ,
                                                    //   maxWidth:'100%',
                                                    //   alignItems:"center",
                                                    //   color:"black",
                                                    //   fontWeight:"bold"
                                                    // }}
                                                    sx={{
                                                        textTransform: "none !important",minWidth:'80%',maxWidth:'100%', backgroundColor: `${buttonColor}`, color: `${buttonTextColor}`, borderRadius: "10px", '&:hover': {
                                                            backgroundColor: `${buttonColor}`
                                                        }
                                                    }}
                                                    >Learn More About Us
                                                  </Button>
                                              </Grid>
                                              </>): ""}
                                              
                                         
                                        </Grid>
                                           
                                </Grid>
                         : ""
                         }       

        </>
    );
}
export default Step4;