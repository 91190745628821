import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AuthUser from "../../Components/Auth/AuthUser";
import swal from "sweetalert";

// Validation schema
const validationSchema = Yup.object({
  selectedQuestion: Yup.string().required("Please select a security question."),
  answer: Yup.string()
    .required("Please provide an answer.")
    .min(3, "Answer must be at least 3 characters."),
});

const QuestionAnswerDialog = ({ open, onClose, onSubmitSuccess, handleFreez }) => {
  const initialValues = {
    selectedQuestion: "",
    answer: "",
  };

  const { http } = AuthUser();
  const [freez, setFreez] = useState(false);
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false); // Track success
  const [responseData, setResponseData] = useState(null); // Store response data
  const [questions, setQuestions] = useState(JSON.parse(localStorage.getItem("SecurityQuestions")));

  const handleFreezStart = () => setFreez(true);
  const handleFreezStop = () => setFreez(false);

  const handleFormSubmit = async (values) => {
    onClose();
    handleFreez();

    try {
      const response = await http.post("/registerEnrollment", {
        step: 5,
        securityQuestionId: values.selectedQuestion,
        securityQuestionAnswer: values.answer,
        email: localStorage.getItem("Main_email"),
        password: localStorage.getItem("tempPassword"),
        customerToken: localStorage.getItem("customerToken"),
      });

      handleFreezStop();

      if (response.data.success === true) {
        setResponseData(response.data);
        onSubmitSuccess(responseData);
      } else {
        const errorMessage =
          response.data.data?.errorByCD || "An error occurred.";
        swal({
          title: "Failed",
          text: errorMessage,
          icon: "error",
          button: "Ok",
        });
      }
    } catch (error) {
      handleFreezStop();
      swal({
        title: "Error",
        text: error.message || "An error occurred during the request.",
        icon: "error",
        button: "Ok",
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
        <DialogTitle id="alert-dialog-title">Select Security Question</DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
            <Form>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      size="small"
                      error={touched.selectedQuestion && Boolean(errors.selectedQuestion)}
                    >
                      <InputLabel id="question-label">Select Question</InputLabel>
                      <Select
                        labelId="question-label"
                        name="selectedQuestion"
                        value={values.selectedQuestion}
                        onChange={(e) => setFieldValue("selectedQuestion", e.target.value)}
                        onBlur={handleBlur("selectedQuestion")}
                      >
                        {questions.map((q) => (
                          <MenuItem key={q.securityQuestionId} value={q.securityQuestionId}>
                            {q.question}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.selectedQuestion && errors.selectedQuestion && (
                        <FormHelperText>{errors.selectedQuestion}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id="answer"
                      name="answer"
                      label="Answer"
                      fullWidth
                      size="small"
                      margin="dense"
                      value={values.answer}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.answer && Boolean(errors.answer)}
                      helperText={touched.answer && errors.answer}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onClose}
                  sx={{ minWidth: 150 }}
                  disabled={freez}
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="success"
                  sx={{ minWidth: 150 }}
                  disabled={freez}
                >
                  {freez ? "Submitting..." : "Submit"}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
    </Dialog>
  );
};

export default QuestionAnswerDialog;