import React, { useMemo, useState, useEffect, useRef } from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import MaterialReactTable from 'material-react-table';
import AuthUser from '../../../Components/Auth/AuthUser';
import { Box, Table, TableBody, TableCell, InputLabel, OutlinedInput, Button, MenuItem, FormControl, Select, Grid, TableContainer, TableHead, TableRow, Paper,  Dialog, DialogTitle, DialogContent } from '@mui/material';
import { DateRangePicker,createStaticRanges } from 'react-date-range';
import { format, subDays } from 'date-fns';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import { useNavigate } from "react-router-dom";
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader'
import Typography from '@mui/joy/Typography';
import config from '../../../AppConfig';
import Badge from '@mui/material/Badge';
import Moment from 'moment';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import { calendarFilter,useDateSelectionRange } from '../../../Components/CommonFormula';
import StarIcon from '@mui/icons-material/StarRate';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slider from "react-slick";
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
];
function UserList1(props) {

  //neccessary variable declearation start

  const { http, user } = AuthUser();
  const dispatch = useDispatch();
  const [companylist, setCompanyList] = useState([]);
  const [filterCompany, setFilterCompany] = useState([]);
  const [search, setSearch] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const q = search == '' ? 'null' : search;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  //neccessary variable declearation end
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const [rowSelection, setRowSelection] = useState({});
  //loader function and variable start
  const [freez, setFreez] = React.useState(true);
  const { selectionRange, setSelectionRange, startDate, endDate,clearFilter } = useDateSelectionRange();
  const [reviewsList, setReviwsList] = useState([]);
  const [reviewFlag, setReviewFlag] = useState(0);
  const [totalReviwsList, setTotalReviwsList] = useState([]);
  const [openSlider, setOpenSlider] = React.useState(false);
  const[index,setIndex]= useState(0);

  const handleFreezStop = () => {
    setFreez(false);
  };
  const handleFreezStart = () => {
    setFreez(true);
  };
  const [filter, setFilter] = useState("Software Referral");
  const option = ["Software Referral", "All Referral"];

  //loader function and variable end

  const tablePreferencesArray = {

  }
  useEffect(() => {
  }, [rowSelection]);
  ////////////////////////
  useEffect(() => {
    if (Object.values(columnVisibility).length > 0) {
      setColumnVisibility(columnVisibility);
    }
    //do something when the row selection changes...
    // console.info({ rowSelection });
  }, [rowSelection]);

  useEffect(() => {
    tablePreferences();
  }, [rowSelection]);
  ////////////////////////

  useEffect(() => {
    if (Object.values(columnVisibility).length > 0) {
      http.post('/saveTablePreferences', {
        columnArray: columnVisibility,
        userId: user.id,
        tableId: 'softwarereferrals'
      }).then((result) => {
      })
    }
  }, [columnVisibility])
  const tablePreferences = () => {
    http.get('/fetchTablePreferences/softwarereferrals')
      .then((res) => {
        if (Object.values(res.data).length == 0) {
          setColumnVisibility(tablePreferencesArray);
        } else {
          setColumnVisibility((JSON.parse(res.data.preferences)));
        }
      });
  }
  //Date range select function start
  
  //Clear date ranges start
  const sideBar = calendarFilter();
  const staticRanges = [
      // ...defaultStaticRanges,
      ...createStaticRanges(sideBar)
  ];
 

  const  ChangeFilter  = async (event) => {
    var value = event.target.value;
    setFilter(value);
  };

  const fetchReviewsList = async () => {
    handleFreezStart();
    const apiUrl = '/reviews-data/1/10';
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if (res) {
        setReviwsList(res.data.reviews.reviews);
        // console.log(JSON.stringify(res))
        const url = `/reviews-data/1/${res.data.reviews.total_results}`;
        const resp = await dispatch(fetchData(url)).unwrap();
        if (resp.status == "success") {
            setTotalReviwsList(resp.data.reviews.reviews);
            handleFreezStop();
            setReviewFlag(1)
        } else {
            setReviewFlag(1)
        }
    }
  
  };
  
  const handleClickClose = () => {
    setOpenSlider(false);
  };
  
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "80px",
    slidesToShow: 1,
    speed: 500,
    lazyLoad: 'ondemand',
    responsive: [
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerPadding: "0px",
            }
        }
    ]
  };
  
  const sliderRef = useRef(null);
  
  const goToFirstSlide=()=>{
    if (sliderRef.current) {
        sliderRef.current.slickGoTo(0);
        setIndex(0);
      }
  }

 

  

  //fetchuserlist function start


  //Get softwareReferraltList function start

  const softwareReferraltList = async () => {
    handleFreezStart();
    const apiUrl = '/softwareReferralReport';
    const args = {
      fromDate: startDate,
      toDate: endDate,
      filter: filter
    }
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if (res) {
      if (res.success === true) {
        setCompanyList(res.data);
        setFilterCompany(res.data)
        setTotalRows(res.total);
        setIsLoading(false);
        handleFreezStop();
      } else {
        handleFreezStop();
      }

    }
  }
  useEffect(() => {
    softwareReferraltList();
    // fetchReviewsList();
  }, []);

  const handleClickOpen = (index) => {
    setOpenSlider(true);
    setIndex(index)
  };

  //Get softwareReferraltList function start


  //columns array start

  const UserListColumns = useMemo(
    //column definitions...
    () => [


      {
        accessorFn: (row) => `${row.software_name ? row.software_name.trim() : ''}`, 
        header: 'Name',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.software_name ? row.original.software_name.trim() : "N/A"),
      },
      {
        accessorFn: (row) => `${row.referred_affiliate_count}`,
        filterVariant: 'range',
        header: 'Referred Affiliate Count.',
        size: 150,
        Cell: ({ renderedCellValue, row }) => (
          <div style={{ textAlign: "center" }}>
            <Badge badgeContent={`${row.original.referred_affiliate_count}`} color={row.original.referred_affiliate_count === 0 ? 'primary' : 'success'} overlap="circular" max={9999}></Badge>
          </div>
        ),
        //custom conditional format and styling
      },
      {
        accessorFn: (row) => `${row.referred_enrollment_count}`,//accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: true,
        header: 'Referred Enrollment Count',
        size: 150,
        muiTableBodyCellCopyButtonProps: {
          fullWidth: true,
          sx: { justifyContent: 'flex-center' },
        },
        Cell: ({ renderedCellValue, row }) => (
          <div style={{ textAlign: "center" }}>
            <Badge badgeContent={`${row.original.referred_enrollment_count}`} color={row.original.referred_enrollment_count === 0 ? 'primary' : 'success'} overlap="circular" max={99999}></Badge>
          </div>
        ),
      },
    ]
  )
  //csv start here
  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    fileName: 'Software-Referral',
    headers: ['Name', 'Referred Affilate Count.', 'Referred Enrollment Count'],
  };

  const csvExporter = new ExportToCsv(csvOptions);
  const handleExportData = (filterCompany) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
        navigate('/login');
        return false;
    }
    const exportedData = [];
    filterCompany.forEach((row) => {
      const mainTableRow = {
        name: `${row.original.software_name}` || 'N/A',
        referred_affiliate_count: `${row.original.referred_affiliate_count}` || 'N/A',
        referred_enrollment_count: `${row.original.referred_enrollment_count}` || 'N/A',
      };
      exportedData.push(mainTableRow);
      const subheader = {
        'ID':'ID',
				'Name': 'Name', // Subheader 1
				'Email': 'Email', // Subheader 2
				'Phone No': 'Phone No', // Subheader 3
				'Approved Date': 'Approved Date', // Subheader 4
				'Referred Affiliate': 'Referred Affiliate', // Subheader 5
				'Member Count': 'Member Count', // Subheader 6
			};
      exportedData.push(subheader);
      if (row.original.userList && row.original.userList) {
				row.original.userList.map((rows,idx) => {
					const subRow = {
            id:idx+1,
						name: rows.name,
            email: rows.email,
            phone_no: rows.phone_no,
            approved_date: Moment(rows.approved_date).format('MM-DD-YYYY H:mm'),
            referred_affiliate: rows.company_name,
            member_count: rows.member_count
          };
          exportedData.push(subRow);
        });
      } else {
        // If no subrows available, add an empty row for subrows
        exportedData.push({});
      }
      exportedData.push({});
  
    });
    const csvContent = convertToCSV(exportedData);
    // Create a Blob object from the CSV data
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    // Create a download link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = csvOptions.fileName + '.csv';

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the file download
    link.click();

    // Clean up the temporary URL and remove the link from the document
    URL.revokeObjectURL(link.href);
    link.remove();
  };

  const convertToCSV = (data) => {
    const headers = csvOptions.headers.join(',') + '\n';

    const rows = data.map((row) => {
      return Object.values(row).map((value) => {
        // Handle any necessary formatting or escaping of values
        // For simplicity, we assume all values are already properly formatted

        // Wrap the value with double quotes if it contains a comma
        if (typeof value === 'string' && value.includes(',')) {
          return `"${value}"`;
        }

        return value;
      }).join(',');
    }).join('\n');

    return headers + rows;
  };
  //csv end here
  const ContentLayout=
      <>
       {/* <Grid container item lg={12} md={12} sm={12} xs={12}>
            <div className="marquee-container">
                <marquee behavior="scroll" direction="left" scrollamount="4" loop="infinite">
                    {reviewsList.map((item, index) => (
                        <div onClick={()=>handleClickOpen(index)} className='card' key={index}>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <Grid item lg={3} md={3} sm={3} xs={3}>
                                    <div className="profile">
                                    <Typography level="h4" color='primary'>{(item.displayname).charAt(0)}</Typography></div>
                                </Grid>
                                <Grid item lg={9} md={9} sm={9} xs={9}>
                                    <div className='wrapper-profil-info'>
                                    <Typography level="h6">{item.displayname}</Typography>
                                    <Typography level="body1">{Moment(item.date).format('MM-DD-YYYY')}</Typography>
                                    </div>
                                    {[...new Array(item.rating)].map(_ => <StarIcon sx={{ color: "#76C044" }} />)}
                                    <Typography level="body1" className="text-start" sx={{textWrap:'auto'}}>{(item.review && item.review.trim() !== '') ? (item.review.slice(0, 55) + "...") : (item.settings && item.settings.placeholder_text && (item.settings.placeholder_text).slice(0, 25) + "..." )}</Typography>
                                </Grid>
                            </Grid>
                        </div>
                    ))}
                </marquee>
            </div>
        </Grid> */}
        <Grid container item lg={12} md={12} sm={12} xs={12}>
          <Typography color="primary" level="h4"> Software Referral Reports</Typography>
        </Grid>
        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: 'space-between' }} mt={2}>
          <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
          <Grid item lg={3} md={4} sm={4} xs={4}>
              <FormControl fullWidth size='small'>
                <InputLabel id="demo-multiple-name-label">Select Filter</InputLabel>
                <Select
                  margin="dense"
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  value={filter}
                  onChange={(e) => {ChangeFilter(e)}}
                  input={<OutlinedInput label="Select Filter" />}
                >
                  {option.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item lg={4} md={4} sm={4} xs={4}>
              <FormControl fullWidth size='small'>
                <InputLabel id="demo-multiple-name-label">Select Date Range</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  value="date"
                  input={<OutlinedInput label="Select Date Range" />}
                >
                  <MenuItem value="date" >{startDate ? <>{startDate} To {endDate}</> : ''}</MenuItem>
                  <DateRangePicker
                    className='custom-date-picker'
                    onChange={item => setSelectionRange([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={selectionRange}
                    maxDate={new Date()}
                    direction="horizontal"
                    staticRanges={staticRanges}
                  />
                 
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={4}>
              <Button variant="contained" color="success" sx={{ minWidth: 200 }} onClick={softwareReferraltList} > Submit</Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid mt={1}>
          <MaterialReactTable
            columns={UserListColumns}
            data={filterCompany}
            filterFns={{
              customSearchFilterFn: (row, id, filterValue) =>
              row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
            }} 
            globalFilterFn="customSearchFilterFn" //exact serach filter functionality
            enableColumnFilterModes
            enableRowSelection={false}
            enableColumnFilters={config.DatatableColumnFilter}
            initialState={{ showColumnFilters: false, density: 'compact' }}
            positionToolbarAlertBanner="bottom"
            enableDensityToggle={false}
            onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
            state={{ columnVisibility, rowSelection, isLoading: isLoading }}
            onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
            muiTableHeadCellProps={{
              //simple styling with the `sx` prop, works just like a style prop in this example
              sx: {
                backgroundColor: "#F6FAFF",
              },
            }}
            renderDetailPanel={({ row }) => (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 500 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>ID</TableCell>
                        <TableCell align="center" sx={{ fontWeight: "bold" }}>Name</TableCell>
                        <TableCell align="center" sx={{ fontWeight: "bold" }}>Email</TableCell>
                        <TableCell align="center" sx={{ fontWeight: "bold" }}>Phone No</TableCell>
                        <TableCell align="center" sx={{ fontWeight: "bold" }}>Approved Date</TableCell>
                        <TableCell align="center" sx={{ fontWeight: "bold" }}>Referred Affiliate</TableCell>
                        <TableCell align="center" sx={{ fontWeight: "bold" }}>Member Count</TableCell>

                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {row.original.userList ? row.original.userList.map((rows, idx) => (
                        <React.Fragment key={idx}>
                          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={idx}>
                            <TableCell component="th" scope="row">{idx + 1}</TableCell>
                            <TableCell align="center">{rows.name}</TableCell>
                            <TableCell align="center">{rows.email}</TableCell>
                            <TableCell align="center">{rows.phone_no}</TableCell>
                            <TableCell align="center">{Moment(rows.approved_date).format('MM-DD-YYYY H:mm')}</TableCell>
                            <TableCell align="center">{rows.company_name}</TableCell>
                            <TableCell align="center"><Badge badgeContent={`${rows.member_count}`} color={rows.member_count === 0 ? 'primary' : 'success'} overlap="circular" max={99999}></Badge></TableCell>

                          </TableRow>
                        </React.Fragment>
                      )) : " "}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
            renderTopToolbarCustomActions={({ table }) => (
              <Box
                sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
              >
                <Button
                  className='export'
                  disabled={table.getPrePaginationRowModel().rows.length === 0}
								//export all rows, including from the next page, (still respects filtering and sorting)
                  onClick={() =>
                    handleExportData(table.getPrePaginationRowModel().rows)
                  }
                  //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                  startIcon={<FileDownloadIcon />}
                  variant="contained"
                >
                  CSV
                </Button>
                
              </Box>
            )}
          />
        </Grid>
        <Dialog
            onClose={(_, reason) => {
                if (reason !== "backdropClick") {
                    handleClickClose();
                }
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            fullWidth
            maxWidth={'md'}
            open={openSlider}
            className='slider-popup'
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                {/* Modal title */}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClickClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <div className="slider-container agentSlider">
                    <button onClick={goToFirstSlide} className='back-button'>Go To First Review</button>
                    <Slider ref={sliderRef} {...settings} initialSlide={index}>
                        {totalReviwsList.map((item, index) => (
                            <div key={index} className="slider-card">
                                <div className='card-slider-inner-block'>
                                    <div style={{ display: 'flex' }}>
                                        <div className="profile">
                                            <Typography level="h4" color='primary'>{(item.displayname).charAt(0)}</Typography>
                                        </div>
                                        <div className='profile-info'>
                                            <Typography level="h6">{item.displayname}</Typography>
                                            {[...new Array(item.rating)].map(_ => <StarIcon sx={{ color: "#76C044" }} />)}
                                            <Typography level="body1">{Moment(item.date).format('MM-DD-YYYY')}</Typography>
                                        </div>
                                    </div>
                                    <div className='reviews-block'>
                                        <Typography level="body1" className="text-start">{item.review ? item.review : item.settings.placeholder_text}</Typography>
                                    </div>
                                </div>
                            </div>
                        ))}


                    </Slider>
                </div>
            </DialogContent>

        </Dialog>
        <Dialog
            onClose={(_, reason) => {
                if (reason !== "backdropClick") {
                    handleClickClose();
                }
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            fullWidth
            maxWidth={'md'}
            open={openSlider}
            className='slider-popup'
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                {/* Modal title */}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClickClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <div className="slider-container agentSlider">
                    <button onClick={goToFirstSlide} className='back-button'>Go To First Review</button>
                    <Slider ref={sliderRef} {...settings} initialSlide={index}>
                        {totalReviwsList.map((item, index) => (
                            <div key={index} className="slider-card">
                                <div className='card-slider-inner-block'>
                                    <div style={{ display: 'flex' }}>
                                        <div className="profile">
                                            <Typography level="h4" color='primary'>{(item.displayname).charAt(0)}</Typography>
                                        </div>
                                        <div className='profile-info'>
                                            <Typography level="h6">{item.displayname}</Typography>
                                            {[...new Array(item.rating)].map(_ => <StarIcon sx={{ color: "#76C044" }} />)}
                                            <Typography level="body1">{Moment(item.date).format('MM-DD-YYYY')}</Typography>
                                        </div>
                                    </div>
                                    <div className='reviews-block'>
                                        <Typography level="body1" className="text-start">{item.review ? item.review : item.settings.placeholder_text}</Typography>
                                    </div>
                                </div>
                            </div>
                        ))}


                    </Slider>
                </div>
            </DialogContent>

        </Dialog>
      </>
  return (
    <div>
      <DefaultLayout content={ContentLayout} />
      {/* loader code start */}
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={freez}
        >
          <Freez />
        </Backdrop>
      </div>
      {/* loader code end */}
    </div>
  );
}


export default UserList1;