import React, { useState, useEffect }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {IconButton,Tooltip,Button,Grid, } from '@mui/material';
import { Typography, Card, CardContent, CardOverflow, List, ListItem, ListItemDecorator, ListItemContent } from '@mui/joy';
const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//   },
  
  largeIcon: {
    width: "40px",
    height:"auto",
    // marginTop: theme.spacing(1.5),
    color:"#ED7E2E" // Adjust the size as per your preference
  },
}));

function MediaShareIcons({url,firstlogoUrlValue,companyId,receivedpid,link }) {
  const classes = useStyles();

  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${url}`;
  const linkedinShareUrl = `https://www.linkedin.com/shareArticle?url=${url}`;
  const instagramShareUrl = `https://www.instagram.com/create/story/`;
  const tiktokShareUrl = `https://www.tiktok.com/login`;

  return (
    <>
    <Grid container item lg={12} md={12} sm={12} xs={12}>
      <Tooltip title="Share on Facebook">
        <IconButton
          
          component="a"
          href={facebookShareUrl}
          target="_blank"
          rel="noopener noreferrer"
         
        >
          <img src={require("../../assets/images/snap-facebook.png")} alt="facebook" className={classes.largeIcon}/>
        </IconButton>
      </Tooltip>
      <Tooltip title="Share on Twitter">
        <IconButton
          
          component="a"
          href={twitterShareUrl}
          target="_blank"
          rel="noopener noreferrer"
          
        >
          <img src={require("../../assets/images/snap-twitter.png")} alt="twitter" className={classes.largeIcon}/>
        </IconButton>
      </Tooltip>
      <Tooltip title="Share on LinkedIn">
        <IconButton
         
          component="a"
          href={linkedinShareUrl+"="}
          target="_blank"
          rel="noopener noreferrer"
          
        >
          <img src={require("../../assets/images/snap-linkedin.png")} alt="linked in" className={classes.largeIcon}/>
        </IconButton>
      </Tooltip>
      <Tooltip title="Share on Instagram">
        <IconButton
          
          component="a"
          href={instagramShareUrl}
          target="_blank"
          rel="noopener noreferrer"
          
        >
          <img src={require("../../assets/images/snap-instagram.png")} alt="instagram" className={classes.largeIcon}/>
        </IconButton>
      </Tooltip>
      <Tooltip title="Share on tiktok">
        <IconButton
          
          component="a"
          href={tiktokShareUrl}
          target="_blank"
          rel="noopener noreferrer"
          
        >
          <img src={require("../../assets/images/snap-tiktok.png")} alt="tiktok" className={classes.largeIcon}/>
        </IconButton>
      </Tooltip>
    </Grid>
   
    </>
  );
}

export default MediaShareIcons;
