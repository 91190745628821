import React, { useState } from "react";
import {  useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { Typography, Autocomplete,Box, Grid,  useMediaQuery, TextField, Button, Tooltip, Stack, Alert, IconButton,InputLabel,Select,OutlinedInput,MenuItem,FormControl,Switch } from "@mui/material";
import Moment from 'moment';
import formatPhoneNumber from '../../../../Components/Formats/PhoneNumberValidation';
import { fetchData, postData } from '../../../../redux/apiSlice';
import swal from "sweetalert";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CloseIcon from "@mui/icons-material/Close";
import BounceEmailDailog from '../../../../Components/BounceEmail/BounceEmailDailog';
import InputMask from 'react-input-mask';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
let RequestFormInitialValues = {
    SoftwareUsed : '',
    othersofttype : ''
};

const CompanyDetailsTab = ({fetchCompanyDetails,company_details,user,company_id,bank_details,taxFlag,handleFreezStart,handleFreezStop}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [scrollX, setScrollX] = useState(0);
    const [statusMessage, setStatusMesage] = useState("");
    const [errorMessage, setErrorMessage] = useState('');
    const [openCommunicationEmailPopup, setOpenCommunicationEmailPopup] = useState(false);
    const [epicProStatus, setEpicProStatus] = useState(company_details.epic_pro_status);
    const [epicProOpenModal, setEpicProOpenModal] = useState(false);
    const [softwares, setSoftwares] = useState([]);
    const [checkOtherStatus, setCheckOtherStatus] = useState(false);
    const [marketingtypeOpen,setMarketingTypetOpen] =useState(false);
    const [referedbyOption, setReferedbyOption] = useState([]); 
    const [referedbyOpen, setReferedbyOpen] = useState(false);
    const [phoneNumberOpen, setPhoneNumberOpen] = useState(false);
    const [timezoneOpen, setTimezoneOpen] = useState(false);
    const [newPhoneNumber, setNewPhoneNumber] = useState("");
    let storedReferralValue = null;
    const isMobile = useMediaQuery('(max-width:600px)');
    const mobileDialogModalStyles = {
        position: 'absolute',
        left: `${scrollX}px`,
    };

    //open communication email
    const editCommunicationEmailPopup = async() => {
		setOpenCommunicationEmailPopup(true);
	};
    //close communication email popup
    const editCommunicationEmailPopupClose = async() =>{
		setOpenCommunicationEmailPopup(false);
	};
    //refresh page after email change
    const refreshPageAfterEmailChange = () =>{
		fetchCompanyDetails();
	}
    const setErrorMessageFun = (successMessage , errorMessage) => {
        setErrorMessage(errorMessage)
        setStatusMesage(successMessage)
        setTimeout(function () {
            setStatusMesage('')
            setErrorMessage('')
        }, 5000);
    }
    // Epic Pro Modal Open
    const epicProModalOpen = () => {
        setEpicProOpenModal(true);
    }
    // Epic Pro Modal Close
    const epicProModalClose = () => {
        setEpicProOpenModal(false);
    };
    //Update Epic Pro
    const changeEpicProStatus = () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id  > 2) {
            navigate('/login');
            return false;
        }
        handleFreezStart();
        setEpicProStatus(!epicProStatus);
        const apiUrl = "/saveEpicProStatus";
        const args = {
            companyId: company_id,
            status: !epicProStatus
        }
        const res = dispatch(postData({ apiUrl, args })).unwrap();
        if (res) {
            handleFreezStop();
            swal({
                title: "Success",
                text: `Epic PRO status has been ${epicProStatus ? "disabled" : "enabled"} successfully`,
                icon: "success",
                button: "Ok",
            })
            if (res.success == true) {
                setStatusMesage(res.message);
                setTimeout(() => {
                    setStatusMesage('');
                }, 3000);
            }
        }
        setEpicProOpenModal(false);
    }
    // Change Marketing Type
    const changeMarketingType = async () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id  >= 4) {
            navigate('/login');
            return false;
        }
        handleFreezStart();
        const apiUrl = "/getSoftwareInfo";
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            if(res.success === true) {
                setSoftwares(res.data);
            }
        }
        if(company_details.marketing_type) {
            if(company_details.marketing_type.includes("Other") || company_details.marketing_type.includes("others")) {
                RequestFormInitialValues.SoftwareUsed = "Other";
                var originalString = company_details.marketing_type;
                var startIndex = originalString.indexOf('Other');
                var endIndex = startIndex + 'Other'.length;
                var newString = originalString.slice(0, startIndex) + originalString.slice(endIndex);
                var firstIndex = newString.indexOf("(");
                var lastIndex = newString.lastIndexOf(")");
                if (firstIndex !== -1 && lastIndex !== -1) {
                    newString = newString.slice(0, firstIndex) + newString.slice(firstIndex + 1, lastIndex) + newString.slice(lastIndex + 1);
                } else {
                    newString = newString;
                }
                RequestFormInitialValues.othersofttype = newString;
    
            }else{
                RequestFormInitialValues.SoftwareUsed = company_details.marketing_type;
                RequestFormInitialValues.othersofttype = '';
            }
        }
        
        handleFreezStop();
        setMarketingTypetOpen(true);
    };
    //close marketing Type Modal
    const marketingTypeClose = (resetForm) => {
        resetForm();
        setMarketingTypetOpen(false);
    };
    //setSoftware
    const setSoftwareType = (softwareType) => {
        if (softwareType == 'Other') {
            document.getElementById("othersofttypes").style.display = "block";
            setCheckOtherStatus(true);
            // setDisable(true)
        } else {
            document.getElementById("othersofttypes").style.display = "none";
            setCheckOtherStatus(false);
            // setDisable(false)

        }
    }
    const handleothersoft = (event) => {
        if (event != '') {
            setCheckOtherStatus(false);
        } else {
            setCheckOtherStatus(true);
        }
    }
    //update Marketing Type
    const updateMarketingType = async (values,resetForm) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
		if(loginUser.roles_id  >= 4) {
			navigate('/login');
			return false;
		}
        if(values.SoftwareUsed == "Other" && values.othersofttype == "") {
            return false;
        }
        setMarketingTypetOpen(false);
        handleFreezStart();
        const apiUrl = '/update-company-marketing-type';
        const args = {
            companyId: company_id,
            softwareTobeUsed: values.SoftwareUsed,
            otherSoftType: values.othersofttype
        }
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if(res) {
            if (res.success === true) {
                swal({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    button: "Ok",
                })
                fetchCompanyDetails();
                handleFreezStop();
            }
        }
        resetForm();
    };
    //Change Referred By 
    const changeReferedBy = async () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id  > 2) {
            navigate('/login');
            return false;
        }
        handleFreezStart();
        const apiUrl = "/fetch-refered-by-list";
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            const additionalCompanyListOptions = [
                { referral_code: "mfsnhomepage", company_name: "MFSN Home Page" },
                { referral_code: 'mfsnloginpage', company_name: 'MFSN Login Page' },
            ];
            const allOptions = [...res, ...additionalCompanyListOptions];

            // Sort the options array by the company_name 
            allOptions.sort((a, b) => a.company_name.localeCompare(b.company_name));
            setReferedbyOption(allOptions);

            if (allOptions.length > 0) {
                setReferedbyOpen(true)
                handleFreezStop();
            }else{
                alert("Something went wrong");
                handleFreezStop();
            }
        }
    };
    //close Referred By Modal
    const referredByClose = () => {
        setReferedbyOpen(false)
    };
    //store referred by 
    const referredbyhandleChange = (data) => {
        storedReferralValue = data.referral_code
    };
    //update referred By
    const referredBySave = async () => {
        referredByClose();
        handleFreezStart();
        const apiUrl = '/update-refered-by';
        const args = {
            companyId: company_id,
            referredBy: storedReferralValue
        }
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if (res) {
            referredByClose();
            fetchCompanyDetails();
            handleFreezStop();
        }
    };
    //phone number popup open
    const phoneNumberPopupOpen = () => {
        setPhoneNumberOpen(true)
    };
    //close phone number popup
    const phoneNumberPopupClose = () => {
        setPhoneNumberOpen(false)
    };

    //timezone popup open
    const timezonePopupOpen = () => {
        setTimezoneOpen(true)
    };
    //close phone number popup
    const timezonePopupClose = () => {
        setTimezoneOpen(false)
    };

    //validations
    const marketingTypeSchema = Yup.object().shape({
        SoftwareUsed: Yup.string().required('Please enter Software.')
    });




    return (
        <>
            <Box className="common-container">
                {/* Row 1 */}
                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                    <Grid item xs={3}>
                        <Typography variant="body2" >Company Name :</Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{(company_details.company_name) || "N/A"}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" >Company Website :</Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{(company_details.company_website) || "N/A"}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" >Business Started Year :</Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{(company_details.business_started_year) || "N/A"}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" >Company Status :</Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{company_details.is_disabled == 1 ? "Disabled" : company_details.company_status == 1 ? "Active" : "Not Active"}</Typography>
                    </Grid>
                </Grid>
                {/* Row 2 */}
                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                    <Grid item xs={3}>
                        <Typography variant="body2" >Affiliate Name :</Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{company_details.reference_affiliates ? company_details.reference_affiliates.first_name != null && company_details.reference_affiliates.last_name != null ? company_details.reference_affiliates.first_name + ' ' + company_details.reference_affiliates.last_name : 'N/A' : 'N/A'}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" >Title :</Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{(company_details.title) || "N/A"}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" >Email :</Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{company_details.reference_affiliates && company_details.reference_affiliates.email ? company_details.reference_affiliates.email : 'N/A'}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" className="flex-align">Phone Number :
                            {user.roles_id <= 3 ?
                                <Tooltip title="Edit">
                                    <img src={require('../../../../assets/icons/new-edit.png')} alt="Edit" className="edit-icon" onClick={phoneNumberPopupOpen}/>
                                </Tooltip>
                            : ""}
                        </Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{formatPhoneNumber(company_details.reference_affiliates && company_details.reference_affiliates.phone_no ? company_details.reference_affiliates.phone_no : 'N/A')}</Typography>
                    </Grid>
                </Grid>
                {/* Row 3 */}
                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                    <Grid item xs={3}>
                        <Typography variant="body2" className="flex-align">Communication Email :  
                            {user.roles_id <= 3 ?
                                <Tooltip title="Edit">
                                    <img src={require('../../../../assets/icons/new-edit.png')} alt="Edit" className="edit-icon" onClick={editCommunicationEmailPopup}/>
                                </Tooltip>
                            : ""}
                        </Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{company_details.reference_affiliates && company_details.reference_affiliates.communication_email ? company_details.reference_affiliates.communication_email : 'N/A'}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" >Agreement ID :</Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{company_details.reference_affiliates && company_details.reference_affiliates.pandadoc_id ? company_details.reference_affiliates.pandadoc_id : 'N/A'}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" >Approval Date :</Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{company_details.reference_affiliates && company_details.reference_affiliates.approved_date ? Moment(company_details.reference_affiliates.approved_date).format('MM-DD-YYYY') : 'N/A'}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" className="flex-align">
                            Time Zone : 
                            {user.roles_id <= 3 ?
                                <Tooltip title="Edit">
                                    <img src={require('../../../../assets/icons/new-edit.png')} alt="Edit" className="edit-icon" onClick={timezonePopupOpen}/>
                                </Tooltip>
                            : ""}
                        </Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{company_details.reference_affiliates && company_details.reference_affiliates.timezone ? company_details.reference_affiliates.timezone : 'N/A'}</Typography>
                    </Grid>
                </Grid>
                {/* Row 4 */}
                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                    <Grid item xs={3}>
                        <Typography variant="body2" >Street Address :</Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{company_details.reference_affiliates && company_details.reference_affiliates.address ? company_details.reference_affiliates.address : 'N/A'}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" >City :</Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{company_details.reference_affiliates && company_details.reference_affiliates.city ? company_details.reference_affiliates.city : 'N/A'}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" >State :</Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{company_details.reference_affiliates && company_details.reference_affiliates.state_code ? company_details.reference_affiliates.state_code : 'N/A'}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" >Zipcode :</Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{company_details.reference_affiliates && company_details.reference_affiliates.zip_code ? company_details.reference_affiliates.zip_code : 'N/A'}</Typography>
                    </Grid>
                </Grid>
                {/* Row 5 (Editable Fields) */}
                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                    <Grid item xs={3}>
                        <Typography variant="body2" className="flex-align">Marketing Type :  
                            {user.roles_id <= 3 ?
                                <Tooltip title="Edit">
                                    <img src={require('../../../../assets/icons/new-edit.png')} alt="Edit" className="edit-icon" onClick={changeMarketingType}/>
                                </Tooltip>
                            : ""}
                        </Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{(company_details.marketing_type) || "N/A"}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" className="flex-align">Referred By : 
                            {user.roles_id < 3 ? 
                                <Tooltip title="Edit">
                                    <img src={require('../../../../assets/icons/new-edit.png')} alt="Edit" className="edit-icon" onClick={changeReferedBy}/>
                                </Tooltip>
                            : ""}
                        </Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{company_details.referred_by || "N/A"}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" >Referral Enrolled Date :</Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{company_details.referral_enrolled_date ? Moment(company_details.referral_enrolled_date).format('MM-DD-YYYY') : "N/A"}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" >Referral Code :</Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{(company_details.referral_code) || "N/A"}</Typography>
                    </Grid>
                </Grid>
                {/* Row 6 (Epic PRO Status with Switch) */}
                <Grid container spacing={2}>
                        {user.roles_id < 3 && (
                            <Grid item xs={3}>
                                <Typography variant="body2">Epic PRO Status :</Typography>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <Typography variant="subtitle1" fontWeight="bold">Off</Typography>
                                    <Switch checked={epicProStatus} onChange={epicProModalOpen} />
                                    <Typography variant="subtitle1" fontWeight="bold">On</Typography>
                                </Stack>
                            </Grid>
                        )}
                        <Grid item xs={3}>
                            <Typography variant="body2">Cobrand Status :</Typography>
                            <Typography variant="subtitle1" fontWeight="bold" className="common-value">
                            {Array.isArray(company_details.cobranding_status) && company_details.cobranding_status.length > 0 ? 'Enabled' : 'Disabled'}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2">Snapshot Enrolled Date :</Typography>
                            <Typography variant="subtitle1" fontWeight="bold" className="common-value">
                            {company_details.credit_snapshot && company_details.credit_snapshot.snapshot_enrolled_date
                                ? Moment(company_details.credit_snapshot.snapshot_enrolled_date).format('MM-DD-YYYY')
                                : 'N/A'}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2">Credit Snapshot Auto Signup Link Status :</Typography>
                            <Typography variant="subtitle1" fontWeight="bold" className="common-value">
                            {company_details.credit_snapshot && company_details.credit_snapshot.crs_signup_enabled == 1
                                ? 'Configured'
                                : 'Not Configured'}
                            </Typography>
                        </Grid>

                        <Grid item xs={3}>
                            <Typography variant="body2">Cobrand Auto Signup Link Status :</Typography>
                            <Typography variant="subtitle1" fontWeight="bold" className="common-value">
                            {company_details.cobrand_customize_final && company_details.cobrand_customize_final.customer_signup_link_enabled == 1
                                ? 'Configured'
                                : 'Not Configured'}
                            </Typography>
                        </Grid>

                        <Grid item xs={3}>
                            <Typography variant="body2">Bank Detail Submitted :</Typography>
                            <Typography variant="subtitle1" fontWeight="bold" className="common-value">
                                {bank_details.bank_name ? "Yes" : "No"}
                            </Typography>
                        </Grid>
                        
                        {taxFlag && (
                            <Grid item xs={3}>
                            <Typography variant="body2">W9 Form Submitted :</Typography>
                            <Typography variant="subtitle1" fontWeight="bold" className="common-value">
                                {taxFlag ? "Yes" : "No"}
                            </Typography>
                        </Grid>
                        )}
                        
                </Grid>
            </Box>
            
            {/* communication email edit code starts */}
            <Dialog
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        editCommunicationEmailPopupClose();
                    }
                }}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={openCommunicationEmailPopup}
                aria-labelledby="responsive-dialog-title"
                style={isMobile ? mobileDialogModalStyles : null}
                PaperProps={{
                    sx: {
                        borderRadius: "12px",
                    }
                }}
            >
                <DialogTitle className="dialog-title">
                    <Typography variant="h6" fontWeight="bold">Update Communication Email</Typography>
                    <IconButton onClick={editCommunicationEmailPopupClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    {statusMessage && <Alert severity="success">{statusMessage}</Alert>}
                    {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                    <Grid container spacing={1}>
                        <Grid item lg={3} md={3} sm={4} xs={4} className="aligned-grid">
                            <Typography gutterBottom>Company Name:</Typography>
                        </Grid>
                        <Grid item lg={8} md={8} sm={8} xs={8} className="aligned-grid">
                            <Typography variant="h6" fontWeight="bold" gutterBottom>{company_details.company_name}</Typography>
                        </Grid>

                        <Grid item lg={3} md={3} sm={4} xs={4} className="aligned-grid">
                            <Typography gutterBottom>Registered Email:</Typography>
                        </Grid>
                        <Grid item lg={8} md={8} sm={8} xs={8} className="aligned-grid">
                            <Typography variant="h6" fontWeight="bold" gutterBottom>
                                {company_details.reference_affiliates?.email || 'N/A'}
                            </Typography>
                        </Grid>
                    </Grid>

                    <BounceEmailDailog  
                        id={company_id} 
                        email={company_details.reference_affiliates?.email} 
                        communication_email={company_details.reference_affiliates?.communication_email} 
                        onValueChange={setErrorMessageFun} 
                        closeModal={editCommunicationEmailPopupClose} 
                        fetchData={refreshPageAfterEmailChange} 
                    />
                </DialogContent>
            </Dialog>

            {/* communication email edit code ends */}

            {/* epic pro status modal code starts */}                
            <Dialog
                open={epicProOpenModal}
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        epicProModalClose(); // Use consistent function name
                    }
                }}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                PaperProps={{
                    sx: {
                        borderRadius: "12px",
                        padding: "20px"
                    }
                }}
            >
                <DialogTitle className="dialog-title">
                    <Typography variant="h6" fontWeight="bold"> Change Epic PRO Status</Typography>
                    <IconButton onClick={(e)=>{epicProModalClose();}}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body2">
                        {epicProStatus 
                            ? "If you disable Epic PRO status, Affiliate will not be able to view their respective member's Epic PRO report in the member list."
                            : "If you enable Epic PRO status, Affiliate will be able to view their respective member's Epic PRO report in the member list."
                        }
                    </Typography>
                    <br />
                    <Typography variant="body2">
                        Are you sure, you want to {epicProStatus ? "disable" : "enable"} Epic PRO status?
                    </Typography>
                    <Grid container spacing={2} sx={{ textAlign: "center", width: "100%", mt: 1 }}>
                        <Grid item xs={6}>
                            <Button 
                                variant="contained" 
                                onClick={epicProModalClose} 
                                className="cancel-button"
                            >
                                No
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button 
                                variant="contained" 
                                onClick={changeEpicProStatus}  // Fixed function name
                                className="save-button"
                            >
                                Yes
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            {/* epic pro status modal code end */}
            {/* marketing type modal code starts */}
            <Formik
                initialValues={RequestFormInitialValues}
                validationSchema={marketingTypeSchema}
                onSubmit={async (values, { resetForm }) => {
                    updateMarketingType(values,resetForm);
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue,resetForm }) => (
                    <>
                        <Dialog
                            open={marketingtypeOpen}
                            onClose={(event, reason) => {
                                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                                    marketingTypeClose(resetForm);
                                }
                            }}
                            fullWidth={fullWidth}
                            maxWidth={maxWidth}
                            PaperProps={{
                                sx: {
                                    borderRadius: "12px",
                                    padding: "20px"
                                }
                            }}
                        >
                            <DialogTitle className="dialog-title" >
                                <Typography variant="h6" fontWeight="bold"> Change Software to be Used </Typography>
                                <IconButton onClick={(e)=>{marketingTypeClose(resetForm);}}>
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent>
                                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                                    <FormControl fullWidth>
                                    <InputLabel id="demo-multiple-name-label" className="blue-border">Software to be Used</InputLabel>
                                        <Select
                                            // required
                                            labelId="demo-multiple-name-label"
                                            id="SoftwareUsed"
                                            input={<OutlinedInput label="Software to be Used" />}
                                            name="SoftwareUsed"
                                            value={values.SoftwareUsed?values.SoftwareUsed:''}
                                            onChange={(e) => {
                                                handleChange(e);
                                                setSoftwareType(e.target.value); values.othersofttype = "";
                                            }}
                                            onBlur={handleBlur}
                                            MenuProps={MenuProps}
                                            className="blue-border" 
                                        >
                                            {softwares.map((software,index) => (
                                                <MenuItem
                                                key={index}
                                                    value={software.link_name}
                                                >
                                                    {software.software_name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} mt={2} display={RequestFormInitialValues.SoftwareUsed == "Other" ? "block" :"none"} id="othersofttypes">
                                    <FormControl fullWidth>
                                        <TextField
                                            // required
                                            autoComplete="othersofttype"
                                            name="othersofttype"
                                            onChange={(e) => { handleChange(e); handleothersoft(e.target.value); }}
                                            value={values.othersofttype}
                                            onBlur={handleBlur('othersofttype')}
                                            fullWidth
                                            id="othersofttype"
                                            label=" Other Software Name "
                                            className="blue-border"
                                        />
                                    <p className="error-text">{checkOtherStatus === true ? "Please Enter Software Name" : ''}</p>
                                </FormControl>
                            </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }} spacing={2} mt={1}>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" 
                                        onClick={(e)=>{
                                            marketingTypeClose(resetForm);
                                            }}
                                            className="cancel-button">
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" onClick={handleSubmit} className="save-button">
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Dialog>
                    </>
                )}
            </Formik>
            {/* marketing type modal code end */}
            {/* refered by modal code starts */}
            <Dialog
                open={referedbyOpen}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                        referredByClose();
                    }
                }}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                PaperProps={{
                    sx: {
                        borderRadius: "12px",
                        padding: "20px"
                    }
                }}
            >
                <DialogTitle className="dialog-title" >
                    <Typography variant="h6" fontWeight="bold"> Change Referred By </Typography>
                    <IconButton onClick={(e)=>{referredByClose()}}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                        <Autocomplete
                            options={referedbyOption}
                            getOptionLabel={(option) => option.company_name || ''}
                            onChange={(event, referredValue) => { referredbyhandleChange(referredValue) }}
                            fullWidth
                            margin="dense"
                            renderInput={(params) => <TextField {...params} label="Company Names" />}
                            className="blue-border"
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }} spacing={2} mt={1}>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Button variant="contained" onClick={referredByClose} className="cancel-button">
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Button variant="contained" onClick={referredBySave}  className="save-button">
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            {/* refered by modal code end*/}
            {/* phone number  modal code starts */}
            <Formik
                initialValues={{ phone_number: "" }}
                validationSchema={Yup.object({
                    phone_number: Yup.string()
                    .min(14, 'Phone number must be of 10 digit!')
                    .max(14, 'Phone number must be of maximum 10 digit!')
                    .required('Phone number is required'),
                })}
                onSubmit={async (values, { resetForm }) => {
                    const loginUser = JSON.parse(localStorage.getItem("user"));
                    if (loginUser.roles_id >= 4) {
                        navigate("/login");
                        return;
                    }
                    handleFreezStart();
                    const apiUrl = "/update-company";
                    const args = {
                        companyId: company_id,
                        phone_number: values.phone_number, // Get phone number from Formik
                    };
                    const res = await dispatch(postData({ apiUrl, args })).unwrap();
                    if (res && res.success === true) {
                        swal({
                            title: "Success",
                            text: res.message,
                            icon: "success",
                            button: "Ok",
                        });
                        fetchCompanyDetails(); // Refresh data
                        handleFreezStop();
                        resetForm(); // Reset form after success
                        phoneNumberPopupClose(); // Close modal
                    }
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue,resetForm }) => (
                <>
                    <Dialog
                        open={phoneNumberOpen}
                        onClose={(event, reason) => {
                            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                                phoneNumberPopupClose();
                            }
                        }}
                        fullWidth={fullWidth}
                        maxWidth={maxWidth}
                        PaperProps={{
                            sx: {
                                borderRadius: "12px",
                                padding: "20px"
                            }
                        }}
                    >
                        <DialogTitle className="dialog-title" >
                            <Typography variant="h6" fontWeight="bold"> Update Phone Number </Typography>
                            <IconButton onClick={(e)=>{phoneNumberPopupClose()}}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={1}>
                                <Grid item lg={3} md={3} sm={4} xs={4} className="aligned-grid">
                                    <Typography gutterBottom>Company Name:</Typography>
                                </Grid>
                                <Grid item lg={8} md={8} sm={8} xs={8} className="aligned-grid">
                                    <Typography variant="h6" fontWeight="bold" gutterBottom>{company_details.company_name}</Typography>
                                </Grid>

                                <Grid item lg={5} md={5} sm={4} xs={4} className="aligned-grid">
                                    <Typography gutterBottom>Registered Phone Number:</Typography>
                                </Grid>
                                <Grid item lg={7} md={7} sm={8} xs={8} className="aligned-grid">
                                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                                        {formatPhoneNumber(company_details.reference_affiliates && company_details.reference_affiliates.phone_no ? company_details.reference_affiliates.phone_no : 'N/A')}
                                    </Typography>
                                </Grid>
                                <Grid item lg={12} md={12} sm={8} xs={8} className="aligned-grid">
                                    <InputMask
                                        mask="(999) 999-9999"
                                        maskChar={null}
                                        value={values.phone_number}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    >
                                        {(inputProps) => (
                                            <TextField
                                                {...inputProps}
                                                fullWidth
                                                required
                                                margin="dense"
                                                placeholder="New Phone Number"
                                                label="New Phone Number"
                                                variant="outlined"
                                                name="phone_number"
                                                className="blue-border"
                                                error={touched.phone_number && Boolean(errors.phone_number)}
                                                helperText={touched.phone_number && errors.phone_number}
                                            />
                                        )}
                                    </InputMask>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }} spacing={2} mt={1}>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <Button variant="contained" onClick={phoneNumberPopupClose} className="cancel-button">
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <Button variant="contained" onClick={handleSubmit}  className="save-button">
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Dialog>
                </>
                )}
            </Formik>
            {/* phone number modal code end*/}
            {/* timezone  modal code starts */}
            <Formik
                initialValues={{ timezone: "" }}
                validationSchema={Yup.object({
                    timezone: Yup.string()
                        .required('Please select Timezone.'),
                })}
                onSubmit={async (values, { resetForm }) => {
                    const loginUser = JSON.parse(localStorage.getItem("user"));
                    if (loginUser.roles_id >= 4) {
                        navigate("/login");
                        return;
                    }
                    handleFreezStart();
                    const apiUrl = "/update-company";
                    const args = {
                        companyId: company_id,
                        timezone: values.timezone, // Get phone number from Formik
                    };
                    const res = await dispatch(postData({ apiUrl, args })).unwrap();
                    if (res && res.success === true) {
                        swal({
                            title: "Success",
                            text: res.message,
                            icon: "success",
                            button: "Ok",
                        });
                        fetchCompanyDetails(); // Refresh data
                        handleFreezStop();
                        resetForm(); // Reset form after success
                        timezonePopupClose(); // Close modal
                    }
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue,resetForm }) => (
                <>
                    <Dialog
                        open={timezoneOpen}
                        onClose={(event, reason) => {
                            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                                timezonePopupClose();
                            }
                        }}
                        fullWidth={fullWidth}
                        maxWidth={maxWidth}
                        PaperProps={{
                            sx: {
                                borderRadius: "12px",
                                padding: "20px"
                            }
                        }}
                    >
                        <DialogTitle className="dialog-title" >
                            <Typography variant="h6" fontWeight="bold"> Update Time Zone </Typography>
                            <IconButton onClick={(e)=>{timezonePopupClose()}}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={1}>
                                <Grid item lg={3} md={3} sm={4} xs={4} className="aligned-grid">
                                    <Typography gutterBottom>Company Name:</Typography>
                                </Grid>
                                <Grid item lg={8} md={8} sm={8} xs={8} className="aligned-grid">
                                    <Typography variant="h6" fontWeight="bold" gutterBottom>{company_details.company_name}</Typography>
                                </Grid>

                                <Grid item lg={4} md={4} sm={4} xs={4} className="aligned-grid">
                                    <Typography gutterBottom>Current Time Zone:</Typography>
                                </Grid>
                                <Grid item lg={8} md={8} sm={8} xs={8} className="aligned-grid">
                                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                                        {company_details.reference_affiliates && company_details.reference_affiliates.timezone ? company_details.reference_affiliates.timezone : 'N/A'}
                                    </Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                                    <FormControl fullWidth size='small' margin='dense'>
                                    <InputLabel id="demo-multiple-name-label">Timezone*</InputLabel>
                                    <Select
                                        name="timezone"
                                        labelId="demo-simple-select-label"
                                        id="timezone"
                                        value={values.timezone||''}
                                        label="Timezone"
                                        fullWidth
                                        size='small'
                                        margin='dense'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        MenuProps={MenuProps}
                                    >
                                        <MenuItem value="PST">PST - Pacific Standard Time</MenuItem>
                                        {/* <MenuItem value="CT">CT - Central Time</MenuItem> */}
                                        <MenuItem value="EST">EST - Eastern Standard Time</MenuItem>
                                        <MenuItem value="Asia/Kolkata">IST - Indian Standard Time</MenuItem>
                                        <MenuItem value="EDT">EDT - Eastern Daylight Time</MenuItem>
                                        <MenuItem value="UTC">UTC - Coordinated Universal Time</MenuItem>
                                        <MenuItem value="PDT">PDT - Pacific Daylight Time</MenuItem>
                                        <MenuItem value="WET">WET - Western European Time</MenuItem>
                                        <MenuItem value="CDT">CDT - Central Daylight Time</MenuItem>
                                        <MenuItem value="Australia/Perth">WST - Western Standard Time</MenuItem>
                                        <MenuItem value="CST">CST - Central Standard Time</MenuItem>
                                        <MenuItem value="MST">MST - Mountain Standard Time</MenuItem>
                                        <MenuItem value="HST">HST - Hawaii Standard Time</MenuItem>
                                    </Select>
                                    {touched.timezone && errors.timezone ? <div className='error'>{errors.timezone}</div> : null}
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }} spacing={2} mt={1}>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <Button variant="contained" onClick={timezonePopupClose} className="cancel-button">
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <Button variant="contained" onClick={handleSubmit}  className="save-button">
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Dialog>
                </>
                )}
            </Formik>
            {/* timezone modal code end*/}
        </>  
    );
};

export default CompanyDetailsTab;
