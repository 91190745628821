
import React,{useMemo,useState,useEffect} from 'react';
import { useTheme } from '@mui/material/styles';
import {Box,Tooltip,Button,Grid,Dialog,DialogActions ,DialogTitle,DialogContentText,DialogContent,Typography } from '@mui/material';
import MaterialReactTable from 'material-react-table';
//Date Picker Imports

import { useParams, useNavigate, Link } from 'react-router-dom';
import AuthUser from "../Auth/AuthUser";
//Icons Imports
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here

import SummaryExpandRow from './SummaryExpandRow';
import config from '../../AppConfig';
import { useSelector, useDispatch } from 'react-redux';
import { fetchData, postData } from '../../redux/apiSlice';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import swal from "sweetalert";
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import {currencyFormat} from '../../Components/CommonFormula';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }
  
  const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  ];
function Pending(props) {
  const dispatch = useDispatch();
  const {http,user} = AuthUser();
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const [data, setData] = useState(props.data);
  const [month, setMonth] = useState(props.month);
  const [year, setYear] = useState(props.year);
  const [monthValue, setMonthValue] = useState(props.monthValue);
  const [rowSelection, setRowSelection] = useState({});
  const [search, setSearch] = useState("");
  const [statusMessage, setStatusMesage] = useState('');
  const [errorMessage,setErrorMessage] = useState('');
  const [disable, setDisable] = useState(false);
  const [leadDetails, setleadDetails] = useState([]);
  const [loader, setLoader] = useState("");
  const [perPage, setPerPage] = useState(100000);
  const q = search == '' ? 'null' : search;
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const [refreshText, setRefreshText] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [saveDisable, setSaveDisable] = React.useState(false);
  const [confirm, setConfirmation] = React.useState(false);
  const [notconfirm, setNotConfirm] = React.useState(false);
  const rowsSeleted = Object.keys(rowSelection);
  const tablePreferencesArray = {
  }

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

  useEffect(() => {
  }, [rowSelection]);
  ////////////////////////
  useEffect(() => {
    if (Object.values(columnVisibility).length > 0) {
      setColumnVisibility(columnVisibility);
    }
    //do something when the row selection changes...
   
  }, [rowSelection]);

  useEffect(() => {
    tablePreferences();
  }, [rowSelection]);

  useEffect(()=>{  
    saveTablePreferences();
},[columnVisibility])
  ////////////////////////
 
  const tablePreferences = async() => {
    const apiUrl = '/fetchTablePreferences/commissionsummerypending' ;
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if(res) {
          if (Object.values(res).length == 0) {
            setColumnVisibility(tablePreferencesArray);
          } else {
            setColumnVisibility((JSON.parse(res.preferences)));
          }
    }
  }

  const saveTablePreferences = async() => {
    if (Object.values(columnVisibility).length > 0) {
      const apiUrl = '/saveTablePreferences';
      const args = {
          columnArray: columnVisibility,
          userId: user.id,
          tableId: 'commissionsummerypending'
      }
      const res = await dispatch(postData({apiUrl,args})).unwrap();
    } 
  }

  const checkUserRole = () => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
        swal({
            title: "Failed",
            text: "This action is unauthorized.",
            icon: "error",
            button: "Ok",
        })
        navigate('/login');
        return false;
    }else{
        return true;
    }
  };

  const handleClose = () => {
    var check = checkUserRole();
    if(check === true){
      setOpen(false);
      setSaveDisable(false);
    }
  };

  const bulkApprove = () => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    var check = checkUserRole();
    if(check === true) {
      if(rowsSeleted.length <= 0) {
        alert('Please select at least one company');
        return false;;
      }
      setSaveDisable(true);
      setOpen(true);
    }
    
  };

  const approveCommission =async () => {
    var check = checkUserRole();
    if(check === true) {
    setOpen(false);
    setConfirmation(true);
    setNotConfirm(true);
    props.handleFreezStart();
    const apiUrl = '/bulk-approve-commission';
    const args = {
      companyIds: rowsSeleted,
      dateData: JSON.stringify({year :year,monthvalue:monthValue,month: month }),
    };
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if (res) {
      if(res.success === true){
          swal({
              title: "Success",
              text: "Commission details approved and sent notification.",
              icon: "success",
              button: "Ok",
          })
          
          props.onValueChange();
        
      }else{
          alert("something went wrong ! Please Try Later !");
         
      }
      setSaveDisable(false);
      setConfirmation(false);
      setNotConfirm(false);
      // props.handleFreezStop();
    }
    }
    
  };
    ////////////////////////
    useEffect(() => {
      
    }, []);
    useEffect(() => {
    }, [rowSelection]);
    ////////////////////////

  const navigate = useNavigate();

  const viewDetails = (companyId,totalbilling,commision,referralCommission,referredCompanyCommissionDetailsArr,initialBonus,bonusDetails,finalTotalCommission,status,itemDetails,aids,targetCommission,fastStartBonus) => {
    
    navigate('/commission-details', { state: { companyId: companyId, totalbilling:totalbilling, commision: commision,referralCommission:referralCommission,referredCompanyCommissionDetailsArr:referredCompanyCommissionDetailsArr,initialBonus:initialBonus,bonusDetails:bonusDetails,finalTotalCommission:finalTotalCommission, status:status, itemDetails:itemDetails, aids:aids, targetCommission:targetCommission,fastStartBonus:fastStartBonus, month: month ? month : '', year:year ? year:'', monthValue : monthValue ? monthValue : ''} });
  };

  const customSortFunction = (rowA, rowB) => {
    const a = rowA.original.totalcommission;
    const b = rowB.original.totalcommission;
    return a - b;
  };

  const customSortBillingFunction = (rowA, rowB) => {
    const a = rowA.original.totalbilling;
    const b = rowB.original.totalbilling;
    return a - b;
  };

  const customSortReferralCommFunction = (rowA, rowB) => {
    const a = rowA.original.referralCommission;
    const b = rowB.original.referralCommission;
    return a - b;
  };

  const customSortReferralBonusFunction = (rowA, rowB) => {
    const a = rowA.original.initialBonus;
    const b = rowB.original.initialBonus;
    return a - b;
  };
  const customSortReferralTargetBonusFunction = (rowA, rowB) => {
    const a = rowA.original.targetCommission;
    const b = rowB.original.targetCommission;
    return a - b;
  };
  
  const customSortCommissionFunction = (rowA, rowB) => {
    const a = rowA.original.finalTotalCommission;
    const b = rowB.original.finalTotalCommission;
    return a - b;
  };

  const columns = useMemo(
    //column definitions...
    () => [
      {
        accessorFn: (data) => `${data.companyName ? data.companyName.trim() : ''}`,
        id:'companyName',
        header: 'Company Name',
        size: 50,
        enablePinning: true,
        Cell: ({ renderedCellValue, row }) => (row.original.companyName ? row.original.companyName.trim() : "N/A"),
      },
      {
				accessorFn: (data) => `${data.email ? data.email.trim() : ''} `,   
				enableClickToCopy: true,
				id: 'communicationEmail',
				header: 'Communication Email',
				size: 110,
				Cell: ({ renderedCellValue, row }) => (
            row.original.email ? 
						<>
              {row.original.email.trim()}
              
              {row.original.isBounce == '1' ? 
              
                <Tooltip title="Emails are not getting delivered to this address. Please update the communication email" arrow>
                  <ErrorOutlineIcon color={'error'}/>
                </Tooltip> 
                : null}
					  </>
            :'N/A'
					
				),
			},
      {
        accessorFn: (data) =>  `${data.totalbilling ? (parseFloat(data.totalbilling)).toFixed(2) : ""}`, //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        filterVariant: 'range',
        numeric: true,
        sortingFn: customSortBillingFunction, 
        id:'monthly_billing',
        header: 'Total Monthly Billing',
        size: 100,
        Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.totalbilling))),
        
      },
      {
        accessorFn: (data) =>  `${data.totalcommission ? (parseFloat(data.totalcommission)).toFixed(2) : ""}`,
        filterVariant: 'range',
        numeric: true,
        sortingFn: customSortFunction, 
        id:'monthly_commission',
        header: 'Commission ($)',
        size: 100,
        Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.totalcommission))),
      },
      {
        accessorFn: (data) =>  `${data.referralCommission ? (parseFloat(data.referralCommission)).toFixed(2) : ""}`,
        filterVariant: 'range',
        numeric: true,
        sortingFn: customSortReferralCommFunction, 
        id:'referral_commission',
        header: 'Referral Commission ($)',
        size: 100,
        Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.referralCommission))),
      },
      {
        accessorFn: (data) =>  `${data.initialBonus ? (parseFloat(data.initialBonus)).toFixed(2) : ""}`,
        filterVariant: 'range',
        numeric: true,
        sortingFn: customSortReferralBonusFunction, 
        id:'referral_bonus',
        header: 'One Time Referral Bonus ($)',
        size: 100,
        Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.initialBonus))),
      },
      {
        accessorFn: (data) =>  `${data.targetCommission ? (parseFloat(data.targetCommission)).toFixed(2) : ""}`,
        filterVariant: 'range',
        numeric: true,
        sortingFn: customSortReferralTargetBonusFunction,
        id:'target_commission',
        header: 'Target Incentive ($)',
        size: 100,
        Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.targetCommission))),
      },
      {
        accessorFn: (data) =>  `${data.fastStartBonus ? (parseFloat(data.fastStartBonus)).toFixed(2) : ""}`,
        filterVariant: 'range',
        numeric: true,
        id:'fast_start_bonus',
        header: 'Fast Start Bonus ($)',
        size: 100,
        Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.fastStartBonus))),
      },
      {
        accessorFn: (data) =>  `${data.finalTotalCommission ? (parseFloat(data.finalTotalCommission)).toFixed(2) : ""}`,
        filterVariant: 'range',
        numeric: true,
        sortingFn: customSortCommissionFunction, 
        id:'final_Total_commission',
        header: 'Total Commission ($)',
        size: 100,
        Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.finalTotalCommission))),
      },
      {
        accessorFn: (data) => `${data.reportStatus ? data.reportStatus.trim() : ''}`,
        filterVariant: 'range',
        id:'status',
        header: 'Status',
        size: 50,
        //custom conditional format and styling
        Cell: ({ renderedCellValue, row }) => (row.original.reportStatus ? row.original.reportStatus.trim() : "N/A"),
      },
      {
        accessorFn: (data) => `${data.reportStatus}`,
        id: 'action',
        header: 'Action',
        Cell: ({ row }) => (
          <Box>
            <>        
            <Tooltip title="Details" arrow><Button className='btn btn-sm btn-info text-white'  onClick={()=> viewDetails(`${row.original.companyId}`,`${row.original.totalbilling}`,`${row.original.totalcommission}`, `${row.original.referralCommission}`,row.original.referredCompanyCommissionDetailsArr,`${row.original.initialBonus}`,row.original.bonusDetails, `${row.original.finalTotalCommission}`,`${row.original.reportStatus}`,row.original.itemDetails,row.original.aids,row.original.targetCommission,row.original.fastStartBonus)} ><img src={require('../../assets/images/details.png')} /></Button></Tooltip>&nbsp;
            </> 
          </Box>
        ),
       
      },
    ]
  )
//csv option start here
const csvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
  fileName: 'Commission Summary Report(Pending)',
  headers: ['Company Name','Communication Email','Total Monthly Billing','Commission','Referral Commission','One Time Referral Bonus','Target Incentive','Fast Start Bonus','Total Commission','Status',],
};

const csvExporter = new ExportToCsv(csvOptions);
const handleExportData = (data) => {
  var check = checkUserRole();
  if(check === true) {
      const exportedData = [];
      data.forEach((row) => {
      const mainTableRow = {
        companyName: `${row.original.companyName}` || 'N/A',
        email: `${row.original.email}` || 'N/A',
        totalbilling: parseFloat(`${row.original.totalbilling}`).toFixed(2),
        totalcommission: parseFloat(`${row.original.totalcommission}`).toFixed(2),
        referralcommission: parseFloat(`${row.original.referralCommission}`).toFixed(2),
        referralBonus: parseFloat(`${row.original.initialBonus}`).toFixed(2),
        targetBonus: parseFloat(`${row.original.targetCommission}`).toFixed(2),
        fastStartBonus: parseFloat(`${row.original.fastStartBonus}`).toFixed(2),
        finalTotalCommission: parseFloat(`${row.original.finalTotalCommission}`).toFixed(2),
        reportStatus: `${row.original.reportStatus}` || 'N/A',
      };
      exportedData.push(mainTableRow);
      const subheader = {
        'AID': 'AID', // Subheader 1
        'Total Monthly Billing': 'Total Monthly Billing', // Subheader 2
        'Monthly Commission': 'Monthly Commission', // Subheader 3
        'Status': 'Status', // Subheader 4
        
      };
      exportedData.push(subheader);
      if (row.original.companyAids){
        row.original.companyAids.map((companyAids) => {
          const subRow = {
            aid: companyAids.aid,
            totalbilling: parseFloat(companyAids.totalcommission).toFixed(2),
            totalcommission: parseFloat(companyAids.totalcommission).toFixed(2),
            reportStatus: companyAids.reportStatus? companyAids.reportStatus:'N/A',
          };
          exportedData.push(subRow);
        });
      } else {
        // If no subrows available, add an empty row for subrows
        exportedData.push({});
   }
   exportedData.push({});
 });

 const csvContent = convertToCSV(exportedData);
// Create a Blob object from the CSV data
const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

// Create a download link element
const link = document.createElement('a');
link.href = URL.createObjectURL(blob);
link.download = csvOptions.fileName + '.csv';

// Append the link to the document body
document.body.appendChild(link);

// Programmatically click the link to trigger the file download
link.click();

// Clean up the temporary URL and remove the link from the document
URL.revokeObjectURL(link.href);
link.remove();
};
}


const convertToCSV = (data) => {
const headers = csvOptions.headers.join(',') + '\n';

const rows = data.map((row) => {
  return Object.values(row).map((value) => {
    // Handle any necessary formatting or escaping of values
    // For simplicity, we assume all values are already properly formatted

    // Wrap the value with double quotes if it contains a comma
    if (typeof value === 'string' && value.includes(',')) {
      return `"${value}"`;
    }

    return value;
  }).join(',');
}).join('\n');

return headers + rows;
};
//csv end here
   
        return (
            <>
                  <MaterialReactTable
                      columns={columns}
                      data={data}
                      muiTablePaginationProps={{
                        rowsPerPageOptions: [5,10,15,20,25,50,100,200]
                      }}
                      enablePinning
                      enableColumnFilterModes
                      enableRowSelection
                      filterFns={{
                        customSearchFilterFn: (row, id, filterValue) =>
                        row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
                       }} 
                      globalFilterFn="customSearchFilterFn" //exact serach filter functionality
                      enableColumnFilters={config.DatatableColumnFilter}
                      initialState={{ showColumnFilters: false, density: 'compact',
                        columnPinning: { left: ['mrt-row-expand','mrt-row-select','companyName'] }
                       }}
                      positionToolbarAlertBanner="bottom"
                      enableDensityToggle={false}
                      onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                      getRowId={(row) => row.companyId} //give each row a more useful id
                      // onRowSelectionChange={handleRowSelectionChange} //connect internal row selection state to your own
                      state={{ columnVisibility, rowSelection }} 
                      onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
                      muiTableHeadCellProps={{
                          //simple styling with the `sx` prop, works just like a style prop in this example
                          sx: {
                            backgroundColor:"#F6FAFF",
                          },
                        }} 
                      renderDetailPanel={({ row }) => (
                          <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                alignItems: 'center',
                            }}
                          >
                          <SummaryExpandRow row={row} />
                      </Box>
                      )}
                      //top csv option
                      renderTopToolbarCustomActions={({ table }) => (
                        <Box
                          sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                        >
                          <Button
                            sx={{ bgColor: "#2A68B3" }}
                            disabled={table.getPrePaginationRowModel().rows.length === 0}
                          //export all rows, including from the next page, (still respects filtering and sorting)
                            onClick={() =>
                              handleExportData(table.getPrePaginationRowModel().rows)
                            }
                            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                            // onClick={handleExportData}
                            startIcon={<FileDownloadIcon />}
                            variant="contained"
                            className='export'
                          >
                            CSV
                          </Button>
                        </Box>
                      )
                    } 
                    
                      />
             <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id='actionDiv'>
                    <Button variant="contained" color='success' disabled={saveDisable} onClick={bulkApprove} sx={{minWidth:200}}>Bulk Approve</Button>
            </Grid>

              <BootstrapDialog
                  open={open}
                  onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                     handleClose();
                    }
                  }}
                  aria-labelledby="responsive-dialog-title"
                >
                    <BootstrapDialogTitle id="customized-dialog-title">
                        
                      <Typography level="h2">Approval Confirmation</Typography>
                     
                      </BootstrapDialogTitle>
                      <DialogContent>
                      <DialogContentText>
                      <Typography ><b>Are you sure you want to approve this Data?</b></Typography>
                      </DialogContentText>
                      {/* <DialogContentText sx={{color:"black"}}>It is recommended to save data locally, only if you are ready to approve data. 
                        Update any commision rates that may have changed before approving. Do you confirm?</DialogContentText> */}
                      </DialogContent>
                    <DialogActions sx={{display:"flex",justifyContent:"center"}}>
                        <Button color="primary" variant="contained" sx={{minWidth: 150}} disabled={notconfirm} onClick={()=>{
                           handleClose();
                            }}>
                            No
                        </Button>
                        <Button color='success' variant="contained" sx={{minWidth: 150}} disabled={confirm} onClick={()=>{
                           approveCommission();  
                        }
                            }>
                            Yes
                        </Button>
                    </DialogActions>
                    </BootstrapDialog>
            </>
            );
        
    }
    
    
    export default Pending;