import React, { useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../Components/Loader/Loader';
import {
    Box,TextField, Stack, TableCell, Collapse,
    Grid, TableContainer, TableHead, TableRow, Paper, Button, Tooltip, Alert, Badge,InputAdornment,IconButton
} from '@mui/material';
import { Typography, Card, CardContent, CardOverflow, Option, } from '@mui/joy';
import StarIcon from '@mui/icons-material/Star';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useSelector, useDispatch } from 'react-redux';
import { fetchData, postData } from '../../redux/apiSlice';
import CircularProgress from '@mui/material/CircularProgress';
import config from '../../AppConfig';
import { Link } from 'react-router-dom';
import LoginHeader from '../../Components/LoginHeader';
import RegisterFooter from '../Auth/RegisterFooter';
import AuthUser from '../../Components/Auth/AuthUser';
import { login } from '../../redux/authSlice';

function ApiTool() {
    const { user ,http} = AuthUser();
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const [fieldError, setFieldError] = useState([]);
    const [error, setError] = useState();
    const [loginError, setLoginError] = useState();
    const [view3bError, setView3bError] = useState();
    const [epicProError, setEpicProError] = useState();
    const [freez, setFreez] = React.useState(true);
    const [isVisible, setIsVisible] = useState(true);
    const [checkingCredentials, setcheckingCredentials] = useState(0);
    const [common, setCommon] = useState(0);
    const [checkingEpicPro, setcheckingEpicPro] = useState(0);
    const [isVisible3b, setIsVisible3b] = useState(true);
    const [isVisibleEpiPro, setIsVisibleEpicPro] = useState(true);
    const [userName, setUserName] = useState('');
    const [password, setpassword] = useState('');
    const [companyId, setCompanyId] = useState(null);
    const [token, setToken] = useState(useSelector((state) => state.auth.token!= null ? state.auth.token : null));    

    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };



    const SignupSchema = Yup.object().shape({
        email: Yup.string()
            .email('Invalid email format.')
            // .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid email format.")       //commented for old affiliate login

            .required('Please enter your email address.'),
        password: Yup.string()
            .required('Please enter password.'),
    })

    const Create = async (values) => {
        setcheckingCredentials(1);
        setUserName(values.email);
        setpassword(values.password.trim());

        const apiUrl = '/checkCompanyId';
        const args = {
            username: values.email,
            password: values.password.trim(),
        }
        const response = await dispatch(postData({ apiUrl, args })).unwrap();
        if(response.success === true) {
            setCompanyId(response.companyId);
            const apiUrl = '/checkLoginCredentials';
            const args = {
                username: values.email,
                password: values.password.trim(),
            }
            const res = await dispatch(postData({ apiUrl, args })).unwrap();
            if(res.success === true) {
                setIsVisible(!isVisible);
                setLoginError(res.loginError);
                // set3bReport(1);
                const apiUrl = '/check3Breports';
                const args = {
                    username: values.email,
                    password: values.password.trim(),
                }
                const res1 = await dispatch(postData({ apiUrl, args })).unwrap();
                if(res1.success === true) {
                    setIsVisible3b(!isVisible3b);
                    setView3bError(res1.view3BError);
                    setcheckingEpicPro(1);
                    const apiUrl = '/checkEpicPro';
                    const args = {
                        username: values.email,
                        password: values.password.trim(),
                    }
                    const res2 = await dispatch(postData({ apiUrl, args })).unwrap();
                    if(res2.success === true) {
                        setIsVisibleEpicPro(!isVisibleEpiPro);
                        setEpicProError(res2.epicProError);
                    }else{
                            setIsVisibleEpicPro(!isVisibleEpiPro);
                            setEpicProError(res2.epicProError);
                    }  
                }else{
                    setIsVisible3b(!isVisible3b);
                    setView3bError(res1.view3BError);
                    setcheckingEpicPro(1);
                    const apiUrl = '/checkEpicPro';
                    const args = {
                        username: values.email,
                        password: values.password.trim(),
                    }
                    const res2 = await dispatch(postData({ apiUrl, args })).unwrap();
                    if(res2.success === true) {
                        setIsVisibleEpicPro(!isVisibleEpiPro);
                        setEpicProError(res2.epicProError);
                    }else{
                            setIsVisibleEpicPro(!isVisibleEpiPro);
                            setEpicProError(res2.epicProError);
                    }
                    
                    
                }
            }else{
                setIsVisible(!isVisible);
                setLoginError(res.loginError);
            }

        }



      
    }
     
    const refreshPage = () => {
        window.location.reload();
    };
    
    const checkToken=(email,password,type)=>{
        if(token != null){
            if(type=='view3b'){
                window.open('/view3BReport/' + email+"/"+ password,'_blank')
            }
            else{
                window.open('/epicproreport/member/' + email+"/"+ password,'_blank');
            }
          
        }
        else{
            handleFreezStart();
            http.post("/login", {
                email: 'apiuser@test.com',
                password: 'Mfsn1234!',
            })
                .then(async (res) => {
                    if (res.data.success === true) {
                        await dispatch(login(res.data));
                        localStorage.setItem('token', res.data.token);
                        localStorage.setItem('user', JSON.stringify(res.data.user));
                        localStorage.setItem('company', JSON.stringify(res.data.company)); 
                        localStorage.setItem('token', res.data.token);
                        localStorage.setItem('isAuthenticated', true);
                        localStorage.setItem('taxFlag', res.data.taxFlag); 
                        if(type=='view3b'){
                            window.open('/view3BReport/' + email+"/"+ password,'_blank')
                        }
                        else{
                            window.open('/epicproreport/member/' + email+"/"+ password,'_blank');
                        }
                        handleFreezStop();
                    }
                    else{
                        console.log('error')
                    }
                })
                .catch((error) => {
                    console.log('error')
                }); 
        }
    }

        return (


            
            <>
            <LoginHeader />

            {userName == '' ? 
                <Box mt={4} mb={4} px={9}>
                <Formik
                        initialValues={{
                            email: '',
                            password: '',
                        }}
                        validationSchema={SignupSchema}
                        onSubmit={(values, errors) => {
                            Create(values);
                        }}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                            <>
                           
                    <Card variant="outlined">
                        <CardOverflow
                            sx={{
                                bgcolor: '#F6FAFF',
                                borderColor: '#F6FAFF',
                                padding: '1%',
                            }}
                        >
                            <Grid container item md={12} sx={{ justifyContent: 'space-between' }}>
                                <Typography level="h5" sx={{ mb: 0.5 }}>MFSN Api Status</Typography>
                            </Grid>
                        </CardOverflow>

                        {/* <CardContent > */}
                          
                                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                                        <TextField 
                                        label="User Name" 
                                        variant="outlined" 
                                        size="small" 
                                        name="email"
                                        id="email" 
                                        value={values.email}
                                        fullWidth margin="dense" 
                                        sx={{ width: '80%' }}
                                        onChange={handleChange('email')}
                                        onBlur={handleBlur('email')}
                                        helperText={touched.email && errors.email ? errors.email : null}
                                        error={touched.email && errors.email ? errors.email : null}
                                        />
                                    </Grid >
                                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                                        <TextField 
                                        label="Password" 
                                        variant="outlined" 
                                        size="small" 
                                        name="password"
                                        id="password" 
                                        value={values.password}
                                        onChange={handleChange('password')}
                                        onBlur={handleBlur('password')}
                                        fullWidth margin="dense"  
                                        sx={{ width: '80%' }}
                                        helperText={touched.password && errors.password ? errors.password : null}
                                        error={touched.password && errors.password ? errors.password : null}
                                        />
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={2}>
                                        <Button variant="contained"  onClick={handleSubmit}>Submit</Button>
                                    </Grid>
                        {/* </CardContent> */}

                    </Card>
                   
                        </>
                    )}
                    </Formik>
                </Box>
                :  
                <Box mt={4} mb={4} px={9}> 
                      <Card variant="outlined">
                        <CardOverflow
                            sx={{
                                bgcolor: '#F6FAFF',
                                borderColor: '#F6FAFF',
                                padding: '1%',
                            }}
                        >
                            <Grid container item md={12} sx={{ justifyContent: 'space-between' }}>
                                <Typography level="h5" sx={{ mb: 0.5 }}>Showing Result For Username: {userName}</Typography>
                            </Grid>
                        </CardOverflow>

                    </Card>
                </Box>
                }
                {checkingCredentials == 1 ? 
                <Grid id='checkingCredentials'>
                    <Stack direction="row" spacing={2} mt={2} >
                        <StarIcon style={{ color: "green" }}/>
                        <Typography level="h2" fontSize="xl">Checking for Credentials : </Typography>
                        {isVisible && (
                            <Box sx={{ display: 'flex' }}>
                                <CircularProgress  />
                            </Box>
                        )}
                        {loginError === 0 ? (
                        <Typography variant="h2" fontSize="lg" color="success">
                            Success
                        </Typography>
                        ) : loginError === 1 ? (
                        <Typography variant="h2" fontSize="lg" sx={{ color: "red" }}>
                            Error
                        </Typography>
                        ) : null}
                    </Stack>
                </Grid>
                : ''}  
                {loginError === 0 ? 
                <Grid id='checking3bReport' >
                    <Stack direction="row" spacing={2} mt={2}>
                        <StarIcon style={{ color: "green" }}/>
                        <Typography level="h2" fontSize="xl">Checking for 3b Report : </Typography>
                        {isVisible3b && (
                            <Box sx={{ display: 'flex' }}>
                                <CircularProgress  />
                            </Box>
                        )}
                        {view3bError === 0 ? (
                            <>
                                <Typography variant="h2" fontSize="lg" color="success">
                                Success
                                </Typography>
                                <>
                                        <Tooltip title="View 3B" arrow><button type='submit' onClick={()=>checkToken(btoa(userName),btoa(password),'view3b')} style={{border:"none",background:"none",borderRadius:"0%",padding:'0px'}}><img src={require('../../assets/icons/view3B.png')} /></button></Tooltip>
                                </>
                                {/* <Link
                                to={config.API_URL + '/member/view3B/' + btoa(userName) + '/' + btoa(password)}
                                target="_blank"
                                >
                                <img src={require('../../assets/icons/view3B.png')} alt="View 3B" />
                                </Link> */}
                            </>
                            ) : view3bError === 1 ? (
                            <Typography variant="h2" fontSize="lg" sx={{ color: "red" }}>
                                Error
                            </Typography>
                            ) : null}
                    
                    </Stack>
                </Grid>
                : ''}
                {view3bError === 1 || view3bError === 0 ? 
                <Grid id='checking3bApi'>
                    <Stack direction="row" spacing={2} mt={2}>
                        <StarIcon style={{ color: "green" }}/>
                        <Typography level="h2" fontSize="xl">Checking for 3B API : </Typography>
                        {view3bError === 0 ? (
                        <Typography variant="h2" fontSize="lg" color="success">
                            Success
                        </Typography>
                        ) : view3bError === 1 ? (
                        <Typography variant="h2" fontSize="lg" sx={{ color: "red" }}>
                            Error
                        </Typography>
                        ) : null}
                    </Stack>
                </Grid>
                :'' }
                {checkingEpicPro == 1 ?   
                <Grid id='checkingEpicPro'>
                    <Stack direction="row" spacing={2} mt={2}>
                        <StarIcon style={{ color: "green" }}/>
                        <Typography level="h2" fontSize="xl">Checking for Epic Pro : </Typography>
                        {isVisibleEpiPro && (
                            <Box sx={{ display: 'flex' }}>
                                <CircularProgress  />
                            </Box>
                        )}
                        {epicProError === 0 ? (
                         <>
                            <Typography variant="h2" fontSize="lg" color="success">
                                Success
                            </Typography>
                            <>
                                {/* <form method="POST" action={config.API_URL+'/api/member/epicproreport'} target="_blank">
                                    <input type="hidden" name="companyId" value={companyId} />
                                    <input type="hidden" name="username" value={btoa(userName)} />
                                    <input type="hidden" name="password" value={btoa(password)} />    */}
                                    <Tooltip title="View Epic Pro Report" arrow><button type='submit' onClick={()=>checkToken(btoa(userName),btoa(password))} style={{border:"none",background:"none",borderRadius:"0%",padding:'0px'}}><img src={require('../../assets/icons/EPR.png')} /></button></Tooltip>
                                {/* </form> */}
                            </>
                            {/* <Link
                                to={config.API_URL+'/member/epicproreport/'+companyId+'/'+btoa(userName)+'/'+btoa(password)} 
                                target="_blank"
                                >
                                <img src={require('../../assets/icons/EPR.png')} />
                            </Link> */}
                        </>
                        ) : epicProError === 1 ? (
                        <Typography variant="h2" fontSize="lg" sx={{ color: "red" }}>
                            Error
                        </Typography>
                        ) : null}
                    </Stack>
                </Grid>
                : '' }
                {epicProError === 1 || epicProError === 0 ? 
                <Grid id='checkingEpicProApi'>
                    <Stack direction="row" spacing={2} mt={2}>
                        <StarIcon style={{ color: "green" }}/>
                        <Typography level="h2" fontSize="xl">Checking for Epic Pro API : </Typography>
                        {epicProError === 0 ? (
                        <Typography variant="h2" fontSize="lg" color="success">
                            Success
                        </Typography>
                        ) : epicProError === 1 ? (
                        <Typography variant="h2" fontSize="lg" sx={{ color: "red" }}>
                            Error
                        </Typography>
                        ) : null}
                        
                    </Stack>
                </Grid>
                : ''}
                {userName == '' ? '': 
                    <Grid>
                        <Stack direction="row" spacing={2} mt={2}>
                            <Button variant="contained" onClick={refreshPage}>Check For another Member</Button>
                        </Stack>
                    </Grid>
                }

              <div className='footer-block'> <RegisterFooter  /></div>
           
            
            </>

        );
    }
    

export default ApiTool;
