import React, { useEffect,useState } from 'react';
import { Box, Grid, Button, TextField, InputLabel, MenuItem, FormControl, Select, FormHelperText, Input, OutlinedInput,
    FormControlLabel,RadioGroup,Radio,Checkbox,FormLabel } from '@mui/material';
import { Typography, Card, CardContent } from '@mui/joy';
import DefaultLayout from '../../../Components/DefaultLayout';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import swal from "sweetalert";
import Freez from '../../../Components/Loader/Loader';
import Backdrop from '@mui/material/Backdrop';
import AuthUser from '../../../Components/Auth/AuthUser';
import {useNavigate } from 'react-router-dom';
import { updateTaxFlag } from '../../../../src/redux/authSlice';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};


let updateFieldCount= 0;
    let firstNameCount= 0;
    let lastNameCount= 0;
    let companyNameCount= 0;
    let emailCount= 0;
    let phoneCount= 0;
    let mobileCount= 0;
    let faxCount= 0;
    let addressCount= 0;
    let cityCount= 0;
    let stateCount=0;
    let zipCount=0;
    let ssnCount=0;
    let proofTypeCount=0;
    let checkboxValueCount=0;

function Form1099(props) {
    const { user } = AuthUser();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [states, setStates] = useState([]);
    const [stateCode, setStateCode] = useState('');
    const [payment, setPayment] = useState([]);
    const [freez, setFreez] = React.useState(true);
    const [isClicked, setIsClicked] = React.useState(true);
    const [formDetails, setFormDetails] = useState([]);
    const [companyId, setCompanyId] = useState(user ? user.company_master_id : '');
    const [checkUsaTldStatus, setCheckUsaTldStatus] = useState(false);
	const [emailButton, setEmailButton] = useState(false);

    let RequestFormInitialValues = {
        firstName: formDetails ? formDetails.first_name : '',
        lastName: formDetails ? formDetails.last_name : '',
        companyName : formDetails ? formDetails.company_name :'',
        emailAddress: formDetails ? formDetails.email :'',
        phoneNumber : formDetails ? formDetails.phone_number :'',
        mobileNumber : formDetails ? formDetails.mobile_number :'',
        faxNumber : formDetails ? formDetails.fax :'',
        streetAddress: formDetails ? formDetails.street_address :'',
        city : formDetails ? formDetails.city :'',
        state : formDetails ? formDetails.state_code :'',
        country : 'United States',
        zip : formDetails ? formDetails.zip :'',
        ssn: formDetails ? formDetails.tax_id :'',
        proofType: formDetails ? formDetails.proof_type :'',
        checkboxValue : false,
        isFormDirty : true,
        ssnlabel :  formDetails ? formDetails.proof_type == "tax" ? "EIN* (9 digit number without any special characters)" : formDetails.proof_type == "ssn" ? "SSN* (9 digit number without any special characters)" : '' : '',
    };
   
    const handleChange1 = (event) => {
		setStateCode(event.target.value);
	};

    useEffect(() => {
        if (user.roles_id == 3){
            fetchState();
            fetch1099Details();
        }else{
            navigate('/login');
        }
	}, [])

    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };

    const fetchState = async() => {
        const apiUrl = '/fetchStates';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res) {
            setStates(res.data);
        }
        handleFreezStop();
	}

    const handleChangeClick = () => {
        setIsClicked(!isClicked);
	}

    const fetch1099Details = async() => {
        const apiUrl = '/company-tax-details/' + companyId;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res) {
            if(res.success == true) {
                setFormDetails(res.company1099Info);
                setPayment(res.payment);
            }else{
                swal({
                    title: "Failed",
                    text: res.message,
                    icon: "error",
                    button: "Ok",
                })
                setTimeout(function () {
                    navigate('/affiliate/dashboard');
                }, 3000);
            }
        }
        handleFreezStop();
	}
    //fetch city and zipcode
    const fetchLocationInfo = async (zipcode, setFieldValue) => {
        const apiUrl = "/zipcode/"+zipcode;
        setFieldValue('city','');
        setFieldValue('state','');
        if(zipcode.length == 5)
        {
            setFieldValue('city','...');
            setFieldValue('state','...');
            const res = await dispatch(fetchData(apiUrl)).unwrap();
            if(res.success)
            {
                setFieldValue('city', res.data.places[0]['place name']);
                setFieldValue('state', res.data.places[0]['state abbreviation']);
            }
            else
            {
                setFieldValue('city','');
                setFieldValue('state','');
            }
        }
    };

    const checkUsaTLD = async(email) => {
        if (email.includes('@')) {
			setEmailButton(true);
            const apiUrl = '/checkUsaTLD';
            const args = {
                email: email,
            }
            const res = await dispatch(postData({ apiUrl, args })).unwrap();
            if (res) {
                if (res.success === true) {
                    setCheckUsaTldStatus(true);
                } else {
                    setCheckUsaTldStatus(false);
                }
            }
			setEmailButton(false);
        }
    }
   

    const submitRequest = async (values) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id == 3 ){
            if(checkUsaTldStatus === true || emailButton === true) {
                return false;
            }
            handleFreezStart();
            const apiUrl = '/form-1099-details/store';
            const args = {
                firstName: values.firstName,
                lastName: values.lastName,
                company_name: values.companyName,
                email: values.emailAddress,
                mobile_number: values.mobileNumber,
                phone_number: values.phoneNumber,
                fax: values.faxNumber,
                street_address: values.streetAddress,
                city: values.city,
                state_code: values.state,
                zip: values.zip,
                country: values.country,
                proofType: values.proofType,
                tax_id: values.ssn,
            }
            const res = await dispatch(postData({apiUrl,args})).unwrap();
            if(res) {
                if (res.success) {
                    await dispatch(updateTaxFlag(res.data));
                    localStorage.setItem('taxFlag', 1);
                    swal({
                        title: "Success",
                        text: res.message,
                        icon: "success",
                        button: "Ok",
                    })

                    setTimeout(function () {
                        navigate('/affiliate/dashboard');
                      }, 3000);
                   
                } 
                else {
                    swal({
                        title: "Failed",
                        text: res.message,
                        icon: "error",
                        button: "Ok",
                    })
                }
                handleFreezStop();
            }
        }else{
            navigate('/login');
            return false;
        }
        
    }

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
        .max(15, 'Too Long! (Length must not exceed 15 characters')
        .matches(
            /^[A-Za-z0-9#&()'\-.]+(?: [A-Za-z0-9#&()'\-.]+)*$/,
            'Please enter a valid first name'
        ).required('First Name is required'),
        lastName: Yup.string()
        .max(24, 'Too Long! (Length must not exceed 24 characters')
        .matches(
            /^[A-Za-z0-9#&()'\-.]+(?: [A-Za-z0-9#&()'\-.]+)*$/,
            'Please enter a valid last name'
        ).required('Last Name is required'),
        companyName: Yup.string()
        .max(40, 'Too Long! (Length must not exceed 40 characters')
        .matches(
            /^[A-Za-z0-9#&()'\-.]+(?: [A-Za-z0-9#&()'\-.]+)*$/,
            'Please enter a valid company name'
        ).required('Company Name is required'),
        emailAddress: Yup.string()
        // .email('Invalid email format.')
        .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i, "Invalid email format.")
        .required('Email Address is required'),
        phoneNumber: Yup.string()
        .min(14, 'Phone number must be of 10 digit!')
        .max(14, 'Phone number must be of maximum 10 digit!')
        .required('Phone number is required'),
        mobileNumber: Yup.string()
        .min(14, 'Mobile number must be of 10 digit!')
        .max(14, 'Mobile number must be of maximum 10 digit!')
        .required('Mobile number is required'),
        streetAddress: Yup.string()
        .max(40, 'Too Long! (Length must not exceed 40 characters')
        .matches(
            /^[A-Za-z0-9#&()'\-.]+(?: [A-Za-z0-9#&()'\-.]+)*$/,
            'Please enter a valid street address'
        ).required('Street Address is required'),
        city: Yup.string()
        .max(22, 'Too Long! (Length must not exceed 22 characters')
        .matches(
            /^[A-Za-z0-9#&()'\-.]+(?: [A-Za-z0-9#&()'\-.]+)*$/,
            'Please enter a valid city'
        ).required('City is required'),
        state: Yup.string()
			.min(1, 'Too Short!')
			.max(50, 'Too Long!')
			.required('Please select your State.'),
        zip: Yup.string()
        .max(9, 'ZipCode must be 5 or 9 digits!')
        .matches(
           /^(?:\d{5}|\d{9})$/,
            'Please enter a valid zip code'
        )
            .required('Zipcode is required'),

        proofType: Yup.string()
        .required('Please Select Tax-ID Type.'),

        ssn:Yup.string()
            .min(9, 'SSN or EIN must be of 9 digits without any special characters!')
            .max(9, 'SSN or EIN must be of maximum 9 digits without any special characters!')
            .matches(
                /^(?=[0-9]*$)/,
                'Only digits are allowed. No special characters like - are allowed'
            )
            .required('EIN or SSN is required'),
        checkboxValue: Yup.boolean()
            .oneOf([true], 'You must acknowledge that the information is correct and true.')
            .required('You must acknowledge that the information is correct and true.'),
          // other fields in your form

    });
    const ContentLayout=
            <>
                <Grid container item lg={12} md={12} sm={12} xs={12} mb={2}>
                    <Typography level="h4" color="primary">Information Required to Issue Your Form 1099 :</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mb={2}>
                    <Typography level="h6">Congratulations!  Your company was paid ${payment ? parseFloat(payment.paid).toFixed(2) : 0} in calendar year 2024.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Typography level="h6">MFSN is required to issue 1099-NEC Forms to anyone to whom they paid $600 or more in 2024. Please complete the following form so we may mail your form to you. <b>IMPORTANT!</b>  Tax Information and a Valid EIN or SSN must be submitted before 1/31/25. If not submitted or found to be invalid information, MFSN is required to begin withholding taxes on your commissions.</Typography>
                </Grid>
                <Formik
                    initialValues={RequestFormInitialValues}
                    validationSchema={validationSchema}
                    onSubmit={submitRequest}
                    enableReinitialize={true}
                    >
                    {({ values, errors, touched, handleChange, handleBlur, setFieldTouched, handleSubmit, setFieldValue, setFieldError }) => (
                        <Form>
                            <div>
                                <Box>
                                    <Card>
                                        <CardContent>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                                                <FormControl variant="outlined" size="small" fullWidth margin="dense" sx={{ width: '80%' }}>
                                                    <InputLabel htmlFor="component-helper" shrink={Boolean(values.firstName)}>First Name*</InputLabel>
                                                    <OutlinedInput
                                                        id="firstName" 
                                                        name='firstName'
                                                        label="First Name*"
                                                        value={values.firstName}
                                                        notched={Boolean(values.firstName)}
                                                        // inputProps={{
                                                        //     maxLength:15
                                                        //   }}
                                                        // onChange={handleChange('firstName')}
                                                        onChange={(e)=>{
                                                            handleChange(e)
                                                            if((e.target.value == formDetails.first_name) || (formDetails.first_name == null && e.target.value == '')){
                                                                firstNameCount = 0;
                                                                updateFieldCount = updateFieldCount - 1; 
                                                                if(updateFieldCount <= 0){
                                                                    setFieldValue('isFormDirty',true)
                                                                }                                                                
                                                            }else{                                          
                                                                setFieldValue('isFormDirty',false) 
                                                                if(firstNameCount == 0){
                                                                    firstNameCount = firstNameCount + 1;
                                                                    updateFieldCount = updateFieldCount + 1;                                                        
                                                                }
                                                            }
                                                        }}
                                                        
                                                        onBlur={handleBlur('firstName')}
                                                        // helperText={touched.firstName && errors.firstName ? errors.firstName : null}
														error={touched.firstName && errors.firstName ? errors.firstName : null}
                                                    />
                                                    {touched.firstName && errors.firstName ? <div className='error'>{errors.firstName}</div> : null}
                                                </FormControl>
                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                                                <FormControl variant="outlined" size="small" fullWidth margin="dense" sx={{ width: '80%' }}>
                                                    <InputLabel htmlFor="component-helper" shrink={Boolean(values.lastName)}>Last Name*</InputLabel>
                                                    <OutlinedInput
                                                        id="lastName" 
                                                        name='lastName'
                                                        label="Last Name*"
                                                        value={values.lastName}
                                                        notched={Boolean(values.lastName)}
                                                        onChange={(e)=>{
                                                            handleChange(e)
                                                            if((e.target.value == formDetails.last_name) || (formDetails.last_name == null && e.target.value == '')){
                                                                lastNameCount = 0;
                                                                updateFieldCount = updateFieldCount - 1; 
                                                                if(updateFieldCount <= 0){
                                                                    setFieldValue('isFormDirty',true)
                                                                }                                                                
                                                            }else{                                          
                                                                setFieldValue('isFormDirty',false) 
                                                                if(lastNameCount == 0){
                                                                    lastNameCount = lastNameCount + 1;
                                                                    updateFieldCount = updateFieldCount + 1;                                                        
                                                                }
                                                            }
                                                        }}
                                                        onBlur={handleBlur('lastName')}
														error={touched.lastName && errors.lastName ? errors.lastName : null}
                                                    />
                                                    {touched.lastName && errors.lastName ? <div className='error'>{errors.lastName}</div> : null}
                                                </FormControl>
                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={1}>
                                                <FormControl variant="outlined" size="small" fullWidth margin="dense" sx={{ width: '80%' }}>
                                                    <InputLabel htmlFor="component-helper" shrink={Boolean(values.companyName)}>Company Name*</InputLabel>
                                                    <OutlinedInput
                                                        id="companyName" 
                                                        name='companyName'
                                                        label="Company Name*"
                                                        value={values.companyName}
                                                        notched={Boolean(values.companyName)}
                                                        // onChange={handleChange('companyName')}
                                                        onChange={(e)=>{
                                                            handleChange(e)
                                                            if((e.target.value == formDetails.company_name) || (formDetails.company_name == null && e.target.value == '')){
                                                                companyNameCount = 0;
                                                                updateFieldCount = updateFieldCount - 1; 
                                                                if(updateFieldCount <= 0){
                                                                    setFieldValue('isFormDirty',true)
                                                                }                                                                
                                                            }else{                                          
                                                                setFieldValue('isFormDirty',false) 
                                                                if(companyNameCount == 0){
                                                                    companyNameCount = companyNameCount + 1;
                                                                    updateFieldCount = updateFieldCount + 1;                                                        
                                                                }
                                                            }
                                                        }}
                                                        onBlur={handleBlur('companyName')}
                                                        // helperText={touched.companyName && errors.companyName ? errors.companyName : null}
														error={touched.companyName && errors.companyName ? errors.companyName : null}
                                                    />
                                                    {touched.companyName && errors.companyName ? <div className='error'>{errors.companyName}</div> : null}
                                                </FormControl>
                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={1}>
                                                <FormControl variant="outlined" size="small" fullWidth margin="dense" sx={{ width: '80%' }}>
                                                    <InputLabel htmlFor="component-helper" shrink={Boolean(values.emailAddress)}>Email Address*</InputLabel>
                                                    <OutlinedInput
                                                        id="emailAddress" 
                                                        name='emailAddress' 
                                                        label="Email Address*"
                                                        value={values.emailAddress}
                                                        notched={Boolean(values.emailAddress)}
                                                        // onChange={handleChange('emailAddress')}
                                                        onChange={(e)=>{
                                                            handleChange(e)
                                                            checkUsaTLD(e.target.value);
                                                            if((e.target.value == formDetails.email) || (formDetails.email == null && e.target.value == '')){
                                                                emailCount = 0;
                                                                updateFieldCount = updateFieldCount - 1; 
                                                                if(updateFieldCount <= 0){
                                                                    setFieldValue('isFormDirty',true)
                                                                }                                                                
                                                            }else{                                          
                                                                setFieldValue('isFormDirty',false) 
                                                                if(emailCount == 0){
                                                                    emailCount = emailCount + 1;
                                                                    updateFieldCount = updateFieldCount + 1;                                                        
                                                                }
                                                            }
                                                        }}
                                                        onBlur={handleBlur('emailAddress')}
                                                        // helperText={touched.emailAddress && errors.emailAddress ? errors.emailAddress : null}
														error={touched.emailAddress && errors.emailAddress ? errors.emailAddress : null}
                                                    />
                                                    <div className='error'>{touched.emailAddress && errors.emailAddress ?errors.emailAddress : checkUsaTldStatus ? "Invalid email format." : '' }</div> 
                                                </FormControl>
                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={1}>
                                                <FormControl variant="outlined" size="small" fullWidth margin="dense" sx={{ width: '80%' }}>
                                                    <InputMask
                                                        mask='(999) 999-9999'
                                                        maskChar={null}
                                                        // type="text"
                                                        required
                                                        fullWidth
                                                        margin="dense"
                                                        size="small"
                                                        id="phoneNumber"
                                                        label="Phone Number"
                                                        name="phoneNumber"
                                                        // onChange={handleChange('phoneNumber')}
                                                        onChange={(e)=>{
                                                            handleChange(e)
                                                            if((e.target.value == formDetails.phone_number) || (formDetails.phone_number == null && e.target.value == '')){
                                                                phoneCount = 0;
                                                                updateFieldCount = updateFieldCount - 1; 
                                                                if(updateFieldCount <= 0){
                                                                    setFieldValue('isFormDirty',true)
                                                                }                                                                
                                                            }else{                                          
                                                                setFieldValue('isFormDirty',false) 
                                                                if(phoneCount == 0){
                                                                    phoneCount = phoneCount + 1;
                                                                    updateFieldCount = updateFieldCount + 1;                                                        
                                                                }
                                                            }
                                                        }}
                                                        InputLabelProps={{
                                                        shrink: Boolean(values.phoneNumber),
                                                        }}
                                                        value={values.phoneNumber}
                                                        onBlur={handleBlur('phoneNumber')}
                                                        autoComplete="off"
                                                        // helperText={touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : null}
                                                        error={touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : null}
                                                    >
                                                        
                                                        {(inputProps) =>
                                                            <TextField
                                                                label="Phone Number" variant="outlined"
                                                                {...inputProps}
                                                            />
                                                        }
                                                    </InputMask>
                                                    {touched.phoneNumber && errors.phoneNumber ? <div className='error'>{errors.phoneNumber}</div> : null}
                                                </FormControl>
                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={1}>
                                                <FormControl variant="outlined" size="small" fullWidth margin="dense" sx={{ width: '80%' }}>
                                                    <InputMask
                                                        mask='(999) 999-9999'
                                                        maskChar={null}
                                                        type="text"
                                                        required
                                                        fullWidth
                                                        margin="dense"
                                                        size="small"
                                                        id="mobileNumber"
                                                        label="Mobile Number"
                                                        name="mobileNumber"
                                                        InputLabelProps={{
                                                            shrink: Boolean(values.mobileNumber),
                                                            }}
                                                        // onChange={handleChange('mobileNumber')}
                                                        onChange={(e)=>{
                                                            handleChange(e)
                                                            if((e.target.value == formDetails.mobile_number) || (formDetails.mobile_number == null && e.target.value == '')){
                                                                mobileCount = 0;
                                                                updateFieldCount = updateFieldCount - 1; 
                                                                if(updateFieldCount <= 0){
                                                                    setFieldValue('isFormDirty',true)
                                                                }                                                                
                                                            }else{                                          
                                                                setFieldValue('isFormDirty',false) 
                                                                if(mobileCount == 0){
                                                                    mobileCount = mobileCount + 1;
                                                                    updateFieldCount = updateFieldCount + 1;                                                        
                                                                }
                                                            }
                                                        }}
                                                        value={values.mobileNumber}
                                                        onBlur={handleBlur('mobileNumber')}
                                                        autoComplete="off"
                                                        // helperText={touched.mobileNumber && errors.mobileNumber ? errors.mobileNumber : null}
                                                        error={touched.mobileNumber && errors.mobileNumber ? errors.mobileNumber : null}
                                                    >
                                                        {(inputProps) =>
                                                            <TextField
                                                                label="Phone Number" variant="outlined"
                                                                {...inputProps}
                                                            />
                                                        }
                                                    </InputMask>
                                                    {touched.mobileNumber && errors.mobileNumber ? <div className='error'>{errors.mobileNumber}</div> : null}
                                                </FormControl>
                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={1}>
                                                <FormControl variant="outlined" size="small" fullWidth margin="dense" sx={{ width: '80%' }}>
                                                    <InputLabel htmlFor="component-helper" shrink={Boolean(values.faxNumber)}>Fax No</InputLabel>
                                                    <OutlinedInput
                                                        id="faxNumber" 
                                                        name='faxNumber' 
                                                        label="Fax No"
                                                        value={values.faxNumber}
                                                        notched={Boolean(values.faxNumber)}
                                                        // onChange={handleChange('faxNumber')}
                                                        onChange={(e)=>{
                                                            handleChange(e)
                                                            if((e.target.value == formDetails.fax) || (formDetails.fax == null && e.target.value == '')){
                                                                faxCount = 0;
                                                                updateFieldCount = updateFieldCount - 1; 
                                                                if(updateFieldCount <= 0){
                                                                    setFieldValue('isFormDirty',true)
                                                                }                                                                
                                                            }else{                                          
                                                                setFieldValue('isFormDirty',false) 
                                                                if(faxCount == 0){
                                                                    faxCount = faxCount + 1;
                                                                    updateFieldCount = updateFieldCount + 1;                                                        
                                                                }
                                                            }
                                                        }}
                                                        onBlur={handleBlur('faxNumber')}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={1}>
                                                <FormControl variant="outlined" size="small" fullWidth margin="dense" sx={{ width: '80%' }}>
                                                    <InputLabel htmlFor="component-helper" shrink={Boolean(values.streetAddress)}>Street Address*</InputLabel>
                                                    <OutlinedInput
                                                        id="streetAddress" 
                                                        name='streetAddress' 
                                                        label="Street Address*"
                                                        value={values.streetAddress}
                                                        notched={Boolean(values.streetAddress)}
                                                        // onChange={handleChange('streetAddress')}
                                                        onChange={(e)=>{
                                                            handleChange(e)
                                                            if((e.target.value == formDetails.street_address) || (formDetails.street_address == null && e.target.value == '')){
                                                                addressCount = 0;
                                                                updateFieldCount = updateFieldCount - 1; 
                                                                if(updateFieldCount <= 0){
                                                                    setFieldValue('isFormDirty',true)
                                                                }                                                                
                                                            }else{                                          
                                                                setFieldValue('isFormDirty',false) 
                                                                if(addressCount == 0){
                                                                    addressCount = addressCount + 1;
                                                                    updateFieldCount = updateFieldCount + 1;                                                        
                                                                }
                                                            }
                                                        }}
                                                        onBlur={handleBlur('streetAddress')}
                                                        // helperText={touched.streetAddress && errors.streetAddress ? errors.streetAddress : null}
														error={touched.streetAddress && errors.streetAddress ? errors.streetAddress : null}
                                                    />
                                                    {touched.streetAddress && errors.streetAddress ? <div className='error'>{errors.streetAddress}</div> : null}
                                                </FormControl>
                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={1}>
                                                <FormControl variant="outlined" size="small" fullWidth margin="dense" sx={{ width: '80%' }}>
                                                    <InputLabel htmlFor="component-helper"  shrink={Boolean(values.zip)}>Zip*</InputLabel>
                                                    <OutlinedInput
                                                        id="zip" 
                                                        name="zip" 
                                                        label="zip*"
                                                        value={values.zip}
                                                        notched={Boolean(values.zip)}
                                                        // onChange={handleChange('zip')}
                                                        onChange={(e)=>{
                                                            handleChange(e);
                                                            if((e.target.value == formDetails.zip) || (formDetails.zip == null && e.target.value == '')){
                                                                zipCount = 0;
                                                                updateFieldCount = updateFieldCount - 1; 
                                                                if(updateFieldCount <= 0){
                                                                    setFieldValue('isFormDirty',true)
                                                                }                                                                
                                                            }else{                                          
                                                                setFieldValue('isFormDirty',false) 
                                                                if(zipCount == 0){
                                                                    zipCount = zipCount + 1;
                                                                    updateFieldCount = updateFieldCount + 1;                                                        
                                                                }
                                                            }
                                                            fetchLocationInfo(e.target.value, setFieldValue);
                                                        }}
                                                        onBlur={handleBlur('zip')}
                                                        // helperText={touched.zip && errors.zip ? errors.zip : null}
														error={touched.zip && errors.zip ? errors.zip : null}
                                                    />
                                                    {touched.zip && errors.zip ? <div className='error'>{errors.zip}</div> : null}
                                                </FormControl>
                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={1}>
                                                <FormControl variant="outlined" size="small" fullWidth margin="dense" sx={{ width: '80%' }}>
                                                    <InputLabel htmlFor="component-helper" shrink={Boolean(values.city)}>City*</InputLabel>
                                                    <OutlinedInput
                                                        id="city" 
                                                        name='city' 
                                                        label="city*"
                                                        value={values.city}
                                                        notched={Boolean(values.city)}
                                                        // onChange={handleChange('city')}
                                                        onChange={(e)=>{
                                                            handleChange(e)
                                                            if((e.target.value == formDetails.city) || (formDetails.city == null && e.target.value == '')){
                                                                cityCount = 0;
                                                                updateFieldCount = updateFieldCount - 1; 
                                                                if(updateFieldCount <= 0){
                                                                    setFieldValue('isFormDirty',true)
                                                                }                                                                
                                                            }else{                                          
                                                                setFieldValue('isFormDirty',false) 
                                                                if(cityCount == 0){
                                                                    cityCount = cityCount + 1;
                                                                    updateFieldCount = updateFieldCount + 1;                                                        
                                                                }
                                                            }
                                                        }}
                                                        onBlur={handleBlur('city')}
                                                        // helperText={touched.city && errors.city ? errors.city : null}
														error={touched.city && errors.city ? errors.city : null}
                                                    />
                                                    {touched.city && errors.city ? <div className='error'>{errors.city}</div> : null}
                                                </FormControl>
                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={1}>
                                               <FormControl fullWidth size="small" margin="dense" sx={{ width: '80%' }}
                                               error={touched.state && errors.state ? errors.state : null}>
                                                    <InputLabel id="demo-multiple-name-label" shrink={Boolean(values.state)}>State*</InputLabel>
                                                    <Select
                                                        fullWidth
                                                        labelId="demo-simple-select-label"
                                                        value={values.state ||''}
                                                        notched={Boolean(values.state)}
                                                        // onChange={(e) => {handleChange(e); setFieldValue("state", e.target.value, true) }}
                                                        onChange={(e)=>{
                                                            handleChange(e); 
                                                            setFieldValue("state", e.target.value, true)
                                                            if((e.target.value == formDetails.state_code) || (formDetails.state_code == null && e.target.value == '')){
                                                                stateCount = 0;
                                                                updateFieldCount = updateFieldCount - 1; 
                                                                if(updateFieldCount <= 0){
                                                                    setFieldValue('isFormDirty',true)
                                                                }                                                                
                                                            }else{                                          
                                                                setFieldValue('isFormDirty',false) 
                                                                if(stateCount == 0){
                                                                    stateCount = stateCount + 1;
                                                                    updateFieldCount = updateFieldCount + 1;                                                        
                                                                }
                                                            }
                                                        }}
                                                        onBlur={handleBlur('state')}
                                                        id="state"
                                                        input={<OutlinedInput label="State" />}
                                                        name="state"
                                                        // helperText={touched.state && errors.state ? errors.state : null}
														error={touched.state && errors.state ? errors.state : null}
                                                        MenuProps={MenuProps}
                                                    >
                                                        {states.map((state, index) => {
                                                            return (
                                                                <MenuItem key={index} value={state.state_code}

                                                                >{state.state_name}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                    {touched.state && errors.state ? <FormHelperText >Please select State.</FormHelperText> : null}

                                                </FormControl>
                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={1}>
                                                <FormControl variant="outlined" size="small" fullWidth margin="dense" sx={{ width: '80%' }}>
                                                    <InputLabel htmlFor="component-helper" shrink={Boolean(values.country)}>Country</InputLabel>
                                                    <OutlinedInput
                                                        id="country" 
                                                        name="country" 
                                                        label="country"
                                                        value={values.country}
                                                        notched={Boolean(values.country)}
                                                        onChange={handleChange('country')}
                                                        onBlur={handleBlur('country')}
                                                        readOnly={true}
                                                        style={{ backgroundColor: '#f0f0f0' }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={1}>
                                                {/* <Card variant="outlined"> */}
                                                    <FormControl  sx={{ width: '80%' }}>
                                                        <FormLabel>Please provide the relevant Tax-ID  below*</FormLabel>
                                                        <RadioGroup
                                                            row
                                                            name='proofType'
                                                            id='proofType'
                                                            onChange={(e)=>{
                                                                handleChange(e);
                                                                console.log("proof:"+ e.target.value);
                                                                if(e.target.value == "tax") {
                                                                    // setssnlabel("Tax ID* (9 digit number without any special characters)");
                                                                    setFieldValue('ssnlabel',"EIN* (9 digit number without any special characters)") 
                                                                }else if(e.target.value == "ssn"){
                                                                  setFieldValue('ssnlabel',"SSN* (9 digit number without any special characters)") 
                                                                }
                                                                if(e.target.value == formDetails.proof_type){
                                                                    proofTypeCount = 0;
                                                                    updateFieldCount = updateFieldCount - 1; 
                                                                    if(updateFieldCount <= 0){
                                                                        setFieldValue('isFormDirty',true)
                                                                    }                                                                        
                                                                }else{                                          
                                                                    setFieldValue('isFormDirty',false) 
                                                                    if(proofTypeCount == 0){
                                                                        proofTypeCount = proofTypeCount + 1;
                                                                        updateFieldCount = updateFieldCount + 1;
                                                                    }
                                                                }
                                                                
                                                            }}
                                                            value={values.proofType || ''}
                                                            onBlur={handleBlur('proofType')}
                                                        >
                                                            <FormControlLabel value="tax" control={<Radio />} label="EIN" sx={{ fontWeight: "bold" }} />
                                                            <FormControlLabel value="ssn" control={<Radio />} label="SSN" sx={{ fontWeight: "bold" }} />
                                                        </RadioGroup>
                                                        {touched.proofType && errors.proofType ? <div className='error'>{errors.proofType}</div> : null}
                                                    </FormControl>
                                                {/* </Card> */}
                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={1}>
                                                <FormControl variant="outlined" size="small" fullWidth margin="dense" sx={{ width: '80%' }}>
                                                <InputLabel htmlFor="component-helper" shrink={Boolean(values.ssn)}>{values.ssnlabel} </InputLabel>
                                                    <OutlinedInput
                                                        id="ssn" 
                                                        name='ssn' 
                                                        label={values.ssnlabel}
                                                        value={values.ssn}
                                                        notched={Boolean(values.ssn)}
                                                        // onChange={handleChange('ssn')}
                                                        onChange={(e)=>{
                                                            handleChange(e);
                                                            if((e.target.value == formDetails.tax_id) || (formDetails.tax_id == null && e.target.value == '')){
                                                                ssnCount = 0;
                                                                updateFieldCount = updateFieldCount - 1; 
                                                                if(updateFieldCount <= 0){
                                                                    setFieldValue('isFormDirty',true)
                                                                }                                                                
                                                            }else{                                          
                                                                setFieldValue('isFormDirty',false) 
                                                                if(ssnCount == 0){
                                                                    ssnCount = ssnCount + 1;
                                                                    updateFieldCount = updateFieldCount + 1;                                                        
                                                                }
                                                            }
                                                        }}
                                                        onBlur={handleBlur('ssn')}
                                                        // helperText={touched.ssn && errors.ssn ? errors.ssn : null}
														error={touched.ssn && errors.ssn ? errors.ssn : null}
                                                    />
                                                    {touched.ssn && errors.ssn ? <div className='error'>{errors.ssn}</div> : null}
                                                </FormControl>
                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                                                <FormControlLabel control=
                                                {<Checkbox
                                                 name="checkboxValue"
                                                 id="checkboxValue"
                                                 checked={values.checkboxValue} 

                                                 />} label="By clicking the button below, I acknowledge that the information above is correct and true."
                                                    onChange={(e) => {
                                                        if(values.checkboxValue){
                                                            checkboxValueCount = 0;
                                                            updateFieldCount = updateFieldCount - 1; 
                                                            if(updateFieldCount <= 0){
                                                                setFieldValue('isFormDirty',true)
                                                            }                                                                
                                                        }else{                                          
                                                            setFieldValue('isFormDirty',false) 
                                                            if(checkboxValueCount == 0){
                                                                checkboxValueCount = checkboxValueCount + 1;
                                                                updateFieldCount = updateFieldCount + 1;                                                        
                                                            }
                                                        }      
                                                        setFieldValue('checkboxValue',!(values.checkboxValue)) 
                                                    }}
                                                />
                                                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                                                 {touched.checkboxValue && errors.checkboxValue ? <div className='error'>{errors.checkboxValue}</div> : null}
                                                </Grid>
                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={2}>
                                                <Button variant="contained" type="submit" disabled={values.isFormDirty} onClick={handleSubmit}>Submit</Button>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    <div>
                                        <Backdrop
                                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                            open={freez}
                                        // onClick={handleFreezStop}
                                        >
                                            <Freez />
                                        </Backdrop>
                                    </div>
                                </Box>
                            </div>
                        </Form>
                    )}
                </Formik>
            </>
     
    return (
        <div>
            <DefaultLayout content={ContentLayout} />
        </div>
    );
}
export default Form1099;