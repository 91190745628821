import { createSlice } from '@reduxjs/toolkit';
import { NavLink, Navigate } from 'react-router-dom';

const isAuthenticated = localStorage.getItem('isAuthenticated');
const user = localStorage.getItem('user');
const token = localStorage.getItem('token');
const SnapShotStatus = localStorage.getItem('SnapShotStatus');
const switchedAccount = localStorage.getItem('switchedAccount');
const admin = localStorage.getItem('admin');
const company = localStorage.getItem('company');
const taxFlag = localStorage.getItem('taxFlag');
const crsSignupEnabled = localStorage.getItem('crsSignupEnabled');

const initialState = {
    isAuthenticated: isAuthenticated ? isAuthenticated : false,  
    user: user ? user : null,
    company: company ? company : null,
    token: token ? token : null,
    SnapShotStatus: SnapShotStatus ? SnapShotStatus : null,
    admin: admin ? admin : null,
    switchedAccount: switchedAccount ? switchedAccount : false,
    taxFlag: taxFlag ? taxFlag : null,
    lastloginUserId: null,
    crsSignupEnabled: crsSignupEnabled ? crsSignupEnabled : null
};

const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        login(state, action) {
            state.isAuthenticated = true;
            state.user = JSON.stringify(action.payload.user);
            state.company = JSON.stringify(action.payload.company);
            state.token = action.payload.token;
            state.SnapShotStatus = action.payload.SnapShotStatus;
            state.taxFlag = action.payload.taxFlag;
            state.crsSignupEnabled = action.payload.crsSignupEnabled;
            // <Navigate to="/manageleads/hot-leads" replace />;
        },
        logout(state) {
        const lastloginUserId = JSON.parse(localStorage.getItem('user')).id
        state.isAuthenticated = false;
        state.user = null;
        state.company = null;
        state.token = null;
        state.SnapShotStatus = null;
        state.taxFlag = null;
        state.lastloginUserId = lastloginUserId;
        state.crsSignupEnabled = null;
        },
        snapshot(state,action) {
            state.SnapShotStatus = action.payload.snapshot_status;
        },
        updateTaxFlag(state) {
            state.taxFlag = 1;
        },
        updateCrsSignupEnabled(state,action) {
            state.crsSignupEnabled = action.payload.crs_signup_enabled;
        },
        affiliateLogin(state,action) {
            state.switchedAccount = true;
            state.admin = user;
            state.token = action.payload.token;
            state.user = JSON.stringify(action.payload.user);
            state.company = JSON.stringify(action.payload.company);
            state.SnapShotStatus = action.payload.SnapShotStatus;
            state.crsSignupEnabled = action.payload.crsSignupEnabled;
            state.taxFlag = action.payload.taxFlag;
        },
        affiliateLogout(state,action) {
            state.switchedAccount = false;
            state.user = JSON.stringify(action.payload.user);
            state.token = action.payload.token;
            state.company = null;
            state.admin = null;
            state.SnapShotStatus = null;
            state.crsSignupEnabled = null;
            state.taxFlag = null;
        },
    }, 
});

export const { login, logout, affiliateLogin, affiliateLogout, snapshot, updateTaxFlag, updateCrsSignupEnabled } = authSlice.actions;
export default authSlice.reducer;