import React, { useState } from "react";
import { Box, TextField, Stack, Typography, Grid, Button,Paper, Avatar,Checkbox,FormControlLabel, IconButton, InputAdornment  } from '@mui/material';
import { Card, CardContent } from '@mui/joy';
import { Link } from 'react-router-dom';
import LanguageIcon from '@mui/icons-material/Language';
import Freez from '../../../Components/Loader/Loader'
import Backdrop from '@mui/material/Backdrop';
import { Formik } from 'formik';
import * as Yup from 'yup';
import AuthUser from "../../../Components/Auth/AuthUser";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import config from '../../../AppConfig';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
function SnapShotLogin1(props) {
    const paperStyle={padding :30,height:'auto',margin:"20px auto", }
    const avatarStyle={backgroundColor:'#1bbd7e'}
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const handleClickPasswordVisibility = () => setPasswordVisibility(!passwordVisibility);
    const { http } = AuthUser();
    const [errormsg,setErrorMsg] = useState();
    const currentYear = new Date().getFullYear();
    const [checkUsaTldStatus, setCheckUsaTldStatus] = useState(false);
    const [emailButton, setEmailButton] = useState(false);

    const SignupSchema = Yup.object().shape({
        email: Yup.string()
                .email('Invalid email format.')
                .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i, "Invalid email format.")
                
                .required('Please enter your email address.'),
        password: Yup.string()
                .required('Please enter password.'),
      })
    //loader function and variable start
    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    
    const checkUsaTLD = (email) => {
        if (email.includes('@')) {
            setEmailButton(true);
            http.post("/checkUsaTLD", {
                email: email,
            })
            .then((res) => {
                if (res.data.success === true) {
                    setCheckUsaTldStatus(true);
                } else {
                    setCheckUsaTldStatus(false);
                }
            })
            setEmailButton(false);
        }
    }
    return (
        <>
            <Box sx={{ flexGrow: 1, boxShadow: 0, justifyContent: "center", alignItems: "center", borderBottom: "3px #30BE2D solid" }}>
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ padding: "0 1%", margin: "0" }} mb={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12} className="mainlogo">
                        <Stack direction="row">
                            <Link to='/'>
                                <img alt="#" src={require('../../../assets/images/mainLogo.jpg')} />
                            </Link> &nbsp;&nbsp;
                            <Stack id="userwayBtn" tabIndex="0" direction="row" className="userwayLogin" >
                                <LanguageIcon />
                                <Typography>en/esp/fr/...</Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                // onClick={handleFreezStop}
                >
                    <Freez />
                </Backdrop>
            </div>
            <Grid alignItems='center' className='ScoreLoginBanner'>
                <h1 style={{ textAlign: 'center', marginTop: 0, paddingTop: '2%', color: 'white' }}>Welcome to the CreditSnapShot Portal</h1>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                    <Paper elevation={10} style={paperStyle}>
                        <Grid align='center'>
                            <Avatar style={avatarStyle}><LockOutlinedIcon /></Avatar>
                            <h2>Login to View Your Credit Score</h2>
                        </Grid>
                        <Formik
                            initialValues={{
                                email: '',
                                password: '',
                            }}
                            validationSchema={SignupSchema}
                            onSubmit={(values) => {
                                if(emailButton === true || checkUsaTldStatus === true) {
                                    return false;
                                }
                                handleFreezStart();
                                //Call API for login
                                http.post("/creditSnapshotEnrollLogin", {
                                    email: values.email,
                                    password: values.password.trim(),
                                })
                                    .then((res) => {
                                        if (res.data.success === true) {
                                            if (res.data.step === "inCreditSuccess") {
                                                localStorage.setItem("ScoreData", JSON.stringify(res.data.data));
                                                // Get the base URL
                                                const baseUrl = window.location.origin;
                                                // Append "/score" to the base URL
                                                const updatedUrl = config.BASE_URL + `score/` + res.data.data.userId;
                                                // Navigate to the updated URL
                                                window.location.href = updatedUrl;
                                            }
                                        } else {
                                            handleFreezStop();
                                            res.data.message ? setErrorMsg(res.data.message) : setErrorMsg(res.data.data.errorByStichCredit)
                                        }
                                    })
                            }}
                        >
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                                <>
                                    <Grid item alignItems='center' spacing={2}>
                                        <TextField
                                            id="outlined-basic"
                                            name="email"
                                            label="E-mail Address"
                                            variant="outlined"
                                            fullWidth margin="dense"
                                            size="small"
                                            onChange={(e)=>{
                                                checkUsaTLD(e.target.value);   
                                                handleChange('email');
                                                setFieldValue('email', e.target.value, true)
                                            }}
                                            autoComplete="email"
                                            onBlur={handleBlur('email')}
                                            value={values.confirmpassword}
                                            // helperText={touched.email && errors.email ? errors.email : null}
                                            error={touched.email && errors.email ? errors.email : null}
                                        />
                                         <div className='error'>{touched.email && errors.email ?errors.email : checkUsaTldStatus ? "Invalid email format." : '' }</div> 
                                        <TextField
                                            id="outlined-basic"
                                            name="password"
                                            autoComplete="password"
                                            label="Password"
                                            type={passwordVisibility ? "text" : "password"}
                                            variant="outlined"
                                            fullWidth margin="dense"
                                            size="small"
                                            onChange={handleChange('password')}
                                            onBlur={handleBlur('password')}
                                            value={values.password}
                                            helperText={touched.password && errors.password ? errors.password : null}
                                            error={touched.password && errors.password ? errors.password : null}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end"> {
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickPasswordVisibility}>
                                                            {passwordVisibility ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                        </IconButton>
                                                    }
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    {errormsg ? <>
                                        <Typography sx={{ color: "red" }} ><b>
                                            Error : - {errormsg}</b>
                                        </Typography>
                                    </>
                                        : null}
                                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                                        <FormControlLabel control={<Checkbox defaultChecked />} label="Remember Me" />
                                    </Grid>
                                    <Grid item alignItems='center' spacing={2} sx={{ marginTop: 4, marginBottom: 6 }}>
                                        <Button color="primary" variant="contained" size="large" fullWidth onClick={handleSubmit} >Login</Button>
                                    </Grid>
                                </>
                            )}
                        </Formik>
                    </Paper>
                </div>
            </Grid>
            <Paper sx={{
        border: 'none',
        bottom: 0,
      }} component="footer" square variant="outlined">
        <Card sx={{ borderTop: "2px #30BE2D solid", borderRadius: "0%" }}>
          <CardContent>
          <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                <Typography level="body1" fontSize="lg" sx={{ textAlign: "center" }}>Copyright © {currentYear} www.MyFreeScoreNow.com. All rights reserved. MyFreeScoreNow.com is operated by MyFreeScoreNow Inc.</Typography>
              </Grid>
            </CardContent>
            </Card>
            </Paper>

        </>
    )
}
export default SnapShotLogin1;