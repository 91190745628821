import React, { useState, useEffect, useRef } from "react";
import EnrollFooter from '../../Auth/EnrollFooter';
import EnrollHeader from "../../../Components/EnrollHeader";
import { Modal,List, Paper, Box,ListItem,ListItemText,DialogActions, Stepper, Step,useMediaQuery, Stack,Tooltip, IconButton,Divider, StepLabel, Grid, TextField, DialogContentText, InputAdornment, FormControl, InputLabel, Select, OutlinedInput, MenuItem, FormHelperText, Dialog, DialogTitle, DialogContent ,Button } from '@mui/material';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import {  useParams, useNavigate } from "react-router-dom";
import { Typography, Card, CardContent, CardOverflow } from '@mui/joy';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import TermsAndConditions from '../../FooterPages/TermsAndConditions';
import { Formik, Form,Field } from 'formik';
import Freez from '../../../Components/Loader/Loader';
import Backdrop from '@mui/material/Backdrop';
import AuthUser from "../../../Components/Auth/AuthUser";
import dayjs from 'dayjs';
import swal from "sweetalert";
import * as Yup from 'yup';
import config from '../../../AppConfig';
import InputMask from 'react-input-mask';
import formatPhoneNumber from '../../../Components/Formats/PhoneNumberValidation';
import { parse, isBefore, subYears } from 'date-fns';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import axios from "axios";
import TextareaAutosize from '@mui/base/TextareaAutosize';
import Chat from "../../../Components/CreditSnapshot/Chat";
let chatSessionId = null;
let score = null;

let mobilenumbertext = "";
let mobile = "";
let ssn = "";
const steps = [
    'Step 1',
    'Step 2',
];
const today = dayjs();
let minDate = dayjs(today.subtract(110, 'year').toDate());
let maxDate = dayjs(today.subtract(18, 'year').toDate());
const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
    });
    return formattedDate;
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
let formikValues = [];
function EnrollStep2(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { http, getToken } = AuthUser();
    const [states, setStates] = useState([]);
    const inputRef1 = useRef();
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
   
    const [error, setError] = useState(false);
    const [otp, setOTP] = useState('');
    const [errormsg, setErrorMsg] = useState('');
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [sentotp, setSentotp] = React.useState('');
    const [resendButtonDisable, setResendButtonDisable] = React.useState(false);
    const [resendCounter, setResendCounter] = React.useState(0);
    const [buttonText, setButtonText] = useState('Resend Link');
    const [isValidFormat, setIsValidFormat] = useState(true);
    const [dateFormatErrorMsg, setDateFormatErrorMsg] = useState("");
    const [isDateValid, setDateValid] = useState(true);
    const [lessthanErrorMsg, setLessthanErrorMsg] = useState("");
    const [tc, settc] = useState(false);
    const [tcflag, settcflag] = useState(false);
    const [value, setValue] = useState('');
    const [aid, setAID] = useState(localStorage.SnapShot_aid);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [creditSnapshotObj, setCreditSnapshotObj] = useState(null);
    const [openIframe, setOpenIframe] = useState(false);
    const [chatEnable, setChatEnable] = useState(localStorage.getItem("SnapShot_chat_support") == "true" ? true : false);
    const [emailEnable, setEmailEnable] = useState(localStorage.getItem("SnapShot_email_support") == "true" ? true : false);
    const [companyObj, setCompanyObj] = React.useState(false);
    const [isChatOpen, setIsChatOpen] = useState(false);
    const chatbotRef = useRef(null);
    const [startJourneyOpen, setStartJourneyOpen] = useState(false);
    const [isMinimized, setIsMinimized] = useState(true); // Minimize state
    const [openModal, setOpenModal] = useState(false);
    const[messages, setMessages] = useState([]);
    const [assistYouFlag, setAssistYouFlag] = useState(false);
    const [emailChatopen, setEmailChatopen] = useState(false);
    const [msgChatopen, setMsgChatopen] = useState(false);
    const [memberMsg, setMemberMsg] = useState("");
    const [BlackboxObj, setBlackboxObj] = useState(null);
    const { userId } = useParams();
    const [chatEmail, setChatEmail] = useState(localStorage.getItem('chatbot_email') != undefined ?  localStorage.getItem('chatbot_email') : localStorage.getItem('SnapShot_email'));
    console.log("companyObj.company_master_id:"+companyObj.company_master_id);
  const handleClose = () => {
    setOpen(false);
  };

  const sendMessage = (message) => {
    chatbotRef.current.handleNewUserMessage(message);
};

const startCreditRepairJourney = () => {
    setStartJourneyOpen(true);
}

const openChatPopup = (e) => {
    setIsChatOpen(true);
    var sessionMessagesObj;
    if(localStorage.getItem("sessionMessages") != undefined) {
        sessionMessagesObj = localStorage.getItem("sessionMessages");
    }
    e.preventDefault();
    const element = document.getElementById("chatbotDiv");
    element.style.display = "block";
    setIsMinimized(!isMinimized);
    
    setStartJourneyOpen(false);
    chatSessionId = localStorage.getItem('chatSessionId');
    console.log("chatSessionId:" + chatSessionId);
    if(chatSessionId != null){
        const sessionMessage1 = localStorage.getItem("sessionMessages");
        if (sessionMessage1) {
            const parsedMessages = JSON.parse(sessionMessage1);
            setMessages(parsedMessages);
        }
        
        setAssistYouFlag(true);
    }else{
    const Fname = localStorage.getItem('SnapShot_firstName')?.trim() ? localStorage.getItem('SnapShot_firstName') : 'Friend';
    if(sessionMessagesObj > 1) {
        setMessages(sessionMessagesObj);
    }
    if(!assistYouFlag){
        setAssistYouFlag(true);
    }
}

}

const openEmailChatPopup = () => {
    setStartJourneyOpen(false);
    setIsMinimized(true);
    setEmailChatopen(true);
}

const closeStartJourneyOpen = () => {
    setStartJourneyOpen(false);
}

const closeEmailChatPopup = () => {
    setStartJourneyOpen(false);
    setEmailChatopen(false);
}


const Upgradeto3B = () => {
    setStartJourneyOpen(false);
    handleFreezStart();
    var BlackboxObj1 = null;
    if (window.IGLOO && typeof window.IGLOO.getBlackbox === 'function') {
        BlackboxObj1 = window.IGLOO.getBlackbox();
        setBlackboxObj(BlackboxObj1);
        console.log('Blackbox generated:', BlackboxObj1);
    } else {
        console.error('IGLOO or getBlackbox function is not available.');
    }

    localStorage.setItem('aid', score ? score.aid : 'seo');
    localStorage.setItem('pid', score ? score.pid : '00017');
    http.post("/registerEnrollment", {
        ugradeToCD: true,
        userId: userId,
        blackboxCode: BlackboxObj1 ? BlackboxObj1.blackbox : "",
        step: 1
    })
        .then((res) => {
        handleFreezStop();
        if (res.data.success === true) {
            localStorage.setItem("trackingToken", res.data.data.trackingToken);
            localStorage.setItem('customerToken', res.data.data.customerToken);
            localStorage.setItem('Step', res.data.data.nextstep);
            localStorage.setItem('idQuestions', JSON.stringify(res.data.data.idQuestions));
            localStorage.setItem("UpgradeTo3B", true);
            localStorage.setItem('Main_firstName', score ? score.userObj.first_name : '');
            localStorage.setItem('Main_lastName', score ? score.userObj.last_name : '');
            localStorage.setItem('Main_email', score ? score.email : '');
            window.location.href = config.BASE_URL + `enroll?pid=` + localStorage.getItem('pid') + `&aid=` + localStorage.getItem('aid') + `&sid=&tid=&adid=&from=crsleads`;
        } else {
            handleFreezStop();
            const errorObj = res.data.data.errorByCD;
            swal({
            title: "Failed",
            text: errorObj[0] && errorObj[0].message ? errorObj[0].message : res.data.data.errorByCD,
            icon: "error",
            button: "Ok",
            })
        }
        })
    }
    const sendEmailChatSchema = Yup.object().shape({
        subject: Yup.string()
          .min(2, 'Too Short!')
          .required('Please enter subject'),
        description : Yup.string()
          .min(2, 'Too Short!')
          .required('Please enter Description'),
      });

    const handleIFrame = () => {
        setOpenIframe(true);
    }

    const handleCloseIFrame = () => {
        setOpenIframe(false);
    }
    
    //loader function and variable start
    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    const toggleDrawer1 = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setOpen1(open);
    };
    const toggleDrawer2 = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setOpen2(open);
    };
    const toggleDrawer3 = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setOpen3(open);
    };
    const toggleDrawer4 = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setOpen4(open);
    };
    //loader function and variable end
    //handle OTP Change start
    const handleInputChange = (e) => {
        const inputValue = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
        setValue(inputValue);
        if (inputValue === e.target.value) {
            setError(false);
        } else {
            setError(true);
        }
        setOTP(e.target.value);
    };
    const handleOpenDialog = () => {
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

    const handleSubmitOtp = () => {
       
        
        SnapShotErollStep3();
        handleCloseDialog();
    };
    //handle OTP Change end
    const fetchState = () => {
        http.get("/fetchStates").then((res) => {
            setStates(res.data.data);
        });
    }
    useEffect(() => {
        fetchState();
    }, [])

    
    //get Snapshot AID function and variable ends
   

    //Step3 Function starts
    const SnapShotErollStep3 = (values) => {
        handleFreezStart();
        http.post("/verifyCRSUser", {
            aid: localStorage.getItem('SnapShot_aid'),
            userId: localStorage.getItem('UserId'),
            streetAddress: localStorage.getItem('streetAddress'),
            streetAddress1: localStorage.getItem('streetAddress1'),
            city: localStorage.getItem('city'),
            state: localStorage.getItem('state'),
            mobile: localStorage.getItem('mobile'),
            dob: localStorage.getItem('dob'),
            ssn: localStorage.getItem('ssn'),
            zip: localStorage.getItem('zip'),
            firstName: localStorage.getItem('SnapShot_firstName'),
            lastName: localStorage.getItem('SnapShot_lastName'),
            email: localStorage.getItem('SnapShot_email'),
            password: localStorage.getItem('SnapShot_password'),
            source: "default",
            dtoken: localStorage.getItem('Dtoken'),
            utoken: localStorage.getItem('utoken'),
            smfaToken: localStorage.getItem('smfaToken'),
            url: config.BASE_URL,
        })
            .then((res) => {
                handleFreezStop();
                if (res.data.success === true) {
                    localStorage.setItem("ScoreData", JSON.stringify(res.data.data));
                    // Get the base URL
                    const baseUrl = window.location.origin;
                    // Append "/score" to the base URL
                    const updatedUrl = config.BASE_URL + `score/` + res.data.data.userId;
                    // Navigate to the updated URL
                    window.location.href = updatedUrl;
                } else {
                    if (res.data.data.step === "VerifyPopup") {
                        setButtonText("Resend Link");
                        setResendButtonDisable(false);
                        setSentotp(res.data.data.code);
                        handleOpenDialog();
                        alert("Oops! Encountered some error. Please try again later. \n**" + res.data.data.errorByStichCredit);
                    } else {
                        handleFreezStop();
                        swal({
                            title: "Failed",
                            text: "Oops! Encountered some error. Please try again later. \n**" + res.data.data.errorByStichCredit,
                            icon: "error",
                            button: "Ok",
                        });
                    }
                }
            })
            .catch((error) => {
                handleFreezStop();
                swal({
                    title: "Failed",
                    text: error.message,
                    icon: "error",
                    button: "Ok",
                })
            });
    }
    //Step3 Function ends

    //Step2 Function starts
    const SnapShotErollStep2 = (values) => {

        if (values.DateofBirth1 == "" || values.DateofBirth1 == undefined || values.DateofBirth1 == null) {
            swal({
                title: "Alert",
                text: "Date of Birth is required",
                icon: "error",
                button: "Ok",
            }).then((ok) => {
                if (ok) {
                    inputRef1.current?.focus();
                }
            });

            return null;
        }
       
   
        if (isDateValid == false || isValidFormat == false) {
            return false;
        }
        mobile = values.MobileNumber.replace(/[^0-9]/g, '');
        ssn = values.SocialSecurityNumber.replace(/[^0-9]/g, '');
        handleFreezStart();
        http.post("/creditSnapshotEnrollStep2", {
            aid: localStorage.getItem('SnapShot_aid'),
            userId: localStorage.getItem('UserId'),
            streetAddress1: values.StreetAddress,
            streetAddress2: values.StreetAddress2,
            city: values.City,
            state: values.State,
            mobile: mobile,
            dob: formatDate(values.DateofBirth1),
            ssn: ssn,
            zip: values.ZipCode,
            isConfirmedTerms: tc,
            firstName: localStorage.getItem('SnapShot_firstName'),
            lastName: localStorage.getItem('SnapShot_lastName'),
            email: localStorage.getItem('SnapShot_email'),
            password: localStorage.getItem('SnapShot_password'),
            source: "default",
            dtoken: localStorage.getItem('Dtoken'),
            rtoken: localStorage.getItem('rtoken'),
            paToken: localStorage.getItem('paToken')
        })
            .then((res) => {
                mobilenumbertext = values.MobileNumber;
                console.log("step",res);
                if (res.data.success === true) {
                    console.log("step1",res);
                    handleFreezStop();
                    
                    if (res.data.data.step === "VerifyPopup") {
                        console.log("step2",res.data);
                        localStorage.setItem("smfaToken", res.data.data.smfaToken);
                        localStorage.setItem("utoken", res.data.data.utoken);
                        formikValues = values;
                        setButtonText("Resend Link");
                        setResendButtonDisable(false);
                        handleOpenDialog();
                    }

                } else {
                    handleFreezStop();
                    setErrorMsg(res.data.data.errorByStichCredit);
                }
            })
            .catch((error) => {
                handleFreezStop();
                swal({
                    title: "Failed",
                    text: error.message,
                    icon: "error",
                    button: "Ok",
                })
            });
    }
    //Step2 Function ends

    // resend Otp function
    const resendOtp = () => {
        console.log(formikValues);
        setResendButtonDisable(true);
        setButtonText("Processing");
        const counter = resendCounter;
        setResendCounter(counter);
        http.post("/creditSnapshotEnrollStep2", {
            aid: localStorage.getItem('SnapShot_aid'),
            userId: localStorage.getItem('UserId'),
            streetAddress1: formikValues.StreetAddress,
            streetAddress2: formikValues.StreetAddress2,
            city: formikValues.City,
            state: formikValues.State,
            mobile: formikValues.MobileNumber.replace(/[^0-9]/g, ''),
            dob: formatDate(formikValues.DateofBirth1),
            ssn: formikValues.SocialSecurityNumber.replace(/[^0-9]/g, ''),
            zip: formikValues.ZipCode,
            firstName: localStorage.getItem('SnapShot_firstName'),
            lastName: localStorage.getItem('SnapShot_lastName'),
            email: localStorage.getItem('SnapShot_email'),
            password: localStorage.getItem('SnapShot_password'),
            source: "default",
            counter: counter,
            dtoken: localStorage.getItem('Dtoken'),
            rtoken: localStorage.getItem('rtoken'),
            paToken: localStorage.getItem('paToken')
        })
            .then((res) => {
                if (res.data.success === true) {
                    localStorage.setItem("smfaToken", res.data.data.smfaToken);
                    localStorage.setItem("utoken", res.data.data.utoken);
                    alert("Link Resent Successfully");

                } else {
                    alert("Link Resent Failed");
                }
                setButtonText("Resend Link");
                setResendButtonDisable(false);
            })
            .catch((error) => {
                setButtonText("Resend Link");
                setResendButtonDisable(false);
                handleFreezStop();
                swal({
                    title: "Failed",
                    text: error.message,
                    icon: "error",
                    button: "Ok",
                })
            });
    }
    const validationSchema = Yup.object().shape({

        StreetAddress: Yup.string()
            .min(1, 'Too Short!')
            .matches(
                /^(?=[A-z0-9\-\. ]*$)/,
                'Please enter a valid Street Adress'
            )
            .required('Street address is required'),

        StreetAddress2: Yup.string()
            .min(1, 'Too Short!')
            .matches(
                /^(?=[A-z0-9\-\. ]*$)/,
                'Please enter a valid Street Adress'
            ),

        City: Yup.string()
            .required('City is required'),

        State: Yup.string()
            .required('Please select state'),

        ZipCode: Yup.string()
            .min(5, 'ZipCode must be of 5 digit!')
            .max(5, 'ZipCode must be of maximum 5 digit!')
            
            .required('Zipcode is required'),

        MobileNumber: Yup.string()
            .min(14, 'Phone number must be of 10 digit!')
            .max(14, 'Phone number must be of maximum 10 digit!')
            .required('Mobile number is required'),

        SocialSecurityNumber: Yup.string()
            .min(11, 'Social security number must be of 9 digit!')
            .max(11, 'Social security number must be of maximum 9 digit!')
            .required('Social security number is required'),
        DateofBirth1: Yup.string()
            .required('Please enter Date of Birth'),
            tc: Yup.boolean()
            .oneOf([true], 'Please accept the terms and conditions.'),
    });
    const handleDateChange = (event) => {
        const { value } = event.target;
        // setInputDate(value);

        //check date format
        const regexPattern = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
        const checkformat = regexPattern.test(value);
        if (checkformat) {

            const [month, day, year] = value.split('/');
            const parsedDate = new Date(`${year}-${month}-${day}`);

            // Check if the parsed date is valid
            const isValidDate = !isNaN(parsedDate) && parsedDate.toISOString().slice(0, 10) === `${year}-${month}-${day}`;
            setIsValidFormat(isValidDate);
            if (!isValidDate) {
                setDateFormatErrorMsg("The birth date must be formatted as MM/DD/YYYY");
                return;
            }

        } else {
            setIsValidFormat(false);
            setDateFormatErrorMsg("The birth date must be formatted as MM/DD/YYYY");
            return;
        }
        //check less than 18 years old
        const inputDateParsed = parse(value, 'MM/dd/yyyy', new Date());
        const eighteenYearsAgo = subYears(new Date(), 18);
        const isValid = isBefore(inputDateParsed, eighteenYearsAgo);

        setDateValid(isValid);
        if (!isValid) {
            setLessthanErrorMsg("You must be at least 18 to be a member");
        } else {
            setLessthanErrorMsg("");
        }
    };
    //fetch city and state from zipcode
    const fetchLocationInfo = async (zipcode, setFieldValue) => {
        const apiUrl = "/zipcode/"+zipcode;
        setFieldValue('City','');
        setFieldValue('State','');
        if(zipcode.length == 5)
        {
            setFieldValue('City','...');
            setFieldValue('State','...');
            handleFreezStart();
            const res = await dispatch(fetchData(apiUrl)).unwrap();
            if(res.success)
            {
                setFieldValue('City', res.data.places[0]['place name']);
                setFieldValue('State', res.data.places[0]['state abbreviation']);
                handleFreezStop();
            }
            else
            {
                setFieldValue('City','');
                setFieldValue('State','');
                handleFreezStop();
            }
        }
    };

    const getCreditSnapshotDetails = (value) => {
        setCompanyObj(value);
        setCreditSnapshotObj(value);
    }
    const changeOpenChatPopupValue = (value) => {
        setIsMinimized(value);
    }

    return (
        <>
            <Box>
                <EnrollHeader aid={aid} logoName={'creditSnapShot'} onValueChange={getCreditSnapshotDetails} stepCount="2"/>
            </Box>
            <Box mt={3} mb={3}>
                {/* <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}> */}
                    {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Stepper activeStep={1} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Grid> */}
                    {/* <Grid item lg={8} md={6} sm={12} xs={12} > */}
                        {/* <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "right" }}> */}
                            {/* <PhoneInTalkOutlinedIcon />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Typography>
                                Call a Credit Specialist Now at {formatPhoneNumber(localStorage.getItem('SnapShot_phone'))} We can help!
                            </Typography>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                           
                        {/* </Grid> */}
                    {/* </Grid> */}
                {/* </Grid> */}
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Card className="backImgMain" sx={{boxShadow:"none",display:"flex",justifyContent:"center",borderRight:isMobile?"none":"1px solid black",borderRadius:"0%",minHeight:450,maxHeight:500}}>
                            <Grid container item lg={12} md={12} sm={12} xs={12} className="centering" >
                                <Typography sx={{fontSize:"20px",fontWeight:"bold"}} className="centering">
                                    Just One More Step To Get Your <span style={{color:"blue"}}>FREE </span>Credit Summary
                                </Typography>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12} className="centering" mt={3}>
                               <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} className="centering">
                                        <img alt="#" src={require("../../../assets/images/Chats.png")} style={{width:"100px",height:"100%"}}/>
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} className="centering">
                                        <Typography sx={{textAlign:"center"}}><b>Chat With Us Now</b></Typography>
                                    </Grid>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                <Grid container item lg={12} md={12} sm={12} xs={12} className="centering">
                                        <img alt="#" src={require("../../../assets/images/Emailing.png")} style={{width:"100px",height:"100%"}}/>
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} className="centering">
                                        <Typography sx={{textAlign:"center"}}><b>Email Us Now</b></Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                creditSnapshotObj && creditSnapshotObj.step_2_link ?
                                <>
                                  <Grid container item lg={12} md={12} sm={12} xs={12} className="centering" >
                                        <Grid container item lg={12} md={12} sm={12} xs={12} className="centering">
                                            <a href="#" onClick={handleIFrame}><img alt="#" src={require("../../../assets/images/play.png")} style={{width:"100px",height:"100%"}}/></a>
                                        </Grid>
                                        <Grid container item lg={12} md={12} sm={12} xs={12} className="centering">
                                            <Typography sx={{textAlign:"center"}}><b>Learn More About Us</b></Typography>
                                        </Grid>
                                </Grid>
                                </> : ''
                            }
                          
                        </Card>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Grid container item lg={12} md={12} sm={12} xs={12} alignItems="center" 
                                        justifyContent="center" sx={{px:isMobile?0:10}}>
                                    <Stack direction="row" justifyContent="center" sx={{px:isMobile?0:10}}>
                                    <Grid item lg={3} md={3} sm={3} xs={3} sx={{ textAlign: 'center',paddingLeft:isMobile?0:"150px"  }}>
                                    <Button
                                        sx={{
                                            backgroundColor: "#D2D3D5",
                                            color: "black",
                                            minWidth: isMobile ? 60 : 100,
                                            borderRadius: "3px",
                                            fontSize: isMobile?"15px":"20px",
                                            '&:hover': {
                                            backgroundColor: "#D2D3D5",
                                            color:"black"
                                            },
                                        }}
                                        >Step 1</Button>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}
                                        sx={{
                                            display: 'flex', 
                                            alignItems: 'center', 
                                            }}>
                                        <Divider 
                                        sx={{
                                            width: '100%', 
                                            borderStyle: 'dotted', // Makes the line dotted
                                            borderColor: 'black',  // Optional: Customize the color
                                            borderWidth: '1px',    // Optional: Adjust the thickness
                                            }} />
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={3} xs={3} sx={{ textAlign: 'center',paddingLeft:0,paddingRight:isMobile?0:"150px" }}>
                                    <Button sx={{backgroundColor:"#C7E0FF",color:"black",minWidth:isMobile?60:100,borderRadius:"3px",fontSize:isMobile?"15px":"20px",
                                        '&:hover': {
                                            backgroundColor: "#C7E0FF",
                                            color:"black"
                                            },
                                    }}>Step 2</Button>
                                    </Grid>
                                    </Stack>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12} alignItems="center" p={4}>
                                        <Formik
                                            initialValues={{
                                                StreetAddress: '',
                                                StreetAddress2: '',
                                                City: '',
                                                State: '',
                                                ZipCode: '',
                                                MobileNumber: '',
                                                DateofBirth: '',
                                                DateofBirth1: '',
                                                SocialSecurityNumber: '',
                                                tc: false
                                            }}
                                            validationSchema={validationSchema}
                                            onSubmit={(values, { setSubmitting }) => {
                                                // Handle form submission logic here
                                                setSubmitting(false);
                                                SnapShotErollStep2(values);
                                            }}
                                        >

                                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                                                <Form>
                                                    <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3}>
                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                            {/* <Grid container item lg={12} md={12} sm={12} xs={12} >
                                                                <p>
                                                                    <span style={{ fontWeight: 600, fontSize: "1.1rem" }}>You’re just a few steps away! Equifax needs your date of birth and social security number to access your score and credit summary.</span>
                                                                </p>
                                                                <Typography level="body1">Your data is secure. Checking your own score will not hurt your credit.</Typography>
                                                            </Grid> */}
                                                            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3}>
                                                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                                                
                                                                    <FormControl variant="outlined" fullWidth
                                                                        margin="dense" size="small">
                                                                        <InputLabel>Street Address*</InputLabel>
                                                                        <OutlinedInput
                                                                            id="StreetAddress"
                                                                            name="StreetAddress"
                                                                            label="Street Address*"
                                                                            error={touched.StreetAddress && !!errors.StreetAddress}
                                                                            helperText={touched.StreetAddress && errors.StreetAddress}
                                                                            value={values.StreetAddress}
                                                                            autoComplete="StreetAddress"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            onKeyDown={()=>setErrorMsg('')}
                                                                            endAdornment={
                                                                                <InputAdornment position="end">
                                                                                    <IconButton
                                                                                        edge="end"
                                                                                    >
                                                                                        {touched.StreetAddress && !errors.StreetAddress ? (
                                                                                            <CheckIcon style={{ color: 'green' }} />
                                                                                        ) : (
                                                                                            touched.StreetAddress && errors.StreetAddress ? (
                                                                                                <CloseIcon style={{ color: 'red' }} />
                                                                                            ) : null
                                                                                        )}
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            }

                                                                        />
                                                                        <FormHelperText style={{ color: 'red' }}>
                                                                            {touched.StreetAddress && errors.StreetAddress}
                                                                        </FormHelperText>
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                                                    <TextField
                                                                        id="StreetAddress2"
                                                                        name="StreetAddress2"
                                                                        label="Street Address line 2 (Optional)"
                                                                        variant="outlined"
                                                                        size="small"
                                                                        fullWidth
                                                                        margin="dense"
                                                                        autoComplete="StreetAddress2"
                                                                        error={touched.StreetAddress2 && !!errors.StreetAddress2}
                                                                        helperText={touched.StreetAddress2 && errors.StreetAddress2}
                                                                        value={values.StreetAddress2}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        onKeyDown={()=>setErrorMsg('')}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3}>
                                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                                    <InputMask
                                                                        mask='(999) 999-9999'
                                                                        maskChar={null}
                                                                        name='MobileNumber'
                                                                        fullWidth
                                                                        margin="dense"
                                                                        size="small"
                                                                        id='MobileNumber'
                                                                        autoComplete="MobileNumber"
                                                                        error={touched.MobileNumber && !!errors.MobileNumber}
                                                                        value={values.MobileNumber}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        onKeyDown={()=>setErrorMsg('')}
                                                                    >
                                                                        {(inputProps) =>
                                                                            <div>
                                                                                <TextField
                                                                                    label="Phone Number*" variant="outlined"
                                                                                    {...inputProps}
                                                                                    InputProps={{
                                                                                        endAdornment: (
                                                                                            <InputAdornment position="end">
                                                                                                <IconButton edge="end">
                                                                                                    {touched.MobileNumber && !errors.MobileNumber ? (
                                                                                                        <CheckIcon style={{ color: 'green' }} />
                                                                                                    ) : (
                                                                                                        touched.MobileNumber && errors.MobileNumber ? (
                                                                                                            <CloseIcon style={{ color: 'red' }} />
                                                                                                        ) : null
                                                                                                    )}
                                                                                                </IconButton>
                                                                                            </InputAdornment>
                                                                                        ),
                                                                                    }}
                                                                                />
                                                                                <FormHelperText style={{ color: 'red' }}>
                                                                                    {touched.MobileNumber && errors.MobileNumber}
                                                                                </FormHelperText>
                                                                            </div>
                                                                        }
                                                                    </InputMask>
                                                                </Grid>
                                                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                                                
                                                                    <InputMask
                                                                        mask='99999'
                                                                        maskChar={null}
                                                                        id="ZipCode"
                                                                        name="ZipCode"
                                                                        label="Zip Code*"
                                                                        variant="outlined"
                                                                        size="small"
                                                                        fullWidth
                                                                        margin="dense"
                                                                        autoComplete="ZipCode"
                                                                        error={touched.ZipCode && !!errors.ZipCode}
                                                                        helperText={touched.ZipCode && errors.ZipCode}
                                                                        value={values.ZipCode}
                                                                        onChange={(e) => {
                                                                            handleChange(e);
                                                                            fetchLocationInfo(e.target.value, setFieldValue);
                                                                        }}
                                                                        onKeyDown={()=>setErrorMsg('')}
                                                                        onBlur={handleBlur}
                                                                    >
                                                                    {(inputProps) =>
                                                                    <TextField
                                                                    label="Zip Code*" variant="outlined"
                                                                            {...inputProps}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                    <InputAdornment position="end">
                                                                                        <IconButton edge="end">
                                                                                        {touched.ZipCode && !errors.ZipCode ? (
                                                                                            <CheckIcon style={{ color: 'green' }} />
                                                                                        ) : (
                                                                                            touched.ZipCode && errors.ZipCode ? (
                                                                                                <CloseIcon style={{ color: 'red' }} />
                                                                                            ) : null
                                                                                        )}
                                                                                        </IconButton>
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }}
                                                                    />
                                                                    }
                                                                </InputMask>
                                                                    
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3}>
                                                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                                                
                                                                    <FormControl variant="outlined" fullWidth
                                                                        margin="dense" size="small">
                                                                        <InputLabel>City*</InputLabel>
                                                                        <OutlinedInput
                                                                            id="City"
                                                                            name="City"
                                                                            label="City*"
                                                                            autoComplete="City"
                                                                            endAdornment={
                                                                                <InputAdornment position="end">
                                                                                    <IconButton
                                                                                        edge="end"
                                                                                    >
                                                                                        {touched.City && !errors.City ? (
                                                                                            <CheckIcon style={{ color: 'green' }} />
                                                                                        ) : (
                                                                                            touched.City && errors.City ? (
                                                                                                <CloseIcon style={{ color: 'red' }} />
                                                                                            ) : null
                                                                                        )}
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            }
                                                                            error={touched.City && !!errors.City}
                                                                            helperText={touched.City && errors.City}
                                                                            value={values.City}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            onKeyDown={()=>setErrorMsg('')}
                                                                        />
                                                                        <FormHelperText style={{ color: 'red' }}>
                                                                            {touched.City && errors.City}
                                                                        </FormHelperText>
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                                                    <FormControl fullWidth size='small' margin="dense"
                                                                        error={touched.State && errors.State ? errors.State : null}
                                                                    >
                                                                        <InputLabel id="demo-multiple-name-label">State*</InputLabel>
                                                                        <Select
                                                                            fullWidth
                                                                            labelId="demo-simple-select-label"
                                                                            name="State"
                                                                            autoComplete="State"
                                                                            error={touched.State && !!errors.State}
                                                                            helperText={touched.State && errors.State}
                                                                            value={values.State}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            onClick={()=>setErrorMsg('')}
                                                                            id="State"
                                                                            MenuProps={MenuProps}
                                                                            input={<OutlinedInput label="State*" />}
                                                                        >
                                                                            {states.map((state, id) => {
                                                                                return (
                                                                                    <MenuItem value={state.state_code}>{state.state_name}</MenuItem>
                                                                                )
                                                                            })}
                                                                        </Select>
                                                                        {touched.State && errors.State ? <FormHelperText style={{ color: 'red' }}>Please select State.</FormHelperText> : null}
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3}>
                                                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                                                    {/* <Grid > */}
                                                                        <InputMask
                                                                            mask='999-99-9999'
                                                                            maskChar={null}
                                                                            placeholder="555-55-5555"
                                                                            name='SocialSecurityNumber'
                                                                            id='SocialSecurityNumber'
                                                                            autoComplete="SocialSecurityNumber"
                                                                            error={touched.SocialSecurityNumber && !!errors.SocialSecurityNumber}
                                                                            helperText={touched.SocialSecurityNumber && errors.SocialSecurityNumber}
                                                                            value={values.SocialSecurityNumber}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            onKeyDown={()=>setErrorMsg('')}
                                                                            fullWidth
                                                                            margin="dense"
                                                                            size="small"
                                                                        >
                                                                            {(inputProps) =>
                                                                            <div>
                                                                                <TextField
                                                                                    label="Social Security Number - Full*" 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    margin="dense"
                                                                                    size="small"
                                                                                    InputProps={{
                                                                                        endAdornment: (
                                                                                            <InputAdornment position="end">
                                                                                                    <IconButton edge="end">
                                                                                                        {touched.SocialSecurityNumber && !errors.SocialSecurityNumber ? (
                                                                                                            <CheckIcon style={{ color: 'green' }} />
                                                                                                        ) : (
                                                                                                            touched.SocialSecurityNumber && errors.SocialSecurityNumber ? (
                                                                                                                <CloseIcon style={{ color: 'red' }} />
                                                                                                            ) : null
                                                                                                        )}
                                                                                                    </IconButton>
                                                                                                    <Tooltip
                                                                                                        placement='top'
                                                                                                        title="Your social security number is needed to confirm your identity and retrieve your credit report and score." arrow>
                                                                                                        <IconButton
                                                                                                        edge="end" 
                                                                                                        >
                                                                                                            <img alt="#" src={require("../../../assets/images/i_icon.png")} />
                                                                                                        </IconButton>
                                                                                                    </Tooltip>
                                                                                            </InputAdornment>
                                                                                        ),
                                                                                        ...inputProps,
                                                                                    }}
                                                                                />
                                                                                <FormHelperText style={{ color: 'red' }}>
                                                                                    {touched.SocialSecurityNumber && errors.SocialSecurityNumber}
                                                                                </FormHelperText>
                                                                            </div>
                                                                            }
                                                                        </InputMask>
                                                                </Grid>
                                                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                                                    <FormControl fullWidth size='small'>
                                                                        <InputMask
                                                                            mask='99/99/9999'
                                                                            maskChar={null}
                                                                            name="DateofBirth1"
                                                                            id='DateofBirth1'
                                                                            autoComplete="DateofBirth1"
                                                                            inputRef={inputRef1}
                                                                            value={values.DateofBirth1}
                                                                            maxDate={maxDate}
                                                                            minDate={minDate}
                                                                            label="Date of Birth*"
                                                                            onChange={(e) => {
                                                                                handleDateChange(e);
                                                                                handleChange(e);
                                                                            }}
                                                                            onBlur={handleBlur}
                                                                            onKeyDown={()=>setErrorMsg('')}
                                                                            variant="outlined"
                                                                            size="small"
                                                                            fullWidth
                                                                            margin="dense"
                                                                            placeholder="MM/DD/YYYY"
                                                                            error={touched.DateofBirth1 && !!errors.DateofBirth1}
                                                                        >
                                                                            {(inputProps) =>
                                                                            <div>
                                                                                <TextField
                                                                                    label="Date of Birth*" variant="outlined"
                                                                                    {...inputProps}
                                                                                    InputProps={{
                                                                                        endAdornment: (
                                                                                            <InputAdornment position="end">
                                                                                                <IconButton edge="end">
                                                                                                {touched.DateofBirth1 && !errors.DateofBirth1  && isValidFormat && isDateValid ?  (
                                                                                                        <CheckIcon style={{ color: 'green' }} />
                                                                                                    ) : (
                                                                                                        (touched.DateofBirth1 && errors.DateofBirth1  || !isValidFormat || !isDateValid) ?  (
                                                                                                            <CloseIcon style={{ color: 'red' }} />
                                                                                                        ) : null
                                                                                                    )}
                                                                                                </IconButton>
                                                                                            </InputAdornment>
                                                                                        ),
                                                                                    }}
                                                                                />
                                                                                <FormHelperText style={{ color: 'red' }}>
                                                                                {touched.DateofBirth1 && errors.DateofBirth1}
                                                                            </FormHelperText>
                                                                        </div>
                                                                            }
                                                                        </InputMask>

                                                                    </FormControl>
                                                                    <FormHelperText sx={{ color: "#d32f2f" }}>{!isValidFormat ? dateFormatErrorMsg : !isDateValid ? lessthanErrorMsg : ''}</FormHelperText>
                                                                
                                                                </Grid>
                                                            </Grid>
                                                            {errormsg ? <>
                                                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                                                <Grid item lg={11} md={11} sm={11} xs={11}>
                                                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                    <p className="error" style={{ fontSize: "1.0rem" }}><b>Oops! Encountered some error. Please try again later.</b></p>
                                                                    <p className="error"  style={{ fontSize: "0.9rem" }}><b>**{errormsg}</b></p>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            </>
                                                            : null}
                                                            
                                                                
                                                            <Grid container item lg={12} md={12} sm={12} xs={12} >
                                                                <Grid sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                                    <Tooltip title={<div><b>Why do we need</b><br /><p>We communicate directly with the credit bureaus to obtain your most accurate and up to date credit report. In order for them to locate your inforamtion, they use a combination of your SSN, DOB and personal information to make sure we are showing you the correct credit report.</p><p>We also use this information to make sure we're not showing your information to anyone that's not you.</p></div>} placement="top" arrow>
                                                                        <span><img alt="#" style={{ height: "25px" }} src={require("../../../assets/images/Blue_question_mark.jpg")} /><span style={{ fontSize: "1.2rem" }} ><b>Why do we need this information?</b></span>
                                                                        </span></Tooltip>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                            
                                                                <div className="form-group">
                                                                    <div className=" pull-right input-group checkbox">
                                                                        <label htmlFor="isConfirmedTerms">
                                                                        <Field type="checkbox"
                                                                        style={{height:"20px",width:"20px",marginTop:"2px"}}
                                                                checked={tc}
                                                                name="tc"
                                                                onClick={() => {
                                                                    settc(!tc);
                                                                    settcflag(tc);
                                                                }}/>
                                                                        
                                                                            &nbsp;By Checking This Box: I Acknowledge That I Have Read, Understand, Agree To And Accept The&nbsp;
                                                                            <a href="#" onClick={toggleDrawer1(true)} style={{ cursor: "pointer" }}>Terms And Conditions</a>
                                                                            <SwipeableDrawer
                                                                                anchor={'bottom'}
                                                                                open={open1}
                                                                                onClose={toggleDrawer1(false)}
                                                                                onOpen={toggleDrawer1(true)}
                                                                            >
                                                                                <TermsAndConditions closeVal={toggleDrawer1(false)} />
                                                                            </SwipeableDrawer>
                                                                            {/* ,&nbsp;
                                                                            <a href="#" onClick={toggleDrawer2(true)} style={{ cursor: "pointer" }}>
                                                                                Cancellation Policy
                                                                            </a>
                                                                            <SwipeableDrawer
                                                                                anchor={'bottom'}
                                                                                open={open2}
                                                                                onClose={toggleDrawer2(false)}
                                                                                onOpen={toggleDrawer2(true)}
                                                                            >
                                                                                <CancelPolicy closeVal={toggleDrawer2(false)} />
                                                                            </SwipeableDrawer>
                                                                            ,&nbsp;
                                                                            <a href="#" onClick={toggleDrawer3(true)} style={{ cursor: "pointer" }}>
                                                                                Privacy Policy
                                                                            </a>
                                                                            <SwipeableDrawer
                                                                                anchor={'bottom'}
                                                                                open={open3}
                                                                                onClose={toggleDrawer3(false)}
                                                                                onOpen={toggleDrawer3(true)}
                                                                            >
                                                                                <PrivacyPolicy closeVal={toggleDrawer3(false)} />
                                                                            </SwipeableDrawer>,and&nbsp;
                                                                            <a href="#" onClick={toggleDrawer4(true)} style={{ cursor: "pointer" }}>
                                                                                Refund Policy
                                                                            </a> */}
                                                                            {/* <SwipeableDrawer
                                                                                anchor={'bottom'}
                                                                                open={open4}
                                                                                onClose={toggleDrawer4(false)}
                                                                                onOpen={toggleDrawer4(true)}
                                                                            >
                                                                                <RefundPolicy closeVal={toggleDrawer4(false)} />
                                                                            </SwipeableDrawer> */}
                                                                            .
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                            
                                                            {errors.tc && errors.tc && (
                                                                    <FormHelperText style={{color: "#d32f2f",backgroundColor: '#ffffffad',paddingLeft:'3px'}}>{errors.tc}</FormHelperText>
                                                                )}
                                                            
                                                            <Grid item lg={12} md={12} sm={12} xs={12} mt={2} sx={{ display: "flex", justifyContent: "right" }}>
                                                                <Button variant="contained" type="submit" disabled={isSubmitting} sx={{  minWidth: 200, }}>NEXT</Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Form>

                                            )}

                                        </Formik>
                                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{mt:isMobile?4:0}}>
                                        {
                                        chatEnable  ?
                                            <Grid item lg={4} md={4} sm={12} xs={12} mt={1} sx={{ display:"flex",justifyContent:"center",marginTop:isMobile?2:0 }}>
                                            <Button varient="outlined"
                                                onClick={openChatPopup}
                                                // disabled={isChatOpen}
                                                startIcon={<img src={require("../../../assets/images/chatforbutton.png")} alt="icon" style={{ width: 30, height: 30 }} />}
                                                sx={{
                                                textTransform: "none !important",
                                                border:"1px solid #2E67BC",
                                                minWidth:'80%' ,
                                                maxWidth:'100%',
                                                color:"black",
                                                alignItems:"center",
                                                fontWeight:"bold"// Prevents uppercase transformation
                                            }}>Chat With Us Now</Button>
                                            </Grid>
                                            : ''
                                        }
                                        {
                                            emailEnable ?
                                                <Grid item lg={4} md={4} sm={12} xs={12}  mt={1} sx={{ display:"flex",justifyContent:"center",marginTop:isMobile?2:0 }}>
                                                    <Button  startIcon={<img src={require("../../../assets/images/Emailforbutton.png")} alt="icon" style={{ width: 30, height: 30 }} />}
                                                    onClick={openEmailChatPopup}
                                                    sx={{
                                                    textTransform: "none",
                                                    border:"1px solid #2E67BC",
                                                    minWidth:'80%' ,
                                                    maxWidth:'100%',
                                                    color:"black",
                                                    alignItems:"center",
                                                    fontWeight:"bold" // Prevents uppercase transformation
                                                    }}>Email Us Now</Button>
                                                </Grid> : ''
                                        }
                                        
                                    </Grid>
                                </Grid>  
                            </Grid>
                    </Grid>
            </Box>
            <Box>
            <Chat mEmail={chatEmail} firstName={localStorage.getItem('SnapShot_firstName')} isMinimized={isMinimized} changeOpenChatPopupValue={changeOpenChatPopupValue} openEmailChatPopup={openEmailChatPopup} companyId={companyObj.company_master_id} source="snapshot"/>
                <EnrollFooter />
            </Box>


            {/* OTP Dialog Start */}
            <Dialog open={open}
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleCloseDialog();
                    }
                }}
                fullWidth={fullWidth}
                maxWidth={maxWidth}>
                <DialogTitle sx={{ bgcolor: "#F6FAFF" }}>
                    <Grid container item xs sx={{ justifyContent: "space-between" }}>
                        <Grid item xs>
                            <Typography level="h4" color="primary">Authenticate Identity</Typography>
                        </Grid>
                        <Grid item xs sx={{ textAlign: "right" }}>
                            <IconButton
                                aria-label="close"
                                onClick={handleCloseDialog}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {/* For security purposes, we have sent an authentication link to your mobile number({mobilenumbertext}). Please check your phone and click on the autentication link once you authorized by that link click here, */}
                        You should have received a text on your mobile with an authentication link to verify your identity. 
                        Please click on the link to authenticate yourself.
                        Once done, please click the button below to continue to your credit score.
                    </DialogContentText>
                    
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} sx={{ justifyContent : "center" }}>
                        <Button  color="primary" sx={{ minWidth: 100 }} variant="contained" onClick={handleSubmitOtp}>
                            Continue To View My FREE Credit Score
                        </Button>
                    </Grid>

                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={1} sx={{ justifyContent: "right" }}>

                        <Typography>Haven't received the authorization link yet?</Typography>&nbsp;&nbsp;
                        <Button disabled={resendButtonDisable} onClick={resendOtp} color="success" variant="contained" sx={{ bgcolor: "#76C044", color: "white", minWidth: 100 }}>{buttonText}</Button>
                    </Grid>
                </DialogContent>
                {/* </DialogActions> */}
            </Dialog>
            {/* OTP Dialog End */}

            {/* Iframe Dialog Start */}
            <Dialog open={openIframe}
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleCloseIFrame();
                    }
                }}
                fullWidth={fullWidth}
                maxWidth={maxWidth}>
                <DialogTitle sx={{ bgcolor: "#F6FAFF" }}>
                    <Grid container item xs sx={{ justifyContent: "space-between" }}>
                        <Grid item xs>
                            <Typography level="h4" color="primary">Learn More About Us</Typography>
                        </Grid>
                        <Grid item xs sx={{ textAlign: "right" }}>
                            <IconButton
                                aria-label="close"
                                onClick={handleCloseIFrame}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} sx={{ justifyContent : "center" }}>
                        <iframe
                            width="560"
                            height="315"
                            src={creditSnapshotObj ? creditSnapshotObj.step_2_link : ''}
                            title="Video Player"
                            // frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </Grid>
                </DialogContent>
                {/* </DialogActions> */}
            </Dialog>
            {isChatOpen && (
            <Box
                sx={{
                    position: "fixed",
                    bottom: "2%",
                    right: "2%",
                    cursor: "pointer",
                }}
                // onClick={() => setIsMinimized(!isMinimized)}
                onClick={openChatPopup}
                >
                <img
                    src={require("../../../assets/images/chatforbutton.png")}
                    alt="Chat Icon"
                    style={{
                    width: "70px",
                    height: "70px",
                    borderRadius: "50%",
                    boxShadow:
                        "0px 4px 8px rgba(0, 0, 0, 0.2), 0px 8px 16px rgba(0, 0, 0, 0.19)",
                    }}
                />
                </Box>
                )}
            <Dialog
                open={open3}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
            >
                <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                >
                    <Freez />
                </Backdrop>
                </div>
                <Grid>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                </Grid>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={3}>
                    <Typography level="h2"><span style={{ color: "red" }}><i>WAIT!</i></span> READY TO SEE IT ALL? </Typography>
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={3}>
                    <Typography level="h5" sx={{ color: "#76C044" }}>You've taken the first step to staying on top of your credit - DON'T STOP HERE!</Typography>
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={3}>
                    <Typography level="h4" sx={{ color: "#2D6DB4", fontWeight: 900 }}>SCORES CAN VERY BY BUREAU. CHECK YOUR SCORES FROM <u>ALL 3 BUREAUS NOW!</u></Typography>
                    </Grid>
                </DialogContentText>
                </DialogContent>
            </Dialog>

            {/* Start Credit Repair Journey */}
            <Dialog
                open={msgChatopen}
                onClose={(_, reason) => {
                if (reason !== "backdropClick") {
                    // setDeleteOpen(false)
                }
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {/* Delete Pid */}
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={2} sx={{ textAlign: "right" }}>
                    <CloseIcon onClick={closeStartJourneyOpen} />
                    </Grid>
                </Grid>
                </DialogTitle>
                <DialogContent>
                {/* <DialogContentText id="alert-dialog-description">
                    <span style={{fontWeight : '700', color: '#000000'}}>Great, We need some information to get started, But first, Let us get your 3B report.</span>
                </DialogContentText> */}
                </DialogContent>
                <DialogActions >
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                    label="member Msg"
                    type="text"
                    size="small"
                    fullWidth
                    name='memberMsg'
                    autoComplete='memberMsg'
                    id='memberMsg'
                    onChange={(e) => { setMemberMsg(e.target.value); }}
                    value={memberMsg}
                    // onBlur={handleBlur('FirstName')}
                    />
                </Grid>
                <Grid item>
                    <Button variant="contained" sx={{ minWidth: 200 }} color="success"
                    // onClick={handleSubmit}
                    onclick={sendMessage}
                    >
                    Send1
                    </Button>
                </Grid>
                </DialogActions>
            </Dialog>

            <Dialog
                open={startJourneyOpen}
                onClose={(_, reason) => {
                if (reason !== "backdropClick") {
                    // setDeleteOpen(false)
                }
                }}
                maxWidth="md"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {/* Delete Pid */}
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={2} sx={{ textAlign: "right" }}>
                    <CloseIcon onClick={closeStartJourneyOpen} />
                    </Grid>
                </Grid>
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Grid container item lg={12} md={12} sm={12} xs={12} className="centering"><img alt="#" src={require("../../../assets/images/bureau-logos.png")}/> </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography sx={{ fontWeight: '600', color: '#000000',textAlign:"center",fontSize:"30px" }}>Great, We Need Some Information To Get Started, But first, Let's Get Your 3B Report.</Typography>
                    </Grid>
                </DialogContentText>
                </DialogContent>
                <DialogActions >
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center" }}>
                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center" }}>
                    <Button variant="contained"
                        size="large"
                        // color="warning"
                        sx={{
                        backgroundColor: "#2E67BC", borderColor: "#FF9400", minWidth: 150, minHeight: 40, fontSize: "20px", fontWeight: "bold",
                        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                        }}
                        onClick={() => { Upgradeto3B() }}
                    >
                        GET ALL 3 REPORTS AND SCORES
                    </Button>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} mt={2} sx={{ textAlign: "center" }}>
                    <a target="_blank" href={creditSnapshotObj && creditSnapshotObj.credit_snapshot_link ? creditSnapshotObj.credit_snapshot_link : "#"}> I’ll Do That Later, Continue To Auto Signup Now</a>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display:"flex", justifyContent:"center",alignItems: "center",marginTop:"2%" }} >
                    <Stack direction="row">
                        {
                            creditSnapshotObj && creditSnapshotObj.enable_chat_support ?
                            <>
                            <Card varient="outlined" className="centering" sx={{boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",flex: "1", minWidth:150, maxWidth: "300px",}}>
                            <Grid container item lg={12} md={12} sm={12} xs={12} className="centering">
                                <a href="#" onClick={openChatPopup}><img alt="#" src={require("../../../assets/images/Chat-bot-Icon.png")} style={{ marginLeft: "auto", marginRight: "auto", height: "auto" ,width:"56%"}} /></a>
                            </Grid>
                            
                            <Grid container item lg={12} md={12} sm={12} xs={12} className="centering">
                                <Typography level="h5" sx={{textAlign:"center"}}>Chat With Us</Typography>
                            </Grid>
                            </Card>
                            </> : ''
                        }
                        
                        &nbsp; &nbsp;
                        <Card varient="outlined" className="centering" sx={{boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",flex: "1",minWidth:150,maxWidth: "300px",}}>
                        <Grid container item lg={12} md={12} sm={12} xs={12} className="centering">
                        <a href="#" onClick={openEmailChatPopup}><img alt="#" src={require("../../../assets/images/email-multiple-outline.png")} style={{ marginLeft: "auto", marginRight: "auto", height: "auto",width:"100%" }} /></a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} className="centering">
                        <Typography level="h5" sx={{textAlign:"center"}}>Email Us</Typography>
                        </Grid>
                        </Card>
                        </Stack>
                    </Grid>
                </Grid>
                </DialogActions>
            </Dialog>


            {/* Email Chat DialogBox */}
            <Formik
                initialValues={{
                subject: '',
                description : ''
                }}
                validationSchema={sendEmailChatSchema}
                onSubmit={async (values, { resetForm }) => {
                handleFreezStart();
                http.post("/sendChatEmail", {
                    // userId : score.userObj.user_id,
                    email : chatEmail,
                    subject : values.subject,
                    description : values.description,
                    source : "snapshot",
                    companyId : companyObj.company_master_id
                })
                .then((res) => {
                    if (res.data.success === true) {
                    swal({
                        title: "Success",
                        text: res.data.message,
                        icon: "success",
                        button: "Ok",
                    });
                    handleFreezStop();
                    }else{
                    // alert("something went wrong");
                    handleFreezStop();
                    }
                })
                closeEmailChatPopup();
                resetForm();
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                <Dialog
                    open={emailChatopen}
                    onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        // setDeleteOpen(false)
                    }
                    }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {/* Delete Pid */}
                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{justifyContent:"space-between"}}>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                        <Typography level="h5" sx={{textAlign:"center"}}>Send Us The Email</Typography>
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={2} sx={{textAlign:"right"}}>
                        <CloseIcon  onClick={closeEmailChatPopup}/>
                    </Grid>
                    </Grid>
                </DialogTitle>
                <DialogActions >
                <Grid container item lg={12} md={12} sm={12} xs={12} >   
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TextField
                        id="outlined-start-adornment"
                        type="text"
                        label="Subject"
                        fullWidth
                        variant="outlined"
                        size="small"
                        margin='dense'
                        autoComplete='subject'
                        value={values.subject}
                        onChange={handleChange('subject')}
                        onBlur={handleBlur('subject')}
                        InputLabelProps={{
                            shrink: Boolean(values.subject)
                        }}
                        /> 
                        {touched.subject && errors.subject ? <div className='error'>{errors.subject}</div> : null}
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                        <TextareaAutosize
                            placeholder="Message"
                            type="text"
                            size="small"
                            fullWidth
                            name='description'
                            autoComplete='description'
                            id='description'
                            onChange={handleChange('description')}
                            onBlur={handleBlur('description')}
                            value={values.description}
                            maxRows={7}
                            minRows={4}
                            
                            style={{
                            width: '100%', // Ensures full width
                            // padding: '8px', // Optional for better spacing
                            boxSizing: 'border-box', // Ensures padding doesn't affect width
                        }}
                        />
                        {touched.description && errors.description ? <div className='error'>{errors.description}</div> : null}
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} mt={2} sx={{ justifyContent: "center", display: "flex"}}>
                    <Button variant="contained" sx={{ minWidth: 200 }} color="success" 
                        onClick={handleSubmit}
                        >
                        Send
                    </Button>
                    </Grid>
                </Grid>
                </DialogActions>
                </Dialog>
            )}
            </Formik>

            {/* loader code start */}
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}
        </>
    )
}
export default EnrollStep2;