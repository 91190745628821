import React, { useMemo, useState, useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Alert, Grid, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip, TextField,Box,Typography} from '@mui/material';
import MaterialReactTable from 'material-react-table';
import Moment from 'moment';
import Loader from '../Loader/Loader';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Formik } from 'formik';
import * as Yup from 'yup';
import AuthUser from '../Auth/AuthUser';
import AiFillPhone from '@mui/icons-material/Phone';
import config from '../../AppConfig';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../redux/apiSlice';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import formatPhoneNumber from '../../../src/Components/Formats/PhoneNumberValidation';
import {openOrFocusWindow } from  '../CommonFormula';

let RequestFormInitialValues = {
  id: '',
  aid: '',
  email: '',
};
function ManageMembersList(props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [data, setData] = useState(props.data);
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const [rowSelection, setRowSelection] = useState({});
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');

 const tablePreferencesArray = { 

    }
    useEffect(() => {
    }, [rowSelection]);
     ////////////////////////
     useEffect(() => {
      if(Object.values(columnVisibility).length>0){
        setColumnVisibility(columnVisibility);
      }
      //do something when the row selection changes...
      // console.info({ rowSelection });
    }, [rowSelection]);
  
    useEffect(() => {
      tablePreferences();
    }, [rowSelection]);

    useEffect(()=>{  
      saveTablePreferences();
  },[columnVisibility])

    ////////////////////////
  
    const tablePreferences = async() => {   

      const apiUrl = '/fetchTablePreferences/adminmanagememberlist';
      const res = await dispatch(fetchData(apiUrl)).unwrap();
      if (res) {
        if (Object.values(res).length == 0) {
          setColumnVisibility(tablePreferencesArray);
        } else {
          setColumnVisibility((JSON.parse(res.preferences)));
        }
      }
    }

    const saveTablePreferences = async() => {
      if (Object.values(columnVisibility).length > 0) {
          const apiUrl = '/saveTablePreferences';
          const args = {
              columnArray: columnVisibility,
              userId: user.id,
              tableId: 'adminmanagememberlist'
          }
      const res = await dispatch(postData({apiUrl,args})).unwrap();
      } 
    }

    const handleClick = (phone_no) => {
      window.open(`${config.CallHippoDialer}${phone_no}`, 'CallHippo Dialer');
  };
  
  const columns = useMemo(
    //column definitions...
    () => [
      {
        accessorFn: (data) => `${data.event_by_user ? data.event_by_user.name.trim() : ''}`,
        id: 'User Name',
        header: 'User Name',
        size: 50,
        minSize: 200, 
         maxSize: 400,
        enablePinning: true,
        
        Cell: ({ renderedCellValue, row }) => (
          <>
            {row.original.event_by_user ? row.original.event_by_user.name.trim() : 'N/A'}
          </>
        ),
      },
      {
        accessorFn: (data) => `${data.email ? data.email.trim() : ''}`,
        id: 'Email',
        header: 'Email',
        size: 50,
       
        minSize: 150, 
        maxSize: 400, 
        enablePinning: true,
        Cell: ({ renderedCellValue, row }) => (
          <>
            {row.original.email ? row.original.email.trim() : 'N/A'}
          </>
        ),
      },
      {
        accessorFn: (data) => `${(data.aids) ? (data.aids.aid) ? data.aids.aid.trim() : '' : ''}`,
        id: 'AID',
        header: 'AID',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <>
            {(row.original.aids) ? (row.original.aids.aid) ? row.original.aids.aid.trim() : 'N/A' : 'N/A'}
          </>
        ),
      },
      
      {
        accessorFn: (data) => `${data.event_by_user?data.event_by_user.phone_no?data.event_by_user.phone_no.trim(): 'N/A': 'N/A'}`,
        id: 'Affiliate Phone No',
        header: 'Affiliate Phone No',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <div>
           {row.original.event_by_user ? formatPhoneNumber(row.original.event_by_user.phone_no.trim()) : 'N/A'}
            &nbsp;&nbsp;
            {row.original.event_by_user?.phone_no && (
              <Tooltip title="Dial" arrow>
                  <a onClick={(event) => {event.preventDefault(); openOrFocusWindow(config.CallHippoDialer+row.original.event_by_user.phone_no); }} >
                    <AiFillPhone color="primary" />
                  </a>
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        accessorFn: (data) => `${data.event_by_user ? data.event_by_user.role.role_name.trim() : ''}`,
        id: 'User Type',
        header: 'User Type',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <>
            {row.original.event_by_user ? row.original.event_by_user.role.role_name.trim() : 'N/A'}
          </>
        ),
      },
      
      {
        accessorFn: (data) => `${data.assigned_user ? data.assigned_user.name.trim() : ''}`,
        id: 'Assigned To',
        header: 'Assigned To',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <>
            {row.original.assigned_user ? row.original.assigned_user.name.trim() : 'N/A'}
          </>
        ),
      },
      {
        accessorFn: (data) => `${data.status ? data.status.trim() : 'N/A'}`,
        id: 'Satus',
        header: 'Status',
        size: 50,
      },

      {
        accessorFn: (data) => `${data.notes ? data.notes.trim() : ''}`,
        id: 'Comment',
        header: 'Comment',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <Box className="datatable">
          <Typography>{row.original.notes ? row.original.notes.trim() : 'N/A'}
          </Typography>
        </Box>
        ),
      },
      {
        accessorFn: (data) => `${data.created_at ? data.created_at : ''}`,
        id: 'Date On',
        header: 'Date On',
        size: 50,
        sortDescFirst: true,
        Cell:({renderedCellValue,row}) => (
          <div>
            {row.original.created_at ? Moment(row.original.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') :"N/A"}
           </div>          
         ), 
      },
      {
        accessorFn: (data) => <>
          <Link className='btn btn-sm btn-info text-white' to={{ pathname: "/missingMemberDetails/" + data.id }} ><Tooltip title="Details" arrow><img src={require('../../assets/images/details.png')} /></Tooltip></Link>
          {/* <Button href={"/missingMemberDetails/"+data.id}  color="success" variant="contained" >DETAILS</Button> */}

          {data.status === 'Assigned' ? <Button className='btn btn-success text-white' onClick={() => { handleClickOpen(data.id, data.email) }} ><Tooltip title="ReAssign" arrow><img src={require('../../assets/images/reactivate.png')} /></Tooltip>
          </Button> : ''}


        </>,
        id: 'actions',
        header: 'Actions',
      },

    ]
  );
  const [open, setOpen] = useState(false);
  const handleClickOpen = (id, email) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
        navigate('/login');
        return false;
    }
    RequestFormInitialValues.id = id;
    RequestFormInitialValues.email = email;
    RequestFormInitialValues.aid = '';
    setOpen(true);
  };

  const handleClose = () => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
        navigate('/login');
        return false;
    }
    setOpen(false);
  };

  const { http, user } = AuthUser();
  const [disable, setDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  //Start Request Add Missing Member
  //Schema for formik form
  const RequestFormSchema = Yup.object().shape({
    aid: Yup.string().required('Please enter aid'),
    email: Yup.string().email('Invalid email format.')
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid email format.")
      .required('Please enter your email address.')
  })
  //Submit Request Add Missing Member Form
  const submitRequest = async(values, { resetForm }) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
        navigate('/login');
        return false;
    }
    setErrorMessage("")
    setIsLoading(true)
    setDisable(true);
    RequestFormInitialValues.aid = values.aid;
    RequestFormInitialValues.email = values.email;

    const apiUrl = '/reassingMissingMember';
    const args = {
      id: values.id,
      aid: values.aid,
      emailId: values.email,
    }
    const res = await dispatch(postData({apiUrl,args})).unwrap();
    if(res) {
      if (res.success === false) {
        setErrorMessage(res.message)
        setIsLoading(false)
        setTimeout(function () {
          setErrorMessage('')
          setDisable(false)
          handleClose();
        }, 4000);
        setDisable(false);
      } else {
        setSuccessMessage(res.message)
        setIsLoading(false)
        setTimeout(function () {
          setSuccessMessage('')
          setDisable(false)
          handleClose();
        }, 4000);
        setDisable(false);
        RequestFormInitialValues = {
          id: '',
          aid: '',
          email: '',
        };
      }
    }else{
      setErrorMessage("Something went wrong.")
      setIsLoading(false)
      setTimeout(function () {
        setErrorMessage('')
        setDisable(false)
      }, 3000);
      setDisable(false);
    }
    resetForm();
  }

    //csv option start here
    const csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      fileName: 'Manage Members',
      headers: ['AID','User Name', 'Affiliate Phone No','User Type','Email','Assigned To', 'Status','Comment','Date On',],
    };
  
    const csvExporter = new ExportToCsv(csvOptions);
    const handleExportData = (rows) => {
      const loginUser = JSON.parse(localStorage.getItem('user'));
      if(loginUser.roles_id >= 3 ) {
          navigate('/login');
          return false;
      }
      const exportedData = rows.map((row) => {
        
        return {
          aid: `${(row.original.aids) ? (row.original.aids.aid) ? row.original.aids.aid : 'N/A' : 'N/A'}`,
         user_name:`${row.original.event_by_user ? row.original.event_by_user.name : 'N/A'}`,
         affiliate_phone:`${row.original.event_by_user ?formatPhoneNumber(row.original.event_by_user.phone_no) : 'N/A'}`,
         user_type:`${row.original.event_by_user ? row.original.event_by_user.role.role_name : 'N/A'}`,
         email: `${row.original.email ? row.original.email : 'N/A'}`,
         assigned_to:`${row.original.assigned_user ? row.original.assigned_user.name : ''}`,
         status:`${row.original.status ? row.original.status : 'N/A'}`,
         comment:`${row.original.notes ? row.original.notes : 'N/A'}`,
         date_on:`${row.original.created_at ? Moment(row.original.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') : 'N/A'}`,
        };
      });
  
      const csvContent = convertToCSV(exportedData);
      // Create a Blob object from the CSV data
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
  
      // Create a download link element
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = csvOptions.fileName + '.csv';
  
      // Append the link to the document body
      document.body.appendChild(link);
  
      // Programmatically click the link to trigger the file download
      link.click();
  
      // Clean up the temporary URL and remove the link from the document
      URL.revokeObjectURL(link.href);
      link.remove();
    };
  
    const convertToCSV = (data) => {
      const headers = csvOptions.headers.join(',') + '\n';
  
      const rows = data.map((row) => {
        return Object.values(row).map((value) => {
          // Handle any necessary formatting or escaping of values
          // For simplicity, we assume all values are already properly formatted
          return value;
        }).join(',');
      }).join('\n');
  
      return headers + rows;
    };
    //csv end here
  return (
    <>
      {isLoading ? <><Loader /></> : ''}
      <MaterialReactTable
        columns={columns}
        data={data}
        enableColumnFilterModes
        enablePinning
        enableRowSelection={false}
        filterFns={{
          customSearchFilterFn: (row, id, filterValue) =>
          row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
         }} 
        globalFilterFn="customSearchFilterFn" //exact serach filter functionality
        initialState={{ showColumnFilters: false, density: 'compact',
          columnPinning: { left: ['mrt-row-expand','User Name','Email'] },
        sorting: [
          { id: 'Date On', desc: true }, //then sort by city in descending order by default
        ], }}
        positionToolbarAlertBanner="bottom"
        enableDensityToggle={false}
        enableColumnFilters={config.DatatableColumnFilter}
        muiTableHeadCellProps={{
          sx: {
            backgroundColor: "#F6FAFF",
          },
          
        }}
        renderTopToolbarCustomActions={({ table }) => (
          <Box
            sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
          >
            <Button
              color="primary"
              disabled={table.getPrePaginationRowModel().rows.length === 0}
								//export all rows, including from the next page, (still respects filtering and sorting)
                  onClick={() =>
                    handleExportData(table.getPrePaginationRowModel().rows)
                  }
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              // onClick={handleExportData}
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              CSV
            </Button>
            
            
          </Box>
        )}

        onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
        state={{ columnVisibility, rowSelection }} 
        onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
        
      />
      {/* new activity popup start */}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" sx={{ backgroundColor: "#F6FAFF" }}>
          Member Details
        </DialogTitle>
        <Formik
          initialValues={RequestFormInitialValues}
          validationSchema={RequestFormSchema}
          onSubmit={submitRequest}
        >{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (<>

          <DialogContent>
            <DialogContentText>
              {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
              {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
              <Grid container item xs={12} spacing={2} mt={1}>
                <Grid item xs>
                  <TextField
                    fullWidth
                    size="small"
                    id="outlined-basic"
                    label="AID"
                    variant="outlined"
                    name='aid'
                    value={values.aid}
                    onChange={handleChange}
                    helperText={touched.aid && errors.aid ? errors.aid : null}
                    error={touched.aid && errors.aid ? errors.aid : null}
                  />
                </Grid>

              </Grid>
              <Grid container item xs={12} spacing={2} mt={1}>
                <Grid item xs>
                  <TextField
                    fullWidth
                    size="small"
                    id="outlined-basic"
                    label="Email Address*"
                    variant="outlined"
                    name='email'
                    disabled={true}
                    value={values.email}
                    onChange={handleChange}
                    helperText={touched.email && errors.email ? errors.email : null}
                    error={touched.email && errors.email ? errors.email : null}
                  />
                </Grid>

              </Grid>
            </DialogContentText>
            <DialogActions sx={{marginTop:isMobile?"3%":"0%"}}>
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }} spacing={3}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Button variant="contained" sx={{ minWidth: 250 }} onClick={handleClose}>
                  CLOSE
                </Button>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Button variant="contained" sx={{ minWidth: 250 }} onClick={disable === false ? handleSubmit : ''} color="success" type="submit">
                  SAVE
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
          </DialogContent>
          

        </>)}</Formik>
      </Dialog>
      {/* new activity popup end */}

    </>
  );
}


export default ManageMembersList;