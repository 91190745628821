import React, { useState, useEffect } from "react";
import { Box, FormControl, Radio, Grid, RadioGroup, FormLabel, FormControlLabel, TextField } from '@mui/material';
import { Typography, Card, CardContent, Button } from '@mui/joy';
import AuthUser from "../../Components/Auth/AuthUser";
import swal from "sweetalert";
import Freez from '../../Components/Loader/Loader'
import Backdrop from '@mui/material/Backdrop';
import SecureWebsite from "../../Components/Enrollment/SecureWebsite";
import { FCMServerKey, FCMSendUrl,getOAuthToken } from "../../firbase/firstore";
import config from "../../AppConfig";

let answersArray = {};
let setTestIdQuestions={"referenceNumber":"12345678","question1":{"name":"CITIES_ASSOC_WITH","displayName":"Which of these cities are you associated with?","type":"IDMA","questionId":48991498,"choiceList":{"choice":[{"key":"3079312488","display":"King’s Landing"},{"key":"3079312490","display":"Hogsmeade"},{"key":"3079312492","display":"Whoville"},{"key":"3079312494","display":"Bedrock"},{"key":"3079312496","display":"None of the above"}]}},"question2":{"name":"NOT_CREDIT_BUREAU","displayName":"Which company is NOT a credit bureau?","type":"IDMA","questionId":48991500,"choiceList":{"choice":[{"key":"3079312498","display":"Experian"},{"key":"3079312500","display":"Monsters Inc"},{"key":"3079312502","display":"Equifax"},{"key":"3079312504","display":"TransUnion"},{"key":"3079312506","display":"None of the above"}]}},"question3":{"name":"INVENTED_INTERNET","displayName":"Who invented the internet?","type":"IDMA","questionId":48991502,"choiceList":{"choice":[{"key":"3079312508","display":"J. C. R. Licklider"},{"key":"3079312510","display":"George Clooney"},{"key":"3079312512","display":"Al Gore"},{"key":"3079312514","display":"Howard Stark"},{"key":"3079312516","display":"None of the above"}]}},"response":"QUESTIONS"}

function NewEnrollment3({ onValueChange, props, customColorArray,textColor,btnTextColor ,internFlag }) {
  
    const { http } = AuthUser();
    const [questionarray, setQuestionArray] = useState(internFlag==true?setTestIdQuestions:JSON.parse(localStorage.getItem('idQuestions')));
    let referencenumber = questionarray.referenceNumber;
    let IDMChoice = questionarray.question1.name === "IDM_Choice" || questionarray.question1.name === "IDM_PINVERIFY"  ? true : false;

    const [answersValue, setAnswersValue] = useState({});
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [errorfromcd, seterrorfromcd] = useState();
    const [userInput, setUserInput] = useState('');
    const [userInputError, setUserInputError] = useState(false);
    const [BlackboxObj, setBlackboxObj] = useState([]);

    // var BlackboxObj;
    // document.addEventListener('DOMContentLoaded', function () {
    //     BlackboxObj = window.IGLOO ? window.IGLOO.getBlackbox() : '';
    // });
    // let BlackboxObj = window.IGLOO ? window.IGLOO.getBlackbox():'';

    //loader function and variable start
    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    //loader function and variable end

    //enrollment step 2 function start

    const MainErollStep3 = (options) => {
        let errorFlag = 0;
        var errMsg = "Please answer all questions to proceed further";
        if(options === 'RESET'){
            setUserInput(options);
        }
        if(userInput === '' && options === ''){
            if(Object.values(answersArray).length > 0 ){
                Object.values(answersArray).map((row)=>{
                    if(row){
                    }else{
                        errorFlag = 1;
                    }
                });
            }else{
                errorFlag = 1;
            }
        }else{
            if(userInputError){
                errorFlag = 1;
                errMsg = "Please enter correct OTP first."
            }
        }
        if(options === 'RESET'){
            errorFlag = 0;
        }
        if(errorFlag === 1){
            swal({
                title: "Failed",
                text: errMsg,
                icon: "error",
                button: "Ok",
            });
        }else{
            setButtonDisabled(true);
            handleFreezStart();
            var BlackboxObj1 = null;
            if (window.IGLOO && typeof window.IGLOO.getBlackbox === 'function') {
                BlackboxObj1 = window.IGLOO.getBlackbox();
                setBlackboxObj(BlackboxObj1);
                console.log('Blackbox generated:', BlackboxObj1);
            } else {
                console.error('IGLOO or getBlackbox function is not available.');
            }
            http.post("/registerEnrollment", {
    
                step: 3,
                trackingToken: localStorage.getItem('trackingToken'),
                customerToken: localStorage.getItem('customerToken'),
                referenceNumber: referencenumber,
                answers: answersArray,
                userInput: options?options:userInput,
                blackboxCode : BlackboxObj1 ? BlackboxObj1.blackbox : "", 
                ugradeToCD : localStorage.getItem('UpgradeTo3B'), 
            })
            .then(async (res) => {
                handleFreezStop();
                if(res.data.data.failedAttempt){
                    const memberData = res.data.data.memberData;
                    const fcmTokens = res.data.data.fcmTokens;
                    /** Send desktop notification */                        
                    var key = FCMServerKey;
                    var OAuthToken =await getOAuthToken();
                    var dataPayload = {
                        title: "Abandoned Registrant Process",
                        body: `Abandoned At: ID Verification \nAID: ${localStorage.getItem("aid")}\nName: ${memberData.first_name || "N/A"} ${memberData.last_name || "N/A"}\nEmail: ${memberData.email || "N/A"}\nPhone Number: ${memberData.phone_number || "N/A"}`,
                        image: "firebase-logo.png",
                        click_action: `${config.BASE_URL}AbandonedMembers`, // Handle redirection in service worker
                    };
                    fcmTokens.forEach((token) => {
                        fetch(FCMSendUrl, {
                            method: "POST",
                            headers: {
                                Authorization: "Bearer " + OAuthToken,
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                message: {
                                    token: token, // Send to one token per request
                                    data: dataPayload,
                                },
                            }),
                        })
                            .then((response) => response.json())
                            .then((data) => console.log("FCM Response:", data))
                            .catch((error) => console.error("FCM Error:", error));
                    });
                }
                if (res.data.success === true) {
                    if(res.data.data.SecurityQuestions){
                        localStorage.setItem('SecurityQuestions', JSON.stringify(res.data.data.SecurityQuestions));
                        localStorage.setItem('creditSnapshotLink', res.data.data.creditSnapshotLink);
                        localStorage.setItem('tempPassword', res.data.data.tempPassword);
                    }
                    onValueChange(res.data.data.nextstep);
                } else {
                    if(res.data.data.currentstep === 3 && res.data.data.nextstep === 3){
                        if(res.data.data.idQuestions){
                            setQuestionArray(res.data.data.idQuestions);
                            answersArray = {};
                            setAnswersValue({});
                            setUserInput('');
                        }
                    }
                    const errorObj = res.data.data.errorByCD;                    
                    if(errorObj[0] && errorObj[0].message){
                        seterrorfromcd(errorObj[0].message)
                    }else{
                        seterrorfromcd(res.data.data.errorByCD)
                    }
                    let firstStep=props;
                    if(errorObj[0] && errorObj[0].message){
                        errorObj.forEach(function (errorByCD) {      
                            if(errorByCD.code === "EXCEEDED_MAX_TRIES"){
                                firstStep = 1;
                                swal({
                                    title: "Failed",
                                    text: errorByCD.message + " Call Now : 1-888-548-2008" ,
                                    icon: "error",
                                    button: "Ok",
                                });
                            }
                        });
                    }
                    onValueChange(firstStep?firstStep:props);
                    // onValueChange(props);
                }
                setButtonDisabled(false);
            })
            .catch((error) => {
                handleFreezStop();
                setButtonDisabled(false);
                swal({
                    title: "Failed",
                    text: error.message,
                    icon: "error",
                    button: "Ok",
                })
            });
        }
    }
    //enrollment step 2 function end

    useEffect(() => {
        setAnswersValue(answersArray);
    },[answersValue])

    const setAnswers = (answer,index) => {
        answersArray['answer'+index] = answer;
        setAnswersValue(answersArray);

    }
    //use effect to set question and answers ends

    useEffect(() => {
        setAnswersValue(answersArray);
        // if (document.readyState !== 'loading') {
        //     if (window.IGLOO && typeof window.IGLOO.getBlackbox === 'function') {
        //         var BlackboxObj1 = window.IGLOO.getBlackbox();
        //         setBlackboxObj(BlackboxObj1);
        //     }
        // } else {
        //     document.addEventListener('DOMContentLoaded', function () {
        //         if (window.IGLOO && typeof window.IGLOO.getBlackbox === 'function') {
        //             var BlackboxObj1 = window.IGLOO.getBlackbox();
        //             setBlackboxObj(BlackboxObj1);
        //         }
        //     });
        // }
    },[])

    const handleUserInput = (value) => {
        setUserInput(value);
        seterrorfromcd('');
        if(value.length < 4 || value.length > 8){
            setUserInputError(true);
        }else{
            setUserInputError(false);
        } 
    }

    return (
        <>
            <Box p={4} justifyContent='center' className="enrollment-p-0">
                <Card variant="outlined" >
                    <CardContent className="enrollment-form-padding">
                        <Grid container item lg={12} md={12} sm={12} xs={12} p={2}>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <Typography level="h6"><b>{IDMChoice ? '' : 'ID Verification Questions' }</b></Typography>
                            </Grid>
                            {
                                IDMChoice ?
                                null
                                :
                                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                                    <Typography sx={{textAlign:"justify"}} >In order to keep your credit information safe, please answer the following identity verification questions. When you answer all the questions correctly, you'll be eligible to receive your credit score and report.</Typography>
                                </Grid>                                
                            }
                            <Grid container item lg={12} md={12} sm={12} xs={12} mt={4} spacing={2} direction="row" justifyContent="center" alignItems="flex-start">
                                {
                                    Object.values(questionarray).length > 0 ?
                                    Object.values(questionarray).map((row, index)=>{
                                        if(row.name){                                            
                                            if(row.name === "IDM_PINVERIFY"){
                                                return (
                                                    <Grid container mb={2} lg={12} md={12} sm={12} xs={12} item spacing={2} direction="row" justifyContent="center" alignItems="center" > 
                                                        <Grid item alignItems="center">
                                                            <FormLabel id="demo-radio-buttons-group-label" alignItems="center" sx={{ fontWeight: 600, color: "black" }}>
                                                                {row.displayName}
                                                            </FormLabel>                               
                                                        </Grid>    
                                                        <Grid item alignItems="center">                                                                                                  
                                                            <TextField
                                                                name="userInput"
                                                                id="userInput"
                                                                value={userInput}
                                                                onChange={(e)=>handleUserInput(e.target.value)}
                                                                placeholder="OTP"
                                                                size="small"
                                                                autoComplete="userInput"
                                                                type="number"
                                                                error={userInputError?userInputError:errorfromcd}
                                                                helperText={userInputError?'Please Enter Valid OTP':errorfromcd}
                                                            />  
                                                        </Grid>                                                      
                                                    </Grid>
                                                );
                                            }else{
                                                answersArray['answer'+index] = '';                                            
                                                return (
                                                    <Grid item lg={4} md={4} sm={12} xs={12} >
                                                        <FormControl sx={{textAlign:'justify'}} >
                                                            <FormLabel id="demo-radio-buttons-group-label" mt={2} sx={{ fontWeight: 600, color: "black" }}>
                                                                {index} {row.displayName}
                                                            </FormLabel>
                                                            <RadioGroup
                                                                aria-labelledby="demo-radio-buttons-group-label"
                                                                name="radio-buttons-group"     
                                                                id={"answer_"+index}
                                                                value={answersValue['answer'+index]}
                                                                onChange={(e) => { setAnswers(e.target.value,index) }}
                                                            >
                                                            {
                                                                (row.choiceList.choice).map((item) => {                                                                
                                                                    return (<FormControlLabel value={item.key} 
                                                                        control={<Radio />} 
                                                                        label={item.display} />)
                                                                })
                                                            }                                                            
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Grid>
                                                );
                                            }  
                                        }                                      
                                    })                                    
                                    :
                                    null
                                }
                                <Grid container item spacing={2} lg={12} md={12} sm={12} xs={12}>
                                    {
                                        questionarray.question1.name === "IDM_PINVERIFY" ?
                                        null
                                        :
                                        errorfromcd ? <Typography color="danger" style={{backgroundColor: '#ffffffad',paddingLeft:'3px'}}><b>{errorfromcd}</b></Typography>
                                        : null
                                    }
                                </Grid>  
                                {
                                    questionarray.question1.name === "IDM_PINVERIFY" ?
                                        <Grid container spacing={2} lg={12} md={12} sm={12} xs={12} alignItems="center" direction="column" justifyContent="center">
                                            <Grid item>
                                                <Button color="warning" sx={{ minWidth: 150, backgroundColor: '#3470B1', color: 'white', borderRadius: "0%" }} onClick={()=>{MainErollStep3('')}} disabled={isButtonDisabled}>NEXT STEP</Button>
                                            </Grid>
                                            <Grid item>
                                                <Typography color="primary" level="title-lg" >Haven't recieved OTP yet?</Typography>
                                                <Button fullWidth color="primary" sx={{ minWidth: 150,backgroundColor: '#3470B1', color: 'white', borderRadius: "0%" }} onClick={()=>{MainErollStep3('RESET')}}>CHOOSE ANOTHER VERIFICATION METHOD</Button>
                                            </Grid>                                            
                                        </Grid>
                                    :
                                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "right" }}>
                                        <Button  sx={{ minWidth: 150, backgroundColor: '#3470B1', color: 'white', borderRadius: "0%" }} onClick={()=>{MainErollStep3('')}} disabled={isButtonDisabled}>NEXT STEP</Button>
                                    </Grid>
                                }   
                                <SecureWebsite hyperlinkColor={'#3470B1'}/>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
            {/* loader code start */}
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}
        </>
    )
}
export default NewEnrollment3;