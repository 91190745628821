import React, { useState, useEffect } from "react";
import swal from 'sweetalert';
// Assume `postData` is an action that you've defined and imported
import AuthUser from '../../../Components/Auth/AuthUser';
import Loader from '../../../Components/Loader/Loader';
import Freez from '../../../Components/Loader/Loader'
import Backdrop from '@mui/material/Backdrop';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import { Grid, Typography,Box } from "@mui/material";
import { Card, CardContent } from '@mui/joy';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
function EpicProreport(props) {
    const dispatch = useDispatch();
    const { http, user } = AuthUser();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [freez, setFreez] = React.useState(false);
    const [error, setError] = React.useState('');
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
   
    
    // const fetchreport=async () => {
    //     handleFreezStart();
    //     const pathname = window.location.pathname;
    //     const encodedEmail = pathname.split('/').pop();
    //     // console.log("email", encodedEmail);
    //     const email = atob(encodedEmail);
    //     // console.log("email1", email);
    //     const apiUrl = '/auth/member-credentials/post';
    //     const args = {
    //         email:email
    //     };
    //     const res = await dispatch(postData({ apiUrl, args })).unwrap();
    //     // const args={
    //     //     email:email
    //     // }
    //     // http.post('/auth/member-credentials/post',args).then((res)=>{
           
    //     if(res && res.data){
    //         handleFreezStop();
    //         const { email, password } = res.data.data;
    //             EPRJson(email, password);    
    //     }
    //     else {
    //         swal({
    //             title: "Failed",
    //             text: res.message,
    //             icon: "error",
    //             button: "Ok",
    //         });
    //     }
        
       
    // };
    useEffect(()=>{
        const pathname = window.location.pathname;
      const parts = pathname.split('/');
      const email = atob(parts[3]);
      const password = atob(parts[4]);
    //   alert(email);
      EPRJson(email,password);
    },[]);
    

    const EPRJson = async (email,password) => {
        const userRole=JSON.parse(localStorage.getItem('state'));
        const rolesId = userRole?.auth?.user ? JSON.parse(userRole.auth.user).roles_id : null;
        setIsLoading(true)
        const args = {
          username: email,
          password: password,
          is_system : true,
        };
      
        try {
          // alert(email);
          // alert(password);
          const res = await http.post('/auth/v2/3B/epic/report.html', args);
          if (res.status === 200) {
            if (res.data.success === true) {
                setData(res.data.data);
                localStorage.setItem("epicReportEmail", email);
                setTimeout(() => { 
                    executeScriptsInHtml(res.data.data); // Execute scripts after setting HTML content
                }, 0);
                if(rolesId == 11){
                  localStorage.clear();
                }
              setIsLoading(false)
            } else {
                setIsLoading(false)
                setError(res.data.error)
            }
          } else {
            setIsLoading(false)
            setError('Something went wrong')
            
          }
        } catch (error) {
            setIsLoading(false)
          if (error.response) {
            setError(error.response.data.error || 'Something went wrong')
          } else {
            setError('Something went wrong')
          }
        }
    };
  
   
    // Function to manually execute scripts in HTML content
    const executeScriptsInHtml = (html) => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = html;
        const scripts = tempElement.getElementsByTagName('script');
        for (let i = 0; i < scripts.length; i++) {
            const script = scripts[i];
            const newScript = document.createElement('script');
            newScript.textContent = script.textContent;
            document.body.appendChild(newScript).parentNode.removeChild(newScript);
        }
    };

    return (
        <>
         
         {isLoading ? <><Loader /></> : 

         data.length>0?   
         <div dangerouslySetInnerHTML={{ __html: data }}></div>
         :
         error?
        <Box sx={{height:'100vh',display:'flex'}}>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{display:'flex',justifyContent:'center',margin:'auto'}}>
            <Card variant="outlined" size="lg" sx={{textAlign:'center'}}>
           <CardContent>
          <Grid>
          <ErrorOutlineIcon color="error"  sx={{fontSize:'6vw'}}/>
                <Typography variant="h2" sx={{color:'orange'}}>
                    Error
                </Typography>
                <Typography variant="h4">{error}</Typography>
          </Grid>
           
           </CardContent> </Card>
            </Grid>
        </Box>
        : null
           }
       
             
        </>
    );
}

export default EpicProreport;