import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { Typography, Grid, Box, Table,TableContainer, TableBody, TableHead, TextField, TableRow, Button,TableCell,IconButton } from "@mui/material";
import { Sheet } from '@mui/joy';
import MaterialReactTable from 'material-react-table';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { fetchData, postData } from '../../../../redux/apiSlice';
import swal from "sweetalert";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Formik } from 'formik';
import * as Yup from 'yup';
import AddIcon from "@mui/icons-material/Add";
import Moment from 'moment';
import CloseIcon from "@mui/icons-material/Close";
let adid;
const CoBrandPIDsTab = ({fetchCompanyDetails,aids,company_id,cobrandpids,user,checkUserRole,handleFreezStart,handleFreezStop}) => {
    const dispatch = useDispatch();
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [copySuccess, setCopySuccess] = useState('');
    const [selectPid,setSelectedPid] = useState('');
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const addLink = (pid) => {
        var check = checkUserRole();
        if(check === true) {
            setSelectedPid(pid);
            setOpen(true);
        }
    };
    //Validation
    const AdidSchema = Yup.object().shape({
        AID: Yup.string()
        .min(2, 'Too Short!')
        .max(60, 'Length Of AID can be maximum 60 Chars/digits!')
        .matches(/^[^,\s]*$/, 'No commas or spaces allowed')
        .matches(/^[a-zA-Z0-9]*$/, 'Only alphanumeric characters allowed')
        .required('Please enter AID.'),
        ADID: Yup.string()
        .min(2, 'Too Short!')
        .max(60, 'Length Of ADID can be maximum 60 Chars/digits!')
        .matches(/^[^,\s]*$/, 'No commas or spaces allowed')
        .matches(/^[a-zA-Z0-9]*$/, 'Only alphanumeric characters allowed')
        .required('Please enter ADID.'),
    });

    const columns = [
        { accessorKey: "aid", header: "AID", size: 70 },
        { accessorKey: "cobrandPid", header: "Cobrand Pid", size: 150,
            Cell: ({ row }) => (row.original.cobranPid === 0 || row.original.cobranPid === null ? '' : row.original.cobranPid),
        },
        { accessorKey: "basePid", header: "Base Pid", size: 150, 
            Cell: ({ row }) => (row.original.basePid === 0 || row.original.basePid === null ? '' : row.original.basePid),
        },
        {
            accessorKey: "price",
            header: "Price",
            size: 150,
            Cell: ({ row }) => (row.original.price ? `$${row.original.price}` : '$0.00'),
        },
        {
            accessorKey: "commission",
            header: "Commission",
            size: 150,
            Cell: ({ row }) => (row.original.commission ? `$${row.original.commission}` : '$0.00'),
        },
        {
            accessorKey: "trial_days",
            header: "Trial Days",
            size: 150,
            Cell: ({ row }) => (row.original.trial_days ? `${row.original.trial_days} days` : '0 days'),
        },
        { 
            accessorKey: "cobrandStatus",
            header: "Co-Brand Status", 
            size: 150,
            Cell: ({ row }) => (row.original.cobrandStatus == 1 && row.original.isDisabled == 0 ? "Active" : "Inactive"),
        },
    ];

        const [openAid, setOpenAid] = useState(false);
        const handleCloseAid = () => setOpenAid(false);
        const handleOpenAid = () => {
            var check = checkUserRole();
            if(check === true) {
                setOpenAid(true);
            }
        };
    
        const aidColumns = [
            { accessorKey: "aid", header: "AID", size: 100 },
            {
                accessorKey: "created_by",
                header: "Created By",
                size: 200,
                accessorFn: (row) => row.user ? row.user.name.toLowerCase() : "system generated", // ✅ Used for sorting
                sortingFn: (rowA, rowB) => {
                    const nameA = rowA.original.user ? rowA.original.user.name.toLowerCase() : "system generated";
                    const nameB = rowB.original.user ? rowB.original.user.name.toLowerCase() : "system generated";
                    return nameA.localeCompare(nameB); // ✅ Alphabetical sorting
                },
                Cell: ({ row }) => (
                        row.original.user 
                        ? `${row.original.user.name} ${
                            row.original.user.roles_id === 1 ? "(Super Admin)"
                            : row.original.user.roles_id === 2 ? "(Admin)"
                            : row.original.user.roles_id === 3 ? "(Affiliate)"
                            : "(No Role)"  // <-- Added default case
                        }`
                        : "System Generated"
                ),
            },
            {
                accessorKey: "created_at",
                header: "Created At",
                size: 150,
                Cell: ({ row }) => (Moment(row.original.created_at).tz("America/Los_Angeles").format("MM-DD-YYYY")),
            },
            {
                accessorKey: "aid_status",
                header: "Status",
                size: 120,
                Cell: ({ row }) => (row.original.aid_status === 1 ? "Active" : "Disabled"),
            },
        ];
    
    return (
        <>
            <Grid container item sx={{ backgroundColor: "#D1E3FF", padding: "10px" }} mb={2}>
                <Typography variant="h6" color="primary">AID List</Typography>
            </Grid>
            <Box sx={{ width: "100%" }} mb={3}>
                <MaterialReactTable
                    columns={aidColumns}
                    data={aids}
                    enablePagination
                    enableColumnFilters
                    enableSorting
                    enableGlobalFilter
                    enableRowSelection={false}
                    muiTablePaperProps={{
                        sx: { backgroundColor: "white" },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            backgroundColor: "#2A68B3",
                            color: "white",
                            fontWeight: "bold",
                        },
                    }}
                    muiTableBodyRowProps={({ row }) => ({
                        sx: { backgroundColor: row.index % 2 === 0 ? "white" : "#EBF4FF" },
                    })}
                    renderTopToolbarCustomActions={({ table }) => (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between', // Keeps the layout consistent
                                gap: '1rem',
                                p: '0.5rem',
                                flexWrap: 'wrap',
                                width: '100%',
                            }}
                        >
                            {user.roles_id < 3 && (
                                <Button
                                    variant="contained"
                                    className="create-button"
                                    onClick={handleOpenAid}
                                >
                                    Create AID
                                </Button>
                            )}
                        </Box>
                    )}
                />
            </Box>
            {/* Aid Modal Open */}
            <Formik
                initialValues={{
                    AID: "",
                }}
                validationSchema={AdidSchema}
                onSubmit={async (values, { resetForm }) => {
                    handleFreezStart();
                    const apiUrl = '/addCompanyAID';
                    const args = {
                        company_master_id: company_id,
                        aid: values.AID
                    }
                    const res = await dispatch(postData({ apiUrl, args })).unwrap();
                    let shouldClosePopup = false;
                    if (res) {
                        if (res.success === true) {
                            handleFreezStop();
                            swal({
                                title: "Success",
                                text: res.message,
                                icon: "success",
                                button: "Ok",
                                showBackdrop: true, // Show backdrop
                                closeOnClickOutside: false, // Disable closing on click outside
                            });
                            handleClose();
                            fetchCompanyDetails();
                            resetForm();
                            shouldClosePopup = true;
                        } else {
                            handleFreezStop();
                            handleClose();
                            resetForm();
                            swal({
                                title: "Failed",
                                text: "AID Already Exists.",
                                icon: "error",
                                button: "Ok",
                                showBackdrop: true,
                                closeOnClickOutside: false,
                            });
                        }
                    }
                    document.querySelector(".swal-modal").addEventListener("click", (event) => {
                        event.stopPropagation();
                    });

                    // Listen for clicks outside the popup and close it if needed
                    document.body.addEventListener("click", () => {
                        if (shouldClosePopup) {
                            swal.close();
                        }
                    });
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue,resetForm }) => (
                    <>
                        <Dialog
                            open={openAid}
                            onClose={(event, reason) => {
                                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                                    handleCloseAid(resetForm());
                                }
                            }}
                            fullWidth={fullWidth}
                            maxWidth={maxWidth}
                            PaperProps={{
                                sx: {
                                    borderRadius: "12px",
                                    padding: "20px"
                                }
                            }}
                        >
                            <DialogTitle className="dialog-title" >
                                <Typography variant="h6" fontWeight="bold"> Create New AID </Typography>
                                <IconButton onClick={(e)=>{handleCloseAid(resetForm());}}>
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <TextField
                                        label="New AID"
                                        type="text"
                                        size="small"
                                        fullWidth
                                        margin='dense'
                                        value={values.AID}
                                        onChange={(e) => {
                                            handleChange(e);
                                        }}
                                        // onChange={(e) => { handlechange1(e.target.value) }}
                                        name='AID'
                                        id='AID'
                                        className="blue-border"
                                        helperText={touched.AID && errors.AID ? errors.AID : ""}
                                        error={touched.AID && Boolean(errors.AID)}
                                    />
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }} spacing={2} mt={1}>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" 
                                            onClick={(e)=>{
                                                handleCloseAid(resetForm());
                                            }}
                                            className="cancel-button">
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" onClick={handleSubmit} className="save-button">
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Dialog>
                    </>
                )}
            </Formik>
            <Grid container item sx={{ backgroundColor: "#D1E3FF", padding: "10px" }}  mb={2}>
                <Typography variant="h6" color="primary">PID List</Typography>
            </Grid>
            <Box sx={{ width: "100%" }} >
                <MaterialReactTable
                    columns={columns}
                    data={cobrandpids}
                    enablePagination
                    enableColumnFilters
                    enableSorting
                    enableGlobalFilter
                    enableRowSelection={false}
                    muiTablePaperProps={{
                        sx: { backgroundColor: "white" },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            backgroundColor: "#2A68B3",
                            color: "white",
                            fontWeight: "bold",
                        },
                    }}
                    muiTableBodyRowProps={({ row }) => ({
                        sx: { backgroundColor: row.index % 2 === 0 ? "white" : "#EBF4FF" },
                    })}
                />
            </Box>
            <Grid item lg={12} md={12} sm={12} xs={12} mt={2} >
                <Grid container item sx={{ backgroundColor: "#D1E3FF", padding: "10px" }}>
                    <Typography variant="h6" color="primary">Cobrand Links</Typography>
                </Grid>
                {/* <TableContainer> */}
                <Sheet sx={{ height: 500, overflow: 'auto',ml:1}} >
                    <Table
                        aria-label="table with sticky header"
                        stickyHeader
                        stripe="odd"
                        sx={{mt:2}}
                        >
                        {cobrandpids == null
                            ? ""
                            : cobrandpids.map((item, index) => {
                                return item.description ? (
                                    <React.Fragment key={index}>
                                        <strong>{item.description}</strong>
                                        <br />
                                        <div className="link-container">
                                            {/* Left Side: Link & Copy Icon */}
                                            <div className="link-left">
                                                <a href={item.href} target="_blank" rel="noopener noreferrer" style={{ color: "black", textDecoration: "none" }}>
                                                    {item.href}
                                                </a>
                                                {/* Copy Icon for Main Link */}
                                                <ContentCopyOutlinedIcon
                                                    sx={{ ml: 1, fontSize: "15px", cursor: "pointer" }}
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(item.href);
                                                        setCopySuccess(item.cobranPid);
                                                        setTimeout(() => {
                                                            setCopySuccess("");
                                                        }, 3000);
                                                    }}
                                                />
                                                {copySuccess == item.cobranPid ? " Copied!" : ""}
                                            </div>

                                            {/* Right Side: Add Sublink Button */}
                                            {user.roles_id <= 2 && (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => addLink(item.cobranPid)}
                                                    className="add-sublink-btn"
                                                    startIcon={<AddIcon />}
                                                >
                                                    Add Sublink
                                                </Button>
                                            )}
                                        </div>
                                        {/* Sub-links in Numbered Format with Copy Icon */}
                                        {item.sublinks && item.sublinks.length > 0 && (
                                            <div style={{ marginTop: "5px" }}>
                                                <span style={{ fontWeight: "bold", display: "block"}}>Sublinks:</span>
                                                <ol>
                                                    {item.sublinks.map((subLink, subIndex) => (
                                                        <li
                                                            key={subIndex}
                                                            style={{
                                                                listStyleType: "decimal",
                                                                alignItems: "center",
                                                                gap: "5px",
                                                                marginBottom: "10px",
                                                                paddingBottom: "8px",
                                                                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                                                            }}
                                                        >
                                                            <a href={subLink} target="_blank" style={{ color: "#2D6DB4", textDecoration: "none" }}>
                                                                {subLink}
                                                            </a>
                                                            <ContentCopyOutlinedIcon
                                                                sx={{ fontSize: "15px", cursor: "pointer", marginLeft: "10px" }}
                                                                onClick={() => {
                                                                    navigator.clipboard.writeText(subLink);
                                                                    setCopySuccess(subLink);
                                                                    setTimeout(() => {
                                                                        setCopySuccess("");
                                                                    }, 3000);
                                                                }}
                                                            />
                                                            {copySuccess === subLink ? "Copied!" : ""}
                                                        </li>
                                                    ))}
                                                </ol>
                                            </div>
                                        )}
                                    </React.Fragment>
                                ) : null;
                        })}
                    </Table>
                </Sheet>
                {/* </TableContainer> */}
            </Grid>
            {/* add sublink code start */}
            <Formik
                initialValues={{
                    ADID: adid,
                }}
                validationSchema={AdidSchema}
                onSubmit={async (values, {resetForm}) => {
                    handleFreezStart();
                    const apiUrl = "/updateAdid";
                    const args = {
                        pid:selectPid,
                        adid: values.ADID,
                        
                    }
                    const res = await dispatch(postData({ apiUrl, args })).unwrap();
                    if (res) {
                        resetForm();
                        if (res.success === true) {
                            handleFreezStop();
                            setOpen(false);
                            swal({
                                title: "Success",
                                text: res.message,
                                icon: "success",
                                button: "Ok",
                                showBackdrop: true, // Show backdrop
                                closeOnClickOutside: false, // Disable closing on click outside
                            }).then((ok) => {
                                if (ok) {
                                    handleClose();
                                    fetchCompanyDetails();
                                }
                            });
                        } else {
                            handleFreezStop();
                            setOpen(false);
                            swal({
                                title: "Failed",
                                text: res.message,
                                icon: "error",
                                button: "Ok",
                                showBackdrop: true,
                                closeOnClickOutside: false,
                            });
                        }
                    }
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue,resetForm }) => (
                    <>
                        <div>
                            <Dialog
                                open={open}
                                onClose={(event, reason) => {
                                    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                                        handleClose(resetForm());
                                    }
                                }}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                fullWidth={fullWidth}
                                maxWidth={maxWidth}
                                PaperProps={{
                                    sx: {
                                        borderRadius: "12px",
                                        padding: "20px"
                                    }
                                }}
                            >
                                {/* <Box sx={style}> */}
                                <>
                                    <DialogTitle textAlign={'center'} id="responsive-dialog-title" p={5} sx={{ backgroundColor: "#F6FAFF" }}>
                                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                                            <Typography variant="h5" color='primary'>Enter Unique Identifier</Typography>
                                        </Grid>
                                    </DialogTitle>
                                    <DialogContent>
                                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                                            <TextField
                                                type="text"
                                                size="small"
                                                label="Unique Identifier"
                                                variant="outlined"
                                                fullWidth
                                                margin='dense'
                                                value={values.ADID}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                }}
                                                // onChange={(e) => { handlechange1(e.target.value) }}
                                                name='ADID'
                                                id='ADID'
                                                className="blue-border"
                                                helperText={touched.ADID && errors.ADID ? errors.ADID : ""}
                                                error={touched.ADID && Boolean(errors.ADID)}
                                            />
                                        </Grid>
                                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }} spacing={3} mt={2}>
                                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                                <Button variant="contained" sx={{ minWidth: 200 }} className="cancel-button" onClick={(e)=>{handleClose(resetForm());}}>Close</Button>
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                                <Button variant="contained" sx={{ minWidth: 200 }} className="save-button" onClick={handleSubmit}>Create</Button>
                                            </Grid>
                                        </Grid>
                                    </DialogContent>
                                </>
                                {/* </Box> */}
                            </Dialog>
                        </div>
                    </>
                )}
            </Formik>
            {/* add sublink code ends */}
        </>
    )

}
export default CoBrandPIDsTab;