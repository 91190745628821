import React, { useMemo, useState, useEffect } from 'react';
//MRT Imports
import MaterialReactTable from 'material-react-table';
//Material-UI Imports
import {
    Box,
    useMediaQuery,
    Table, Tooltip, TableCell, Paper, Button, TableContainer, TableRow,Stack,Checkbox,
    Dialog, DialogActions, DialogTitle, TextField, DialogContent,Grid,FormControl,InputLabel
} from '@mui/material';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import {  useNavigate, Link,  } from 'react-router-dom';
//Mock Data
import { data } from './makeData';
import AuthUser from '../Auth/AuthUser';
import Loader from '../Loader/Loader';
import config from '../../AppConfig';
import Freez from '../Loader/Loader'
import Backdrop from '@mui/material/Backdrop';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import swal from "sweetalert";
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../redux/apiSlice';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Moment from 'moment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import DialogContentText from '@mui/material/DialogContentText';
import formatPhoneNumber from '../../../src/Components/Formats/PhoneNumberValidation';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import {openOrFocusWindow } from  '../CommonFormula';
import AiFillPhone from '@mui/icons-material/Phone';
let RequestFormInitialValues = {
    id : '',
    email : '',
    password : '',
  };

function MemberListData(props) {
    const dispatch = useDispatch();
    const { http,user,company } = AuthUser();
    const [rowSelection, setRowSelection] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [memberData, setMemberData] = useState([]);
    const [columnVisibility, setColumnVisibility] = useState({
        aid: false, // Initially hide the "aid" column
        publisher_id: false, // Initially hide the "publisher_id" column
    });
    const [open, setOpen] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [operation, setOperation] = React.useState('');
    const [submitEnable, setSubmitEnable] = React.useState(false);
    const [submitCheckBox, setSubmitCheckBox] = React.useState(false);
    const [passwordVisibility, setPasswordVisibility] = useState({});
    const [passOpen, setPassOpen] = React.useState(false);
    const [action, setAction] = React.useState('');
    const [actionId, setActionId] = React.useState('');
    const [btnName, setBtnName] = React.useState('');
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = React.useState(false);
    const [pauseOpen, setPauseOpen] = React.useState(false);
    const [pauseEmail, setPauseEmail] = React.useState('');
    const [status, setStatus] = React.useState('');
    const [scrollX, setScrollX] = useState(0);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [companyType, setCompanyType] = useState("");
    const [prodigyUser, setProdigyUser] = useState("");
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  
    //save prefrences start function here
    const tablePreferencesArray = { 

    }
    useEffect(() => {
    }, [rowSelection]);
     ////////////////////////
     useEffect(() => {
        if(Object.values(columnVisibility).length>0){
          setColumnVisibility(columnVisibility);
        }
        //do something when the row selection changes...
        // console.info({ rowSelection });
      }, [rowSelection]);
    
      useEffect(() => {
        tablePreferences();
      }, [rowSelection]);
      ////////////////////////
    
    const saveTablePreferences = async() => {
        if(Object.values(columnVisibility).length > 0){
            const apiUrl = '/saveTablePreferences';
            const args = {
                columnArray: columnVisibility,
                userId:user.id,
                tableId: 'affiliatememberlist'
            }
		    const res = await dispatch(postData({apiUrl,args})).unwrap();
        } 
    }

    useEffect( ()=>{  
    saveTablePreferences();
    },[columnVisibility])

    //fetch table preferences from database
    const tablePreferences = async () => { 
        const apiUrl = '/fetchTablePreferences/affiliatememberlist';
		const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res){
            if(Object.values(res).length == 0 ){
                setColumnVisibility(tablePreferencesArray);
            }else{
                setColumnVisibility((prev) => ({
                    ...prev,
                    ...((JSON.parse(res.preferences))), // Override default visibility with fetched preferences
                    aid: false, 
                    publisher_id: false, 
                }));
            }
        }
    }
    //save prefrences end function here

    //view3b functions and varialbles starts
    const view3b = (row) => {
        localStorage.setItem('username', row.original.email);
        localStorage.setItem('username', row.original.password);
        // Append "/score" to the base URL
        const updatedUrl = config.BASE_URL + `3breport`;
        // Navigate to the updated URL
        window.open(updatedUrl, '_blank');

    }

    const updateSchema = Yup.object().shape({
        password: Yup.string()
          .min(8, 'Must be 8 digits.')
          .required('Please enter Password'),
    })

    //view3b functions and varialbles ends
    // Function to decode base64 string starts
    const decodeBase64 = (str) => {
        try {
            var passValue = str =  atob(str);
            var masked = str.substring(0, str.length - 4);
            masked = masked.replace(/./g, '*'); //The character is ASCII-7 (Press Alt+7 to type)
            var text = passValue.substring(passValue.length - 4);
            var newPass = masked + text;
            return newPass;
        } catch (error) {
            // console.error('Error decoding base64:', error);
            return '';
        }
    };

    const getPasswordString = (str) => {
        try {
            var passValue = str =  atob(str);
            return passValue;
        } catch (error) {
            // console.error('Error decoding base64:', error);
            return '';
        }
    };

    const handleClickOpen = (id,email,aid, operation) => {
        setScrollX(window.scrollX);
        setOpen(true);
        operation == "Add" ? setSubmitEnable(true) : setSubmitEnable(false);
        RequestFormInitialValues.id = id;
        RequestFormInitialValues.email = email;
        RequestFormInitialValues.aid = aid;
        setOperation(operation);
        
    };
 
    const handleClickPassOpen = (id,email,aid, action) => {
        setScrollX(window.scrollX);
        setActionId(id);
        setPassOpen(true);
        RequestFormInitialValues.id = id;
        RequestFormInitialValues.email = email;
        RequestFormInitialValues.aid = aid;
        setAction(action);
        switch(action)
        {
            case "Login" : setBtnName("Login");
                           break;
            case "3B" : 
                        setBtnName("3B View");
                        break;
            case "Epic Pro" :
                        setBtnName("Epic Pro Report"); 
                        break;
            
        }
        // RequestFormInitialValues.password = password;
    };

    const createProdigyAccount = async(email) => {
        handleFreezStart();
        const apiUrl = '/createMemberCient';
        const args = {
            email: email,
            userId: prodigyUser,
        }
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        if(res){
            if (res.success) {
                swal({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    button: "Ok",
                })
            }else{
                swal({
                    title: "Failed",
                    text: res.message,
                    icon: "error",
                    button: "Ok",
                })
            }
        }
        handleFreezStop();
        getMemberData();
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handlePassClose = () => {
        setPassOpen(false);
    };

    const closePauseDialog = () => {
        setPauseOpen(false);
    };

    const changeAction = () => {
        setSubmitCheckBox(!submitCheckBox);
        setSubmitEnable(!submitEnable);
    };

    // Function to decode base64 string ends
    //loader function and variable start
    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };

    const submitRequest = async (values) => {
        setSubmitEnable(true);
        // handleFreezStart();
        const apiUrl = '/save-enrollment-password';
        const args = {
            email: values.email,
            aid: values.aid,
            password: values.password,
        }
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        if(res){

            if (res.success) {
                setOpen(false);
                swal({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    button: "Ok",
                })
                
                        getMemberData();                    
                    
            } 
            else {
                swal({
                    title: "Failed",
                    text: res.message,
                    icon: "error",
                    button: "Ok",
                }).then((ok) => {
                    if (ok) {
                        getMemberData();                    
                    }
                });
            }
        }
        
    }
    const getPuaseOrActiveConfirmation = (email,status) => {
        setPauseEmail(email);
        setStatus(status);
        setPauseOpen(true);
    }

    const handleClickPause = async () => {
        setPauseOpen(false);
        handleFreezStart();
        const args = {
            email: pauseEmail,
            status:status,
        }
        const apiUrl = '/affiliate/changeMemberStatus';
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        if(res){

            if (res.success) {
                setOpen(false);
                swal({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    button: "Ok",
                })
                    getMemberData();                    
                    
            } 
            else {
                swal({
                    title: "Failed",
                    text: res.message,
                    icon: "error",
                    button: "Ok",
                }).then((ok) => {
                    if (ok) {
                        getMemberData();                    
                    }
                });
            }
        }
        handleFreezStop();
        
    }

    const submitRedirectRequest = async (values) => {
       
        switch(action)
        {
            case "Login" : 
                            document.getElementById("password_"+actionId).value = values.password.trim(); 
                            var form = document.getElementById("loginForm_"+actionId);
                            form.submit();
                            break;
            case "3B" : 
          window.open("/view3BReport/" + btoa(values.email)+"/" + btoa(values.password.trim()),'_blank')
                            break;
            case "Epic Pro" : 
                                var formData = document.createElement('form');
                                formData.id = 'form_epic_pro'+ values.email;
                                formData.method = 'post';
                                formData.action = config.API_URL+'/api/member/epicproreport';
                            
                                // Create input fields for parameters
                                var emailInput = document.createElement('input');
                                emailInput.type = 'hidden';
                                emailInput.name = 'username';
                                emailInput.value = btoa(values.email);
                            
                                var passwordInput = document.createElement('input');
                                passwordInput.type = 'hidden';
                                passwordInput.name = 'password';
                                passwordInput.value = btoa(values.password.trim());

                                var companyIdInput = document.createElement('input');
                                companyIdInput.type = 'hidden';
                                companyIdInput.name = 'companyId';
                                companyIdInput.value = company ? company.id : null;
                                
                                // Append input fields to the form
                                formData.appendChild(emailInput);
                                formData.appendChild(passwordInput);
                                formData.appendChild(companyIdInput);

                            
                                // Append form to the document body and submit it
                                document.body.appendChild(formData);
                                formData.target = '_blank'; // Open link in a new tab
                                formData.submit();

                                document.body.removeChild(formData);
                        // window.open(config.API_URL+'/member/epicproreport/'+company.id+'/'+btoa(values.email)+'/'+ btoa(values.password), '_blank');
                        break;
            
        }
    }

    //loader function and variable end
    const getMemberData = async() => {
        setIsLoading(true);
        const apiUrl = '/affiliate/memberList/'+props.tab;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res){
            setMemberData(res.data.data);
            setCompanyType(res.data.companyType);
            setProdigyUser(res.data.prodigyUser);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getMemberData();
    }, []);

    const togglePasswordVisibility = (rowId) => {
        setPasswordVisibility((prevState) => ({
            ...prevState,
            [rowId]: !prevState[rowId],
        }));
    };
    
  const fetchReport = async (email, password) => {
    handleFreezStart();
    const args = {
      username: email,
      password: password,
      is_system: true
    };
  
    try {
      const res = await http.post('/auth/3B/report.html', args);
  
      if (res.status === 200) {
        if (res.data.success === true) {
          sessionStorage.setItem('view3BData', res.data.data.html);
          handleFreezStop();
          window.open('/view3BReport', '_blank');
        } else {
          handleFreezStop();
          swal({
            title: "Failed",
            text: res.data.error,
            icon: "error",
            button: "Ok",
          });
        }
      } else {
        handleFreezStop();
        swal({
            title: "Failed",
            text: 'An error occurred1',
            icon: "error",
            button: "Ok",
          });
      }
    } catch (error) {
        console.log(error)
      handleFreezStop();
      if (error.response) {
        swal({
          title: "Failed",
          text: error.response.data.error || 'An error occurred',
          icon: "error",
          button: "Ok",
        });
      } else {
        swal({
          title: "Failed",
          text: 'An error occurred2',
          icon: "error",
          button: "Ok",
        });
      }
      console.error('Error:', error);
    }
  };
    const columns = useMemo(
        () => [
            {
                accessorFn: (row) => `${row.aid? row.aid.trim():''}`, //accessorFn used to join multiple data into a single cell
                id: 'aid', //id is still required when using accessorFn instead of accessorKey
                header: 'AID',
                Cell: ({ renderedCellValue, row }) => (row.original.aid ? row.original.aid.trim() : 'N/A'),
                // size: 100,
            },
            {
                accessorFn: (row) => `${row.name?row.name.trim():''}`, //accessorFn used to join multiple data into a single cell
                id: 'name', //id is still required when using accessorFn instead of accessorKey
                header: 'Name',
                Cell: ({ renderedCellValue, row }) => (row.original.name ? row.original.name.trim() : 'N/A'),
                // size: 150,
            },
            {
                accessorFn: (row) => `${row.email?row.email.trim():''}`,
                accessorKey: 'email', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                enableClickToCopy: true,
                id: 'email',
                header: 'Email',
                sortingFn: (rowA, rowB) => {
                    const emailA = rowA.original.email.toLowerCase();
                    const emailB = rowB.original.email.toLowerCase();
                    return emailA.localeCompare(emailB);
                  },
                Cell: ({ renderedCellValue, row }) => (row.original.email ? row.original.email.trim() : 'N/A'),
            },
            {
				accessorKey: 'phone_number',
				accessorFn: (row) => row.phone_number?.trim() || 'N/A',
				filterVariant: 'range',
				id:'phoneNo',
				header: 'Phone Number',
				size: 50,
                Cell: ({ renderedCellValue,row }) => (
					row.original.phone_number ?
					<div>
						{formatPhoneNumber(row.original.phone_number)}
					</div>: 'N/A'
				),
			},
            {
                accessorFn: (row) => `${row.password}`, //accessorFn used to join multiple data into a single cell
                id: 'password', //id is still required when using accessorFn instead of accessorKey
                header: 'Password',
                Cell: ({ renderedCellValue, row }) => (
                    <Box className="datatable-column">
                        {passwordVisibility[row.id] ? getPasswordString(row.original.password) : decodeBase64(row.original.password) }
                        {
                        `${decodeBase64(row.original.password)}` 
                        ? 
                            `${decodeBase64(row.original.password).length}` > 0 
                            ?
                                <>
                                {/* {user.roles_id != 5 
							    ? */}
                                <>
                                    {passwordVisibility[row.id] ?
                                    
                                    <VisibilityIcon style={{marginLeft: "10%"}} onClick={() => togglePasswordVisibility(row.id) }/> 
                                    :
                                    <VisibilityOffIcon style={{marginLeft: "10%"}} onClick={() => togglePasswordVisibility(row.id) }/> }

                                    <Tooltip title='Update Password' arrow>
                                        <ModeEditOutlineOutlinedIcon onClick={() => { handleClickOpen(`${row.id}`,`${row.original.email}`,`${row.original.aid}`, "Update" ) }}/>
                                    </Tooltip>
                                    </>
                                    
                                {/* : null }   */}
                                </>  
                            : 
                            'N/A'
                        :
                        <>
                        {/* {user.roles_id != 5 
                        ?  */}
                            <Tooltip title='Add Password' arrow>
                                <AddCircleOutlineIcon onClick={() => { handleClickOpen(`${row.original.member_id}`,`${row.original.email}`,`${row.original.aid}`, "Add" ) }}/>
                            </Tooltip>
                        {/* : null } */}
                        </>
                        }
                    </Box>
                ),
            },
           
           
            {
                accessorFn: (row) => `${row.date_range_filter?row.date_range_filter:''}`,
                accessorKey: 'enrolled_date', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                enableClickToCopy: true,
                id: 'enrolledDate',
                header: 'Enrolled Date',
                // size: 300,
                Cell:({renderedCellValue,row}) => (
                    <div style={{textAlign:"center"}}>
                    {row.original.date_range_filter?Moment(row.original.date_range_filter).format('MM-DD-YYYY'):'N/A'}
                    </div>          
                  ),
            },
            {
                accessorFn: (row) => `${row.publisher_id? row.publisher_id:''}`,
                id: 'publisher_id',
                header: 'Publisher Id',
                Cell: ({ renderedCellValue, row }) => (row.original.publisher_id ? row.original.publisher_id == 17 ? "00017" : row.original.publisher_id : 'N/A'),
            },
            {

                
                header: 'Action',
                Cell: ({ renderedCellValue, row }) => (
                    <>
                    
                    <Stack direction="row" justifyContent={"space-evenly"}>
                        <Grid>
                        {getPasswordString(row.original.password).length > 0 ? 
                            <>
                                <form method="post" action="https://member.myfreescorenow.com/external-login" target="_blank">
                                    <input type="hidden" name="loginType" value="CUSTOMER" />
                                    <input type="hidden" name="j_username" value={row.original.email} />
                                    <input type="hidden" name="j_password" value={getPasswordString(row.original.password)} target="_blank"/>                            
                                    <Tooltip title="Login" arrow><button type='submit' style={{border:"none",background:"none",borderRadius:"0%",padding:'0px'}}><img src={require('../../assets/icons/login.png')} /></button></Tooltip>
                                </form>
                            </>
                        : 
                            <>
                                <form method="post" id={`loginForm_${row.original.member_id}`} action="https://member.myfreescorenow.com/external-login" target="_blank">
                                <input type="hidden" name="loginType" value="CUSTOMER" />
                                <input type="hidden" name="j_username" id={`username_${row.original.member_id}`} value={row.original.email} />
                                <input type="hidden" name="j_password" id={`password_${row.original.member_id}`} value="" />
                            </form>
                                <>
                                    {/* {user.roles_id != 5 
                                    ?  */}
                                        <Tooltip title="Login" arrow><button onClick={() => { handleClickPassOpen(`${row.original.member_id}`,`${row.original.email}`,`${row.original.aid}`, "Login" )}} style={{border:"none",background:"none",borderRadius:"0%",padding:'0px'}}><img src={require('../../assets/icons/login.png')} /></button></Tooltip>
                                    {/* : null } */}
                                </>
                            </>
                        }
                        </Grid>
                       
                        <Grid>
                        {getPasswordString(row.original.password).length > 0 ? 
                        <>
                                <Tooltip title="View 3B" arrow><Button onClick={()=>window.open('/view3BReport/' + btoa(row.original.email)+"/"+row.original.password,'_blank')} sx={{padding:'0px'}}><img src={require('../../assets/icons/view3B.png')} /></Button></Tooltip>

                        </>
                        : 
                        <>
                            {/* {user.roles_id != 5 ?  */}
                                <Tooltip title="View 3B" arrow><button onClick={() => { handleClickPassOpen(`${row.original.member_id}`,`${row.original.email}`,`${row.original.aid}`, "3B" )}} style={{border:"none",background:"none",borderRadius:"0%",padding:'0px'}}><img src={require('../../assets/icons/view3B.png')} /></button></Tooltip>
                            {/* : null } */}
                        </>
                        }
                        </Grid>

                        {
                        company
                        ?
                            company.epic_pro_status == 1 ?
                            <Grid>
                            {getPasswordString(row.original.password).length > 0 ? 
                                <>
                                    {/* <form method="POST" action={config.API_URL+'/api/member/epicproreport'} target="_blank">
                                        <input type="hidden" name="companyId" value={company.id} />
                                        <input type="hidden" name="username" value={btoa(row.original.email)} />
                                        <input type="hidden" name="password" value={row.original.password} />    */}
                                        <Tooltip title="View Epic Pro Report" arrow><button onClick={()=>window.open('/epicproreport/member/' + btoa(row.original.email)+"/"+row.original.password,'_blank')} style={{border:"none",background:"none",borderRadius:"0%",padding:'0px'}}><img src={require('../../assets/icons/EPR.png')} /></button></Tooltip>
                                    {/* </form> */}
                                </>
                                
                                // <Tooltip title="View Epic Pro Report" arrow>
                                //     <Link to={config.API_URL+'/member/epicproreport/'+company.id+'/'+btoa(row.original.email)+'/'+row.original.password} target="_blank"><img src={require('../../assets/icons/EPR.png')} /></Link> 
                                // </Tooltip>
                            :
                            <>
                                {/* {user.roles_id != 5 
                                ?  */}
                                    <Tooltip title="View Epic Pro Report" arrow><button onClick={() => { handleClickPassOpen(`${row.original.member_id}`,`${row.original.email}`,`${row.original.aid}`, "Epic Pro" )}} style={{border:"none",background:"none",borderRadius:"0%",padding:'0px'}}><img src={require('../../assets/icons/EPR.png')} /></button></Tooltip>
                                {/* : null } */}
                            </>
                            }
                               
                            </Grid>
                            :
                            null
                            :
                            null
                        }
                        <Grid>
                            {
                                row.original.plan == "Sponsored"  && row.original.free_member === null ?
                               <>
                                    {row.original.account_status == "ACTIVE" ?
                                    <>
                                        <Tooltip title="Pause" arrow><button onClick={() => { getPuaseOrActiveConfirmation(`${row.original.email}`,"pause")}} style={{border:"none",background:"none",borderRadius:"0%",padding:'0px'}}><img src={require('../../assets/icons/pause.png')} /></button></Tooltip>
                                    </>
                                    :<Tooltip title="Activate" arrow><button onClick={() => { getPuaseOrActiveConfirmation(`${row.original.email}`,"resume")}} style={{border:"none",background:"none",borderRadius:"0%",padding:'0px'}}><img src={require('../../assets/icons/resume.png')} /></button></Tooltip>
                                    }
                               </> 
                               : ''
                            }
                        </Grid>
                        </Stack>                     
                                     
                        
                    </>
                ),
            },
        ],
    );

    const ProdigyColumns = useMemo(
        () => [
            {
                accessorFn: (row) => `${row.aid? row.aid.trim():''}`, //accessorFn used to join multiple data into a single cell
                id: 'aid', //id is still required when using accessorFn instead of accessorKey
                header: 'AID',
                Cell: ({ renderedCellValue, row }) => (row.original.aid ? row.original.aid.trim() : 'N/A'),
                // size: 100,
            },
            {
                accessorFn: (row) => `${row.name?row.name.trim():''}`, //accessorFn used to join multiple data into a single cell
                id: 'name', //id is still required when using accessorFn instead of accessorKey
                header: 'Name',
                Cell: ({ renderedCellValue, row }) => (row.original.name ? row.original.name.trim() : 'N/A'),
                // size: 150,
            },
            {
                accessorFn: (row) => `${row.email?row.email.trim():''}`,
                accessorKey: 'email', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                enableClickToCopy: true,
                id: 'email',
                header: 'Email',
                sortingFn: (rowA, rowB) => {
                    const emailA = rowA.original.email.toLowerCase();
                    const emailB = rowB.original.email.toLowerCase();
                    return emailA.localeCompare(emailB);
                  },
                Cell: ({ renderedCellValue, row }) => (row.original.email ? row.original.email.trim() : 'N/A'),
            },
            {
				accessorKey: 'phone_number',
				accessorFn: (row) => `${row.phone_number ? row.phone_number.trim() : 'N/A'}`,
				filterVariant: 'range',
				id:'phoneNo',
				header: 'Phone Number',
				size: 50,
                Cell: ({ renderedCellValue,row }) => (
					row.original.phone_number ?
					<div>
						{formatPhoneNumber(row.original.phone_number)}
						&nbsp;&nbsp;
				<span>
					{ row.original.current_hours != '' ? 
							row.original.current_hours >= 8 && row.original.current_hours <= 19 ?
							<Tooltip title={row.original.current_time}><WbSunnyIcon color="warning"></WbSunnyIcon ></Tooltip> : <Tooltip title={row.original.current_time}><DarkModeIcon ></DarkModeIcon></Tooltip>
						:''
					}
				</span>
				{user.roles_id == 13?null:<Tooltip title="Dial" arrow>
						<a onClick={(event) => {event.preventDefault(); openOrFocusWindow(config.CallHippoDialer+row.original.phone_number); }} >
							<AiFillPhone color="primary" />
						</a>
					</Tooltip>}
					</div>: 'N/A'
				),
			},
            {
                accessorFn: (row) => `${row.password}`, //accessorFn used to join multiple data into a single cell
                id: 'password', //id is still required when using accessorFn instead of accessorKey
                header: 'Password',
                
                Cell: ({ renderedCellValue, row }) => (
                    <Box className="datatable-column">
                        {passwordVisibility[row.id] ? getPasswordString(row.original.password) : decodeBase64(row.original.password) }
                        {
                        `${decodeBase64(row.original.password)}` 
                        ? 
                            `${decodeBase64(row.original.password).length}` > 0 
                            ?
                                <>
                                {/* {user.roles_id != 5 
							    ? */}
                                <>
                                    {passwordVisibility[row.id] ?
                                    
                                    <VisibilityIcon style={{marginLeft: "10%"}} onClick={() => togglePasswordVisibility(row.id) }/> 
                                    :
                                    <VisibilityOffIcon style={{marginLeft: "10%"}} onClick={() => togglePasswordVisibility(row.id) }/> }

                                    <Tooltip title='Update Password' arrow>
                                        <ModeEditOutlineOutlinedIcon onClick={() => { handleClickOpen(`${row.id}`,`${row.original.email}`,`${row.original.aid}`, "Update" ) }}/>
                                    </Tooltip>
                                    </>
                                    
                                {/* : null }   */}
                                </>  
                            : 
                            'N/A'
                        :
                        <>
                        {/* {user.roles_id != 5 
                        ?  */}
                            <Tooltip title='Add Password' arrow>
                                <AddCircleOutlineIcon onClick={() => { handleClickOpen(`${row.original.member_id}`,`${row.original.email}`,`${row.original.aid}`, "Add" ) }}/>
                            </Tooltip>
                        {/* : null } */}
                        </>
                        }
                    </Box>
                ),
            },
            {
                accessorFn: (row) => `${row.date_range_filter?row.date_range_filter:''}`,
                accessorKey: 'enrolled_date', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                enableClickToCopy: true,
                id: 'enrolledDate',
                header: 'Enrolled Date',
                Cell:({renderedCellValue,row}) => (
                    <div style={{textAlign:"center"}}>
                    {row.original.date_range_filter?Moment(row.original.date_range_filter).format('MM-DD-YYYY'):'N/A'}
                    </div>          
                  ),
            },
            {
                accessorFn: (row) => `${row.publisher_id? row.publisher_id:''}`,
                id: 'publisher_id',
                header: 'Publisher Id',
                Cell: ({ renderedCellValue, row }) => (row.original.publisher_id ? row.original.publisher_id == 17 ? "00017" : row.original.publisher_id : 'N/A'),
                // size: 100,
            },
            {
                accessorFn: (row) => `${(data.software_enroll_client_for_member_list) ? data.software_enroll_client_for_member_list.linked_account_username : '' }`,
                accessorKey: 'pUserName',
                enableClickToCopy: true,
                id: 'pUserName',
                header: 'Prodigy Surge Acc. User Name',
                Cell:({renderedCellValue,row}) => (
                    <div style={{textAlign:"center"}}>
                    {row.original.software_enroll_client_for_member_list ?  row.original.software_enroll_client_for_member_list.linked_account_username :'N/A' }
                    </div>          
                  ),
            },
            {
                accessorFn: (row) => `${row}`,
                accessorKey: 'pPassword',
                enableClickToCopy: true,
                id: 'pPassword',
                header: 'Prodigy Surge Acc. Password',
                Cell:({renderedCellValue,row}) => (
                    <div style={{textAlign:"center"}}>
                    { row.original.software_enroll_client_for_member_list ?  row.original.software_enroll_client_for_member_list.password :'N/A' }
                    </div>          
                  ),
            },
            {

                header: 'Actions',
                Cell: ({ renderedCellValue, row }) => (
                    <>
                    
                    <Stack direction="row" justifyContent={"space-evenly"}>
                        <Grid>
                        {getPasswordString(row.original.password).length > 0 ? 
                            <>
                                <form method="post" action="https://member.myfreescorenow.com/external-login" target="_blank">
                                    <input type="hidden" name="loginType" value="CUSTOMER" />
                                    <input type="hidden" name="j_username" value={row.original.email} />
                                    <input type="hidden" name="j_password" value={getPasswordString(row.original.password)} target="_blank"/>                            
                                    <Tooltip title="Login" arrow><button type='submit' style={{border:"none",background:"none",borderRadius:"0%",padding:'0px'}}><img src={require('../../assets/icons/login.png')} /></button></Tooltip>
                                </form>
                            </>
                        : 
                            <>
                                <form method="post" id={`loginForm_${row.original.member_id}`} action="https://member.myfreescorenow.com/external-login" target="_blank">
                                <input type="hidden" name="loginType" value="CUSTOMER" />
                                <input type="hidden" name="j_username" id={`username_${row.original.member_id}`} value={row.original.email} />
                                <input type="hidden" name="j_password" id={`password_${row.original.member_id}`} value="" />
                            </form>
                                <>
                                    {/* {user.roles_id != 5 
                                    ?  */}
                                        <Tooltip title="Login" arrow><button onClick={() => { handleClickPassOpen(`${row.original.member_id}`,`${row.original.email}`,`${row.original.aid}`, "Login" )}} style={{border:"none",background:"none",borderRadius:"0%",padding:'0px'}}><img src={require('../../assets/icons/login.png')} /></button></Tooltip>
                                    {/* : null } */}
                                </>
                            </>
                        }
                        </Grid>
                       
                        <Grid>
                        {getPasswordString(row.original.password).length > 0 ? 
                        <>
                                <Tooltip title="View 3B" arrow><Button onClick={()=>window.open('/view3BReport/' + btoa(row.original.email)+"/"+ row.original.password,'_blank')} sx={{padding:'0px'}}><img src={require('../../assets/icons/view3B.png')} /></Button></Tooltip>

                        </>
                            // <Tooltip title="View 3B" arrow><Link to={config.API_URL+'/member/view3B/'+btoa(row.original.email)+'/'+row.original.password} target="_blank"><img src={require('../../assets/icons/view3B.png')} /></Link></Tooltip>
                        : 
                        <>
                            {/* {user.roles_id != 5  */}
                            {/* ?  */}
                            {/* // <Tooltip title="View 3B" arrow><Button onClick={()=>fetchReport(row.original.email,atob(row.original.password))}><img src={require('../../assets/icons/view3B.png')} /></Button></Tooltip> */}

                                <Tooltip title="View 3B" arrow><button onClick={() => { handleClickPassOpen(`${row.original.member_id}`,`${row.original.email}`,`${row.original.aid}`, "3B" )}} style={{border:"none",background:"none",borderRadius:"0%",padding:'0px'}}><img src={require('../../assets/icons/view3B.png')} /></button></Tooltip>
                            {/* : null } */}
                        </>
                        }
                        </Grid>

                        {
                        
                            <Grid>
                            {getPasswordString(row.original.password).length > 0 ? 
                                <>
                                    <form method="POST" action={config.API_URL+'/api/member/epicproreport'} target="_blank">
                                        <input type="hidden" name="companyId" value={company.id} />
                                        <input type="hidden" name="username" value={btoa(row.original.email)} />
                                        <input type="hidden" name="password" value={row.original.password} />   
                                        <Tooltip title="View Epic Pro Report" arrow><button type='submit' style={{border:"none",background:"none",borderRadius:"0%",padding:'0px'}}><img src={require('../../assets/icons/EPR.png')} /></button></Tooltip>
                                    </form>
                                </>
                                
                                // <Tooltip title="View Epic Pro Report" arrow>
                                //     <Link to={config.API_URL+'/member/epicproreport/'+company.id+'/'+btoa(row.original.email)+'/'+row.original.password} target="_blank"><img src={require('../../assets/icons/EPR.png')} /></Link> 
                                // </Tooltip>
                            :
                            <>
                                {/* {user.roles_id != 5  */}
                                {/* ?  */}
                                    <Tooltip title="View Epic Pro Report" arrow><button onClick={() => { handleClickPassOpen(`${row.original.member_id}`,`${row.original.email}`,`${row.original.aid}`, "Epic Pro" )}} style={{border:"none",background:"none",borderRadius:"0%",padding:'0px'}}><img src={require('../../assets/icons/EPR.png')} /></button></Tooltip>
                                {/* : null } */}
                            </>
                            }
                               
                            </Grid>
                            
                        }
                        <Grid>
                            {
                               row.original.plan == "Sponsored"  && row.original.free_member === null?
                               <>
                                    {row.original.account_status == "ACTIVE" ?
                                    <>
                                        <Tooltip title="Pause" arrow><button onClick={() => { getPuaseOrActiveConfirmation(`${row.original.email}`,"pause")}} style={{border:"none",background:"none",borderRadius:"0%",padding:'0px'}}><img src={require('../../assets/icons/pause.png')} /></button></Tooltip>
                                    </>
                                    :<Tooltip title="Activate" arrow><button onClick={() => { getPuaseOrActiveConfirmation(`${row.original.email}`,"resume")}} style={{border:"none",background:"none",borderRadius:"0%",padding:'0px'}}><img src={require('../../assets/icons/resume.png')} /></button></Tooltip>
                                    }
                               </> 
                               : ''
                            }
                        </Grid>
                        <Grid>
                            {
                               
                                   

                                    getPasswordString(row.original.password).length > 0  && row.original.software_enroll_client_for_member_list == null? 
                                    <>
                                        <Tooltip title="Create linked accont" arrow><AddCircleOutlineIcon onClick={() => { createProdigyAccount(`${row.original.email}`)}}></AddCircleOutlineIcon></Tooltip>
                                    </>
                                    : 
                                    ''
                                    
                                    
                               
                            }
                        </Grid>
                        </Stack>                     
                                     
                        
                    </>
                ),
            },
        ],
    );
    //csv option start here
    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        fileName: 'Member List',
        headers: companyType == "ProdigyType" ?
        ['AID','Name','Email', 'Password','Prodigy Surge Acc. User Name','Prodigy Surge Acc. Password','Enrolled Date','Member Id','Phone No','Account Status','Street Address','City','State','Zip','Publisher Id'] :
        ['AID','Name','Email', 'Password','Enrolled Date','Member Id','Phone No','Account Status','Street Address','City','State','Zip','Publisher Id']
      };
    
      const csvExporter = new ExportToCsv(csvOptions);
      const handleExportData = (memberData) => {
        const exportedData = memberData.map((row) => {
          if(companyType == "ProdigyType") {
            return {
                aid:`${row.original.aid}` || "N/A",
                name:`${row.original.name}`||"N/A",
                email:`${row.original.email}`||"N/A",
                password: getPasswordString(row.original.password)||"N/A",
                puser_name :`${row.original.software_enroll_client_for_member_list ?  row.original.software_enroll_client_for_member_list.linked_account_username :'N/A'}`,
                puser_password :`${row.original.software_enroll_client_for_member_list ?  row.original.software_enroll_client_for_member_list.password :'N/A'}`,
                enrolled_date: Moment(row.original.date_range_filter).format('MM-DD-YYYY H:mm') ||"N/A",
                'Member Id':`${(row.original.member_id) ? (row.original.member_id) : 'N/A'}`,
                'Phone No':`${(row.original.phone_number) ? (row.original.phone_number) : 'N/A'}`,
                'Account Status': `${row.original.account_status}` || "N/A",
                'Street Address':`${(row.original.street_address) ? (row.original.street_address) :  'N/A'}`,
                'City': `${row.original.city}` || "N/A",
                'State':`${row.original.state}` || "N/A",
                'Zip':`${row.original.zip}` || "N/A",
                'Publisher Id':`${row.original.publisher_id}` || "N/A"
              };
        }else{
            return {
                 aid:`${row.original.aid}` || "N/A",
                name:`${row.original.name}`||"N/A",
                email:`${row.original.email}`||"N/A",
                password: getPasswordString(row.original.password)||"N/A",
                enrolled_date: Moment(row.original.date_range_filter).format('MM-DD-YYYY H:mm') ||"N/A",
                'Member Id':`${(row.original.member_id) ? (row.original.member_id) : 'N/A'}`,
                'Phone No':`${(row.original.phone_number) ? (row.original.phone_number) : 'N/A'}`,
                'Account Status': `${row.original.account_status}` || "N/A",
                'Street Address':`${(row.original.street_address) ? (row.original.street_address) :  'N/A'}`,
                'City': `${row.original.city}` || "N/A",
                'State':`${row.original.state}` || "N/A",
                'Zip':`${row.original.zip}` || "N/A",
                'Publisher Id':`${row.original.publisher_id}` || "N/A"
            };
        }
         
        });
        // csvExporter.generateCsv(exportedData);
          // Convert data to CSV format
      const csvContent = convertToCSV(exportedData);
    
      // Create a Blob object from the CSV data
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    
      // Create a download link element
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = csvOptions.fileName + '.csv';
    
      // Append the link to the document body
      document.body.appendChild(link);
    
      // Programmatically click the link to trigger the file download
      link.click();
    
      // Clean up the temporary URL and remove the link from the document
      URL.revokeObjectURL(link.href);
      link.remove();
    };
    
    const convertToCSV = (data) => {
      const headers = csvOptions.headers.join(',') + '\n';
    
      const rows = data.map((row) => {
        return Object.values(row).map((value) => {
          // Handle any necessary formatting or escaping of values
          // For simplicity, we assume all values are already properly formatted
    
          // Wrap the value with double quotes if it contains a comma
          if (typeof value === 'string' && value.includes(',')) {
            return `"${value}"`;
          }
    
          return value;
        }).join(',');
      }).join('\n');
    
      return headers + rows;
    };
    const mobileDialogModalStyles = {
        position: 'absolute',
        left: `${scrollX}px`,
      };
    return (
        <>
            {isLoading ? <Loader /> : <></>}
            <MaterialReactTable
                columns={companyType == "ProdigyType" ? ProdigyColumns : columns}
                data={memberData}
                enableColumnFilterModes
                filterFns={{
                    customSearchFilterFn: (row, id, filterValue) =>
                    row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
                   }} 
                globalFilterFn="customSearchFilterFn" //exact serach filter functionality
                enableRowSelection={false}
                enableColumnFilters={config.DatatableColumnFilter}
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{ columnVisibility, rowSelection }} //pass our managed row selection state to the table to use
                initialState={{
                    showColumnFilters: false,
                    density: 'compact',
                    
                }}
                onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
                positionToolbarAlertBanner="bottom"
                enableDensityToggle={false}
                renderDetailPanel={({ row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                        }}
                    >
                        <TableContainer component={Paper}>
                            <Table className='back'>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>Member Id:</TableCell>
                                    <TableCell>{row.original.member_id ? row.original.member_id : 'N/A' }</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Name:</TableCell>
                                    <TableCell>{row.original.name ? row.original.name : 'N/A' }</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Plan:</TableCell>
                                    <TableCell>{row.original.plan ? row.original.plan : 'N/A'}</TableCell>                                    
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>Phone No:</TableCell>
                                    <TableCell>{row.original.phone_number ? formatPhoneNumber(row.original.phone_number) : 'N/A'}</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Account Status:</TableCell>
                                    <TableCell>{row.original.account_status ? row.original.account_status : 'N/A'}</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Street Address:</TableCell>
                                    <TableCell>{row.original.street_address ? row.original.street_address : 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>City:</TableCell>
                                    <TableCell>{row.original.city ? row.original.city : 'N/A'}</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>State:</TableCell>
                                    <TableCell>{row.original.state ? row.original.state : 'N/A'}</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Zip:</TableCell>
                                    <TableCell>{row.original.zip ? row.original.zip : 'N/A'}</TableCell>
                                </TableRow>                                
                            </Table>
                        </TableContainer>
                    </Box>
                )}
                //top csv option
                renderTopToolbarCustomActions={({ table }) => (
                    <Box
                        sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                    >
                         <Button
                color="primary"
                disabled={table.getPrePaginationRowModel().rows.length === 0}
								//export all rows, including from the next page, (still respects filtering and sorting)
                  onClick={() =>
                    handleExportData(table.getPrePaginationRowModel().rows)
                  }
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                CSV
              </Button>
                        
                    </Box>
                )
                }
            />
            {/* loader code start */}
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                    onClick={handleFreezStop}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}
            {/* edit popup start here */}
    

        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        style={isMobile? mobileDialogModalStyles:null}
        >
        <DialogTitle id="alert-dialog-title" className="back">
            Member
        </DialogTitle>
        <Formik
            initialValues={RequestFormInitialValues}
            validationSchema={updateSchema}
            onSubmit={submitRequest}
        >{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (<>
            <DialogContent>
            {
                operation == "Add" ? 
                <Grid container item xs>
                <span > <Checkbox value={submitCheckBox}  onClick={changeAction}/>  I hereby confirm that {company ? company.company_name+' has': 'we have'} the consent of the member to save her/his credentials on the MyFreeScoreNow platform.</span>
                </Grid>
                : ''
            }
            <Grid container item xs sx={{ mt:2 }}>
               <span >UserName : {values.email}</span>
            </Grid>
            <Grid container item xs>
                <TextField type='hidden' name="email" value={values.email} sx={{ display: 'none' }} />
                <TextField type='hidden' name="aid" value={values.aid} sx={{ display: 'none' }} />
                <TextField
                id="id"
                type="text"
                name='password'
                label="Password"
                fullWidth
                size="small"
                margin="dense"
                value={values.password}
                onChange={handleChange('password')}
                onBlur={handleBlur('password')}
                />
                {touched.password && errors.password ? <div className='error'>{errors.password}</div> : null}
            </Grid>
            </DialogContent>
            <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button variant="contained" color="primary" sx={{ minWidth:isMobile?100: 200 }} onClick={handleClose}>Close</Button>
            <Button variant="contained" color="success" sx={{ minWidth:isMobile?100: 200 }} disabled={submitEnable} onClick={handleSubmit}>{operation} Password</Button>
            </DialogActions>
        </>)}</Formik>
        </Dialog>

        <Dialog
        open={passOpen}
        onClose={handlePassClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        style={isMobile? mobileDialogModalStyles:null}
        >
        <DialogTitle id="alert-dialog-title" className="back">
            {btnName}
        </DialogTitle>
        <Formik
            initialValues={RequestFormInitialValues}
            validationSchema={updateSchema}
            onSubmit={submitRedirectRequest}
        >{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (<>
            <DialogContent>
            {
                operation == "Add" ? 
                <Grid container item xs>
                </Grid>
                : ''
            }
            
            <Grid container item xs>
                <TextField type='hidden' name="email" value={values.email} sx={{ display: 'none' }} />
                <TextField type='hidden' name="aid" value={values.aid} sx={{ display: 'none' }} />
                <FormControl variant="outlined"fullWidth margin="dense">
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                       id="id"
                       name='password'
                       label="Password"
                       value={values.password}
                       onChange={handleChange('password')}
                       onBlur={handleBlur('password')}
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                        
                    />
                     {touched.password && errors.password ? <div className='error'>{errors.password}</div> : null}
                    </FormControl>
               
            </Grid>
            </DialogContent>
            <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button variant="contained" color="primary" sx={{ minWidth:isMobile?100: 200 }} onClick={handlePassClose}>Close</Button>
            <Button variant="contained" color="success" sx={{ minWidth:isMobile?100: 200 }} onClick={handleSubmit}>{btnName}</Button>
            </DialogActions>
        </>)}</Formik>
        </Dialog>

        <Dialog
            open={pauseOpen}
            onClose={(_, reason) => {
                if (reason !== "backdropClick") {
                    setPauseOpen(false)
                }
                }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{status == "pause" ? "Pause" : "Activate"} Member Account</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Are you sure you want to {status == "pause" ? "pause" : "activate"} the account of this member?
            </DialogContentText>
            </DialogContent>
            <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button autoFocus variant="contained" sx={{ minWidth: 100 }} onClick={closePauseDialog} >
                Cancel
            </Button>
            <Button onClick={handleClickPause}  autoFocus variant="contained" color='success' style={{ minWidth: 100 }}>
                Confirm
            </Button>
            </DialogActions>
        </Dialog>
        </>


      
    );
}

export default MemberListData;