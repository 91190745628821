import { addDays, endOfDay, startOfDay, startOfMonth, endOfMonth, addMonths, startOfWeek, endOfWeek,subDays, format } from "date-fns";
import { useState, useEffect } from 'react';

let windowReference = null;
let windowCheckInterval = null;

export function calculateNetRevenue(rowData) {
	return rowData ? rowData.allAidfinalAmount ? rowData.allAidfinalAmount : 0 : 0;
}

export function calculateRevenueAfterCommission(rowData) {
	return rowData.allAidfinalAmount ? (rowData.allAidfinalAmount - rowData.allAidcomputedCommission).toFixed(2) : 0;
}

export function calculateTotalProductCost(rowData) {
	let total = 
	(rowData.costDetails ?
		(
			(rowData.costDetails.ACTION ? (rowData.costDetails.ACTION.total * 0.3) : 0) 
			+ 
			(rowData.costDetails.CREDIT_MONITORING  ? (rowData.costDetails.CREDIT_MONITORING.total * 0.1) : 0)  
			+
			(rowData.costDetails.CREDIT_REPORT ? (rowData.costDetails.CREDIT_REPORT.total * 0.3) : 0)  
			+ 
			(rowData.costDetails.CREDIT_SCORE  ? (rowData.costDetails.CREDIT_SCORE.total * 0.06) : 0)  
			+ 
			(rowData.costDetails.ID_FRAUD_INSURANCE  ? (rowData.costDetails.ID_FRAUD_INSURANCE.total * 0.25) : 0)  
			+ 
			(rowData.costDetails.IDMA ? (rowData.costDetails.IDMA.total * 0.5) : 0)  
			+ 
			(rowData.costDetails.MONEY_MANAGER  ? (rowData.costDetails.MONEY_MANAGER.total * 0.75) : 0) 
			+ 
			(rowData.costDetails.PRIVACY ? (rowData.costDetails.PRIVACY.total * 0.15) : 0)  
			+ 
			(rowData.costDetails.SSN_LOOKUP  ? (rowData.costDetails.SSN_LOOKUP.total * 0.15) : 0)  
			+ 
			(rowData.costDetails.THREE_BUREAU_REPORT ? (rowData.costDetails.THREE_BUREAU_REPORT.total * 9.00) : 0)
		).toFixed(2) 
	: 0);

	return total;
}

export function calculateLicenseFees (rowData) {
	return rowData.allAidPaymentCount ? (rowData.allAidPaymentCount * 1.75).toFixed(2) : 0;
}

export function calculateGrossProfitValue(rowData) {
	return ( calculateRevenueAfterCommission(rowData) - calculateLicenseFees(rowData) - calculateTotalProductCost(rowData) ).toFixed(2)
}
export function calculateGrossProfitPercentage(rowData){
  return (calculateNetRevenue(rowData)) !== 0 ?
						( ( calculateGrossProfitValue(rowData) / calculateNetRevenue(rowData) ) * 100 ).toFixed(1)
					: 0;
}

export function currencyFormat(num) {
	if (num == 0) {
        return '$0.00';
    }
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const openOrFocusWindow = (url) => {
	const isWindowAlreadyOpen = localStorage.getItem('CallHippoDialer');

	if (!windowReference || windowReference.closed) {
		if (!isWindowAlreadyOpen) {
			windowReference = window.open(url, 'CallHippoDialer');
			windowReference.focus();
			localStorage.setItem('CallHippoDialer', 'true');

			// Start interval to check if window is closed
			if (!windowCheckInterval) {
				windowCheckInterval = setInterval(() => {
					if (windowReference && windowReference.closed) {
						clearInterval(windowCheckInterval);
						windowCheckInterval = null;
						localStorage.removeItem('CallHippoDialer');
						windowReference = null;
					}
				}, 1000); // Check every second
			}
		} else {
			alert('The window is already open in another tab.');
		}
	} else {
		windowReference.location.href = url;
		windowReference.focus();
	}

	// Ensure that the event listener is added only once
	if (!window.unloadEventAdded) {
		window.addEventListener('beforeunload', () => {
			if (windowReference && !windowReference.closed) {
				windowReference.close();
			}
			localStorage.removeItem('CallHippoDialer');
		});
		window.unloadEventAdded = true;
	}
};

// Ensure windowReference is managed across multiple invocations
window.addEventListener('storage', (event) => {
	if (event.key === 'CallHippoDialer' && !event.newValue) {
		windowReference = null;
	}
});

const defined = {
	startOfWeek: startOfWeek(new Date()),
	endOfWeek: endOfWeek(new Date()),
	startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
	endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
	startOfToday: startOfDay(new Date()),
	endOfToday: endOfDay(new Date()),
	startOfYesterday: startOfDay(addDays(new Date(), -1)),
	endOfYesterday: endOfDay(addDays(new Date(), -1)),
	startOfMonth: startOfMonth(new Date()),
	endOfMonth: endOfMonth(new Date()),
	startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
	endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
};

export const calendarFilter = () => {
	const customDateObjects = [
		{
			label: "Today",
			range: () => ({
				startDate: defined.startOfToday,
				endDate: defined.endOfToday
			})
		},
		{
			label: "Yesterday",
			range: () => ({
				startDate: defined.startOfYesterday,
				endDate: defined.endOfYesterday
			})
		},
		{
			label: "This Week",
			range: () => ({
				startDate: defined.startOfWeek,
				endDate: defined.endOfToday
			})
		},
		{
			label: "Last Week",
			range: () => ({
				startDate: defined.startOfLastWeek,
				endDate: defined.endOfLastWeek
			})
		},
		{
			label: "This Month",
			range: () => ({
				startDate: defined.startOfMonth,
				endDate: defined.endOfToday
			})
		},
		{
			label: "Last Month",
			range: () => ({
				startDate: defined.startOfLastMonth,
				endDate: defined.endOfLastMonth
			})
		},
	];

	return customDateObjects;
};

export const getInitialSelectionRange = (page) => {
	if (page == 'true') {
        return [
            {
                startDate: '',
                endDate: '',
                key: 'selection'
            }
        ];
    } else if(page == 'abandon')
	{
		return [
			{
				startDate: subDays(new Date(), 8),
				endDate: subDays(new Date(), 2),
				key: 'selection'
			}
		];
	} else if(page == 'performanceDashboard')
	{
		return [
			{
				startDate: new Date(),
				endDate: new Date(),
				key: 'selection'
			}
		];
	}else 
	{
		return [
			{
				startDate: subDays(new Date(), 6),
				endDate: new Date(),
				key: 'selection'
			}
		];
    }
};

export const useDateSelectionRange = (initialRange = getInitialSelectionRange()) => {
    const [selectionRange, setSelectionRange] = useState(initialRange);
	const initialStartDate = selectionRange[0].startDate ? format(selectionRange[0].startDate, 'MM-dd-yyyy') : '';
    const initialEndDate = selectionRange[0].endDate ? format(selectionRange[0].endDate, 'MM-dd-yyyy') : '';
    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(initialEndDate);
	
	useEffect(() => {
        if (selectionRange[0].startDate && selectionRange[0].endDate) {
            setStartDate(format(selectionRange[0].startDate, 'MM-dd-yyyy'));
            setEndDate(format(selectionRange[0].endDate, 'MM-dd-yyyy'));
        } else {
            setStartDate('');
            setEndDate('');
        }
    }, [selectionRange]);


	const clearFilter = () => {
		setStartDate('');
        setEndDate('');
	  }

    return {
        selectionRange,
        setSelectionRange,
        startDate,
        endDate,
		clearFilter
    };
};
