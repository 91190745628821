
import React, { useMemo, useRef, useState, useEffect, } from 'react';
import { useParams, useNavigate, useLocation, json } from "react-router-dom";
import DefaultLayout from '../../../Components/DefaultLayout';
import AuthUser from '../../../Components/Auth/AuthUser';
import ViewBankForm from '../../../Components/BankDetail/ViewBankForm';
import Moment from 'moment';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
let htmlData ='';

function BankDetailsForm(props) {
    const dispatch = useDispatch();
    const { http, user } = AuthUser();
    const { id } = useParams();
    const [value, setValue] = useState();
    const navigate = useNavigate();
    const [bank_details, setBankDetails] = useState([]);  
    const [updatedAttributes, setUpdatedAttributes] = useState([]);
    const [companyName, setCompanyName] = useState('');
    const [operation, setOperation] = useState("Update");
    const [btntext, setBtntext] = useState("Update");
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [currentDate, setCurrentDate] = useState("");
    const [bankName, setBankName] = useState("");
    const [routingNumber, setRoutingNumber] = useState("");
    //set max date
    const myDate = new Date();
    myDate.setDate(myDate.getDate());
    let cDate = Moment(myDate).tz('America/Los_Angeles').format('MM-DD-YY');
    //loader function and variable start
    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    //loader function and variable end
    //report issue functions and variable start
    var [reportissue,setReportIssue] = useState();
    var [subject,setSubject] = useState("MFSN Bank details Incorrect (ACH Issue)");
    const [routingNumberError, setRoutingNumberError] = useState(true);
    const [routingNumberPreviousValue, setRoutingNumberPreviousValue] = useState(false);
   
    const [mailBody,setMailBody] = useState();
    //report issue functions and variable end
    //history table variable start
    const [filterhistory, setFilterHistory] = useState([]);
    //history table variable end
    //modal variable and function start
    const [firstlable, setFirstLable] = useState(false);

    //Call Function if load this page
    useEffect(() => {
        setCurrentDate(cDate);
        fetchCompanyBankDetails();
    }, [])

    const fetchCompanyBankDetails = async() => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id >= 3 && loginUser.roles_id <= 5) {
            if(loginUser.company_master_id != id) {
                navigate('/login');
            }
        }
        handleFreezStart();
        const apiUrl = '/fetch-company-bank-details/' + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res) {
           handleFreezStop();
            setCompanyName(res.companyName);
            if (res.companyBankDetails != undefined) {
                setBankDetails(res.companyBankDetails);
                // if(res.updated_attributes.length > 0) {
                    setUpdatedAttributes(res.companyBankDetails.updated_attributes);
                // }
                htmlData = `
                <p>Dear ${ res.companyBankDetails.company_name },</p>
                <p>The bank details you provided could not be verified with your bank. Please login to your affiliate account and update your account information in bank details.</p>
                <p>If you have any questions or if there is anything we can do to help you, please feel free to contact us:</p>
                <p>info@myfreescorenow.com</p>
                <p888-548-2008</p>
                <p>Thank You!</p>`;
                setMailBody(htmlData);

                setValue(bank_details.last_ach_updated);
                // fetchCompanyBankDetailsHistory(res.id);
                setFilterHistory(Object.values(res.updatedAllAttributes));
                if (res.companyBankDetails.report_issue == 1) {
                    if(user.roles_id < 3) {
                        setBtntext("Update And Resolve")
                    }else{
                        setBtntext("Update")
                    }  
                }else{
                    setBtntext("Update")
                }

                if (res.companyBankDetails.bank_name == null || res.companyBankDetails.bank_name == undefined) {
                    setFirstLable(true);
                }
                setOperation("Update");
                if(res.companyBankDetails.routing_number) {
                    var routingNumberOriginal = res.companyBankDetails.routing_number;
                    var routingNumberUpdated = res.companyBankDetails.routing_number_updated;
                    var bankNameOriginal = res.companyBankDetails.bank_name;
                    const apiUrl = "/bankname/"+ routingNumberOriginal;
                    if(routingNumberOriginal && routingNumberOriginal.length == 9) {
                        const res = await dispatch(fetchData(apiUrl)).unwrap();
                        if(res.success) {
                            setBankName(res.data);
                            setRoutingNumberError(true);
                            if(routingNumberUpdated == 1) {
                                setRoutingNumberError(true);
                                setRoutingNumber(routingNumberOriginal);
                                setBankName(bankNameOriginal);
                                setRoutingNumberPreviousValue(true);
                            }
                        }else{
                            setBankName(bankNameOriginal);
                            if(routingNumberUpdated == 1) {
                                setRoutingNumberError(true);
                                setRoutingNumber(routingNumberOriginal);
                                setRoutingNumberPreviousValue(true); 
                            }else{
                                setRoutingNumberError(false);
                            }
                        }
                    }
                }
            } else {
                setOperation("create");
                setBtntext("Submit")
            }
        } 
    };

    function ContentLayout() {
    return (
        <>
            <ViewBankForm bankDetails={bank_details} updatedAttributes={updatedAttributes} filterhistory={filterhistory} fetchCompanyBankDetails={fetchCompanyBankDetails} btntext={btntext} htmlData={htmlData} currentDate={currentDate} companyName={companyName} operation={operation} bankName={bankName} routingNumberErr={routingNumberError} routingNumberOriginal={routingNumber} routingNumberPreviousValue={routingNumberPreviousValue}/>  
        </>
    );
    }

    return (
        <div>
            <DefaultLayout content={<ContentLayout />} />
            {/* loader code start */}
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}
        </div>
    );
}

export default BankDetailsForm;