import React, { useState, useEffect,useRef,forwardRef,useImperativeHandle} from "react";
import {
    Modal,
    List, Paper, Box,
    ListItem,
    ListItemText, Grid, Button, Tooltip, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Stack, Table, TableBody, TableCell, TableRow, TextField, CardContent
  } from '@mui/material';
  import { Typography, Sheet, Card, CardOverflow } from '@mui/joy';
  import IconButton from '@mui/material/IconButton';
  import CloseIcon from '@mui/icons-material/Close';
  import SendIcon from "@mui/icons-material/Send";
  import axios from "axios";
  import config from "../../AppConfig";
  import AuthUser from "../Auth/AuthUser";
  import MailOutlineIcon from '@mui/icons-material/MailOutline';
  import swal from "sweetalert";

  let emailValue = "";
  let role = null;
  let chatSessionId = null;

const Chat =  ({ mEmail, firstName,isMinimized,changeOpenChatPopupValue,openEmailChatPopup,companyId,source }) => {
    const { http } = AuthUser();
    const [input, setInput] = useState("");
    const[messages, setMessages] = useState([]);
    const [isWarningDisplayed, setIsWarningDisplayed] = useState(false);
    const isWarningDisplayedRef = useRef(false);
    const [isSessionCleared, setIsSessionCleared] = useState(false);
    const [sessionId, setSessionId] = useState(localStorage.getItem("chatSessionId") != undefined ? localStorage.getItem("chatSessionId")  : null);
    const [isSetMsg, setIsSetMsg] = useState(false);
    const [outOfOffc, setOutOfOffc] = useState(false);
    const messagesEndRef = useRef(null);
    const [minimized, setMinimized] = useState(isMinimized);
    const [memberEmail, setMemberEmail] = useState(mEmail);
    const [Fname, setFname] = useState(firstName);
    const [affName, setAffName] = useState();
     const [inputEmail, setInputEmail] = useState("");
    
    useEffect(() => {
        scrollToBottom();
    }, [messages,isSessionCleared, outOfOffc]);

    useEffect(() => {
        isWarningDisplayedRef.current = isWarningDisplayed;
    }, [isWarningDisplayed]);

    useEffect(() => {
        var sessionMessages2 = localStorage.getItem('sessionMessages');
        if((sessionMessages2 == null || sessionMessages2 == undefined) && (minimized === false) && memberEmail) {
        setMessages([
            { text: "Hello "+`${Fname != null ? Fname : 'Friend'}`+", Thank You For Reaching Out.", type: "bot" },
        ]);
          var sessionMessage1 = ([
            { text: "Hello "+`${Fname != null ? Fname : 'Friend'}`+", Thank You For Reaching Out.", type: "bot" },
          ]);
          localStorage.setItem("sessionMessages",JSON.stringify(sessionMessage1));
        }else if(localStorage.getItem('chatSessionId') == undefined && sessionMessages2 != undefined && memberEmail) {
            setMessages([
              { text: "Hello "+`${Fname != null ? Fname : 'Friend'}`+", Thank You For Reaching Out.", type: "bot" },
            ]);
            var sessionMessage1 = ([
              { text: "Hello "+`${Fname != null ? Fname : 'Friend'}`+", Thank You For Reaching Out.", type: "bot" },
            ]);
          localStorage.setItem("sessionMessages",JSON.stringify(sessionMessage1));
        }

        chatSessionId = localStorage.getItem('chatSessionId');
        if(chatSessionId != null){
            const sessionMessage1 = localStorage.getItem("sessionMessages");
            if (sessionMessage1) {
                const parsedMessages = JSON.parse(sessionMessage1);
                setMessages(parsedMessages);
            }
        }
       
    }, [minimized, Fname, memberEmail]);


    useEffect(() => {
        // if (props.email) {
          
          
          const fetchNewMessages = async () => {
          try {
            chatSessionId = localStorage.getItem('chatSessionId');
            emailValue = localStorage.getItem('chatbot_email');
            if(chatSessionId != null && (emailValue == memberEmail)) {
              if (!localStorage.getItem("lastMessageTime")) {
                localStorage.setItem("lastMessageTime", Date.now());
              }

              const sessionMesgs = JSON.parse(localStorage.getItem('sessionMessages'));
              if (sessionMesgs && Array.isArray(sessionMesgs) && sessionMesgs.length > 0) {
                sessionMesgs.forEach(msg => {
                  if (msg.type === "outofoffice") {
                    setOutOfOffc(true);
                  }
                });
              }

              const response = await http.get(`/fetchNewMessages/${chatSessionId}/member/${source}`);
              if (response.data.success) {
                  const newMessages = response.data.data.map(item => ({
                      text: item.message,
                      type: item.is_affiliate === '1' ? "bot" : item.is_affiliate === '2' ? "outofoffice" :  "user",
                  }));
                  response.data.data.map(elem => {
                    if(elem.is_affiliate === '2' ){
                      setOutOfOffc(true);
                    }else{
                      setOutOfOffc(false);
                    }
                  })
    
                  if (newMessages.length > 0) {
                    localStorage.setItem('lastMessageTime', Date.now());
                    var sessionMessagesObj = localStorage.getItem("sessionMessages"); 
                    var sessionMessage1 = JSON.parse(sessionMessagesObj);
    
                    if (response.data.sessionObj && response.data.sessionObj.affiliate_name != null) {
                      const affiliateName = response.data.sessionObj.affiliate_name;
                      setAffName(affiliateName);
                      const joinMessageExists = sessionMessage1.some(
                        (msg) => msg.text === `${affiliateName} has joined the chat`
                      );
                  
                      if (!joinMessageExists) {
                        const joinMessage = {
                          text: `${affiliateName} has joined the chat`,
                          type: "info", 
                        };
                        
                        sessionMessage1.push(joinMessage);
                        localStorage.setItem("sessionMessages", JSON.stringify(sessionMessage1));
                        setMessages((prevMessages) => [...prevMessages, joinMessage]);
                      }
                    }
                    newMessages.forEach((message) => {
                      sessionMessage1.push(message);
                    });
    
                    localStorage.setItem("sessionMessages",JSON.stringify(sessionMessage1)); 
                    setMessages((prevMessages) => [...prevMessages.filter((msg) => msg.type !== "warning"), ...newMessages]);
                  }
                  if (response.data.sessionObj && response.data.sessionObj.is_expired == 1)
                  {
                      localStorage.removeItem("lastMessageTime");
                      localStorage.removeItem('chatbot_email');
                      // localStorage.removeItem('sessionMessages');
                      localStorage.setItem("lastSessionId", chatSessionId);
                      localStorage.removeItem('chatSessionId');
                      setIsSessionCleared(true);
                  }else if(response.data.sessionObj && response.data.sessionObj.is_expired == 0){
                      setIsSessionCleared(false);
                  }
              }
            }
          
          } catch (error) {
            console.error("Error fetching new messages:", error);
          }
          };
        
          const interval = setInterval(() => {
              fetchNewMessages();
          }, 10000);
    
          
          const inactivityCheck = setInterval(() => {
            if( !outOfOffc && !isSessionCleared){
              const lastMessageTime = localStorage.getItem("lastMessageTime");
              if (lastMessageTime) {
                const timeElapsed = Date.now() - lastMessageTime;
                if (timeElapsed > 2 * 60 * 1000 && !isWarningDisplayedRef.current) {
                  setIsWarningDisplayed(true);
                  isWarningDisplayedRef.current = true;
                  // Add inactivity warning to chat
                  const inactivityWarning = {
                    text: "You have been inactive for 2 minutes. Do you want to continue the chat?",
                    type: "warning",
                    actions: [
                      { label: "Yes", action: "continueSession" },
                      { label: "No", action: "clearSession" },
                    ],
                  };
                  setMessages((prevMessages) => [...prevMessages, inactivityWarning]);
                }
                if (timeElapsed > 5 * 60 * 1000) { 
                  localStorage.removeItem("lastMessageTime");
                  handleClearSession(1);
                }
              }
            }
          }, 10000);
          return () => {clearInterval(interval); clearInterval(inactivityCheck);}; // Cleanup interval on component unmount
        // }
    }, [memberEmail,isSessionCleared,outOfOffc]);

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
          messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const sendChatHistory = async() => {
      var sessionId = localStorage.getItem('lastSessionId');
      const response2 = await axios.get(
        `${config.API_BASE_URL}/downloadChatHistroy/${sessionId}`
      );
      if (response2.data.success == true) {
        swal({
          title: "Success",
          text: response2.data.message,
          icon: "success",
          button: "Ok",
        })
      }
    };

    const handleSendMessage = async() => {
        chatSessionId = localStorage.getItem('chatSessionId');
        setIsSetMsg(true);
        if(chatSessionId == null) {
            localStorage.setItem('chatbot_email', memberEmail ? memberEmail : '');
            localStorage.setItem('first_name', Fname ? Fname : '');

            const response = await axios.post(
            config.API_BASE_URL + "/initialize-chat-session",
            { email: memberEmail, role: role, member_name: Fname, companyId : companyId,source : source}
            );
            setSessionId(response.data.session_id);
            localStorage.removeItem("lastMessageTime");
            localStorage.setItem("chatSessionId", response.data.session_id);
        }
        chatSessionId = localStorage.getItem('chatSessionId');
        if (input.trim() && chatSessionId) {
            var sessionMessage1 = [...messages];
            var sessionMessagesObj = localStorage.getItem("sessionMessages"); 
            var sessionMessage1 = JSON.parse(sessionMessagesObj);
            sessionMessage1.push({ text: input, type: "user" });
            localStorage.setItem("sessionMessages",JSON.stringify(sessionMessage1));  

            setMessages([...messages, { text: input, type: "user" }]);
            setInput("");
        
            const response2 = await axios.post( 
            config.API_BASE_URL + "/saveMemberMessage", { message: input, session_id: chatSessionId, email : memberEmail });
            
        }
        setIsSetMsg(false);
      };

   

    const handleClearSession = async(expired=0) => {
        var sessionId = localStorage.getItem('chatSessionId');
        const response2 = await axios.post(
            `${config.API_BASE_URL}/clearSession/${sessionId}`,{expired:expired,reason:"End Session due to inactivity"}
        );
        if (response2.data.success == true) {
            // if(isSessionCleared)
            // {
            setIsSessionCleared(true);
            // }else {
            // setIsSessionCleared(false);
            // }
            localStorage.removeItem('chatbot_email');
            // localStorage.removeItem('sessionMessages');
            localStorage.setItem("lastSessionId", sessionId);
            localStorage.removeItem('chatSessionId');
            localStorage.removeItem('lastMessageTime');
        }
    };
    
    const handleSessionContinue = () => {
        localStorage.setItem("lastMessageTime", Date.now()); // Update last message time
        setMessages((prevMessages) =>
            prevMessages.filter((msg) => msg.type !== "warning")
        );
        setIsWarningDisplayed(false);
    
    };

    const handleSessionClear = () => {
    localStorage.removeItem("lastMessageTime");
    handleClearSession(1); // Clear session
    setIsWarningDisplayed(false);
    };

    useEffect(() => {
        setMinimized(isMinimized);
    }, [isMinimized]);

    useEffect(() => {
        setFname(firstName);
    }, [firstName]);

    useEffect(() => {
        setMemberEmail(mEmail);
    }, [mEmail]);
    const [emailValid, setEmailValid] = useState(true);
    const validateEmail = (email) => {
      const emailRegex = /^[A-Z0-9._-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      return emailRegex.test(email);
  };
  const handleEmailChange = (e) => {
    const email = e.target.value;
    setInputEmail(email);
    setEmailValid(validateEmail(email));
  };
    return (
        <>
        <Grid  id="chatbotDiv">
        {minimized ? null : (
            <Grid 
                container
                justifyContent="flex-end"
                alignItems="flex-end"
                sx={{ position: "fixed", width: "100%", zIndex: 1000, bottom: "0%", padding: "1%",left: 0 }}
            >
                <Grid item xs={12} sm={8} md={6} lg={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Card sx={{
                        boxShadow:
                            "0 0px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 12px 0 rgba(0, 0, 0, 0.19)",
                        borderRadius: "0px"
                    }}>
                        <CardOverflow sx={{ backgroundColor: "#435AC2", borderRadius: "0px", padding: "3%", cursor: "pointer" }}
                        onClick={() => {
                            if (minimized) {
                                changeOpenChatPopupValue(false); // Expand the chatbot when clicking on CardOverflow
                              setTimeout(() => {
                                messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
                              }, 0);
                            }
                          }}>
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ alignItems: "center" }}>
                                <Grid item lg={1} md={1} sm={1} xs={1} sx={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                    <img src={require("../../assets/images/chatforbutton.png")}
                                        alt="Chat Icon"
                                        style={{ width: "35px", height: "35px" }} />
                                </Grid>
                                <Grid item lg={9} md={9} sm={9} xs={9}>
                                    <Typography level="h1" sx={{ color: "white !important", fontSize: 'xl', mb: 0.5 }}> <b> &nbsp;Chat With Us!</b></Typography>
                                </Grid>
                                <Grid item lg={2} md={2} sm={2} xs={2} sx={{ textAlign: "right" }}>
                                    <IconButton 
                                    onClick={() => changeOpenChatPopupValue(!minimized)}
                                    >
                                        <CloseIcon sx={{ color: "white", fontSize: 'xl' }} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </CardOverflow>
                        {!minimized && (
                        <CardContent>
                            {/* Message Area */}
                            {memberEmail != null ? (
                            <>
                            <Box
                                sx={{
                                    maxHeight: 300,
                                    minHeight: 300,
                                    overflowY: "auto",
                                    // p: 1,
                                    // border: "1px solid #ddd",
                                    borderRadius: 2,
                                    // mt: 2,
                                    // backgroundColor: "#f9f9f9",
                                }}
                            >
                                <List>
                                    {messages.map((message, index) => (
                                        <ListItem
                                            key={index}
                                            sx={{
                                            justifyContent:
                                                message.type === "user"  ? "flex-end"  : message.type === "info" ? "center" : message.type === "warning" ? "center" :"flex-start",
                                            }}
                                        >
                                            <Paper
                                            sx={{
                                                p: '5px 15px',
                                                backgroundColor:
                                                message.type === "user" ? "#1976d2" : message.type === "info" ? "#e0f7fa" : message.type === "warning" ? "#fff8e1" :"#eee",
                                                color: message.type === "user" ? "#fff" : message.type === "info" ? "#00796b" : message.type === "warning" ? "#ff9800" : "#000",
                                                borderRadius: 2,
                                            }}
                                            >
                                            <ListItemText primary={message.text} />
                                            {message.type === "warning" && (
                                                <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
                                                {message.actions.map((action, idx) => (
                                                    <Button
                                                    key={idx}
                                                    variant="contained"
                                                    size="small"
                                                    onClick={() => {
                                                        if (action.action === "continueSession") handleSessionContinue();
                                                        if (action.action === "clearSession") handleSessionClear();
                                                    }}
                                                    disabled={isSessionCleared}
                                                    sx={{
                                                        backgroundColor: action.action === "continueSession" ? "#4caf50" : "#f44336",
                                                        color: "#fff",
                                                    }}
                                                    >
                                                    {action.label}
                                                    </Button>
                                                ))}
                                                </Box>
                                            )}
                                            </Paper>
                                        </ListItem>
                                        ))}
                                        {isSessionCleared && (
                                        <ListItem sx={{ justifyContent: "center" }}>
                                            <Paper
                                            sx={{
                                                p: 1,
                                                backgroundColor: "#e0f7fa",
                                                color: "#00796b",
                                                borderRadius: 2,
                                            }}
                                            >
                                            <ListItemText
                                                primary={`The session has been ended`}
                                                primaryTypographyProps={{
                                                align: "center",
                                                fontSize: "0.9rem",
                                                fontWeight: "bold",
                                                }}
                                            />
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              sx={{
                                                marginTop: 2,
                                                padding: "10px 20px",
                                                backgroundColor: "#00796b", // Custom background color
                                                "&:hover": {
                                                  backgroundColor: "#004d40", // Darker shade on hover
                                                },
                                              }}
                                              onClick={() => {
                                                // Handle the button click (you can add your logic here)
                                                sendChatHistory();
                                              }}
                                            >
                                              <MailOutlineIcon sx={{ marginRight: 1 }} /> Send Chat to Mail
                                            </Button>
                                            </Paper>
                                        </ListItem>
                                        )}

                                        {
                                            outOfOffc && (
                                            <ListItem sx={{ justifyContent: "center" }}>
                                                <Button  startIcon={<img src={require("../../../src/assets/images/Emailforbutton.png")} alt="icon" style={{ width: 30, height: 30 }} />}
                                                onClick={openEmailChatPopup}
                                                sx={{
                                                textTransform: "none",
                                                border:"1px solid #2E67BC",
                                                minWidth:'80%' ,
                                                maxWidth:'100%',
                                                color:"black",
                                                alignItems:"center",
                                                fontWeight:"bold" // Prevents uppercase transformation
                                                }}>Email Us Now</Button>
                                            </ListItem>
                                            )
                                        }
                                    <div ref={messagesEndRef}></div>
                                </List>
                            </Box>
                            {/* Input Area */}
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    mt: 2,
                                }}
                            >
                                <TextField
                                    fullWidth
                                    placeholder="Type your message..."
                                    value={input}
                                    onChange={(e) => setInput(e.target.value)}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter" && input.trim()) handleSendMessage();
                                      }}
                                    sx={{ mr: 1 }}
                                />
                                <IconButton
                                    // sx={{ border: "1px solid #3779eb", borderRadius: "6px", backgroundColor: "#435AC2 !important", color: "white", padding: "2.3%" }}
                                    sx={{ border: "1px solid #3779eb", borderRadius: "6px", backgroundColor: isSessionCleared || outOfOffc || !input.trim() ? "#ccc !important" : "#435AC2 !important", color: "white", padding: "2.3%" }}
                                    onClick={handleSendMessage}
                                    disabled={(isSessionCleared || outOfOffc || !input.trim()) || isSetMsg} 
                                >
                                    <SendIcon sx={{ fontSize: "1.7rem" }} />
                                </IconButton>
                            </Box>
                            </> ) :
                            (
                                <>
                                <h3>Please provide your email below to start chatting with us.</h3>
                                <input
                                    type="email"
                                    placeholder="Enter your email"
                                    value={inputEmail}
                                    onChange={handleEmailChange}
                                    style={{
                                    padding: "10px",
                                    fontSize: "16px",
                                    width: "300px",
                                    marginBottom: "20px",
                                    borderRadius: "5px",
                                    border: "1px solid #ccc",
                                    }}
                                />
                                <button
                                    onClick={() => {
                                    if (inputEmail.trim() !== "" && emailValid) {
                                        setMemberEmail(inputEmail); // Save email and show chat UI
                                    } 
                                    // else if(inputEmail.trim() !== ""){
                                    //     alert("Please enter a valid email!");
                                    // }
                                    }}
                                    style={{
                                    marginLeft: "10px",
                                    padding: "10px",
                                    backgroundColor: "#1976d2",
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                    }}
                                >
                                    Submit
                                </button>
                                <br/>
                                {!emailValid && <span style={{ color: "red" }}>Please enter a valid email address.</span>}
                                </>
                            )}
                        </CardContent>
                        )}
                    </Card>
                </Grid>
            </Grid>
        )}
        </Grid>

        {/* Chatbox icon */}
        {/* {chatOpen && (
              <Box
                  sx={{
                    position: "fixed",
                    bottom: "2%",
                    right: "2%",
                    cursor: "pointer",
                  }}
                  onClick={openChatPopupComponent}
                >
                  <img
                    src={require("../../../src/assets/images/chatforbutton.png")}
                    alt="Chat Icon"
                    style={{
                      width: "70px",
                      height: "70px",
                      borderRadius: "50%",
                      boxShadow:
                        "0px 4px 8px rgba(0, 0, 0, 0.2), 0px 8px 16px rgba(0, 0, 0, 0.19)",
                    }}
                  />
                </Box>
                )} */}
        </>
    )
}
export default Chat;