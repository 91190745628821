import React, { useEffect, useState, useRef, } from 'react';
import { Grid, Divider} from '@mui/material';
import { Typography, Card, CardContent} from '@mui/joy';
import StepButton from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepConnector, {
    stepConnectorClasses,
} from "@mui/material/StepConnector";
import LoginHeader from '../LoginHeader';
import { styled } from '@mui/system';
import NewEnrollment1 from './NewEnrollment1';
import NewEnrollment2 from './NewEnrollment2';
import NewEnrollment4 from './NewEnrollment4';
import NewEnrollment3 from './NewEnrollment3'
import NewEnrollment5 from './NewEnrollment5'
import { useDispatch } from 'react-redux';
import { fetchData,postData } from '../../redux/apiSlice';
import AuthUser from '../../Components/Auth/AuthUser';
import config from '../../AppConfig'
import Loader from '../../Components/Loader/Loader';
import RegisterFooter from "../../views/Auth/RegisterFooter";
import { useSelector } from 'react-redux';

let pid = "00017";
let aid = "seo";
let sid = "";
let adid= "";
let otpdv = false;
let cobrandActiveFlag = false;
let hideNavigation = 'false';
let isMobileParams = 'false';
let bannerImg1 = require("../../assets/images/defaultImg1.png")
let bannerImg2 = require("../../assets/images/defaultImg2.png")
let bannerImg3 = require("../../assets/images/defaultImg3.png")
let defaultCustomBanner = [
    { step: 1, url: bannerImg1, flag: 0 },
    { step: 2, url: bannerImg2, flag: 0 },
    { step: 4, url: bannerImg3, flag: 0 },
];
let defaultCustomColor = [{ bodyBackground: '#FFFFFF', primaryColor: '#3470B1',hyperlinkColor:'#3470B1',buttonColor:'#3470B1', buttonTextColor:'#FFFFFF' }]
function CustomizeEnrollIndex(props) {
    console.log("propsss",props);
    const { http } = AuthUser();
    const gridRef = useRef(null);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [customColorArrays, setCustomColorArrays] = useState(defaultCustomColor)
    const [croppedImages, setCroppedImages] = useState(defaultCustomBanner);
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const [internFlag, setInternFlag] = useState(false)
    const [offerdetails, setOfferDetails] = useState();
    const queryParameters = new URLSearchParams(window.location.search);
    const [from, setFrom] = useState(queryParameters.has('from') ? queryParameters.get('from') : "");
    const [originfrom, setOriginFrom] = useState(localStorage.getItem("Step"));
    const [stepcount, setStepCount] = useState(originfrom && from == "crsleads" ? originfrom : 1);
    const [textColor, setTextColor] = useState('');
    const [emailEnable, setEmailEnable] = useState(0);
    const[btnTextColor,setBtnTextColor]=useState(props.btnTextColor?props.btnTextColor:'white')
    const steps = ['Register', 'Verify Identity', 'Complete'];
    const [chatEnable, setChatEnable] = useState(props.chatEnabled);
    const [emailUsEnable, setEmailUsEnable] = useState(props.emailEnabled);
    const [companyId, setCompanyId] = useState('');
    const [step1Link, setStep1Link] = useState(props.step1Link);
    const [step2Link, setStep2Link] = useState(props.step2Link);
    const [step3Link, setStep3Link] = useState(props.step3Link);
    

    const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 10,
            left: 'calc(-50% + 20px)',
            right: 'calc(50% + 20px)',
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: customColorArrays[0].primaryColor,
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: customColorArrays[0].primaryColor,
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#d3d3d3',
            borderTopWidth: 3,
            borderRadius: 1,
        },
        // Additional styling for half connector
        [`& .halfLine`]: {
            borderLeftWidth: '50%', // Half width
            borderTopWidth: 3,
            borderStyle: 'solid',
            borderColor: customColorArrays[0].primaryColor, // Adjust as needed
            position: 'absolute',
            top: 0,
            bottom: 0,
        },
    }));

    const handleChildValue = (step) => {
        setStepCount(step)
        if (step == 1 || step == 2) {
            setActiveStep(0)
        }
        else if (step == 4 || step == 5) {
            setActiveStep(2)
        }
        else {
            setActiveStep(1)
        }
    }


    const getEnrollmentCustomizeDetails = async () => {
        if (stepcount == 1 || stepcount == 2) {
            setActiveStep(0)
        }
        else if (stepcount == 4 || stepcount == 5) {
            setActiveStep(2)
        }
        else {
            setActiveStep(1)
        }
        const queryParameters = new URLSearchParams(window.location.search);
        for (let key of queryParameters.keys()) {
            if (key.toLowerCase() === 'aid') {
                aid = queryParameters.get(key);
                if(aid === "Network") {
                    aid = "seo";
                }
            }
            if (key.toLowerCase() === 'pid') {
                pid = queryParameters.get(key);
                if(pid === "15837" || pid === "40674" || pid === "37491") {
                    pid = "00017";
                }
            }
            if (key.toLowerCase() === 'adid') {
                adid = queryParameters.get(key);
            }
            if (key.toLowerCase() === 'sid') {
                sid = queryParameters.get(key);
            }
            if (key.toLowerCase() === 'otpdv') {
                otpdv = queryParameters.get(key);
            }
            if (key.toLowerCase() === 'hidenavigation') {
                hideNavigation = queryParameters.get(key);
            }
            if (key.toLowerCase() === 'ismobile') {
                isMobileParams = queryParameters.get(key);
            }
        }
        setIsLoading(true);
        const apiUrl = '/enrollmentCustomizeDetails/' + aid + '/' + pid;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            if (res.data) {
                setCompanyId(res.data.company_master_id);
                console.log(res.data);
                setCroppedImages(
                    [{ step: 1, url: res.data.hero_image_1 ? config.BACKEND_URL + "images/bannerImages/" + res.data.hero_image_1 : bannerImg1 },
                    { step: 2, url: res.data.hero_image_2 ? config.BACKEND_URL + "images/bannerImages/" + res.data.hero_image_2 : bannerImg2 },
                    { step: 4, url: res.data.hero_image_3 ? config.BACKEND_URL + "images/bannerImages/" + res.data.hero_image_3 : bannerImg3 }]
                );
                setCustomColorArrays(
                    [
                        { bodyBackground: res.data.body_background_color?res.data.body_background_color:customColorArrays[0].bodyBackground, primaryColor: res.data.primary_color?res.data.primary_color:customColorArrays[0].primaryColor, hyperlinkColor: res.data.hyper_link_color?res.data.hyper_link_color:customColorArrays[0].hyperlinkColor,buttonColor: res.data.button_color && res.data.default_setting == 0 ? res.data.button_color : customColorArrays[0].buttonColor,buttonTextColor: res.data.button_text_color && res.data.default_setting == 0 ? res.data.button_text_color : customColorArrays[0].buttonTextColor },
                    ])
                setTextColor(res.data.text_color)
                getContrastText(res.data.primary_color)
                setIsLoading(false);
                setChatEnable(res.data.enable_chat_support == 1? true : chatEnable);
                setEmailUsEnable(res.data.enable_email_support == 1? true : emailUsEnable);
                setCompanyId(res.data.company_master_id)
                setStep1Link(res.data.step_1_link);
                setStep2Link(res.data.step_2_link);
                setStep3Link(res.data.step_3_link);
                // props.getChatData((res.data.enable_chat_support == 1? true : false),(res.data.enable_email_support == 1? true : false));
            }
            else {
                setCustomColorArrays(defaultCustomColor)
                setCroppedImages(defaultCustomBanner)
                setIsLoading(false);
                setTextColor('black')
            }

        }
        getOfferDetails()
    }

    const getOfferDetails =async () => {
        setIsLoading(true);
        const apiUrl = '/getofferdetailsofpid';
        const args = {
			pid: pid,
		}
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if (res) 
        {
            setIsLoading(false);
            if (res.success === true) {
                setOfferDetails(res.data[0])
            } else {
                //    handleCallback("Something went wrong please try after some time")
            }
        }
    }
    useEffect(() => {
        if (stepcount == 1) {
            localStorage.removeItem('customerToken');
            localStorage.removeItem('trackingToken');
            localStorage.removeItem('Main_name');
            localStorage.removeItem('Main_firstName');
            localStorage.removeItem('Main_lastName');
            localStorage.removeItem('Main_middleName');
            localStorage.removeItem('Main_email');
            localStorage.removeItem('Main_password');
            localStorage.removeItem('aid');
            localStorage.removeItem('aid');
            localStorage.removeItem('idQuestions');
            localStorage.removeItem('tempPassword');
            
            localStorage.removeItem('chatbot_email');
            localStorage.removeItem('sessionMessages');
            localStorage.removeItem('chatSessionId');
            localStorage.removeItem('lastMessageTime');
            localStorage.removeItem('UpgradeTo3B');
        }
    }, [])

    useEffect(() => {
        if (props.cobrandFlag == true) {
            if (props.templateValue === 1) {
                setActiveStep(0)
                setStepCount(1)
            }
            else if (props.templateValue === 2) {
                setActiveStep(0)
                setStepCount(2)
            }
            else if (props.templateValue === 3) {
                setActiveStep(1)
                setStepCount(3)
            }
            else if (props.templateValue === 4) {
                setActiveStep(2)
                setStepCount(4)
            }
            else {
                setActiveStep(2)
                setStepCount(5)
            }
        }
    }, [props.templateValue])

    useEffect(() => {
        if (props.croppedImages && Object.keys(props.croppedImages).length > 0) {
            setCroppedImages(
                [{ step: 1, url: props.croppedImages?props.croppedImages[0].url ? props.croppedImages[0].url : bannerImg1: bannerImg1 },
                { step: 2, url: props.croppedImages?props.croppedImages[1].url ? props.croppedImages[1].url : bannerImg2: bannerImg2 },
                { step: 4, url: props.croppedImages?props.croppedImages[2].url ? props.croppedImages[2].url : bannerImg3: bannerImg3 }]
            );
        }

        else { setCroppedImages(defaultCustomBanner) }
    }, [props.croppedImages])

    useEffect(() => {
        if (props.customColorArrays && Object.keys(props.customColorArrays).length > 0) {
            setIsLoading(true)
            setCustomColorArrays(props.customColorArrays)
            setIsLoading(false)
        }

        else { setCustomColorArrays(defaultCustomColor) }
        setIsLoading(false)
    }, [props.customColorArrays])


    useEffect(() => {
        if (props.textColor) {
            setTextColor(props.textColor)
        }
        else {
            setTextColor(textColor)
        }
    }, [props.textColor])

    useEffect(() => {
        if (props.cobrandFlag == true) {
            fetchCorandList()
        } else {
            
            getEnrollmentCustomizeDetails()
            
        }
    }, []);
    const getContrastText = (color) => {
        // Convert background color to RGB
        const rgb = color.match(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i);
        const r = parseInt(rgb[1], 16);
        const g = parseInt(rgb[2], 16);
        const b = parseInt(rgb[3], 16);

        // Calculate brightness using the formula (r * 299 + g * 587 + b * 114) / 1000
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;

        // If brightness is greater than 125, return black, else return white
        brightness > 125 ? setBtnTextColor('black') : setBtnTextColor('white');

    };
 
    const fetchCorandList = async () => {
        setIsLoading(true)
        const apiUrl = '/cobrandingDetails/all'; // Replace with your API endpoint
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            const loginUser = JSON.parse(localStorage.getItem('user'));
            if(loginUser.roles_id == 3) {
                for (let i = 0; i < res.data.length; i++) {
                    const item = res.data[i];
                    if (item.cobrand_details && item.cobrand_details.is_active == 1) {
                        aid = item.aid;
                        if (item.cobrand_details?.cobrand_plan && item.cobrand_details.cobrand_plan.length > 0) {
                            let pidArray=item.cobrand_details.cobrand_plan.find(items => items.is_disabled == 0);
                            pid = pidArray.pid;
                        }
                        else {
                            pid = "00017";
                        }
                        getOfferDetails();
                        cobrandActiveFlag = true;
                        break; // Exit the loop once the condition is met
                    }
                }
                if (!cobrandActiveFlag) {
                    aid='seo';
                    pid='00017'
                  }
               
            }else if(loginUser.roles_id == 1 || loginUser.roles_id == 2) {
                let customizationReviewCompanyId= localStorage.getItem("customizationReviewCompanyId")
                const list= res.data.filter(item => item.company_master_id == customizationReviewCompanyId);
                for (let i = 0; i < list.length; i++) {
                    const item = list[i];
                    if (item.cobrand_details && item.cobrand_details.is_active == 1) {
                        aid = item.aid;
                        if (item.cobrand_details?.cobrand_plan && item.cobrand_details.cobrand_plan.length > 0) {
                            let pidArray=item.cobrand_details.cobrand_plan.find(items => items.is_disabled == 0);
                            pid = pidArray.pid;
                        } else {
                            pid = "00017";
                        }
                        getOfferDetails();
                        cobrandActiveFlag = true;
                        break; // Exit the loop once the condition is met
                    }
                }
                if (!cobrandActiveFlag) {
                  aid='seo';
                  pid='00017'
                }
            }
            setIsLoading(false)
        }
        setIsLoading(false)
    }
    return (
        <>
            {isLoading ? <Loader /> : <></>}
            <Grid>
                <LoginHeader aid={aid} hideNavigation={hideNavigation} isMobileParams={isMobileParams} logoName={'cobranding'} customColorArray={customColorArrays[0]} />
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ pointerEvents: props.cobrandFlag == true ? 'none' : 'auto' }}>
                <Grid container item lg={12} md={12} sm={12} xs={12} mb={2} mt={2} className="customize-wraper">
                    {offerdetails ?
                        <Grid className='offerDetails-css offerDetails-css-mob' sx={{ display: stepcount == 5 ? 'none' : 'block' }}>
                            {
                                offerdetails.is_sponsored ?
                                    <Typography level="h5" className="fontSizeCobrand"><b>{
                                        pid == 67902 ? "You are enrolling in a MyFreeScoreNow credit monitoring membership Sponsored by Cordova Financial." : offerdetails.description}</b></Typography>
                                    :
                                    offerdetails.days == 0 ?
                                        pid == 11233 ?
                                            <Typography level="h5" className="fontSizeCobrand"><b>Get all 3 Reports and 3 Scores now!  A new 3-Bureau report included each month. $1.00 Upfront Activation Fee AND {offerdetails.price} Monthly MyFreeScoreNow Membership automatically charged at time of order. Payments non-refundable. 
                                            {/* Call 888-548-2008 to cancel to avoid future billings. */}
                                            </b></Typography>
                                            :
                                            <Typography level="h5" className="fontSizeCobrand"><b>Get all 3 Reports and 3 Scores now!  A new 3-Bureau report included each month. {offerdetails.price} Monthly MyFreeScoreNow Membership automatically charged at time of order. Payments non-refundable. 
                                            {/* Call 888-548-2008 to cancel to avoid future billings. */}
                                            </b></Typography>
                                        :
                                        pid == 67040 || pid == 36642 ?
                                            <Typography level="h5" className="fontSizeCobrand"><b></b>{offerdetails.description}</Typography>
                                            :
                                            offerdetails.hasFee == 'None' ?
                                                <Typography level="h5" className="fontSizeCobrand"><b>Get all 3 Reports and 3 Scores now! {offerdetails.days}-Day Free Trial of credit monitoring. {offerdetails.price} Monthly MyFreeScoreNow Membership automatically charged after the {offerdetails.days}-Day Trial Period. Payments non-refundable. 
                                                {/* Call 1-888-548-2008 to cancel to avoid future billings. */}
                                                </b></Typography>
                                                :
                                                <Typography level="h5" className="fontSizeCobrand"><b>Get all 3 Reports and 3 Scores now! New 3-Bureau Report included monthly $1.00 Upfront Activation Fee. Monthly MyFreeScoreNow Membership of {offerdetails.price} automatically charged after {offerdetails.days}-Day Trial. Payments non-refundable. 
                                                {/* Call 888-548-2008 to cancel to avoid future billings. */}
                                                </b></Typography>
                            }
                        </Grid>
                        :
                        null}
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12} p={3} pt={0} className={(!offerdetails) || stepcount == 5 ? 'grid-outer-view mt-3' : 'grid-outer-view'} >
                    <Card className='cardstyle cobrand-card-wrapper' style={{ background: `${customColorArrays[0].bodyBackground}`, padding: '0px',borderRadius:'0px'}} id="bodyBackground" >
                        <CardContent>
                            <Grid container item lg={12} md={12} sm={12} xs={12} className='row-reverse1' >
                                <Grid item lg={6} md={6} sm={12} xs={12} ref={gridRef} sx={{ display: stepcount == 3 || stepcount == 5 ? 'none' : 'flex' }} className="CobrandImgBanner-wrapper">
                                    {croppedImages.map((item, index) => {
                                        return (item.step == stepcount) ? (
                                            <>
                                            <img key={item.step} src={item.url} className="CobrandImgBanner" layout="fill" 
                                            />
                                            </>
                                        ) : null
                                    })}
                                </Grid>

                                <Divider orientation="vertical" flexItem style={{ width: '3px', background: `${customColorArrays[0].primaryColor}`}} sx={{ display: stepcount == 3 || stepcount == 5 ? 'none' : 'block' }} />
                                <Grid item lg={(stepcount == 3 || stepcount == 5) ? 12 : 5} md={(stepcount == 3 || stepcount == 5) ? 12 : 5} sm={12} xs={12} className='cobrand-form-content' sx={{ margin: 'auto' }} >
                                    <Grid item lg={12} md={12} sm={12} xs={12} pb={2} style={{margin: stepcount == 3 || stepcount == 5 ? '0px 30px' : '0px'}} className='stepper-css'>
                                        <Stepper
                                            activeStep={activeStep}
                                            connector={<CustomStepConnector />}>
                                            {steps.map((label, index) => (
                                                <Step key={label} completed={completed[index + 1]}
                                                    sx={{ padding: '0px', "& .MuiStepIcon-root": { color: activeStep === index ? 'white !important' : customColorArrays[0].primaryColor }, "& .MuiStepIcon-text": { fill: activeStep === index ? 'black' : 'white' }, "& .MuiStep-root .MuiStepIcon-root": { color: activeStep === index ? 'white !important' : customColorArrays[0].primaryColor } }}>
                                                    <StepButton
                                                        style={{ borderColor: `${customColorArrays[0].primaryColor}`, background: activeStep === index ? customColorArrays[0].primaryColor : 'white', "& .MuiStepLabelLabel": { color: activeStep === index ? 'white' : 'black' } }}
                                                        className="cobrand-stepper cobrand-stepper-enroll"
                                                        sx={{
                                                            background: activeStep === index ? customColorArrays[0].primaryColor : 'white !important',
                                                            borderColor: `${customColorArrays[0].primaryColor}`,
                                                            color: activeStep === index ? 'white' : 'black',
                                                            borderWidth: '2px', borderStyle: 'solid',
                                                        }}
                                                    >
                                                        {label}
                                                    </StepButton>
                                                </Step>
                                            ))}
                                        </Stepper>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} pb={2}>
                                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ display: 'block', width: '100%' }}>
                                            {stepcount == 1 ? <NewEnrollment1 onValueChange={handleChildValue} props={stepcount} customColorArray={customColorArrays[0]} textColor={textColor} aid={aid} pid={pid} adid={adid} btnTextColor={btnTextColor} chatEnable={chatEnable} emailUsEnable={emailUsEnable} companyId={companyId} step1Link={step1Link}/> : ""}
                                            {stepcount == 2 ? <NewEnrollment2 onValueChange={handleChildValue} props={stepcount} customColorArray={customColorArrays[0]} textColor={textColor} btnTextColor={btnTextColor} chatEnable={chatEnable} emailUsEnable={emailUsEnable} companyId={companyId} step2Link={step2Link}/> : ""}
                                            {stepcount == 3 ? <NewEnrollment3 onValueChange={handleChildValue} props={stepcount} customColorArray={customColorArrays[0]} textColor={textColor}  btnTextColor={btnTextColor} internFlag={props.cobrandFlag} chatEnable={chatEnable} emailUsEnable={emailUsEnable} companyId={companyId}/> : ""}
                                            {stepcount == 4 ? <NewEnrollment4 onValueChange={handleChildValue} props={stepcount} customColorArray={customColorArrays[0]} textColor={textColor} btnTextColor={btnTextColor} chatEnable={chatEnable} emailUsEnable={emailUsEnable} companyId={companyId} step3Link={step3Link}/> : ""}
                                            {stepcount == 5 ? <NewEnrollment5 customColorArray={customColorArrays[0]} internFlag={props.cobrandFlag} pid={props.cobrandFlag ? pid : ''} btnTextColor={btnTextColor} chatEnable={chatEnable} emailUsEnable={emailUsEnable} companyId={companyId}/> : ""}
                                            </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <RegisterFooter customColorArray={customColorArrays[0]} />
        </>

    )
}
export default CustomizeEnrollIndex;