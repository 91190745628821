import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import DefaultLayout from '../../../Components/DefaultLayout';
import { TabContext, TabPanel, TabList } from '@mui/lab';
import { Box, Tab, Grid, FormControl, Button, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import Enrolled from '../../../Components/MyLeads/AffiliateMyLeads/Enrolled';
import Upgraded from '../../../Components/MyLeads/AffiliateMyLeads/Upgraded';
import { DateRangePicker ,createStaticRanges} from 'react-date-range';
import { format, subDays } from 'date-fns';
import Freez from '../../../Components/Loader/Loader'
import Backdrop from '@mui/material/Backdrop';
import AuthUser from '../../../Components/Auth/AuthUser';
import { Typography } from '@mui/joy';
import swal from "sweetalert";
import { useSelector, useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import { calendarFilter,useDateSelectionRange,getInitialSelectionRange } from '../../../Components/CommonFormula';

function Index(props) {
    const dispatch = useDispatch();
    const { tab } = useParams();
    const { http } = AuthUser();
    const [value, setValue] = React.useState(tab);
    const [link, setLink] = React.useState("");
    const { selectionRange, setSelectionRange, startDate, endDate,clearFilter } = useDateSelectionRange(getInitialSelectionRange('true'))

    useEffect(() => {
        setValue(tab);
    }, [tab])
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
    const sideBar = calendarFilter();
    const staticRanges = [
        // ...defaultStaticRanges,
        ...createStaticRanges(sideBar)
    ];


    //loader function and variable start
    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    //loader function and variable end


    //Get leads function start

    const [enrolled1, setEnrolled] = useState([]);
    const [upgraded1, setUpgraded] = useState([]);

    const GetMyLeads = async () => {
        handleFreezStart();

        const apiUrl = '/fetchLeadReport';
        const args = {
            fromDate: startDate,
            toDate: endDate
        }
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if (res) {
            if (res.success == true) {
                handleFreezStop();
                setEnrolled(res.data.enrolledMembers);
                setUpgraded(res.data.upgradedMembers);
            } else {
                handleFreezStop();

            }
        } else {
            swal({
                title: "Failed",
                text: "Network Error",
                icon: "error",
                button: "Ok",
            });
        }

    }

    const fetchCreditInfo = async () => {
        const apiUrl = '/fetchCreditInfo';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            setLink(res.data.credit_snapshot_link);
            localStorage.setItem('creditSnapshotLink', res.data.credit_snapshot_link);
        }
    }
    useEffect(() => {
        GetMyLeads();
        fetchCreditInfo();
    }, []);

    //Get leads function start

    // const drawerWidth = 590;
    const ContentLayout=
            <>
                <Box sx={{ width: "100%", typography: 'body1' }}>
                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                        <Typography level="h4" color="primary">Credit Snapshot Leads</Typography>
                    </Grid>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: 'space-between' }}>
                                <Grid item lg={7} md={7} xs={6} sm={6}>
                                    <TabList onChange={handleChange} aria-label="lab API tabs example" >
                                            <Tab  component={Link} to="/affiliate/myleads/enrolled" value="enrolled" label="Enrolled" />
                                            <Tab  component={Link} to="/affiliate/myleads/upgraded" value="upgraded" label="Upgraded" />
                                    </TabList>
                                </Grid>
                                <Grid item lg={5} md={5} xs={6} sm={6} sx={{ justifyContent: "right" }}>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} >
                                        <Grid item lg={6} md={6} sm={8} xs={8}>
                                            <FormControl fullWidth size='small'>
                                                <InputLabel id="demo-multiple-name-label">Select Date Range</InputLabel>
                                                <Select
                                                    labelId="demo-multiple-name-label"
                                                    id="demo-multiple-name"
                                                    value="date"
                                                    input={<OutlinedInput label="Select Date Range" />}
                                                >
                                                    <MenuItem value="date" >{startDate ? <>{startDate} To {endDate}</> : 'Select Date Range'}</MenuItem>
                                                    <DateRangePicker
                                                        className='custom-date-picker'
                                                        onChange={item => setSelectionRange([item.selection])}
                                                        showSelectionPreview={true}
                                                        moveRangeOnFirstSelection={false}
                                                        months={2}
                                                        ranges={selectionRange}
                                                        direction="horizontal"
                                                        maxDate={new Date()}
                                                        staticRanges={staticRanges}
                                                    />
                                                </Select>
                                                 <Button onClick={clearFilter} >Clear Dates</Button>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={4} xs={4}>
                                            <Button variant="contained" color="success" sx={{ minWidth: 150 }}
                                                onClick={() => {
                                                    GetMyLeads();
                                                }}>
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                        {enrolled1 ? <TabPanel value="enrolled"><Enrolled props={enrolled1} /></TabPanel> : null}
                        {upgraded1 ? <TabPanel value="upgraded"><Upgraded props={upgraded1} /></TabPanel> : null}

                    </TabContext>
                </Box>
            </>
     
    return (
        <div>
            <DefaultLayout content={ContentLayout} />
            {/* loader code start */}
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                    onClick={handleFreezStop}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}
        </div>
    );
}

export default Index;