import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBTYkkvmNnJRn2UXulbDzsPFBx-nVsQomI",
  authDomain: "myfreescorenow-acd3b.firebaseapp.com",
  projectId: "myfreescorenow-acd3b",
  storageBucket: "myfreescorenow-acd3b.appspot.com",
  messagingSenderId: "524057498388",
  appId: "1:524057498388:web:85312b6fb6556c6b91a801",
  measurementId: "G-SB4E21Z143"
};

export const app = initializeApp(firebaseConfig);

let messaging1='';
if ('serviceWorker' in navigator) {
   messaging1 = getMessaging(app);
}

export const messaging = messaging1;
export const fcmAuth = getAuth(app);

export const getOAuthToken = async () => {
  try {
    const response = await fetch("https://api.myfreescorenow.com/api/get-oauth-token");
    const data = await response.json();
    if (data.oauth_token) {
      console.log("OAuth Token:", data.oauth_token);
      return data.oauth_token;
    } else {
      console.error("Error fetching token:", data.error);
      return null;
    }
  } catch (error) {
    console.error("Request failed:", error);
    return null;
  }
};
export const FCMServerKey = "AAAAegRC0xQ:APA91bEghntF3c1e9XUbicDFaNLxwX4i0C9fNjQ5AGo3iJvcLMLZt5enIwFDB1IBgjuMkX918_2Fo3oBt1-J2f-2zx0iRfIRKKpQeAnyV0QDYwyWetIwKOhAUGHml2BgUhRe9HyufWtp";
export const FCMSendUrl = "https://fcm.googleapis.com/v1/projects/myfreescorenow-acd3b/messages:send";