import React from "react";
import { Route,BrowserRouter,Routes, Navigate } from 'react-router-dom';
import Routing from "./Navigation/Routing";
import './Css/Custom.css';
import './Css/Custom1.css';
import { onMessage } from "firebase/messaging";
import { messaging } from "./firbase/firstore";
import swal from "sweetalert";
import config from "./AppConfig";


function App() {
	if(messaging !== ''){
		onMessage(messaging, (payload) => {
			console.log('app received------', payload);
			if (document.visibilityState === "visible") {
				swal({
					title: payload.data.title,
					text: payload.data.body,
					icon: "info",
					buttons: ["Cancel", "Ready for Assistance"],			
				}).then((ok) => {
					if (ok) {
						window.location.href = config.BASE_URL+'AbandonedMembers';     
					}
				});
			}
		});
	}
	return (
		<>
		<BrowserRouter>
			<Routing />
		</BrowserRouter>
		</>
		
	);
}

export default App;
