
import React,{useMemo,useState,useEffect} from 'react';
import {useParams, useNavigate, Link} from 'react-router-dom';
import { Typography,Table, Option } from '@mui/joy';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import DefaultLayout from '../../../Components/DefaultLayout';
import { Grid,InputLabel,MenuItem, FormControl, Select,Dialog,DialogTitle,DialogContent,DialogActions,Box,Tab,DialogContentText,TextField,FormHelperText,Tooltip,Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {TabContext,TabPanel,TabList} from '@mui/lab';
import { useSelector, useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import { useTheme } from '@mui/material/styles';
import AuthUser from "../../../Components/Auth/AuthUser";
import Loader from '../../../Components/Loader/Loader';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader'
import swal from "sweetalert";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MaterialReactTable from 'material-react-table';
import dayjs from 'dayjs';
import {currencyFormat} from '../../../Components/CommonFormula';
import config from '../../../AppConfig';
import * as Yup from 'yup';
import { Formik } from 'formik';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ContactMailIcon from '@mui/icons-material/ContactMail';

let RequestFormInitialValues = {
  softwareId: '',
  firstName: '',
  lastName: '',
  email: ''
};

let capturePaymentValues = {
  softwareId: '',
  referralCommission: '',
  bonus: '',
  totalCommission: '',
  paymentDate: '',
  paymentDetails: '',
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };
  




const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    //   width: 250,
    },
  },
};
let summaryMonthYear =[];
let reportSubmitted = 0;


function SoftwareCommissionSummary(props) {
    const {user} = AuthUser();
    const dispatch = useDispatch();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [data, setData] = useState();
    const [month, setMonth] = useState();
    const [year, setYear] = useState();
    const [labelMonth, setLabelMonth] = useState();
    const [labelYear, setLabelYear] = useState();
    const [monthValue, setMonthValue] = useState();
    const [perPage, setPerPage] = useState(100000);
    const [isLoading, setIsLoading] = useState(false);
    const [saveToLocal, setSaveToLocal] = useState(false);
    const [freez, setFreez] = React.useState(false);
    const [currentDate, setCurrentDate] = useState("");
    const [minDate, setMinDate] = useState("");
    const [rowSelection, setRowSelection] = useState({});
    const [columnVisibility, setColumnVisibility] = React.useState({});
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('md');
    const [editDisabled, setEditDisabled] = React.useState(false);
    const [openCapturePayment, setOpenCapturePayment] = React.useState(false);
    const [capturePaymentBtn, setCapturePaymentBtn] = useState(false);
    const [todaysDate, setTodaysDate] = useState("");
    const [captureFlag, setCaptureFlag] = useState(false);
    const tablePreferencesArray = {
    }
    
    const navigate = useNavigate();
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };

    const backupData = (values) =>{
        summaryMonthYear = values;
        localStorage.setItem('softwareSummaryMonth', summaryMonthYear[0]);
        localStorage.setItem('softwareSummaryYear', summaryMonthYear[1]);
    }


    const setDefaultMonthYear = async page => {
        const currentDate = new Date();
        const lastTwoMonthsDate = new Date();
        lastTwoMonthsDate.setMonth(currentDate.getMonth() - 2);
        const lastTwoMonthsYear = lastTwoMonthsDate.getFullYear();
        const lastTwoMonth = lastTwoMonthsDate.getMonth();
        localStorage.getItem("softwareSummaryMonth") === null ? setMonth(lastTwoMonth) : setMonth(localStorage.getItem("softwareSummaryMonth"));
        localStorage.getItem("softwareSummaryYear") === null ? setYear(lastTwoMonthsDate) : setYear(localStorage.getItem("softwareSummaryYear"));
    }

    useEffect(() => {
        if(user.roles_id < 3) {
            const myDate = new Date();
            myDate.setDate(myDate.getDate());
            var cDate = Moment(myDate).tz('America/Los_Angeles').format('MM-DD-YY'); 
            setCurrentDate(cDate);
    
            const startDate = new Date('2005-01-01');
            startDate.setDate(startDate.getDate());
            var cDate = Moment(startDate).tz('America/Los_Angeles').format('MM-DD-YY'); 
            setMinDate(cDate);

            setDefaultMonthYear();
        }else{
            navigate('/login');
        }
       
    }, []);

    useEffect(() => {
      if (Object.values(columnVisibility).length > 0) {
        setColumnVisibility(columnVisibility);
      }
    }, [rowSelection]);

    useEffect(() => {
      tablePreferences();
  },[]);

  useEffect(()=>{  
    saveTablePreferences();
  },[columnVisibility])

  const tablePreferences = async () => { 
    const apiUrl = '/fetchTablePreferences/'+"software-commissions";
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if(res){
      if (Object.values(res).length == 0) {
        setColumnVisibility(tablePreferencesArray);
      } else {
        setColumnVisibility((JSON.parse(res.preferences)));
      }
    }
  }

  const saveTablePreferences = async() => {
    if (Object.values(columnVisibility).length > 0) {
      const apiUrl = '/saveTablePreferences';
      const args = {
          columnArray: columnVisibility,
          userId: user.id,
          tableId: 'software-commissions'
      }
      const res = await dispatch(postData({apiUrl,args})).unwrap();
    } 
  }

    const checkUserRole = () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id >= 3 ) {
            swal({
                title: "Failed",
                text: "This action is unauthorized.",
                icon: "error",
                button: "Ok",
            })
            navigate('/login');
            return false;
        }else{
            return true;
        }
    };

    const submitForm = async(event) => {
        var check = checkUserRole();
        if(check === true) {
            var currentMonth = (new Date).getMonth();
            var currentYear = (new Date).getFullYear();
            if(month > currentMonth && (new Date(year).getFullYear() == currentYear || new Date(year).getFullYear() > currentYear)){
                swal({
                    title: "Failed",
                    text: "Please Select Correct Month and Year",
                    icon: "error",
                    button: "Ok",
                  })
                  return false;
            }
            if (typeof month === 'undefined' || typeof year === 'undefined') {          //check month & year is undefined
                swal({
                    title: "Failed",
                    text: "Please Select Month and Year",
                    icon: "error",
                    button: "Ok",
                  })
                
            }else {
                reportSubmitted = 1;
                
                handleFreezStart();
                const sendYear = new Date(year).getFullYear();

                const apiUrl = '/check-fetch-month-year';
                const args = {
                    month : month,
                    year:sendYear, 
                }
                const res = await dispatch(postData({apiUrl,args})).unwrap();
                if(res) {
                    if(res.success === true) {
                        if(res.exists == "Yes") {
                            fetchCommission(month,sendYear);
                        }else{
                            swal({
                                title: "Failed",
                                text: "Provided month & year commission not saved till yet",
                                icon: "error",
                                button: "Ok",
                              })
                              handleFreezStop();
                        }
                    }else{
                        swal({
                            title: "Failed",
                            text: "Something went wrong, please try again",
                            icon: "error",
                            button: "Ok",
                          })
                          handleFreezStop();
                    }
                }
            }
        }
    };

    const fetchCommission = async(month,year) => {
        const apiUrl = '/software-commission-data';
        const args = {
            month : month,
            year:year, 
        }
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        if(res) {
            
            if(res.success === true){
                setData(res.result);
                setLabelMonth(res.dateData.month);                                         
                setLabelYear(res.dateData.year);                                         
                setMonthValue(res.dateData.monthvalue);  
                setCaptureFlag(res.captureFlag);  
                handleFreezStop();
            }else{
                handleFreezStop();         
            }
        }
    }

    const customSortReferralCommFunction = (rowA, rowB) => {
        const a = rowA.original.referralCommission;
        const b = rowB.original.referralCommission;
        return a - b;
    };

    const customSortBonusFunction = (rowA, rowB) => {
      const a = rowA.original.totalBonus;
      const b = rowB.original.totalBonus;
      return a - b;
    };
  
    const customSortTotalCommFunction = (rowA, rowB) => {
      const a = rowA.original.totalBonus;
      const b = rowB.original.totalBonus;
      return a - b;
    };

    const handleClickOpen = (id,firstName,lastName,email) => {
      RequestFormInitialValues.softwareId = id;
      RequestFormInitialValues.firstName = firstName != "null" ? firstName : '';
      RequestFormInitialValues.lastName = lastName != "null" ? lastName : '';
      RequestFormInitialValues.email = email != "null" ? email : '';
      setOpen(true);
    };

    const handleCapturePayment = (id,referralCommission,bonus,totalCommission) => {
      capturePaymentValues.softwareId = id;
      capturePaymentValues.referralCommission = referralCommission;
      capturePaymentValues.bonus = bonus;
      capturePaymentValues.totalCommission = totalCommission;
      const newDate = new Date();
      newDate.setDate(newDate.getDate());
      let tDate = Moment(newDate).tz('America/Los_Angeles').format('MM-DD-YY'); 
      setTodaysDate(tDate);
     
      tDate = new Date(tDate);
      const day = String(tDate.getDate()).padStart(2, '0');
      const month = String(tDate.getMonth() + 1).padStart(2, '0');
      const year = tDate.getFullYear();
      var mydate = year+"/"+month+"/"+day;
      capturePaymentValues.paymentDate = mydate;
      setOpenCapturePayment(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleCloseCapturePayment = () => {
      setOpenCapturePayment(false);
    };

    const editSchema = Yup.object().shape({
      firstName: Yup.string()
        .matches(/^[A-Za-z]+$/, "Invalid first name")
        .required('Please enter first name'),
  
      lastName: Yup.string()
        .matches(/^[A-Za-z]+$/, "Invalid last name")
        .required('Please enter last name'),
  
      email: Yup.string()
      .email('Invalid email format.')
      // .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid email format.")
      .matches(/^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/i, "Invalid email format.")
      .required('Please enter email.'),
    });

    const captureSchema = Yup.object().shape({
      paymentDetails: Yup.string()
        .required('Please enter payment details'),
  
      paymentDate: Yup.string()
        .required('Please enter Date'),
      
    })

    const submitRequest = async (values) => {
      setOpen(false);
      setEditDisabled(true);
      handleFreezStart();
      const apiUrl = '/update-software-info';
      const args = {
        id: values.softwareId,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
      };
      const res = await dispatch(postData({ apiUrl, args })).unwrap();
      if (res) {
        if (res.success === true) {
          setOpen(false);
          swal({
            title: "Success",
            text: res.message,
            icon: "success",
            button: "Ok",
          })
        } else {
          swal({
            title: "Failed",
            text: res.message,
            icon: "error",
            button: "Ok",
          })
        }
      }
      submitForm();
      setEditDisabled(false);
    }

    const captureCommission = async (values) => {
      console.log(values);
      setOpenCapturePayment(false);
      setCapturePaymentBtn(true);
      handleFreezStart();
      const date = new Date(values.paymentDate);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      var mydate = year+"/"+month+"/"+day;

      const apiUrl = '/capture-software-commission';
      const args = {
        id: values.softwareId,
        referralCommission: values.referralCommission,
        bonus: values.bonus,
        totalCommission: values.totalCommission,
        paymentDate: mydate,
        paymentDetails: values.paymentDetails,
        month:labelMonth,
        year:labelYear
      };
      const res = await dispatch(postData({ apiUrl, args })).unwrap();
      if (res) {
        if (res.success === true) {
          setOpen(false);
          swal({
            title: "Success",
            text: res.message,
            icon: "success",
            button: "Ok",
          })
        } else {
          swal({
            title: "Failed",
            text: res.message,
            icon: "error",
            button: "Ok",
          })
        }
      }
      submitForm();
      setCapturePaymentBtn(false);
    }

    const csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      fileName: 'Commission Summary Report',
      headers: ['Software Name','Name', 'Email', 'Referral Commission ($)','One Time Referral Bonus ($)','Total Commission'],
    };

    const handleExportData = (data) => {
      const exportedData = data.map((row) => {
        return {
          software_name: `${row.original.softwareName? row.original.softwareName: 'N/A'}`,
          name: `${row.original.name? row.original.name: 'N/A'}`,
          email: `${row.original.email ? row.original.email : '0'}`,
          referral_commission:`${row.original.referralCommission ? row.original.referralCommission : '0'}`,
          bonus:`${row.original.totalBonus ? row.original.totalBonus : '0'}`,
          total_commission:`${row.original.totalCommission?row.original.totalCommission:'N/A'}`,
        };
      });
  
      const csvContent = convertToCSV(exportedData);
      // Create a Blob object from the CSV data
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
  
      // Create a download link element
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = csvOptions.fileName + '.csv';
  
      // Append the link to the document body
      document.body.appendChild(link);
  
      // Programmatically click the link to trigger the file download
      link.click();
  
      // Clean up the temporary URL and remove the link from the document
      URL.revokeObjectURL(link.href);
      link.remove();
    };

    const convertToCSV = (data) => {
      const headers = csvOptions.headers.join(',') + '\n';
  
      const rows = data.map((row) => {
        return Object.values(row).map((value) => {
          // Handle any necessary formatting or escaping of values
          // For simplicity, we assume all values are already properly formatted
      
          // Wrap the value with double quotes if it contains a comma
          if (typeof value === 'string' && value.includes(',')) {
          return `"${value}"`;
          }
      
          return value;
        }).join(',');
        }).join('\n');
  
      return headers + rows;
    };

    const viewDetails = (softwareId) => {
    
      navigate('/software-commission-details', { state: { softwareId: softwareId, month:labelMonth, year:labelYear} });
    };

    const columns = useMemo(
        //column definitions...
        () => [
          {
            accessorFn: (data) => `${data.softwareName ? data.softwareName.trim() : ''}`,
            id:'softwareName',
            header: 'Software Name',
            size: 50,
            Cell: ({ renderedCellValue, row }) => (row.original.softwareName ? row.original.softwareName.trim() : "N/A"),
          },
          {
            accessorFn: (data) => `${data.name ? data.name.trim() : ''} `,   
            enableClickToCopy: true,
            id: 'name',
            header: 'Name',
            size: 110,
            Cell: ({ renderedCellValue, row }) => (
                row.original.name != " "? 
                <>
                    {row.original.name.trim()}
                </>
                :'N/A'
                
            ),
          },
          {
                    accessorFn: (data) => `${data.email ? data.email.trim() : ''} `,   
                    enableClickToCopy: true,
                    id: 'email',
                    header: 'Communication Email',
                    size: 110,
                    Cell: ({ renderedCellValue, row }) => (
                        row.original.email ? 
                        <Box
                          sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '1rem',
                          }}
                          className="datatable"
                          >
                            <span>
                                {row.original.email.trim()}
                            </span>
                        </Box>
                :'N/A'
                        
                    ),
          },
          
          {
            accessorFn: (data) =>  `${data.referralCommission ? (parseFloat(data.referralCommission)).toFixed(2) : ""}`,
            filterVariant: 'range',
            numeric: true,
            sortingFn: customSortReferralCommFunction, 
            id:'referral_commission',
            header: 'Referral Commission ($)',
            size: 100,
            Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.referralCommission))),
          },
          {
            accessorFn: (data) =>  `${data.totalBonus ? (parseFloat(data.totalBonus)).toFixed(2) : ""}`,
            filterVariant: 'range',
            numeric: true,
            sortingFn: customSortBonusFunction, 
            id:'bonus',
            header: 'One Time Referral Bonus ($)',
            size: 100,
            Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.totalBonus))),
          },
          {
            accessorFn: (data) =>  `${data.totalCommission ? (parseFloat(data.totalCommission)).toFixed(2) : ""}`,
            filterVariant: 'range',
            numeric: true,
            sortingFn: customSortTotalCommFunction, 
            id:'total',
            header: 'Total Commission($)',
            size: 100,
            Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.totalCommission))),
          },
         
          {
            id: 'action',
            header: 'Action',
            Cell: ({ row }) => (
              <Box>
                <>   
               
                  {
                    ((parseFloat(row.original.totalCommission) > 0 && row.original.captured == false) && captureFlag == true) ? 
                    <Tooltip title="Capture Payment" arrow><MonetizationOnIcon sx={{marginRight:"10px"}} onClick={()=> handleCapturePayment(`${row.original.id}`,`${row.original.referralCommission}`,`${row.original.totalBonus}`, `${row.original.totalCommission}`)} /></Tooltip>
                  : 
                    row.original.captured == true ?  
                    <Tooltip title="Paid"><PaidOutlinedIcon sx={{marginRight:"10px"}} color="disabled"/></Tooltip>
                    : ''
                  }
                  <Tooltip title="Update Software Details" arrow>
                    <ContactMailIcon
                      onClick={() => { handleClickOpen(`${row.original.id}`,`${row.original.firstName}`, `${row.original.lastName}`, `${row.original.email}`) }}
                    /> 
                  </Tooltip>
                  <Tooltip title="Details" arrow><Button sx={{marginBottom:"15px"}} className='btn btn-sm btn-info text-white' onClick={()=> viewDetails(`${row.original.id}`)} ><img src={require('../../../assets/images/details.png')} /></Button></Tooltip>
                </> 
              </Box>
            ),
           
          },
        ]
      )
    
    function ContentLayout() {
    return (
        <>
         {/* {isLoading?<Loader/>:<></>} */}
        <Box>
            <Grid container item xs={12} mb={2}>
                <Typography level="h4" color="primary">
                    Software Commission Summary Report
                </Typography>
            </Grid>
               
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                <Grid >
                    <FormControl sx={{ width: 200 }} mt={4}>
                        <InputLabel id="month">Select Month</InputLabel>
                        <Select
                            labelId="demo-multiple-name-label"
                            name='month'
                            label="Select Month"
                            value={summaryMonthYear[0] ? summaryMonthYear[0] : month}
                            MenuProps={MenuProps}
                            onChange={(e) => { setMonth(e.target.value); backupData([e.target.value, year]) }}
                        >
                            <MenuItem value="0" >January</MenuItem>
                            <MenuItem value="1" >February</MenuItem>
                            <MenuItem value="2">March</MenuItem>
                            <MenuItem value="3" >April</MenuItem>
                            <MenuItem value="4" >May</MenuItem>
                            <MenuItem value="5" >June</MenuItem>
                            <MenuItem value="6" >July</MenuItem>
                            <MenuItem value="7" >August</MenuItem>
                            <MenuItem value="8" >September</MenuItem>
                            <MenuItem value="9" >October</MenuItem>
                            <MenuItem value="10" >November</MenuItem>
                            <MenuItem value="11" >December</MenuItem>
                        </Select>
                    </FormControl>&nbsp;&nbsp;&nbsp;  
                </Grid>
                <Grid >
                    <FormControl sx={{ width: 200 }} size='small' className='commission-year-selector'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']} >
                                    <DatePicker

                                        id="year"
                                        name="year"
                                        label="Select Year *"
                                        views={['year']}
                                        value={summaryMonthYear[1] ? dayjs(summaryMonthYear[1]) : dayjs(year)}
                                        onChange={(e) => { setYear(e); backupData([month, e]) }}
                                        MenuProps={MenuProps}
                                        minDate={dayjs(minDate)}
                                        maxDate={dayjs(currentDate)}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                    </FormControl>&nbsp;&nbsp;&nbsp; 
                </Grid>
                <Grid sx={{display: 'flex',alignItems: 'center'}}>
                    <Button variant="contained" color='success' onClick={submitForm} sx={{ minWidth: 200 }} >Submit</Button>
                </Grid>
            </Grid>
                
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} >
            <Typography level="h6" color="black" mb={2}>
                <b>{labelMonth} {labelYear} Software Commission Report</b>
            </Typography>
            </Grid>
            <MaterialReactTable
                columns={columns}
                data={data ? data : []}
                globalFilterFn="customSearchFilterFn" 
                enableColumnFilterModes
                enableClickToCopy
                enableRowSelection={false}
                enableColumnFilters={config.DatatableColumnFilter}
                getRowId={(row) => row.id} 
                onRowSelectionChange={setRowSelection} 
                state={{ columnVisibility, rowSelection, isLoading: isLoading }} 
                initialState={{
                  showColumnFilters: false,
                  density: 'compact',
                }}
                onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
                positionToolbarAlertBanner="bottom"
                enableDensityToggle={false}
                muiTableHeadCellProps={{
                  //simple styling with the `sx` prop, works just like a style prop in this example
                  sx: {
                    backgroundColor: "#F6FAFF",
                  },
                }}
                renderTopToolbarCustomActions={({ table }) => (
                  <Box
                      sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                  >
                  <Button
                      color="primary"
                      disabled={table.getPrePaginationRowModel().rows.length === 0}
                      //export all rows, including from the next page, (still respects filtering and sorting)
                      onClick={() =>
                          handleExportData(table.getPrePaginationRowModel().rows)
                      }
                      startIcon={<FileDownloadIcon />}
                      variant="contained"
                  >
                      CSV
                  </Button>
                  </Box>
                  )
                  }
            />

        </Box>
        </>
        );
    }
     
    return(
        <>
            <DefaultLayout content={<ContentLayout />} />
            <div>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={freez}
              onClick={handleFreezStop}
            >
              <Freez />
            </Backdrop>
          </div> 
          <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={fullWidth}
          maxWidth={maxWidth}
        >
          <DialogTitle id="alert-dialog-title" className="back">
            Update Software Details
          </DialogTitle>
          <Formik
            initialValues={RequestFormInitialValues}
            validationSchema={editSchema}
            onSubmit={submitRequest}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <>
              <DialogContent>
                <Grid container item xs>
                  <TextField type='hidden' name="softwareId" value={values.softwareId} sx={{ display: 'none' }} />
                  <TextField
                    id="firstName"
                    name='firstName'
                    label="First Name"
                    fullWidth
                    size="small"
                    margin="dense"
                    value={values.firstName}
                    onChange={handleChange('firstName')}
                    onBlur={handleBlur('firstName')}
                  />
                  {touched.firstName && errors.firstName ? <div className='error'>{errors.firstName}</div> : null}
                </Grid>
                <Grid container item xs mt={2}>
                  <TextField
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    fullWidth
                    size="small"
                    margin="dense"
                    value={values.lastName}
                    onChange={handleChange('lastName')}
                    onBlur={handleBlur('lastName')}
                  />
                  {touched.lastName && errors.lastName ? <div className='error'>{errors.lastName}</div> : null}
                </Grid>
                <Grid container item xs mt={2}>
                  <TextField
                    id="email"
                    name="email"
                    label="Communication Email"
                    fullWidth
                    size="small"
                    margin="dense"
                    value={values.email}
                    onChange={handleChange('email')}
                    onBlur={handleBlur('email')}
                  />
                  {touched.email && errors.email ? <div className='error'>{errors.email}</div> : null}
                </Grid>
              </DialogContent>
              <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                <Button variant="contained" disabled={editDisabled} color="success" sx={{ minWidth: 200 }} onClick={handleSubmit}>Save</Button>
                <Button variant="contained" color="primary" sx={{ minWidth: 200 }} onClick={handleClose}>Close</Button>
              </DialogActions>
            </>
            )}
          </Formik>
        </Dialog>
        <Dialog
          open={openCapturePayment}
          onClose={handleCloseCapturePayment}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={fullWidth}
          maxWidth={maxWidth}
        >
          <DialogTitle id="alert-dialog-title" className="back">
            Capture Payment
          </DialogTitle>
          <Formik
            initialValues={capturePaymentValues}
            validationSchema={captureSchema}
            onSubmit={captureCommission}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <>
              <DialogContent>
                <Grid container item xs>
                  <TextField type='hidden' name="softwareId" value={values.softwareId} sx={{ display: 'none' }} />
                  <TextField type='hidden' name="referralCommission" value={values.referralCommission} sx={{ display: 'none' }} />
                  <TextField type='hidden' name="bonus" value={values.bonus} sx={{ display: 'none' }} />
                  <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                    <TextField margin="dense" size="small" id="outlined-basic" label="Payment Amount ($): *"
                      variant="outlined" fullWidth
                      type="number"
                      value={values.totalCommission}
                      // onChange={handleChange('totalCommission')}
                      readOnly={true}
                      // onBlur={handleBlur('totalCommission')} 
                    />
                  </Grid>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <DemoContainer components={['DatePicker']} margin="dense" size="small" fullWidth>
                      <DatePicker label="Payment Date:*" name="paymentDate" id="paymentDate" defaultValue={dayjs(todaysDate)}
                        onChange={(value) => { setFieldValue("paymentDate", value, true) }} />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <FormHelperText>{touched.paymentDate && errors.paymentDate ? <div className='error'>{errors.paymentDate}</div> : null}</FormHelperText>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                  <FormControl fullWidth size="small">
                    <TextareaAutosize
                      margin="dense"
                      aria-label="minimum height"
                      minRows={3}
                      value={values.paymentDetails}
                      onChange={handleChange('paymentDetails')}
                      onBlur={handleBlur('paymentDetails')}
                      placeholder="Payment Details"
                      style={{ width: "100%" }}
                    />
                  </FormControl>
                  {touched.paymentDetails && errors.paymentDetails ? <div className='error'>{errors.paymentDetails}</div> : null}
                </Grid>
              </DialogContent>
              <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                <Button variant="contained" disabled={capturePaymentBtn} color="success" sx={{ minWidth: 200 }} onClick={handleSubmit}>Capture Payment</Button>
                <Button variant="contained" color="primary" sx={{ minWidth: 200 }} onClick={handleCloseCapturePayment}>Close</Button>
              </DialogActions>
            </>
            )}
          </Formik>
        </Dialog>
        </>
    );
}
    
export default SoftwareCommissionSummary;